import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { isStripeCheckoutSessionEnabled } from 'services/storage';
import useStripePaymentMethodActions from 'graphql/hooks/useStripePaymentMethodActions';

const useConnect = () => {
  const { updateCheckout } = useStripePaymentMethodActions();
  const navigate = useNavigate();

  const handleChangePaymentMethod = useCallback(async () => {
    if (isStripeCheckoutSessionEnabled()) {
      updateCheckout();
    } else {
      navigate('/account/subscription/change-payment-card');
    }
  }, [navigate, updateCheckout]);

  const handleManageFamilyMembers = useCallback(() => {
    navigate('/account/subscription/manage-family-members');
  }, [navigate]);

  return { handleManageFamilyMembers, handleChangePaymentMethod };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
