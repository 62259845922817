import getPackCourses from './getPackCourses';
import getPack from './getPack';

const getCoursePack = async (courseKey: string, packKey?: string) => {
  const { packCourses } = await getPackCourses();

  const lwCourseKey = courseKey.toLowerCase();
  const filteredPackCourses = packCourses.filter(
    ({ bdId, hash, id }) =>
      bdId.toLowerCase() === lwCourseKey ||
      hash.toLowerCase() === lwCourseKey ||
      id.toLowerCase() === lwCourseKey,
  );

  if (filteredPackCourses.length === 0) {
    return undefined;
  }

  if (filteredPackCourses.length === 1) {
    const { packId } = filteredPackCourses[0];
    return packId ? getPack(packId) : undefined;
  }

  if (packKey) {
    const lwPackKey = packKey.toLowerCase();
    const packCourse = filteredPackCourses.find(
      ({ packHash, packId }) =>
        packId.toLowerCase() === lwPackKey ||
        packHash.toLowerCase() === lwPackKey,
    );
    if (packCourse) {
      return packCourse.packId ? getPack(packCourse.packId) : undefined;
    }
  }

  const primaryPackCourse = filteredPackCourses.find(
    ({ packIsPrimaryPack }) => packIsPrimaryPack,
  );
  if (primaryPackCourse) {
    return getPack(primaryPackCourse.packId);
  }

  const firstNotIntroCourse = filteredPackCourses.find(
    ({ isIntroCourse }) => !isIntroCourse,
  );
  if (firstNotIntroCourse) {
    return getPack(firstNotIntroCourse.packId);
  }

  return getPack(filteredPackCourses[0].packId);
};

export default getCoursePack;
