import { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import firebaseDatabase from 'services/firebase';

type ActiveSessionsData = {
  data: number | undefined;
  loading: boolean;
};

const useActiveSessions = () => {
  const [activeSessionsData, setActiveSessionsData] =
    useState<ActiveSessionsData>({ data: undefined, loading: true });

  useEffect(() => {
    const activeSessionsRef = ref(firebaseDatabase, 'active_sessions_count');

    const unsubscribe = onValue(activeSessionsRef, (snapshot) => {
      const data: number = snapshot.val();

      setActiveSessionsData({ data, loading: false });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return {
    activeSessions: activeSessionsData.data,
    loading: !activeSessionsData.data && activeSessionsData.loading,
  };
};

export default useActiveSessions;
export type UseActiveSessions = ReturnType<typeof useActiveSessions>;
