import type { FC } from 'react';
import ExpiredPromotion from 'components/ExpiredPromotion';
import PublicPageLayout from 'components/PublicPageLayout';
import StripePaymentModal from 'containers/Modals/StripePayment';
import CoverImage from './CoverImage';
import Price from './Price';
import {
  Button,
  Container,
  ContentContainer,
  Content,
  Description,
  Footer,
  Isotype,
  Loader,
  Overline,
  Title,
  Donate,
} from './styles';
import { Props } from './types';
import { SUBSCRIPTION_LANDING_IMAGES } from '../constants';

const Lifetime: FC<Props> = ({
  className,
  expiresAt,
  hasPromo,
  isLoading,
  onClick,
  planAmount,
  planOriginalAmount,
  priceId,
  userId,
}) => {
  const isExpiredUserIdLifetimePromotion = userId && !priceId;
  const isExpiredPriceIdLifetimePromotion =
    !!priceId && (!expiresAt || new Date().getTime() > expiresAt);

  if (isExpiredUserIdLifetimePromotion || isExpiredPriceIdLifetimePromotion) {
    return (
      <PublicPageLayout>
        <ExpiredPromotion
          description="Click the link below to continue with your lifetime purchase at the standard price."
          isPublic
          linkText="Purchase a Lifetime"
          linkTo="/checkout?p=l"
        />
      </PublicPageLayout>
    );
  }

  const imageAlt = SUBSCRIPTION_LANDING_IMAGES.lifetime?.alt || '';
  const desktopImageUrl = SUBSCRIPTION_LANDING_IMAGES.lifetime?.desktop || '';
  const mobileImageUrl = SUBSCRIPTION_LANDING_IMAGES.lifetime?.mobile || '';

  return (
    <Container className={className}>
      <ContentContainer>
        <CoverImage
          alt={imageAlt}
          caption="Artwork By: Look at the Parking Lot"
          desktopImageUrl={desktopImageUrl}
          mobileImageUrl={mobileImageUrl}
        />
        <Content>
          <Isotype />
          {!isLoading && hasPromo && (
            <Overline $hasPromo>Limited time offer</Overline>
          )}
          <Title>Upgrade to a Lifetime Membership</Title>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {!hasPromo && <Overline>Limited time offer</Overline>}
              <Price
                hasPromo={hasPromo}
                planAmount={planAmount}
                planOriginalAmount={planOriginalAmount}
              />
              <Button onClick={onClick}>Upgrade Now</Button>
              <Description>
                One-time payment. Never think about billing again.
              </Description>
              <Donate>
                As a company, Waking Up strives to help alleviate human
                suffering on many fronts. To that end, we donate a minimum of
                10% of our profits to the most effective charities.
              </Donate>
            </>
          )}
        </Content>
      </ContentContainer>
      <Footer />
      <StripePaymentModal />
    </Container>
  );
};

export default Lifetime;
