import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { media } from 'styles/mixins';

interface Props {
  pressed: boolean;
}

const styles = css<Props>`
  cursor: pointer;
  line-height: 0;
  margin: -0.5rem;
  padding: 0.5rem;

  ${({ pressed }) =>
    pressed &&
    css`
      transition: opacity 0ms 0ms !important;
    `};

  :active {
    opacity: 0.7 !important;
  }

  svg {
    line-height: 0;
  }

  ${media.phoneLandscape} {
    margin: -1rem;
    padding: 1rem;
  }
`;

export const DivRoot = styled.div<Props>`
  ${styles};
`;

export const LinkRoot = styled(({ pressed, ...rest }) => <Link {...rest} />)`
  ${styles};
`;
