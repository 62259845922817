import { useReactiveVar } from '@apollo/client';
import parseISO from 'date-fns/parseISO';
import subHours from 'date-fns/subHours';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import differenceInHours from 'date-fns/differenceInHours';
import { dismissRetreatVar } from 'graphql/reactive/dismissRetreat';
import useMe from 'graphql/hooks/useMe';
import useRetreat from 'graphql/hooks/useRetreat';

const useConnect = () => {
  const {
    id: userId,
    isSubscribed,
    profile: { subscriptionSubtype },
  } = useMe();
  const { lastCheckedDates } = useReactiveVar(dismissRetreatVar);
  const { retreat, loading: isLoading } = useRetreat();

  const now = new Date();

  let showRetreat = false;
  if (retreat && isBefore(now, parseISO(retreat.endDate))) {
    const lastCheckedDate = lastCheckedDates[userId];

    if (!lastCheckedDate) {
      showRetreat = true;
    } else {
      const dtLastCheckedDate = parseISO(lastCheckedDate);
      const dt24HoursBefore = subHours(parseISO(retreat.startDate), 24);
      const checkedBeforeWithin24Hours = isBefore(
        dtLastCheckedDate,
        dt24HoursBefore,
      );

      if (checkedBeforeWithin24Hours) {
        const isRemindTime =
          differenceInHours(now, dtLastCheckedDate) >= retreat.ctaReappearHours;
        const isWithin24Hours = isAfter(now, dt24HoursBefore);

        showRetreat = isRemindTime || isWithin24Hours;
      }
    }
  }

  return {
    isLoading,
    isSubscribed,
    retreat,
    showRetreat,
    subscriptionSubtype,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
