import type { FC } from 'react';
import { Dot, Item, Text } from './styles';
import type { Props } from './types';

const OptionItem: FC<Props> = ({ active, onClick, value }) => (
  <Item $active={active} onClick={onClick}>
    <Dot>{active && <>&bull;</>}</Dot>
    <Text variant="inherit" noWrap>
      {value}
    </Text>
  </Item>
);

export default OptionItem;
