import styled from 'styled-components';
import DefaultImage from 'components/Image';
import DefaultIcon from 'components/Icon';
import DefaultButton from 'components/Button';
import {
  Heading as DefaultHeading,
  Body as DefaultBody,
} from 'components/Typography';
import DefaultDetailsTabs from 'components/DetailsTabs';

export const Container = styled.div`
  padding-bottom: 2rem;
`;

export const OptionsMenuButton = styled.button`
  align-items: center;
  background: transparent;
  border-radius: 1.5rem;
  border: none;
  box-sizing: content-box;
  color: ${({ theme }) => theme.colors.coreNeutralBlack};
  cursor: pointer;
  display: flex;
  height: 1.125rem;
  justify-content: center;
  line-height: 1;
  outline-offset: 0.375rem;
  padding: 0 0.5rem;
  position: relative;
  user-select: none;
`;

export const Icon = styled(DefaultIcon).attrs(({ theme }) => ({
  color:
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralBlack
      : theme.colors.coreNeutralWhite,
}))``;

export const InfoCard = styled.div`
  margin-bottom: ${({ theme }) => theme.measures.l};
`;

export const Image = styled(DefaultImage)`
  border-radius: ${({ theme }) => theme.radius.xsm};
  margin: 0 auto ${({ theme }) => theme.measures.l} auto;
  width: 17.5rem;
`;

export const Title = styled(DefaultHeading).attrs(() => ({
  $weight: 'bold',
}))``;

export const Subtitle = styled(DefaultHeading).attrs(() => ({
  $weight: 'regular',
}))`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutral700
      : theme.colors.coreNeutral50};
`;

export const Button = styled(DefaultButton)`
  margin-top: ${({ theme }) => theme.measures.l};
`;

export const DurationAndFav = styled(DefaultBody).attrs(() => ({
  $size: 'medium',
  $weight: 'regular',
}))`
  margin-top: ${({ theme }) => theme.measures.xxs};
  display: flex;
  align-items: center;
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutral600
      : theme.colors.coreNeutral200};
`;

export const FavIcon = styled(DefaultIcon).attrs(() => ({ size: 16 }))`
  margin-left: ${({ theme }) => theme.measures.xs};
`;

export const PlayIcon = styled(DefaultIcon)`
  margin-right: ${({ theme }) => theme.measures.xs};
`;

export const DetailsTabs = styled(DefaultDetailsTabs)`
  margin-top: ${({ theme }) => theme.measures.l};
`;
