import { useCallback, useMemo } from 'react';
import { useField } from 'formik';
import type { LogicProps } from './types';

const useLogic = ({ name, options }: LogicProps) => {
  const [{ value: formikValue }, meta, helpers] = useField(name);
  const { touched, error } = meta;
  const { setValue } = helpers;

  const handleChange = useCallback(
    (newValue: any) => {
      setValue(newValue?.value || '');
    },
    [setValue],
  );

  const value = useMemo(() => {
    if (formikValue === null) {
      return null;
    }

    return options.find((opt) => opt.value === formikValue) || null;
  }, [formikValue, options]);

  return {
    error,
    handleChange,
    showError: touched && !!error,
    value,
  };
};

export default useLogic;
export type UseLogic = ReturnType<typeof useLogic>;
