import type { FC } from 'react';
import { PackCategory } from 'graphql/generated';
import NavTab from 'components/NavTab';
import CourseList from 'components/CourseList';
import PacksList from 'components/PacksList';
import DurationSelect from './DurationSelect';
import IntroCourse from './IntroCourse';
import Daily from './Daily';
import RetreatsList from './RetreatsList';
import Retreat from './Retreat';
import RetreatPack from './RetreatPack';
import {
  CardPrimary,
  Container,
  Filters,
  Icon,
  NavTabList,
  OptionsMenuButton,
  SectionDescription,
  SectionHeader,
} from './styles';
import useConnect from './connect';

const PracticePage: FC = () => {
  const {
    additionalMeditationsFilter,
    currentUrl,
    handleChangeAdditionalMeditationsFilter,
    handleOpenCourseOptionsMenu,
    handleOpenPackOptionsMenu,
    handleOpenRetreatOptionsMenu,
    handlePlayDaily,
    handlePlayIntroCourse,
    handlePlayIntroDaily,
    handlePlayCourse,
    isLoading,
    packs,
    retreat,
    selectedPackCategory,
    selectedPackCourses,
    selectedPackDaily,
    selectedPackDescription,
    selectedPackDisplayCourseImages,
    selectedPackId,
    selectedPackImageUrl,
    selectedPackIsLongerMeditations,
    selectedPackSubpacks,
    selectedPackSubtitle,
    selectedPackTitle,
    showBackLink,
    showRetreat,
    showRetreatPack,
    showRetreatsList,
    titleDescription,
  } = useConnect();

  const isIntro = selectedPackCategory === PackCategory.intro;

  const isRetreatRelatedPage =
    showRetreatsList || showRetreat || showRetreatPack;
  const showIntroCourse = !isRetreatRelatedPage && !!isIntro;
  const showDaily =
    !isRetreatRelatedPage &&
    !isIntro &&
    !!selectedPackDaily &&
    !!selectedPackId;
  const showSubpacks =
    !isRetreatRelatedPage &&
    !isIntro &&
    !selectedPackDaily &&
    !!selectedPackSubpacks &&
    selectedPackSubpacks.length > 0;
  const showCoursesList =
    !isRetreatRelatedPage &&
    !isIntro &&
    !!selectedPackId &&
    !!selectedPackCourses &&
    selectedPackCourses.length > 0;
  const showPacks =
    !isRetreatRelatedPage &&
    !isIntro &&
    !showDaily &&
    !showSubpacks &&
    !showCoursesList;
  const showCardPrimary = !isRetreatRelatedPage && !isIntro && !!selectedPackId;
  const showTabs =
    !!retreat &&
    !showRetreat &&
    !showRetreatPack &&
    (showRetreatsList || showPacks);

  return (
    <Container>
      {!showRetreatPack && (
        <SectionHeader
          $showTabs={showTabs}
          showBackLink={showBackLink}
          testId="meditations-header"
          title="Practice"
          titleIconName="lotusLight"
        >
          {showCoursesList && (
            <OptionsMenuButton
              aria-label="open series options"
              onClick={handleOpenPackOptionsMenu}
            >
              <Icon
                aria-hidden="true"
                name="threeDotsVerticalLight"
                size={18}
              />
            </OptionsMenuButton>
          )}
          {showRetreat && (
            <OptionsMenuButton
              aria-label="open retreat options"
              onClick={handleOpenRetreatOptionsMenu}
            >
              <Icon
                aria-hidden="true"
                name="threeDotsVerticalLight"
                size={18}
              />
            </OptionsMenuButton>
          )}
        </SectionHeader>
      )}
      {!showBackLink && (
        <SectionDescription>{titleDescription}</SectionDescription>
      )}
      {showTabs && (
        <NavTabList>
          <NavTab
            aria-controls="practice-panel"
            end
            id="practice-tab"
            isActive={!showRetreatsList}
            to="/practice"
          >
            Series
          </NavTab>
          <NavTab
            aria-controls="retreats-panel"
            id="retreats-tab"
            isActive={showRetreatsList}
            to="/practice/retreats"
          >
            Retreats
          </NavTab>
        </NavTabList>
      )}
      {showCardPrimary && (
        <CardPrimary
          boldSubtitle
          description={selectedPackDescription}
          hideImageLoader={isIntro || !!selectedPackDaily}
          imageUrl={selectedPackImageUrl}
          subtitle={selectedPackSubtitle}
          title={selectedPackTitle}
        />
      )}
      {selectedPackIsLongerMeditations && (
        <Filters>
          <DurationSelect
            duration={additionalMeditationsFilter}
            onChangeDuration={handleChangeAdditionalMeditationsFilter}
          />
        </Filters>
      )}
      {showIntroCourse && (
        <IntroCourse
          chapters={selectedPackSubpacks}
          onOpenOptionsMenu={handleOpenCourseOptionsMenu}
          onPlayCourse={handlePlayIntroCourse}
          onPlayDaily={handlePlayIntroDaily}
        />
      )}
      {showPacks && (
        <PacksList
          ariaLabelledby={showTabs ? 'practice-tab' : undefined}
          currentUrl={currentUrl}
          hideTopBorder
          id="practice-panel"
          isLoading={isLoading}
          packs={packs}
          role={showTabs ? 'tabpanel' : undefined}
        />
      )}
      {showCoursesList && (
        <CourseList
          courses={selectedPackCourses}
          displayCourseImages={selectedPackDisplayCourseImages}
          isLoading={isLoading}
          onOpenOptionsMenu={handleOpenCourseOptionsMenu}
          packId={selectedPackId}
          setTrack={handlePlayCourse}
        />
      )}
      {showSubpacks && (
        <PacksList
          currentUrl={currentUrl}
          hideTopBorder={showCoursesList}
          isLoading={isLoading}
          packs={selectedPackSubpacks}
        />
      )}
      {showDaily && (
        <Daily
          duration={selectedPackDaily.dailyAudio1Length}
          onClick={handlePlayDaily}
          permission={selectedPackDaily?.permission}
        />
      )}
      {showRetreatsList && (
        <RetreatsList
          ariaLabelledby="retreats-tab"
          id="retreats-panel"
          retreats={[retreat!]}
          role="tabpanel"
        />
      )}
      {showRetreat && <Retreat {...retreat!} />}
      {showRetreatPack && <RetreatPack {...retreat!} />}
    </Container>
  );
};

export default PracticePage;
