import { memo } from 'react';
import type { FC } from 'react';
import { Modal, ModalContent } from './styles';
import useConnect from './connect';

const IntroCourseSectionCompleted: FC = () => {
  const { closeModal, endMessage, isOpen } = useConnect();

  return (
    <Modal fullscreenOnMobile open={isOpen} onClose={closeModal}>
      <ModalContent dangerouslySetInnerHTML={{ __html: endMessage }} />
    </Modal>
  );
};

export default memo(IntroCourseSectionCompleted);
