/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const AUDIO_DATA_FRAGMENT = gql`
  fragment AudioData on Audio {
    __typename
    id
    length
    url
  }
`;
