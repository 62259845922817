import styled from 'styled-components';
import { media } from 'styles/mixins';
import colorWithAlpha from 'lib/color';
import DefaultModal from 'components/Modal';
import DefaultButton from 'components/Button';

export const Modal = styled(DefaultModal)`
  box-shadow: ${({ theme }) =>
    theme.name === 'LIGHT' ? 'none' : theme.elevation.web4};

  .MuiPaper-root {
    align-items: center;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    justify-content: center;
    padding: 4rem 2rem;
    text-align: center;
    width: 22rem;
  }

  ${media.phoneLandscape} {
    .MuiPaper-root {
      background-color: ${({ theme }) =>
        colorWithAlpha({
          color:
            theme.name === 'LIGHT'
              ? theme.colors.coreNeutralAurora
              : theme.colors.coreNeutralBlack,
          alpha: 0.85,
        })};
      padding: 0 1rem;
      width: 100%;
    }
  }
`;

export const Overline = styled.p`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreTeal700
      : theme.colors.coreTeal300};
  font-size: 0.5625rem;
  font-weight: inherit;
  letter-spacing: 0.2em;
  line-height: 1.33;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
`;

export const Title = styled.h1`
  color: inherit;
  font-size: 1.3125rem;
  font-weight: inherit;
  letter-spacing: 0.01em;
  line-height: 1.075;
  margin-bottom: 0.3475rem;
  max-width: 80%;

  ${media.phoneLandscape} {
    max-width: 16rem;
  }
`;

export const Subtitle = styled.p`
  color: inherit;
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: 0.01em;
  line-height: 1.33;
  margin-bottom: 1.4806rem;
`;

export const Button = styled(DefaultButton)`
  margin-top: ${({ theme }) => theme.measures.m};
  max-width: 22rem;
`;

export const Cancel = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: inherit;
  letter-spacing: 0.01em;
  line-height: 1.4286;
  margin-top: 1.9825rem;
  outline: none;
  text-decoration-line: underline;
  width: fit-content;
`;
