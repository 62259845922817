import styled from 'styled-components';
import { media } from 'styles/mixins';
import SectionHeader from 'components/DeprecatedSectionHeader';

export const Container = styled.div``;

export const Content = styled.div`
  align-items: stretch;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  margin: 0 auto;
  max-width: 29rem;
  width: 100%;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  ${media.tabletPortrait} {
    text-align: center;
  }

  ${media.phoneLandscape} {
    text-align: center;
  }
`;

export const Header = styled(SectionHeader)`
  ${media.tabletPortrait} {
    display: none;
  }
`;

export const InvalidCode = styled.div`
  color: ${({ theme }) => theme.colors.coreRed500};
  margin: 1rem 0;
  text-align: center;
`;
