import { makeVar } from '@apollo/client';
import { getTrialCheckedDate, setTrialCheckedDate } from 'services/storage';

export type OpenAccess = {
  lastCheckedDates: Record<string, string>;
};

export const openAccessVar = makeVar<OpenAccess>({
  lastCheckedDates: getTrialCheckedDate() || {},
});

export const updateOpenAccessLastCheckedDate = (userId: string) => {
  const now = new Date().toISOString();

  const currentValue = openAccessVar()?.lastCheckedDates || {};
  const newValue = { ...currentValue, [userId]: now };

  openAccessVar({ ...openAccessVar(), lastCheckedDates: newValue });
  setTrialCheckedDate(newValue);
};
