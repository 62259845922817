import { memo } from 'react';
import type { FC } from 'react';
import { Container, AspectRatioBox, Img, Loader } from './styles';
import type { Props } from './types';

const Image: FC<Props> = ({
  alt,
  aspectRatio = 1,
  className,
  hideLoader,
  src,
}) => (
  <Container className={className}>
    <AspectRatioBox $aspectRatio={aspectRatio}>
      {!hideLoader && <Loader />}
      {src && <Img src={src} alt={alt} />}
    </AspectRatioBox>
  </Container>
);

export default memo(Image);
