import styled, { css } from 'styled-components';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import colorWithAlpha from 'lib/color';
import { ButtonPrimary } from 'components/Typography';
import DefaultIcon from 'components/Icon';
import { ContainerProps } from './types';

export const Container = styled.button<ContainerProps>`
  align-items: center;
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.fontButtonEmphasized};
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  min-width: max-content;
  outline: none;
  padding: 0.8rem 1rem;
  position: relative;
  text-decoration: none;
  transition: all 200ms ease;
  transition-property: background-color, color, border-color;
  width: 100%;

  ${ButtonPrimary}

  &:disabled {
    cursor: default;
  }

  ${({ mode, theme }) => {
    if (mode === 'deemphasized' && theme.name === 'DARK') {
      return css`
        background-color: transparent;
        border: 1.5px solid
          ${colorWithAlpha({
            color: theme.colors.coreNeutralWhite,
            alpha: 0.15,
          })};
        color: ${theme.colors.coreNeutralAurora};

        &:hover {
          border: 1.5px solid
            ${colorWithAlpha({
              color: theme.colors.coreNeutralWhite,
              alpha: 0.33,
            })};
        }

        &:focus {
          background-color: ${colorWithAlpha({
            color: theme.colors.coreNeutralWhite,
            alpha: 0.15,
          })};
          box-shadow: 0px 0px 0px 1.5px #0e1529,
            0px 0px 0px 3px rgba(255, 255, 255, 0.15);
        }

        &:active {
          background-color: ${colorWithAlpha({
            color: theme.colors.coreNeutralWhite,
            alpha: 0.15,
          })};
          border: 1.5px solid
            ${colorWithAlpha({
              color: theme.colors.coreNeutralWhite,
              alpha: 0.15,
            })};
          box-shadow: none;
        }

        &:disabled {
          background-color: ${theme.colors.coreNeutral900};
          border: 1.5px solid ${theme.colors.coreNeutral900};
          color: ${colorWithAlpha({
            color: theme.colors.coreNeutralWhite,
            alpha: 0.15,
          })};
        }
      `;
    }

    if (mode === 'deemphasized' && theme.name === 'LIGHT') {
      return css`
        background-color: ${theme.colors.coreNeutralWhite};
        border: 2px solid ${theme.colors.coreNeutral200};
        color: ${theme.colors.coreNeutralAbyss};

        &:hover {
          border: 2px solid ${theme.colors.coreNeutral400};
        }

        &:focus {
          background-color: ${theme.colors.coreBlue700};
          border: 2px solid ${theme.colors.coreNeutral200};
          box-shadow: 0px 0px 0px 2px #f4f4f4, 0px 0px 0px 3.5px #ffffff;
          color: ${theme.colors.coreNeutralWhite};
        }

        &:active {
          background-color: ${theme.colors.coreBlue700};
          border: 2px solid ${theme.colors.coreNeutral200};
          box-shadow: none;
          color: ${theme.colors.coreNeutralWhite};
        }

        &:disabled {
          background-color: ${theme.colors.coreNeutral50};
          border: 2px solid ${theme.colors.coreNeutral50};
          color: ${theme.colors.coreNeutral400};
        }
      `;
    }

    if (mode === 'destructive' && theme.name === 'DARK') {
      return css`
        background-color: transparent;
        border: 1.5px solid ${theme.colors.coreRed300};
        color: ${theme.colors.coreNeutralAurora};

        &:hover {
          border: 1.5px solid ${theme.colors.coreRed700};
        }

        &:focus {
          background-color: ${colorWithAlpha({
            color: theme.colors.coreRed500,
            alpha: 0.25,
          })};
          box-shadow: 0px 0px 0px 2px #0e1529,
            0px 0px 0px 3.5px rgba(255, 79, 83, 0.25);
        }

        &:active {
          background-color: ${colorWithAlpha({
            color: theme.colors.coreRed500,
            alpha: 0.25,
          })};
          box-shadow: none;
        }

        &:disabled {
          background-color: ${theme.colors.coreNeutral900};
          border: 1.5px solid ${theme.colors.coreNeutral900};
        }
      `;
    }

    if (mode === 'destructive' && theme.name === 'LIGHT') {
      return css`
        background-color: ${theme.colors.coreNeutralWhite};
        border: 2px solid ${theme.colors.coreRed700};
        color: ${theme.colors.coreNeutralAbyss};

        &:hover {
          border: 2px solid ${theme.colors.coreRed500};
        }

        &:focus {
          background-color: ${theme.colors.coreRed700};
          border: 2px solid ${theme.colors.coreRed700};
          box-shadow: 0px 0px 0px 2px #f4f4f4,
            0px 0px 0px 3.5px rgba(255, 79, 83, 0.25);
          color: ${theme.colors.coreNeutralWhite};
        }

        &:active {
          background-color: ${theme.colors.coreRed700};
          border: 2px solid ${theme.colors.coreRed700};
          box-shadow: none;
          color: ${theme.colors.coreNeutralWhite};
        }

        &:disabled {
          background-color: ${theme.colors.coreNeutral50};
          border: 2px solid ${theme.colors.coreNeutral50};
          color: ${theme.colors.coreNeutral400};
        }
      `;
    }

    if (theme.name === 'DARK') {
      return css`
        background-color: ${colorWithAlpha({
          color: theme.colors.coreNeutralBlack,
          alpha: 0.33,
        })};
        border: 1.5px solid ${theme.colors.coreTeal500};
        color: ${theme.colors.coreNeutralAurora};

        &:hover {
          background-color: none;
          border: 1.5px solid ${theme.colors.coreTeal700};
        }

        &:focus {
          background-color: ${colorWithAlpha({
            color: theme.colors.coreTeal500,
            alpha: 0.25,
          })};
          border: 1.5px solid ${theme.colors.coreTeal500};
          box-shadow: 0px 0px 0px 2px #0e1529,
            0px 0px 0px 3.5px rgba(0, 208, 189, 0.25);
        }

        &:active {
          background-color: ${colorWithAlpha({
            color: theme.colors.coreTeal500,
            alpha: 0.25,
          })};
          border: 1.5px solid ${theme.colors.coreTeal500};
          box-shadow: none;
        }

        &:disabled {
          background-color: ${theme.colors.coreNeutral900};
          border: 1.5px solid ${theme.colors.coreNeutral900};
          color: ${colorWithAlpha({
            color: theme.colors.coreNeutralWhite,
            alpha: 0.15,
          })};
        }
      `;
    }

    return css`
      background-color: ${theme.colors.coreNeutralWhite};
      border: 2px solid ${theme.colors.coreBlue700};
      color: ${theme.colors.coreBlue700};

      &:hover {
        border: 2px solid ${theme.colors.coreBlue500};
      }

      &:focus {
        background-color: ${theme.colors.coreBlue700};
        box-shadow: 0px 0px 0px 2px #f4f4f4,
          0px 0px 0px 3.5px rgba(0, 96, 103, 0.25);
        color: ${theme.colors.coreNeutralWhite};
      }

      &:active {
        background-color: ${theme.colors.coreBlue700};
        border: 2px solid ${theme.colors.coreBlue900};
        box-shadow: none;
        color: ${theme.colors.coreNeutralWhite};
      }

      &:disabled {
        background-color: ${theme.colors.coreNeutral50};
        border: 2px solid ${theme.colors.coreNeutral50};
        color: ${theme.colors.coreNeutral400};
      }
    `;
  }}
`;

export const LoaderContainer = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const Loader = styled(CircularProgress).attrs<CircularProgressProps>(
  () => ({ size: 20, color: 'inherit' }),
)`
  color: ${({ theme }) => theme.colors.fontButtonEmphasized};
`;

export const Icon = styled(DefaultIcon)`
  display: block;
  margin-right: ${({ theme }) => theme.measures.xs};
`;

export const Label = styled.span<{ $hidden?: boolean }>`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  justify-content: center;
  opacity: ${({ $hidden }) => ($hidden ? '0' : '1')};
`;
