import styled from 'styled-components';
import DefaultButton from 'components/Button';
import { Label } from 'components/Button/styles';
import { media } from 'styles/mixins';

export const Container = styled.div`
  margin: 0 1rem;
`;

export const RemainingTime = styled.p`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreTeal700
      : theme.colors.coreTeal300};
  font-size: 0.5625rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.2em;
  line-height: 1.33;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0.5625rem;
`;

export const Title = styled.h1`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.01em;
  line-height: 1.25;
  text-align: center;
  margin-bottom: 1.25rem;

  ${media.phoneLandscape} {
    font-size: 0.875rem;
    margin-bottom: 0.625rem;
  }

  @media (max-height: 450px) {
    margin-bottom: 0.75rem;
  }

  ${media.phonePortrait} {
    font-size: 1rem;
    margin-bottom: 2.0625rem;
  }
`;

export const Button = styled(DefaultButton)`
  width: 21.4375rem;
  margin: 0 auto;

  ${Label} {
    font-weight: ${({ theme }) => theme.font.weight.semibold};
    font-size: 0.875rem;
  }

  ${media.phoneLandscape} {
    height: 2.1875rem;
  }

  @media (max-height: 450px) {
    height: 2.1875rem;
  }

  ${media.phonePortrait} {
    height: inherit;
  }
`;
