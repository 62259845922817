import styled from 'styled-components';
import { Form } from 'formik';
import DefaultButton from 'components/Button';
import DefaultRadioGroup from 'components/Inputs/RadioGroup';

export const Container = styled(Form)`
  max-width: 40rem;
`;

export const ContinueButton = styled(DefaultButton)`
  &&& {
    margin-top: 0.5rem;
    width: auto;
  }
`;

export const RadioGroup = styled(DefaultRadioGroup)`
  width: 100%;
`;
