import { memo, SVGAttributes } from 'react';
import type { FC } from 'react';

const ButtonPlayIcon: FC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    fill="none"
    viewBox="0 0 65 64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 64c17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32zm-5.479-43.853L44.52 31.145a1 1 0 010 1.707L26.522 43.85A1 1 0 0125 42.997V21a1 1 0 011.521-.853z"
      fill="currentColor"
    />
  </svg>
);

export default memo(ButtonPlayIcon);
