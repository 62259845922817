import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultPublicPageFooter from 'components/PublicPageFooter';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;
  max-width: 1184px;
  min-height: 100vh;
  padding: 5.25rem 2rem 0 2rem;

  ${media.tabletLandscape} {
    padding-top: 3rem;
  }

  ${media.phoneLandscape} {
    padding: 0 1.25rem;
  }
`;

export const Main = styled.main`
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;

  ${media.phoneLandscape} {
    display: block;
  }
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.colors.coreNeutralAbyss};
  display: flex;
  flex-flow: column nowrap;
  margin-top: 2rem;

  ${media.tabletPortrait} {
    margin-top: 1.375rem;
  }

  ${media.phoneLandscape} {
    align-items: center;
    margin-top: 2.3244rem;
  }
`;

export const Title = styled.h1`
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.21;
  margin-bottom: 1.5rem;

  ${media.phoneLandscape} {
    margin-bottom: 0;
    max-width: 16rem;
    text-align: center;
  }
`;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.coreBlue500};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  letter-spacing: 0.1em;
  line-height: 1.5;
  margin-bottom: 0.9rem;
  text-transform: uppercase;

  ${media.phoneLandscape} {
    font-size: 0.9rem;
    margin-top: 1.9375rem;
    text-align: center;
  }
`;

export const Footer = styled(DefaultPublicPageFooter)``;
