import { apolloClient } from 'services/api';
import { GetContentDocument } from 'graphql/generated';
import { normalizePerson } from 'models/Person';

const getPerson = async (personId?: string) => {
  const { data } = await apolloClient.query({
    query: GetContentDocument,
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
  });

  if (!personId || !data?.content?.persons) {
    return undefined;
  }

  const p = data.content.persons.find((v) => v.id === personId);

  return p ? normalizePerson(p) : undefined;
};

export default getPerson;
