import { CreateAppsFlyerLinkV2 } from './types';

export const ONELINK_ALL = 'https://wakingup.onelink.me/eerd';
export const ONELINK_ANDROID = 'https://wakingup.onelink.me/CA3I';
export const ONELINK_IOS = 'https://wakingup.onelink.me/8fmX';

export const AD = { keys: ['af_ad'] };
export const ADSET = { keys: ['af_adset'] };
export const CAMPAIGN = { keys: ['af_campaign', 'utm_campaign', 'incmp'] };
export const CHANNEL = { keys: ['utm_channel', 'in_channel', 'inchnl'] };
export const DEEPLINKVALUE = { keys: ['deep_link_value'] };
export const MEDIASOURCE = { keys: ['af_pid', 'utm_source', 'inmedia'] };

export const APPSFLYER_LINK_DEFAULT: CreateAppsFlyerLinkV2 = {
  all: '',
  android: '',
  ios: '',
  onelinkAll: ONELINK_ALL,
  onelinkAndroid: ONELINK_ANDROID,
  onelinkIos: ONELINK_IOS,
  queryString: '',
};
