import { useCallback, useMemo } from 'react';
import keyBy from 'lodash/keyBy';
import { trackWidgetEvent } from 'services/analytics';
import createPersonsLine from 'lib/createPersonsLine';
import useCarouselControls from 'lib/useCarouselControls';
import { CoursePermission } from 'models/Course';
import { PACK_ICONS, PACK_TYPE_NAME } from 'models/Pack';
import { PlayerAutoplayType, PlayerSource } from 'models/Player';
import type { CardSecondaryItem } from 'components/CardSecondary/types';
import { getCourse } from 'graphql/requests';
import usePacks from 'graphql/hooks/usePacks';
import usePackCourses from 'graphql/hooks/usePackCourses';
import useCourseActions from 'graphql/hooks/useCourseActions';
import { WidgetCarouselImageSize } from 'graphql/generated';
import type { ConnectProps } from './types';

const useConnect = ({ id, imageSize, items }: ConnectProps) => {
  const { packs, loading: loadingPacks } = usePacks();
  const { packCoursesWithPrimaryPackObj, loading: loadingPackCourses } =
    usePackCourses();
  const { play } = useCourseActions();
  const { carouselRef, handleControlClick } = useCarouselControls();

  const loading = loadingPacks || loadingPackCourses;
  const isLargeImage = imageSize === WidgetCarouselImageSize.large;

  const carouselItems = useMemo<CardSecondaryItem[]>(() => {
    if (loading) {
      return [];
    }

    const packsObj = keyBy(packs, ({ hash }) => hash);

    return items.reduce<CardSecondaryItem[]>((t, { caption, hash }, index) => {
      const course = packCoursesWithPrimaryPackObj[hash];

      if (course) {
        let ariaLabel = `Play ${course.title} session`;
        let href;

        if (course.permission === CoursePermission.FORBIDDEN) {
          ariaLabel = 'Go to subscription page';
          href = '/account/subscription';
        } else if (course.isConversation) {
          ariaLabel = `Go to ${course.title} conversation`;
          href = `/theory/conversations/${hash}`;
        }

        t.push({
          ariaLabel,
          caption,
          duration: course.audioLength,
          durationIcon:
            course.permission === CoursePermission.FORBIDDEN
              ? 'lockSimpleFilled'
              : 'playRoundedLight',
          href,
          imageUrl: isLargeImage
            ? course.imageThumbnailsThumbX2
            : course.imageThumbnailsThumbX1,
          itemId: course.hash,
          itemType: course.isConversation ? 'conversation' : 'session',
          overline: PACK_TYPE_NAME[course.packType],
          overlineIcon: PACK_ICONS[course.packType],
          position: index,
          subtitle: createPersonsLine(course.displaySpeakers),
          title: course.title,
        });

        return t;
      }

      const pack = packsObj[hash];

      if (pack) {
        let imageUrl = isLargeImage
          ? pack.imageThumbnailsThumbX2
          : pack.imageThumbnailsThumbX1;

        if (!imageUrl) {
          const courseWithImage = pack.courses.find(
            (c) => !!c.imageThumbnailsThumbX1,
          );
          if (courseWithImage) {
            imageUrl = isLargeImage
              ? courseWithImage?.imageThumbnailsThumbX2
              : courseWithImage?.imageThumbnailsThumbX1;
          }
        }

        t.push({
          ariaLabel: `Go to ${pack.title} series`,
          caption,
          href: `/pack/${pack.hash}`,
          imageUrl: imageUrl || '',
          itemId: pack.hash,
          itemType: 'series',
          overline: PACK_TYPE_NAME[pack.type],
          overlineIcon: PACK_ICONS[pack.type],
          position: index,
          subtitle: createPersonsLine(pack.speakers),
          title: pack.title,
        });

        return t;
      }

      return t;
    }, []);
  }, [isLargeImage, items, loading, packCoursesWithPrimaryPackObj, packs]);

  const handleItemClick = useCallback(
    async (item: CardSecondaryItem) => {
      if (['conversation', 'session'].includes(item.itemType)) {
        const course = await getCourse(item.itemId);

        if (
          !course ||
          course.isConversation ||
          course.permission === CoursePermission.FORBIDDEN
        ) {
          return;
        }

        play({
          autoplayType: PlayerAutoplayType.PACK,
          courseId: item.itemId,
          packId: course.packId,
          source: PlayerSource.HOME_WIDGET,
        });

        trackWidgetEvent(id, {
          category: item.overline?.toLowerCase(),
          content_caption: item.caption,
          content_position: item.position,
          content_type: item.itemType,
          duration: item.duration,
          hash: course.hash,
          name: item.title,
          pack_hash: course.packHash,
          pack_name: course.packTitle,
        });
      } else if (item.itemType === 'series') {
        trackWidgetEvent(id, {
          category: item.overline?.toLowerCase(),
          content_caption: item.caption,
          content_position: item.position,
          content_type: item.itemType,
          name: item.title,
          pack_hash: item.itemId,
          pack_name: item.title,
        });
      }
    },
    [id, play],
  );

  return {
    carouselItems,
    carouselRef,
    handleControlClick,
    handleItemClick,
    loading,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
