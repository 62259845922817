import { StripeFamilyMemberDataFragment } from 'graphql/generated';
import { apolloClient } from 'services/api';

function cacheUpdateFamilyMembers(members: StripeFamilyMemberDataFragment[]) {
  apolloClient.cache.modify({
    fields: {
      familyMembers() {
        return members;
      },
    },
  });
}

export default cacheUpdateFamilyMembers;
