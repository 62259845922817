import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${media.phonePortrait} {
    align-items: center;
  }
`;

export const Image = styled.img<{ $isLoading: boolean }>`
  border-radius: 0.3125rem;
  box-shadow: ${({ theme }) => theme.elevation.web3};
  height: auto;
  object-fit: cover;
  visibility: ${({ $isLoading }) => ($isLoading ? 'hidden' : 'visible')};
  width: 23.8819rem;

  ${media.tabletPortrait} {
    width: 20rem;
  }

  ${media.phoneLandscape} {
    width: 16rem;
  }

  ${media.phonePortrait} {
    margin-top: 3.2819rem;
    width: 100%;
  }
`;

export const Caption = styled.p`
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.2em;
  line-height: 1.33;
  margin-top: 1.4212rem;
  opacity: 0.2;
  text-transform: uppercase;

  ${media.tabletPortrait} {
    font-size: 0.7rem;
    margin-top: 1.6rem;
  }

  ${media.phonePortrait} {
    margin-top: 1rem;
  }
`;
