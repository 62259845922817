import { useMemo, useCallback } from 'react';
import { AppConfig } from 'config';
import { trackEvent } from 'services/analytics';
import { ModalType } from 'graphql/reactive';
import useCopyInputContent from 'lib/useCopyInputContent';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import useMe from 'graphql/hooks/useMe';

const useConnect = () => {
  const { isOpen, payload } = useModal(ModalType.GIVE_FREE_TRIAL);
  const { closeModal } = useModalActions();
  const { ref, copy } = useCopyInputContent();
  const { profile } = useMe();

  const source = payload?.source;
  const token = profile.shareCode || undefined;

  const link = useMemo(
    () => (token ? `${AppConfig.dynamicBaseUrl}/shareOpenAccess/${token}` : ''),
    [token],
  );

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleCopyLink = useCallback(() => {
    copy();
    trackEvent('Share App 30 days', { source, token });
  }, [copy, source, token]);

  return {
    handleClose,
    handleCopyLink,
    inviteRef: ref,
    isOpen,
    link,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
