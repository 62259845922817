import { apolloClient } from 'services/api';
import notEmpty from 'lib/notEmpty';
import {
  normalizeLifetimePlan,
  normalizeSubscriptionPlan,
} from 'models/SubscriptionPlan';
import type { QueryOptions } from 'types/utils';
import {
  GetLifetimePriceDocument,
  GetLifetimePriceQuery,
  GetLifetimePriceQueryVariables,
  GetSubscriptionPlansDocument,
  GetSubscriptionPlansQuery,
  GetSubscriptionPlansQueryVariables,
} from '../generated';

const getSubscriptionPlans = async (
  planOptions: QueryOptions<
    GetSubscriptionPlansQueryVariables,
    GetSubscriptionPlansQuery
  > = {},
  lifetimeOptions: QueryOptions<
    GetLifetimePriceQueryVariables,
    GetLifetimePriceQuery
  > = {},
) => {
  const { data: subscriptionPlansData } = await apolloClient.query({
    query: GetSubscriptionPlansDocument,
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    ...planOptions,
  });

  const { data: lifetimePriceData } = await apolloClient.query({
    query: GetLifetimePriceDocument,
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    ...lifetimeOptions,
  });

  const plans = subscriptionPlansData?.subscriptionPlans
    ? subscriptionPlansData.subscriptionPlans
        .filter(notEmpty)
        .map(normalizeSubscriptionPlan)
    : [];
  if (lifetimePriceData?.lifetimePrice) {
    plans.push(normalizeLifetimePlan(lifetimePriceData.lifetimePrice));
  }

  return plans;
};

export default getSubscriptionPlans;
