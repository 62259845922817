import { memo, type FC } from 'react';
import PaymentMethod from './PaymentMethod';
import Form from './Form';
import { Container, Content, SectionHeader, Title } from './styles';
import useConnect from './connect';

const ChangePaymentCard: FC = () => {
  const { email, firstName, lastName, isLoading, stripePaymentMethod, upUrl } =
    useConnect();

  return (
    <Container>
      <SectionHeader
        testId="info-header"
        title="Change Payment Card"
        backHref={upUrl}
      />
      <Content>
        <Title>Change Payment Card</Title>
        {stripePaymentMethod && <PaymentMethod {...stripePaymentMethod} />}
        <Form
          email={email}
          firstName={firstName}
          isLoading={isLoading}
          lastName={lastName}
        />
      </Content>
    </Container>
  );
};

export default memo(ChangePaymentCard);
