import kebabCase from 'lodash/kebabCase';
import isBefore from 'date-fns/isBefore';
import endOfDay from 'date-fns/endOfDay';
import type { Props as IconProps } from 'components/Icon/types';
import getDateFromIsoString from 'lib/getDateFromIsoString';
import type { Course } from 'models/Course';
import type { Daily } from 'models/Daily';
import {
  normalizePersonInfo,
  type Person,
  type PersonInfo,
} from 'models/Person';
import {
  PackCategory,
  PackType,
  type CourseInfoDataFragment,
  type PackDataFragment,
} from 'graphql/generated';
import { getPack } from 'graphql/requests';

export const DAILY_MEDITATION_PACK_ID = '123456789';
export const DAILY_MEDITATION_PACK_HASH = 'dailyhash';

export enum PackPermission {
  UNLOCKED = 'unlocked',
  LOCKED = 'locked',
  FORBIDDEN = 'forbidden',
}
export enum AdditionalMeditationsFilter {
  SHOW_ALL = 'Show all',
  THIRTY = '30-Minute',
  FORTYFIVE = '45-Minute',
  SIXTY = '60-Minute',
}

export const PACK_TYPE_NAME: Record<PackType, string> = {
  [PackType.meditation]: 'Practice',
  [PackType.lesson]: 'Theory',
  [PackType.life]: 'Life',
};

export const PACK_ICONS: Record<PackType, IconProps['name']> = {
  [PackType.meditation]: 'lotusLight',
  [PackType.lesson]: 'bookOpenLight',
  [PackType.life]: 'lifeLight',
};

const normalizeCourseInfo = (courseInfo: CourseInfoDataFragment) => ({
  courseHash: courseInfo.course_hash || '',
  id: courseInfo.id || '',
  isPrimaryPack: courseInfo.is_primary_pack ?? false,
});

export type CourseInfo = ReturnType<typeof normalizeCourseInfo>;

export const normalizePack = (pack: PackDataFragment) => {
  const now = new Date();
  const isNewUntil = (pack.is_new_until as string) || '';
  const dtIsNewUntil = getDateFromIsoString(isNewUntil);
  const isNew = !!dtIsNewUntil && isBefore(now, endOfDay(dtIsNewUntil));
  const hasNewCoursesUntil = (pack.has_new_courses_until as string) || '';
  const dtHasNewCourses = getDateFromIsoString(hasNewCoursesUntil);
  const hasNewCourses =
    !!dtHasNewCourses && isBefore(now, endOfDay(dtHasNewCourses));

  let isNewText = '';
  if (isNew) isNewText = 'New Series';
  else if (hasNewCourses) isNewText = 'New Sessions';

  const title = pack.title || '';
  const type = pack.type ?? PackType.meditation;

  return {
    active: pack.active ?? false,
    artists: [] as Person[],
    autoPlay: pack.auto_play ?? false,
    backgroundColor: '',
    category: pack.category_v2 || PackCategory.standard,
    courses: [] as Course[],
    coursesInfo: pack.courses_info?.map(normalizeCourseInfo) || [],
    dailies: [] as Daily[],
    description: pack.description || '',
    displayBylines: pack.display_bylines ?? false,
    displayContentCount: pack.display_content_count ?? false,
    displayCourseImages: pack.display_course_images ?? false,
    displayTitle: pack.display_title ?? false,
    endMessage: pack.end_message || '',
    hasFreeCourses: false,
    hash: pack.hash || '',
    hasNewCourses,
    hasNewCoursesUntil,
    id: pack.id,
    imageThumbnailsThumbX1: pack.image?.thumbnails?.thumb_x1 || '',
    imageThumbnailsThumbX2: pack.image?.thumbnails?.thumb_x2 || '',
    imageUrl: pack.image?.url || '',
    info: pack.info || '',
    isDaily: false,
    isLongerMeditations: pack.id === '7',
    isNew,
    isNewText,
    isNewUntil,
    nSubpacks: 0,
    number: pack.pack_number ?? 1000,
    overwriteCourseImage: pack.overwrite_course_image ?? false,
    parentPackId: pack.parent_pack_id || '',
    permission: PackPermission.UNLOCKED,
    persons: [] as Person[],
    personsInfo: pack.persons_info?.map(normalizePersonInfo) || [],
    progress: 0,
    publishDate: (pack.publish_date as string) || '',
    retreatId: '',
    retreatTitle: '',
    slug: kebabCase(title),
    speakers: [] as Person[],
    subtitle: pack.subtitle || '',
    summary: pack.summary || '',
    teachers: [] as Person[],
    teachersAndSpeakers: [] as Person[],
    title,
    type,
    typename: 'Pack',
    urlBase: `/${type === PackType.lesson ? 'theory' : 'practice'}`,
  };
};

export type Pack = ReturnType<typeof normalizePack>;

export const normalizeDailyPack = (daily: Daily): Pack => ({
  active: true,
  artists: [] as Person[],
  autoPlay: false,
  backgroundColor: daily.imageBackgroundColor || '',
  category: PackCategory.standard,
  courses: [] as Course[],
  coursesInfo: [] as CourseInfo[],
  dailies: [daily],
  description: '',
  displayBylines: true,
  displayContentCount: false,
  displayCourseImages: false,
  displayTitle: true,
  endMessage: '',
  hasFreeCourses: false,
  hash: DAILY_MEDITATION_PACK_HASH,
  hasNewCourses: false,
  hasNewCoursesUntil: '',
  id: DAILY_MEDITATION_PACK_ID,
  imageThumbnailsThumbX1: daily.imageThumbnailsThumbX1 || '',
  imageThumbnailsThumbX2: daily.imageThumbnailsThumbX2 || '',
  imageUrl: daily.imageUrl || '',
  info: '',
  isDaily: true,
  isLongerMeditations: false,
  isNew: false,
  isNewText: '',
  isNewUntil: '',
  nSubpacks: 0,
  number: 0,
  overwriteCourseImage: false,
  parentPackId: '',
  permission: PackPermission.UNLOCKED,
  persons: [] as Person[],
  personsInfo: [] as PersonInfo[],
  progress: 0,
  publishDate: '',
  retreatId: '',
  retreatTitle: '',
  slug: kebabCase('Daily Meditation'),
  speakers: [] as Person[],
  subtitle: 'Refreshes daily',
  summary: '',
  teachers: [] as Person[],
  teachersAndSpeakers: [] as Person[],
  title: 'Daily Meditation',
  type: PackType.meditation,
  typename: 'Pack',
  urlBase: '/practice',
});

export const getPackPermission = (
  isSubscribed: boolean,
  hasFreeCourses: boolean,
) => {
  if (!isSubscribed && !hasFreeCourses) {
    return PackPermission.FORBIDDEN;
  }
  return PackPermission.UNLOCKED;
};

export const getDailyPackPermission = (
  isSubscribed: boolean,
  isDailyUnlocked: boolean,
) => {
  if (!isSubscribed) {
    return PackPermission.FORBIDDEN;
  }
  if (!isDailyUnlocked) {
    return PackPermission.LOCKED;
  }
  return PackPermission.UNLOCKED;
};

export const getLastIntroCourseId = (
  packs: PackDataFragment[] | null,
  introPackId: string,
) => {
  if (!packs || !introPackId) return null;

  const lastIntroPack = packs
    .filter((p) => p?.parent_pack_id === introPackId)
    .pop();
  const { courses_info } = lastIntroPack || {};

  return courses_info && courses_info.length > 0
    ? courses_info[courses_info.length - 1].course_hash
    : null;
};

export const getPackUrl = async (pack: Pack): Promise<string> => {
  if (pack) {
    const { title, parentPackId, type } = pack;
    const parentPack = await getPack(parentPackId);
    if (parentPack) {
      return `${await getPackUrl(parentPack)}/${kebabCase(title)}`;
    }

    let packType = 'practice';
    if (type === PackType.lesson) packType = 'theory';
    else if (type === PackType.life) packType = 'life';

    return `/${packType}/${kebabCase(title)}`;
  }
  return '';
};
