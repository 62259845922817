import { apolloClient } from 'services/api';
import { RedeemOpenAccessDocument } from '../generated';
import {
  GET_CONTENT,
  GET_DAILY,
  GET_FEATURE_CONTENT,
  GET_REMOTE_CONFIG,
} from '../queries';

const redeemOpenAccess = async () => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: RedeemOpenAccessDocument,
      refetchQueries: [
        { query: GET_CONTENT },
        { query: GET_DAILY },
        { query: GET_FEATURE_CONTENT },
        { query: GET_REMOTE_CONFIG },
      ],
    });

    return data?.redeemOpenAccess || null;
  } catch {
    return null;
  }
};

export default redeemOpenAccess;
