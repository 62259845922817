import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { up } from 'lib/paths';
import useFamilyMembers from 'graphql/hooks/useFamilyMembers';
import useFamilyMembersActions from 'graphql/hooks/useFamilyMembersActions';
import useModalActions from 'graphql/hooks/useModalActions';

const useConnect = () => {
  const { familyMembers, loading } = useFamilyMembers();
  const { removeMember, addMember } = useFamilyMembersActions();
  const { closeModal, openAddFamilyMemberModal } = useModalActions();
  const { pathname } = useLocation();

  const upUrl = useMemo(() => up(pathname), [pathname]);

  return {
    addMember,
    closeModal,
    familyMembers,
    loading,
    openAddFamilyMemberModal,
    removeMember,
    upUrl,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
