import styled from 'styled-components';
import MaterialSlider from '@mui/material/Slider';
import colorWithAlpha from 'lib/color';
import { Overline } from 'components/Typography';

export const Container = styled.div`
  flex: 1 1 auto;
`;

export const Label = styled.label`
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  white-space: nowrap;
`;

export const Slider = styled(MaterialSlider)`
  &.MuiSlider-root {
    color: ${({ theme }) => theme.colors.coreNeutralWhite};
    display: block;
    padding: 5px 0;
  }

  & .MuiSlider-rail,
  & .MuiSlider-track,
  & .MuiSlider-thumb {
    background-color: ${({ theme }) => theme.colors.coreNeutralWhite};
    border: none;
  }

  & .MuiSlider-rail,
  & .MuiSlider-track {
    border-radius: 4px;
    height: 4px;
  }

  & .MuiSlider-thumb {
    height: 12px;
    width: 12px;

    &.Mui-focusVisible,
    &:hover {
      box-shadow: 0px 0px 0px 8px
        ${({ theme }) =>
          colorWithAlpha({ color: theme.colors.fontEmphasized, alpha: 0.35 })};
    }

    &.Mui-active {
      box-shadow: 0px 0px 0px 12px
        ${({ theme }) =>
          colorWithAlpha({ color: theme.colors.fontEmphasized, alpha: 0.35 })};
    }

    &:focus {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }
`;

export const Time = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  line-height: 1;
  white-space: nowrap;
`;

export const TimePlayed = styled(Overline)`
  user-select: none;
`;

export const TimePending = styled(Overline)`
  user-select: none;
`;
