import { captureMessage } from '@sentry/react';
import { DevConfig, isProduction } from 'config';

declare global {
  interface Window {
    gtag: any;
    dataLayer: any[];
  }
}

let didWarn = false;

function gtag() {
  if (isProduction) {
    try {
      if (typeof window !== 'undefined') {
        if (typeof window.gtag === 'function') {
          // eslint-disable-next-line prefer-rest-params
          window.gtag.apply(null, arguments as any);
        } else if (Array.isArray(window.dataLayer)) {
          // eslint-disable-next-line prefer-rest-params
          window.dataLayer.push(arguments);
        } else if (
          typeof process !== 'undefined' &&
          process.env.NODE_ENV !== 'production' &&
          !didWarn
        ) {
          didWarn = true;
          // eslint-disable-next-line no-console
          console.warn(
            '[lib/gtag] Google Tag Manager was not found on your site. Neither "gtag()" or "dataLayer[]" could be located on the "window". If you are not using Google Tag Manager in dev you can ignore this warning. Otherwise, see the Google Tag Manager dev guide for examples: https://developers.google.com/tag-manager/devguide',
          );
        }
      }
    } catch (error) {
      captureMessage(error);
    }
  }
}

export const registerGAUser = ({ id, uuid }: { id: string; uuid: string }) => {
  if (isProduction) {
    try {
      // @ts-ignore
      gtag('set', 'user_id', uuid);

      // @ts-ignore
      gtag('set', 'user_properties', {
        user_id: id,
        user_uuid: uuid,
      });
    } catch (error) {
      captureMessage(
        `Unable to set GA UUID: ${id} (ERROR: ${error?.message || 'unknown'})`,
      );
    }
  }
};

export const trackGAEvent = (
  type: string,
  name: string,
  data: Record<string, any>,
) => {
  if (isProduction) {
    try {
      const eventData = { ...data, platform: 'web' };

      // @ts-ignore
      gtag(type, name, eventData);

      if (DevConfig.gaLogging) {
        // eslint-disable-next-line no-console
        console.log('GA', type, name, eventData);
      }
    } catch (error) {
      captureMessage(
        `Unable to track GA event (ERROR: ${error?.message || 'unknown'})`,
      );
    }
  }
};
