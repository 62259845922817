import { useCallback, useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { trackEvent, trackMediaEvent } from 'services/analytics';
import {
  DailyMeditationDuration,
  DAILY_DURATIONS,
} from 'models/DailyMeditationDuration';
import { BookmarkType } from 'graphql/generated';
import {
  dailyPlayerVar,
  PlayerType,
  playerTypeVar,
  resetOptionsMenuVar,
  resetPlayerTypeVar,
  updateDailyPlayerVar,
} from 'graphql/reactive';
import {
  getDailyAutoplayTrackList,
  updatePlayerSettings,
  updateSettings,
} from 'graphql/requests';
import useDaily from 'graphql/hooks/useDaily';
import useSharedDaily from 'graphql/hooks/useSharedDaily';
import useBookmark from 'graphql/hooks/useBookmark';
import useSettings from 'graphql/hooks/useSettings';
import useMeActions from 'graphql/hooks/useMeActions';
import useDailyActions from 'graphql/hooks/useDailyActions';

const useConnect = () => {
  const {
    autoplayType,
    hash: dailyHash = '',
    duration: dailyDuration,
    isMinimized,
    queryString,
    sharedHash,
    source,
  } = useReactiveVar(dailyPlayerVar);
  const { daily: standardDaily, loading: isLoadingStandardDaily } = useDaily({
    skip: !!dailyHash || !!sharedHash,
  });
  const { sharedDaily, loading: isLoadingSharedDaily } = useSharedDaily({
    skip: !!dailyHash || !sharedHash,
    variables: { shared_hash: sharedHash || '' },
  });
  const { bookmark: bookmarkDaily, loading: isLoadingBookmarks } = useBookmark(
    dailyHash,
    BookmarkType.daily,
    { skip: !dailyHash },
  );
  const {
    loading: isLoadingSettings,
    settings: { recentlyPlayed },
  } = useSettings();
  const { addProgressStart } = useMeActions();
  const { markAsListened } = useDailyActions();

  const completedRef = useRef<boolean>(false);

  let daily;
  if (bookmarkDaily) {
    daily = bookmarkDaily;
  } else if (sharedDaily) {
    daily = sharedDaily;
  } else if (standardDaily) {
    daily = standardDaily;
  }

  const {
    dailyAudio1Url = '',
    dailyAudio2Url = '',
    dailyAudioIsGuest = false,
    dailyAudioSubtitle,
    hash = '',
    id = '',
    imageThumbnailsThumbX1 = '',
    imageUrl = '',
    isSpecial = false,
    title = '',
  } = daily || {};
  const audioUrl =
    dailyDuration === DailyMeditationDuration.MIN_20
      ? dailyAudio2Url
      : dailyAudio1Url;
  const subtitle =
    dailyAudioIsGuest && dailyAudioSubtitle ? dailyAudioSubtitle : '';

  const startTime =
    recentlyPlayed.find((rp) => rp.course_hash === hash)?.last_known_location ||
    0;

  useEffect(() => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: 'Daily Meditation',
        artist: 'Waking Up',
        artwork: [
          {
            src: imageThumbnailsThumbX1,
            sizes: '512x512',
            type: 'image/jpg',
          },
        ],
      });
    }
  }, [imageThumbnailsThumbX1]);

  const handleClosePlayer = useCallback(() => {
    resetOptionsMenuVar();
    resetPlayerTypeVar();
  }, []);

  const handleToggleDisplayStatus = useCallback(() => {
    resetOptionsMenuVar();
    updateDailyPlayerVar({ isMinimized: !dailyPlayerVar().isMinimized });
  }, []);

  const handleChangeDuration = useCallback(
    (newDuration: DailyMeditationDuration) => {
      updateDailyPlayerVar({ duration: newDuration });

      trackEvent('Toggle Daily Duration', {
        value: DAILY_DURATIONS[newDuration],
      });
    },
    [],
  );

  const handleInit = useCallback(
    async ({ currentTime, duration }: HTMLAudioElement) => {
      const code = uuidv4();

      await updateSettings(
        {
          player: {
            code,
            courseId: hash,
            queryString,
            sharedDailyHash: sharedHash,
            source,
          },
        },
        true,
      );

      await addProgressStart({
        courseHash: hash,
        mediaDuration: Math.ceil(duration),
      });

      trackMediaEvent('Media Start', hash, {
        duration,
        position: currentTime,
      });
    },
    [addProgressStart, hash, queryString, sharedHash, source],
  );

  const handleComplete = useCallback(
    ({ currentTime, duration }: HTMLAudioElement) => {
      completedRef.current = true;

      const percentComplete = Math.floor(100 * (currentTime / duration));

      trackMediaEvent('Media Complete', hash, {
        duration,
        percentComplete,
        position: currentTime,
        quartileComplete: 4,
      });

      const { duration: dDuration } = dailyPlayerVar();
      markAsListened(dDuration, sharedHash);
    },
    [markAsListened, hash, sharedHash],
  );

  const handleEnd = useCallback(async () => {
    const autoplayTrackList = await getDailyAutoplayTrackList();

    if (autoplayTrackList) {
      const index = autoplayTrackList.indexOf(hash);

      if (index >= 0 && index < autoplayTrackList.length - 1) {
        if (!document.hidden) {
          playerTypeVar(null);
        }

        setTimeout(() => {
          updateDailyPlayerVar({
            hash: autoplayTrackList[index + 1],
            isMinimized: false,
          });
          playerTypeVar(PlayerType.DAILY);
        }, 750);
      } else {
        resetPlayerTypeVar();
      }
    } else {
      resetPlayerTypeVar();
    }
  }, [hash]);

  const handleClose = useCallback(
    async ({ currentTime, duration }: HTMLAudioElement) => {
      const percentComplete = Math.floor(100 * (currentTime / duration));
      const quartileComplete = Math.floor(percentComplete / 25) + 1;

      if (!completedRef.current) {
        trackMediaEvent('Media Close', hash, {
          duration,
          percentComplete,
          position: currentTime,
          quartileComplete,
        });
      }

      updatePlayerSettings(hash, currentTime);
    },
    [hash],
  );

  return {
    audioUrl,
    autoplayType,
    dailyDuration,
    handleChangeDuration,
    handleClose,
    handleClosePlayer,
    handleComplete,
    handleEnd,
    handleInit,
    handleToggleDisplayStatus,
    hash,
    id,
    imageThumbnailsThumbX1,
    imageUrl,
    isLoadingData:
      isLoadingStandardDaily ||
      isLoadingSharedDaily ||
      isLoadingSettings ||
      isLoadingBookmarks,
    isMinimized,
    isSpecial,
    sharedHash,
    startTime,
    subtitle,
    title,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
