import styled, { css } from 'styled-components';
import { Radio as MUIRadio } from '@mui/material';
import colorWithAlpha from 'lib/color';
import { Props } from './types';

export const Radio = styled(MUIRadio)`
  &.MuiRadio-root {
    color: ${({ theme }) => theme.colors.fontBase};

    &.Mui-checked {
      color: ${({ theme }) => theme.colors.fontEmphasized};
    }
  }

  .MuiSvgIcon-root {
    height: 1.4286rem;
    width: 1.4286rem;
  }
`;

export const Container = styled.div<{ $isSelected: Props['isSelected'] }>`
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${({ theme }) =>
        colorWithAlpha({
          color:
            theme.name === 'LIGHT'
              ? theme.colors.coreNeutralBlack
              : theme.colors.coreNeutralWhite,
          alpha: 0.06,
        })};
    `};

  align-items: center;
  border-radius: ${({ theme }) => theme.radius.sm};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0.8rem;

  :hover {
    background-color: hsla(186, 100%, 90%, 0.06);
  }
`;
