/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const ABOUTS = gql`
  query Abouts {
    abouts {
      id
      body
    }
  }
`;
