import styled from 'styled-components';
import { media } from 'styles/mixins';
import colorWithAlpha from 'lib/color';
import DefaultModal from 'components/Modal';
import { Display } from 'components/Typography';
import Button from 'components/Button';
import DefaultButtonSecondary from 'components/ButtonSecondary';

export const Modal = styled(DefaultModal)`
  .MuiPaper-root {
    align-items: center;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    justify-content: space-evenly;
    padding: 3rem 2rem;
    text-align: center;
    width: 24rem;
  }

  ${media.phoneLandscape} {
    .MuiPaper-root {
      background-color: ${({ theme }) =>
        colorWithAlpha({
          color:
            theme.name === 'LIGHT'
              ? theme.colors.coreNeutralAurora
              : theme.colors.coreNeutralBlack,
          alpha: 0.85,
        })};
      padding: 0 1rem;
      margin: 0 auto;
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  max-width: 24rem;
`;

export const Description = styled(Display).attrs(() => ({ $size: 'small' }))`
  padding-bottom: 2rem;

  ${media.phoneLandscape} {
    padding-bottom: 0;
  }
`;

export const Buttons = styled(Content)`
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: 24rem;
  width: 100%;
`;

export const ButtonPrimary = styled(Button)`
  width: 100%;
`;

export const ButtonSecondary = styled(DefaultButtonSecondary)`
  margin-top: 1.5rem;
  text-transform: uppercase;
`;
