import { memo, type FC } from 'react';
import { Audio } from './styles';
import useConnect from './connect';

const BackgroundAudio: FC = () => {
  const { audioRef, audioUrl, backgroundAudioEnabled } = useConnect();

  return (
    <Audio
      autoPlay={backgroundAudioEnabled}
      preload="auto"
      ref={audioRef}
      src={audioUrl}
    />
  );
};

export default memo(BackgroundAudio);
