import { AnimatePresence } from 'framer-motion';
import type { FC } from 'react';
import Option from './Option';
import { Buttons, Container, Error, Label, Loader } from './styles';
import type { Props } from './types';
import useLogic from './logic';

const PriceSelector: FC<Props> = ({
  className,
  isLoading,
  label,
  name,
  prices,
}) => {
  const { error, touched, handleSelectPrice, value } = useLogic({ name });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container className={className}>
      <Label>{label}</Label>
      <Buttons aria-label="choose-a-cost" role="radiogroup">
        {prices.map((opt) => (
          <Option
            key={opt.price}
            description={opt.description}
            isSelected={opt.price === value}
            onClick={handleSelectPrice}
            price={opt.price}
          />
        ))}
      </Buttons>
      <AnimatePresence initial={false}>
        {touched && error && <Error>{error}</Error>}
      </AnimatePresence>
    </Container>
  );
};

export default PriceSelector;
