import DefaultTextField from 'components/Inputs/TextField';
import HeaderLink from 'components/Links/HeaderLink';
import DefaultHeadPickerLink from 'components/HeadPickerLink';
import DefaultSectionHeader from 'components/DeprecatedSectionHeader';
import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Container = styled.div``;

export const SectionHeader = styled(DefaultSectionHeader)`
  ${media.tabletPortrait} {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  width: 100%;

  ${media.tabletPortrait} {
    margin-top: ${({ theme }) => theme.measures.x};
  }

  ${media.phoneLandscape} {
    margin-top: ${({ theme }) => theme.measures.m};
  }
`;

export const HeadPickerLink = styled(DefaultHeadPickerLink)`
  margin-bottom: ${({ theme }) => theme.measures.xl};
`;

export const Form = styled.form.attrs(() => ({ noValidate: true }))`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundBase};
  display: flex;
  flex-direction: column;
  width: 23.4375rem;
`;

export const TextField = styled(DefaultTextField)`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.measures.l};
  }
`;

export const SaveLinkContainer = styled.div`
  display: none;

  ${media.tabletPortrait} {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
`;

export const SaveLink = styled(HeaderLink)`
  display: inline-block;
  font-size: 1rem;
  margin-top: 3rem;
  text-align: center;
`;
