import styled from 'styled-components';

export const Container = styled.div<{
  $hasLabel?: boolean;
  $readOnly?: boolean;
}>`
  background: ${({ theme, $readOnly }) =>
    $readOnly ? '#e1e1e1' : theme.colors.coreNeutralWhite};
  border-radius: ${({ theme }) => theme.radius.sm};
  line-height: 1.2rem;
  padding: ${({ $hasLabel }) => ($hasLabel ? `0.8rem 0` : '0')};
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.fontBase};
  display: block;
  font-size: 0.9375rem;
  font-weight: bold;
  margin: 0 1.4rem 0.25rem 1.4rem;
  pointer-events: none;
`;
