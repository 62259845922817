import { useCallback, useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { capitalize } from 'lodash';
import { trackMediaEvent } from 'services/analytics';
import createPersonsLine from 'lib/createPersonsLine';
import getCourseProgressPercent from 'lib/getCourseProgressPercent';
import { formatDurationShort } from 'lib/formatTime';
import { PackCategory } from 'graphql/generated';
import { CourseStatus, CourseType } from 'models/Course';
import { PlayerSource } from 'models/Player';
import {
  previewPlayerVar,
  resetPlayerTypeVar,
  updatePreviewPlayerVar,
} from 'graphql/reactive';
import { updateSettings } from 'graphql/requests';
import usePlayerActions from 'graphql/hooks/usePlayerActions';
import useCourse from 'graphql/hooks/useCourse';
import useSettings from 'graphql/hooks/useSettings';
import useMe from 'graphql/hooks/useMe';

const useConnect = () => {
  const { hash, packId, queryString, showFinalScreen, source } =
    useReactiveVar(previewPlayerVar);
  const { course, loading: isLoadingCourse } = useCourse(hash, packId);
  const {
    loading: isLoadingSettings,
    settings: { recentlyPlayed },
  } = useSettings();
  const { isSubscribed } = useMe();
  const { openCoursePlayer } = usePlayerActions();
  const navigate = useNavigate();

  const completedRef = useRef<boolean>(false);

  const {
    audioLength = 0,
    courseType = CourseType.LESSON,
    displaySpeakers = [],
    imageThumbnailsThumbX1 = '',
    imageUrl = '',
    isFree = false,
    isIntroCourse = false,
    packCategory,
    packOverwriteCourseImage = false,
    packImageThumbnailsThumbX1 = '',
    packImageUrl = '',
    packTitle = '',
    previews = [],
    subtitle: previewSubtitle = '',
    status = CourseStatus.UNSTARTED,
    title: previewTitle = '',
  } = course || {};
  const correctImageUrl = packOverwriteCourseImage ? packImageUrl : imageUrl;
  const correctImageThumbnailsThumbX1 = packOverwriteCourseImage
    ? packImageThumbnailsThumbX1
    : imageThumbnailsThumbX1;
  const audioId = previews[0]?.id || '';
  const audioUrl = previews[0]?.url || '';
  const title = `${previewTitle} · Preview`;
  const subtitle = createPersonsLine(displaySpeakers, previewSubtitle);
  const trackList = [
    ...previews.map((v) => ({
      duration: formatDurationShort(v.length),
      pretitle: 'Preview',
      title,
    })),
    {
      duration: formatDurationShort(audioLength),
      hash,
      pretitle: `Full ${
        packCategory === PackCategory.conversations
          ? 'Conversation'
          : capitalize(courseType)
      }`,
      progress: getCourseProgressPercent({
        hash,
        audioLength,
        recentlyPlayed,
        status,
      }),
      title,
    },
  ];

  useEffect(() => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: `${title} (Preview)`,
        artist: packTitle ? 'Series' : undefined,
        album: packTitle,
        artwork: [
          {
            src: correctImageThumbnailsThumbX1,
            sizes: '512x512',
            type: 'image/jpg',
          },
        ],
      });
    }
  }, [correctImageThumbnailsThumbX1, packTitle, title]);

  const handlePlayFullCourse = useCallback(
    (courseHash: string) => {
      trackMediaEvent('Media Request Full Length', courseHash);

      resetPlayerTypeVar();

      if (isFree || isSubscribed) {
        setTimeout(
          () =>
            openCoursePlayer({
              hash: courseHash,
              source: PlayerSource.MEDIA_PREVIEW,
            }),
          750,
        );
      } else {
        navigate('/account/subscription');
      }
    },
    [isFree, isSubscribed, navigate, openCoursePlayer],
  );

  const handleClosePlayer = useCallback(() => {
    resetPlayerTypeVar();
  }, []);

  const handleInit = useCallback(
    async ({ currentTime, duration }: HTMLAudioElement) => {
      const code = uuidv4();

      await updateSettings(
        { player: { code, courseId: hash, queryString, source } },
        true,
      );

      trackMediaEvent('Media Start', hash, {
        duration,
        position: currentTime,
        preview_id: audioId,
      });
    },
    [audioId, hash, queryString, source],
  );

  const handleComplete = useCallback(
    ({ currentTime, duration }: HTMLAudioElement) => {
      completedRef.current = true;

      const percentComplete = Math.floor(100 * (currentTime / duration));

      trackMediaEvent('Media Complete', hash, {
        duration,
        percentComplete,
        position: currentTime,
        preview_id: audioId,
        quartileComplete: 4,
      });
    },
    [audioId, hash],
  );

  const handleEnd = useCallback(async () => {
    updatePreviewPlayerVar({ showFinalScreen: true });
  }, []);

  const handleClose = useCallback(
    async ({ currentTime, duration }: HTMLAudioElement) => {
      const percentComplete = Math.floor(100 * (currentTime / duration));
      const quartileComplete = Math.floor(percentComplete / 25) + 1;

      if (!completedRef.current) {
        trackMediaEvent('Media Close', hash, {
          duration,
          percentComplete,
          position: currentTime,
          preview_id: audioId,
          quartileComplete,
        });
      }

      await updateSettings({ player: null }, true);
    },
    [audioId, hash],
  );

  return {
    audioUrl,
    handleClose,
    handleClosePlayer,
    handleComplete,
    handleEnd,
    handleInit,
    handlePlayFullCourse,
    hash,
    imageThumbnailsThumbX1: correctImageThumbnailsThumbX1,
    imageUrl: correctImageUrl,
    isIntro: isIntroCourse,
    isLoadingData: isLoadingCourse || isLoadingSettings,
    packId,
    showFinalScreen,
    subtitle,
    title,
    trackList,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
