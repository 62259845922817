import styled from 'styled-components';
import DefaultIcon from 'components/Icon';
import DefaultCirclePlayIcon from './CirclePlayIcon';
import DefaultCirclePauseIcon from './CirclePauseIcon';

export const Container = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 50%;
  box-sizing: content-box;
  color: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  line-height: 1;
  opacity: 1;
  transition: opacity 100ms ease;

  &:active {
    opacity: 0.33;
  }
`;

export const CirclePlayIcon = styled(DefaultCirclePlayIcon)`
  display: block;
  height: 100%;
  width: 100%;
`;

export const CirclePauseIcon = styled(DefaultCirclePauseIcon)`
  display: block;
  height: 100%;
  width: 100%;
`;

export const Icon = styled(DefaultIcon)`
  display: block;
`;
