import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

export const Loader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 2rem 0;
`;

export const ProgressText = styled.div`
  font-weight: bold;
  font-size: 1rem;
  padding-top: 0.6rem;
  text-align: center;
`;

export const Progress = styled(CircularProgress).attrs(() => ({
  size: 50,
}))``;
