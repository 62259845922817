import { FC, memo } from 'react';
import useLogic from './logic';
import {
  Container,
  AvatarContainer,
  Avatar,
  Info,
  FullName,
  MemberSince,
  City,
} from './styles';
import { Props } from './types';

const UserInfo: FC<Props> = ({
  avatarUrl,
  city,
  firstName,
  lastName,
  joinedAt,
  inline,
  className,
}) => {
  const { fullName, joinDate } = useLogic({ firstName, joinedAt, lastName });

  return (
    <Container $inline={inline} className={className}>
      <AvatarContainer>
        <Avatar alt="" src={avatarUrl} />
      </AvatarContainer>
      <Info $inline={inline}>
        <FullName
          data-cypress-id="account-view-username"
          data-jest-id="username"
        >
          {fullName}
        </FullName>
        {joinDate && <MemberSince>Member since {joinDate}</MemberSince>}
        {city && <City>{city}</City>}
      </Info>
    </Container>
  );
};

export default memo(UserInfo);
