import { getNextIntroCourse } from 'models/Course';
import getProfile from './getProfile';
import getDaily from './getDaily';
import getIntroCourses from './getIntroCourses';

const getNextMeditation = async () => {
  const profile = await getProfile();
  const { isDailyUnlocked, isSubscribed } = profile;

  if (isDailyUnlocked) {
    if (!isSubscribed) {
      return null;
    }

    const daily = await getDaily();
    if (daily) {
      return { type: 'daily', nextMeditation: daily };
    }
  }

  const introCourses = await getIntroCourses();
  const nextIntroCourse = getNextIntroCourse(introCourses);

  if (introCourses && introCourses.length > 0) {
    if (isSubscribed) {
      return { type: 'course', nextMeditation: nextIntroCourse };
    }
  }

  return null;
};

export default getNextMeditation;
