import { matchPath } from 'react-router-dom';

export interface BackLevelRule {
  levels: number;
  route: string;
}

export const createGetBackLevels =
  (rules: BackLevelRule[]) =>
  (path: string): number =>
    (rules.find(({ route }) => matchPath(route, path)) || { levels: 1 }).levels;
