import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import type { Settings } from 'models/Settings';
import { UpdateSettingsDocument } from '../generated';
import { cacheUpdateSettings } from '../cache';
import { getSettings } from '../requests';

const useSettingsActions = () => {
  const [updateSettingsMutation, { loading: isUpdating }] = useMutation(
    UpdateSettingsDocument,
  );

  const updateSettings = useCallback(
    async (newSettings: Partial<Settings>, setOptimisticResponse?: boolean) => {
      if (newSettings) {
        const currentSettings = await getSettings();

        try {
          const newFullSettings = JSON.stringify({
            ...currentSettings,
            ...newSettings,
          });

          if (setOptimisticResponse) {
            cacheUpdateSettings(newFullSettings);
          }

          await updateSettingsMutation({
            variables: { settings: newFullSettings },
            update() {
              if (!setOptimisticResponse) {
                cacheUpdateSettings(newFullSettings);
              }
            },
          });
        } catch (error) {
          if (setOptimisticResponse) {
            cacheUpdateSettings(JSON.stringify(currentSettings));
          }
        }
      }
    },
    [updateSettingsMutation],
  );

  return {
    isUpdating,
    updateSettings,
  };
};

export default useSettingsActions;
export type UseSettingsActions = ReturnType<typeof useSettingsActions>;
