import { memo } from 'react';
import type { FC } from 'react';
import { useMediaQuery } from '@mui/material';
import { media } from 'styles/mixins';
import Control from './Control';
import {
  Controls,
  Container,
  LeftSection,
  Link,
  RightSection,
  Subtitle,
  Title,
} from './styles';
import type { Props } from './types';

const HomeSectionHeader: FC<Props> = ({
  className,
  linkTitle,
  linkTo,
  onControlClick,
  replace,
  state,
  subtitle,
  testId,
  title,
}) => {
  const isTabletPortrait = useMediaQuery(media.tabletPortrait);

  return (
    <Container className={className}>
      <LeftSection>
        <Title
          as="h2"
          data-cypress-id={`${testId}-title`}
          data-jest-id={`${testId}-title`}
        >
          {title}
        </Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {linkTo && linkTitle && isTabletPortrait && (
          <Link
            data-cypress-id={`${testId}-action`}
            data-jest-id={`${testId}-action`}
            replace={replace}
            showChevron
            state={state}
            to={linkTo}
          >
            {linkTitle}
          </Link>
        )}
      </LeftSection>
      {!isTabletPortrait && (
        <RightSection>
          {linkTo && linkTitle && (
            <Link
              data-cypress-id={`${testId}-action`}
              data-jest-id={`${testId}-action`}
              replace={replace}
              state={state}
              to={linkTo}
            >
              {linkTitle}
            </Link>
          )}
          {onControlClick && (
            <Controls>
              <Control onTriggerScroll={onControlClick} type="back" />
              <Control onTriggerScroll={onControlClick} type="forward" />
            </Controls>
          )}
        </RightSection>
      )}
    </Container>
  );
};

export default memo(HomeSectionHeader);
