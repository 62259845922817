import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { UpdateUserRetreatDocument, RetreatUserAction } from '../generated';

const useRetreatActions = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateUserRetreatMutation, { loading: isUpdatingUserRetreat }] =
    useMutation(UpdateUserRetreatDocument);

  const updateUserRetreat = useCallback(
    async ({
      action,
      retreatId,
    }: {
      action: RetreatUserAction;
      retreatId: string;
    }) => {
      try {
        const { data } = await updateUserRetreatMutation({
          variables: { action, id: retreatId },
        });

        return data?.updateUserRetreat;
      } catch (error) {
        enqueueSnackbar(error?.message || 'Unable to update user retreat', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, updateUserRetreatMutation],
  );

  return {
    isUpdatingUserRetreat,
    loading: isUpdatingUserRetreat,
    updateUserRetreat,
  };
};

export default useRetreatActions;
export type UseRetreatActions = ReturnType<typeof useRetreatActions>;
