import { apolloClient } from 'services/api';
import { MeDocument, type UserDataFragment } from '../generated';

function cacheUpdateMe(data: Partial<UserDataFragment>) {
  const meCacheData = apolloClient.readQuery({ query: MeDocument });

  if (!meCacheData?.me) {
    return null;
  }

  const newData = apolloClient.writeQuery({
    query: MeDocument,
    data: { __typename: 'Query', me: { ...meCacheData.me, ...data } },
  });

  return newData;
}

export default cacheUpdateMe;
