import { memo, useCallback } from 'react';
import type { FC } from 'react';
import { Container } from './styles';
import type { Props } from './types';

const Tab: FC<Props> = ({ active, index, onClick, title }) => {
  const handleClick = useCallback(() => {
    onClick(index);
  }, [index, onClick]);

  return (
    <Container $active={active} onClick={handleClick}>
      {title}
    </Container>
  );
};

export default memo(Tab);
