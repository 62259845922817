import styled from 'styled-components';
import { media } from 'styles/mixins';
import type { Props } from './types';

export const Row = styled.div`
  align-items: stretch;
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  justify-content: flex-start;
`;

export const Stat = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  justify-content: space-between;
  text-align: center;
`;

export const StatTitle = styled.div`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: 0.015rem;
  line-height: 1;
  margin-bottom: ${({ theme }) => theme.measures.xs};

  ${media.phonePortrait} {
    font-size: 0.75rem;
  }
`;

export const StatValue = styled.div`
  color: ${({ theme }) => theme.colors.fontEmphasized};
  line-height: 1;
`;

export const StatSeparator = styled.div`
  margin-right: 0.5rem;
  width: 0.5rem;
`;

export const RowSeparator = styled(StatSeparator)`
  ${media.phonePortrait} {
    display: none;
  }
`;

export const Container = styled.div<{
  $bigValues: Props['bigValues'];
  $forceRows: Props['forceRows'];
  $hideSeparators: Props['hideSeparators'];
  $reverseColors: Props['reverseColors'];
  $titlePosition: Props['titlePosition'];
}>`
  align-items: stretch;
  display: flex;
  flex-flow: ${({ $forceRows }) =>
    $forceRows ? 'column nowrap' : 'row nowrap'};
  font-size: 1rem;
  font-family: ${({ theme }) => theme.font.family.inter};
  justify-content: flex-start;
  ${Row} {
    &:not(:last-child) {
      margin-bottom: ${({ $forceRows }) => ($forceRows ? '1rem' : '0')};
      ${media.phonePortrait} {
        margin-bottom: 1rem;
      }
    }
  }
  ${Stat} {
    border-right-color: ${({ $reverseColors }) =>
      $reverseColors ? `rgba(255, 255, 255, 0.1)` : `rgba(100, 100, 100, 0.1)`};
    flex-direction: ${({ $titlePosition }) =>
      $titlePosition === 'bottom' ? `column-reverse` : `column`};
    flex-basis: ${({ $forceRows }) => ($forceRows ? '0%' : 'auto')};
    ${media.phonePortrait} {
      flex-basis: 0%;
    }
  }
  ${StatTitle} {
    margin-bottom: ${({ $titlePosition, theme }) =>
      $titlePosition === 'bottom' ? '0' : theme.measures.xs};
    margin-top: ${({ $titlePosition, theme }) =>
      $titlePosition === 'bottom' ? theme.measures.m : '0'};
  }
  ${StatValue} {
    font-size: ${({ $bigValues }) => ($bigValues ? '2rem' : '1.2rem')};
    font-weight: ${({ $bigValues }) => ($bigValues ? 'normal' : 'bold')};
    ${media.phonePortrait} {
      font-size: ${({ $bigValues }) => ($bigValues ? '1.6rem' : '1.2rem')};
    }
  }
  ${StatSeparator} {
    border-right: ${({ $hideSeparators }) =>
      $hideSeparators ? 'none' : '2px solid rgba(100, 100, 100, 0.1)'};
  }
  ${RowSeparator} {
    border-right: ${({ $forceRows, $hideSeparators }) =>
      $forceRows || $hideSeparators
        ? 'none'
        : '2px solid rgba(100, 100, 100, 0.1)'};
  }

  ${media.phonePortrait} {
    flex-flow: column nowrap;
  }
`;
