import { CourseStatus } from 'models/Course';
import { DailyStatus } from 'graphql/generated';
import type { UseSettings } from 'graphql/hooks/useSettings';

const getCourseProgressPercent = ({
  audioLength,
  hash,
  recentlyPlayed,
}: {
  audioLength: number;
  hash: string;
  recentlyPlayed: UseSettings['settings']['recentlyPlayed'];
  status: CourseStatus | DailyStatus;
}) => {
  const lastKnownLocation = recentlyPlayed.find(
    (rp) => rp.course_hash === hash,
  )?.last_known_location;

  if (!lastKnownLocation || lastKnownLocation === -1) {
    return 0;
  }

  return (lastKnownLocation / audioLength) * 100;
};

export default getCourseProgressPercent;
