import { memo, useCallback } from 'react';
import type { FC } from 'react';
import { updateOpenAccessLastCheckedDate } from 'graphql/reactive';
import differenceInDays from 'date-fns/differenceInDays';
import parseISO from 'date-fns/parseISO';
import { Button, Container, Content, Icon, Title } from '../styles';
import { Props } from './types';

const TrialExpirationDays: FC<Props> = ({
  expirationDate,
  isTrialExtension = false,
  userId,
}) => {
  const handleClose = useCallback(() => {
    updateOpenAccessLastCheckedDate(userId);
  }, [userId]);

  const now = new Date();
  const dtExpirationDate = parseISO(expirationDate);
  const nDays = differenceInDays(dtExpirationDate, now) + 1;

  return (
    <Container>
      <Button aria-label="Close notification" onClick={handleClose}>
        <Icon name="xCircleLight" />
      </Button>
      <Content>
        <Title $weight="bold">
          {nDays} day{nDays > 1 ? 's' : ''} remaining on your{' '}
          {isTrialExtension ? 'final ' : ''}free trial.
        </Title>
      </Content>
    </Container>
  );
};

export default memo(TrialExpirationDays);
