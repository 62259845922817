import { type FC, useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider, useReactiveVar } from '@apollo/client';
import { ThemeProvider as MaterialUIProvider } from '@mui/material/styles';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from 'styled-components';
import { ApiConfig } from 'config';
import { themeVar } from 'graphql/reactive/theme';
import { skipMaintenanceMode } from 'services/storage';
import { apolloClient } from 'services/api';
import GlobalStyle from 'styles/globalStyle';
import themes, { createMaterialUITheme, notistackConfig } from 'styles/themes';
import MaintenancePage from 'components/MaintenancePage';
import Routes from 'containers/Routes';

const stripePromise = loadStripe(ApiConfig.STRIPE_KEY, { locale: 'en' });

const ENABLE_MAINTENANCE_MODE = false;

const App: FC = () => {
  const theme = useReactiveVar(themeVar);
  const currentTheme = theme === 'dark' ? themes.dark : themes.light;

  const materialUITheme = useMemo(
    () => createMaterialUITheme(currentTheme),
    [currentTheme],
  );

  if (ENABLE_MAINTENANCE_MODE && !skipMaintenanceMode()) {
    return <MaintenancePage />;
  }

  return (
    <ApolloProvider client={apolloClient}>
      <Elements stripe={stripePromise}>
        <Router>
          <MaterialUIProvider theme={materialUITheme}>
            <ThemeProvider theme={currentTheme}>
              <SnackbarProvider {...notistackConfig}>
                <>
                  <Routes />
                  <GlobalStyle />
                </>
              </SnackbarProvider>
            </ThemeProvider>
          </MaterialUIProvider>
        </Router>
      </Elements>
    </ApolloProvider>
  );
};

export default App;
