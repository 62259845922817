import type { FC } from 'react';
import { Formik, Form } from 'formik';
import Button from 'components/Button';
import { Content, Errors, Modal, TextField } from './styles';
import useConnect from './connect';

const AddFamilyMemberModal: FC = () => {
  const { closeModal, initialValues, isOpen, submit, validationSchema } =
    useConnect();

  return (
    <Modal open={isOpen} title="Add Member" onClose={closeModal}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {({ errors, handleSubmit, isSubmitting, values }) => (
          <Form onSubmit={handleSubmit}>
            <Content>
              <TextField
                id="email"
                label="Email"
                name="email"
                placeholder="info@example.com"
              />
              <Button
                type="submit"
                disabled={isSubmitting || !values.email}
                id="add-member-submit"
              >
                Add
              </Button>
            </Content>
            {errors.submitError && <Errors>{errors.submitError}</Errors>}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddFamilyMemberModal;
