import { object as yupObject, string as yupString } from 'yup';
import { Step } from './Form/types';

export const initialValues = {
  message: '',
  step: Step.message,
  subject: '',
  submitError: '',
};

export const validationSchema = yupObject({
  subject: yupString().required('Please, enter a subject'),
  message: yupString().required('Please, enter a message'),
});
