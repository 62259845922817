import { memo } from 'react';
import type { FC } from 'react';
import { Props } from './types';

const Wordmark: FC<Props> = ({ ...rest }) => (
  <svg
    fill="none"
    viewBox="0 0 438 56"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M95.23 1.11l25.47 52.7h-9.17l-5.43-11.6H84.3l-5.43 11.6h-9.1l25.46-52.7zm-7.26 33.32h14.46l-7.19-15.41-7.27 15.41zM131.1 1.69h8.51v26.2l22.94-26.2h10.13l-23.24 26.5L174.9 53.8h-10.71l-24.58-24.59V53.8h-8.51V1.69zm55.45 0h8.51V53.8h-8.51V1.69zm160.27 31.85V1.69h8.51v32.07c0 8.07 5.06 12.55 12.48 12.55 7.41 0 12.4-4.48 12.4-12.55V1.69h8.51v31.85c0 12.84-8.66 20.84-20.92 20.84-12.25.01-20.98-7.99-20.98-20.84zm57.24-31.85h15.56c10.79 0 18.2 6.75 18.2 16.59 0 9.69-7.41 16.59-18.2 16.59h-7.05V53.8h-8.51V1.69zm15.19 25.25c6.24 0 9.91-3.45 9.91-8.66s-3.67-8.66-9.91-8.66h-6.68v17.32h6.68zM.05 1.56h8.73l13.5 31.97L36.04.98l13.7 32.35L62.96 1.56h8.81l-21.7 52.7L36.05 20.5 22.03 54.26.05 1.56zm222.92 19.29V53.8h-8.51V1.1l33.67 33.83V1.68h8.51v52.7l-33.67-33.53zm68.41 10.64h16.52v12.04c-2.94 1.84-6.83 2.79-10.64 2.79-10.28 0-17.98-8.15-17.98-18.57s7.71-18.57 17.98-18.57c4.44 0 8.86 1.56 12.06 4.21l5.82-5.82c-4.69-4.27-11.03-6.47-17.88-6.47-14.75 0-26.64 11.6-26.64 26.64 0 15.04 11.89 26.64 26.64 26.64 7.34 0 14.31-2.5 19.15-6.68V23.26h-21.64l-3.39 8.23z"
      fill="currentColor"
    />
  </svg>
);

export default memo(Wordmark);
