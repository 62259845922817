import styled from 'styled-components';
import { CardElement as StripeCardElement } from '@stripe/react-stripe-js';
import { Form as FormikForm } from 'formik';
import { media } from 'styles/mixins';
import DefaultTextField from 'components/Inputs/TextField';
import DefaultText from 'components/Text';
import DefaultButton from 'components/Button';
import DefaultLink from 'components/Link';
import { CARD_ELEMENT_OPTIONS } from './constants';

export const Container = styled.div`
  flex: 1 1 auto;
  padding-left: 2.75rem;

  ${media.tabletPortrait} {
    padding: 0;
  }
`;

export const PaymentMethods = styled.div`
  &::after {
    content: "Or enter card details and click 'Subscribe'";
    display: block;
    opacity: 0.5;
    padding: 0.5rem;
    text-align: center;
  }
`;

export const OtherPaymentButton = styled(DefaultButton)`
  &&& {
    background-color: ${({ theme }) => theme.colors.coreNeutralBlack};
    border-radius: ${({ theme }) => theme.radius.sm};
    color: ${({ theme }) => theme.colors.coreTeal50};
    margin-top: 0;
    width: 100%;
  }
`;

export const Form = styled(FormikForm)``;

export const FormSection = styled.div<{ $disabled?: boolean }>`
  border: none;
  display: flex;
  flex-flow: column nowrap;
  opacity: ${({ $disabled }) => ($disabled ? '0.5' : '1')};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'default')};
  transition: opacity 200ms ease;
  user-select: ${({ $disabled }) => ($disabled ? 'none' : 'default')};
`;

export const Title = styled.div`
  margin-bottom: ${({ theme }) => theme.measures.xs};
`;

export const TextField = styled(DefaultTextField)`
  box-shadow: 0 1px 3px 0 #e6ebf1;
  margin-bottom: 1.25rem;

  ${media.phonePortrait} {
    & input {
      font-size: 1.15rem;
      padding-left: 0.8rem;
      &::placeholder {
        font-size: 1.15rem;
      }
    }
  }
`;

export const CurrentEmail = styled.p`
  letter-spacing: -0.03em;
  margin-bottom: 2rem;
`;

export const CardElement = styled(StripeCardElement).attrs(() => ({
  options: CARD_ELEMENT_OPTIONS,
}))`
  &.StripeElement {
    background-color: ${({ theme }) => theme.colors.coreNeutralWhite};
    border: 1px solid transparent;
    border-radius: ${({ theme }) => theme.radius.sm};
    box-shadow: 0 1px 3px 0 #e6ebf1;
    box-sizing: border-box;
    padding: 0.8rem 0.75rem;
    transition: box-shadow 150ms ease;
  }

  &.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  &.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
`;

export const FormErrors = styled(DefaultText)`
  margin-bottom: 0.6rem;
  margin-top: 1.6rem;
`;

export const Button = styled(DefaultButton)`
  &&& {
    align-self: center;
    margin-top: 1rem;
    padding: 0.6rem 1.4rem;
    width: 12rem;
  }
`;

export const Link = styled(DefaultLink)`
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  color: inherit;
`;
