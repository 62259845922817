import { useMatch } from 'react-router-dom';
import { media } from 'styles/mixins';
import useMediaQuery from 'lib/useMediaQuery';

const NO_NAVIGATION_ROUTE = { path: '/mobile/subscription' };

const useConnect = () => {
  const isTabletPortrait = useMediaQuery(media.tabletPortrait);
  const matchNoNavigationRoute = !!useMatch(NO_NAVIGATION_ROUTE);

  return {
    isTabletPortrait,
    matchNoNavigationRoute,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
