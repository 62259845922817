import { useMemo } from 'react';
import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { up } from 'lib/paths';
import FAQ from 'components/Pages/FAQ';
import Info from 'components/Pages/Info';
import useAbouts from 'graphql/hooks/useAbouts';
import { Container, Content, Header } from './styles';
import type { Props } from './types';

const InfoPage: FC<Props> = ({ section }) => {
  const { pathname } = useLocation();
  const { abouts } = useAbouts();
  const { aboutCourse, aboutSam, privacy, terms } = abouts;

  const basePath = useMemo(() => up(pathname), [pathname]);

  return (
    <Container>
      <Header backHref={basePath} testId="info-header" title="Account" />
      <Content>
        {section === 'course' && (
          <Info title="About the Course" html={aboutCourse} />
        )}
        {section === 'help' && <FAQ />}
        {section === 'privacy' && <Info title="Privacy" html={privacy} />}
        {section === 'sam' && <Info title="About Sam Harris" html={aboutSam} />}
        {section === 'terms' && <Info title="Terms" html={terms} />}
      </Content>
    </Container>
  );
};

export default InfoPage;
