import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import useMe from 'graphql/hooks/useMe';
import useMeActions from 'graphql/hooks/useMeActions';
import { UserNotificationType } from 'graphql/generated';
import type { FormFields } from './Form/types';

const useConnect = () => {
  const { loading, notifications } = useMe({
    fetchPolicy: 'cache-and-network',
  });
  const { enqueueSnackbar } = useSnackbar();
  const { updateNotifications } = useMeActions();

  const initialValues = useMemo(
    () => ({
      dailyRecaps: notifications.some(
        ({ id }) => id === UserNotificationType.daily_recaps,
      ),
      newContent: notifications.some(
        ({ id }) => id === UserNotificationType.new_content,
      ),
      recommendations: notifications.some(
        ({ id }) => id === UserNotificationType.recommendations,
      ),
      retreats: notifications.some(
        ({ id }) => id === UserNotificationType.retreats,
      ),
    }),
    [notifications],
  );

  const handleSubmit = useCallback(
    async (values: FormFields) => {
      try {
        const payload: { id: UserNotificationType }[] = [];
        if (values.dailyRecaps) {
          payload.push({ id: UserNotificationType.daily_recaps });
        }
        if (values.newContent) {
          payload.push({ id: UserNotificationType.new_content });
        }
        if (values.recommendations) {
          payload.push({ id: UserNotificationType.recommendations });
        }
        if (values.retreats) {
          payload.push({ id: UserNotificationType.retreats });
        }

        await updateNotifications(payload);
      } catch (error) {
        enqueueSnackbar(
          error?.message || 'Unable to update notification settings',
          {
            variant: 'error',
          },
        );
      }
    },
    [enqueueSnackbar, updateNotifications],
  );

  return { handleSubmit, initialValues, loading };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
