import {
  useCallback,
  useEffect,
  useState,
  type KeyboardEvent,
  type MouseEvent,
} from 'react';
import { PlayerAutoplayType, PlayerSource } from 'models/Player';
import type { Course } from 'models/Course';
import { PackType } from 'graphql/generated';
import { setOptionsMenuVar } from 'graphql/reactive';
import useRecentlyPlayed from 'graphql/hooks/useRecentlyPlayed';
import useCourseActions from 'graphql/hooks/useCourseActions';
import useRefreshSettingsEffect from 'graphql/hooks/useRefreshSettingsEffect';

const useConnect = () => {
  const { loading, recentlyPlayed } = useRecentlyPlayed();
  const { play } = useCourseActions();

  const [selectedType, setSelectedType] = useState<PackType>(
    PackType.meditation,
  );

  useRefreshSettingsEffect();

  const { recentlyPlayedLife, recentlyPlayedPractice, recentlyPlayedTheory } =
    recentlyPlayed.reduce<{
      recentlyPlayedLife: Course[];
      recentlyPlayedPractice: Course[];
      recentlyPlayedTheory: Course[];
    }>(
      (t, course) => {
        if (course.packType === PackType.life) {
          t.recentlyPlayedLife.push(course);
        }
        if (course.packType === PackType.meditation) {
          t.recentlyPlayedPractice.push(course);
        }
        if (course.packType === PackType.lesson) {
          t.recentlyPlayedTheory.push(course);
        }
        return t;
      },
      {
        recentlyPlayedLife: [],
        recentlyPlayedPractice: [],
        recentlyPlayedTheory: [],
      },
    );

  const recentlyPlayedPracticeLength = recentlyPlayedPractice.length;
  const recentlyPlayedTheoryLength = recentlyPlayedTheory.length;
  const recentlyPlayedLifeLength = recentlyPlayedLife.length;
  useEffect(() => {
    if (!loading) {
      if (recentlyPlayedPracticeLength > 0) {
        setSelectedType(PackType.meditation);
      } else if (recentlyPlayedTheoryLength > 0) {
        setSelectedType(PackType.lesson);
      } else if (recentlyPlayedLifeLength > 0) {
        setSelectedType(PackType.life);
      }
    }
  }, [
    loading,
    recentlyPlayedLifeLength,
    recentlyPlayedPracticeLength,
    recentlyPlayedTheoryLength,
  ]);

  const handleRecentlyPlayedPracticeTrack = useCallback(
    async (courseId: string, packId: string) => {
      await play({
        autoplayType: PlayerAutoplayType.RECENTLY_PLAYED_PRACTICE,
        courseId,
        packId,
        source: PlayerSource.RECENTLY_PLAYED,
      });
    },
    [play],
  );

  const handleRecentlyPlayedTheoryTrack = useCallback(
    async (courseId: string, packId: string) => {
      await play({
        autoplayType: PlayerAutoplayType.RECENTLY_PLAYED_THEORY,
        courseId,
        packId,
        source: PlayerSource.RECENTLY_PLAYED,
      });
    },
    [play],
  );

  const handleRecentlyPlayedLifeTrack = useCallback(
    async (courseId: string, packId: string) => {
      await play({
        autoplayType: PlayerAutoplayType.RECENTLY_PLAYED_LIFE,
        courseId,
        packId,
        source: PlayerSource.RECENTLY_PLAYED,
      });
    },
    [play],
  );

  const handleSelectPracticeTab = useCallback(() => {
    setSelectedType(PackType.meditation);
  }, []);

  const handleSelectTheoryTab = useCallback(() => {
    setSelectedType(PackType.lesson);
  }, []);

  const handleSelectLifeTab = useCallback(() => {
    setSelectedType(PackType.life);
  }, []);

  const handleOpenOptionsMenu = useCallback(
    (
      event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLButtonElement>,
      courseId: string,
      packId: string,
    ) => {
      event.stopPropagation();
      setOptionsMenuVar({
        anchorEl: event.currentTarget,
        id: courseId,
        packId,
        type: 'course',
      });
    },
    [],
  );

  return {
    handleOpenOptionsMenu,
    handleRecentlyPlayedLifeTrack,
    handleRecentlyPlayedPracticeTrack,
    handleRecentlyPlayedTheoryTrack,
    handleSelectLifeTab,
    handleSelectPracticeTab,
    handleSelectTheoryTab,
    loading,
    recentlyPlayedLife,
    recentlyPlayedPractice,
    recentlyPlayedTheory,
    selectedType,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
