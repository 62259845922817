/* eslint-disable import/prefer-default-export */
import {
  array as yupArray,
  mixed as yupMixed,
  object as yupObject,
  string as yupString,
} from 'yup';
import { Step } from './types';

export const validationSchema = yupObject({
  email: yupString().max(150).email('Error: Invalid email address'),
  step: yupMixed<Step>().oneOf([Step.email, Step.pin]),
  pin: yupArray().when('step', {
    is: (value: Step) => value === Step.pin,
    then: yupArray()
      .of(yupString())
      .test(
        'len',
        'Error: Must be exactly 4 characters',
        (val) => val?.join('').length === 4,
      ),
  }),
});
