import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { useReactiveVar } from '@apollo/client';
import useMediaQuery from 'lib/useMediaQuery';
import { media } from 'styles/mixins';
import getUserProgress from 'graphql/requests/getUserProgress';
import useMe from 'graphql/hooks/useMe';
import useMeActions from 'graphql/hooks/useMeActions';
import useModalActions from 'graphql/hooks/useModalActions';
import useAuthActions from 'graphql/hooks/useAuthActions';
import useResetActions from 'graphql/hooks/useResetActions';
import useRefreshSettingsEffect from 'graphql/hooks/useRefreshSettingsEffect';
import { setThemeVar, themeVar } from 'graphql/reactive/theme';
import { updateSettings } from 'graphql/requests';

const useConnect = () => {
  const { loading: isLoadingMe, profile } = useMe({
    fetchPolicy: 'cache-and-network',
  });
  const { updateProfile } = useMeActions();
  const { logout } = useAuthActions();
  const {
    closeModal,
    openDailyDurationModal,
    openDialogModal,
    openOnboardingExperienceModal,
    openOnboardingSlidesModal,
    openResetContentMenuModal,
  } = useModalActions();
  const { resetAllContent, resetIntroCourses } = useResetActions();
  const theme = useReactiveVar(themeVar);
  const isMobile = useMediaQuery(media.phoneLandscape);
  const { pathname } = useLocation();

  const userId = profile.id;

  useRefreshSettingsEffect();

  const handleResetIntroCourse = useCallback(() => {
    openDialogModal({
      confirmButtonId: 'settings-reset-intro-course-confirm',
      description:
        'This will restart the Introductory Course. Daily Meditations will be locked until you complete the restarted Introductory Course. Are you sure you want to continue?',
      onCancel: closeModal,
      onConfirm: () => {
        closeModal();
        resetIntroCourses();
      },
      title: 'Reset Introductory Course',
    });
  }, [closeModal, openDialogModal, resetIntroCourses]);

  const handleResetAllContent = useCallback(() => {
    openDialogModal({
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      description: 'Are you sure you want to continue?',
      onCancel: closeModal,
      onConfirm: () => {
        closeModal();
        resetAllContent();
      },
      title: 'Mark All Content as unplayed',
    });
  }, [closeModal, openDialogModal, resetAllContent]);

  const handleExportProgressToCSV = useCallback(async () => {
    const progress = await getUserProgress(userId);

    const csvContent = [
      ['Finished On', 'Title', 'Duration'].join(','),
      ...progress.map(({ finishedAt, title, durationSeconds }) =>
        [
          finishedAt ? format(parseISO(finishedAt), 'MM/dd/yyyy') : '',
          `"${title.replace(/"/g, "'").replace(/#/g, '')}"`,
          durationSeconds,
        ].join(','),
      ),
    ].join('\n');

    const now = format(new Date(), 'yyyyMMddHHmmss');
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const fileName = `wu_progress_${userId}_${now}`;

    const link = document.createElement('a');
    link.id = fileName;
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [userId]);

  const handleChangeTheme = useCallback(() => {
    setThemeVar(theme === 'dark' ? 'light' : 'dark');
    updateSettings({ theme: theme === 'dark' ? 'light' : 'dark' }, true);
  }, [theme]);

  return {
    handleChangeTheme,
    handleExportProgressToCSV,
    handleResetAllContent,
    handleResetIntroCourse,
    isDarkMode: theme === 'dark',
    isMobile,
    loading: isLoadingMe,
    logOut: logout,
    openDailyDurationModal,
    openOnboardingExperienceModal,
    openOnboardingSlidesModal,
    openResetContentMenuModal,
    pathname,
    profile,
    updateProfile,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
