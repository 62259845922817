import { SelfTimerInterval } from 'models/SelfTimerInterval';
import { PlayerSource } from 'models/Player';

export type SettingsPlayer = {
  code: string;
  courseId: string;
  packId?: string;
  queryString?: string;
  retreatHash?: string;
  sharedDailyHash?: string;
  source: PlayerSource;
};

export type SettingsRecentlyPlayed = {
  course_hash: string;
  date?: number;
  last_known_location: number;
};

export type Settings = {
  autoplay: boolean;
  backgroundAudioEnabled: boolean;
  backgroundAudioProgress: number;
  backgroundAudioTrackIndex: number;
  backgroundAudioVolumeWeb: number;
  dailyDurationChangeTo20Count: number;
  experiments: Record<string, string>;
  firstMediaStarted: boolean;
  hasPresentedHome: boolean;
  hasSeenPopupWarningSharingFreeMonth: boolean;
  hasSeenPopupWarningSpeedControl: boolean;
  lastDateDisplayedRoadBlockSubscription?: number;
  lastSessionAddDuration: number;
  lastTimerDuration: number;
  lastTimerInterval: SelfTimerInterval;
  playbackSpeed: string;
  player: SettingsPlayer | null;
  profileSelectedHeadIndex: number;
  recentlyPlayed: Array<SettingsRecentlyPlayed>;
  showAbreviatedMinutesMeditated: boolean;
  theme: 'dark' | 'light' | null;
  unlockedGuestPacks: Array<string>;
};

export const DEFAULT_SETTINGS: Settings = {
  autoplay: true,
  backgroundAudioEnabled: false,
  backgroundAudioProgress: 0,
  backgroundAudioTrackIndex: 0,
  backgroundAudioVolumeWeb: 0.5,
  dailyDurationChangeTo20Count: 0,
  experiments: {},
  firstMediaStarted: false,
  hasPresentedHome: false,
  hasSeenPopupWarningSharingFreeMonth: false,
  hasSeenPopupWarningSpeedControl: false,
  lastSessionAddDuration: 0,
  lastTimerDuration: 1,
  lastTimerInterval: SelfTimerInterval.NONE,
  playbackSpeed: '1.0',
  player: null,
  profileSelectedHeadIndex: 0,
  recentlyPlayed: [] as Array<SettingsRecentlyPlayed>,
  showAbreviatedMinutesMeditated: false,
  theme: null,
  unlockedGuestPacks: [] as Array<string>,
};

export const normalizeSettings = (settings: string): Settings => {
  try {
    const parsedSettings = JSON.parse(settings);
    return { ...DEFAULT_SETTINGS, ...parsedSettings };
  } catch (e) {
    return DEFAULT_SETTINGS;
  }
};
