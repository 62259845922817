import { useMemo } from 'react';
import { useQuery, type QueryHookOptions } from '@apollo/client';
import keyBy from 'lodash/keyBy';
import parseISO from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import getDateFromIsoString from 'lib/getDateFromIsoString';
import createPersonsLine from 'lib/createPersonsLine';
import {
  getLastIntroCourseId,
  getPackPermission,
  normalizePack,
} from 'models/Pack';
import {
  Course,
  CoursePermission,
  CourseStatus,
  getCoursePermission,
  normalizeCourse,
} from 'models/Course';
import { normalizePerson, Person, separatePersonsByType } from 'models/Person';
import {
  PackCategory,
  GetContentDocument,
  type GetContentQuery,
  type GetContentQueryVariables,
} from '../generated';
import useMe from './useMe';

const usePacks = (
  options: QueryHookOptions<GetContentQuery, GetContentQueryVariables> = {},
) => {
  const { introPackId, isSubscribed, isDailyUnlocked } = useMe();
  const { data, loading: isLoadingContent } = useQuery(GetContentDocument, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  const { packs, introPack } = useMemo(() => {
    if (
      !data?.content ||
      !data.content.packs ||
      !data.content.courses ||
      !data.content.persons
    ) {
      return { packs: [], introPack: undefined };
    }

    const {
      courses: contentCourses,
      packs: contentPacks,
      persons: contentPersons,
    } = data.content;
    const contentCoursesObj = keyBy(contentCourses, (v) => v.hash);
    const contentPersonsObj = keyBy(contentPersons, (v) => v.id);

    const lastIntroCourseId = getLastIntroCourseId(contentPacks, introPackId);
    const now = new Date();

    const allPacks = contentPacks
      .map((p, i, arr) => {
        const pack = normalizePack(p);
        const packCourses = pack.coursesInfo.reduce<Course[]>(
          (t, { courseHash }) => {
            const course = contentCoursesObj[courseHash];
            if (course) {
              t.push(normalizeCourse(course));
            }
            return t;
          },
          [],
        );
        const packPersons = pack.personsInfo.reduce<Person[]>(
          (t, { personId, type }) => {
            const person = contentPersonsObj[personId];
            if (person) {
              t.push({ ...normalizePerson(person), type });
            }
            return t;
          },
          [],
        );

        if (
          pack.category === PackCategory.intro &&
          !pack.parentPackId &&
          isDailyUnlocked
        ) {
          pack.number = 1000;
        }

        const nSubpacks = arr
          ? arr.filter(({ parent_pack_id }) => parent_pack_id === p.id).length
          : 0;
        pack.nSubpacks = nSubpacks;

        const finishedCourses = packCourses.filter(
          (c) => c.status === CourseStatus.FINISHED,
        );
        pack.progress = (finishedCourses.length / packCourses.length) * 100;

        pack.hasFreeCourses = packCourses.some(({ isFree }) => isFree);

        let packHasNewCourses = false;
        if (pack.hasNewCoursesUntil) {
          const dtHasNewCoursesUntil = getDateFromIsoString(
            pack.hasNewCoursesUntil,
          );
          packHasNewCourses =
            !!dtHasNewCoursesUntil &&
            isBefore(now, endOfDay(dtHasNewCoursesUntil));
        }

        const {
          artists: packArtists,
          speakers: packSpeakers,
          teachers: packTeachers,
          teachersAndSpeakers: packTeachersAndSpeakers,
        } = separatePersonsByType(packPersons);

        pack.persons = packPersons;

        pack.artists = packArtists;
        pack.speakers = packSpeakers;
        pack.teachers = packTeachers;
        pack.teachersAndSpeakers = packTeachersAndSpeakers;

        const packSubtitle = createPersonsLine(packSpeakers, pack.subtitle);
        pack.subtitle = packSubtitle;

        pack.courses = packCourses
          .map((course, index) => {
            let isNewCourse = false;
            if (packHasNewCourses && pack.publishDate) {
              const dtPublishDate = getDateFromIsoString(pack.publishDate);
              isNewCourse =
                !!dtPublishDate &&
                isAfter(parseISO(course.createdAt), startOfDay(dtPublishDate));
            }

            const coursePersons = course.personsInfo.reduce<Person[]>(
              (t, { personId, type }) => {
                const person = contentPersonsObj[personId];
                if (person) {
                  t.push({ ...normalizePerson(person), type });
                }
                return t;
              },
              [],
            );

            const {
              artists: courseArtists,
              speakers: courseSpeakers,
              teachers: courseTeachers,
              teachersAndSpeakers: courseTeachersAndSpeakers,
            } = separatePersonsByType(coursePersons);

            const displayArtists =
              courseArtists.length > 0 ? courseArtists : packArtists;
            const displaySpeakers =
              courseSpeakers.length > 0 ? courseSpeakers : packSpeakers;
            const displayTeachers =
              courseTeachers.length > 0 ? courseTeachers : packTeachers;
            const displayTeachersAndSpeakers =
              courseTeachersAndSpeakers.length > 0
                ? courseTeachersAndSpeakers
                : packTeachersAndSpeakers;

            const courseSubtitle = createPersonsLine(
              courseSpeakers,
              course.subtitle,
            );

            return {
              ...course,
              artists: courseArtists,
              displayArtists,
              displaySpeakers,
              displayTeachers,
              displayTeachersAndSpeakers,
              isConversation: pack.category === PackCategory.conversations,
              isIntroCourse: pack.category === PackCategory.intro,
              isLastCourse: packCourses.length - 1 === index,
              isLastIntro:
                !!course.id &&
                !!lastIntroCourseId &&
                course.id === lastIntroCourseId,
              isNew: isNewCourse,
              isNewText: isNewCourse ? 'New' : '',
              packArtists,
              packAutoPlay: pack.autoPlay,
              packCategory: pack.category,
              packEndMessage: pack.endMessage,
              packHash: pack.hash,
              packHasNewCourses: pack.hasNewCourses,
              packId: pack.id,
              packImageThumbnailsThumbX1: pack.imageThumbnailsThumbX1,
              packImageUrl: pack.imageUrl,
              packIsNew: pack.isNew,
              packIsPrimaryPack:
                !!course.primaryPackId && pack.id === course.primaryPackId,
              packOverwriteCourseImage: pack.overwriteCourseImage,
              packParentPackId: pack.parentPackId,
              packPersons,
              packProgress: pack.progress,
              packSpeakers,
              packSubtitle,
              packSummary: pack.summary,
              packTeachers,
              packTeachersAndSpeakers,
              packTitle: pack.title,
              packType: pack.type,
              permission: getCoursePermission(isSubscribed, course.isFree),
              persons: coursePersons,
              speakers: courseSpeakers,
              subtitle: courseSubtitle,
              teachers: courseTeachers,
              teachersAndSpeakers: courseTeachersAndSpeakers,
            };
          })
          .sort((a, b) => {
            const aAvailable = a.permission === CoursePermission.UNLOCKED;
            const bAvailable = b.permission === CoursePermission.UNLOCKED;

            if (aAvailable && !bAvailable) {
              return -1;
            }
            if (!aAvailable && bAvailable) {
              return 1;
            }

            if (a.isHighlighted && !b.isHighlighted) {
              return -1;
            }
            if (!a.isHighlighted && b.isHighlighted) {
              return 1;
            }

            return 0;
          });

        return pack;
      })
      .map((pack, i, arr) => {
        pack.permission = getPackPermission(
          isSubscribed,
          pack.hasFreeCourses ||
            (!pack.parentPackId &&
              pack.category !== PackCategory.intro &&
              arr.some(
                (p) =>
                  p.parentPackId &&
                  p.parentPackId === pack.id &&
                  p.hasFreeCourses,
              )),
        );
        return pack;
      })
      .sort((a, b) => a.number - b.number);

    return {
      packs: allPacks,
      introPack: allPacks.find((pack) => pack.id === introPackId),
    };
  }, [data, introPackId, isDailyUnlocked, isSubscribed]);

  return {
    introPack,
    loading: !data && isLoadingContent,
    packs,
  };
};

export default usePacks;
export type UsePacks = ReturnType<typeof usePacks>;
