import { memo } from 'react';
import type { FC } from 'react';
import {
  Button,
  Buttons,
  Content,
  Description,
  Label,
  Modal,
  TextArea,
  Title,
} from './styles';
import useConnect from './connect';

const ShareLinkModal: FC = () => {
  const {
    closeModal,
    description,
    handleCopyLink,
    isOpen,
    link,
    linkRef,
    title,
  } = useConnect();

  return (
    <Modal
      descriptionId="share-link-description"
      onClose={closeModal}
      open={isOpen}
      titleId="share-link-title"
    >
      <Title as="h2" id="share-link-title">
        {title}
      </Title>
      <Content>
        <Description>{description}</Description>
        <Label id="share-link">Link to share:</Label>
        <TextArea
          aria-labelledby="share-link"
          id="share-link-modal-link"
          readOnly
          ref={linkRef}
          rows={1}
          value={link}
        />
      </Content>
      <Buttons>
        <Button mode="deemphasized" onClick={closeModal}>
          Cancel
        </Button>
        <Button onClick={handleCopyLink}>Copy</Button>
      </Buttons>
    </Modal>
  );
};

export default memo(ShareLinkModal);
