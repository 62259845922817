import styled from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';
import Icon from 'components/Icon';
import { Display as DefaultDisplay } from 'components/Typography';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 3rem;
  padding: ${({ theme }) => theme.measures.m} 0;
`;

export const TitleAndDescription = styled.div``;

export const TitleRow = styled.div`
  align-items: center;
  display: flex;
`;

export const TitleIcon = styled(Icon).attrs(() => ({
  size: 24,
}))`
  display: block;
  margin-right: 0.625rem;
`;

export const Title = styled(DefaultDisplay).attrs(() => ({ $size: 'small' }))`
  font-size: 1.25rem;
`;

export const Description = styled.h3`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutral500
      : theme.colors.coreNeutral300};
  font-size: 0.875rem;
  letter-spacing: 0.01em;
  line-height: 1.429;
  margin-top: 0.4375rem;
`;

export const BackLink = styled(DefaultLink)`
  color: inherit;
  line-height: 1;
`;

export const Content = styled.div`
  margin-left: ${({ theme }) => theme.measures.m};
`;
