import { motion } from 'framer-motion';
import colorWithAlpha from 'lib/color';
import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultLoader from '../Loader';

export const Loader = styled(DefaultLoader)`
  height: 15.6rem;
`;

export const Container = styled.div`
  margin-bottom: 1.6rem;
  margin-top: 1.6rem;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.fontBase};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 1.1875;
  margin-bottom: ${({ theme }) => theme.measures.xxs};
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.fontDeemphasized};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 1.25;
  margin-bottom: 1.125rem;
`;

export const Buttons = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);

  ${media.phonePortrait} {
    grid-template-columns: 1fr;
  }
`;

export const Error = styled(motion.div).attrs(() => ({
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  transition: { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] },
}))`
  color: ${({ theme }) => theme.colors.fontLabelError};
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.01rem;
  margin-top: ${({ theme }) => theme.measures.xxs};
  text-align: left;
`;

export const MembershipInfo = styled.div`
  background-color: ${colorWithAlpha({ color: '#215ADB', alpha: 0.25 })};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.coreNeutralBlack};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 1.1875;
  margin-top: 1rem;
  min-height: 3.5625rem;
  padding: 1.1875rem 0;
  text-align: center;

  strong {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`;
