import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Animation = styled.div`
  width: 150px;
`;

export const Message = styled(motion.p).attrs(() => ({
  animate: { opacity: 1 },
  transition: { duration: 1, delay: 0.25 },
}))`
  display: flex;
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 1.35;
  margin-bottom: -1rem;
  opacity: 0;
  text-align: center;
  white-space: pre-wrap;
`;
