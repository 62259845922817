import styled, { DefaultTheme, css } from 'styled-components';
import colorWithAlpha from 'lib/color';
import { ButtonSecondary } from 'components/Typography';
import DefaultIcon from 'components/Icon';
import { ContainerProps } from './types';

const activeState = ({
  theme,
  mode,
}: ContainerProps & { theme: DefaultTheme }) => {
  switch (mode) {
    case 'destructive':
      return css`
        background-color: ${colorWithAlpha({
          color: theme.colors.coreRed500,
          alpha: 0.25,
        })};
        box-shadow: 0px 0px 0px 4px
          ${colorWithAlpha({ color: theme.colors.coreRed500, alpha: 0.25 })};
      `;
    case 'deemphasized':
      return css`
        background-color: ${colorWithAlpha({
          color: theme.colors.coreNeutralBlack,
          alpha: 0.1,
        })};
        box-shadow: 0px 0px 0px 4px
          ${theme.name === 'LIGHT'
            ? colorWithAlpha({
                color: theme.colors.coreNeutralBlack,
                alpha: 0.1,
              })
            : colorWithAlpha({
                color: theme.colors.coreNeutralWhite,
                alpha: 0.15,
              })};
      `;
    default:
      return css`
        background-color: ${colorWithAlpha({
          color: theme.colors.coreTeal500,
          alpha: 0.25,
        })};
        box-shadow: 0px 0px 0px 4px
          ${colorWithAlpha({ color: theme.colors.coreTeal500, alpha: 0.25 })};
      `;
  }
};

const hoverState = ({ theme }: ContainerProps & { theme: DefaultTheme }) =>
  css`
    background: ${theme.name === 'LIGHT'
      ? colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.015 })
      : colorWithAlpha({ color: theme.colors.coreNeutralWhite, alpha: 0.05 })};
    box-shadow: 0px 0px 0px 4px
      ${theme.name === 'LIGHT'
        ? colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.015 })
        : colorWithAlpha({
            color: theme.colors.coreNeutralWhite,
            alpha: 0.05,
          })};
  `;

const focusState = ({
  theme,
  mode,
}: ContainerProps & { theme: DefaultTheme }) => {
  switch (mode) {
    case 'destructive':
      return css`
        background: ${theme.name === 'LIGHT'
          ? theme.colors.coreNeutralAurora
          : theme.colors.coreNeutralAbyss};
        box-shadow: 0px 0px 0px 3px
            ${theme.name === 'LIGHT'
              ? theme.colors.coreNeutralAurora
              : theme.colors.coreNeutralAbyss},
          0px 0px 0px 4px
            ${colorWithAlpha({ color: theme.colors.coreRed500, alpha: 0.25 })};
      `;
    case 'deemphasized':
      return css`
        background: ${theme.name === 'LIGHT'
          ? theme.colors.coreNeutralAurora
          : theme.colors.coreNeutralAbyss};
        box-shadow: 0px 0px 0px 3px
            ${theme.name === 'LIGHT'
              ? theme.colors.coreNeutralAurora
              : theme.colors.coreNeutralAbyss},
          0px 0px 0px 4px
            ${theme.name === 'LIGHT'
              ? colorWithAlpha({
                  color: theme.colors.coreNeutralBlack,
                  alpha: 0.25,
                })
              : colorWithAlpha({
                  color: theme.colors.coreNeutralWhite,
                  alpha: 0.25,
                })};
      `;
    default:
      return css`
        background: ${theme.name === 'LIGHT'
          ? theme.colors.coreNeutralAurora
          : theme.colors.coreNeutralAbyss};
        box-shadow: 0px 0px 0px 3px
            ${theme.name === 'LIGHT'
              ? theme.colors.coreNeutralAurora
              : theme.colors.coreNeutralAbyss},
          0px 0px 0px 4px
            ${colorWithAlpha({ color: theme.colors.coreTeal500, alpha: 0.25 })};
      `;
  }
};

const disabledState = ({
  theme,
}: ContainerProps & { theme: DefaultTheme }) => css`
  background: transparent;
  color: ${theme.name === 'LIGHT'
    ? colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.33 })
    : colorWithAlpha({ color: theme.colors.coreNeutralWhite, alpha: 0.33 })};
`;

export const Container = styled.button<ContainerProps>`
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 0.125rem;
  color: ${({ theme }) => theme.colors.fontButtonEmphasized};
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding: 0;
  position: relative;
  text-decoration: underline;
  transition: all 200ms ease;
  transition-property: background-color, box-shadow, border-color, color;
  width: auto;

  ${ButtonSecondary}

  &:hover {
    ${hoverState}
  }
  &:focus {
    ${focusState}
  }
  &:active {
    ${activeState}
  }
  &:disabled {
    ${disabledState}
  }
`;

export const Icon = styled(DefaultIcon)<{
  $disabled: ContainerProps['disabled'];
  $mode: ContainerProps['mode'];
}>`
  color: ${({ $disabled, $mode, theme }) => {
    if ($disabled) {
      return theme.name === 'LIGHT'
        ? colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.33 })
        : colorWithAlpha({ color: theme.colors.coreNeutralWhite, alpha: 0.33 });
    }

    if ($mode === 'deemphasized') {
      return 'inherit';
    }

    if ($mode === 'destructive') {
      return theme.name === 'LIGHT'
        ? theme.colors.coreRed700
        : theme.colors.coreRed500;
    }

    return theme.name === 'LIGHT'
      ? theme.colors.coreTeal700
      : theme.colors.coreTeal500;
  }};
  display: block;
  margin-right: ${({ theme }) => theme.measures.xs};
  transition: color 200ms ease;
`;
