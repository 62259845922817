import styled from 'styled-components';

import DefaultButton from 'components/Button';

export const Container = styled.div``;

export const Button = styled(DefaultButton)`
  margin: 1.75rem auto 5rem auto;
  max-width: 25rem;
`;
