import { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import firebaseDatabase from 'services/firebase';
import {
  normalizeLiveAudio,
  type LiveAudio as LiveAudioModel,
} from 'models/LiveAudio';
import { LiveAudio } from 'graphql/generated';

type LiveAudioData = {
  data: LiveAudioModel | undefined;
  loading: boolean;
};

const useLiveAudio = () => {
  const [liveAudioData, setLiveAudioData] = useState<LiveAudioData>({
    data: undefined,
    loading: true,
  });

  useEffect(() => {
    const liveAudioRef = ref(firebaseDatabase, 'live_audio');

    const unsubscribe = onValue(liveAudioRef, (snapshot) => {
      const data: LiveAudio = snapshot.val();

      setLiveAudioData({
        data: data ? normalizeLiveAudio(data) : undefined,
        loading: false,
      });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return {
    liveAudio: liveAudioData.data,
    loading: !liveAudioData.data && liveAudioData.loading,
  };
};

export default useLiveAudio;
export type UseLiveAudio = ReturnType<typeof useLiveAudio>;
