import styled from 'styled-components';
import DefaultButton from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import { motion } from 'framer-motion';
import { CircularProgress, CircularProgressProps } from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  background-color: #fff;
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  min-height: 2.8125rem;
`;

export const Label = styled.label`
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  white-space: nowrap;
`;

export const Input = styled(InputBase)`
  flex: 1 1 auto;

  .MuiInputBase-root {
    background: ${({ theme }) => theme.colors.coreNeutralWhite};
    border-radius: 0.25rem 0 0 0.25rem;
    color: ${({ theme }) => theme.colors.coreNeutralAbyss};
    height: 100%;
    transition: border 0.2s ease;
  }

  .MuiInputBase-input {
    font-size: 1.0625rem;
    line-height: 1.235;
    padding: 0 0.75rem;

    &::placeholder {
      color: ${({ theme }) => theme.colors.coreNeutralAbyss};
      opacity: 0.5;
    }

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset;
    }
  }
`;

export const Button = styled(DefaultButton)<{ $isLoading?: boolean }>`
  &&& {
    background-color: ${({ theme }) => theme.colors.coreBlue500};
    border-radius: 0 0.25rem 0.25rem 0;
    box-shadow: none;
    color: ${({ theme }) => theme.colors.coreNeutralAurora};
    cursor: pointer;
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.font.weight.book};
    line-height: 1.235;
    min-height: auto;
    min-width: 6.25rem;
    opacity: inherit;
    outline: none;
    padding: 0.75rem 1.9063rem;
    position: relative;
    text-transform: capitalize;
    text-decoration: none;
    transition: opacity 0.2s ease;

    &:disabled,
    .Mui-disabled {
      cursor: default;
      opacity: ${({ $isLoading }) => ($isLoading ? '1' : '0.6')};
      pointer-events: none;
    }
  }
`;

export const Loader = styled(CircularProgress).attrs<CircularProgressProps>(
  () => ({ size: 20, color: 'inherit' }),
)`
  color: inherit;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
`;

export const Error = styled(motion.p).attrs(() => ({
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  transition: { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] },
}))`
  color: ${({ theme }) => theme.colors.coreRed500};
  font-size: 0.75rem;
  letter-spacing: 0.01rem;
  line-height: 1.33;
  margin-top: 0.25rem;
  text-align: left;
`;
