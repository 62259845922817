import { memo, type FC } from 'react';
import { SLIDES } from '../constants';
import Button from './Button';
import { Container, Dot } from './styles';
import type { Props } from './types';

const Pagination: FC<Props> = ({ onSelectIndex, selectedIndex }) => {
  return (
    <Container>
      {SLIDES.map((slide, i) => (
        <Dot key={slide.title}>
          <Button
            index={i}
            onClick={onSelectIndex}
            selected={i === selectedIndex}
          />
        </Dot>
      ))}
    </Container>
  );
};

export default memo(Pagination);
