import styled from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';
import { media } from 'styles/mixins';
import { HEADER_HEIGHT } from 'styles/metrics';
import DefaultSectionHeader from 'components/SectionHeader';
import { Display as DefaultDisplay } from 'components/Typography';

export const SectionHeader = styled(DefaultSectionHeader)`
  margin-top: -1rem;
  padding-bottom: 0;

  ${media.tabletPortrait} {
    background-color: ${({ theme }) => theme.colors.backgroundBase};
    margin-top: -1.4rem;
    position: sticky;
    top: ${HEADER_HEIGHT};
    z-index: 7;
  }
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 3rem;
  padding: ${({ theme }) => theme.measures.m} 0;
  position: relative;

  ${media.tabletPortrait} {
    background-color: ${({ theme }) => theme.colors.backgroundBase};
    margin-top: -1.4rem;
    position: sticky;
    top: ${HEADER_HEIGHT};
    z-index: 7;
  }

  ${media.phoneLandscape} {
    padding-bottom: 0;
  }
`;

export const BackLink = styled(DefaultLink)`
  align-items: center;
  color: inherit;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;

  :active {
    color: ${({ theme }) => theme.colors.fontDeemphasized};
  }

  ${media.tabletPortrait} {
    display: none;
  }
`;

export const Title = styled(DefaultDisplay).attrs(() => ({ $size: 'small' }))``;

export const Content = styled.div`
  padding-bottom: 1.4rem;
`;
