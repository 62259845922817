import type { FC } from 'react';
import { Link } from './styles';

type Props = {
  href?: string;
  onClick?: () => void;
};

const LogoutLink: FC<Props> = (props) => (
  <Link {...props} id="logout">
    Log Out
  </Link>
);

export default LogoutLink;
