import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import MuiCircularProgress from '@mui/material/CircularProgress';
import DefaultSubscriptionCoverImage from 'components/SubscriptionCoverImage';
import { media } from 'styles/mixins';

export const Container = styled.div<{ $isPublic: boolean }>`
  align-items: flex-start;
  display: flex;
  width: 100%;

  ${({ $isPublic }) =>
    $isPublic
      ? css`
          padding-top: 2.25rem;
          padding-bottom: 3.25rem;
        `
      : css`
          flex-direction: column-reverse;
          align-items: center;
          max-width: 36rem;
        `}

  ${media.tabletPortrait} {
    align-items: center;
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 2.6875rem;

  ${media.tabletLandscape} {
    margin-right: 1rem;
  }

  ${media.phoneLandscape} {
    margin-right: 0;
  }
`;

export const Image = styled.img<{ $isPublic: boolean }>`
  height: ${({ $isPublic }) => ($isPublic ? 'auto' : '33rem')};
  width: ${({ $isPublic }) => ($isPublic ? '30.375rem' : '22rem')};

  ${media.tabletLandscape} {
    height: 37.5rem;
    width: 25rem;
  }

  ${media.phonePortrait} {
    height: auto;
    margin: 0 -1.25rem;
    width: 100vw;
  }
`;

export const SubscriptionCoverImage = styled(DefaultSubscriptionCoverImage)`
  margin: 0;
`;

export const Artwork = styled.p`
  color: #111111;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: 0.6875rem;
  line-height: 0.8125rem;
  opacity: 0.4;
`;

export const Loader = styled(MuiCircularProgress).attrs(() => ({
  size: 35,
}))``;

export const Information = styled.div<{ $isPublic: boolean }>`
  display: flex;
  flex-direction: column;
  padding-top: ${({ $isPublic }) => $isPublic && '12.375rem'};

  ${media.tabletPortrait} {
    padding-top: 0;
  }

  ${media.phoneLandscape} {
    padding: 0 1rem;
  }
`;

export const StepContent = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.25 },
}))`
  width: 100%;
`;

export const Title = styled.h1`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  font-size: 1.9586rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.01em;
  line-height: 1.21;
  margin-bottom: 1.5rem;

  ${media.phoneLandscape} {
    font-size: 1.5228rem;
  }
`;

export const Description = styled.p`
  font-size: 1.125rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  line-height: 1.375rem;
  margin-bottom: 1.755rem;

  ${media.tabletPortrait} {
    font-size: 1.125rem;
    margin-bottom: 2.8125rem;
  }
`;
