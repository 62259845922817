import stringToEnum from 'lib/stringToEnum';
import notEmpty from 'lib/notEmpty';
import { normalizePersonInfo, type Person } from 'models/Person';
import {
  PackCategory,
  PackType,
  SpeedControlLevel,
  type CourseDataFragment,
} from 'graphql/generated';

export const START_HERE_COURSE_HASH = 'dfdb46';

export enum CourseType {
  LESSON = 'lesson',
  MEDITATION = 'meditation',
}

export enum CourseStatus {
  UNSTARTED = 'unstarted',
  STARTED = 'started',
  FINISHED = 'finished',
}

export enum CoursePermission {
  UNLOCKED = 'unlocked',
  LOCKED = 'locked',
  FORBIDDEN = 'forbidden',
}

export const normalizeCourse = (course: CourseDataFragment) => {
  const hash = course.hash || '';

  return {
    artists: [] as Person[],
    audioLength: course.audio?.length || 0,
    audioUrl: course.audio?.url || '',
    backgroundAudioAvailable: course.background_audio_available ?? true,
    bdId: course.id || '',
    bookmarkedAt: (course.bookmarked_at as string) || '',
    courseType:
      stringToEnum(CourseType, course.course_type) || CourseType.LESSON,
    createdAt: (course.created_at as string) || '',
    deepLink: '',
    description: course.description || '',
    displayArtists: [] as Person[],
    displaySpeakers: [] as Person[],
    displayTeachers: [] as Person[],
    displayTeachersAndSpeakers: [] as Person[],
    ephemeral: course.ephemeral ?? false,
    hash,
    headImageThumbnailsThumbX1: course.head_image?.thumbnails?.thumb_x1 || '',
    headImageThumbnailsThumbX2: course.head_image?.thumbnails?.thumb_x2 || '',
    headImageUrl: course.head_image?.url || '',
    id: hash,
    imageThumbnailsThumbX1: course.image?.thumbnails?.thumb_x1 || '',
    imageThumbnailsThumbX2: course.image?.thumbnails?.thumb_x2 || '',
    imageUrl: course.image?.url || '',
    info: course.info || '',
    isConversation: false,
    isBookmark: course.is_bookmark ?? false,
    isFree: course.is_free ?? false,
    isIntroCourse: false,
    isLastCourse: false,
    isLastIntro: false,
    isHighlighted:
      stringToEnum(CourseStatus, course.status) === CourseStatus.FINISHED
        ? false
        : course.highlighted,
    isNew: false,
    isNewText: '',
    packArtists: [] as Person[],
    packAutoPlay: false,
    packCategory: PackCategory.standard,
    packEndMessage: '',
    packHash: '',
    packHasNewCourses: false,
    packId: '',
    packImageThumbnailsThumbX1: '',
    packImageUrl: '',
    packIsNew: false,
    packIsPrimaryPack: false,
    packOverwriteCourseImage: false,
    packParentPackId: '',
    packPersons: [] as Person[],
    packProgress: 0,
    packSpeakers: [] as Person[],
    packSubtitle: '',
    packSummary: '',
    packTeachers: [] as Person[],
    packTeachersAndSpeakers: [] as Person[],
    packTitle: '',
    packType: PackType.meditation,
    permission: CoursePermission.UNLOCKED,
    persons: [] as Person[],
    personsInfo: course.persons_info?.map(normalizePersonInfo) || [],
    playedLastTimeAt: (course.played_last_time_at as string) || '',
    previews:
      course.previews
        ?.filter(notEmpty)
        .map((v) => ({ id: v.id, length: v.length || 0, url: v.url || '' })) ||
      [],
    primaryPackId:
      course.packs_info?.find(({ is_primary_pack }) => !!is_primary_pack)
        ?.pack_id || null,
    progress: 0,
    retreatHash: '',
    retreatId: '',
    retreatTitle: '',
    section: course.course_type === 'lesson' ? 'theory' : 'practice',
    shareable: course.shareable ?? false,
    speakers: [] as Person[],
    speedControlLevel: course.speed_control_level || SpeedControlLevel.disabled,
    status: stringToEnum(CourseStatus, course.status) || CourseStatus.UNSTARTED,
    subtitle: course.subtitle || '',
    summary: course.summary || '',
    tags: course.tags?.map((tag) => ({ id: tag.id, value: tag.value })) || [],
    teachers: [] as Person[],
    teachersAndSpeakers: [] as Person[],
    title: course.title || '',
    typename: 'Course',
  };
};

export type Course = ReturnType<typeof normalizeCourse>;

export const getCoursePermission = (isSubscribed: boolean, isFree: boolean) => {
  if (!isSubscribed && !isFree) {
    return CoursePermission.FORBIDDEN;
  }
  return CoursePermission.UNLOCKED;
};

export const getNextIntroCourse = (introCourses: Course[]) => {
  const introCoursesDescSorted = introCourses.reverse();

  const higherIntroCourseFinishedIndex = introCoursesDescSorted.findIndex(
    ({ status }) => status === CourseStatus.FINISHED,
  );

  if (higherIntroCourseFinishedIndex === -1) {
    return introCoursesDescSorted[introCoursesDescSorted.length - 1];
  }
  if (higherIntroCourseFinishedIndex === 0) {
    return introCoursesDescSorted[0];
  }
  return introCoursesDescSorted[higherIntroCourseFinishedIndex - 1];
};
