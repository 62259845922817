import { useCallback, useEffect, useMemo, useState } from 'react';
import copy from 'clipboard-copy';
import { AppConfig } from 'config';
import useMe from 'graphql/hooks/useMe';
import useModal from 'graphql/hooks/useModal';
import usePlayerActions from 'graphql/hooks/usePlayerActions';
import useModalActions from 'graphql/hooks/useModalActions';
import { ModalType } from 'graphql/reactive';
import { trackEvent } from 'services/analytics';

const useConnect = () => {
  const { profile } = useMe();
  const { isOpen, payload } = useModal(ModalType.SHARE_APP);
  const { openCoursePlayer } = usePlayerActions();
  const { closeModal, openSendEmailModal } = useModalActions();

  const [showPopup, setShowPopup] = useState(false);

  const source = payload?.source;
  const token = profile.shareCode || undefined;

  const link = useMemo(
    () => (token ? `${AppConfig.dynamicBaseUrl}/shareOpenAccess/${token}` : ''),
    [token],
  );

  useEffect(() => {
    if (isOpen) {
      trackEvent('Share Begin', { origin: source, token });
    }
  }, [isOpen, source, token]);

  const handleCopyLink = useCallback(() => {
    copy(link);

    trackEvent('Share App 30 days', { method: 'copy_link', source, token });

    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 4000);
  }, [link, source, token]);

  const handleOpenCourse = useCallback(() => {
    // TODO: Determine which course to open
    closeModal();
    setTimeout(() => openCoursePlayer({ hash: 'CBB4D9' }), 300);
  }, [closeModal, openCoursePlayer]);

  const handleOpenSendEmailModal = useCallback(() => {
    setTimeout(() => openSendEmailModal(), 300);
  }, [openSendEmailModal]);

  return {
    handleClose: closeModal,
    handleCopyLink,
    handleOpenCourse,
    handleOpenSendEmailModal,
    isOpen,
    link,
    showPopup,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
