/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import colorWithAlpha from 'lib/color';

export const Container = styled.button<{ $active: boolean }>`
  background: none;
  border: none;
  border-bottom: solid 0.125rem transparent;
  cursor: pointer;
  display: inline-block;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.01em;
  line-height: 1.429;
  outline: none;
  padding: 0 0 ${({ theme }) => theme.measures.m} 0;
  text-decoration: none;
  white-space: nowrap;

  ${({ $active, theme }) => {
    if ($active) {
      return css`
        border-bottom: solid 0.125rem ${theme.colors.fontEmphasized} !important;
        color: ${theme.colors.fontEmphasized} !important;
      `;
    }

    return theme.name === 'LIGHT'
      ? css`
          color: ${theme.colors.coreNeutral500};

          &:hover {
            border-bottom: solid 0.125rem
              ${colorWithAlpha({
                color: theme.colors.coreNeutralBlack,
                alpha: 0.1,
              })};
            color: ${colorWithAlpha({
              color: theme.colors.coreNeutralBlack,
              alpha: 0.55,
            })};
          }
          &:focus {
            border-bottom: solid 0.125rem
              ${colorWithAlpha({
                color: theme.colors.coreNeutralBlack,
                alpha: 0.33,
              })};
            color: ${colorWithAlpha({
              color: theme.colors.coreNeutralBlack,
              alpha: 0.6,
            })};
          }
        `
      : css`
          color: ${theme.colors.coreNeutral400};

          &:hover {
            border-bottom: solid 0.125rem
              ${colorWithAlpha({
                color: theme.colors.coreNeutralWhite,
                alpha: 0.05,
              })};
            color: ${colorWithAlpha({
              color: theme.colors.coreNeutralWhite,
              alpha: 0.5,
            })};
          }
          &:focus {
            border-bottom: solid 0.125rem
              ${colorWithAlpha({
                color: theme.colors.coreNeutralWhite,
                alpha: 0.15,
              })};
            color: ${colorWithAlpha({
              color: theme.colors.coreNeutralWhite,
              alpha: 0.6,
            })};
          }
        `;
  }}
`;
