/* eslint-disable import/prefer-default-export */
import { Props as IconProps } from 'components/Icon/types';

export const ICONS: Record<string, IconProps['name']> = {
  '0.8': 'numberPlaybackSpeed08xLight',
  '1.0': 'numberPlaybackSpeed1xLight',
  '1.2': 'numberPlaybackSpeed12xLight',
  '1.4': 'numberPlaybackSpeed14xLight',
  '1.6': 'numberPlaybackSpeed16xLight',
  '1.8': 'numberPlaybackSpeed18xLight',
  '2.0': 'numberPlaybackSpeed2xLight',
};
