import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultButtonPlay from 'components/ButtonPlay';
import { Props } from '../types';

export const Container = styled.div`
  align-items: center;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translate(0, -50%);

  ${media.tabletPortrait} {
    right: 0.5rem;
  }
`;

export const ButtonPlay = styled(DefaultButtonPlay)<{
  $isLoading: Props['isLoading'];
}>`
  flex: 0 0 auto;
  height: 1.8rem;
  pointer-events: ${({ $isLoading }) => ($isLoading ? 'none' : 'default')};
  position: relative;
  width: 1.8rem;

  ${media.tabletPortrait} {
    height: 2.6rem;
    width: 2.6rem;
  }
`;
