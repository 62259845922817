import { memo, useCallback } from 'react';
import type { FC, MouseEvent } from 'react';
import { setOptionsMenuVar } from 'graphql/reactive';
import { ButtonIcon } from './styles';
import type { Props } from './types';

const Options: FC<Props> = ({ dailyId, sharedHash }) => {
  const handleOpen = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setOptionsMenuVar({
        anchorEl: event.currentTarget,
        dailyId,
        sharedHash,
        offset: '0, -100%p',
        placement: 'top-end',
        type: 'dailyPlayer',
      });
    },
    [dailyId, sharedHash],
  );

  return (
    <ButtonIcon
      aria-label="player options menu"
      iconName="threeDotsVerticalLight"
      id="wu-player-options"
      onClick={handleOpen}
    />
  );
};

export default memo(Options);
