import styled from 'styled-components';
import colorWithAlpha from 'lib/color';
import { blink } from 'styles/mixins';

export const Container = styled.div`
  overflow: hidden;
`;

export const AspectRatioBox = styled.div<{ $aspectRatio: number }>`
  height: 0;
  overflow: hidden;
  padding-top: ${({ $aspectRatio }) => `${(1 / $aspectRatio) * 100}%`};
  position: relative;
`;

export const Img = styled.img`
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const Loader = styled.div`
  animation: ${blink} 3s linear 3;
  background-color: ${({ theme }) =>
    colorWithAlpha({ color: theme.colors.fontDeemphasized, alpha: 0.8 })};
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
`;
