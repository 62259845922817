import { memo, type FC } from 'react';
import { AnimatePresence } from 'framer-motion';
import Portal from 'components/Portal';
import PackDetails from './PackDetails';
import CourseDetails from './CourseDetails';
import PersonDetails from './PersonDetails';
import { Animation, Button, Container, Content, Icon } from './styles';
import useLogic from './logic';

const Details: FC = () => {
  const {
    backRef,
    details,
    handleBack,
    handleKeyDown,
    hasDetails,
    hasPersonDetails,
    modalRef,
    personDetails,
  } = useLogic();

  const showPack = hasDetails && details?.type === 'pack';
  const showCourse = hasDetails && details?.type === 'course';
  const showPerson = hasPersonDetails;

  return (
    <Portal>
      <AnimatePresence initial={false}>
        {(hasDetails || hasPersonDetails) && (
          <Container onKeyDown={handleKeyDown} ref={modalRef}>
            <Button
              aria-label="Hide details"
              onClick={handleBack}
              ref={backRef}
            >
              <Icon aria-hidden="true" name="arrowLeftLight" />
            </Button>
            <Content>
              <Animation
                animate={{
                  x: showPerson ? '-100%' : '0%',
                  opacity: showPerson ? 0 : 1,
                  visibility: showPerson ? 'hidden' : 'visible',
                }}
                initial={{
                  x: showPerson ? '-100%' : '0%',
                  opacity: showPerson ? 0 : 1,
                  visibility: showPerson ? 'hidden' : 'visible',
                }}
              >
                {showPack && <PackDetails packId={details.packId} />}
                {showCourse && (
                  <CourseDetails
                    courseId={details.courseId}
                    packId={details.packId}
                  />
                )}
              </Animation>
              <Animation
                animate={{
                  x: showPerson ? '0%' : '100%',
                  opacity: showPerson ? 1 : 0,
                  visibility: showPerson ? 'visible' : 'hidden',
                }}
                initial={{
                  x: showPerson ? '0%' : '100%',
                  opacity: showPerson ? 1 : 0,
                  visibility: showPerson ? 'visible' : 'hidden',
                }}
              >
                {personDetails && <PersonDetails id={personDetails.personId} />}
              </Animation>
            </Content>
          </Container>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default memo(Details);
