import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultButton from 'components/Button';
import { Label } from 'components/Button/styles';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: -1.5rem;

  ${media.phoneLandscape} {
    margin-top: 0;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const Button = styled(DefaultButton)`
  background-color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreBlue500
      : theme.colors.coreTeal500};
  border: none;
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.coreNeutralAurora};
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralWhite
      : theme.colors.coreNeutralAbyss};
  cursor: pointer;
  display: inline-block;
  outline: none;
  padding: 0.75rem 1.5625rem;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  margin-top: 1.5rem;
  width: auto;

  &:hover,
  &:focus {
    background-color: ${({ theme }) =>
      theme.name === 'LIGHT'
        ? theme.colors.coreBlue700
        : theme.colors.coreTeal700};
    border: none;
  }

  &:not(:last-child) {
    margin-right: 1.875rem;
  }

  ${Label} {
    font-size: 1.0625rem;
    line-height: 1.3125rem;
    letter-spacing: 0;

    strong {
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
  }

  ${media.tabletLandscape} {
    &:not(:last-child) {
      margin-right: 1.25rem;
    }
  }

  ${media.phoneLandscape} {
    margin-bottom: 1.5rem;
    margin-top: 0;

    &:not(:last-child) {
      margin-right: 0;
    }

    ${Label} {
      font-size: 1.0625rem;
    }
  }
`;
