export enum SubscriptionStatus {
  success = 'success',
  error = 'error',
}

export type RouteParams = {
  status: SubscriptionStatus;
};

export type RouteState = {
  email: string;
  orderId: string;
  planAmount: number;
  planInterval: string;
  promoCode?: string;
};
