import { FC } from 'react';
import useMe from 'graphql/hooks/useMe';
import GiftCardPurchase from 'containers/GiftCardPurchase';
import { Container, Header } from './styles';

const GiftCardPurchasePage: FC = () => {
  const {
    profile: { email, fullName },
  } = useMe();

  return (
    <>
      <Header testId="info-header" title="Account" backHref="/account" />
      <Container>
        <GiftCardPurchase userEmail={email} userName={fullName} />
      </Container>
    </>
  );
};

export default GiftCardPurchasePage;
