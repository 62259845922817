import { useCallback } from 'react';
import type { FC } from 'react';
import { kebabCase } from 'lodash';
import NavTab from 'components/NavTab';
import type { Props } from './types';

const Tab: FC<Props> = ({ onClick, selectedTab, tabName, tabValue }) => {
  const handleClick = useCallback(() => {
    onClick(tabValue);
  }, [onClick, tabValue]);

  return (
    <NavTab
      key={tabValue}
      aria-controls={`${kebabCase(tabValue)}-pannel`}
      id={`${kebabCase(tabValue)}-tab`}
      isActive={selectedTab === tabValue}
      onClick={handleClick}
    >
      {tabName}
    </NavTab>
  );
};

export default Tab;
