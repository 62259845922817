import getRetreat from './getRetreat';

const getRetreatCourse = async (courseKey?: string, packKey?: string) => {
  const retreat = await getRetreat();

  if (!courseKey || !retreat?.pack?.courses) {
    return undefined;
  }

  const lwCourseKey = courseKey.toLowerCase();

  if (packKey) {
    const lwPackKey = packKey.toLowerCase();

    return retreat.pack.courses.find(
      ({ bdId, hash, id, packHash, packId }) =>
        (bdId.toLowerCase() === lwCourseKey ||
          hash.toLowerCase() === lwCourseKey ||
          id.toLowerCase() === lwCourseKey) &&
        (packHash.toLowerCase() === lwPackKey ||
          packId.toLowerCase() === lwPackKey),
    );
  }

  return retreat.pack.courses.find(
    ({ bdId, hash, id }) =>
      bdId.toLowerCase() === lwCourseKey ||
      hash.toLowerCase() === lwCourseKey ||
      id.toLowerCase() === lwCourseKey,
  );
};

export default getRetreatCourse;
