import { memo, type FC } from 'react';
import { Button } from './styles';
import type { Props } from './types';

const Manage: FC<Props> = ({
  isLoading,
  onCancel,
  onReactivate,
  renewIntent,
}) => {
  let text = renewIntent === 'WILL_RENEW' ? 'Cancel' : 'Reactivate';
  if (isLoading) {
    text = renewIntent === 'WILL_RENEW' ? 'Cancelling' : 'Reactivating';
  }

  return (
    <Button
      $isLoading={isLoading}
      disabled={isLoading}
      id={`stripe-${
        renewIntent === 'WILL_RENEW' ? 'cancel' : 'reactivate'
      }-subscription-button`}
      onClick={renewIntent === 'WILL_RENEW' ? onCancel : onReactivate}
      type="button"
    >
      {text} Subscription
    </Button>
  );
};

export default memo(Manage);
