import { useMemo } from 'react';
import { useQuery, type QueryHookOptions } from '@apollo/client';
import {
  TrialUserDocument,
  type TrialUserQuery,
  type TrialUserQueryVariables,
} from '../generated';

const useTrialUser = (
  options: QueryHookOptions<TrialUserQuery, TrialUserQueryVariables> = {},
) => {
  const { data, error, loading } = useQuery(TrialUserDocument, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  const trialUser = useMemo(
    () => (data?.trialUser ? data.trialUser : undefined),
    [data],
  );

  return { trialUser, error, loading };
};

export default useTrialUser;
export type UseTrialUser = ReturnType<typeof useTrialUser>;
