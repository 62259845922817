import styled from 'styled-components';
import DefaultTextField from 'components/Inputs/TextField';
import { IconButton as DefaultIconButton } from '../styles';

export const Header = styled.div`
  align-items: center;
  color: inherit;
  display: flex;
  font-weight: inherit;
  position: relative;
`;

export const IconButton = styled(DefaultIconButton)`
  top: unset;
`;

export const TextField = styled(DefaultTextField)`
  margin-top: 3rem;
`;
