/* eslint-disable import/prefer-default-export */
export const FEATURES = {
  standard: [
    '<strong>Release negative thoughts</strong> with meditations for beginners.',
    '<strong>Explore deeper levels of consciousness</strong> with advanced meditations.',
    '<strong>Listen to world-renowned scholars</strong> and teachers in conversation.',
    '<strong>Learn ancient wisdom</strong>, pressure-tested by modern science.',
  ],
  lifetime: [
    '<strong>Release negative thoughts</strong> with meditations for beginners.',
    '<strong>Explore deeper levels of consciousness</strong> with advanced meditations.',
    '<strong>Listen to world-renowned scholars</strong> and teachers in conversation.',
    '<strong>Learn ancient wisdom</strong>, pressure-tested by modern science.',
    '<strong>Access an ever-growing library</strong> of sessions on life’s most important questions.',
  ],
};
