import { createTheme } from '@mui/material';
import colorWithAlpha from 'lib/color';
import { SnackbarOrigin, CombinedClassKey } from 'notistack';

export const basePalette = {
  coreNeutral50: '#dee0e4',
  coreNeutral100: '#d1d2d3',
  coreNeutral200: '#c2c7cb',
  coreNeutral300: '#a8a8a8',
  coreNeutral400: '#8d8d8d',
  coreNeutral500: '#6f6f6f',
  coreNeutral600: '#4e5357',
  coreNeutral700: '#393939',
  coreNeutral800: '#262626',
  coreNeutral900: '#161616',
  coreNeutralAbyss: '#0e1529',
  coreNeutralAurora: '#f4f4f4',
  coreNeutralBlack: '#000000',
  coreNeutralWhite: '#ffffff',
  coreBlue100: '#85cdea',
  coreBlue300: '#00a8dc',
  coreBlue500: '#2565f5',
  coreBlue700: '#0d34a5',
  coreBlue900: '#0e1e62',
  coreTeal50: '#ebf0ee',
  coreTeal100: '#88f5df',
  coreTeal300: '#19f4ca',
  coreTeal500: '#00d0bd',
  coreTeal700: '#006067',
  coreTeal900: '#002c33',
  coreTeal950: '#0f2b43',
  coreRed100: '#fdcdc6',
  coreRed300: '#ff7770',
  coreRed500: '#ff4f53',
  coreRed700: '#942950',
  coreRed900: '#6c183a',
  corePurple100: '#d3b4ea',
  corePurple300: '#ab82ff',
  corePurple500: '#a151f5',
  corePurple700: '#55286d',
  corePurple900: '#321431',
  coreOrange100: '#deb679',
  coreOrange300: '#ffd345',
  coreOrange500: '#ffb300',
  coreOrange700: '#bc5b0c',
  coreOrange900: '#492f35',
  coreGreen100: '#f5fd57',
  coreGreen300: '#a3ef00',
  coreGreen500: '#00a93c',
  coreGreen700: '#00542f',
  coreGreen900: '#012d18',
};

export const lightPalette = {
  backgroundBase: basePalette.coreNeutralAurora,
  backgroundCard: basePalette.coreNeutralWhite,
  backgroundModal: basePalette.coreNeutralAurora,
  borderBase: colorWithAlpha({
    color: basePalette.coreNeutralBlack,
    alpha: 0.1,
  }),
  borderButtonEmphasized: basePalette.coreBlue700,
  borderButtonDeemphasized: basePalette.coreNeutral200,
  fontBase: basePalette.coreNeutralAbyss,
  fontEmphasized: '#215adb',
  fontDeemphasized: basePalette.coreNeutral500,
  fontButtonEmphasized: basePalette.coreBlue700,
  fontButtonDeemphasized: basePalette.coreBlue700,
  fontInputBase: basePalette.coreNeutralAbyss,
  fontLabelActivated: basePalette.coreNeutralAbyss,
  fontLabelFocused: '#215adb',
  fontLabelError: basePalette.coreRed700,
  scrollbarColor: '#8294A5',
};

export const darkPalette = {
  backgroundBase: basePalette.coreNeutralAbyss,
  backgroundCard: colorWithAlpha({
    color: basePalette.coreNeutralBlack,
    alpha: 0.25,
  }),
  backgroundModal: basePalette.coreNeutralAbyss,
  borderBase: colorWithAlpha({
    color: basePalette.coreNeutralWhite,
    alpha: 0.15,
  }),
  borderButtonEmphasized: basePalette.coreBlue700, // TODO: Define this color
  borderButtonDeemphasized: basePalette.coreNeutral200, // TODO: Define this color
  fontBase: basePalette.coreNeutralAurora,
  fontEmphasized: basePalette.coreTeal500,
  fontDeemphasized: basePalette.coreNeutral400,
  fontButtonEmphasized: basePalette.coreBlue700, // TODO: Define this color
  fontButtonDeemphasized: basePalette.coreBlue700, // TODO: Define this color
  fontInputBase: basePalette.coreNeutralAurora,
  fontLabelActivated: basePalette.coreNeutralAurora,
  fontLabelFocused: basePalette.coreTeal500,
  fontLabelError: basePalette.coreRed500,
  scrollbarColor: '#8294A5',
};

const measures = {
  // Borders
  radius: {
    xsm: '0.125rem',
    sm: '0.25rem',
    md: '0.5rem',
    lg: '1rem',
  },

  font: {
    family: {
      druk: 'Druk, sans-serif',
      inter: 'Inter, sans-serif',
    },
    weight: {
      book: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      black: 900,
    },
  },

  measures: {
    xxs: '0.25rem',
    xs: '0.5rem',
    s: '0.75rem',
    m: '1rem',
    l: '1.5rem',
    x: '2rem',
    xl: '4rem',
    xxl: '8rem',
  },

  elevation: {
    web1: '0rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.08), 0rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.06), 0rem 0.125rem 0.5rem rgba(0, 0, 0, 0.04)',
    web2: '0rem 0.125rem 0.25rem -0.125rem rgba(0, 0, 0, 0.12), 0rem 0.5rem 1rem 0.0625rem rgba(0, 0, 0, 0.1), 0rem 1rem 2rem rgba(0, 0, 0, 0.08)',
    web3: '0rem 0.25rem 0.5rem -0.25rem rgba(0, 0, 0, 0.16), 0rem 1rem 2.625rem 0.125rem rgba(0, 0, 0, 0.14), 0rem 1.5rem 2.25rem rgba(0, 0, 0, 0.12)',
    web4: '0rem 0.5rem 2rem -0.375rem rgba(0, 0, 0, 0.18), 0rem 1.5rem 3rem 0.1875rem rgba(0, 0, 0, 0.16), 0rem 4rem 3rem rgba(0, 0, 0, 0.14)',
  },
};

const light = {
  ...measures,
  name: 'LIGHT' as 'LIGHT' | 'DARK',
  colors: {
    ...basePalette,
    ...lightPalette,
  },
};

const dark = {
  ...measures,
  name: 'DARK' as 'LIGHT' | 'DARK',
  colors: {
    ...basePalette,
    ...darkPalette,
  },
};

export type Theme = typeof light;
const themes = {
  light,
  dark,
};

export const createMaterialUITheme = (theme: Theme) =>
  createTheme({
    palette: {
      primary: {
        main: theme.colors.fontEmphasized,
      },
      secondary: {
        main: theme.colors.coreBlue300,
        contrastText: '#ffcc00',
      },
    },
    typography: {
      fontFamily: theme.font.family.inter,
    },
  });

export const notistackConfig: {
  maxSnack: number;
  anchorOrigin: SnackbarOrigin;
  classes: Partial<Record<CombinedClassKey, string>>;
} = {
  maxSnack: 3,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  classes: {
    variantSuccess: 'wu-snackbar-success',
    variantError: 'wu-snackbar-error',
    variantInfo: 'wu-snackbar-info',
  },
};

export default themes as { [key in keyof typeof themes]: Theme };
