import { memo } from 'react';
import type { FC } from 'react';
import { Button, Container } from './styles';
import { Props } from './types';

const Buttons: FC<Props> = ({ currentUrl, giftCardPrices }) => (
  <Container>
    {giftCardPrices.map((giftCard) => (
      <Button
        key={giftCard.type}
        to={`${currentUrl}${giftCard.urlSuffix}`}
        id={`purchase-gift-card-${giftCard.type
          .toLowerCase()
          .replace(/_/g, '-')}`}
      >
        <strong>{giftCard.duration}</strong> ($
        {Math.round(giftCard.amount / 100)})
      </Button>
    ))}
  </Container>
);

export default memo(Buttons);
