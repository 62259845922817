import { memo, type FC } from 'react';
import type { Panel } from 'components/Panels/types';
import Teachers from './Teachers';
import Artwork from './Artwork';
import { Container, Html, PanelContent, Panels } from './styles';
import type { Props } from './types';

const DetailsTabs: FC<Props> = ({
  artists,
  className,
  hideTeacherLinks,
  imageUrl,
  summary,
  teachers,
}) => {
  const panels = [] as Panel[];

  if (summary)
    panels.push({
      info: (
        <PanelContent>
          <Html dangerouslySetInnerHTML={{ __html: summary }} />
        </PanelContent>
      ),
      title: 'Summary',
    });

  if (teachers.length > 0)
    panels.push({
      info: (
        <PanelContent>
          <Teachers hideLinks={hideTeacherLinks} teachers={teachers} />
        </PanelContent>
      ),
      title: `Teacher${teachers.length > 1 ? 's' : ''}`,
    });

  if (!!imageUrl || artists.length > 0)
    panels.push({
      info: (
        <PanelContent>
          <Artwork artists={artists} imageUrl={imageUrl} />
        </PanelContent>
      ),
      title: 'Artwork',
    });

  return (
    <Container className={className}>
      <Panels data={panels} />
    </Container>
  );
};

export default memo(DetailsTabs);
