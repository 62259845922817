import { useCallback, useState } from 'react';
import type { FC } from 'react';
import { AnimatePresence } from 'framer-motion';
import useMediaQuery from 'lib/useMediaQuery';
import { media } from 'styles/mixins';
import Blur from './Blur';
import {
  ButtonCTA,
  Collapsable,
  Container,
  Description,
  Flex,
  Image,
  Info,
  Title,
} from './styles';
import type { Props } from './types';

const Banner: FC<Props> = ({
  className,
  description,
  imageAlt = '',
  imageUrl,
  showFullDescription,
}) => {
  const [isDescriptionOpen, setIsDescriptionOpen] = useState<boolean>(false);
  const isPhoneLandscape = useMediaQuery(media.phoneLandscape);

  const handleToggleDescription = useCallback(() => {
    setIsDescriptionOpen((v) => !v);
  }, []);

  const showDescription = !!description && showFullDescription;
  const showCollapsableDescription = !!description && !showFullDescription;

  return (
    <Container className={className}>
      <Flex>
        {isPhoneLandscape && imageUrl && <Blur imageUrl={imageUrl} />}
        <Info>
          <Title>INTRODUCTORY COURSE</Title>
        </Info>
        <Image alt={imageAlt} src={imageUrl} />
      </Flex>
      {showDescription && <Description>{description}</Description>}
      {showCollapsableDescription && (
        <>
          <AnimatePresence>
            {isDescriptionOpen && (
              <Collapsable>
                <Description>{description}</Description>
              </Collapsable>
            )}
          </AnimatePresence>
          <ButtonCTA
            active={isDescriptionOpen}
            onClick={handleToggleDescription}
          >
            Read more
          </ButtonCTA>
        </>
      )}
    </Container>
  );
};

export default Banner;
