import styled, { css } from 'styled-components';
import { blink, media } from 'styles/mixins';
import DefaultIsotype from 'components/Isotype';
import { Props } from './types';

export const Container = styled.div`
  animation: ${blink} 3s linear infinite;
  border-radius: ${({ theme }) => theme.radius.md};
  height: 100%;
`;

export const Image = styled(DefaultIsotype)<{ $layout: Props['layout'] }>`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? 'rgba(141, 141, 141, 0.8)'
      : 'rgba(141, 141, 141, 0.8)'};
  display: block;
  height: 8.75rem;
  width: 8.75rem;
  margin-bottom: 2.2rem;
  transform: translateY(2%);

  ${({ $layout }) =>
    $layout === 'horizontal' &&
    css`
      margin-bottom: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    `}
`;

export const Info = styled.div`
  min-width: 0;
  position: relative;
  z-index: 1;
`;

export const Overline = styled.div<{ $layout: Props['layout'] }>`
  background: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? 'rgba(141, 141, 141, 0.8)'
      : 'rgba(141, 141, 141, 0.8)'};
  height: 0.58rem;
  margin-bottom: ${({ theme }) => theme.measures.xs};
  width: 8.6rem;

  ${({ $layout }) =>
    $layout === 'horizontal' &&
    css`
      margin-bottom: ${({ theme }) => theme.measures.xxs};
    `}

  ${media.phoneLandscape} {
    width: 7.8rem;
  }
`;

export const Title1 = styled.div<{ $layout: Props['layout'] }>`
  background: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? 'rgba(141, 141, 141, 0.8)'
      : 'rgba(141, 141, 141, 0.8)'};
  height: 3.3rem;
  margin-bottom: 0.35rem;
  width: 10rem;

  ${media.tabletLandscape} {
    height: 3rem;
  }

  ${media.tabletPortrait} {
    height: 3.3rem;
    width: 11.4rem;
  }

  ${media.phoneLandscape} {
    width: 8rem;
  }

  ${({ $layout }) =>
    $layout === 'horizontal' &&
    css`
      height: 2.6rem;

      ${media.tabletLandscape} {
        height: 2.6rem;
      }

      ${media.tabletPortrait} {
        height: 2.6rem;
      }

      ${media.phoneLandscape} {
        height: 2.25rem;
      }
    `}
`;

export const Title2 = styled(Title1)`
  width: 12.75rem;

  ${media.tabletLandscape} {
    width: 11rem;
  }

  ${media.tabletPortrait} {
    width: 12.4rem;
  }

  ${media.phoneLandscape} {
    width: 9rem;
  }
`;

export const Subtitle = styled.div`
  background: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? 'rgba(141, 141, 141, 0.8)'
      : 'rgba(141, 141, 141, 0.8)'};
  height: 0.8rem;
  margin-top: 1rem;
  width: 8rem;

  ${media.tabletLandscape} {
    margin-top: 0.75rem;
  }

  ${media.phoneLandscape} {
    height: 0.7rem;
    margin-bottom: 0.58rem;
    margin-top: 1rem;
  }
`;
