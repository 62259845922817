/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const TAG_DATA_FRAGMENT = gql`
  fragment TagData on Tag {
    __typename
    color
    id
    type
    value
  }
`;
