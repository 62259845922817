/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
  font-size: 1rem;
  line-height: 1.375;
`;

export const StandardPrice = styled.p`
  font-size: 0.85rem;
  margin-bottom: 0.3rem;
  opacity: 0.75;
`;

export const StrikeThrough = styled.span<{ visible: boolean }>`
  font-size: inherit;
  padding-right: 0.2rem;
  position: relative;

  &:before {
    border-top: 2px solid #ff0000;
    content: '';
    display: ${({ visible }) => (visible ? 'block' : 'none')};
    left: 0;
    position: absolute;
    right: 0;
    top: 40%;
    transform: rotate(10deg);
  }
`;

export const CurrentPrice = styled.p`
  font-weight: ${({ theme }) => theme.font.weight.semibold};
`;
