import type { FC } from 'react';
import Form from './Form';
import { Modal } from './styles';
import useConnect from './connect';

const FullScholarshipModal: FC = () => {
  const {
    fullScholarshipPrices,
    handleClose,
    handleSubmit,
    initialValues,
    isLoading,
    isOpen,
  } = useConnect();

  return (
    <Modal
      descriptionId="fullScholarship-description"
      fullscreenOnMobile
      onClose={handleClose}
      open={isOpen}
      titleId="fullScholarship-title"
    >
      <Form
        descriptionId="fullScholarship-description"
        fullScholarshipPrices={fullScholarshipPrices}
        initialValues={initialValues}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        titleId="fullScholarship-title"
      />
    </Modal>
  );
};

export default FullScholarshipModal;
