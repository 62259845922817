import { memo } from 'react';
import type { FC } from 'react';
import { ButtonIcon } from './styles';
import type { Props } from './types';

const Share: FC<Props> = ({ className, onClick }) => (
  <ButtonIcon
    aria-label="share this course"
    className={className}
    iconName="shareLight"
    id="wu-player-share"
    onClick={onClick}
  />
);

export default memo(Share);
