import styled from 'styled-components';
import { Body, Display } from 'components/Typography';
import { media } from 'styles/mixins';

export const Container = styled.div`
  padding: 0 1.6rem;
  position: relative;
  height: 100%;

  ${media.phonePortrait} {
    padding: 0 1rem;
  }

  @media (max-height: 450px) {
    align-items: center;
    padding: 0 4rem;
    display: flex;
  }
`;

export const Image = styled.img`
  display: block;
  height: 50%;
  left: 50%;
  max-height: 18rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -33%);
  width: auto;

  @media (max-height: 450px) {
    height: 10rem;
    position: relative;
    left: auto;
    transform: none;
    top: auto;
  }
`;

export const Info = styled.div`
  text-size-adjust: none;
  text-align: center;

  @media (max-height: 450px) {
    margin-left: 2rem;
    text-align: left;
  }
`;

export const Title = styled(Display).attrs(() => ({ $size: 'small' }))`
  line-height: 1.25;
  margin-bottom: 0.6rem;
`;

export const Description = styled(Body)``;
