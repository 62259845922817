import { memo } from 'react';
import type { FC } from 'react';
import { Props } from './types';

const LogoBlackStacked: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="none"
    height={(size * 173) / 438}
    viewBox="0 0 438 173"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M250.02 46.19h6.66l-8.03-22.65v.03C244.6 9.94 231.99 0 217.05 0c-18.21 0-32.97 14.76-32.97 32.97 0 8.98 3.59 17.11 9.41 23.06h-.01l.19.19.15.15 43.02 42.66V65.94h13.17V46.19h.01z"
      fill="currentColor"
    />
    <path
      d="M250 46.36h6.67l-8-22.61C243.58 9.31 232 .17 217.05.17a33 33 0 00-23.55 56l43.35 43V66.11H250V46.36zM95.19 118.75l25.47 52.69h-9.18l-5.43-11.6h-21.8l-5.43 11.6h-9.1l25.47-52.69zm-7.27 33.32h14.46l-7.19-15.42-7.27 15.42zM131.05 119.33h8.52v26.2l22.94-26.2h10.13l-23.24 26.5 25.46 25.61h-10.71l-24.58-24.59v24.59h-8.52v-52.11zM186.5 119.33h8.5v52.11h-8.5v-52.11zM346.77 151.17v-31.84h8.52v32.07c0 8.08 5.06 12.55 12.47 12.55 7.41 0 12.4-4.47 12.4-12.55v-32.07h8.52v31.84c0 12.85-8.66 20.84-20.92 20.84-12.26 0-20.99-7.98-20.99-20.84zM404 119.33h15.56c10.79 0 18.2 6.75 18.2 16.59 0 9.68-7.41 16.58-18.2 16.58h-7.05v18.94H404v-52.11zm15.19 25.25c6.24 0 9.91-3.45 9.91-8.66s-3.67-8.66-9.91-8.66h-6.68v17.32h6.68zM0 119.17h8.73l13.5 32L36 118.61l13.7 32.35 13.2-31.79h8.81L50 171.89l-14-33.72-14 33.72-22-52.72zM222.92 138.49v33h-8.51v-52.7l33.66 33.84v-33.3h8.52v52.69l-33.67-33.53zM291.33 149.17h16.52v12a20.475 20.475 0 01-10.64 2.79c-10.27 0-18-8.14-18-18.57s7.71-18.56 18-18.56a19.249 19.249 0 0112.07 4.21l5.81-5.82a26.778 26.778 0 00-24.123-5.947 26.771 26.771 0 00-6.163 49.644 26.773 26.773 0 0012.406 3.123 29.647 29.647 0 0019.16-6.68v-24.45h-21.65l-3.39 8.26z"
      fill="currentColor"
    />
  </svg>
);

export default memo(LogoBlackStacked);
