import { useCallback } from 'react';
import { AppConfig } from 'config';
import { trackEvent } from 'services/analytics';
import { RetreatUserState, UserNotificationType } from 'graphql/generated';
import { resetOptionsMenuVar } from 'graphql/reactive';
import { getProfile } from 'graphql/requests';
import useRetreat from 'graphql/hooks/useRetreat';
import useModalActions from 'graphql/hooks/useModalActions';
import useMe from 'graphql/hooks/useMe';
import useMeActions from 'graphql/hooks/useMeActions';

const useConnect = () => {
  const { retreat } = useRetreat();
  const { notifications } = useMe();
  const { openLeaveRetreatModal, openShareLinkModal } = useModalActions();
  const { updateNotifications } = useMeActions();

  const {
    addToCalendarUrl = '',
    hash,
    id,
    userState = RetreatUserState.idle,
    title = '',
  } = retreat || {};
  const hasReserved = userState === RetreatUserState.reserved;
  const notificationsEnabled = notifications.some(
    (v) => v.id === UserNotificationType.retreats,
  );

  const handleAddToCalendar = useCallback(() => {
    trackEvent('Event Add to Calendar', {
      event_hash: hash,
      event_type: 'retreat',
      name: title,
      response: 'Add to Your Calendar',
      source: 'retreat_three_dots',
    });

    resetOptionsMenuVar();
  }, [hash, title]);

  const handleLeave = useCallback(() => {
    if (hasReserved) {
      resetOptionsMenuVar();
      openLeaveRetreatModal();
    }
  }, [hasReserved, openLeaveRetreatModal]);

  const handleToggleNotifications = useCallback(async () => {
    if (hasReserved) {
      const { notifications: currentNotifications } = await getProfile();
      const retreatNotificationEnabled = currentNotifications.some(
        (v) => v.id === UserNotificationType.retreats,
      );

      trackEvent('Event Reminder Prompt', {
        event_hash: hash,
        event_type: 'retreat',
        name: title,
        response: !retreatNotificationEnabled ? 'enabled' : 'disabled',
        source: 'retreat_three_dots',
      });

      if (retreatNotificationEnabled) {
        await updateNotifications(
          currentNotifications.filter(
            (v) => v.id !== UserNotificationType.retreats,
          ),
        );
      } else {
        await updateNotifications([
          ...currentNotifications,
          { id: UserNotificationType.retreats },
        ]);
      }
    }
  }, [hash, hasReserved, title, updateNotifications]);

  const handleShare = useCallback(async () => {
    if (!hash || !id) return;

    trackEvent('Event Share', {
      event_hash: hash,
      event_type: 'retreat',
      name: title,
      source: 'retreat_three_dots',
    });

    resetOptionsMenuVar();
    openShareLinkModal({
      id,
      description:
        'Share this link with anyone you feel would benefit from this Waking Up retreat',
      link: `${AppConfig.dynamicBaseUrl}/retreat/${hash}`,
      title: 'Share this retreat',
    });
  }, [hash, id, openShareLinkModal, title]);

  return {
    addToCalendarUrl,
    handleAddToCalendar,
    handleLeave,
    handleShare,
    handleToggleNotifications,
    hasReserved,
    notificationsEnabled,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
