import styled from 'styled-components';
import DefaultTextField from '@mui/material/TextField';
import colorWithAlpha from 'lib/color';
import DefaultIcon from 'components/Icon';

export const ClearButton = styled.button`
  background: transparent;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: block;
  outline: none;
  position: absolute;
  right: 0.625rem;
  bottom: 0.625rem;
  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.fontEmphasized};
  }

  &:focus {
    > svg {
      border-radius: 50%;
      box-shadow: 0 0 0 2px #f4f4f4, 0 0 0 3.5px rgba(0, 96, 103, 0.25);
    }
  }
`;

export const Icon = styled(DefaultIcon).attrs(({ theme }) => ({
  color:
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora,
}))``;

export const TextField = styled(DefaultTextField)<{ $hasIcon: boolean }>`
  &&& {
    * {
      transition: all 150ms ease;
    }
  }

  .MuiFilledInput-adornedStart {
    padding-left: 0.2rem;
  }

  .MuiFilledInput-underline:before {
    border-bottom: 1px solid
      ${({ theme }) =>
        theme.name === 'LIGHT'
          ? colorWithAlpha({
              color: theme.colors.coreNeutralBlack,
              alpha: 0.25,
            })
          : colorWithAlpha({
              color: theme.colors.coreNeutralWhite,
              alpha: 0.25,
            })};
  }

  .MuiInputBase-root {
    color: ${({ theme }) =>
      theme.name === 'LIGHT'
        ? colorWithAlpha({
            color: theme.colors.coreNeutralBlack,
            alpha: 0.55,
          })
        : colorWithAlpha({
            color: theme.colors.coreNeutralWhite,
            alpha: 0.5,
          })};
    background: none;
    padding-bottom: 0.8rem !important;
    padding-right: 2rem;
    padding-top: 1.25rem !important;

    &:hover,
    &.Mui-focused {
      background: none;
    }

    .MuiInputBase-input,
    .MuiInputFilledInput-input {
      color: white;
      color: ${({ theme }) =>
        theme.name === 'LIGHT'
          ? theme.colors.coreNeutralAbyss
          : theme.colors.coreNeutralAurora};
      padding: ${({ $hasIcon }) => ($hasIcon ? '0 0.4rem 0 0' : '0')};
      font-size: 1rem;
      font-weight: ${({ theme }) => theme.font.weight.book};
      line-height: 1.25;

      &.Mui-disabled {
        color: ${({ theme }) => `${theme.colors.coreNeutralAbyss}80`};
      }
    }

    &.MuiFilledInput-underline:before {
      border-bottom: 1px solid
        ${({ theme }) => `${theme.colors.coreNeutralAbyss}40`}!important;
      transition: border-color 200ms ease;
    }

    &.Mui-focused {
      &.MuiFilledInput-underline:before {
        border-bottom: 1px solid
          ${({ theme }) => `${theme.colors.fontEmphasized}`}!important;
      }
    }

    &.Mui-error {
      &.MuiFilledInput-underline:before {
        border-bottom: 1px solid
          ${({ theme }) => `${theme.colors.coreNeutralAbyss}40`}!important;
      }
    }

    &.MuiFilledInput-underline.Mui-error:after {
      display: none;
    }

    &.MuiFilledInput-underline.Mui-focused:after {
      display: none;
    }

    &.Mui-disabled {
      background: none;
    }
  }

  .MuiFormHelperText-root {
    display: none !important;
    background: blue !important;
  }
`;
