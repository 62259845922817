import flatMap from 'lodash/flatMap';
import getProfile from './getProfile';
import getPacks from './getPacks';

const getIntroCourses = async () => {
  const { introPackId } = await getProfile();
  const { packs } = await getPacks();

  const introSubpacks = packs.filter((p) => p.parentPackId === introPackId);

  return flatMap(introSubpacks, (c) => c.courses);
};

export default getIntroCourses;
