import styled from 'styled-components';
import DefaultModal from 'components/Modal';
import DefaultButton from 'components/Button';
import { media } from 'styles/mixins';

export const Modal = styled(DefaultModal)`
  .MuiPaper-root {
    margin: 0;
    max-width: 95vw;
    padding: 2.4rem;
    width: 40rem;

    ${media.phonePortrait} {
      padding: 2.4rem 1.4rem;
    }
  }
`;

export const Title = styled.h2`
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1;
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const Description = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
`;

export const Footer = styled.footer`
  border-top: 1px solid ${({ theme }) => theme.colors.coreNeutral300};
  margin-top: 1.5rem;
`;

export const FooterContent = styled.div`
  margin-top: 1.5rem;
`;

export const FooterTitle = styled.h3`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
`;

export const ContactUs = styled(DefaultButton)`
  &&& {
    margin-top: 1rem;
    width: 6rem;
  }
`;
