/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Container = styled.p`
  font-size: 1.0821rem;
  letter-spacing: -0.03rem;
  line-height: 1.21;
  margin-bottom: 2rem;
  max-width: 29.75rem;

  ${media.tabletPortrait} {
    max-width: none;
  }

  strong {
    font-size: inherit;
    font-weight: ${({ theme }) => theme.font.weight.semibold};
  }
`;
