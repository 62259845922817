import styled, { css } from 'styled-components';
import DefaultBrandIcon from './BrandIcon';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const BrandIcon = styled(DefaultBrandIcon)<{ $isGeneric: boolean }>`
  margin-right: 1.17rem;

  ${({ $isGeneric }) =>
    !$isGeneric &&
    css`
      background-color: ${({ theme }) => theme.colors.coreNeutralWhite};
      border: 1px solid ${({ theme }) => theme.colors.coreNeutral100};
      border-radius: 0.1114rem;
    `}
`;

export const CardBrand = styled.div`
  background-color: grey;
  height: 1.4487rem;
  width: 3.1756rem;
  margin-right: 1.17rem;
`;

export const Last4 = styled.p`
  color: ${({ theme }) => theme.colors.coreNeutralBlack};
  font-size: 1.0029rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: -0.04em;
  line-height: 1.53;
  margin-right: 3.6544rem;
  opacity: 0.7;
  text-align: center;
`;

export const ChangeCardButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.coreNeutralBlack};
  cursor: pointer;
  font-size: 0.78rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  height: 1.5625rem;
  letter-spacing: -0.04em;
  line-height: 1.97;
  opacity: 0.7;
  text-align: center;
  text-decoration-line: underline;
  text-transform: uppercase;
  min-width: 5.375rem;
`;
