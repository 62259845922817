import styled from 'styled-components';
import { motion } from 'framer-motion';
import { media } from 'styles/mixins';
import DefaultButtonCTA from 'components/ButtonCTA';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  left: 50%;
  position: absolute;
  top: 2.6rem;
  transform: translateX(-50%);

  ${media.tabletPortrait} {
    top: 2rem;
  }
`;

export const ButtonCTA = styled(DefaultButtonCTA)<{ active: boolean }>`
  background: transparent !important;
  box-shadow: none !important;
  color: ${({ active, theme }) =>
    active ? theme.colors.coreTeal100 : theme.colors.coreNeutralAurora};

  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: 3px;
  }

  &:active {
    color: ${({ theme }) => theme.colors.coreTeal100};
  }
`;

export const Menu = styled(motion.div).attrs(() => ({
  initial: 'closed',
  animate: 'open',
  exit: 'closed',
  variants: {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: '-0.6rem' },
  },
  transition: { duration: 0.25, ease: [0.19, 1, 0.22, 1] },
}))`
  background: ${({ theme }) => theme.colors.coreNeutralAurora};
  border-radius: 0.25rem;
  margin-top: ${({ theme }) => theme.measures.m};
  padding: ${({ theme }) => theme.measures.xs} 0;
`;
