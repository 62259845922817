import { useMemo } from 'react';
import { useQuery, type QueryHookOptions } from '@apollo/client';
import { normalizeRemoteConfig } from 'models/RemoteConfig';
import {
  GetRemoteConfigDocument,
  type GetRemoteConfigQuery,
} from '../generated';

const useRemoteConfig = (options?: QueryHookOptions<GetRemoteConfigQuery>) => {
  const { data, error, loading } = useQuery(GetRemoteConfigDocument, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  const remoteConfig = useMemo(
    () =>
      data?.remoteConfig ? normalizeRemoteConfig(data.remoteConfig) : undefined,
    [data],
  );

  return {
    data,
    error,
    loading,
    remoteConfig,
  };
};

export default useRemoteConfig;
export type UseRemoteConfig = ReturnType<typeof useRemoteConfig>;
