import styled from 'styled-components';
import DefaultModal from 'components/Modal';
import { media, prettyScrollbar } from 'styles/mixins';
import colorWithAlpha from 'lib/color';
import { Body as DefaultBody } from 'components/Typography';
import DefaultButton from 'components/Button';

export const Modal = styled(DefaultModal)`
  &&& {
    .MuiPaper-root {
      width: 52rem;
      max-width: 52rem;
      padding: 0;
    }
  }
`;

export const Title = styled(DefaultBody).attrs(() => ({
  $size: 'large',
  $weight: 'bold',
}))`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderBase};
  flex: 0 0 auto;
  padding: ${({ theme }) =>
    `${theme.measures.x} ${theme.measures.x} ${theme.measures.l} ${theme.measures.x}`};
`;

export const Content = styled.div`
  ${prettyScrollbar({ vertical: true })};
  background-color: ${({ theme }) =>
    colorWithAlpha({
      color: theme.colors.coreNeutralBlack,
      alpha: 0.01,
    })};
  flex: 1 1 auto;
  overflow-y: auto;
  padding: ${({ theme }) => theme.measures.x};
`;

export const Description = styled(DefaultBody).attrs(() => ({
  $size: 'medium',
  $weight: 'regular',
}))``;

export const Label = styled.label`
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  white-space: nowrap;
`;

export const TextArea = styled.textarea`
  background-color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralWhite
      : colorWithAlpha({
          color: theme.colors.coreNeutralWhite,
          alpha: 0.1,
        })};
  border: none;
  border-radius: ${({ theme }) => theme.radius.sm};
  color: ${({ theme }) =>
    colorWithAlpha({
      color:
        theme.name === 'LIGHT'
          ? theme.colors.coreNeutralBlack
          : theme.colors.coreNeutralWhite,
      alpha: 0.8,
    })};
  font-size: 0.875rem;
  margin-top: ${({ theme }) => theme.measures.m};
  outline: none;
  overflow: hidden;
  padding: 0.8rem;
  resize: none;
  width: 100%;

  ${media.tabletPortrait} {
    height: 4rem;
  }
  ${media.phoneLandscape} {
    height: 3.5rem;
  }
  ${media.phonePortrait} {
    height: 5.5rem;
  }
`;

export const Buttons = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.borderBase};
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  padding: ${({ theme }) => `${theme.measures.l} ${theme.measures.x}`};

  > :not(:last-child) {
    margin-right: ${({ theme }) => theme.measures.m};
  }

  ${media.phonePortrait} {
    justify-content: center;
  }
`;

export const Button = styled(DefaultButton)`
  display: inline-block;
  flex: 0 0 0;

  ${media.phonePortrait} {
    flex: 1 1 auto;
  }
`;
