import { LinkIconButton } from 'components/IconButton';
import ChevronLeft from 'components/Icons/ChevronLeft';
import DefaultMenuIcon from 'components/Icons/Menu';
import LogoBlackHorizontal from 'components/LogoBlackHorizontal';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { HEADER_HEIGHT, ZIndex } from 'styles/metrics';

export const Container = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${HEADER_HEIGHT};
  width: 100vw;
  background-color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralWhite
      : theme.colors.coreNeutralAbyss};
  padding: 0 1.5rem;
  z-index: ${ZIndex.HEADER};
`;

export const MenuButton = styled.button`
  background: transparent;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: block;
  line-height: 0;
  outline: none;
  margin: -0.5rem;
  padding: 0.5rem;
  transition: all 0.5s 0.05s;

  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

export const MenuIcon = styled(DefaultMenuIcon).attrs(() => ({ size: 28 }))``;

export const LogoLink = styled(Link)`
  line-height: 0;
  color: inherit;
  outline-offset: 0.5rem;
`;

export const Logo = styled(LogoBlackHorizontal).attrs(() => ({ size: 170 }))`
  display: block;
`;

export const BackButton = styled(LinkIconButton)`
  color: inherit;
`;

export const BackIcon = styled(ChevronLeft)`
  height: 1.25rem;
  width: 1.25rem;
`;

export const BackPlace = styled.div`
  width: 1.25rem;
`;
