import styled, { css } from 'styled-components';
import { media } from 'styles/mixins';

export const Container = styled.div<{ $isPublic: boolean }>`
  display: flex;
  padding-bottom: 4rem;

  ${({ $isPublic }) =>
    $isPublic
      ? css`
          padding-top: 8.5rem;
        `
      : css`
          align-items: center;
          flex-direction: column;
          max-width: 29.75rem;
          padding-bottom: 0;
        `}

  ${media.tabletLandscape} {
    padding-top: 1.25rem;
  }

  ${media.phoneLandscape} {
    flex-direction: column;
    padding: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2.375rem;

  ${media.phoneLandscape} {
    padding-top: 0;
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  font-size: 1.5625rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.21;
  margin-bottom: 1.375rem;
`;

export const ResultDescription = styled.p`
  color: ${({ theme }) => theme.colors.coreNeutralBlack};
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 1.7;

  a,
  strong {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  strong {
    font-weight: ${({ theme }) => theme.font.weight.semibold};
  }
`;
