import { useCallback } from 'react';
import type { FormikHelpers } from 'formik';
import parseISO from 'date-fns/parseISO';
import subMinutes from 'date-fns/subMinutes';
import { AppConfig } from 'config';
import { trackEvent } from 'services/analytics';
import { RetreatUserAction, UserNotificationType } from 'graphql/generated';
import {
  ModalType,
  updateDismissRetreatLastCheckedDate,
} from 'graphql/reactive';
import { getProfile, getRetreat } from 'graphql/requests';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import useRetreatActions from 'graphql/hooks/useRetreatActions';
import useMeActions from 'graphql/hooks/useMeActions';
import { FormFields, Step } from './Form/types';

const useConnect = () => {
  const { isOpen } = useModal(ModalType.RESERVE_RETREAT);
  const { closeModal, openShareLinkModal } = useModalActions();
  const { updateUserRetreat } = useRetreatActions();
  const { updateNotifications } = useMeActions();

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleSubmit = useCallback(
    async (
      { sendReminders, step }: FormFields,
      { setErrors, setFieldValue }: FormikHelpers<FormFields>,
    ) => {
      try {
        const retreat = await getRetreat();
        if (!retreat) return null;

        const { hash, id, startDate, title } = retreat;

        if (step === Step.reminders) {
          await updateUserRetreat({
            action: RetreatUserAction.reserve,
            retreatId: id,
          });

          trackEvent('Event Reminder Prompt', {
            event_hash: hash,
            event_type: 'retreat',
            name: title,
            response: sendReminders ? 'enabled' : 'disabled',
            source: 'event_reminder_modal',
          });

          const { id: userId, notifications } = await getProfile();
          const retreatNotificationEnabled = notifications.some(
            (v) => v.id === UserNotificationType.retreats,
          );
          if (sendReminders && !retreatNotificationEnabled) {
            await updateNotifications([
              ...notifications,
              { id: UserNotificationType.retreats },
            ]);
          } else if (!sendReminders && retreatNotificationEnabled) {
            await updateNotifications(
              notifications.filter(
                (v) => v.id !== UserNotificationType.retreats,
              ),
            );
          }

          const checkDate = subMinutes(parseISO(startDate), 1445);
          updateDismissRetreatLastCheckedDate(userId, checkDate.toISOString());

          setFieldValue('step', Step.calendar);
        } else if (step === Step.calendar) {
          trackEvent('Event Add to Calendar', {
            event_hash: hash,
            event_type: 'retreat',
            name: title,
            response: 'Maybe Later',
            source: 'event_calendar_prompt',
          });

          setFieldValue('step', Step.share);
        } else if (step === Step.share) {
          closeModal();

          trackEvent('Event Share', {
            event_hash: hash,
            event_type: 'retreat',
            name: title,
            source: 'calendar_prompt',
          });

          setTimeout(() => {
            openShareLinkModal({
              id: hash,
              description:
                'Share this link with anyone you feel would benefit from this Waking Up retreat',
              link: `${AppConfig.dynamicBaseUrl}/retreat/${hash}`,
              title: 'Share this retreat',
            });
          }, 400);
        }
      } catch (error) {
        setErrors({ submitError: error.message });
      }
    },
    [closeModal, openShareLinkModal, updateNotifications, updateUserRetreat],
  );

  return {
    handleClose,
    handleSubmit,
    isOpen,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
