import styled from 'styled-components';
import DefaultConfirmation from 'components/Confirmation';

export const Container = styled(DefaultConfirmation)``;

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-flow: column;
  text-align: center;
`;

export const Description = styled.h2`
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
`;

export const Link = styled.a`
  color: inherit;
  margin-left: 0.3rem;
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.25;
  margin-bottom: 1.5rem;
  text-align: center;
`;
