import type { FC } from 'react';
import useLogic from './logic';
import { Container, Error, Group, Option, Radio } from './styles';
import { Props } from './types';

const RadioGroup: FC<Props> = ({ className, name, id, options, disabled }) => {
  const { handleChange, touched, value, error } = useLogic({ name });

  return (
    <Container className={className} id={id}>
      <Group
        aria-label={name}
        name={name}
        value={value}
        onChange={handleChange}
      >
        {options.map(
          ({ value: optionValue, label, disabled: optionDisabled }) => (
            <Option
              control={<Radio />}
              value={optionValue}
              label={label}
              key={optionValue}
              disabled={disabled || optionDisabled}
            />
          ),
        )}
      </Group>
      {touched && error && <Error>{error}</Error>}
    </Container>
  );
};

export default RadioGroup;
