import { memo } from 'react';
import type { FC } from 'react';
import {
  BackButton,
  Button,
  ChevronLeft,
  Content,
  Footer,
  Modal,
  Result,
  Text,
  Title,
} from './styles';
import useConnect from './connect';

const UnlockFreeMonthSuccessModal: FC = () => {
  const { closeModal, courseHash, handlePlayFullCourse, isOpen } = useConnect();

  return (
    <Modal
      disableRestoreFocus
      fullscreenOnMobile
      hideClose
      onClose={closeModal}
      open={isOpen}
      titleId="unlock-free-month-success-title"
    >
      <BackButton onClick={closeModal}>
        <ChevronLeft />
      </BackButton>
      <Title id="unlock-free-month-success-title">WEEK TRIAL</Title>
      <Content>
        <Result>Success!</Result>
        <Text id="unlock-free-month-success-description">
          You now have a week to explore Waking Up. We think you'll find it's
          worth it.
        </Text>
        {courseHash && (
          <Button onClick={handlePlayFullCourse}>Listen now</Button>
        )}
        <Footer>No credit card required. Cancel anytime.</Footer>
      </Content>
    </Modal>
  );
};

export default memo(UnlockFreeMonthSuccessModal);
