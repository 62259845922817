import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse } from 'query-string';
import formatCents from 'lib/formatCents';
import { trackEvent } from 'services/analytics';
import { trackFacebookEvent } from 'services/analytics/facebook';
import { trackGAEvent } from 'services/analytics/ga';

const useCheckoutSessionSuccess = ({ screen }: { screen: string }) => {
  const { search, pathname, state } = useLocation();
  const navigate = useNavigate();

  const {
    amount: parsedAmount,
    code: parsedCode,
    email: parsedEmail,
    experiment: parsedExperiment,
    price: parsedPrice,
    sku: parsedSku,
    variant: parsedVariant,
  } = parse(search);
  const amount = typeof parsedAmount === 'string' ? Number(parsedAmount) : 0;
  const code = typeof parsedCode === 'string' ? parsedCode : '';
  const email = typeof parsedEmail === 'string' ? parsedEmail : '';
  const experiment =
    typeof parsedExperiment === 'string' ? parsedExperiment : '';
  const price = typeof parsedPrice === 'string' ? parsedPrice : '';
  const sku = typeof parsedSku === 'string' ? parsedSku : '';
  const variant = typeof parsedVariant === 'string' ? parsedVariant : '';

  useEffect(() => {
    if (amount && email && price && sku) {
      const experimentData =
        experiment && variant ? { experiment, variant } : {};

      trackFacebookEvent('track', 'Purchase', {
        value: amount / 100,
        currency: 'USD',
      });

      trackEvent('Purchase Completed', {
        ...experimentData,
        code,
        cost: formatCents(amount).slice(1),
        email,
        screen,
        subscription_option: sku,
        subscription_sku: sku,
      });

      trackGAEvent('event', `subscription_new`, {
        event_category: 'subscription',
        logged_in: 'no',
        subscription_option: price,
      });

      navigate(`${pathname}?email=${encodeURIComponent(email)}`, {
        replace: true,
      });
    }
  }, [
    amount,
    code,
    email,
    experiment,
    navigate,
    pathname,
    price,
    screen,
    sku,
    variant,
  ]);

  return {
    amount,
    code,
    email,
    pathname,
    price,
    search,
    sku,
    state: state as {},
  };
};

export default useCheckoutSessionSuccess;
