import { apolloClient } from 'services/api';
import { MeDocument, ContentStatus } from '../generated';

function cacheUnlockAllContent() {
  const response = apolloClient.readQuery({ query: MeDocument });

  const cacheMe = response?.me;

  if (cacheMe) {
    apolloClient.writeQuery({
      query: MeDocument,
      data: {
        __typename: 'Query',
        me: {
          ...cacheMe,
          content_status: ContentStatus.unlocked,
        },
      },
    });
  }
}

export default cacheUnlockAllContent;
