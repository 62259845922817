/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Container = styled.ul`
  background: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAurora
      : theme.colors.coreNeutralAbyss};
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  margin-top: 5rem;
  text-size-adjust: none;
  width: 100%;

  ${media.tabletLandscape} {
    margin-top: 4rem;
  }

  ${media.tabletPortrait} {
    margin-top: 6rem;
  }

  ${media.phoneLandscape} {
    margin-top: 2rem;
  }

  @media (max-height: 650px) {
    margin-top: 2rem;
  }

  @media (max-height: 500px) {
    margin-top: 2.4rem;
  }
`;
