import styled from 'styled-components';
import DefaultPanels from 'components/Panels';

export const Container = styled.div`
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
`;

export const Image = styled.img`
  border-radius: 0.2rem;
  display: block;
  filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.24));
  height: 4rem;
  margin-right: 0.625rem;
  width: 4rem;
`;

export const Content = styled.div``;

export const Panels = styled(DefaultPanels)`
  font-size: 0.875rem;
  width: 100%;
`;

export const PanelContent = styled.div`
  font-size: 0.875rem;
  padding-top: 1.75rem;
`;

export const Html = styled.div`
  font-size: 0.875rem;
  line-height: 1.4;
  letter-spacing: 0.01em;

  span,
  a,
  p {
    font-size: inherit;
  }

  a,
  a:hover,
  a:active,
  a:visited {
    color: inherit;
  }

  p {
    display: block;
    margin-block-end: 1rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  strong,
  b {
    font-size: inherit;
    font-weight: 600;
  }

  em,
  i {
    font-size: inherit;
    font-style: italic;
  }

  ul {
    font-size: inherit;
    list-style-position: inside;
    margin: 0.5rem 0;
  }
`;
