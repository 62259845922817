import styled from 'styled-components';
import DefaultLink from 'components/Link';
import { media } from 'styles/mixins';

export const Container = styled.div`
  color: #111111;
  margin-bottom: 2.3rem;

  ${media.phoneLandscape} {
    text-align: center;
  }
`;

export const Text = styled.p`
  font-size: 0.9375rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 1.2;
  margin-bottom: 0.5625rem;
`;

export const Small = styled.p`
  font-size: 0.6875rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  line-height: 1.18;
  opacity: 0.4;

  ${media.phoneLandscape} {
    max-width: 16.795rem;
  }
`;

export const Link = styled(DefaultLink)`
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
`;
