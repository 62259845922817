import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  color: inherit;
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.01em;
  line-height: 1.5;
  margin-bottom: 1.25rem;
  max-width: 15.6rem;
  text-align: center;
  width: 100%;

  ${media.phoneLandscape} {
    margin-top: 2.25rem;
    margin-bottom: 1rem;
    max-width: 22.5rem;
  }

  ${media.phonePortrait} {
    margin-top: 4.25rem;
    margin-bottom: 1.7419rem;
    max-width: 15.6rem;
  }
`;

export const Subtitle = styled.p`
  color: inherit;
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: 0.01em;
  line-height: 1.4286;
  margin-bottom: 3.125rem;
  text-align: center;

  ${media.phoneLandscape} {
    margin-bottom: 1.5rem;
  }

  ${media.phonePortrait} {
    margin-bottom: 4.3225rem;
  }
`;
