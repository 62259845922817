import styled, { DefaultTheme, css } from 'styled-components';
import colorWithAlpha from 'lib/color';
import DefaultIcon from 'components/Icon';
import { ContainerProps } from './types';

const hoverState = ({ theme }: ContainerProps & { theme: DefaultTheme }) => css`
  background: ${colorWithAlpha({
    color: theme.colors.coreNeutralBlack,
    alpha: 0.015,
  })};
  box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.015);
`;

const focusState = ({ theme }: ContainerProps & { theme: DefaultTheme }) => css`
  background: ${theme.colors.coreNeutralAurora};
  box-shadow: 0px 0px 0px 3px ${theme.colors.coreNeutralAurora},
    0px 0px 0px 4px
      ${colorWithAlpha({ color: theme.colors.coreBlue100, alpha: 0.25 })};
`;

const activeState = ({
  theme,
  $size,
}: ContainerProps & { theme: DefaultTheme }) => {
  switch ($size) {
    case 'small':
      return css`
        background: ${colorWithAlpha({
          color: theme.colors.coreBlue100,
          alpha: 0.1,
        })};
        box-shadow: 0px 0px 0px 4px
          ${colorWithAlpha({ color: theme.colors.coreBlue100, alpha: 0.1 })};
      `;
    default:
      return css`
        background: ${colorWithAlpha({
          color: theme.colors.coreBlue100,
          alpha: 0.25,
        })};
        box-shadow: 0px 0px 0px 4px
          ${colorWithAlpha({ color: theme.colors.coreBlue100, alpha: 0.25 })};
      `;
  }
};

const disabledState = ({
  theme,
  $size,
}: ContainerProps & { theme: DefaultTheme }) => {
  switch ($size) {
    case 'small':
      return css`
        color: ${colorWithAlpha({
          color: theme.colors.coreNeutralBlack,
          alpha: 0.33,
        })};
        pointer-events: none;
      `;
    default:
      return css`
        color: ${colorWithAlpha({
          color: theme.colors.coreNeutralBlack,
          alpha: 0.25,
        })};
        pointer-events: none;
      `;
  }
};

export const Container = styled.button<ContainerProps>`
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 0.125rem;
  color: ${({ $size, theme }) =>
    $size === 'small'
      ? theme.colors.fontButtonEmphasized
      : theme.colors.fontEmphasized};
  cursor: pointer;
  display: flex;
  flex-direction: row nowrap;
  justify-content: center;
  min-width: max-content;
  outline: none;
  padding: 0;
  text-decoration-line: ${({ $hideChevron, $size }) =>
    $hideChevron && $size === 'default' ? 'underline' : 'none'};
  transition: all 200ms ease;
  transition-property: background, box-shadow, color;
  width: auto;

  &:hover {
    ${hoverState}
  }
  &:focus {
    ${focusState}
  }
  &:active {
    ${activeState}
  }
  &:disabled {
    ${disabledState}
  }

  ${({ $active, $size, theme }) =>
    $active &&
    $size === 'small' &&
    css`
      color: ${theme.colors.fontEmphasized};
    `}
`;

export const ChevronIcon = styled(DefaultIcon)`
  display: block;
  margin-left: ${({ theme }) => theme.measures.xxs};
`;
