import type { FC } from 'react';
import { Props } from './types';

const PlayLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M21.389 11.36L7.89 3.11a.75.75 0 00-1.141.64v16.5a.75.75 0 001.141.64l13.498-8.25a.75.75 0 000-1.28z"
      fill="currentColor"
    />
  </svg>
);

export default PlayLight;
