import { Container, Title, Body1, Button, Body2 } from './styles';

const Placeholder = () => (
  <Container>
    <Title />
    <Body1 />
    <Body2 />
    <Button />
  </Container>
);

export default Placeholder;
