import { formatDeepLink } from 'lib/formatDeepLink';
import type {
  WidgetCarouselDataFragment,
  WidgetCarouselItemDataFragment,
  WidgetContinueListeningDataFragment,
  WidgetCtaDataFragment,
  WidgetImageDataFragment,
  WidgetSpotlightDataFragment,
  WidgetUpNextDataFragment,
} from 'graphql/generated';

export type ValidWidgetFragments =
  | WidgetUpNextDataFragment
  | WidgetCtaDataFragment
  | WidgetSpotlightDataFragment
  | WidgetContinueListeningDataFragment
  | WidgetCarouselDataFragment
  | WidgetImageDataFragment;

export const normalizeWidgetCarouselItem = (
  item: WidgetCarouselItemDataFragment,
) => ({
  caption: item.caption ?? undefined,
  hash: item.hash,
  position: 0,
});

export type WidgetCarouselItem = ReturnType<typeof normalizeWidgetCarouselItem>;

export const normalizeWidgetUpNext = (widget: WidgetUpNextDataFragment) => ({
  category: 'preset',
  id: widget.id,
  position: 0,
  title: widget.title,
  typename: widget.__typename,
});

export type WidgetUpNext = ReturnType<typeof normalizeWidgetUpNext>;

export const normalizeWidgetCTA = (widget: WidgetCtaDataFragment) => ({
  category: 'preset',
  id: widget.id,
  position: 1,
  title: widget.title,
  typename: widget.__typename,
});

export type WidgetCTA = ReturnType<typeof normalizeWidgetCTA>;

export const normalizeWidgetSpotlight = (
  widget: WidgetSpotlightDataFragment,
) => ({
  category: 'preset',
  id: widget.id,
  position: 2,
  title: widget.title,
  typename: widget.__typename,
});

export type WidgetSpotlight = ReturnType<typeof normalizeWidgetSpotlight>;

export const normalizeWidgetContinueListening = (
  widget: WidgetContinueListeningDataFragment,
) => ({
  category: 'preset',
  id: widget.id,
  imageSize: 'medium',
  layout: 'vertical',
  position: 0,
  title: widget.title,
  typename: widget.__typename,
});

export type WidgetContinueListening = ReturnType<
  typeof normalizeWidgetContinueListening
>;

export const normalizeWidgetCarousel = (
  widget: WidgetCarouselDataFragment,
) => ({
  category: 'custom',
  id: widget.id,
  imageSize: widget.image_size,
  items:
    widget.items?.map((wi, i) => ({
      ...normalizeWidgetCarouselItem(wi),
      position: i,
    })) || [],
  layout: widget.layout,
  position: 0,
  subtitle: widget.subtitle || '',
  title: widget.title,
  typename: widget.__typename,
});

export type WidgetCarousel = ReturnType<typeof normalizeWidgetCarousel>;

export const normalizeWidgetImage = (widget: WidgetImageDataFragment) => ({
  category: 'custom',
  deepLink: widget.deeplink ? formatDeepLink(widget.deeplink) : undefined,
  id: widget.id,
  position: 0,
  title: widget.title,
  typename: widget.__typename,
  urlDesktop: widget.url_desktop,
  urlMobile: widget.url_mobile,
});

export type WidgetImage = ReturnType<typeof normalizeWidgetImage>;

export type Widget =
  | WidgetUpNext
  | WidgetCTA
  | WidgetSpotlight
  | WidgetContinueListening
  | WidgetCarousel
  | WidgetImage;

export function normalizeWidget(
  widget:
    | WidgetUpNextDataFragment
    | WidgetCtaDataFragment
    | WidgetSpotlightDataFragment
    | WidgetContinueListeningDataFragment
    | WidgetCarouselDataFragment
    | WidgetImageDataFragment,
): Widget {
  if (widget.__typename === 'WidgetUpNext') {
    return normalizeWidgetUpNext(widget);
  }
  if (widget.__typename === 'WidgetCTA') {
    return normalizeWidgetCTA(widget);
  }
  if (widget.__typename === 'WidgetSpotlight') {
    return normalizeWidgetSpotlight(widget);
  }
  if (widget.__typename === 'WidgetContinueListening') {
    return normalizeWidgetContinueListening(widget);
  }
  if (widget.__typename === 'WidgetCarousel') {
    return normalizeWidgetCarousel(widget);
  }
  return normalizeWidgetImage(widget);
}
