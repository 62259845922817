import styled, { css } from 'styled-components';
import colorWithAlpha from 'lib/color';
import { media } from 'styles/mixins';
import DefaultIcon from 'components/Icon';
import DefaultImage from 'components/Image';
import {
  Heading as DefaultHeading,
  Body as DefaultBody,
} from 'components/Typography';

const LOCKED_OPACITY = '0.4';

export const Container = styled.div<{ $isHighlighted?: boolean }>`
  background: none;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderBase};
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutral600
      : theme.colors.coreNeutral200};
  cursor: pointer;
  display: flex;
  padding: 1.25rem 0;
  position: relative;
  text-align: left;
  text-decoration: none;
  width: 100%;

  ${({ theme, $isHighlighted }) =>
    $isHighlighted &&
    css`
      background: ${theme.name === 'LIGHT'
        ? colorWithAlpha({ color: theme.colors.coreBlue100, alpha: 0.2 })
        : colorWithAlpha({ color: theme.colors.coreTeal500, alpha: 0.1 })};
      box-shadow: ${theme.measures.m} 0px 0px 0px
          ${theme.name === 'LIGHT'
            ? colorWithAlpha({ color: theme.colors.coreBlue100, alpha: 0.2 })
            : colorWithAlpha({ color: theme.colors.coreTeal500, alpha: 0.1 })},
        -${theme.measures.m} 0px 0px 0px ${theme.name === 'LIGHT' ? colorWithAlpha({ color: theme.colors.coreBlue100, alpha: 0.2 }) : colorWithAlpha({ color: theme.colors.coreTeal500, alpha: 0.1 })};
    `};

  ${media.tabletPortrait} {
    padding: 1.25rem 1.4rem;
  }

  ${media.phonePortrait} {
    padding: 1.25rem 1rem;
  }
`;

export const RightIcon = styled(DefaultIcon)`
  color: ${({ theme }) => theme.colors.fontBase};
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  ${media.tabletPortrait} {
    right: 1.4rem;
  }

  ${media.phonePortrait} {
    right: 1rem;
  }
`;

export const Image = styled(DefaultImage)<{ $reduceOpacity?: boolean }>`
  border-radius: ${({ theme }) => theme.radius.xsm};
  flex: 0 0 auto;
  margin-right: 0.75rem;
  opacity: ${({ $reduceOpacity }) => ($reduceOpacity ? LOCKED_OPACITY : '1')};
  width: 5.75rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: flex-start;
`;

export const Info = styled.div<{ $hasMenu: boolean; $reduceOpacity?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-right: ${({ $hasMenu }) => ($hasMenu ? '2rem' : '0')};
  opacity: ${({ $reduceOpacity }) => ($reduceOpacity ? LOCKED_OPACITY : '1')};
`;

export const TitleContent = styled.div`
  align-items: center;
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.fontBase
      : theme.colors.coreNeutralWhite};
  display: flex;
`;

export const PreTitleContent = styled(TitleContent)`
  margin-bottom: 0.25rem;
`;

export const Title = styled(DefaultHeading).attrs(() => ({
  $weight: 'bold',
}))`
  word-break: break-word;
`;

export const Pretitle = styled.span`
  color: inherit;
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.07em;
  line-height: 1.3;
  text-transform: uppercase;
`;

export const Highlight = styled(Pretitle)`
  color: ${({ theme }) => theme.colors.fontEmphasized};
  margin-bottom: 0.2rem;
`;

export const Subtitle = styled(DefaultHeading).attrs(() => ({
  $weight: 'regular',
}))`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutral700
      : theme.colors.coreNeutral50};
  font-size: 0.9rem;
  margin-top: 0.25rem;
`;

export const FavContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const Description = styled(DefaultBody).attrs(() => ({
  $weight: 'regular',
}))`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutral600
      : theme.colors.coreNeutral200};
  margin-top: 0.25rem;
  word-break: break-word;
`;

export const OptionsButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  outline-offset: 0.2rem;
  padding: 0.4rem 0.9rem;
  position: absolute;
  right: 0.6rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
`;

export const ThreeDotsIcon = styled(DefaultIcon).attrs(({ theme }) => ({
  color: theme.colors.coreNeutral400,
  name: 'threeDotsVerticalLight',
  size: 18,
}))`
  color: inherit;
  display: block;
`;

export const ProgressContainer = styled.div<{ $reduceOpacity?: boolean }>`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 0.5rem;
  opacity: ${({ $reduceOpacity }) => ($reduceOpacity ? LOCKED_OPACITY : '1')};
`;

export const IconsContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.75rem;
  line-height: 1.33;
`;

export const Dot = styled.span`
  color: ${({ theme }) => theme.colors.coreNeutral400};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: 0 0.5rem;
`;

export const Icon = styled(DefaultIcon).attrs(() => ({ size: 14 }))`
  color: ${({ theme }) => theme.colors.coreNeutral400};
  display: block;
  margin-right: 0.25rem;
`;

export const TitleIcon = styled(DefaultIcon).attrs(() => ({ size: 14 }))`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.fontBase
      : theme.colors.coreNeutralWhite};
  margin-right: 0.25rem;
`;

export const PreTitleIcon = styled(TitleIcon)`
  margin-bottom: 0.1rem;
  margin-right: 0.313rem;
`;

// Needed for z-index workaround on Read more button
export const ExpandContainer = styled.div`
  display: inline-flex;
`;

export const ExpandButton = styled.button<{ $neutralColor?: boolean }>`
  align-items: center;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.fontEmphasized};
  cursor: pointer;
  display: inline-flex;
  margin-top: ${({ theme }) => theme.measures.xs};
  z-index: 1;

  :hover {
    opacity: 0.8;
  }

  ${({ theme, $neutralColor }) =>
    $neutralColor &&
    css`
      color: ${theme.name === 'LIGHT'
        ? colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.25 })
        : colorWithAlpha({
            color: theme.colors.coreNeutralWhite,
            alpha: 0.25,
          })};
    `};
`;

export const ExpandLabel = styled(DefaultBody).attrs(() => ({
  as: 'span',
  $size: 'medium',
  $weight: 'regular',
}))``;

export const ExpandIcon = styled(DefaultIcon).attrs(() => ({
  name: 'caretDownLight',
  size: 16,
}))<{ $rotate?: boolean }>`
  margin-left: ${({ theme }) => theme.measures.xxs};
  transform: ${({ $rotate }) => ($rotate ? 'scaleY(-1)' : 'scaleY(1)')};
  transition: transform 200ms ease-in;
`;
