import { memo, type FC } from 'react';
import { Form as FormikForm, Formik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import Thanks from './Thanks';
import { initialValues, validationSchema } from './constants';
import { Button, ButtonContainer, Error, TextField } from './styles';
import { Step, type Props } from './types';

const Form: FC<Props> = ({ onSubmit }) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
  >
    {({ errors, isSubmitting, values }) => (
      <FormikForm>
        {values.step === Step.message ? (
          <>
            <TextField label="recipient email*" name="email" type="email" />
            <TextField label="recipient name" name="name" />
            <TextField
              label="message"
              name="message"
              maxLength={500}
              multiline
            />
            <ButtonContainer>
              <Button
                disabled={isSubmitting}
                isLoading={isSubmitting}
                type="submit"
              >
                Send free month
              </Button>
              <AnimatePresence initial={false}>
                {errors.submitError && (
                  <Error>ERROR: {errors.submitError}</Error>
                )}
              </AnimatePresence>
            </ButtonContainer>
          </>
        ) : (
          <Thanks />
        )}
      </FormikForm>
    )}
  </Formik>
);

export default memo(Form);
