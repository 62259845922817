import { memo } from 'react';
import type { FC } from 'react';
import { CirclePauseIcon, CirclePlayIcon, Container, Icon } from './styles';
import { Props } from './types';

const ButtonPlay: FC<Props> = ({ isPlaying, reverse, ...rest }) => {
  return (
    <Container {...rest}>
      {!reverse && (isPlaying ? <CirclePauseIcon /> : <CirclePlayIcon />)}
      {reverse &&
        (isPlaying ? <Icon name="pauseLight" /> : <Icon name="playLight" />)}
    </Container>
  );
};

export default memo(ButtonPlay);
