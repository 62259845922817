import styled from 'styled-components';
import { motion } from 'framer-motion';
import DefaultIcon from 'components/Icon';

export const Container = styled(motion.div).attrs(() => ({
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: { opacity: 1 },
    collapsed: { opacity: 0 },
  },
  transition: { duration: 0.5, ease: 'easeInOut' },
}))`
  align-items: center;
  background: rgba(61, 61, 61, 0.9);
  border-radius: 6.1875rem;
  box-shadow: ${({ theme }) => theme.elevation.web3};
  color: ${({ theme }) => theme.colors.coreNeutralAurora};
  display: flex;
  font-size: 0.859rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  letter-spacing: -0.2752px;
  line-height: 1.24;
  padding: 1.2813rem 1.5rem;
`;

export const CheckIcon = styled(DefaultIcon)`
  color: ${({ theme }) => theme.colors.coreGreen500};
  margin-right: 0.5rem;
`;
