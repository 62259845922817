import { useCallback } from 'react';
import type { MouseEventHandler } from 'react';
import { LogicProps } from './types';

const JUMP_SIZE = 15;

const useLogic = ({ onIncreasePosition, onPlayChange }: LogicProps) => {
  const handleJumpBackwards: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (onIncreasePosition) {
        onIncreasePosition(-JUMP_SIZE);
      }
    },
    [onIncreasePosition],
  );

  const handlePlayChange: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (onPlayChange) {
        onPlayChange();
      }
    },
    [onPlayChange],
  );

  const handleJumpForward: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (onIncreasePosition) {
        onIncreasePosition(JUMP_SIZE);
      }
    },
    [onIncreasePosition],
  );

  return { handleJumpBackwards, handleJumpForward, handlePlayChange };
};

export default useLogic;
export type UseLogic = ReturnType<typeof useLogic>;
