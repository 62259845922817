import type { FC } from 'react';
import { Container, Image, Overline, Subtitle, Title } from './styles';
import type { Props } from './types';

const RetreatCard: FC<Props> = ({
  className,
  descriptionId,
  formattedDate,
  imageAlt = '',
  imageUrl,
  subtitle,
  title,
  titleId,
}) => (
  <Container className={className}>
    {imageUrl && <Image alt={imageAlt} src={imageUrl} />}
    <Overline>{formattedDate}</Overline>
    <Title id={titleId}>{title}</Title>
    <Subtitle id={descriptionId}>{subtitle}</Subtitle>
  </Container>
);

export default RetreatCard;
