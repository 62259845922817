import {
  type KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  detailsVar,
  personDetailsVar,
  resetDetails,
  resetPersonDetails,
} from 'graphql/reactive';

const useLogic = () => {
  const details = useReactiveVar(detailsVar);
  const personDetails = useReactiveVar(personDetailsVar);

  const backRef = useRef<HTMLButtonElement>(null!);
  const modalRef = useRef<HTMLDivElement>(null!);

  const hasDetails = !!details;
  const hasPersonDetails = !!personDetails;

  useEffect(() => {
    if (hasDetails || hasPersonDetails) {
      document.body.style.overflowY = 'hidden';
      backRef.current.focus();
      backRef.current.blur();
      return () => {
        document.body.style.overflowY = 'auto';
      };
    }
  }, [hasDetails, hasPersonDetails]);

  const handleBack = useCallback(() => {
    if (hasPersonDetails) {
      resetPersonDetails();
    } else {
      resetDetails();
    }
  }, [hasPersonDetails]);

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (e.key !== 'Tab') return;

      if (modalRef.current) {
        const focusableModalElements = modalRef.current.querySelectorAll(
          'button:not([disabled]), a, [role="button"]',
        );

        const lastElement =
          focusableModalElements[focusableModalElements.length - 1];

        if (!e.shiftKey && document.activeElement === lastElement) {
          backRef.current?.focus();
          return e.preventDefault();
        }
        if (e.shiftKey && document.activeElement === backRef.current) {
          (lastElement as HTMLElement).focus();
          e.preventDefault();
        }
      }
    },
    [],
  );

  return {
    backRef,
    details,
    handleBack,
    handleKeyDown,
    hasDetails,
    hasPersonDetails,
    modalRef,
    personDetails,
  };
};

export default useLogic;
export type UseLogic = ReturnType<typeof useLogic>;
