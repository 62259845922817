import { memo, useCallback, type FC } from 'react';
import { AppConfig, isDevelopment } from 'config';
import useMe from 'graphql/hooks/useMe';
import useModalActions from 'graphql/hooks/useModalActions';
import NavLink from './NavLink';
import {
  AppLink,
  AppsLinks,
  AppStoreBadge,
  Container,
  GooglePlayBadge,
  Links,
  Logo,
  Separator,
} from './styles';
import type { Props } from './types';

const Desktop: FC<Props> = ({ className }) => {
  const {
    profile: { subscriptionIsElegibleForSharing },
  } = useMe();
  const {
    openGiveFreeTrialModal,
    openSelfTimerConfigurationModal,
    openShareAppModal,
    openSupportModal,
  } = useModalActions();

  const handleOpenShareTheApp = useCallback(() => {
    if (isDevelopment) {
      openShareAppModal({ source: 'menu' });
    } else {
      openGiveFreeTrialModal({ source: 'menu' });
    }
  }, [openGiveFreeTrialModal, openShareAppModal]);

  return (
    <Container aria-label="Main menu" className={className}>
      <Logo aria-hidden id="nav-desktop-logo" />
      <Links>
        <NavLink
          end
          iconName="houseLineLight"
          id="nav-desktop-home"
          title="Home"
          to="/"
        />
        <NavLink
          iconName="bookOpenLight"
          id="nav-desktop-theory"
          title="Theory"
          to="/theory"
        />
        <NavLink
          iconName="lotusLight"
          id="nav-desktop-practice"
          title="Practice"
          to="/practice"
        />
        <NavLink
          iconName="lifeLight"
          id="nav-desktop-life"
          title="Life"
          to="/life"
        />
        <NavLink
          iconName="timerLight"
          id="nav-desktop-meditationtimer"
          onClick={openSelfTimerConfigurationModal}
          title="Meditation Timer"
        />
        <NavLink
          iconName="bookmarkSingleInactive"
          id="nav-desktop-mylibrary"
          title="My Library"
          to="/my-library"
        />
        <NavLink
          iconName="arrowUUpLeftLight"
          id="nav-desktop-recentlyplayed"
          title="Recently Played"
          to="/recently-played"
        />
        <NavLink
          iconName="search"
          id="nav-desktop-search"
          title="Search"
          to="/search"
        />
        <Separator />
        <NavLink
          iconName="userCircleLight"
          id="nav-desktop-account"
          title="Account"
          to="/account"
        />
        <NavLink
          iconName="chatCircleDotsLightInverted"
          id="nav-desktop-support"
          title="Contact Support"
          onClick={openSupportModal}
        />
        {subscriptionIsElegibleForSharing && (
          <NavLink
            iconName="shareLight"
            id="nav-desktop-inviteafriend"
            onClick={handleOpenShareTheApp}
            title="Invite a Friend"
          />
        )}
        <Separator />
        <AppsLinks>
          <AppLink
            aria-label="Link to App Store (opens in a new tab)"
            href={AppConfig.appStoreURL}
            target="_blank"
          >
            <AppStoreBadge />
          </AppLink>
          <AppLink
            aria-label="Link to Google Play (opens in a new tab)"
            href={AppConfig.playStoreURL}
            target="_blank"
          >
            <GooglePlayBadge />
          </AppLink>
        </AppsLinks>
      </Links>
    </Container>
  );
};

export default memo(Desktop);
