import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputsContainer = styled.fieldset`
  border: none;
  color: ${({ theme }) => theme.colors.coreNeutralAbyss};
  display: flex;
`;

export const Legend = styled.legend`
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  white-space: nowrap;
`;

export const Error = styled(motion.div).attrs(() => ({
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  transition: { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] },
}))`
  color: ${({ theme }) => theme.colors.fontLabelError};
  font-size: 0.75rem;
  letter-spacing: 0.01rem;
  line-height: 1.33;
  margin-top: 0.25rem;
  text-align: left;
`;
