import { UseConnect } from '../connect';

export enum Step {
  reminders,
  calendar,
  share,
}

export type FormFields = {
  sendReminders: boolean;
  step: Step;
  submitError?: string;
};

export type Props = {
  descriptionId: string;
  initialValues: FormFields;
  onClose: UseConnect['handleClose'];
  onSubmit: UseConnect['handleSubmit'];
  titleId: string;
};
