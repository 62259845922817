import styled from 'styled-components';
import DefaultHeaderLink from 'components/Links/HeaderLink';

export const SectionHeader = styled.div`
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

export const Title = styled.span`
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Expander = styled.div`
  flex: 1;
`;

export const HeaderLink = styled(DefaultHeaderLink)`
  margin-right: 4px;
`;
