/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { BaseLink } from '..';

export const BackLinkBase = styled(({ ...rest }) => <BaseLink {...rest} />)`
  align-items: center;
  color: inherit;
  display: flex;

  svg {
    height: 0.75rem;
    width: 0.75rem;
  }
`;
