export const BASE_TITLE = 'Waking Up with Sam Harris';
export const POST_TITLE = '- A New Operating System for Your Mind';

export const ROUTES_TITLES: Record<string, string> = {
  '/': `Home | ${BASE_TITLE} ${POST_TITLE}`,
  '/account': `My Account | ${BASE_TITLE}`,
  '/account/course': 'About Waking Up with Sam Harris',
  '/account/edit': `Edit Profile | ${BASE_TITLE}`,
  '/account/help': `FAQ | ${BASE_TITLE}`,
  '/account/holiday-gift-card': 'Purchase a Holiday Gift Card',
  '/account/notifications': `Notification Settings | ${BASE_TITLE}`,
  '/account/privacy': `Privacy | ${BASE_TITLE}`,
  '/account/purchase-gift-card': `Purchase a Gift Card | ${BASE_TITLE}`,
  '/account/redeem/gift': `Redeem a Gift Card | ${BASE_TITLE}`,
  '/account/redeem/promo': `Redeem a Promo Code | ${BASE_TITLE}`,
  '/account/sam': 'About Sam Harris',
  '/account/subscription': `Subscription Options | ${BASE_TITLE}`,
  '/account/terms': `Terms | ${BASE_TITLE}`,
  '/gifts/redeem': `Redeem Gifts | ${BASE_TITLE}`,
  '/holiday-gift-card': 'Purchase a Holiday Gift Card',
  '/practice': `Practice Series | ${BASE_TITLE}`,
  '/practice/natural-wisdom': 'Natural Wisdom | Advaita Series by Jayasāra',
  '/practice/wisdom-texts': 'Wisdom Texts | Advaita Series by Jayasāra',
  '/purchase-gift-card': `Purchase a Gift Card | ${BASE_TITLE}`,
  '/redeem/success': `Redeem | ${BASE_TITLE}`,
  '/subscription/success': `Subscription | ${BASE_TITLE}`,
  '/theory/questions-answers': `Theory Questions & Answers | ${BASE_TITLE}`,
};
