import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import keyBy from 'lodash/keyBy';
import { useSnackbar } from 'notistack';
import { trackEvent, trackMediaEvent } from 'services/analytics';
import { CourseStatus } from 'models/Course';
import {
  ChangeIntroCourseDocument,
  ResetAllContentDocument,
  ResetCourseDocument,
  ResetIntroCoursesDocument,
  ResetPackCoursesDocument,
} from '../generated';
import { cacheUpdateCourseFragment } from '../cache';
import {
  GET_CONTENT,
  GET_DAILY,
  GET_FEATURE_CONTENT,
  GET_REMOTE_CONFIG,
} from '../queries';
import {
  getCourse,
  getIntroCourses,
  getPack,
  getPackCourses,
  getSettings,
} from '../requests';
import useSettingsActions from './useSettingsActions';

const useResetActions = () => {
  const { updateSettings } = useSettingsActions();
  const { enqueueSnackbar } = useSnackbar();

  const [resetAllContentMutation, { loading: isResettingAllContent }] =
    useMutation(ResetAllContentDocument);

  const [resetCourseMutation, { loading: isResettingCourse }] =
    useMutation(ResetCourseDocument);

  const [resetPackCoursesMutation, { loading: isResettingPackCourses }] =
    useMutation(ResetPackCoursesDocument);

  const [resetIntroCoursesMutation, { loading: isResettingIntroCourse }] =
    useMutation(ResetIntroCoursesDocument);

  const [changeIntroCourseMutation] = useMutation(ChangeIntroCourseDocument, {
    refetchQueries: [
      { query: GET_CONTENT },
      { query: GET_DAILY },
      { query: GET_FEATURE_CONTENT },
      { query: GET_REMOTE_CONFIG },
    ],
  });

  const resetAllContent = useCallback(async () => {
    try {
      const allCourses = await getPackCourses();
      await resetAllContentMutation();

      allCourses.packCourses
        .filter(({ isIntroCourse }) => !isIntroCourse)
        .forEach(({ hash }) => {
          cacheUpdateCourseFragment(hash, { status: 'unstarted' });
        });

      await updateSettings({ recentlyPlayed: [] });

      trackEvent('Settings Reset All Content');

      enqueueSnackbar('All content has been reset successfully', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error?.message || 'Unable to reset content', {
        variant: 'error',
      });
    }
  }, [resetAllContentMutation, enqueueSnackbar, updateSettings]);

  const resetIntroCourses = useCallback(async () => {
    try {
      await resetIntroCoursesMutation();

      const introCourses = await getIntroCourses();

      // Reset intro courses resume/recentlyPlayed in settings.
      const introCoursesObj = introCourses.reduce<Record<string, boolean>>(
        (t, { hash }) => {
          t[hash] = true;
          return t;
        },
        {},
      );

      Object.keys(introCoursesObj).forEach((courseHash) => {
        cacheUpdateCourseFragment(courseHash, { status: 'unstarted' });
      });

      const { recentlyPlayed } = await getSettings();
      await updateSettings({
        recentlyPlayed: recentlyPlayed.filter(
          (rp) => !introCoursesObj[rp.course_hash],
        ),
      });

      trackEvent('Settings Reset Intro Course');

      enqueueSnackbar('Introductory course has been reset successfully', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error?.message || 'Unable to reset introductory course', {
        variant: 'error',
      });
    }
  }, [resetIntroCoursesMutation, enqueueSnackbar, updateSettings]);

  const resetCourse = useCallback(
    async (courseId: string) => {
      try {
        const course = await getCourse(courseId);
        if (!course) return;

        if (course.status === CourseStatus.FINISHED) {
          await resetCourseMutation({ variables: { course_hash: courseId } });
        }

        const { recentlyPlayed } = await getSettings();
        await updateSettings({
          recentlyPlayed: recentlyPlayed.map((rp) =>
            rp.course_hash === course.hash
              ? { ...rp, date: 0, last_known_location: -1 }
              : rp,
          ),
        });

        cacheUpdateCourseFragment(course.hash, { status: 'unstarted' });

        trackMediaEvent('Media Mark Content as Unplayed', course.hash);

        enqueueSnackbar('Course has been reset successfully', {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error?.message || 'Unable to reset course', {
          variant: 'error',
        });
      }
    },
    [resetCourseMutation, enqueueSnackbar, updateSettings],
  );

  const resetPackCourses = useCallback(
    async (packHash: string) => {
      try {
        const pack = await getPack(packHash);
        if (!pack) return;

        await resetPackCoursesMutation({ variables: { pack_hash: packHash } });

        const coursesInfoObj = keyBy(pack.coursesInfo, (ci) => ci.courseHash);
        const { recentlyPlayed } = await getSettings();
        await updateSettings({
          recentlyPlayed: recentlyPlayed.map((rp) =>
            coursesInfoObj[rp.course_hash]
              ? { ...rp, date: 0, last_known_location: -1 }
              : rp,
          ),
        });

        pack.coursesInfo.forEach(({ courseHash }) => {
          trackMediaEvent('Media Mark Content as Unplayed', courseHash);
        });

        enqueueSnackbar('Pack courses have been reset successfully', {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error?.message || 'Unable to reset pack courses', {
          variant: 'error',
        });
      }
    },
    [resetPackCoursesMutation, enqueueSnackbar, updateSettings],
  );

  const changeIntroCourse = useCallback(async () => {
    try {
      await changeIntroCourseMutation();

      enqueueSnackbar('Introductory course has been changed successfully', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(
        error?.message || 'Unable to change introductory course',
        { variant: 'error' },
      );
    }
  }, [changeIntroCourseMutation, enqueueSnackbar]);

  return {
    changeIntroCourse,
    isResettingAllContent,
    isResettingCourse,
    isResettingIntroCourse,
    isResettingPackCourses,
    resetAllContent,
    resetCourse,
    resetIntroCourses,
    resetPackCourses,
  };
};

export default useResetActions;
export type UseCourses = ReturnType<typeof useResetActions>;
