import styled from 'styled-components';
import DefaultIcon from 'components/Icon';
import DefaultButton from 'components/Button';
import {
  Overline as DefaultOverline,
  Heading as DefaultHeading,
  Body as DefaultBody,
} from 'components/Typography';
import colorWithAlpha from 'lib/color';
import { media } from 'styles/mixins';

export const Container = styled.div`
  align-items: center;
  border-top: 1px solid
    ${({ theme }) =>
      theme.name === 'LIGHT'
        ? colorWithAlpha({
            color: theme.colors.coreNeutralBlack,
            alpha: 0.1,
          })
        : colorWithAlpha({
            color: theme.colors.coreNeutralWhite,
            alpha: 0.25,
          })};
  padding: 1.25rem 0;
`;

export const Overline = styled(DefaultOverline)`
  color: ${({ theme }) => theme.colors.fontEmphasized};
  font-size: 0.75rem;
  margin-bottom: 0.1875rem;
`;

export const Title = styled(DefaultHeading).attrs(() => ({
  $weight: 'bold',
}))`
  margin-bottom: 0.0625rem;
`;

export const Button = styled(DefaultButton)`
  max-width: 16rem;
  margin-top: 1.25rem;

  ${media.phoneLandscape} {
    max-width: 100%;
  }
`;

export const Icon = styled(DefaultIcon)`
  margin-right: 0.5rem;
`;

export const Duration = styled(DefaultBody).attrs(() => ({
  $weight: 'bold',
  $size: 'small',
}))`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreTeal700
      : theme.colors.coreTeal500};
  text-transform: uppercase;
  margin-top: 0.0625rem;
`;

export const TitleContent = styled.div`
  align-items: center;
  display: flex;
`;
