import { memo, type FC, useCallback } from 'react';
import { Container } from './styles';
import type { Props } from './types';

const Button: FC<Props> = ({ index, onClick, selected }) => {
  const handleClick = useCallback(() => {
    onClick(index);
  }, [index, onClick]);

  return <Container $selected={selected} onClick={handleClick} />;
};

export default memo(Button);
