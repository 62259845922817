import { useCallback } from 'react';
import type { FC } from 'react';
import { formatDurationShort } from 'lib/formatTime';
import {
  Container,
  Overline,
  Title,
  Duration,
  Button,
  Icon,
  TitleContent,
} from './styles';
import type { Props } from './types';

const NextCourse: FC<Props> = ({
  courseCode,
  duration,
  isPractice,
  overline,
  packId,
  setTrack,
  title,
}) => {
  const handlePlay = useCallback(() => {
    setTrack(courseCode, packId);
  }, [courseCode, packId, setTrack]);

  return (
    <Container>
      <Overline>{overline}</Overline>
      <TitleContent>
        <Icon
          aria-hidden="true"
          name={isPractice ? 'lotusLight' : 'bookOpenLight'}
          size={16}
        />
        <Title>{title}</Title>
      </TitleContent>
      <Duration>{formatDurationShort(duration)}</Duration>
      <Button aria-label="Play next intro course session" onClick={handlePlay}>
        <Icon aria-hidden="true" name="playCircleLight" />
        PLAY NEXT
      </Button>
    </Container>
  );
};

export default NextCourse;
