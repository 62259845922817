import { memo, useCallback, type FC } from 'react';
import { formatDurationShort } from 'lib/formatTime';
import {
  WidgetCarouselImageSize,
  WidgetCarouselLayout,
} from 'graphql/generated';
import Link from 'components/Link';
import {
  Caption,
  Container,
  Content,
  Duration,
  DurationIcon,
  Icon,
  Image,
  ImageContainer,
  Overline,
  Separator,
  Subtitle,
  Title,
} from './styles';
import type { Props } from './types';

const CardSecondary: FC<Props> = ({
  className,
  id,
  onClick,
  ...cardSecondaryItem
}) => {
  const {
    ariaLabel,
    caption,
    duration,
    durationIcon,
    href,
    imageAlt = '',
    imageSize = WidgetCarouselImageSize.medium,
    imageUrl,
    layout = WidgetCarouselLayout.vertical,
    overline,
    overlineIcon,
    replace,
    state,
    subtitle,
    title,
  } = cardSecondaryItem;

  const handleClick = useCallback(() => {
    if (onClick) onClick(cardSecondaryItem);
  }, [cardSecondaryItem, onClick]);

  let element: 'button' | typeof Link | undefined;
  if (href) element = Link;
  else if (onClick) element = 'button';

  return (
    <Container
      $imageSize={imageSize}
      $layout={layout}
      aria-label={ariaLabel}
      as={element}
      className={className}
      id={id}
      onClick={onClick ? handleClick : undefined}
      replace={replace}
      state={state}
      to={href}
    >
      <ImageContainer $layout={layout}>
        <Image alt={imageAlt} hideLoader src={imageUrl} />
        {!!duration && (
          <Duration>
            {durationIcon && <DurationIcon name={durationIcon} />}
            <span>{formatDurationShort(duration)}</span>
          </Duration>
        )}
      </ImageContainer>
      <Content $layout={layout}>
        {(overline || caption) && (
          <Overline $imageSize={imageSize}>
            {overlineIcon && (
              <Icon
                $imageSize={imageSize}
                aria-hidden="true"
                name={overlineIcon}
              />
            )}
            {overline}
            {overline && caption && <Separator>·</Separator>}
            {caption && <Caption>{caption}</Caption>}
          </Overline>
        )}
        <Title $imageSize={imageSize}>{title}</Title>
        {subtitle && <Subtitle $imageSize={imageSize}>{subtitle}</Subtitle>}
      </Content>
    </Container>
  );
};

export default memo(CardSecondary);
