import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Container = styled.div`
  display: block;
  margin-bottom: 1rem;
  margin-top: 1rem;
  position: relative;

  ${media.tabletPortrait} {
    margin-bottom: 0.8rem;
  }
`;

export const Image = styled.img`
  display: block;
  height: auto;
  width: 100%;
`;
