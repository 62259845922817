import { useCallback } from 'react';
import { RetreatUserAction } from 'graphql/generated';
import { ModalType } from 'graphql/reactive';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import useRetreat from 'graphql/hooks/useRetreat';
import useRetreatActions from 'graphql/hooks/useRetreatActions';
import formatRetreatDate from 'lib/formatRetreatDate';
import { trackEvent } from 'services/analytics';

const useConnect = () => {
  const { isOpen } = useModal(ModalType.LEAVE_RETREAT);
  const { closeModal } = useModalActions();
  const { retreat } = useRetreat();
  const { isUpdatingUserRetreat, updateUserRetreat } = useRetreatActions();

  const {
    endDate,
    hash = '',
    id = '',
    subtitle,
    startDate,
    title,
  } = retreat || {};
  const overline =
    startDate && endDate ? formatRetreatDate(startDate, endDate) : '';

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleLeave = useCallback(async () => {
    await updateUserRetreat({ action: RetreatUserAction.leave, retreatId: id });

    trackEvent('Event Cancel Reservation', {
      event_hash: hash,
      event_type: 'retreat',
      name: title,
    });

    setTimeout(() => closeModal(), 200);
  }, [closeModal, hash, id, updateUserRetreat, title]);

  return {
    handleClose,
    handleLeave,
    isOpen,
    isUpdatingUserRetreat,
    overline,
    subtitle,
    title,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
