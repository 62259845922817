import { memo, type FC } from 'react';
import Banner from './Banner';
import Placeholder from './Placeholder';
import Retreat from './Retreat';
import { Container, Separator } from './styles';
import type { Props } from './types';
import useConnect from './connect';

const WidgetCta: FC<Props> = ({ widgetId }) => {
  const { isLoading, isSubscribed, retreat, showRetreat, subscriptionSubtype } =
    useConnect();

  if (isLoading) {
    return <Placeholder />;
  }

  if (isSubscribed && subscriptionSubtype === 'open_access') {
    return (
      <Container>
        <Banner widgetId={widgetId} />
        {showRetreat && (
          <>
            <Separator />
            <Retreat {...retreat!} />
          </>
        )}
      </Container>
    );
  }

  return (
    <Container>
      {showRetreat ? <Retreat {...retreat!} /> : <Banner widgetId={widgetId} />}
    </Container>
  );
};

export default memo(WidgetCta);
