import { memo, type FC, useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import capitalize from 'lib/capitalize';
import { FamilyMemberType } from 'graphql/generated';
import {
  Container,
  Content,
  FamilySharing,
  Isotype,
  Item,
  Logo,
  SourceMessage,
  Title,
} from './styles';
import { SUBSCRIPTION_SOURCE_MESSAGES } from './constants';
import type { Props } from './types';

const SubscriptionInfo: FC<Props> = ({
  endTime,
  isMobileWebview = false,
  joinedAt,
  planAmount,
  planInterval,
  planSubtype,
  renewIntent,
  source,
  stripeTrialChargeDate,
  subscriptionFamilyMember,
}) => {
  const start = joinedAt
    ? `Joined on ${format(parseISO(joinedAt), 'MMMM do yyyy')}`
    : '';

  const end = useMemo(() => {
    if (stripeTrialChargeDate) {
      const trialEndDate = format(
        parseISO(stripeTrialChargeDate),
        'MMMM do yyyy',
      );
      return `Trial ends on ${trialEndDate}`;
    }

    if (!endTime) {
      return '';
    }

    if (parseISO(endTime).getFullYear() > 2100) {
      return 'Lifetime Membership';
    }

    const expireDate = format(parseISO(endTime), 'MMMM do yyyy');

    return renewIntent === 'WILL_RENEW'
      ? `Will renew ${expireDate}`
      : `Ends ${expireDate}`;
  }, [endTime, renewIntent, stripeTrialChargeDate]);

  const isEFL = source === 'EFL' || source === 'EMAIL';
  const isStripe = source === 'stripe';
  const isFamilyMember = subscriptionFamilyMember === FamilyMemberType.MEMBER;
  const hidePaidPlanInfo =
    stripeTrialChargeDate && renewIntent === 'WONT_RENEW';

  return (
    <Container>
      {isMobileWebview ? <Logo /> : <Isotype />}
      <Content>
        <Title>Waking Up with Sam Harris</Title>
        <SourceMessage>{SUBSCRIPTION_SOURCE_MESSAGES[source]}</SourceMessage>
        {!isEFL && (
          <>
            {isFamilyMember && <FamilySharing>Family Sharing</FamilySharing>}
            <Item>{start}</Item>
            <Item>{end}</Item>
            {isStripe && !!planAmount && !!planInterval && !hidePaidPlanInfo && (
              <Item id={`subscribed-for-${planInterval}`}>
                {planSubtype.includes('_family')
                  ? 'Family'
                  : `${capitalize(planInterval)}ly`}{' '}
                plan ($
                {planAmount.toFixed(2)}/{planInterval})
              </Item>
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

export default memo(SubscriptionInfo);
