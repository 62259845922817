import { useCallback } from 'react';
import type { FC, MouseEventHandler, ReactNode } from 'react';
import type { LinkProps as DefaultLinkProps } from 'react-router-dom';
import { BaseLinkStyle } from './styles';

export type LinkProps = {
  children?: ReactNode;
  className?: string;
  href?: DefaultLinkProps['to'];
  id?: string;
  onClick?: () => void;
  replace?: DefaultLinkProps['replace'];
  state?: DefaultLinkProps['state'];
  title?: string;
};

// ToDo: Get rid of this component
export const BaseLink: FC<LinkProps> = ({
  className,
  href,
  onClick,
  ...props
}) => {
  const handleClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (event) => {
      if (onClick) {
        event.preventDefault();
        onClick();
      }
    },
    [onClick],
  );

  return (
    <BaseLinkStyle
      className={className}
      to={href || '#'}
      onClick={handleClick}
      {...props}
    />
  );
};
