import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultModal from 'components/Modal';

const SECTION_WIDTH_REM = 26;

export const Modal = styled(DefaultModal)<{ $isTimeSectionOpen: boolean }>`
  &&& {
    .MuiPaper-root {
      display: grid;
      grid-template-columns: ${({ $isTimeSectionOpen }) =>
        $isTimeSectionOpen ? 'repeat(2, 1fr)' : '100%'};
      max-width: 100vw;
      min-height: 37rem;
      padding: 0.8rem 1.4rem;
      width: ${({ $isTimeSectionOpen }) =>
        $isTimeSectionOpen
          ? `${2 * SECTION_WIDTH_REM}rem`
          : `${SECTION_WIDTH_REM}rem`};
    }
    ${media.phoneLandscape} {
      .MuiPaper-root {
        grid-template-columns: 100%;
        min-height: 0;
        padding: 0.8rem 1.8rem;
        width: 100%;
      }
    }
  }
`;

const ClearButton = styled.button`
  background: transparent;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: block;
  line-height: 1;
`;

export const CloseButton = styled(ClearButton)<{ $isTimeSectionOpen: boolean }>`
  display: block;
  left: 1rem;
  position: absolute;
  top: 1rem;
  ${media.tabletPortrait} {
    display: ${({ $isTimeSectionOpen }) =>
      $isTimeSectionOpen ? 'none' : 'block'};
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3.5rem;
`;
