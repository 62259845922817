import { useCallback, useEffect } from 'react';
import { trackEvent } from 'services/analytics';
import { trackGAEvent } from 'services/analytics/ga';
import formatCents from 'lib/formatCents';
import { EVENT_PREFIX } from './constants';
import type { LogicProps } from './types';

const SCREEN = 'web_checkout_logged_in';

const useLogic = ({
  amount,
  hasMonthlySubscription,
  hasYearlySubscription,
  id,
  interval,
  isEligibleForYearlyUpgradeWithDiscount,
  onCreate,
  sku,
  type,
}: LogicProps) => {
  const lwPlanInterval = interval.toLowerCase();
  const formatedPlanAmount = formatCents(amount);

  const isMonthlyToYearlyUpgrade =
    hasMonthlySubscription && type === 'standard' && lwPlanInterval === 'year';
  const isFamilyUpgrade =
    (hasMonthlySubscription || hasYearlySubscription) && type === 'family';
  const highlight =
    (hasMonthlySubscription &&
      type === 'standard' &&
      lwPlanInterval === 'year' &&
      isEligibleForYearlyUpgradeWithDiscount) ||
    (hasYearlySubscription &&
      type === 'lifetime' &&
      lwPlanInterval === 'lifetime');

  let buttonText = `SUBSCRIBE FOR US${formatedPlanAmount}/${lwPlanInterval}`;
  if (type === 'lifetime') {
    buttonText = `LIFETIME FOR US${formatedPlanAmount}`;
  } else if (type === 'family') {
    buttonText = `FAMILY PLAN FOR US${formatedPlanAmount}/${lwPlanInterval}`;
  } else if (
    hasMonthlySubscription &&
    type === 'standard' &&
    lwPlanInterval === 'year'
  ) {
    buttonText = isEligibleForYearlyUpgradeWithDiscount
      ? `UPGRADE TO ANNUAL (SAVE $140)`
      : `SWITCH TO YEARLY PLAN (US${formatedPlanAmount}/${lwPlanInterval})`;
  }

  useEffect(() => {
    trackEvent('Purchase Display Checkout Options', {
      ID: sku || id,
      screen: SCREEN,
    });
  }, [id, sku]);

  const handleClick = useCallback(() => {
    if (EVENT_PREFIX[interval]) {
      trackGAEvent('event', `${EVENT_PREFIX[interval]}PurchaseClick`, {
        event_category: 'subscription',
        logged_in: 'yes',
      });
    }

    onCreate(amount, id, interval, sku, type);
  }, [amount, id, interval, onCreate, sku, type]);

  return {
    buttonText,
    handleClick,
    highlight,
    isUpgrade: isMonthlyToYearlyUpgrade || isFamilyUpgrade,
  };
};

export default useLogic;
export type UseLogic = ReturnType<typeof useLogic>;
