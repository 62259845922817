import styled from 'styled-components';
import DefaultButton from '@mui/material/Button';
import { media } from 'styles/mixins';
import { ContainerProps } from './types';

export const Link = styled(DefaultButton).attrs(() => ({
  variant: 'contained',
  color: 'primary',
}))<ContainerProps>`
  &&& {
    border-radius: ${({ theme }) => theme.radius.md};
    border: none;
    color: ${({ theme }) => theme.colors.coreNeutralWhite};
    cursor: pointer;
    min-height: 3rem;
    min-width: max-content;
    outline: none;
    position: relative;
    text-decoration: none;
    width: 100%;

    .MuiButton-label {
      text-decoration: none;
      text-transform: uppercase;
      font-weight: ${({ theme }) => theme.font.weight.medium};
    }
  }
`;

export const Text = styled.span`
  margin-left: 0.5rem;

  ${media.tabletPortrait} {
    font-size: 0.9rem;
  }
`;
