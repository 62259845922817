export const initHelpScout = () => {
  if (window.Beacon) {
    window.Beacon('init', '50a26bbd-5226-4ff1-a78c-a3fe0ba83203');
    window.Beacon('config', { display: { style: 'manual' } });
  } else {
    setTimeout(() => initHelpScout(), 1000);
  }
};

export const toggleHelpScout = () => {
  window.Beacon('toggle');
};

initHelpScout();
