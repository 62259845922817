import styled from 'styled-components';
import { motion } from 'framer-motion';
import { media } from 'styles/mixins';
import DefaultModal from 'components/Modal';
import DefaultIcon from 'components/Icon';

export const Modal = styled(DefaultModal)`
  box-shadow: ${({ theme }) =>
    theme.name === 'LIGHT' ? 'none' : theme.elevation.web4};

  .MuiPaper-root {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding: 1.5rem 1.125rem 2rem 1.125rem;
    width: 38rem;
  }

  ${media.phoneLandscape} {
    .MuiPaper-root {
      padding: 2.5rem 1rem 2rem 1rem;
      width: 100%;
    }
  }
`;

export const Title = styled.h1`
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: -0.04em;
  line-height: 1.3;
  margin-bottom: 1rem;
`;

export const Text = styled.p`
  font-size: 0.925rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: -0.04em;
  line-height: 1.4;

  strong {
    font-size: inherit;
    font-weight: ${({ theme }) => theme.font.weight.semibold};
  }
`;

export const Subtitle = styled(Text)`
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  margin-bottom: 1rem;
`;

export const Separator = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.fontBase};
  margin-bottom: 1.875rem;
  opacity: 0.4;
  padding-bottom: 1.875rem;
`;

export const Price = styled.p`
  font-size: 1.5645rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: -0.04em;
  line-height: 1.3;
  margin-bottom: 0.75rem;

  span {
    font-size: 0.95rem;
    letter-spacing: -0.04em;
    line-height: 1.27;
    margin-left: 0.6rem;
  }
`;

export const Actions = styled.div`
  margin-bottom: 1.875rem;
  width: 100%;
`;

export const AnimatedContainer = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.7 },
}))``;

export const Button = styled.button<{ $isUpdating: boolean }>`
  align-items: center;
  background: ${({ $isUpdating, theme }) =>
    $isUpdating ? theme.colors.coreNeutral400 : theme.colors.coreBlue500};
  border: none;
  border-radius: 0.65rem;
  color: ${({ theme }) => theme.colors.coreNeutralWhite};
  cursor: pointer;
  display: flex;
  font-size: 1.35rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  justify-content: center;
  line-height: 1.8;
  margin-bottom: 0.875rem;
  max-width: 19rem;
  padding: 0.3rem 1.5rem;
  text-transform: uppercase;
  transition: background 200ms ease;
  width: 100%;

  &:hover,
  &:focus {
    background-color: ${({ $isUpdating }) => !$isUpdating && '#2555c5'};
  }

  &:disabled {
    cursor: default;
    background: ${({ theme }) => theme.colors.coreNeutral300};
  }
`;

export const Icon = styled(DefaultIcon).attrs(() => ({ size: 21 }))`
  display: block;
  margin-right: 0.4rem;
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.fontLabelError};
  font-size: 0.75rem;
  letter-spacing: 0.01rem;
  line-height: 1.33;
  margin-top: 0.6rem;
`;
