import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultModal from 'components/Modal';
import DefaultIconButton from 'components/IconButton';
import DefaultIcon from 'components/Icon';

export const Modal = styled(DefaultModal)`
  &&& {
    .MuiPaper-root {
      padding: 0 0 10rem 0;
      width: 20rem;
      text-align: center;
    }

    ${media.phoneLandscape} {
      .MuiPaper-root {
        padding: 0;
        width: 100%;
      }
    }
  }
`;

export const BackButton = styled(DefaultIconButton)`
  left: 0.8rem;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 1.35rem;
`;

export const Icon = styled(DefaultIcon).attrs(() => ({
  size: 24,
}))`
  display: block;
`;

export const Title = styled.div`
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  margin-bottom: 0.6rem;
  margin-top: 1.2rem;
  text-align: center;
`;

export const Menu = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderBase};
  border-top: 1px solid ${({ theme }) => theme.colors.borderBase};
  margin-top: 1rem;
`;

export const MenuItem = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 0.8rem 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderBase};
  }
`;

export const Text = styled.div`
  font-size: 0.95rem;
`;
