import { useLocation, useParams } from 'react-router-dom';
import { parse } from 'query-string';
import { SubscriptionPeriod } from 'models/SubscriptionPlan';

const useConnect = () => {
  const location = useLocation();
  const { search } = location;
  const { code } = useParams();
  const { ea, id, p, p_id } = parse(search);

  const userId = id && typeof id === 'string' ? id : undefined;
  const expiresAt = ea && typeof ea === 'string' ? Number(ea) : undefined;
  const priceId = p_id && typeof p_id === 'string' ? p_id : undefined;

  return {
    code,
    expiresAt,
    period:
      typeof p === 'string' &&
      Object.values(SubscriptionPeriod).includes(p as SubscriptionPeriod)
        ? (p as SubscriptionPeriod)
        : SubscriptionPeriod.MENU_YEAR,
    priceId,
    userId,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
