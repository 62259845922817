import type { FC } from 'react';
import { Props } from './types';

const ChatCircleDotsLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M4.259 16.594a8.996 8.996 0 1 1 3.147 3.147h0l-3.108.888a.75.75 0 0 1-.927-.927l.888-3.108h0Z"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 12.938a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.874ZM16.5 12.938a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.874ZM7.5 12.938a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.874Z"
      fill="currentColor"
    />
  </svg>
);

export default ChatCircleDotsLight;
