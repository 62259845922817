import { memo, type FC } from 'react';
import { AppConfig } from 'config';
import { Caption, Container, Image, ImageContainer, Title } from './styles';

const Thanks: FC = () => (
  <Container>
    <Title>Thank you.</Title>
    <ImageContainer>
      <Image
        alt=""
        src={`${AppConfig.imagesURL}/email-sent-confirmation.jpg`}
      />
    </ImageContainer>
    <Caption>Artwork by Joni Majer</Caption>
  </Container>
);

export default memo(Thanks);
