import styled from 'styled-components';
import { AppConfig } from 'config';
import { media } from 'styles/mixins';
import DefaultStars from 'components/Stars';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  margin-right: 5.875rem;

  ${media.tabletLandscape} {
    margin-right: 4rem;
  }

  ${media.tabletPortrait} {
    margin-right: 2.6rem;
  }

  ${media.phoneLandscape} {
    margin-bottom: 2.6rem;
    margin-right: 0;
  }
`;

export const ImageContainer = styled.div`
  height: 48.75rem;
  position: relative;
  width: 22.5rem;

  ${media.tabletPortrait} {
    height: 32.5rem;
    width: 15rem;
  }

  ${media.phoneLandscape} {
    height: 20rem;
    width: 19rem;
  }
`;

export const Image = styled.img`
  display: block;
  height: 100%;
  object-fit: cover;
  object-position: 0 0;
  width: 100%;
`;

export const Reviews = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-flow: column nowrap;
  padding-top: 1.625rem;

  ${media.phoneLandscape} {
    background-image: url('${AppConfig.imagesURL}/share/app-screenshot-shadow-mobile.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    border-top: 1px solid #6d6d6d;
    margin: 0 -1.5rem;
  }
`;

export const Caption = styled.p`
  font-size: 1.0625rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  line-height: 1.41;
  margin-bottom: 0.625rem;
`;

export const Stars = styled(DefaultStars)`
  color: ${({ theme }) => theme.colors.coreOrange300};
`;
