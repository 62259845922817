import { useCallback } from 'react';
import type { MouseEventHandler } from 'react';
import { useMediaQuery } from '@mui/material';
import { media } from 'styles/mixins';
import type { LogicProps } from './types';

const useLogic = ({ onSetVolume, volume }: LogicProps) => {
  const isTabletLandscape = useMediaQuery(media.tabletLandscape);

  const { isMuted, level } = volume;

  const handleChangeVolume = useCallback(
    (e: Event, value: number | number[]) => {
      e.preventDefault();
      e.stopPropagation();

      if (typeof value === 'number') {
        onSetVolume({ isMuted: false, level: value });
      }
    },
    [onSetVolume],
  );

  const handleToggleMute: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      onSetVolume({ isMuted: !isMuted, level });
    },
    [isMuted, level, onSetVolume],
  );

  const handleSliderClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.stopPropagation();
    },
    [],
  );

  return {
    displayedVolume: isMuted ? 0 : level,
    handleChangeVolume,
    handleSliderClick,
    handleToggleMute,
    isTabletLandscape,
  };
};

export default useLogic;
export type UseLogic = ReturnType<typeof useLogic>;
