import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import openTypeformPopup from 'lib/openTypeformPopup';
import { RetreatUserAction } from 'graphql/generated';
import { ModalType } from 'graphql/reactive';
import { getProfile, getRetreat } from 'graphql/requests';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import useRetreatActions from 'graphql/hooks/useRetreatActions';
import { FORM_ID } from './constants';

const useConnect = () => {
  const { isOpen } = useModal(ModalType.END_RETREAT);
  const { closeModal } = useModalActions();
  const { isUpdatingUserRetreat, updateUserRetreat } = useRetreatActions();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleSubmit = useCallback(async () => {
    const retreat = await getRetreat();
    const { id } = retreat || {};

    if (!id) return;

    await updateUserRetreat({ action: RetreatUserAction.end, retreatId: id });
    navigate('/');
  }, [navigate, updateUserRetreat]);

  const handleEnd = useCallback(async () => {
    closeModal();
    const { uuid } = await getProfile();
    openTypeformPopup(FORM_ID, {
      autoClose: true,
      hidden: { uuid },
      onClose: handleSubmit,
      onSubmit: handleSubmit,
      size: 80,
    });
  }, [closeModal, handleSubmit]);

  return {
    handleClose,
    handleEnd,
    isOpen,
    isUpdatingUserRetreat,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
