import styled from 'styled-components';

export const Container = styled.div<{ $image: string }>`
  background-image: ${({ $image }) => `url('${$image}')`};
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: -5%;
  filter: blur(120px);
  left: 0;
  position: absolute;
  right: 0;
  top: -5%;
  transform: translateZ(0);
  z-index: -1;
`;

export const Overlay = styled.div`
  background: #0e1529;
  bottom: 0;
  left: 0;
  opacity: 0.4;
  position: absolute;
  right: 0;
  top: 0;
`;
