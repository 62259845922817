import styled from 'styled-components';
import { Form as FormikForm } from 'formik';
import colorWithAlpha from 'lib/color';
import { media } from 'styles/mixins';
import DefaultLink from 'components/Link';
import DefaultRetreatCard from './RetreatCard';
import DefaultRemindersStep from './RemindersStep';

export const Form = styled(FormikForm)`
  align-items: center;
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
`;

export const RemindersStep = styled(DefaultRemindersStep)`
  margin-bottom: 3.125rem;

  ${media.phoneLandscape} {
    margin-bottom: 1.875rem;
  }
`;

export const RetreatCard = styled(DefaultRetreatCard)`
  ${media.phoneLandscape} {
    margin-top: 2.625rem;
  }
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1rem;
`;

export const LinkButton = styled(DefaultLink)`
  align-items: center;
  background-color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? colorWithAlpha({
          color: theme.colors.coreNeutralWhite,
          alpha: 0.5,
        })
      : colorWithAlpha({
          color: theme.colors.coreNeutralBlack,
          alpha: 0.33,
        })};
  border-radius: 0.25rem;
  border: 1.5px solid ${({ theme }) => theme.colors.borderButtonEmphasized};
  color: ${({ theme }) => theme.colors.fontButtonEmphasized};
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  font-family: ${({ theme }) => theme.font.family.inter};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  justify-content: center;
  letter-spacing: 0.1em;
  line-height: 1.5;
  min-width: max-content;
  padding: 0.8rem 1rem;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: background-color, color, border-color;
  transition: all 200ms ease;
  width: 100%;

  :hover {
    background-color: transparent;
  }

  :focus {
    background-color: ${({ theme }) =>
      colorWithAlpha({
        color: theme.colors.coreTeal500,
        alpha: 0.25,
      })};
    box-shadow: 0 0 0 2px #f4f4f4, 0 0 0 3.5px rgba(0, 96, 103, 0.25);
  }
`;

export const Cancel = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: inherit;
  letter-spacing: 0.01em;
  line-height: 1.4286;
  margin-top: 1.625rem;
  outline: none;
  text-decoration-line: underline;
  width: fit-content;
`;
