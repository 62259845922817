import { memo, type FC } from 'react';
import {
  CheckmarkIcon,
  Container,
  Features,
  Item,
  Text,
  Title,
} from './styles';
import { LIST_ITEMS } from './constants';
import type { Props } from './types';

const SubscriptionFeatures: FC<Props> = ({ className }) => (
  <Container className={className}>
    <Title>Become a Subscriber</Title>
    <Features>
      {LIST_ITEMS.map((v) => (
        <Item key={v}>
          <CheckmarkIcon />
          <Text>{v}</Text>
        </Item>
      ))}
    </Features>
  </Container>
);

export default memo(SubscriptionFeatures);
