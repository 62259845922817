import type { FC } from 'react';
import { Props } from './types';

const GlobeHemisphereWestLight: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m20.08 15.972-5.09-3.13a.75.75 0 0 0-.293-.105l-2.139-.288a.75.75 0 0 0-.785.438l-1.284 2.882a.75.75 0 0 0 .134.814l1.763 1.906a.749.749 0 0 1 .185.652l-.359 1.856"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m6.09 5.214-.838 1.983a.75.75 0 0 0-.012.555l1.078 2.875a.75.75 0 0 0 .544.47l2.01.432a.75.75 0 0 1 .517.406l.357.737a.75.75 0 0 0 .675.424h1.259"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m14.293 3.295.875 1.568a.75.75 0 0 1-.152.921l-2.523 2.282a.748.748 0 0 1-.14.1l-1.148.634a.75.75 0 0 1-.363.094h-2a.75.75 0 0 0-.692.459l-.78 1.853"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default GlobeHemisphereWestLight;
