import styled, { css } from 'styled-components';
import colorWithAlpha from 'lib/color';
import DefaultImage from 'components/Image';
import DefaultPlayWithProgress from 'components/PlayWithProgress';
import {
  Body as DefaultBody,
  Overline as DefaultOverline,
  Display as DefaultDisplay,
} from 'components/Typography';
import type { Props } from './types';

export const CONTAINER_WIDTH = '17.5rem';

export const Container = styled.div<{
  $hasNoImage: boolean;
  onClick: Props['onClick'];
}>`
  text-align: left;
  transition: background-color 200ms ease;
  width: ${({ $hasNoImage }) => ($hasNoImage ? '100%' : CONTAINER_WIDTH)};

  ${({ onClick }) =>
    onClick &&
    css`
      background: none;
      border: none;
      cursor: pointer;

      :hover {
        background: ${({ theme }) =>
          theme.name === 'LIGHT'
            ? colorWithAlpha({
                color: theme.colors.coreNeutralBlack,
                alpha: 0.01,
              })
            : colorWithAlpha({
                color: theme.colors.coreNeutralWhite,
                alpha: 0.05,
              })};
      }

      :active {
        background: ${({ theme }) =>
          theme.name === 'LIGHT'
            ? colorWithAlpha({
                color: theme.colors.coreNeutralBlack,
                alpha: 0.05,
              })
            : colorWithAlpha({
                color: theme.colors.coreNeutralWhite,
                alpha: 0.15,
              })};
      }
    `}
`;

export const Image = styled(DefaultImage)`
  border-radius: ${({ theme }) => theme.radius.xsm};
  margin-bottom: ${({ theme }) => theme.measures.m};
`;

export const InfoAndProgress = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Footer = styled.div``;

export const Info = styled.div``;

export const Overline = styled(DefaultOverline)`
  color: ${({ theme }) => theme.colors.fontEmphasized};
`;

export const Title = styled(DefaultDisplay).attrs(() => ({ $size: 'small' }))`
  line-height: 1.25;
`;

export const Subtitle = styled(DefaultBody).attrs(
  ({ $bold }: { $bold?: boolean }) => ({
    $size: 'medium',
    $weight: $bold ? 'bold' : 'regular',
  }),
)<{ $bold?: boolean }>`
  color: ${({ theme }) => theme.colors.fontDeemphasized};
`;

export const Description = styled(DefaultBody).attrs(() => ({
  $size: 'medium',
  $weight: 'regular',
}))`
  color: ${({ theme }) => theme.colors.fontDeemphasized};
  margin-top: ${({ theme }) => theme.measures.xxs};
`;

export const PlayWithProgress = styled(DefaultPlayWithProgress)`
  flex: 0 0 auto;
`;
