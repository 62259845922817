import { apolloClient } from 'services/api';
import { GetSettingsDocument } from '../generated';
import { getProfile } from '../requests';

async function cacheUpdateSettings(settings: string) {
  if (settings) {
    const { id, typename } = await getProfile();

    apolloClient.writeQuery({
      query: GetSettingsDocument,
      data: { __typename: 'Query', me: { __typename: typename, id, settings } },
    });
  }
}

export default cacheUpdateSettings;
