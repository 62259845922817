import { FC, memo } from 'react';
import { Container, FormControlLabel, MaterialSwitch } from './styles';
import { Props } from './types';
import useConnect from './connect';

const Switch: FC<Props> = ({
  className,
  id,
  label,
  labelPosition = 'start',
  name,
  readOnly,
  ref,
  submitOnChange,
}) => {
  const { value, handleChange } = useConnect({ name, submitOnChange });

  return (
    <Container className={className}>
      <FormControlLabel
        control={
          <MaterialSwitch
            checked={value}
            disableRipple
            id={id}
            inputRef={ref}
            name={name}
            onChange={handleChange}
            readOnly={readOnly}
            disabled={readOnly}
          />
        }
        label={label}
        labelPlacement={labelPosition}
      />
    </Container>
  );
};

export default memo(Switch);
