import { memo } from 'react';
import type { FC } from 'react';
import { AppConfig } from 'config';
import { Container, Link, P } from './styles';
import { Props } from './types';

const Conditions: FC<Props> = ({ isPublic }) => (
  <Container>
    <P $highlight>
      Subscriptions billed as one payment. Recurring billing. Cancel anytime for
      any reason.
    </P>
    <P>
      By continuing, you agree to Waking Up’s{' '}
      <Link
        href={isPublic ? AppConfig.termsURL : undefined}
        to={isPublic ? undefined : '/account/terms'}
      >
        Terms & Conditions
      </Link>{' '}
      and{' '}
      <Link
        href={isPublic ? AppConfig.privacyURL : undefined}
        to={isPublic ? undefined : '/account/privacy'}
      >
        Privacy Policy
      </Link>
    </P>
  </Container>
);

export default memo(Conditions);
