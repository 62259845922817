import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultLink from 'components/Link';
import DefaultIcon from 'components/Icon';

export const Container = styled(DefaultLink)`
  align-items: stretch;
  background: ${({ theme }) => theme.colors.coreBlue500};
  border: 1px solid
    ${({ theme }) =>
      theme.name === 'DARK' ? theme.colors.coreNeutralBlack : 'transparent'};
  border-radius: 0.5rem;
  box-shadow: 17.3946px 41.7075px 51.9417px rgba(0, 0, 0, 0.115977),
    10.4143px 24.9707px 29.6583px rgba(0, 0, 0, 0.0978192),
    5.45406px 13.0773px 16.4152px rgba(0, 0, 0, 0.0821808),
    2.24447px 5.38162px 9.11953px rgba(0, 0, 0, 0.0640233),
    0.516228px 1.23777px 4.67872px rgba(0, 0, 0, 0.038309);
  color: ${({ theme }) => theme.colors.coreNeutralAurora};
  cursor: pointer;
  display: flex;
  margin-bottom: ${({ theme }) => theme.measures.x};
  outline-offset: 0.25rem;
  padding: 1.125rem 1.0625rem 0.9375rem 1.0625rem;
  position: relative;
  text-align: left;
  text-decoration: none;

  ${media.phoneLandscape} {
    margin-bottom: ${({ theme }) => theme.measures.l};
  }

  ${media.phonePortrait} {
    flex-direction: column;
  }
`;

export const Info = styled.div`
  flex: 1 1 0;
`;

export const Title = styled.h3`
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  letter-spacing: 0.01em;
  line-height: 1.1;
`;

export const Subtitle = styled.p`
  font-size: 0.75rem;
  letter-spacing: 0.01em;
  line-height: 1.25;
  margin-top: 0.3125rem;
`;

export const CtaSmall = styled.p`
  align-items: center;
  display: flex;
  font-size: 0.7rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  letter-spacing: 0.06em;
  line-height: 1.3;
  margin-top: 0.625rem;
`;

export const PlayIcon = styled(DefaultIcon).attrs(() => ({ size: 14 }))`
  display: block;
  flex: 0 0 auto;
  margin-right: 0.4rem;
`;

export const CtaBig = styled.div`
  align-self: center;
  background-color: ${({ theme }) => theme.colors.coreNeutralWhite};
  border-radius: 0.125rem;
  color: ${({ theme }) => theme.colors.coreBlue500};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.03em;
  line-height: 1.25;
  padding: 0.625rem;
  text-align: center;
  text-transform: uppercase;

  ${media.phonePortrait} {
    margin-top: 1.2rem;
    width: 100%;
  }
`;

export const LiveFlag = styled.div`
  align-items: flex-end;
  background: ${({ theme }) => theme.colors.coreRed500};
  border-radius: 0.125rem;
  display: inline-flex;
  font-size: 0.7rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  left: 1.125rem;
  letter-spacing: 0.06em;
  line-height: 0.6;
  padding: 0.1rem 0.5rem 0.3rem 0.475rem;
  position: absolute;
  text-transform: uppercase;
  top: -0.6rem;
`;

export const EqualizerIcon = styled(DefaultIcon).attrs(() => ({ size: 12 }))`
  display: block;
  flex: 0 0 auto;
  margin-right: 0.1875rem;
`;
