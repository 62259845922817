import kebabCase from 'lodash/kebabCase';
import { ApiConfig } from 'config';
import { normalizeCourse } from 'models/Course';
import { normalizePack } from 'models/Pack';
import {
  RetreatUserState,
  type LiveMeetingInfoDataFragment,
  type RetreatDataFragment,
} from 'graphql/generated';

export const RETREAT_LIVE_MEETING_POLL_INTERVAL = 30 * 1000;

export const normalizeLiveMeetingInfo = (
  liveMeetingInfo: LiveMeetingInfoDataFragment,
) => ({
  description: liveMeetingInfo?.description || '',
  endDate: (liveMeetingInfo?.start_date || '') as string,
  id: liveMeetingInfo?.id || '',
  link: liveMeetingInfo?.link || '',
  startDate: (liveMeetingInfo?.start_date || '') as string,
  title: liveMeetingInfo?.title || '',
});

export type LiveMeetingInfo = ReturnType<typeof normalizeLiveMeetingInfo>;

export const normalizeRetreat = (retreat: RetreatDataFragment) => {
  const courses = retreat.courses?.map(normalizeCourse) || [];

  const pack = retreat.pack ? normalizePack(retreat.pack) : null;

  if (pack) {
    pack.retreatId = retreat.id;
    pack.retreatTitle = retreat.title;
    pack.courses = courses.map((c, index) => ({
      ...c,
      isLastCourse: courses.length - 1 === index,
      packAutoPlay: pack.autoPlay,
      packCategory: pack.category,
      packEndMessage: pack.endMessage,
      packHash: pack.hash,
      packHasNewCourses: pack.hasNewCourses,
      packId: pack.id,
      packImageThumbnailsThumbX1: pack.imageThumbnailsThumbX1,
      packImageUrl: pack.imageUrl,
      packIsNew: pack.isNew,
      packIsPrimaryPack: !!c.primaryPackId && pack.id === c.primaryPackId,
      packOverwriteCourseImage: pack.overwriteCourseImage,
      packParentPackId: pack.parentPackId,
      packProgress: pack.progress,
      packSubtitle: pack.subtitle,
      packTitle: pack.title,
      packType: pack.type,
      retreatHash: retreat.hash,
      retreatId: retreat.id,
      retreatTitle: retreat.title,
    }));
  }

  const id = retreat.id || '';

  return {
    about: retreat.about || '',
    active: retreat.active ?? false,
    addToCalendarUrl: `${ApiConfig.API}/content/retreat/${id}/calendar`,
    ctaReappearHours: retreat.cta_reappear_hours || 72,
    description: retreat.description || '',
    endDate: (retreat.end_date || '') as string,
    faq: retreat.faq || '',
    hash: retreat.hash || '',
    id,
    imageAlt: '',
    imageThumbnailsThumbX1: retreat.image?.thumbnails?.thumb_x1 || '',
    imageThumbnailsThumbX2: retreat.image?.thumbnails?.thumb_x2 || '',
    imageUrl: retreat.image?.url || '',
    liveMeetingInfo: retreat.live_meeting_info
      ? normalizeLiveMeetingInfo(retreat.live_meeting_info)
      : null,
    pack,
    slug: retreat.title ? kebabCase(retreat.title) : null,
    startDate: (retreat.start_date || '') as string,
    subtitle: retreat.subtitle || '',
    title: retreat.title || '',
    trailer: retreat.trailer ? normalizeCourse(retreat.trailer) : null,
    usersAttending: retreat.users_attending || 0,
    userState: retreat.user_state || RetreatUserState.idle,
    waitingRoomAvailableDate: (retreat.waiting_room_available_date ||
      '') as string,
  };
};

export type Retreat = ReturnType<typeof normalizeRetreat>;
