import styled from 'styled-components';
import { media } from 'styles/mixins';
import Button from 'components/Button';
import DefaultSelect from 'components/Inputs/Select';
import DefaultTextField from 'components/Inputs/TextField';

export const Title = styled.h2`
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.25;
  margin-bottom: 1.2rem;
  text-align: center;

  ${media.phonePortrait} {
    font-size: 1.6rem;
  }
`;

export const Description = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.2rem;
`;

export const TextField = styled(DefaultTextField)`
  margin-bottom: 1rem;
`;

export const Select = styled(DefaultSelect)`
  margin-bottom: 1rem;

  .react-select__menu {
    z-index: 2;
  }
`;

export const ButtonsContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: 1rem;

  ${media.phonePortrait} {
    align-items: stretch;
    margin-bottom: 0;
    flex-flow: column-reverse nowrap;
  }
`;

export const Submit = styled(Button)<{ isLoading: boolean }>`
  background-color: #215adb;
  border: none;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.coreNeutralWhite};
  display: inline-block;
  margin-top: 0.5rem;
  padding: 1.0625rem 0;

  &:disabled {
    background-color: #e5e5e5;
    pointer-events: none;
  }

  &:hover,
  &:focus {
    background-color: ${({ isLoading }) => !isLoading && '#2555c5'};
  }
`;
