import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultIcon from 'components/Icon';
import {
  Heading as DefaultHeading,
  Body as DefaultBody,
} from 'components/Typography';

export const Container = styled.div`
  padding-top: ${({ theme }) => theme.measures.xl};
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Icon = styled(DefaultIcon).attrs(() => ({ size: 40 }))`
  display: block;
`;

export const Title = styled(DefaultHeading).attrs(() => ({ $weight: 'bold' }))`
  line-height: 1.3;
  margin-top: 1rem;

  ${media.phonePortrait} {
    max-width: 16rem;
  }
`;

export const Subtitle = styled(DefaultBody).attrs(() => ({
  $size: 'medium',
  $weight: 'regular',
}))`
  margin-top: 0.5625rem;
  max-width: 40rem;

  ${media.phonePortrait} {
    max-width: 18rem;
  }
`;
