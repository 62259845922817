import { useCallback, useRef } from 'react';

const useCarouselControls = () => {
  const carouselRef = useRef<HTMLDivElement | null>(null);

  const handleControlClick = useCallback(
    (type: 'back' | 'forward') => {
      carouselRef?.current?.scrollTo({
        behavior: 'smooth',
        left:
          type === 'back'
            ? carouselRef.current.scrollLeft - 672
            : carouselRef.current.scrollLeft + 672,
      });
    },
    [carouselRef],
  );

  return { carouselRef, handleControlClick };
};

export default useCarouselControls;
export type UseCarouselControls = ReturnType<typeof useCarouselControls>;
