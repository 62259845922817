import type { FC } from 'react';
import { Route } from 'react-router-dom';
import differenceInDays from 'date-fns/differenceInDays';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import startOfDay from 'date-fns/startOfDay';
import { GiftCardType } from 'graphql/generated';
import DialogModal from 'containers/Modals/Dialog';
import EndRetreatModal from 'containers/Modals/EndRetreat';
import GiveFreeTrialModal from 'containers/Modals/GiveFreeTrial';
import IntroCourseSectionCompletedModal from 'containers/Modals/IntroCourseSectionCompleted';
import JoinRetreatModal from 'containers/Modals/JoinRetreat';
import LeaveRetreatModal from 'containers/Modals/LeaveRetreat';
import Navigate from 'components/Navigate';
import NewScholarship from 'containers/NewScholarship';
import OnboardingExperienceModal from 'containers/Modals/OnboardingExperience';
import OnboardingOpenAccessModal from 'containers/Modals/OnboardingOpenAccess';
import OnboardingSlidesModal from 'containers/Modals/OnboardingSlides';
import ReserveRetreatModal from 'containers/Modals/ReserveRetreat';
import ResetContentMenuModal from 'containers/Modals/ResetContentMenu';
import RetreatLiveMeetingModal from 'containers/Modals/RetreatLiveMeeting';
import SelfTimerConfigurationModal from 'containers/Modals/SelfTimerConfiguration';
import SendEmailModal from 'containers/Modals/SendEmail';
import ShareLinkModal from 'containers/Modals/ShareLink';
import ShareAppModal from 'containers/Modals/ShareApp';
import StripePaymentModal from 'containers/Modals/StripePayment';
import SupportModal from 'containers/Modals/Support';
import UnlockFreeMonthModal from 'containers/Modals/UnlockFreeMonth';
import UnlockFreeMonthSuccessModal from 'containers/Modals/UnlockFreeMonthSuccess';
import UpgradeToAnnualModal from 'containers/Modals/UpgradeToAnnual';
import AddFamilyMemberModal from 'containers/Modals/AddFamilyMember';
import AccountEditPage from './Account/Edit';
import Bookmarks from './Bookmarks';
import ChangePaymentCard from './Account/ChangePaymentCard';
import Conversation from './Conversation';
import DeepLinks from './DeepLinks';
import Details from './Details';
import FamilyMembers from './Account/FamilyMembers';
import GiftCardPurchasePage from './Account/GiftCardPurchase';
import HeadPickerPage from './Account/HeadPicker';
import HolidayGiftCardPurchasePage from './Account/HolidayGiftCardPurchase';
import Home from './Home';
import InfoPage from './Account/InfoPage';
import JoinLive from './JoinLive';
import Life from './Life';
import Navigation from './Navigation';
import NotificationsPage from './Account/Notifications';
import OptionsMenu from './OptionsMenu';
import Player from './Player';
import Practice from './Practice';
import ProfilePage from './Account/Profile';
import RecentlyPlayed from './RecentlyPlayed';
import Redeem from './Account/Redeem';
import RedirectCorporate from './RedirectCorporate';
import Search from './Search';
import Subscription from './Account/Subscription';
import Theory from './Theory';
import TrialExpirationDays from './BannerNotifications/TrialExpirationDays';
import TrialExpired from './BannerNotifications/TrialExpired';
import UpgradeToAnnual from './BannerNotifications/UpgradeToAnnual';
import { Container, Content, Routes } from './styles';
import { DEEPLINKS_ROUTES } from './constants';
import type { Props } from './types';
import useConnect from './connect';

const Main: FC<Props> = () => {
  const {
    isEligibleForYearlyUpgradeWithDiscount,
    location,
    openAccessLastCheckedDate,
    returnTo,
    showOpenAccessExpiredBanner,
    showTrialBanner,
    subscriptionExpirationDate,
    subscriptionIsEligibleForYearlyUpgradeWithDiscount,
    subscriptionStartDate,
    subscriptionSubtype,
    upgradeToAnnualLastCheckedDate,
    userId,
  } = useConnect();

  if (typeof returnTo === 'string') {
    return <Navigate replace to={returnTo} />;
  }

  const now = new Date();

  const showTrialExpired = showOpenAccessExpiredBanner;
  const showTrialExpirationDays =
    showTrialBanner &&
    !showOpenAccessExpiredBanner &&
    ((subscriptionSubtype === 'open_access' &&
      isAfter(now, parseISO(subscriptionStartDate))) ||
      (subscriptionSubtype === 'OFFER-FF98B3' &&
        isAfter(now, parseISO(subscriptionStartDate)))) &&
    (!openAccessLastCheckedDate ||
      isBefore(parseISO(openAccessLastCheckedDate), startOfDay(now)));

  let showUpgradeToAnnual = false;
  if (subscriptionIsEligibleForYearlyUpgradeWithDiscount) {
    if (!upgradeToAnnualLastCheckedDate) {
      showUpgradeToAnnual = true;
    } else {
      const dtSubscriptionExpirationDate = startOfDay(
        parseISO(subscriptionExpirationDate),
      );
      const daysUntilExpiration =
        differenceInDays(dtSubscriptionExpirationDate, now) + 1;
      const daysUntilExpirationFromLastChecked =
        differenceInDays(
          dtSubscriptionExpirationDate,
          parseISO(upgradeToAnnualLastCheckedDate),
        ) + 1;
      showUpgradeToAnnual =
        daysUntilExpiration <= 7 && daysUntilExpirationFromLastChecked > 7;
    }
  }

  return (
    <Container>
      <Content>
        <Navigation />
        {showTrialExpired && <TrialExpired />}
        {showTrialExpirationDays && (
          <TrialExpirationDays
            expirationDate={subscriptionExpirationDate}
            isTrialExtension={subscriptionSubtype === 'OFFER-FF98B3'}
            userId={userId}
          />
        )}
        {showUpgradeToAnnual && (
          <UpgradeToAnnual
            expirationDate={subscriptionExpirationDate}
            userId={userId}
          />
        )}
        <Routes
          $isTrialExpirationDaysBannerOpen={showTrialExpirationDays}
          $isTrialExpiredBannerOpen={showTrialExpired}
          $isUpgradeToAnnualOpen={showUpgradeToAnnual}
          location={location}
        >
          <Route element={<Home />} path="/" />
          <Route path="/account">
            <Route element={<ProfilePage />} index />
            <Route path="edit">
              <Route element={<AccountEditPage />} index />
              <Route element={<HeadPickerPage />} path="pick-a-head" />
            </Route>
            <Route path="subscription">
              <Route element={<Subscription />} index />
              <Route element={<Subscription />} path=":company" />
              <Route
                element={<ChangePaymentCard />}
                path="change-payment-card"
              />
              <Route element={<FamilyMembers />} path="manage-family-members" />
            </Route>
            <Route element={<Subscription />} path="corporate/:company" />
            <Route element={<NewScholarship />} path="scholarship-options" />
            <Route
              element={<GiftCardPurchasePage />}
              path="purchase-gift-card"
            />
            <Route
              element={
                <HolidayGiftCardPurchasePage code={GiftCardType.ONE_YEAR30} />
              }
              path="holiday-gift-card"
            />
            <Route element={<Redeem />} path="redeem/promo" />
            <Route element={<Redeem />} path="redeem/gift" />
            <Route element={<InfoPage section="course" />} path="course" />
            <Route element={<InfoPage section="help" />} path="help" />
            <Route element={<InfoPage section="privacy" />} path="privacy" />
            <Route element={<InfoPage section="sam" />} path="sam" />
            <Route element={<InfoPage section="terms" />} path="terms" />
            <Route element={<NotificationsPage />} path="notifications" />
          </Route>
          <Route path="/corporate/:company" element={<RedirectCorporate />} />
          <Route path="/theory">
            <Route element={<Theory />} index />
            <Route
              path="conversations/:courseHash"
              element={<Conversation />}
            />
            <Route element={<Theory />} path=":pack/:subpack/:subpack2" />
            <Route element={<Theory />} path=":pack/:subpack" />
            <Route element={<Theory />} path=":pack" />
          </Route>
          <Route path="/life">
            <Route element={<Life />} index />
            <Route
              path="conversations/:courseHash"
              element={<Conversation />}
            />
            <Route element={<Life />} path=":pack/:subpack/:subpack2" />
            <Route element={<Life />} path=":pack/:subpack" />
            <Route element={<Life />} path=":pack" />
          </Route>
          <Route path="/practice">
            <Route element={<Practice />} index />
            <Route element={<Practice />} path=":pack/:subpack/:subpack2" />
            <Route element={<Practice />} path=":pack/:subpack" />
            <Route element={<Practice />} path=":pack" />
          </Route>
          <Route path="/meditations">
            <Route element={<Practice />} index />
            <Route element={<Practice />} path=":pack/:subpack/:subpack2" />
            <Route element={<Practice />} path=":pack/:subpack" />
            <Route element={<Practice />} path=":pack" />
          </Route>
          <Route element={<Bookmarks />} path="/my-library" />
          <Route element={<RecentlyPlayed />} path="/recently-played" />
          <Route element={<Search />} path="/search" />
          <Route element={<JoinLive />} path="/join-live" />
          <Route element={<Subscription />} path="/mobile/subscription" />
          {DEEPLINKS_ROUTES.map((v) => (
            <Route key={v} element={<DeepLinks />} path={v} />
          ))}

          <Route
            element={
              <Navigate
                replace
                to={{ pathname: '/my-library', search: location.search }}
              />
            }
            path="/favourites"
          />
          <Route
            element={
              <Navigate
                replace
                to={{ pathname: '/my-library', search: location.search }}
              />
            }
            path="/favorites"
          />
          <Route
            element={
              <Navigate
                replace
                to={{
                  pathname: '/openview/myLibrary',
                  search: location.search,
                }}
              />
            }
            path="/openview/favorites"
          />
          <Route
            element={
              <Navigate
                replace
                to={{ pathname: '/account', search: location.search }}
              />
            }
            path="/promotions"
          />
          <Route
            element={
              <Navigate
                replace
                to={{ pathname: '/account', search: location.search }}
              />
            }
            path="/settings/*"
          />
          <Route
            element={
              <Navigate
                replace
                to={{
                  pathname: '/account/redeem/promo',
                  search: location.search,
                }}
              />
            }
            path="/promotions/redeem"
          />
          <Route
            element={
              <Navigate
                replace
                to={{
                  pathname: '/account/redeem/gift',
                  search: location.search,
                }}
              />
            }
            path="/gifts/redeem"
          />
          <Route
            element={
              <Navigate
                replace
                to={{ pathname: '/account', search: location.search }}
              />
            }
            path="/payments"
          />
          <Route
            element={
              <Navigate
                replace
                to={{
                  pathname: '/account/subscription',
                  search: location.search,
                }}
              />
            }
            path="/membership"
          />
          <Route
            element={
              <Navigate
                replace
                to="/account/subscription/change-payment-card"
              />
            }
            path="/account/subscription/change_payment_card"
          />
          <Route
            element={
              <Navigate
                replace
                to={{
                  pathname: '/account/scholarship-options',
                  search: location.search,
                }}
              />
            }
            path="/scholarship-options"
          />
          <Route
            element={<Navigate replace to="/account/purchase-gift-card" />}
            path="/purchase-gift-card"
          />
          <Route
            element={<Navigate replace to="/account/holiday-gift-card" />}
            path="/holiday-gift-card"
          />
          <Route
            element={<Navigate replace to="/account/purchase-gift-card" />}
            path="/gifts"
          />
          <Route
            element={
              <Navigate
                replace
                to={{ pathname: '/checkout', search: location.search }}
              />
            }
            path="/subscription"
          />
          <Route element={<Navigate replace to="/" />} path="*" />
        </Routes>
        <Player />
        <OptionsMenu />
        <Details />
        <SupportModal />
        <SelfTimerConfigurationModal />
        <OnboardingSlidesModal />
        <OnboardingExperienceModal />
        <OnboardingOpenAccessModal />
        <GiveFreeTrialModal />
        <ShareLinkModal />
        <ResetContentMenuModal />
        <UnlockFreeMonthModal />
        <UnlockFreeMonthSuccessModal />
        <LeaveRetreatModal />
        <JoinRetreatModal />
        <ReserveRetreatModal />
        <EndRetreatModal />
        <RetreatLiveMeetingModal />
        <StripePaymentModal />
        <IntroCourseSectionCompletedModal />
        <DialogModal />
        <AddFamilyMemberModal />
        {isEligibleForYearlyUpgradeWithDiscount && <UpgradeToAnnualModal />}
        <SendEmailModal />
        <ShareAppModal />
      </Content>
    </Container>
  );
};

export default Main;
