import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import type { MouseEventHandler, SyntheticEvent } from 'react';
import { formatTime } from 'lib/formatTime';
import type { LogicProps } from './types';

const useLogic = ({
  duration,
  onChangePosition = () => null,
  position = 0,
}: LogicProps) => {
  const [localPosition, setLocalPosition] = useState<number>(position);
  const isDragging = useRef<boolean>(false);

  const { isDurationOverOneHour, timeDuration } = useMemo(() => {
    const durationOverOneHour = !!duration && duration > 3600;
    return {
      isDurationOverOneHour: durationOverOneHour,
      timeDuration: duration
        ? formatTime(Math.round(duration), durationOverOneHour)
        : '--:--',
    };
  }, [duration]);

  const displayedPosition = isDragging.current ? localPosition : position;
  const { timePending, timePlayed } = !duration
    ? {
        timePending: `${isDurationOverOneHour ? '--:' : ''}--:--`,
        timePlayed: `${isDurationOverOneHour ? '--:' : ''}--:--`,
      }
    : {
        timePending: formatTime(
          duration - displayedPosition,
          isDurationOverOneHour,
        ),
        timePlayed: formatTime(displayedPosition, isDurationOverOneHour),
      };

  useEffect(() => {
    if (!isDragging.current) {
      setLocalPosition(position);
    }
  }, [position]);

  const handleSliderClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.stopPropagation();
    },
    [],
  );

  const handleSliderChange = useCallback(
    (e: Event, value: number | number[]) => {
      e.preventDefault();
      e.stopPropagation();

      isDragging.current = true;
      const newValue = Array.isArray(value) ? value[0] : value;
      setLocalPosition(newValue);
    },
    [],
  );

  const handleSliderChangeCommitted = useCallback(
    (e: Event | SyntheticEvent<Element, Event>, value: number | number[]) => {
      e.preventDefault();
      e.stopPropagation();

      isDragging.current = false;
      const newValue = Array.isArray(value) ? value[0] : value;
      onChangePosition(newValue);
    },
    [onChangePosition],
  );

  return {
    handleSliderClick,
    handleSliderChange,
    handleSliderChangeCommitted,
    localPosition,
    timeDuration,
    timePending,
    timePlayed,
  };
};

export default useLogic;
export type UseLogic = ReturnType<typeof useLogic>;
