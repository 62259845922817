import { memo } from 'react';
import type { FC } from 'react';
import PublicPageLayout from 'components/PublicPageLayout';
import CheckoutForm from './CheckoutForm';
import Features from './Features';
import Price from './Price';
import {
  Column,
  Container,
  Header,
  Information,
  Loader,
  Progress,
  RefundInfo,
  Title,
  TrialPrice,
} from './styles';
import type { Props } from './types';
import useConnect from './connect';

const Trial: FC<Props> = ({ className }) => {
  const {
    email,
    isLoading,
    planAmount,
    planId,
    planInterval,
    trialDays,
    trialDuration,
  } = useConnect();

  return (
    <PublicPageLayout className={className}>
      {isLoading ? (
        <Loader>
          <Progress />
        </Loader>
      ) : (
        <Container>
          <Column>
            <Information>
              <Header>
                <Title>Get unlimited access to Waking Up for </Title>
                <TrialPrice>$0 USD today</TrialPrice>
                <Price
                  planAmount={planAmount}
                  planInterval={planInterval}
                  trialDays={trialDays}
                />
              </Header>
              <Features />
              <RefundInfo>
                After your trial ends, your subscription is protected by our
                <strong> risk-free, money-back guarantee.</strong> If at any
                point you decide Waking Up isn’t for you, we’ll give you a full
                refund, no questions asked.
              </RefundInfo>
            </Information>
          </Column>
          <Column>
            <CheckoutForm
              isPublic
              planAmount={planAmount}
              planId={planId}
              planInterval={planInterval}
              priceId={planId}
              userEmail={email}
              trialDuration={trialDuration}
            />
          </Column>
        </Container>
      )}
    </PublicPageLayout>
  );
};

export default memo(Trial);
