import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { SubscribeSelfServeDocument } from '../generated';

const useScholarshipActions = () => {
  const [subscribeSelfServeMutation, { loading: isSelfServing }] = useMutation(
    SubscribeSelfServeDocument,
  );

  const subscribeSelfServe = useCallback(
    async ({
      email,
      firstName,
      lastName,
      source,
      subType,
    }: {
      email: string;
      firstName: string;
      lastName: string;
      source: string;
      subType?: string;
    }) => {
      const { data } = await subscribeSelfServeMutation({
        variables: {
          data: {
            email,
            first_name: firstName,
            last_name: lastName,
            referral_source: source,
            sub_type: subType,
          },
        },
      });

      return data?.subscribeSelfServe;
    },
    [subscribeSelfServeMutation],
  );

  return {
    isSelfServing,
    loading: isSelfServing,
    subscribeSelfServe,
  };
};

export default useScholarshipActions;
export type UseScholarshipActions = ReturnType<typeof useScholarshipActions>;
