/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { TAG_DATA_FRAGMENT } from '../fragments/tag';

export const ALGOLIA_SEARCH = gql`
  query Algolia {
    algoliaSearchKey
    algoliaSearchTerms {
      tags {
        ...TagData
      }
    }
  }
  ${TAG_DATA_FRAGMENT}
`;
