import { makeVar } from '@apollo/client';
import { AdditionalMeditationsFilter } from 'models/Pack';

export type Practice = {
  additionalMeditations: AdditionalMeditationsFilter;
};

export const practiceVar = makeVar<Practice>({
  additionalMeditations: AdditionalMeditationsFilter.SHOW_ALL,
});
