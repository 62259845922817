import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import { media } from 'styles/mixins';
import DefaultUserInfo from 'components/UserInfo';
import { AvatarContainer } from 'components/UserInfo/styles';
import DefaultUserStats from 'components/UserStats';
import DefaultIcon from 'components/Icon';
import DefaultLink from 'components/Link';

export const Container = styled(Drawer).attrs(() => ({
  anchor: 'right',
  transitionDuration: 300,
}))`
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.colors.backgroundBase};
  }

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .MuiDrawer-paperAnchorRight {
    left: 0;
  }
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: block;
  line-height: 0;
  outline: none;
  position: absolute;
  right: 1.5rem;
  top: 1.25rem;
  transition: opacity 0.15s ease 0.05s;

  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

export const Icon = styled(DefaultIcon)`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralBlack
      : theme.colors.coreNeutralWhite};
  height: 1.6rem;
  width: 1.6rem;
`;

export const Info = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundBase};
  display: flex;
  flex-flow: column nowrap;
  line-height: 1.5rem;
  padding: ${({ theme }) => `2.4rem ${theme.measures.m} `};
  position: sticky;
  top: 0;
`;

export const UserInfo = styled(DefaultUserInfo)`
  align-self: flex-start;
  margin-left: 1rem;

  ${AvatarContainer} {
    height: 6.6rem;
    width: 6.6rem;
  }
`;

export const UserStats = styled(DefaultUserStats)`
  flex: 1 1 auto;
  margin-top: 1.6rem;
  ${media.tabletPortrait} {
    width: 100%;
  }
`;

export const Links = styled.ul`
  margin-top: 0.6rem;
`;

export const AppsLinks = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1.8rem 0;
`;

export const AppLink = styled(DefaultLink)`
  color: inherit;
  display: block;
  line-height: 0;
  margin: 0 0.2rem;
  text-decoration: none;
`;

export const AppStoreBadge = styled(DefaultIcon).attrs(() => ({
  color: '#7E7E7E',
  name: 'appStoreBadge',
  size: 150,
}))`
  display: block;
`;

export const GooglePlayBadge = styled(DefaultIcon).attrs(() => ({
  color: '#7E7E7E',
  name: 'googlePlayBadge',
  size: 170,
}))`
  display: block;
`;
