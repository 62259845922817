import { gql } from '@apollo/client';
import {
  WIDGET_CAROUSEL_DATA_FRAGMENT,
  WIDGET_CONTINUE_LISTENING_DATA_FRAGMENT,
  WIDGET_CTA_DATA_FRAGMENT,
  WIDGET_IMAGE_DATA_FRAGMENT,
  WIDGET_SPOTLIGHT_DATA_FRAGMENT,
  WIDGET_UP_NEXT_DATA_FRAGMENT,
} from '../fragments';

export const HOME_CTA = gql`
  query HomeCta {
    homeCta {
      button_text
      deeplink
      description
      title
    }
  }
`;

export const GET_HOME = gql`
  query GetHome {
    home {
      ... on WidgetUpNext {
        ...WidgetUpNextData
      }
      ... on WidgetCTA {
        ...WidgetCTAData
      }
      ... on WidgetSpotlight {
        ...WidgetSpotlightData
      }
      ... on WidgetContinueListening {
        ...WidgetContinueListeningData
      }
      ... on WidgetCarousel {
        ...WidgetCarouselData
      }
      ... on WidgetImage {
        ...WidgetImageData
      }
    }
  }
  ${WIDGET_UP_NEXT_DATA_FRAGMENT}
  ${WIDGET_CTA_DATA_FRAGMENT}
  ${WIDGET_SPOTLIGHT_DATA_FRAGMENT}
  ${WIDGET_CONTINUE_LISTENING_DATA_FRAGMENT}
  ${WIDGET_CAROUSEL_DATA_FRAGMENT}
  ${WIDGET_IMAGE_DATA_FRAGMENT}
`;
