/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_REMOTE_CONFIG = gql`
  query GetRemoteConfig {
    remoteConfig {
      android_current_version
      android_min_version
      background_audios_chunks
      background_audios_filepath
      free_month_cta_image_dark
      free_month_cta_image_light
      free_month_cta_image_web
      ios_current_version
      ios_min_version
      section_life_description
      section_practice_description
      section_theory_description
    }
  }
`;
