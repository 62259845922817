import { memo } from 'react';
import type { FC } from 'react';
import formatCents from 'lib/formatCents';
import { SubscriptionPlanType } from 'graphql/generated';
import {
  Container,
  CurrentPrice,
  StandardPrice,
  StrikeThrough,
} from './styles';
import { Props } from './types';

const Price: FC<Props> = ({
  hasPromo,
  planAmount,
  planInterval,
  planOriginalAmount,
  planType,
  slashedOriginalPrice,
}) => {
  const currentPrice = `${formatCents(planAmount)}/${planInterval}`;
  const originalPrice = hasPromo
    ? `${formatCents(planOriginalAmount)}/${planInterval}`
    : `$129.99/year`;

  const standardPriceText = hasPromo ? `Normally ` : 'Subscribe in-app for ';
  const currentPriceText = hasPromo
    ? `Subscribe today for ${currentPrice}`
    : `Web-only price: ${currentPrice}`;

  return (
    <Container>
      {planType !== SubscriptionPlanType.family && (
        <StandardPrice>
          {standardPriceText}
          <StrikeThrough visible={slashedOriginalPrice}>
            {originalPrice}
          </StrikeThrough>
        </StandardPrice>
      )}
      <CurrentPrice>{currentPriceText}</CurrentPrice>
    </Container>
  );
};

export default memo(Price);
