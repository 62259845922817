import { memo, type FC } from 'react';
import TrackInfo from 'components/Player/TrackInfo';
import ControlButtons from 'components/Player/ControlButtons';
import DailyDurationSelect from 'components/Player/DailyDurationSelect';
import ProgressBar from 'components/Player/ProgressBar';
import VolumeControls from 'components/Player/VolumeControls';
import Audio from '../Audio';
import BackgroundAudio from '../BackgroundAudio';
import Autoplay from './Autoplay';
import BackgroundSounds from './BackgroundSounds';
import Share from './Share';
import Options from './Options';
import {
  Container,
  Content,
  Info,
  Progress,
  Toolbar,
  ToolbarButtons,
} from './styles';
import useConnect from './connect';

const DailyPlayer: FC = () => {
  const {
    audioUrl,
    autoplayType,
    dailyDuration,
    handleChangeDuration,
    handleClose,
    handleClosePlayer,
    handleComplete,
    handleEnd,
    handleInit,
    handleToggleDisplayStatus,
    hash,
    id,
    imageThumbnailsThumbX1,
    imageUrl,
    isLoadingData,
    isMinimized,
    isSpecial,
    sharedHash,
    startTime,
    subtitle,
    title,
  } = useConnect();

  const showAutoplay = !!autoplayType;
  const showOptionsMenu = !isSpecial;

  return (
    <Container
      backgroundImage={imageThumbnailsThumbX1}
      isMinimized={isMinimized}
      onClose={handleClosePlayer}
      onToggleDisplayStatus={handleToggleDisplayStatus}
    >
      <Content>
        <Info>
          <TrackInfo
            imgShadow="drop-shadow"
            imgUrl={imageUrl}
            isMinimized={isMinimized}
            subtitle={subtitle}
            title={title}
          />
        </Info>
        <Audio
          audioUrl={audioUrl}
          autoPlay
          onClose={handleClose}
          onComplete={handleComplete}
          onEnd={handleEnd}
          onInit={handleInit}
          startTime={startTime}
        >
          {({
            duration,
            handleIncreasePosition,
            handleSetPosition,
            handleSetVolume,
            handleTogglePlay,
            isLoading,
            isPlaying: isPlayingAudioPlayer,
            position,
            volume,
          }) => {
            const showDurationSelect =
              !isLoadingData && !isLoading && !isMinimized && position < 30;

            return (
              <>
                {showDurationSelect && (
                  <DailyDurationSelect
                    duration={dailyDuration}
                    onChangeDuration={handleChangeDuration}
                  />
                )}
                <Progress>
                  <ProgressBar
                    duration={duration}
                    isLoading={isLoadingData || isLoading}
                    isMinimized={isMinimized}
                    onChangePosition={handleSetPosition}
                    position={position}
                  />
                  {!isMinimized && (
                    <VolumeControls
                      onSetVolume={handleSetVolume}
                      volume={volume}
                    />
                  )}
                </Progress>
                <ControlButtons
                  isLoading={isLoadingData || isLoading}
                  isMinimized={isMinimized}
                  isPlaying={isPlayingAudioPlayer}
                  onIncreasePosition={handleIncreasePosition}
                  onPlayChange={handleTogglePlay}
                />
              </>
            );
          }}
        </Audio>
        <BackgroundAudio />
      </Content>
      {!isMinimized && (
        <Toolbar>
          <ToolbarButtons>
            <BackgroundSounds />
            {showAutoplay && <Autoplay />}
            <Share hash={hash} id={id} />
          </ToolbarButtons>
          {showOptionsMenu && <Options dailyId={id} sharedHash={sharedHash} />}
        </Toolbar>
      )}
    </Container>
  );
};

export default memo(DailyPlayer);
