import { memo, useCallback } from 'react';
import type { FC, MouseEventHandler } from 'react';
import formatCents from 'lib/formatCents';
import { Button, Description, Icon, Price } from './styles';
import { Props } from './types';

const Option: FC<Props> = ({
  description,
  disabled = false,
  isSelected,
  onClick,
  price,
}) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      onClick(price);
    },
    [onClick, price],
  );

  return (
    <Button
      $selected={isSelected}
      aria-checked={isSelected}
      disabled={disabled}
      onClick={handleClick}
      role="radio"
    >
      {isSelected && <Icon aria-hidden="true" name="checkCircleLight" />}
      <Price>{formatCents(price).split('.')[0]}</Price>
      <Description>{description}</Description>
    </Button>
  );
};

export default memo(Option);
