import { memo } from 'react';
import type { FC } from 'react';
import { Formik } from 'formik';
import { isProduction } from 'config';
import { Form, Switch } from './styles';
import type { Props } from './types';

const NotificationsForm: FC<Props> = ({ initialValues, onSubmit }) => (
  <Formik initialValues={initialValues} onSubmit={onSubmit}>
    <Form>
      <Switch label="New content" name="newContent" submitOnChange />
      <Switch
        label="Content recommendations"
        name="recommendations"
        submitOnChange
      />
      <Switch label="Daily recap" name="dailyRecaps" submitOnChange />
      {!isProduction && (
        <Switch label="Retreats" name="retreats" submitOnChange />
      )}
    </Form>
  </Formik>
);

export default memo(NotificationsForm);
