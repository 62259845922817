import { gql } from '@apollo/client';
import {
  LIVE_MEETING_INFO_DATA_FRAGMENT,
  RETREAT_DATA_FRAGMENT,
} from '../fragments';

export const GET_RETREATS = gql`
  query GetRetreats {
    retreats {
      ...RetreatData
    }
  }
  ${RETREAT_DATA_FRAGMENT}
`;

export const GET_RETREAT = gql`
  query GetRetreat {
    retreat {
      ...RetreatData
    }
  }
  ${RETREAT_DATA_FRAGMENT}
`;

export const GET_LIVE_MEETING_INFO = gql`
  query GetLiveMeetingInfo($id: ID!) {
    liveMeetingInfo(id: $id) {
      ...LiveMeetingInfoData
    }
  }
  ${LIVE_MEETING_INFO_DATA_FRAGMENT}
`;
