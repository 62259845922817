import styled, { css } from 'styled-components';
import DefaultIcon from 'components/Icon';
import colorWithAlpha from 'lib/color';

export const Container = styled.button`
  align-items: center;
  background-color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralWhite
      : colorWithAlpha({
          color: theme.colors.coreNeutralBlack,
          alpha: 0.1,
        })};
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  justify-content: space-between;
  letter-spacing: 0.01em;
  line-height: 1.4286%;
  max-height: 3.5rem;
  outline: none;
  padding: 1rem;
  width: 100%;

  &:first-child {
    border-radius: 0.625rem 0.625rem 0 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderBase};
  }

  &:last-child {
    border-radius: 0 0 0.625rem 0.625rem;
  }
`;

export const Icon = styled(DefaultIcon).attrs(({ theme }) => ({
  color:
    theme.name === 'LIGHT'
      ? theme.colors.fontBase
      : theme.colors.coreNeutralWhite,
}))<{ $active: boolean }>`
  ${({ $active, theme }) => {
    if ($active)
      return css`
        color: ${theme.name === 'LIGHT'
          ? theme.colors.coreTeal700
          : theme.colors.coreTeal500};
      `;
  }}
`;
