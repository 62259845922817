import { memo } from 'react';
import type { FC } from 'react';
import formatCents from 'lib/formatCents';
import {
  Container,
  CurrentPrice,
  StandardPrice,
  StrikeThrough,
} from './styles';
import { Props } from './types';

const Price: FC<Props> = ({ hasPromo, planAmount, planOriginalAmount }) => {
  const currentPriceText = hasPromo
    ? `Your price: ${formatCents(planAmount).replace('.00', '')}`
    : `${formatCents(planAmount).replace('.00', '')} Lifetime Membership`;

  return (
    <Container>
      {hasPromo && (
        <StandardPrice>
          Normal price:{' '}
          <StrikeThrough>{formatCents(planOriginalAmount)}</StrikeThrough>
        </StandardPrice>
      )}
      <CurrentPrice>{currentPriceText}</CurrentPrice>
    </Container>
  );
};

export default memo(Price);
