import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import parseISO from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';
import { AppConfig } from 'config';
import { trackEvent } from 'services/analytics';
import useCountdown from 'lib/useCountdown';
import { PlayerSource } from 'models/Player';
import { RetreatUserAction, RetreatUserState } from 'graphql/generated';
import { getProfile } from 'graphql/requests';
import useRetreatActions from 'graphql/hooks/useRetreatActions';
import usePlayerActions from 'graphql/hooks/usePlayerActions';
import useModalActions from 'graphql/hooks/useModalActions';
import type { ConnectProps } from './types';

const useConnect = ({
  endDate,
  hash,
  id,
  pack,
  screen,
  slug,
  title,
  trailer,
  userState,
  waitingRoomAvailableDate,
}: ConnectProps) => {
  const { isUpdatingUserRetreat, updateUserRetreat } = useRetreatActions();
  const { openRetreatPlayer } = usePlayerActions();
  const { openJoinRetreatModal, openReserveRetreatModal, openShareLinkModal } =
    useModalActions();
  const { countdown: waitingRoomCountdown } = useCountdown(
    waitingRoomAvailableDate,
  );
  const { countdown: joinCutoffCountdown } = useCountdown(endDate);
  const navigate = useNavigate();

  const trailerHash = trailer?.hash;
  const retreatPackUrl = pack?.slug
    ? `/practice/retreats/${slug}/${pack.slug}`
    : '';

  const isReservationOpen = isBefore(
    new Date(),
    parseISO(waitingRoomAvailableDate),
  );

  const showResume = userState === RetreatUserState.paused;
  const showJoin =
    userState !== RetreatUserState.paused &&
    waitingRoomCountdown <= 0 &&
    joinCutoffCountdown > 0;
  const showIdle =
    userState === RetreatUserState.idle &&
    waitingRoomCountdown > 0 &&
    joinCutoffCountdown > 0;
  const showReserved =
    userState === RetreatUserState.reserved &&
    waitingRoomCountdown > 0 &&
    joinCutoffCountdown > 0;

  useEffect(() => {
    let buttonMessage;
    if (showReserved) {
      buttonMessage = 'share_with_a_friend';
    } else if (showIdle) {
      buttonMessage = isReservationOpen
        ? 'reserve_your_spot'
        : 'retreat_countdown';
    } else if (showJoin) {
      buttonMessage = 'join_retreat';
    } else if (showResume) {
      buttonMessage = 'resume_retreat';
    }

    trackEvent('Event Display Landing Screen', {
      button_message: buttonMessage,
      event_hash: hash,
      event_type: 'retreat',
      name: title,
      screen,
    });
  }, [
    hash,
    isReservationOpen,
    screen,
    showIdle,
    showJoin,
    showReserved,
    showResume,
    title,
    userState,
  ]);

  const handleJoin = useCallback(() => {
    openJoinRetreatModal();
  }, [openJoinRetreatModal]);

  const handleResume = useCallback(async () => {
    if (retreatPackUrl) {
      await updateUserRetreat({
        action: RetreatUserAction.join,
        retreatId: id,
      });

      navigate(retreatPackUrl);
    }
  }, [id, navigate, retreatPackUrl, updateUserRetreat]);

  const handlePlayTrailer = useCallback(() => {
    if (trailerHash) {
      openRetreatPlayer({ hash: trailerHash, source: PlayerSource.RETREAT });
    }
  }, [openRetreatPlayer, trailerHash]);

  const handleReserve = useCallback(async () => {
    const { isSubscribed } = await getProfile();
    if (!isSubscribed) {
      navigate('/account/subscription');
      return;
    }

    trackEvent('Event RSVP', {
      event_hash: hash,
      event_type: 'retreat',
      name: title,
    });

    openReserveRetreatModal();
  }, [hash, navigate, openReserveRetreatModal, title]);

  const handleShare = useCallback(async () => {
    if (!hash || !id) return;

    trackEvent('Event Share', {
      event_hash: hash,
      event_type: 'retreat',
      name: title,
      source: 'retreat_button',
    });

    openShareLinkModal({
      id,
      description:
        'Share this link with anyone you feel would benefit from this Waking Up retreat',
      link: `${AppConfig.dynamicBaseUrl}/retreat/${hash}`,
      title: 'Share this retreat',
    });
  }, [hash, id, openShareLinkModal, title]);

  return {
    handleJoin,
    handlePlayTrailer,
    handleReserve,
    handleResume,
    handleShare,
    isReservationOpen,
    isUpdatingUserRetreat,
    showIdle,
    showJoin,
    showReserved,
    showResume,
    waitingRoomCountdown,
  };
};

export default useConnect;
export type UseConnectReturnType = ReturnType<typeof useConnect>;
