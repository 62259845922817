import styled from 'styled-components';
import colorWithAlpha from 'lib/color';
import DefaultIcon from 'components/Icon';

export const Container = styled.button<{ $active?: boolean }>`
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 50%;
  box-sizing: content-box;
  color: ${({ $active, theme }) =>
    $active ? theme.colors.fontEmphasized : theme.colors.coreNeutralAurora};
  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: center;
  line-height: 1;
  position: relative;
  user-select: none;
  width: 3rem;

  &::before {
    background: ${({ theme }) =>
      colorWithAlpha({
        color: theme.colors.coreNeutralBlack,
        alpha: 0.25,
      })};
    border-radius: 50%;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: transform 100ms ease;
    z-index: -1;
  }

  &:active::before {
    background: ${({ theme }) =>
      colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.33 })};
    transform: scale(1.16);
  }

  &:disabled {
    background: ${({ theme }) =>
      colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.1 })};
    color: ${({ theme }) =>
      colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.33 })};
    pointer-events: none;
  }
`;

export const Icon = styled(DefaultIcon)`
  display: block;
  height: 50%;
  width: 50%;
`;
