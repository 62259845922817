import type { FC } from 'react';
import {
  Caption,
  Container,
  Content,
  CornerBottomShadowImage,
  CornerTopShadowImage,
  Image,
  ImageContainer,
  Note,
  SenderName,
} from './styles';
import type { Props } from './types';

const GiftCardPreview: FC<Props> = ({
  imageCaption,
  imageSrc,
  isPublic,
  message,
  senderName,
  showMessage = true,
}) => {
  const note = message || (
    <>
      Sample Note.
      <br />
      This area will be for a sample note
    </>
  );

  return (
    <Container $isPublic={isPublic}>
      {showMessage && <Note>{note}</Note>}
      <SenderName>&#8212; {senderName || 'Entered Sender Name'}</SenderName>
      <Content>
        <ImageContainer>
          <Image alt="Credit card" src={imageSrc} />
          <CornerTopShadowImage
            alt=""
            aria-hidden
            src="https://assets.wakingup.com/web/images/gitcard-corner-shadow.png"
          />
          <CornerBottomShadowImage
            alt=""
            aria-hidden
            src="https://assets.wakingup.com/web/images/gitcard-corner-shadow.png"
          />
        </ImageContainer>
        <Caption>{imageCaption}</Caption>
      </Content>
    </Container>
  );
};

export default GiftCardPreview;
