import type { FC } from 'react';
import ExpiredPromotion from 'components/ExpiredPromotion';
import PublicPageLayout from 'components/PublicPageLayout';
import { Props } from './types';

const HolidayGiftCardPurchasePage: FC<Props> = () => (
  <PublicPageLayout>
    <ExpiredPromotion
      description="Click the link below to continue with your gift card purchase at the standard price."
      isPublic
      linkText="Purchase a Gift Card"
      linkTo="/purchase-gift-card"
    />
  </PublicPageLayout>
);

export default HolidayGiftCardPurchasePage;
