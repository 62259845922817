import { CardElementProps } from '@stripe/react-stripe-js';
import { GiftCardType } from 'graphql/generated';
import { object as yupObject, string as yupString, date as yupDate } from 'yup';

export const TITLE: Record<GiftCardType, string> = {
  [GiftCardType.LIFETIME]: 'Lifetime Membership',
  [GiftCardType.ONE_YEAR]: 'Yearly Membership',
  [GiftCardType.THREE_MONTHS]: 'Three-Month Membership',
  [GiftCardType.ONE_YEAR30]: '',
};

export const SEND_OPTIONS = [
  { label: 'Send Immediately', value: 'send_immediately' },
  { label: 'Send on Date', value: 'send_on_date' },
];

const commonCardStyles = {
  backgroundColor: 'transparent',
  fontFamily: '"Circular Std", sans-serif',
  fontSize: '16px',
  fontSmoothing: 'antialiased',
  '::placeholder': {
    color: '#8d8d8d',
    fontSize: '16px',
  },
};

export const CARD_ELEMENT_OPTIONS_LIGHT: NonNullable<
  CardElementProps['options']
> = {
  style: {
    base: {
      ...commonCardStyles,
      color: '#4a4a4a',
    },
  },
};

export const CARD_ELEMENT_OPTIONS_DARK: NonNullable<
  CardElementProps['options']
> = {
  style: {
    base: {
      ...commonCardStyles,
      color: '#ffffff',
    },
  },
};

export const validationSchema = yupObject({
  recipientEmail: yupString()
    .required('Please enter a valid email')
    .email('Please enter a valid email'),
  recipientName: yupString().required('Please enter a name'),
  senderEmail: yupString()
    .required('Please enter a valid email')
    .email('Please enter a valid email'),
  senderName: yupString().required('Please enter your name'),
  shouldBeSentAtDate: yupDate()
    .nullable()
    .when('sendOn', (sendOn, schema) => {
      if (sendOn === 'send_on_date') {
        return schema.required('Please enter a valid date');
      }
    })
    .typeError('Please enter a valid date'),
  shouldBeSentAtHour: yupDate()
    .nullable()
    .when('sendOn', (sendOn, schema) => {
      if (sendOn === 'send_on_date') {
        return schema.required('Please enter a valid hour');
      }
    })
    .typeError('Please enter a valid hour'),
  timezone: yupString()
    .nullable()
    .when('sendOn', (sendOn, schema) => {
      if (sendOn === 'send_on_date') {
        return schema.required('Please select a timezone');
      }
      return schema;
    })
    .typeError('Please enter a valid hour'),
});
