import type { FC } from 'react';
import { Formik } from 'formik';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import formatCents from 'lib/formatCents';
import GiftCardPreview from '../GiftCardPreview';
import SelectTimezone from './SelectTimezone';
import {
  Block,
  Button,
  CardElement,
  Container,
  DatePicker,
  DateTimePickerLabel,
  Form,
  FormErrors,
  Header,
  Label,
  Loader,
  NoteLabel,
  OtherPaymentButton,
  PaymentLabel,
  PaymentMethods,
  Price,
  Progress,
  PurchaseContainer,
  RadioGroup,
  Row,
  TextField,
  TimePicker,
  Title,
} from './styles';
import { SEND_OPTIONS, validationSchema } from './constants';
import type { Props } from './types';
import useConnect from './connect';

const CheckoutForm: FC<Props> = ({
  cardAmount,
  cardType,
  imageCaption,
  imageSrc,
  isLoading,
  isPublic,
  userEmail,
  userName,
}) => {
  const {
    elements,
    formikRef,
    handleSubmit,
    hasApplePay,
    initialValues,
    loading,
    paymentRequest,
    stripe,
    title,
  } = useConnect({
    cardAmount,
    cardType,
    imageCaption,
    imageSrc,
    userEmail,
    userName,
  });

  if (loading) {
    return (
      <Loader>
        <Progress />
      </Loader>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validationSchema={validationSchema}
    >
      {({ errors, isSubmitting, values }) => (
        <Form $isPublic={isPublic} noValidate>
          <GiftCardPreview
            imageCaption={imageCaption}
            imageSrc={imageSrc}
            isPublic={isPublic}
            message={values.message}
            senderName={values.senderName}
          />
          <Container>
            {isLoading ? (
              <Loader>
                <Progress />
              </Loader>
            ) : (
              <>
                <Header>{title}</Header>
                <Title>Gift to</Title>
                <Row>
                  <TextField
                    autoComplete="name"
                    errorId="purchase-gift-card-recipient-name-error"
                    id="purchase-gift-card-recipient-name-field"
                    label="Gift Recipient Name*"
                    maxLength={150}
                    name="recipientName"
                  />
                  <TextField
                    autoComplete="email"
                    errorId="purchase-gift-card-recipient-email-error"
                    id="purchase-gift-card-recipient-email-field"
                    label="Gift Recipient Email*"
                    maxLength={150}
                    name="recipientEmail"
                    type="email"
                  />
                </Row>
                <Title>Gift from</Title>
                <Row>
                  <TextField
                    autoComplete="name"
                    errorId="purchase-gift-card-sender-name-error"
                    id="purchase-gift-card-sender-name-field"
                    label="Your Name*"
                    maxLength={150}
                    name="senderName"
                  />
                  <TextField
                    autoComplete="email"
                    errorId="purchase-gift-card-sender-email-error"
                    id="purchase-gift-card-sender-email-field"
                    label="Your Email*"
                    maxLength={150}
                    name="senderEmail"
                    readOnly={!!userEmail}
                    type="email"
                  />
                </Row>
                <Row>
                  <RadioGroup
                    options={SEND_OPTIONS}
                    name="sendOn"
                    id="sendOn"
                  />
                </Row>
                {values.sendOn === 'send_on_date' && (
                  <>
                    <DateTimePickerLabel>Delivery date</DateTimePickerLabel>
                    <DatePicker name="shouldBeSentAtDate" />
                    <Row>
                      <Block>
                        <Label>Time of day</Label>
                        <TimePicker
                          name="shouldBeSentAtHour"
                          views={['hours']}
                        />
                      </Block>
                      <Block>
                        <Label>Time zone</Label>
                        <SelectTimezone
                          hideSeparator
                          iconName="globeHemisphereWestLight"
                          isSearchable
                          name="timezone"
                          placeholder="Select their zone"
                        />
                      </Block>
                    </Row>
                  </>
                )}
                <NoteLabel>Add an optional message.</NoteLabel>
                <TextField
                  maxLength={200}
                  multiline
                  name="message"
                  placeholder="Your optional gift message"
                />
                <PaymentLabel>Select Payment Method</PaymentLabel>
                {paymentRequest && (
                  <PaymentMethods>
                    {hasApplePay ? (
                      <PaymentRequestButtonElement
                        options={{
                          paymentRequest,
                          style: {
                            paymentRequestButton: {
                              height: '40px',
                              theme: 'dark',
                              type: 'default',
                            },
                          },
                        }}
                      />
                    ) : (
                      <OtherPaymentButton
                        onClick={paymentRequest.show}
                        type="button"
                      >
                        Select Credit Card
                      </OtherPaymentButton>
                    )}
                  </PaymentMethods>
                )}
                <CardElement />
                {errors.submitError && (
                  <FormErrors error center>
                    Error: {errors.submitError}
                  </FormErrors>
                )}
                <PurchaseContainer>
                  <Price>{formatCents(cardAmount).split('.')[0]}</Price>
                  <Button
                    disabled={!stripe || !elements}
                    id="purchase-gift-card-submit-button"
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    Purchase
                  </Button>
                </PurchaseContainer>
              </>
            )}
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default CheckoutForm;
