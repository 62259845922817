/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import colorWithAlpha from 'lib/color';

export const Container = styled.button<{
  $selected?: boolean;
}>`
  background-color: ${({ theme }) =>
    colorWithAlpha({
      color:
        theme.name === 'LIGHT'
          ? theme.colors.coreNeutralBlack
          : theme.colors.coreNeutralWhite,
      alpha: 0.25,
    })};
  border-radius: 50%;
  border: none;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  height: 0.75rem;
  line-height: 1;
  margin: 0;
  outline: none;
  padding: 0;
  transition: background-color 300ms linear;
  width: 0.75rem;

  &:focus {
    box-shadow: 0 0 0 2px #f4f4f4, 0 0 0 3.5px rgba(0, 96, 103, 0.25);
    outline: -webkit-focus-ring-color auto 1px;
  }

  ${({ $selected }) =>
    $selected &&
    css`
      background-color: ${({ theme }) =>
        theme.name === 'LIGHT'
          ? theme.colors.coreNeutralAbyss
          : theme.colors.coreNeutralAurora};
    `}
`;
