import flatMap from 'lodash/flatMap';
import { Course } from 'models/Course';
import getPacks from './getPacks';

const getPackCourses = async () => {
  const { packs } = await getPacks();

  const allPackCourses = flatMap(packs, ({ courses }) => courses);

  return {
    packCourses: allPackCourses,
    packCoursesWithPrimaryPackObj: allPackCourses.reduce<
      Record<string, Course>
    >((t, v) => {
      if (v.packIsPrimaryPack) t[v.hash] = v;
      return t;
    }, {}),
  };
};

export default getPackCourses;
