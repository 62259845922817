import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { kebabCase } from 'lodash';
import { AppConfig } from 'config';
import getCourseProgressPercent from 'lib/getCourseProgressPercent';
import { CourseStatus, CourseType } from 'models/Course';
import { PACK_TYPE_NAME } from 'models/Pack';
import { BookmarkType } from 'graphql/generated';
import {
  coursePlayerVar,
  resetOptionsMenuVar,
  setDetails,
  updateCoursePlayerVar,
} from 'graphql/reactive';
import useCourse from 'graphql/hooks/useCourse';
import useSettings from 'graphql/hooks/useSettings';
import useBookmarksActions from 'graphql/hooks/useBookmarksActions';
import useResetActions from 'graphql/hooks/useResetActions';
import useModalActions from 'graphql/hooks/useModalActions';
import type { ConnectProps } from './types';

const useConnect = ({ courseId, packId, type }: ConnectProps) => {
  const { course } = useCourse(courseId, packId);
  const {
    settings: { recentlyPlayed },
  } = useSettings();
  const { toggleBookmark } = useBookmarksActions();
  const { resetCourse } = useResetActions();
  const { openShareLinkModal } = useModalActions();
  const { pathname } = useLocation();

  const {
    audioLength = 0,
    courseType,
    ephemeral: isEphemeral = false,
    hash = '',
    info,
    isConversation,
    isBookmark = false,
    isIntroCourse,
    packPersons,
    packSummary,
    packTitle,
    packType,
    persons,
    primaryPackId,
    shareable: isShareable = false,
    status = CourseStatus.UNSTARTED,
    summary,
  } = course || {};

  const lwPackTypeName = packType
    ? PACK_TYPE_NAME[packType].toLowerCase()
    : undefined;
  const progress = course
    ? getCourseProgressPercent({ hash, audioLength, recentlyPlayed, status })
    : 0;
  const isUnstarted = status === CourseStatus.UNSTARTED && progress === 0;
  const isMeditation = courseType === CourseType.MEDITATION;
  const isStandardIntroCourse = isIntroCourse && packId === primaryPackId;
  const isTheoryIntroCourse = isIntroCourse && packId !== primaryPackId;
  const hasDetails =
    !!summary ||
    !!packSummary ||
    (persons && persons.length > 0) ||
    (packPersons && packPersons.length > 0);

  const packUrlRegex = new RegExp(
    `/${lwPackTypeName}.*/${kebabCase(packTitle)}${isConversation ? '$' : ''}`,
  );
  const isPackPage = packUrlRegex.test(pathname);
  const isIntroCoursePage = pathname.includes('/practice/introductory-course');
  const isInCourseSeriesPage =
    isPackPage || (isIntroCoursePage && isStandardIntroCourse);

  let packUrl;
  if (!isInCourseSeriesPage) {
    if (isConversation && lwPackTypeName) {
      packUrl = `/${lwPackTypeName}/conversations`;
    } else if (isStandardIntroCourse) {
      packUrl = '/practice/introductory-course';
    } else if (isTheoryIntroCourse) {
      packUrl = `/pack/${primaryPackId}`;
    } else {
      packUrl = `/pack/${packId}`;
    }
  }

  const conversationUrlRegex = new RegExp(
    `/${lwPackTypeName}/conversations/${hash}`,
  );
  const isConversationPage = conversationUrlRegex.test(pathname);
  const conversationUrl =
    isConversation && !isConversationPage && lwPackTypeName
      ? `/${lwPackTypeName}/conversations/${hash}`
      : undefined;

  const handleToggleBookmark = useCallback(() => {
    if (hash) {
      toggleBookmark(hash, BookmarkType.course);
      if (pathname.includes('my-library')) {
        resetOptionsMenuVar();
      }
    }
  }, [hash, pathname, toggleBookmark]);

  const handleMarkAsUnplayed = useCallback(async () => {
    if (!isUnstarted && courseId) {
      await resetCourse(courseId);
      resetOptionsMenuVar();
    }
  }, [courseId, isUnstarted, resetCourse]);

  const handleShare = useCallback(() => {
    if (isShareable && courseId) {
      openShareLinkModal({
        id: courseId,
        title: 'Share this course',
        description:
          'Share this link with anyone you feel would benefit from this Waking Up course',
        link: `${AppConfig.dynamicBaseUrl}/course/${courseId}`,
      });
    }
  }, [courseId, isShareable, openShareLinkModal]);

  const handleShowDetails = useCallback(() => {
    if (hasDetails) {
      resetOptionsMenuVar();

      if (isConversation) {
        updateCoursePlayerVar({ isMinimized: true });
      } else {
        setDetails({ courseId, packId, type: 'course' });
      }
    }
  }, [courseId, hasDetails, isConversation, packId]);

  const handleViewInSeries = useCallback(() => {
    resetOptionsMenuVar();

    if (type === 'coursePlayer') {
      if (isInCourseSeriesPage) {
        updateCoursePlayerVar({ isMinimized: !coursePlayerVar().isMinimized });
      } else {
        setTimeout(() => {
          updateCoursePlayerVar({
            isMinimized: !coursePlayerVar().isMinimized,
          });
        }, 200);
      }
    }
  }, [isInCourseSeriesPage, type]);

  return {
    conversationUrl,
    handleMarkAsUnplayed,
    handleShare,
    handleShowDetails,
    handleToggleBookmark,
    handleViewInSeries,
    hasDetails,
    info,
    isBookmark,
    isConversation,
    isEphemeral,
    isIntroCourse,
    isMeditation,
    isShareable,
    isTheoryIntroCourse,
    isUnstarted,
    packUrl,
    pathname,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
