import styled from 'styled-components';
import { media } from 'styles/mixins';
import background from 'assets/images/unlock-free-month-bkg.png';
import DefaultModal from 'components/Modal';
import DefaultChevronLeft from 'components/Icons/ChevronLeft';
import DefaultIconButton from 'components/IconButton';
import DefaultButton from 'components/Button';

export const Modal = styled(DefaultModal)`
  &&& {
    .MuiPaper-root {
      padding: 0;
      width: 23rem;
      text-align: center;
    }

    ${media.phoneLandscape} {
      .MuiPaper-root {
        width: 100%;
      }
    }
  }
`;

export const ChevronLeft = styled(DefaultChevronLeft).attrs(() => ({
  size: 16,
}))``;

export const BackButton = styled(DefaultIconButton)`
  left: 1rem;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 1.2rem;
`;

export const Title = styled.div`
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  padding: 0.75rem 0;
`;

export const Content = styled.div`
  align-items: center;
  background-image: url('${background}');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  color: ${({ theme }) => theme.colors.coreNeutralWhite};
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  height: 40rem;
  max-height: 95%;
  position: relative;
  text-align: center;
`;

export const BigText = styled.div`
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 1.3;
  margin-bottom: 1.6rem;
  margin-top: 3rem;
  width: 18rem;
`;

export const Text = styled.div`
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 1.4;
  margin-bottom: 1.2rem;
  width: 15rem;
`;

export const Result = styled.div`
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 1.3;
  margin-bottom: 0.4rem;
  margin-top: 2rem;
`;

export const Button = styled(DefaultButton)`
  &&& {
    min-height: 0;
    padding: 0.6rem 4rem;
    width: auto;

    .MuiButton-label {
      text-decoration: none;
      text-transform: none;
      font-weight: normal;
    }
  }
`;

export const Footer = styled.div`
  bottom: 3rem;
  font-size: 0.9rem;
  position: absolute;
`;
