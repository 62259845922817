import styled from 'styled-components';
import { Form } from 'formik';
import { motion } from 'framer-motion';
import DefaultButton from 'components/Button';
import DefaultTextField from 'components/Inputs/TextField';

export const FormikForm = styled(Form)<{ $centerContent: boolean }>`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  justify-content: ${({ $centerContent }) =>
    $centerContent ? 'center' : 'space-between'};
`;

export const Content = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  position: relative;
`;

const ClearButton = styled.button`
  background: transparent;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: block;
  line-height: 1;
  outline: none;
  width: fit-content;
`;

export const IconButton = styled(ClearButton)`
  left: 0;
  position: absolute;
  top: 0;
`;

export const Title = styled.h1`
  color: inherit;
  font-weight: inherit;
  font-size: 1.5rem;
  line-height: 1.5;
  margin: 0 auto;
  text-align: center;
  letter-spacing: 0.01em;
`;

export const Subtitle = styled.h2`
  color: inherit;
  font-size: 1rem;
  font-weight: inherit;
  letter-spacing: 0.01em;
  line-height: 1.5;
  margin-bottom: 0.75rem;
  margin-top: 4.6875rem;
  text-align: center;
`;

export const Description = styled.p`
  color: inherit;
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: 0.01em;
  line-height: 1.4286;
  text-align: center;
`;

export const TextField = styled(DefaultTextField)`
  margin-top: 3rem;
`;

export const ButtonContainer = styled.div`
  margin-top: 2.625rem;
`;

export const Button = styled(DefaultButton)``;

export const Error = styled(motion.div).attrs(() => ({
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  transition: { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] },
}))`
  color: ${({ theme }) => theme.colors.fontLabelError};
  font-size: 0.8rem;
  letter-spacing: 0.01rem;
  line-height: 1.33;
  margin-top: ${({ theme }) => theme.measures.xs};
  text-align: center;
`;
