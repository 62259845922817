import { memo, SVGAttributes } from 'react';
import type { FC } from 'react';

const CirclePauseIcon: FC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    fill="none"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64 32c0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32 0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32zM28.577 20h-4.154c-.51 0-.923.488-.923 1.09v21.82c0 .602.413 1.09.923 1.09h4.154c.51 0 .923-.488.923-1.09V21.09c0-.602-.413-1.09-.923-1.09zm7.846 0h4.154c.51 0 .923.488.923 1.09v21.82c0 .602-.413 1.09-.923 1.09h-4.154c-.51 0-.923-.488-.923-1.09V21.09c0-.602.413-1.09.923-1.09z"
      fill="currentColor"
    />
  </svg>
);

export default memo(CirclePauseIcon);
