export enum Step {
  email,
  pin,
}

export type FormFields = {
  email: string;
  pin: string[];
  step: Step;
  submitError?: string;
};

export type Props = {
  initialEmail: string;
  initialStep: Step;
};

export type ConnectProps = {
  initialEmail: Props['initialEmail'];
  initialStep: Props['initialStep'];
};
