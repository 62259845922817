import { useCallback, useMemo, useState } from 'react';
import flatMap from 'lodash/flatMap';
import useMediaQuery from 'lib/useMediaQuery';
import { media } from 'styles/mixins';
import { getNextIntroCourse } from 'models/Course';
import type { Pack } from 'models/Pack';
import useMe from 'graphql/hooks/useMe';
import usePacks from 'graphql/hooks/usePacks';
import useModalActions from 'graphql/hooks/useModalActions';

const useConnect = ({ chapters }: { chapters: Pack[] }) => {
  const { introPack } = usePacks();
  const { isDailyUnlocked, isSubscribed } = useMe();
  const isPhoneLandscape = useMediaQuery(media.phoneLandscape);
  const { openIntroCourseSectionCompletedModal } = useModalActions();

  const { nextIntroCourse, defaultExpandedChapters, nextIntroCourseOverline } =
    useMemo(() => {
      const flatCourses = flatMap(chapters, (c) => c.courses);

      const next = getNextIntroCourse(flatCourses);

      const parentChapterIndex = chapters.findIndex((ch) =>
        ch.courses.some(({ id }) => next.id === id),
      );

      return {
        defaultExpandedChapters:
          parentChapterIndex >= 0 ? [parentChapterIndex + 1] : [],
        nextIntroCourse: next,
        nextIntroCourseOverline: chapters[parentChapterIndex].title,
      };
    }, [chapters]);

  const [expandedChapters, setExpandedChapters] = useState<number[]>(
    isSubscribed ? defaultExpandedChapters : [],
  );

  const handleExpandChapter = useCallback((number: number) => {
    setExpandedChapters((v) =>
      v.includes(number) ? v.filter((n) => n !== number) : [number, ...v],
    );
  }, []);

  const handleOpenSectionCompleted = useCallback(
    (packId: string) => openIntroCourseSectionCompletedModal({ packId }),
    [openIntroCourseSectionCompletedModal],
  );

  return {
    expandedChapters,
    handleExpandChapter,
    handleOpenSectionCompleted,
    introPack,
    isDailyUnlocked,
    isPhoneLandscape,
    isSubscribed,
    nextIntroCourseCode: nextIntroCourse.hash || nextIntroCourse.id,
    nextIntroCourseDuration: nextIntroCourse?.audioLength,
    nextIntroCourseImage:
      nextIntroCourse?.headImageUrl || nextIntroCourse?.imageUrl,
    nextIntroCourseOverline,
    nextIntroCoursePackId: nextIntroCourse?.packId,
    nextIntroCourseTitle: nextIntroCourse?.title,
    nextIntroCourseType: nextIntroCourse?.courseType,
  };
};

export default useConnect;
