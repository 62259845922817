import styled from 'styled-components';
import DefaultIcon from 'components/Icon';
import { Html } from '../styles';

export const Container = styled.div``;

export const Teacher = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderBase};
    margin-bottom: 1.4rem;
    padding-bottom: 0.4rem;
  }
`;

export const Name = styled.div`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: 0.01em;
`;

export const Bio = styled(Html)``;

const ClearButton = styled.button`
  background: transparent;
  border: none;
  box-sizing: content-box;
  color: inherit;
  cursor: pointer;
  display: block;
  line-height: 1;
  outline: none;

  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

export const PersonPage = styled(ClearButton)`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.fontBase};
  display: flex;
  margin-bottom: 2rem;
  margin-top: 1rem;
  padding-bottom: 0.1rem;
  padding-top: 0.1rem;
`;

export const Icon = styled(DefaultIcon).attrs(() => ({ size: 16 }))`
  margin-left: 0.4rem;
`;
