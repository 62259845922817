import { memo, useCallback } from 'react';
import type { FC } from 'react';
import { Formik } from 'formik';
import formatRetreatDate from 'lib/formatRetreatDate';
import { trackEvent } from 'services/analytics';
import useRetreat from 'graphql/hooks/useRetreat';
import Button from 'components/Button';
import Close from './Close';
import Calendar from './Calendar';
import {
  ButtonsContainer,
  Cancel,
  Form,
  LinkButton,
  RemindersStep,
  RetreatCard,
} from './styles';
import { Props, Step } from './types';

const RetreatsForm: FC<Props> = ({
  descriptionId,
  initialValues,
  onClose,
  onSubmit,
  titleId,
}) => {
  const { retreat } = useRetreat();

  const {
    addToCalendarUrl,
    hash,
    imageUrl,
    endDate,
    startDate,
    subtitle,
    title,
  } = retreat!;

  const formattedDate = formatRetreatDate(startDate, endDate);

  const handleAddToCalendar = useCallback(() => {
    trackEvent('Event Add to Calendar', {
      event_hash: hash,
      event_type: 'retreat',
      name: title,
      response: 'Add to Your Calendar',
      source: 'event_calendar_prompt',
    });
  }, [hash, title]);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, values: { step } }) => (
        <Form>
          {step === Step.reminders && (
            <>
              <Close />
              <RemindersStep descriptionId={descriptionId} titleId={titleId} />
              <ButtonsContainer>
                <Button id="next" isLoading={isSubmitting} type="submit">
                  Confirm
                </Button>
              </ButtonsContainer>
            </>
          )}
          {step === Step.calendar && (
            <>
              <Calendar
                descriptionId={descriptionId}
                formattedDate={formattedDate}
                subtitle={subtitle}
                title={title}
                titleId={titleId}
              />
              <ButtonsContainer>
                <LinkButton
                  href={addToCalendarUrl}
                  id="download-calendar"
                  onClick={handleAddToCalendar}
                >
                  Add to your calendar
                </LinkButton>
                <Cancel type="submit">Maybe later</Cancel>
              </ButtonsContainer>
            </>
          )}
          {step === Step.share && (
            <>
              <RetreatCard
                descriptionId={descriptionId}
                imageUrl={imageUrl}
                formattedDate={formattedDate}
                subtitle={subtitle}
                title={title}
                titleId={titleId}
              />
              <ButtonsContainer>
                <Button id="next" isLoading={isSubmitting} type="submit">
                  Share with a friend
                </Button>
                <Cancel onClick={onClose} type="button">
                  No thanks
                </Cancel>
              </ButtonsContainer>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default memo(RetreatsForm);
