import {
  type KeyboardEvent,
  type MouseEvent,
  useCallback,
  useMemo,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import kebabCase from 'lodash/kebabCase';
import getCourseProgressPercent from 'lib/getCourseProgressPercent';
import { AdditionalMeditationsFilter, Pack } from 'models/Pack';
import type { Course } from 'models/Course';
import { PlayerAutoplayType, PlayerSource } from 'models/Player';
import { PackType } from 'graphql/generated';
import { setOptionsMenuVar, practiceVar } from 'graphql/reactive';
import useMe from 'graphql/hooks/useMe';
import useSettings from 'graphql/hooks/useSettings';
import usePacks from 'graphql/hooks/usePacks';
import useDaily from 'graphql/hooks/useDaily';
import useRetreat from 'graphql/hooks/useRetreat';
import useCourseActions from 'graphql/hooks/useCourseActions';
import useDailyActions from 'graphql/hooks/useDailyActions';
import useModalActions from 'graphql/hooks/useModalActions';
import useRefreshSettingsEffect from 'graphql/hooks/useRefreshSettingsEffect';
import useRemoteConfig from 'graphql/hooks/useRemoteConfig';

const useConnect = () => {
  const { isDailyUnlocked } = useMe();
  const {
    loading: isLoadingSettings,
    settings: { recentlyPlayed },
  } = useSettings();
  const { packs, loading: isLoadingPacks } = usePacks();
  const { dailyPack, loading: isLoadingDaily } = useDaily();
  const { retreat, loading: isLoadingRetreat } = useRetreat();
  const { remoteConfig } = useRemoteConfig();
  const { play } = useCourseActions();
  const { playDaily } = useDailyActions();
  const { closeModal, openDialogModal } = useModalActions();
  const { additionalMeditations: additionalMeditationsFilter } =
    useReactiveVar(practiceVar);
  const { pathname } = useLocation();
  const { subpack, subpack2, pack } = useParams();

  const packParam = pack;
  const subpackParam = subpack;
  const subpack2Param = subpack2;
  const titleDescription = remoteConfig?.sectionPracticeDescription;

  useRefreshSettingsEffect();

  const showRetreatsList =
    !!retreat && packParam === 'retreats' && !subpackParam;
  const showRetreat =
    !!retreat &&
    packParam === 'retreats' &&
    !!subpackParam &&
    subpackParam === retreat.slug &&
    !subpack2Param;
  const showRetreatPack =
    !!retreat &&
    packParam === 'retreats' &&
    !!subpackParam &&
    subpackParam === retreat.slug &&
    !!subpack2Param &&
    subpack2Param === retreat.pack?.slug;

  const {
    backUrl,
    filteredPacks,
    selectedPackCategory,
    selectedPackCourses,
    selectedPackDaily,
    selectedPackDescription,
    selectedPackDisplayCourseImages,
    selectedPackId,
    selectedPackImageUrl,
    selectedPackIsLongerMeditations,
    selectedPackSubpacks,
    selectedPackSubtitle,
    selectedPackTitle,
  } = useMemo(() => {
    const practicePacksData = packs.filter(
      ({ type }) => type === PackType.meditation,
    );

    let practicePacks = [] as Pack[];
    if (!showRetreatsList) {
      if (
        !isDailyUnlocked &&
        dailyPack?.dailies &&
        dailyPack.dailies.length > 0
      ) {
        practicePacks = [...practicePacksData, dailyPack];
      } else {
        practicePacks = practicePacksData;
      }
    }

    const selectedPack = practicePacks.find(({ title }) =>
      subpackParam
        ? kebabCase(title) === subpackParam
        : kebabCase(title) === packParam,
    );

    const filteredPacksList = subpackParam
      ? practicePacks.filter(
          (p) => p.parentPackId === selectedPack?.parentPackId,
        )
      : practicePacks.filter((p) => !p.parentPackId);

    const getCoursesWithProgress = (courses: Course[]) =>
      courses.map((c) => ({
        ...c,
        progress: getCourseProgressPercent({
          hash: c.hash,
          audioLength: c.audioLength,
          recentlyPlayed,
          status: c.status,
        }),
      }));

    const subpacks = selectedPack?.nSubpacks
      ? practicePacks
          .filter(
            ({ parentPackId }) =>
              parentPackId && parentPackId === selectedPack.id,
          )
          .map((sp) => ({
            ...sp,
            courses: getCoursesWithProgress(sp.courses) || [],
          }))
      : [];

    const filteredCourses =
      (selectedPack?.isLongerMeditations &&
      additionalMeditationsFilter &&
      additionalMeditationsFilter !== AdditionalMeditationsFilter.SHOW_ALL
        ? selectedPack?.courses.filter(({ title }) => {
            return title.includes(additionalMeditationsFilter);
          })
        : selectedPack?.courses) || [];

    const coursesWithProgress = getCoursesWithProgress(filteredCourses);

    return {
      backUrl: subpackParam
        ? `${selectedPack?.urlBase}/${packParam}`
        : undefined,
      filteredPacks: filteredPacksList,
      selectedPackCategory: selectedPack?.category,
      selectedPackCourses: coursesWithProgress,
      selectedPackDaily: selectedPack?.dailies[0],
      selectedPackDescription: selectedPack?.description,
      selectedPackDisplayCourseImages: selectedPack?.displayCourseImages,
      selectedPackId: selectedPack?.id,
      selectedPackImageUrl: selectedPack?.imageUrl,
      selectedPackIsLongerMeditations: selectedPack?.isLongerMeditations,
      selectedPackSubpacks: subpacks,
      selectedPackSubtitle: selectedPack?.subtitle,
      selectedPackTitle: selectedPack?.title,
    };
  }, [
    additionalMeditationsFilter,
    dailyPack,
    isDailyUnlocked,
    packParam,
    packs,
    recentlyPlayed,
    showRetreatsList,
    subpackParam,
  ]);

  const handlePlayCourse = useCallback(
    async (courseId: string, packId: string) => {
      await play({ courseId, autoplayType: PlayerAutoplayType.PACK, packId });
    },
    [play],
  );

  const handlePlayIntroCourse = useCallback(
    async (courseId: string, packId: string) => {
      await play({ courseId, packId, source: PlayerSource.INTRO_COURSE });
    },
    [play],
  );

  const handlePlayIntroDaily = useCallback(async () => {
    await playDaily({ source: PlayerSource.INTRO_COURSE });
  }, [playDaily]);

  const handlePlayDaily = useCallback(() => {
    openDialogModal({
      cancelButtonText: 'Ok',
      description:
        'Daily Meditation will be available when you complete the Introductory Course.',
      onCancel: closeModal,
    });
  }, [closeModal, openDialogModal]);

  const handleChangeAdditionalMeditationsFilter = useCallback(
    (filter: AdditionalMeditationsFilter) => {
      practiceVar({ ...practiceVar(), additionalMeditations: filter });
    },
    [],
  );

  const handleOpenPackOptionsMenu = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (selectedPackId) {
        event.stopPropagation();
        setOptionsMenuVar({
          anchorEl: event.currentTarget,
          id: selectedPackId,
          type: 'pack',
        });
      }
    },
    [selectedPackId],
  );

  const handleOpenCourseOptionsMenu = useCallback(
    (
      event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLButtonElement>,
      courseId: string,
      packId: string,
    ) => {
      event.stopPropagation();
      setOptionsMenuVar({
        anchorEl: event.currentTarget,
        id: courseId,
        packId,
        type: 'course',
      });
    },
    [],
  );

  const handleOpenRetreatOptionsMenu = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (showRetreat) {
        event.stopPropagation();
        setOptionsMenuVar({ anchorEl: event.currentTarget, type: 'retreat' });
      }
    },
    [showRetreat],
  );

  return {
    additionalMeditationsFilter,
    backUrl,
    currentUrl: pathname,
    handleChangeAdditionalMeditationsFilter,
    handleOpenCourseOptionsMenu,
    handleOpenPackOptionsMenu,
    handleOpenRetreatOptionsMenu,
    handlePlayDaily,
    handlePlayCourse,
    handlePlayIntroCourse,
    handlePlayIntroDaily,
    isLoading:
      isLoadingSettings || isLoadingPacks || isLoadingDaily || isLoadingRetreat,
    packs: filteredPacks,
    retreat,
    selectedPackCategory,
    selectedPackCourses,
    selectedPackDaily,
    selectedPackDescription,
    selectedPackDisplayCourseImages,
    selectedPackId,
    selectedPackImageUrl,
    selectedPackIsLongerMeditations,
    selectedPackSubpacks,
    selectedPackSubtitle,
    selectedPackTitle,
    showBackLink: !showRetreatsList && (!!packParam || !!subpackParam),
    showRetreat,
    showRetreatPack,
    showRetreatsList,
    titleDescription,
  };
};

export default useConnect;
export type UseConnectReturnType = ReturnType<typeof useConnect>;
