import type { FC } from 'react';
import {
  Container,
  Image,
  ImageContent,
  Subtitle,
  TextContent,
  Title,
} from './styles';
import { Props } from '../types';

const TrackInfoMaximized: FC<Props> = ({
  className,
  imgBackgroundColor = 'transparent',
  imgObjectFit = 'cover',
  imgObjectPosition = '50% 50%',
  imgShadow = 'none',
  imgUrl,
  title,
  subtitle,
}) => (
  <Container className={className}>
    <ImageContent $backgroundColor={imgBackgroundColor} $shadow={imgShadow}>
      <Image
        $objectFit={imgObjectFit}
        $objectPosition={imgObjectPosition}
        $shadow={imgShadow}
        alt=""
        src={imgUrl}
      />
    </ImageContent>
    <TextContent>
      {title && <Title as="h1">{title}</Title>}
      {subtitle && <Subtitle as="h2">{subtitle}</Subtitle>}
    </TextContent>
  </Container>
);

export default TrackInfoMaximized;
