import { useCallback, memo } from 'react';
import type { FC } from 'react';
import { DailyPermission } from 'models/Daily';
import CardListItem from 'components/CardListItem';
import type { Props } from './types';

const Daily: FC<Props> = ({ duration, onClick, permission }) => {
  const handleClick = useCallback(() => {
    onClick();
  }, [onClick]);

  const isForbidden = permission === DailyPermission.FORBIDDEN;
  const isLocked = permission === DailyPermission.LOCKED;

  return (
    <CardListItem
      isForbidden={isForbidden || isLocked}
      onClick={isForbidden ? undefined : handleClick}
      progress={0}
      title="Daily Meditation"
      to={isForbidden ? '/account/Subscription' : undefined}
      total={duration}
      type="course"
    />
  );
};

export default memo(Daily);
