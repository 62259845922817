import { useQuery, type QueryHookOptions } from '@apollo/client';
import {
  AboutsDocument,
  type AboutsQuery,
  type AboutsQueryVariables,
} from '../generated';

const useAbouts = (
  options: QueryHookOptions<AboutsQuery, AboutsQueryVariables> = {},
) => {
  const { data, ...rest } = useQuery(AboutsDocument, {
    errorPolicy: 'all',
    ...options,
  });

  const aboutsData = data?.abouts;
  const abouts = {
    privacy: aboutsData?.find((el) => el?.id === '3')?.body || '',
    terms: aboutsData?.find((el) => el?.id === '2')?.body || '',
    aboutSam: aboutsData?.find((el) => el?.id === '1')?.body || '',
    aboutCourse: aboutsData?.find((el) => el?.id === '5')?.body || '',
    faqDesktop: aboutsData?.find((el) => el?.id === '6')?.body || '',
    faqMobile: aboutsData?.find((el) => el?.id === '7')?.body || '',
  };

  return { abouts, ...rest };
};

export default useAbouts;
export type UseAbouts = ReturnType<typeof useAbouts>;
