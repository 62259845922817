import { memo } from 'react';
import type { FC } from 'react';
import Icon from 'components/Icon';
import { A11y, Keyboard, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Slide from './Slide';
import Countdown from './Countdown';
import { Content, IconButton, Modal, Slider } from './styles';
import { BREAKPOINTS, SLIDES } from './constants';
import useConnect from './connect';

import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/pagination';

const JoinRetreat: FC = () => {
  const {
    currentSlide,
    handleBack,
    handleClose,
    handleInit,
    handleNext,
    handleSlideChange,
    isOpen,
    isUpdatingUserRetreat,
    retreatTitle,
    startDate,
    swiperRef,
  } = useConnect();

  return (
    <Modal
      fullscreenOnMobile
      hideClose
      onClose={handleClose}
      open={isOpen}
      titleId="join-retreat-title"
    >
      <IconButton aria-label="Go back" onClick={handleBack}>
        <Icon aria-hidden="true" name="arrowLeftLight" />
      </IconButton>
      <Content>
        <Slider>
          <Swiper
            allowTouchMove
            breakpoints={BREAKPOINTS}
            centeredSlides
            keyboard={{
              enabled: true,
            }}
            modules={[A11y, Keyboard, Pagination]}
            onInit={handleInit}
            onSlideChange={handleSlideChange}
            pagination={{ clickable: true }}
            // @ts-ignore
            ref={swiperRef}
            slidesPerView={1.15}
            spaceBetween={16}
          >
            {SLIDES.map(({ title, description }) => (
              <SwiperSlide key={title}>
                <Slide description={description} title={title} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Slider>
        <Countdown
          currentSlide={currentSlide}
          isLoading={isUpdatingUserRetreat}
          onContinue={handleNext}
          startDate={startDate!}
          title={retreatTitle}
          titleId="join-retreat-title"
        />
      </Content>
    </Modal>
  );
};

export default memo(JoinRetreat);
