import { memo } from 'react';
import type { FC } from 'react';
import useAbouts from 'graphql/hooks/useAbouts';
import Page from '../Page';
import { Faq } from './styles';

const FrequentlyAskedQuestions: FC = () => {
  const {
    abouts: { faqDesktop },
  } = useAbouts();

  return (
    <Page title="FAQ">
      <Faq data={faqDesktop} />
    </Page>
  );
};

export default memo(FrequentlyAskedQuestions);
