/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_GIFT_CARD_PRICES = gql`
  query GetGiftCardPrices($code: String) {
    giftCardPrices(code: $code) {
      amount
      amount_decimal
      duration
      type
    }
  }
`;
