import { gql } from '@apollo/client';
import { DAILY_DATA_FRAGMENT } from '../fragments';

export const GET_DAILY = gql`
  query GetDaily {
    daily {
      ...DailyData
    }
  }
  ${DAILY_DATA_FRAGMENT}
`;

export const GET_SHARED_DAILY = gql`
  query GetSharedDaily($shared_hash: String!) {
    sharedDaily(shared_hash: $shared_hash) {
      ...DailyData
    }
  }
  ${DAILY_DATA_FRAGMENT}
`;
