export enum AudioStatus {
  PLAYING,
  PAUSED,
  FINISHED,
}

export type Props = {
  className?: string;
  onVideoEnd?: () => void;
};

export type LogicProps = {
  onVideoEnd: Props['onVideoEnd'];
};
