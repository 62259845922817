import type { FC } from 'react';
import { Props } from './types';

const ArrowCircleDownLight: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.818 12.568 12 15.75l3.182-3.182M12 8.25v7.5"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowCircleDownLight;
