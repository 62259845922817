import styled from 'styled-components';
import { Body as DefaultBody } from 'components/Typography';

export const Container = styled.div``;

export const Body = styled(DefaultBody).attrs(() => ({ $weight: 'bold' }))`
  font-size: 1rem;
  padding: 1.2rem 0 1rem 0;
`;

export const TagsContainer = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const TagItem = styled.li`
  list-style-type: none;
`;
