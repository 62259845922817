import { FC, memo } from 'react';
import { Container, Progress } from './styles';
import type { Props } from './types';

const Loader: FC<Props> = ({ className }) => (
  <Container className={className}>
    <Progress />
  </Container>
);

export default memo(Loader);
