import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultLink from 'components/Link';
import DefaultIcon from 'components/Icon';
import DefaultImage from 'components/Image';
import { Body, Heading } from 'components/Typography';

export const Image = styled(DefaultImage)`
  transition: filter 200ms ease;
  width: 100%;
`;

export const Container = styled(DefaultLink)`
  background: ${({ theme }) => theme.colors.backgroundCard};
  border-radius: ${({ theme }) => theme.radius.md};
  color: ${({ theme }) => theme.colors.fontBase};
  cursor: pointer;
  display: block;
  flex: 1 1 50%;
  margin-left: 0.875rem;
  overflow: hidden;
  padding: 2.5rem;
  text-decoration: none;
  width: auto;
  box-sizing: border-box;

  :hover {
    ${Image} {
      filter: brightness(90%);
    }
  }

  ${media.phoneLandscape} {
    background: transparent;
    padding: 0;
    margin-bottom: ${({ theme }) => theme.measures.l};
    margin-left: auto;
    margin-right: auto;
    margin-top: ${({ theme }) => theme.measures.l};
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  border-radius: 0.5rem;
  margin-bottom: ${({ theme }) => theme.measures.m};
  position: relative;
`;

export const Duration = styled.div`
  align-items: center;
  backdrop-filter: blur(8.73134px);
  background-color: rgba(130, 130, 130, 0.6);
  border-radius: 0.1rem;
  bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.coreNeutralWhite};
  display: inline-flex;
  font-size: 0.65rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  left: -0.25rem;
  letter-spacing: 0.06em;
  line-height: 1.1;
  min-height: 1.5rem;
  padding: 0.2rem 0.45rem 0.2rem 0.4rem;
  position: absolute;
  transform: translateZ(0);

  span {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin-top: 0.03rem;
  }
`;

export const DurationIcon = styled(DefaultIcon)`
  display: block;
  flex: 0 0 auto;
  height: 0.9rem;
  margin-right: 0.2rem;
  width: auto;
`;

export const Overline = styled.p`
  align-items: center;
  color: ${({ theme }) => theme.colors.fontDeemphasized};
  display: flex;
  flex-flow: row nowrap;
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: 0.04em;
  line-height: 1.3;
  margin-bottom: 0.175rem;
  width: 100%;

  span {
    font-size: inherit;
    line-height: inherit;
  }

  ${media.phoneLandscape} {
    font-size: 0.75rem;
  }
`;

export const Icon = styled(DefaultIcon).attrs(() => ({ size: 14 }))`
  display: block;
  flex: 0 0 auto;
  margin-bottom: 0.1rem;
  margin-right: 0.3125rem;
`;

export const Separator = styled.span`
  flex: 0 0 auto;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-left: 0.2rem;
  margin-right: 0.2rem;
`;

export const Caption = styled.span`
  color: ${({ theme }) => theme.colors.fontEmphasized};
  flex: 1 1 0;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Title = styled(Heading).attrs(() => ({
  $weight: 'bold',
}))`
  box-orient: vertical;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 1rem;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  line-height: 1.33;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.phoneLandscape} {
    font-size: 0.875rem;
  }
`;

export const Subtitle = styled(Body).attrs(() => ({
  $size: 'small',
}))`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  margin-top: 0.1125rem;
  opacity: 0.6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${media.phoneLandscape} {
    font-size: 0.75rem;
  }
`;
