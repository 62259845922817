import styled from 'styled-components';
import colorWithAlpha from 'lib/color';
import { media } from 'styles/mixins';

export const Container = styled.div`
  border-top: 0.5px solid
    ${({ theme }) =>
      colorWithAlpha({ color: theme.colors.coreNeutralAbyss, alpha: 0.35 })};
  font-size: 1.1rem;
  line-height: 1.2;
  margin-bottom: 2rem;
  margin-top: 1rem;
  padding-top: 2.75rem;

  ${media.phoneLandscape} {
    border-top: none;
    font-size: 0.95rem;
    margin-top: 1rem;
    padding-top: 0;
  }
`;

export const Title = styled.h2`
  font-size: 1.3125rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  line-height: 1.2;
  margin-bottom: 0.8rem;

  ${media.phonePortrait} {
    font-size: 1.2rem;
  }
`;

export const Subtitle = styled.p`
  font-size: inherit;
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: -0.03em;
  margin-bottom: 1.75rem;
  max-width: 36rem;

  ${media.phonePortrait} {
    margin-bottom: 2rem;
  }
`;

export const Features = styled.ul`
  font-size: inherit;
  list-style-type: none;
`;

export const Feature = styled.li`
  font-size: inherit;
  letter-spacing: -0.03em;
  margin-bottom: 1.2rem;

  strong {
    font-size: inherit;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`;
