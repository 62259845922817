/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Audio = styled.audio.attrs(() => ({
  controls: false,
  preload: 'auto',
}))`
  &::-webkit-media-controls {
    display: none !important;
  }
`;
