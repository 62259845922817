import { memo, useCallback } from 'react';
import type { FC } from 'react';
import animations from './animations';
import { ClearButton, Container, Icon, Title } from './styles';
import type { Props } from './types';

const Modal: FC<Props> = ({
  animation = 'slideUp',
  children,
  className,
  closePosition = 'right',
  descriptionId,
  disableBackdropClick,
  disableEnforceFocus,
  disableEscapeKeyDown,
  disableRestoreFocus,
  fullscreenOnMobile,
  hideClose = false,
  onClose,
  open,
  title,
  titleId,
  ...rest
}) => {
  const handleClose = useCallback(
    (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (disableBackdropClick && reason === 'backdropClick') return;
      if (disableEscapeKeyDown && reason === 'escapeKeyDown') return;

      if (onClose) {
        onClose();
      }
    },
    [disableBackdropClick, disableEscapeKeyDown, onClose],
  );

  return (
    <Container
      $responsiveFullScreen={fullscreenOnMobile}
      aria-describedby={descriptionId}
      aria-labelledby={titleId}
      className={className}
      disableEnforceFocus={disableEnforceFocus}
      disableRestoreFocus={disableRestoreFocus}
      onClose={handleClose}
      open={open}
      TransitionComponent={animations[animation]}
      {...rest}
    >
      {!hideClose && (
        <ClearButton
          $position={closePosition}
          aria-label="close"
          id="modal-close-button"
          onClick={onClose}
          tabIndex={0}
        >
          <Icon aria-hidden="true" name="xLight" />
        </ClearButton>
      )}
      {title && (
        <Title as="h2" id={titleId}>
          {title}
        </Title>
      )}
      {children}
    </Container>
  );
};

export default memo(Modal);
