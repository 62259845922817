import type { FC } from 'react';
import Agreement from './Agreement';
import AppScreenshot from './AppScreenshot';
import Description from './Description';
import MarketingEmail from './MarketingEmail';
import { Container, Content, Footer, Main, Subtitle, Title } from './styles';
import type { Props } from './types';
import useConnect from './connect';

const SignUp: FC<Props> = () => {
  const { initialEmail, isPhoneLandscape, queryString } = useConnect();

  return (
    <Container>
      <Main>
        {!isPhoneLandscape && <AppScreenshot />}
        <Content>
          <Title>Try Waking Up free for 7 days.</Title>
          <Subtitle>Get started</Subtitle>
          <MarketingEmail
            initialEmail={initialEmail}
            queryString={queryString}
          />
          <Agreement />
          {isPhoneLandscape && <AppScreenshot />}
          <Description />
        </Content>
      </Main>
      <Footer />
    </Container>
  );
};

export default SignUp;
