/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import colorWithAlpha from 'lib/color';

export const Label = styled.label`
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledInput = styled.input`
  -moz-appearance: textfield;
  background: ${({ theme }) => theme.colors.coreNeutralWhite};
  border-radius: ${({ theme }) => theme.radius.sm};
  border: 1px solid
    ${({ theme }) =>
      colorWithAlpha({
        color: theme.colors.coreNeutralBlack,
        alpha: 0.3,
      })};
  color: ${({ theme }) => theme.colors.fontInputBase};
  font-size: 1.0625rem;
  height: 2.8125rem;
  line-height: 1.235;
  margin-right: 0.8rem;
  min-width: 0;
  padding: 0 0.75rem;
  text-align: center;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.coreNeutral100};
    opacity: 1;
  }

  &:focus {
    outline: 0;
    border-color: ${({ theme }) =>
      colorWithAlpha({
        color: theme.colors.coreNeutralBlack,
        alpha: 0.8,
      })};
  }

  &:last-child {
    margin-right: 0;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
