import type { CardElementProps } from '@stripe/react-stripe-js';
import { object as yupObject, string as yupString } from 'yup';

export const CARD_ELEMENT_OPTIONS: NonNullable<CardElementProps['options']> = {
  style: {
    base: {
      backgroundColor: 'white',
      color: '#4a4a4a',
      fontFamily: '"Circular Std", sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#aab7c4',
        fontSize: '16px',
      },
    },
  },
};

export const validationSchema = yupObject({
  email: yupString()
    .required('Error: Please enter a valid email')
    .email('Error: Please enter a valid email'),
});
