import { memo } from 'react';
import type { FC } from 'react';
import {
  Button,
  Buttons,
  Content,
  Description,
  Label,
  Modal,
  TextArea,
  Title,
} from './styles';
import useConnect from './connect';

const GiveFreeTrialModal: FC = () => {
  const { handleClose, handleCopyLink, inviteRef, isOpen, link } = useConnect();

  return (
    <Modal
      descriptionId="give-free-trial-description"
      onClose={handleClose}
      open={isOpen}
      titleId="give-free-trial-title"
    >
      <Title id="give-free-trial-title">Share the app with your friends</Title>
      <Content>
        <Description id="give-free-trial-description">
          Check out Waking Up—a new operating system for your mind. Here’s a
          free month (no credit card required):
        </Description>
        <Label id="give-free-trial">Link to share:</Label>
        <TextArea
          aria-labelledby="give-free-trial"
          id="give-free-trial-modal-link"
          readOnly
          ref={inviteRef}
          rows={1}
          value={link}
        />
      </Content>
      <Buttons>
        <Button mode="deemphasized" onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleCopyLink}>Copy</Button>
      </Buttons>
    </Modal>
  );
};

export default memo(GiveFreeTrialModal);
