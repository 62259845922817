import { apolloClient } from 'services/api';
import { getCoursePermission, normalizeCourse } from 'models/Course';
import { GetCourseDocument } from '../generated';
import getPackCourse from './getPackCourse';
import getProfile from './getProfile';
import getRetreatCourse from './getRetreatCourse';

const getCourse = async (courseKey?: string, packKey?: string) => {
  if (!courseKey) {
    return undefined;
  }

  const retreatCourse = await getRetreatCourse(courseKey, packKey);
  if (retreatCourse) {
    return retreatCourse;
  }

  const packCourse = await getPackCourse(courseKey, packKey);
  if (packCourse) {
    return packCourse;
  }

  const { data: courseData } = await apolloClient.query({
    query: GetCourseDocument,
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    variables: { code: courseKey },
  });

  if (!courseData?.course) {
    return undefined;
  }

  const { isSubscribed } = await getProfile();
  const normalizedCourse = normalizeCourse(courseData.course);
  normalizedCourse.permission = getCoursePermission(
    isSubscribed,
    normalizedCourse.isFree,
  );

  return normalizedCourse;
};

export default getCourse;
