import { useCallback } from 'react';
import { trackEvent } from 'services/analytics';
import { setAudioSpeed } from 'graphql/reactive';
import { useAudioSpeed } from 'containers/Main/Player/Audio/hooks';

const useConnect = () => {
  const currentSpeed = useAudioSpeed();

  const handleSetSpeed = useCallback((newSpeed: number) => {
    trackEvent('Toggle Conversation Speed', { value: newSpeed });
    setAudioSpeed(newSpeed);
  }, []);

  return { currentSpeed, handleSetSpeed };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
