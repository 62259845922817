import type { FC } from 'react';
import { AnimatePresence } from 'framer-motion';
import Icon from 'components/Icon';
import {
  About,
  Card,
  Content,
  CopyButton,
  CopyIcon,
  CopyOverline,
  EmailButton,
  Header,
  IconButton,
  InviteOverline,
  Label,
  LinkContainer,
  ListenButton,
  Message,
  Modal,
  ModalTitle,
  PlayIcon,
  Popup,
  Subtitle,
  TextArea,
  Title,
} from './styles';
import useConnect from './connect';

const ShareTheAppModal: FC = () => {
  const {
    handleClose,
    handleCopyLink,
    handleOpenCourse,
    handleOpenSendEmailModal,
    isOpen,
    link,
    showPopup,
  } = useConnect();

  return (
    <Modal
      descriptionId="share-the-app-description"
      fullscreenOnMobile
      hideClose
      onClose={handleClose}
      open={isOpen}
      titleId="share-the-app-title"
    >
      <Content>
        <Header>
          <IconButton
            aria-label="Close share-the-app modal"
            onClick={handleClose}
          >
            <Icon aria-hidden="true" name="arrowLeftLight" />
          </IconButton>
          <ModalTitle>Share</ModalTitle>
        </Header>
        <Card>
          <Title id="share-the-app-title">Share Waking Up. </Title>
          <Subtitle id="share-the-app-description">
            As a member, you can give a free month to anyone.
          </Subtitle>
          <InviteOverline>Send invite</InviteOverline>
          <EmailButton onClick={handleOpenSendEmailModal}>Email</EmailButton>
          <CopyOverline>Or copy your link</CopyOverline>
          <LinkContainer>
            <Label id="share-wu-cta">Link to share:</Label>
            <TextArea
              aria-labelledby="share-wu-cta"
              id="share-wu-cta-link"
              readOnly
              value={link}
            />
            <CopyButton
              aria-label="copy link"
              onClick={handleCopyLink}
              type="button"
            >
              <CopyIcon aria-hidden="true" name="copySimpleLight" />
            </CopyButton>
          </LinkContainer>
          <AnimatePresence initial={false}>
            {showPopup && <Popup />}
          </AnimatePresence>
        </Card>
        <Message>A message from Sam</Message>
        <About>about why sharing matters.</About>
        <ListenButton onClick={handleOpenCourse}>
          <PlayIcon aria-hidden="true" name="playLight" size={18} />
          Listen
        </ListenButton>
      </Content>
    </Modal>
  );
};

export default ShareTheAppModal;
