import { memo } from 'react';
import type { FC } from 'react';
import ProgressBar from 'components/Player/ProgressBar';
import VolumeControls from 'components/Player/VolumeControls';
import ControlButtons from 'components/Player/ControlButtons';
import TrackList from 'components/Player/TrackList';
import Audio from '../Audio';
import FinalScreen from './FinalScreen';
import { Container, Content, Info, Progress, TrackInfo } from './styles';
import useConnect from './connect';

const PreviewPlayer: FC = () => {
  const {
    audioUrl,
    handleClose,
    handleClosePlayer,
    handleComplete,
    handleEnd,
    handleInit,
    handlePlayFullCourse,
    hash,
    imageThumbnailsThumbX1,
    imageUrl,
    isIntro,
    isLoadingData,
    packId,
    showFinalScreen,
    subtitle,
    title,
    trackList,
  } = useConnect();

  return (
    <Container
      backgroundImage={imageThumbnailsThumbX1}
      onClose={handleClosePlayer}
    >
      {!showFinalScreen ? (
        <Content>
          <Info>
            <TrackInfo
              imgShadow={isIntro ? 'none' : 'box-shadow'}
              imgUrl={imageUrl}
              subtitle={subtitle}
              title={title}
            />
          </Info>
          <Audio
            key={hash}
            audioUrl={audioUrl}
            autoPlay
            onClose={handleClose}
            onComplete={handleComplete}
            onEnd={handleEnd}
            onInit={handleInit}
          >
            {({
              duration,
              handleIncreasePosition,
              handleSetPosition,
              handleSetVolume,
              handleTogglePlay,
              isLoading,
              isPlaying,
              position,
              volume,
            }) => {
              return (
                <>
                  <Progress>
                    <ProgressBar
                      duration={duration}
                      onChangePosition={handleSetPosition}
                      position={position}
                    />
                    <VolumeControls
                      onSetVolume={handleSetVolume}
                      volume={volume}
                    />
                  </Progress>
                  <ControlButtons
                    isLoading={isLoadingData || isLoading}
                    isPlaying={isPlaying}
                    onIncreasePosition={handleIncreasePosition}
                    onPlayChange={handleTogglePlay}
                  />
                </>
              );
            }}
          </Audio>
        </Content>
      ) : (
        <FinalScreen courseHash={hash} packId={packId} />
      )}
      <TrackList onPlayTrack={handlePlayFullCourse} tracks={trackList} />
    </Container>
  );
};

export default memo(PreviewPlayer);
