/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import {
  COURSE_DATA_FRAGMENT,
  DAILY_DATA_FRAGMENT,
  FEATURE_CONTENT_DATA_FRAGMENT,
  PACK_DATA_FRAGMENT,
  PERSON_DATA_FRAGMENT,
  RETREAT_DATA_FRAGMENT,
  WIDGET_CAROUSEL_DATA_FRAGMENT,
  WIDGET_CONTINUE_LISTENING_DATA_FRAGMENT,
  WIDGET_CTA_DATA_FRAGMENT,
  WIDGET_IMAGE_DATA_FRAGMENT,
  WIDGET_SPOTLIGHT_DATA_FRAGMENT,
  WIDGET_UP_NEXT_DATA_FRAGMENT,
} from '../fragments';

export const GET_INITIAL_DATA = gql`
  query GetInitialData {
    content {
      courses {
        ...CourseData
      }
      packs {
        ...PackData
      }
      persons {
        ...PersonData
      }
    }
    daily {
      ...DailyData
    }
    feature_content {
      ...FeatureContentData
    }
    retreat {
      ...RetreatData
    }
    remoteConfig {
      android_current_version
      android_min_version
      background_audios_chunks
      background_audios_filepath
      free_month_cta_image_dark
      free_month_cta_image_light
      free_month_cta_image_web
      ios_current_version
      ios_min_version
      section_life_description
      section_practice_description
      section_theory_description
    }
    home {
      ... on WidgetUpNext {
        ...WidgetUpNextData
      }
      ... on WidgetCTA {
        ...WidgetCTAData
      }
      ... on WidgetSpotlight {
        ...WidgetSpotlightData
      }
      ... on WidgetContinueListening {
        ...WidgetContinueListeningData
      }
      ... on WidgetCarousel {
        ...WidgetCarouselData
      }
      ... on WidgetImage {
        ...WidgetImageData
      }
    }
  }
  ${COURSE_DATA_FRAGMENT}
  ${PACK_DATA_FRAGMENT}
  ${PERSON_DATA_FRAGMENT}
  ${DAILY_DATA_FRAGMENT}
  ${FEATURE_CONTENT_DATA_FRAGMENT}
  ${RETREAT_DATA_FRAGMENT}
  ${WIDGET_UP_NEXT_DATA_FRAGMENT}
  ${WIDGET_CTA_DATA_FRAGMENT}
  ${WIDGET_SPOTLIGHT_DATA_FRAGMENT}
  ${WIDGET_CONTINUE_LISTENING_DATA_FRAGMENT}
  ${WIDGET_CAROUSEL_DATA_FRAGMENT}
  ${WIDGET_IMAGE_DATA_FRAGMENT}
`;
