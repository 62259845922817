import { useCallback } from 'react';
import { stringify } from 'query-string';
import { trackMediaEvent } from 'services/analytics';
import generateHex from 'lib/generateHex';
import useCopyInputContent from 'lib/useCopyInputContent';
import { ModalType } from 'graphql/reactive';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';

const useConnect = () => {
  const { isOpen, payload } = useModal(ModalType.SHARE_LINK);
  const { closeModal } = useModalActions();
  const { ref, copy } = useCopyInputContent();

  const { description = '', id, link = '', title = '' } = payload || {};

  const source = 'content share';
  const shareId = generateHex(8);
  const queryString = stringify({ source, share_id: shareId });
  const fullLink = `${link}${link.includes('?') ? '&' : '?'}${queryString}`;

  const handleCopyLink = useCallback(() => {
    copy();

    if (id) {
      trackMediaEvent('Media Share Content', id, {
        source,
        share_id: shareId,
      });
    }
  }, [copy, id, shareId, source]);

  return {
    closeModal,
    description,
    handleCopyLink,
    isOpen,
    link: fullLink,
    linkRef: ref,
    title,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
