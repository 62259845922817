import { gql } from '@apollo/client';
import { IMAGE_DATA_FRAGMENT } from './image';

export const PERSON_DATA_FRAGMENT = gql`
  fragment PersonData on Person {
    __typename
    active
    bio
    id
    image {
      ...ImageData
    }
    name
  }
  ${IMAGE_DATA_FRAGMENT}
`;

export const PERSON_INFO_DATA_FRAGMENT = gql`
  fragment PersonInfoData on PersonInfo {
    __typename
    id
    person_id
    type
  }
`;
