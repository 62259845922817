import { memo } from 'react';
import type { FC } from 'react';
import ProgressBarMaximized from './ProgressBarMaximized';
import ProgressBarMinimized from './ProgressBarMinimized';
import type { Props } from './types';

const ProgressBar: FC<Props> = ({ isMinimized = false, ...rest }) =>
  isMinimized ? (
    <ProgressBarMinimized {...rest} />
  ) : (
    <ProgressBarMaximized {...rest} />
  );

export default memo(ProgressBar);
