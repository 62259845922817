import { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { PaymentRequest } from '@stripe/stripe-js';
import { addHours, getHours } from 'date-fns';
import { FormikHelpers, FormikProps } from 'formik';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import useGiftCardsActions from 'graphql/hooks/useGiftCardsActions';
import { LogicProps, FormValues } from './types';
import { TITLE } from './constants';

const useConnect = ({
  cardAmount,
  cardType,
  imageCaption,
  imageSrc,
  userEmail,
  userName,
}: LogicProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const { purchaseGiftCard } = useGiftCardsActions();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [isCreatingPaymentRequest, setIsCreatingPaymentRequest] =
    useState<boolean>(true);
  const [hasApplePay, setHasApplePay] = useState<boolean>(false);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null,
  );

  const formikRef = useRef<FormikProps<FormValues>>(null);

  const initialValues: FormValues = useMemo(() => {
    let timezone: string | null;
    try {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (error) {
      timezone = null;
    }

    return {
      message: '',
      recipientEmail: '',
      recipientName: '',
      sendOn: 'send_immediately',
      senderEmail: userEmail || '',
      senderName: userName || '',
      shouldBeSentAtDate: null,
      shouldBeSentAtHour: null,
      submitError: '',
      timezone,
    };
  }, [userEmail, userName]);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        requestPayerEmail: false,
        requestPayerName: false,
        requestPayerPhone: false,
        requestShipping: false,
        total: {
          label: 'Waking Up - Purchase Gift Card',
          amount: cardAmount,
        },
      });

      pr.on('paymentmethod', async ({ complete, paymentMethod }) => {
        if (formikRef.current) {
          const { errors, setErrors, values } = formikRef.current;

          let shouldBeSentAt: Date | undefined;
          if (
            values.shouldBeSentAtDate &&
            values.shouldBeSentAtHour &&
            values.timezone !== null
          ) {
            const dateAndHours = addHours(
              values.shouldBeSentAtDate,
              getHours(values.shouldBeSentAtHour),
            );

            shouldBeSentAt = zonedTimeToUtc(dateAndHours, values.timezone);
          }

          if (!!values.senderEmail && !errors.senderEmail) {
            try {
              await purchaseGiftCard({
                message: values.message,
                paymentMethodId: paymentMethod.id,
                recipientEmail: values.recipientEmail,
                recipientName: values.recipientName,
                senderEmail: values.senderEmail,
                senderName: values.senderName,
                shouldBeSentAt,
                type: cardType,
              });

              complete('success');

              navigate(`${pathname}?r=s`, {
                state: {
                  message: values.message,
                  name: values.senderName,
                  prevImageCaption: imageCaption,
                  prevImageSrc: imageSrc,
                  recipientEmail: values.recipientEmail,
                  shouldBeSentAt,
                },
              });
            } catch (error) {
              complete('fail');

              setErrors({
                submitError:
                  error?.message ||
                  `The payment hasn't been processed. Try it again in a few seconds.`,
              });
            }
          } else {
            complete('invalid_payer_email');
          }
        }
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment()
        .then((result) => {
          if (result) {
            if (result.applePay) {
              setHasApplePay(true);
            }
            setPaymentRequest(pr);
          }
          setIsCreatingPaymentRequest(false);
        })
        .catch(() => {
          setIsCreatingPaymentRequest(false);
        });
    }
  }, [
    cardAmount,
    cardType,
    imageCaption,
    imageSrc,
    navigate,
    pathname,
    purchaseGiftCard,
    stripe,
    userEmail,
  ]);

  const handleSubmit = useCallback(
    async (values: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
      if (stripe && elements) {
        try {
          let shouldBeSentAt: Date | undefined;
          if (
            values.shouldBeSentAtDate &&
            values.shouldBeSentAtHour &&
            values.timezone !== null
          ) {
            const dateAndHours = addHours(
              values.shouldBeSentAtDate,
              getHours(values.shouldBeSentAtHour),
            );

            shouldBeSentAt = zonedTimeToUtc(dateAndHours, values.timezone);
          }

          const card = elements.getElement('card');

          if (!card) {
            throw new Error(
              'Unable to get card information. Try again in a few seconds',
            );
          }

          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card,
            billing_details: !userEmail
              ? { email: values.senderEmail }
              : undefined,
          });

          if (error || !paymentMethod) {
            throw new Error(error ? error.message : undefined);
          }

          await purchaseGiftCard({
            message: values.message,
            paymentMethodId: paymentMethod.id,
            recipientEmail: values.recipientEmail,
            recipientName: values.recipientName,
            senderEmail: values.senderEmail,
            senderName: values.senderName,
            shouldBeSentAt,
            type: cardType,
          });

          navigate(`${pathname}?r=s`, {
            state: {
              message: values.message,
              name: values.senderName,
              prevImageCaption: imageCaption,
              prevImageSrc: imageSrc,
              recipientEmail: values.recipientEmail,
              shouldBeSentAt,
            },
          });
        } catch (error) {
          setErrors({
            submitError:
              error?.message ||
              `The payment hasn't been processed. Try it again in a few seconds.`,
          });
        }
      }
    },
    [
      cardType,
      elements,
      imageCaption,
      imageSrc,
      navigate,
      pathname,
      purchaseGiftCard,
      stripe,
      userEmail,
    ],
  );

  return {
    elements,
    formikRef,
    handleSubmit,
    hasApplePay,
    initialValues,
    loading: isCreatingPaymentRequest,
    paymentRequest,
    stripe,
    title: TITLE[cardType],
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
