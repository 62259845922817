import { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const ChevronRight: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="currentColor"
    height={(size * 12) / 7.4099998}
    viewBox="0 0 7.4099998 12"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M0 10.58L4.58 6 0 1.41 1.41 0l6 6-6 6z" />
  </svg>
);

export default memo(ChevronRight);
