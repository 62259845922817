import { memo, useCallback } from 'react';
import type { FC } from 'react';
import getCourseProgressPercent from 'lib/getCourseProgressPercent';
import { CoursePermission, CourseStatus } from 'models/Course';
import useSettings from 'graphql/hooks/useSettings';
import useModalActions from 'graphql/hooks/useModalActions';
import usePlayerActions from 'graphql/hooks/usePlayerActions';
import Course from './Course';
import LiveMeeting from './LiveMeeting';
import { Button, Container } from './styles';
import type { Props } from './types';

const Pack: FC<Props> = ({ id, pack }) => {
  const {
    settings: { recentlyPlayed },
  } = useSettings();
  const { openEndRetreatModal, openRetreatLiveMeetingModal } =
    useModalActions();
  const { openRetreatPlayer } = usePlayerActions();

  let firstUncompletedCourseFlag = false;
  const coursesWithProgress =
    pack?.courses.map((course) => {
      let permission = CoursePermission.FORBIDDEN;
      if (course.status === CourseStatus.FINISHED) {
        permission = CoursePermission.UNLOCKED;
      } else if (!firstUncompletedCourseFlag) {
        firstUncompletedCourseFlag = true;
        permission = CoursePermission.UNLOCKED;
      }

      return {
        ...course,
        permission,
        progress: getCourseProgressPercent({
          audioLength: course.audioLength,
          hash: course.hash,
          recentlyPlayed,
          status: course.status,
        }),
      };
    }) || [];
  const allCoursesCompleted = coursesWithProgress.every(
    (c) => c.status === CourseStatus.FINISHED,
  );

  const handlePlayCourse = useCallback(
    (courseId: string, packId: string) => {
      openRetreatPlayer({ hash: courseId, packId });
    },
    [openRetreatPlayer],
  );

  const handleLiveMeeting = useCallback(() => {
    openRetreatLiveMeetingModal();
  }, [openRetreatLiveMeetingModal]);

  const handleEnd = useCallback(async () => {
    openEndRetreatModal();
  }, [openEndRetreatModal]);

  return (
    <Container>
      {coursesWithProgress.map((course) => (
        <Course key={course.id} {...course} onClick={handlePlayCourse} />
      ))}
      <LiveMeeting
        allCoursesCompleted={allCoursesCompleted}
        onClick={handleLiveMeeting}
        retreatId={id}
      />
      <Button onClick={handleEnd}>End Retreat</Button>
    </Container>
  );
};

export default memo(Pack);
