/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import {
  COURSE_DATA_FRAGMENT,
  PACK_DATA_FRAGMENT,
  PERSON_DATA_FRAGMENT,
} from '../fragments';

export const GET_CONTENT = gql`
  query GetContent {
    content {
      courses {
        ...CourseData
      }
      packs {
        ...PackData
      }
      persons {
        ...PersonData
      }
    }
  }
  ${COURSE_DATA_FRAGMENT}
  ${PACK_DATA_FRAGMENT}
  ${PERSON_DATA_FRAGMENT}
`;
