import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Container = styled.div``;

export const Content = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
`;

export const PresetWidgets = styled.div`
  align-items: stretch;
  display: flex;

  ${media.phoneLandscape} {
    display: block;
  }
`;
