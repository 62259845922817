import styled from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';
import DefaultIcon from 'components/Icon';

export const Link = styled(DefaultLink)`
  color: ${({ theme }) => theme.colors.fontEmphasized};
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.family.inter};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 1.429;
  position: relative;
  text-decoration: none;
  transition: all 0.2s ease;

  &::before {
    background-color: ${({ theme }) => theme.colors.fontEmphasized};
    bottom: 0;
    content: '';
    height: 0.0625rem;
    left: 0;
    position: absolute;
    transform-origin: 100% 0%;
    transform: scaleX(0);
    transition: transform 300ms ease-in-out;
    width: 100%;
  }

  &:hover {
    &::before {
      transform-origin: 0% 0%;
      transform: scaleX(1);
    }
  }

  &:active {
    transform: scaleX(0.97);
  }
`;

export const Icon = styled(DefaultIcon).attrs(() => ({ size: 16 }))`
  margin-bottom: -0.1563rem;
  margin-left: 0.25rem;
`;
