import type { FC } from 'react';
import { components } from 'react-select';
import Icon from 'components/Icon';
import type { Props } from './types';

const DropdownIndicator: FC<Props> = ({ ...props }) => {
  // @ts-ignore
  const { iconName } = props.selectProps;

  return (
    <components.DropdownIndicator {...props}>
      <Icon aria-hidden="true" name={iconName} size={24} />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
