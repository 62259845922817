/* eslint-disable import/prefer-default-export */
import { Dispatch, SetStateAction, useCallback, useState } from 'react';

type SetPressed = Dispatch<SetStateAction<boolean>>;

const handleMouseDown =
  ({ setPressed }: { setPressed: SetPressed }) =>
  () =>
    setPressed(true);

const handleMouseUp =
  ({ setPressed }: { setPressed: SetPressed }) =>
  () =>
    // We wait one second before removing the pressed state so the CSS transition
    // also applies to the "out" animation.
    setTimeout(() => setPressed(false), 1);

export const useLogic = () => {
  const [pressed, setPressed] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMouseDown = useCallback(handleMouseDown({ setPressed }), [
    setPressed,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMouseUp = useCallback(handleMouseUp({ setPressed }), [setPressed]);

  return { pressed, onMouseDown, onMouseUp };
};
