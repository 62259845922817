import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultModal from 'components/Modal';
import colorWithAlpha from 'lib/color';

export const Modal = styled(DefaultModal)`
  &&& {
    .MuiPaper-root {
      padding: 1rem 0 2rem 0;
      width: 24rem;
    }

    ${media.phoneLandscape} {
      .MuiPaper-root {
        width: 100%;
      }
    }

    @media (max-height: 450px) {
      .MuiPaper-root {
        border-radius: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        max-height: none;
        max-width: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 0;
  position: relative;
  width: 100%;
`;

const ClearButton = styled.button`
  background: transparent;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: block;
  line-height: 1;
  outline: none;
  width: fit-content;
`;

export const Slider = styled.div`
  margin: 2.5rem 0;

  > .swiper {
    height: 100%;
    padding-bottom: 1.5rem;

    .swiper-pagination {
      bottom: 0;

      &-bullet {
        background-color: ${({ theme }) =>
          colorWithAlpha({
            color:
              theme.name === 'LIGHT'
                ? theme.colors.coreNeutralBlack
                : theme.colors.coreNeutralWhite,
            alpha: 0.25,
          })};
        height: 0.75rem;
        margin: 0 0.5rem;
        opacity: 1;
        transition: background-color linear 300ms;
        width: 0.75rem;

        ${media.phoneLandscape} {
          margin: 0 0.25rem;
        }

        @media (max-height: 450px) {
          margin: 0 0.25rem;
        }

        &-active {
          background-color: ${({ theme }) =>
            theme.name === 'LIGHT'
              ? theme.colors.coreNeutralAbyss
              : theme.colors.coreNeutralAurora};
        }

        &:focus {
          box-shadow: 0 0 0 2px #f4f4f4, 0 0 0 3.5px rgba(0, 96, 103, 0.25);
        }
      }
    }
  }

  ${media.phoneLandscape} {
    margin: 0;
  }

  @media (max-height: 450px) {
    margin: 0;
  }

  ${media.phonePortrait} {
    margin-top: 7rem;
  }
`;

export const IconButton = styled(ClearButton)`
  padding-left: 1rem;
`;
