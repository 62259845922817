import { useMemo, useCallback } from 'react';
import type { FormikHelpers } from 'formik';
import { ModalType } from 'graphql/reactive';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import useFamilyMembersActions from 'graphql/hooks/useFamilyMembersActions';
import { validationSchema } from './constants';
import type { FormFields } from './types';

const useConnect = () => {
  const { isOpen } = useModal(ModalType.ADD_FAMILY_MEMBER);
  const { closeModal } = useModalActions();
  const { addMember } = useFamilyMembersActions();

  const initialValues: FormFields = useMemo(
    () => ({
      email: '',
      submitError: '',
    }),
    [],
  );

  const submit = useCallback(
    async (
      { email }: FormFields,
      { setErrors }: { setErrors: FormikHelpers<FormFields>['setErrors'] },
    ) => {
      try {
        await addMember(email);
        closeModal();
      } catch (error) {
        setErrors({ submitError: error.message });
      }
    },
    [addMember, closeModal],
  );

  return {
    closeModal,
    initialValues,
    isOpen,
    submit,
    validationSchema,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
