import styled from 'styled-components';
import { blink } from 'styles/mixins';
import colorWithAlpha from 'lib/color';
import DefaultTextPlaceholder from 'components/TextPlaceholder';
import { CONTAINER_WIDTH } from '../styles';

export const Container = styled.div`
  width: ${CONTAINER_WIDTH};
`;

export const Image = styled.div`
  animation: ${blink} 3s linear infinite;
  background-color: ${({ theme }) =>
    colorWithAlpha({ color: theme.colors.fontDeemphasized, alpha: 0.8 })};
  height: ${CONTAINER_WIDTH};
  margin-bottom: 1rem;
  width: ${CONTAINER_WIDTH};
`;

export const Overline = styled(DefaultTextPlaceholder).attrs(() => ({
  typography: 'overline',
}))``;

export const Title = styled(DefaultTextPlaceholder).attrs(() => ({
  typography: 'displaySmall',
}))``;

export const Body = styled(DefaultTextPlaceholder).attrs(() => ({
  typography: 'bodyMedium',
}))``;
