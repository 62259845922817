import { useMemo } from 'react';
import { useQuery, type QueryHookOptions } from '@apollo/client';
import { normalizeHomeCta } from 'models/HomeCta';
import { HomeCtaDocument, type HomeCtaQuery } from '../generated';

const useHomeCta = (options?: QueryHookOptions<HomeCtaQuery>) => {
  const { data, loading } = useQuery(HomeCtaDocument, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  const homeCta = useMemo(
    () => (data?.homeCta ? normalizeHomeCta(data.homeCta) : null),
    [data],
  );

  return { homeCta, loading };
};

export default useHomeCta;
export type UseHomeCta = ReturnType<typeof useHomeCta>;
