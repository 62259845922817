const REPLACES = [
  { search: 'wakingup://', value: '/' },
  { search: 'dynamic.wakingup.com/', value: '' },
];

const getDeeplinkValue = (deepLinkValue: string) =>
  REPLACES.reduce(
    (prev, { search, value }) => prev.replace(search, value),
    deepLinkValue,
  );

export default getDeeplinkValue;
