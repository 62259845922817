import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import useMediaQuery from 'lib/useMediaQuery';
import { media } from 'styles/mixins';
import type { LogicProps } from './types';

const COLLAPSABLE_MIN_LENGTH = 100;

const useLogic = ({
  description,
  onClick,
  onOpenOptions,
  onTextExpand,
}: LogicProps) => {
  const isPhoneLandscape = useMediaQuery(media.phoneLandscape);

  const isCollapsable =
    isPhoneLandscape &&
    !!description &&
    description.length >= COLLAPSABLE_MIN_LENGTH;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(isCollapsable);

  const descriptionValue =
    isCollapsable && isCollapsed
      ? `${description.slice(0, COLLAPSABLE_MIN_LENGTH)}…`
      : description;

  useEffect(() => {
    if (onTextExpand) {
      onTextExpand();
    }
  }, [onTextExpand, isCollapsed]);

  const handleToggleDescription = useCallback(() => {
    setIsCollapsed((v) => !v);
  }, []);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (!onClick) return;

      const { key } = event;
      if (key === 'Enter' || key === ' ' || key === 'Spacebar') {
        event.preventDefault();
        onClick();
      }
    },
    [onClick],
  );

  const handleKeyPressOptions = useCallback(
    (event: KeyboardEvent<HTMLButtonElement>) => {
      if (!onOpenOptions) return;

      const { key } = event;
      if (key === 'Enter' || key === ' ' || key === 'Spacebar') {
        event.preventDefault();
        onOpenOptions(event);
      }
    },
    [onOpenOptions],
  );

  return {
    descriptionValue,
    handleKeyPress,
    handleKeyPressOptions,
    handleToggleDescription,
    isCollapsable,
    isCollapsed,
  };
};

export default useLogic;
export type UseLogic = ReturnType<typeof useLogic>;
