import styled from 'styled-components';
import { dangerButtonStyles } from 'styles/mixins';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
`;

export const Info = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
`;

export const Email = styled.div`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 0;
`;

export const Status = styled.div`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  text-overflow: ellipsis;
  text-transform: capitalize;
  font-weight: 500;
  overflow: hidden;
`;

export const Button = styled.button.attrs({ type: 'button' })`
  ${dangerButtonStyles};
`;
