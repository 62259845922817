import { useEffect, useRef, useCallback, useState } from 'react';
import type { Swiper } from 'swiper';
import { trackEvent } from 'services/analytics';
import { ModalType } from 'graphql/reactive';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import { SLIDES, NSLIDES } from './constants';

const useConnect = () => {
  const swiperRef = useRef<{ swiper: Swiper }>(null!);
  const { isOpen } = useModal(ModalType.ONBOARDING_SLIDES);
  const { closeModal, openOnboardingExperienceModal } = useModalActions();

  const [index, setIndex] = useState(0);

  useEffect(() => {
    SLIDES.forEach(({ image }) => {
      new Image().src = image;
    });
  }, []);

  const handleClose = useCallback(() => {
    closeModal();
    trackEvent('Prompt Experience', { name: 'Experience', action: 'display' });
    setTimeout(() => openOnboardingExperienceModal(), 500);
  }, [closeModal, openOnboardingExperienceModal]);

  const handleNextSlide = useCallback(() => {
    if (swiperRef.current.swiper.realIndex === NSLIDES - 1) {
      handleClose();
      return;
    }
    swiperRef.current.swiper.slideNext();
    setIndex(swiperRef.current.swiper.realIndex);
  }, [handleClose]);

  const handleInit = useCallback((swiper: Swiper) => {
    const { activeIndex, slides } = swiper;
    const activeSlide = slides[activeIndex];
    slides.forEach((slide) => slide.setAttribute('aria-hidden', 'true'));
    activeSlide.setAttribute('aria-hidden', 'false');
  }, []);

  const handleSetIndex = useCallback((newIndex: number) => {
    swiperRef.current.swiper.slideTo(newIndex);
    setIndex(newIndex);
  }, []);

  const handleSlideChange = useCallback((swiper: Swiper) => {
    const { activeIndex, realIndex, slides } = swiper;
    setIndex(realIndex);
    const activeSlide = slides[activeIndex];
    slides.forEach((slide) => slide.setAttribute('aria-hidden', 'true'));
    setTimeout(() => {
      activeSlide.setAttribute('aria-hidden', 'false');
    }, 0);
  }, []);

  return {
    handleClose,
    handleInit,
    handleNextSlide,
    handleSetIndex,
    handleSlideChange,
    index,
    isOpen,
    swiperRef,
  };
};

export default useConnect;
