import styled from 'styled-components';
import DefaultModal from 'components/Modal';
import { media } from 'styles/mixins';

export const Modal = styled(DefaultModal)`
  .MuiPaper-root {
    height: 90vh;
    max-height: 52rem;
    width: 30rem;

    > div {
      width: 100%;
    }

    > button {
      left: 1rem;
      right: unset;
      top: 1rem;
    }
  }

  ${media.phoneLandscape} {
    .MuiPaper-root {
      height: 100%;
      max-height: none;
      width: 100%;
    }
  }
`;

export const ModalContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem 2rem 2rem;

  h1 {
    font-family: ${({ theme }) => theme.font.family.inter};
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.01em;
    line-height: 1.4;
    text-align: center;
  }

  img {
    display: block;
    height: auto;
    margin-bottom: 1rem;
    width: 100%;
  }

  p {
    font-family: ${({ theme }) => theme.font.family.inter};
    font-size: 1rem;
    line-height: 1.4;
    letter-spacing: 0.01em;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  p:first-child {
    margin-block-start: 0;
  }

  p:last-of-type {
    margin-block-end: 0;
  }

  a,
  a:hover,
  a:active,
  a:visited {
    color: inherit;
  }

  strong,
  b {
    font-weight: bold;
  }

  em,
  i {
    font-style: italic;
  }

  ul,
  ol {
    list-style-position: inside;
    margin: 1rem 0;
  }

  ${media.phonePortrait} {
    padding: 3rem 0 2rem 0;

    h1 {
      font-size: 1.5rem;
    }

    p {
      font-size: 0.875rem;
    }
  }
`;
