import styled from 'styled-components';
import DefaultIcon from 'components/Icon';

export const Container = styled.div`
  align-items: center;
  display: inline-flex;
`;

export const Icon = styled(DefaultIcon)`
  display: block;

  &:not(:last-child) {
    margin-right: 0.4rem;
  }
`;
