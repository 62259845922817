import notNullable from 'lib/notNullable';

const configVar = (variable: string = '', optIn = true) =>
  variable ? JSON.parse(variable) : !optIn;

export const ApiConfig = {
  API: notNullable(process.env.REACT_APP_API),
  API_SECRET: notNullable(process.env.REACT_APP_API_SECRET),
  STRIPE_KEY: notNullable(process.env.REACT_APP_STRIPE_KEY),
  AMPLITUDE_KEY: notNullable(process.env.REACT_APP_AMPLITUDE_KEY),
  BRAZE_KEY: notNullable(process.env.REACT_APP_BRAZE_KEY),
  FIREBASE: {
    API_KEY: notNullable(process.env.REACT_APP_FIREBASE_API_KEY),
    AUTH_DOMAIN: notNullable(process.env.REACT_APP_FIREBASE_AUTH_DOMAIN),
    DATABASE_URL: notNullable(process.env.REACT_APP_FIREBASE_DATABASE_URL),
    PROJECT_ID: notNullable(process.env.REACT_APP_FIREBASE_PROJECT_ID),
    STORAGE_BUCKET: notNullable(process.env.REACT_APP_FIREBASE_STORAGE_BUCKET),
    MESSAGING_SENDER_ID: notNullable(
      process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    ),
    APP_ID: notNullable(process.env.REACT_APP_FIREBASE_APP_ID),
    MEASUREMENT_ID: notNullable(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID),
  },
  SHAREASALE_MERCHANT_ID: notNullable(
    process.env.REACT_APP_SHAREASALE_MERCHANT_ID,
  ),
};

export const AppConfig = {
  appName: 'Waking Up',
  appStoreURL: 'https://wakingup.onelink.me/8fmX',
  childrenURL: 'https://wakingup.com/children',
  companyName: 'Waking Up, LLC',
  dynamicBaseUrl: notNullable(process.env.REACT_APP_DYNAMIC_BASE_URL),
  foundationURL: 'https://wakingup.com/foundation',
  imagesURL: 'https://assets.wakingup.com/web/images',
  playStoreURL: ' https://wakingup.onelink.me/CA3I',
  privacyURL: 'https://wakingup.com/privacy-policy',
  publicAppIconURL: 'https://app.wakingup.com/lightrail-icon.png',
  publicUrl: notNullable(process.env.PUBLIC_URL),
  shopURL: 'https://shop.wakingup.com',
  supportEmail: 'support@wakingup.com',
  supportURL: 'https://help.wakingup.com',
  termsURL: 'https://wakingup.com/terms-of-service',
  version: notNullable(process.env.REACT_APP_VERSION),
  webappBaseUrl: notNullable(process.env.REACT_APP_WEBAPP_BASE_URL),
};

export const DevConfig = {
  amplitudeLogging: configVar(process.env.REACT_APP_AMPLITUDE_LOGGING),
  appsflyerLogging: configVar(process.env.REACT_APP_APPSFLYER_LOGGING),
  brazeEnablePushNotifications: configVar(
    process.env.REACT_APP_BRAZE_ENABLE_PUSH_NOTIFICATIONS,
  ),
  brazeLogging: configVar(process.env.REACT_APP_BRAZE_LOGGING),
  gaLogging: configVar(process.env.REACT_APP_GA_LOGGING),
  debugSection: configVar(process.env.REACT_APP_DEBUG_SECTION),
};

// We may deprecate "isTest" & "isProd"
export const isTest = process.env.NODE_ENV === 'test';
export const isProd = process.env.NODE_ENV === 'production';

export const APP_BUILD = process.env.REACT_APP_BUILD || 'development';
export const isDevelopment = APP_BUILD === 'development';
export const isStaging = APP_BUILD === 'staging';
export const isTesting = APP_BUILD === 'testing';
export const isProduction = APP_BUILD === 'production';
export const isLocal = process.env.NODE_ENV !== 'production';

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
export const MEMBERSHIP_SPECIAL_PRICE_ID =
  process.env.REACT_APP_MEMBERSHIP_SPECIAL_PRICE_ID || '';
