import styled, { css } from 'styled-components';
import {
  HEADER_HEIGHT,
  MAX_CONTENT_WIDTH,
  MIN_CONTENT_WIDTH,
  SIDEBAR_TABLET_LANDSCAPE_WIDTH,
  SIDEBAR_WIDTH,
} from 'styles/metrics';
import { media } from 'styles/mixins';
import { motion } from 'framer-motion';
import AnimatedRoutes from 'components/AnimatedRoutes';

export const Container = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { opacity: 0 },
}))``;

export const Content = styled.div`
  margin: 0 auto;
  position: relative;
  width: ${MAX_CONTENT_WIDTH};

  ${media.tabletLandscape} {
    width: ${MIN_CONTENT_WIDTH};
  }

  ${media.tabletPortrait} {
    margin: 0;
    width: 100%;
  }
`;

export const Routes = styled(AnimatedRoutes)<{
  $isTrialExpirationDaysBannerOpen: boolean;
  $isTrialExpiredBannerOpen: boolean;
  $isUpgradeToAnnualOpen: boolean;
}>`
  left: ${SIDEBAR_WIDTH};
  min-height: 100vh;
  padding: 4rem 0 5rem 3.5rem;
  position: absolute;
  transition: padding-bottom 0.2s ease-in-out, padding-top 0.5s ease-in-out;
  width: calc(100% - ${SIDEBAR_WIDTH});

  ${media.tabletLandscape} {
    left: ${SIDEBAR_TABLET_LANDSCAPE_WIDTH};
    padding: 2rem 0 5rem 2rem;
    width: calc(100% - ${SIDEBAR_TABLET_LANDSCAPE_WIDTH});
  }

  ${media.tabletPortrait} {
    left: auto;
    padding: 1.4rem;
    padding-top: calc(${HEADER_HEIGHT} + 1.4rem);
    position: relative;
    width: 100%;
  }

  ${media.phoneLandscape} {
    padding: ${({ theme }) => theme.measures.m};
    padding-top: calc(${HEADER_HEIGHT} + 1rem);
  }

  ${({
    $isTrialExpirationDaysBannerOpen,
    $isTrialExpiredBannerOpen,
    $isUpgradeToAnnualOpen,
  }) => {
    if ($isTrialExpirationDaysBannerOpen) {
      return css`
        padding-top: 9.25rem;

        ${media.tabletLandscape} {
          padding-top: 7.25rem;
        }

        ${media.tabletPortrait} {
          padding-bottom: 5rem;
          padding-top: calc(${HEADER_HEIGHT} + 1.4rem);
        }

        ${media.phoneLandscape} {
          padding-top: calc(${HEADER_HEIGHT} + 1rem);
        }
      `;
    }

    if ($isTrialExpiredBannerOpen) {
      return css`
        padding-top: 10rem;

        ${media.tabletLandscape} {
          padding-top: 8rem;
        }

        ${media.tabletPortrait} {
          padding-bottom: 5rem;
          padding-top: calc(${HEADER_HEIGHT} + 1.4rem);
        }

        ${media.phoneLandscape} {
          padding-top: calc(${HEADER_HEIGHT} + 1rem);
        }
      `;
    }

    if ($isUpgradeToAnnualOpen) {
      return css`
        padding-top: 10.6rem;

        ${media.tabletLandscape} {
          padding-top: 8.6rem;
        }

        ${media.tabletPortrait} {
          padding-bottom: 6rem;
          padding-top: calc(${HEADER_HEIGHT} + 1.4rem);
        }

        ${media.phoneLandscape} {
          padding-bottom: 10rem;
          padding-top: calc(${HEADER_HEIGHT} + 1rem);
        }
      `;
    }
  }};
`;
