import { memo } from 'react';
import type { FC } from 'react';
import TrackInfoMaximized from './TrackInfoMaximized';
import TrackInfoMinimized from './TrackInfoMinimized';
import { Props } from './types';

const TrackInfo: FC<Props> = ({ isMinimized = false, ...rest }) =>
  isMinimized ? (
    <TrackInfoMinimized {...rest} />
  ) : (
    <TrackInfoMaximized {...rest} />
  );

export default memo(TrackInfo);
