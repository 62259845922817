import { useState, useEffect, useCallback, useRef } from 'react';
import { durationOptions } from 'models/DailyMeditationDuration';
import type { LogicProps } from './types';

const useLogic = ({ duration }: LogicProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null!);

  const currentDurationText =
    durationOptions.find((d) => d.id === duration)?.text || '';

  useEffect(() => {
    const listener = (e: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };

    if (document) {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }
  }, []);

  const handleToggleOpen = useCallback(() => {
    setOpen((v) => !v);
  }, []);

  return {
    currentDurationText,
    handleToggleOpen,
    open,
    ref,
  };
};

export default useLogic;
export type UseLogic = ReturnType<typeof useLogic>;
