import type { FC } from 'react';
import {
  ButtonPlay,
  ButtonPlayContainer,
  Container,
  JumpBackButton,
  JumpForwardButton,
  Loading,
} from './styles';
import { Props } from '../types';
import useLogic from '../logic';

const ControlButtonsMaximized: FC<Props> = ({
  className,
  isLoading = false,
  isPlaying = false,
  isSeekDisabled = false,
  onIncreasePosition,
  onPlayChange,
}) => {
  const { handleJumpBackwards, handleJumpForward, handlePlayChange } = useLogic(
    { onIncreasePosition, onPlayChange },
  );

  return (
    <Container className={className}>
      {!isSeekDisabled && (
        <JumpBackButton
          $isLoading={isLoading}
          aria-label="rewind audio 15s"
          iconName="arrowCounterClockwiseLight15s"
          id="wu-player-jump-backward-button"
          onClick={handleJumpBackwards}
        />
      )}
      <ButtonPlayContainer>
        <ButtonPlay
          $isLoading={isLoading}
          aria-label={isPlaying ? 'Pause' : 'Play'}
          isPlaying={isPlaying}
          id="wu-player-play-button"
          onClick={handlePlayChange}
        />
        {isLoading && <Loading />}
      </ButtonPlayContainer>
      {!isSeekDisabled && (
        <JumpForwardButton
          $isLoading={isLoading}
          aria-label="forward audio 15s"
          iconName="arrowClockwiseLight15s"
          id="wu-player-jump-forward-button"
          onClick={handleJumpForward}
        />
      )}
    </Container>
  );
};

export default ControlButtonsMaximized;
