import { memo, type FC } from 'react';
import { formatDurationShort } from 'lib/formatTime';
import { PlayerSource } from 'models/Player';
import Placeholder from './Placeholder';
import {
  Caption,
  Container,
  Duration,
  DurationIcon,
  Icon,
  Image,
  ImageContainer,
  Overline,
  Separator,
  Subtitle,
  Title,
} from './styles';
import type { Props } from './types';
import useConnect from './connect';

const WidgetSpotlight: FC<Props> = ({ widgetId }) => {
  const {
    caption,
    deeplink,
    duration,
    durationIcon,
    handleClick,
    imageUrl,
    isPublicDeeplink,
    loading,
    overline,
    overlineIcon,
    subtitle,
    title,
  } = useConnect({ widgetId });

  if (loading) {
    return (
      <Container>
        <Placeholder />
      </Container>
    );
  }

  return (
    <Container
      href={isPublicDeeplink ? deeplink : undefined}
      onClick={handleClick}
      state={{ playerSource: PlayerSource.FEATURED_CONTENT }}
      target={!!deeplink && isPublicDeeplink ? '_blank' : undefined}
      to={isPublicDeeplink ? undefined : deeplink}
    >
      <ImageContainer>
        <Image alt="" src={imageUrl} />
        {!!duration && (
          <Duration>
            {durationIcon && <DurationIcon name={durationIcon} />}
            <span>{formatDurationShort(duration)}</span>
          </Duration>
        )}
      </ImageContainer>
      {(overline || caption) && (
        <Overline>
          {overlineIcon && <Icon aria-hidden="true" name={overlineIcon} />}
          {overline}
          {overline && caption && <Separator>·</Separator>}
          {caption && <Caption>{caption}</Caption>}
        </Overline>
      )}
      {title && <Title as="h2">{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Container>
  );
};

export default memo(WidgetSpotlight);
