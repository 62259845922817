import { memo } from 'react';
import type { FC } from 'react';
import { media } from 'styles/mixins';
import useMediaQuery from 'lib/useMediaQuery';
import Icon from 'components/Icon';
import {
  Button,
  Container,
  Content,
  DescriptionDesktop,
  DescriptionMobile,
  IconButton,
  TitleDesktop,
  TitleMobile,
} from './styles';
import type { Props } from './types';

const NotificationBanner: FC<Props> = ({
  body,
  buttonLabel,
  buttonTo,
  className,
  onClose,
  onClickButton,
  title,
}) => {
  const isPhoneLandscape = useMediaQuery(media.phoneLandscape);

  const showButton = buttonLabel && (!!onClickButton || !!buttonTo);

  return (
    <Container layout className={className}>
      {onClose && (
        <IconButton aria-label="Close banner" onClick={onClose}>
          <Icon name="xCircleLight" />
        </IconButton>
      )}
      <Content key={title} layout="position">
        {isPhoneLandscape ? (
          <>
            <TitleMobile as="h2">{title}</TitleMobile>
            <DescriptionMobile>{body}</DescriptionMobile>
          </>
        ) : (
          <>
            <TitleDesktop as="h2">{title}</TitleDesktop>
            <DescriptionDesktop>{body}</DescriptionDesktop>
          </>
        )}
        {showButton && (
          <Button onClick={onClickButton} to={buttonTo}>
            {buttonLabel}
          </Button>
        )}
      </Content>
    </Container>
  );
};

export default memo(NotificationBanner);
