import type { FC } from 'react';
import { AppConfig } from 'config';
import { Container, Link } from './styles';

const Links: FC = () => (
  <Container>
    <Link
      aria-label="Log in (opens in a new tab)"
      href="/"
      rel="noopener"
      target="_blank"
    >
      Log in
    </Link>
    <Link
      aria-label="FAQ + Support (opens in a new tab)"
      href={AppConfig.supportURL}
      rel="noopener"
      target="_blank"
    >
      FAQ + Support
    </Link>
    <Link
      aria-label="Privacy Policy (opens in a new tab)"
      href={AppConfig.privacyURL}
      rel="noopener"
      target="_blank"
    >
      Privacy Policy
    </Link>
    <Link
      aria-label="Terms of Service (opens in a new tab)"
      href={AppConfig.termsURL}
      rel="noopener"
      target="_blank"
    >
      Terms of Service
    </Link>
  </Container>
);

export default Links;
