/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { ZIndex } from 'styles/metrics';
import { media } from 'styles/mixins';

export const Container = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 50%;
  box-sizing: content-box;
  color: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  left: ${({ theme }) => theme.measures.x};
  line-height: 1;
  opacity: 1;
  position: absolute;
  top: ${({ theme }) => theme.measures.x};
  z-index: ${ZIndex.PLAYER_CLOSE_BUTTON};

  ${media.tabletPortrait} {
    left: ${({ theme }) => theme.measures.m};
    top: ${({ theme }) => theme.measures.l};
  }
`;
