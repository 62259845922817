import { memo } from 'react';
import type { FC } from 'react';
import {
  Container,
  Image,
  Info,
  Overline,
  Subtitle,
  Title1,
  Title2,
} from './styles';
import { Props } from './types';

const Placeholder: FC<Props> = ({ layout }) => (
  <Container>
    <Image $layout={layout} />
    <Info>
      <Overline $layout={layout} />
      <Title1 $layout={layout} />
      <Title2 $layout={layout} />
      <Subtitle />
    </Info>
  </Container>
);

export default memo(Placeholder);
