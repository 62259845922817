import type { FC } from 'react';
import { Props } from './types';

const PencilLight: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M8.68934 20.2493H4.5C4.30109 20.2493 4.11032 20.1703 3.96967 20.0296C3.82902 19.889 3.75 19.6982 3.75 19.4993V15.3099C3.75 15.2114 3.7694 15.1139 3.80709 15.0229C3.84478 14.9319 3.90003 14.8492 3.96967 14.7796L15.2197 3.52961C15.3603 3.38895 15.5511 3.30994 15.75 3.30994C15.9489 3.30994 16.1397 3.38895 16.2803 3.52961L20.4697 7.71895C20.6103 7.8596 20.6893 8.05036 20.6893 8.24928C20.6893 8.44819 20.6103 8.63895 20.4697 8.77961L9.21967 20.0296C9.15003 20.0992 9.06735 20.1545 8.97635 20.1922C8.88536 20.2299 8.78783 20.2493 8.68934 20.2493Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.75 6L18 11.25"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.95296 20.2013L3.79858 15.0469"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PencilLight;
