import styled from 'styled-components';
import MaterialSlider from '@mui/material/Slider';

export const Container = styled.div`
  left: 0;
  padding-right: 0;
  pointer-events: none;
  position: absolute;
  top: -4px;
  transform: none;
  width: 100%;
`;

export const Slider = styled(MaterialSlider)`
  &.MuiSlider-root {
    display: block;
    padding: 0;
  }

  & .MuiSlider-rail,
  & .MuiSlider-track {
    border: none;
    border-radius: 0;
    height: 4px;
  }

  & .MuiSlider-rail {
    background-color: ${({ theme }) => theme.colors.coreBlue100};
    opacity: 1;
  }

  & .MuiSlider-track {
    background-color: ${({ theme }) => theme.colors.coreBlue500};
  }

  & .MuiSlider-thumb {
    display: none;
  }
`;
