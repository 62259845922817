import useCountdown from 'lib/useCountdown';
import { formatDurationShort } from 'lib/formatTime';

const useConnect = ({ startDate }: { startDate: string }) => {
  const { countdown } = useCountdown(startDate);

  const canBegin = countdown === 0;
  const remainingText =
    countdown === 0
      ? 'now open'
      : `begins in ${formatDurationShort(countdown / 1000)}`;

  return {
    canBegin,
    remainingText,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
