import getPacks from './getPacks';

const getPack = async (key: string) => {
  const lwKey = key.toLowerCase();
  const { packs } = await getPacks();

  return packs.find(
    (pack) =>
      pack.id.toLowerCase() === lwKey || pack.hash.toLowerCase() === lwKey,
  );
};

export default getPack;
