/* eslint-disable import/prefer-default-export */

const LEGACY_SUBSCRIPTION_SOURCE_MESSAGE =
  'You have a lifetime subscription due to your support of Sam Harris prior to September 18, 2018.';

export const SUBSCRIPTION_SOURCE_MESSAGES: Record<string, string> = {
  ADMIN: 'Subscription granted by Sam Harris',
  apple: 'Subscribed via Apple',
  apple_xact: 'Subscribed via Apple',
  EFL: LEGACY_SUBSCRIPTION_SOURCE_MESSAGE,
  EMAIL: LEGACY_SUBSCRIPTION_SOURCE_MESSAGE,
  gift_card: 'Subscribed via gift or promotion',
  gpl: 'Subscribed via Google',
  lightrail: 'Subscribed via gift or promotion',
  open_access: 'Free trial',
  stripe: 'Subscribed via website',
};
