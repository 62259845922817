import { makeVar } from '@apollo/client';

export type BackgroundSounds = {
  backgroundAudioEnabled: boolean;
  backgroundAudioProgress: number;
  backgroundAudioTrackIndex: number;
  backgroundAudioVolume: number;
};

export const emptyBackgroundSounds: BackgroundSounds = {
  backgroundAudioEnabled: false,
  backgroundAudioProgress: 0,
  backgroundAudioTrackIndex: 0,
  backgroundAudioVolume: 0.5,
};

export const backgroundSoundsVar = makeVar<BackgroundSounds>(
  emptyBackgroundSounds,
);

export const resetBackgroundSoundsVar = () => {
  backgroundSoundsVar(emptyBackgroundSounds);
};

export const setBackgroundSoundsVar = (data: Partial<BackgroundSounds>) => {
  backgroundSoundsVar({ ...emptyBackgroundSounds, ...data });
};

export const updateBackgroundSoundsVar = (data: Partial<BackgroundSounds>) => {
  backgroundSoundsVar({ ...backgroundSoundsVar(), ...data });
};
