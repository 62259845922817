import { useCallback } from 'react';
import type { FC } from 'react';
import { Container, More, Text } from './styles';
import { Props } from './types';

const CompleteButton: FC<Props> = ({ onOpenEndSection, packId, section }) => {
  const handleOpen = useCallback(() => {
    onOpenEndSection(packId);
  }, [onOpenEndSection, packId]);

  return (
    <Container>
      <Text>Section {section} Complete</Text>
      <More onClick={handleOpen}>Read more</More>
    </Container>
  );
};

export default CompleteButton;
