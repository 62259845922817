/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const ClearButton = styled.button`
  background: transparent;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: block;
  line-height: 1;
  outline: none;
  width: fit-content;
`;

export const IconButton = styled(ClearButton)`
  left: 0.8rem;
  position: absolute;
  top: 1rem;
`;
