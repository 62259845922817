import { memo, type FC } from 'react';
import { Card, Container, Content, Image, Subtitle, Title } from './styles';
import type { Props } from './types';

const Placeholder: FC<Props> = ({
  className,
  imageSize,
  layout,
  nItems = 6,
}) => (
  <Container $imageSize={imageSize} $layout={layout} className={className}>
    {Array.from(Array(nItems).keys()).map((key) => (
      <Card key={key} $imageSize={imageSize} $layout={layout}>
        <Image $imageSize={imageSize} $layout={layout} />
        <Content $layout={layout}>
          <Title />
          <Subtitle />
        </Content>
      </Card>
    ))}
  </Container>
);

export default memo(Placeholder);
