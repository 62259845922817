import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.measures.x};
  margin-top: ${({ theme }) => theme.measures.x};

  ${media.phoneLandscape} {
    margin-top: ${({ theme }) => theme.measures.l};
  }
`;

export const Separator = styled.div`
  height: ${({ theme }) => theme.measures.x};

  ${media.phoneLandscape} {
    height: ${({ theme }) => theme.measures.s};
  }
`;
