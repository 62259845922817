import { memo } from 'react';
import type { FC } from 'react';
import ScholarshipModal from 'containers/Modals/Scholarship';
import StripePaymentModal from 'containers/Modals/StripePayment';
import FullScholarshipModal from 'containers/Modals/FullScholarship';
import {
  Button,
  Buttons,
  Container,
  Logo,
  NoWrap,
  Text,
  VideoPlayer,
} from './styles';
import useConnect from './connect';

const ScholarshipPage: FC = () => {
  const { handleRequestFreeAccount, handleVideoEnd, isVideoPlayed } =
    useConnect();

  return (
    <Container>
      <Logo />
      <Text>
        Listen to a brief message from <NoWrap>Sam Harris</NoWrap>, creator of
        Waking Up.
      </Text>
      <VideoPlayer onVideoEnd={handleVideoEnd} />
      <Buttons>
        <Button disabled={!isVideoPlayed} onClick={handleRequestFreeAccount}>
          Request a Scholarship
        </Button>
      </Buttons>
      <FullScholarshipModal />
      <ScholarshipModal />
      <StripePaymentModal />
    </Container>
  );
};

export default memo(ScholarshipPage);
