import { memo, useCallback } from 'react';
import type { FC } from 'react';
import { useMediaQuery } from '@mui/material';
import parseISO from 'date-fns/parseISO';
import isAfter from 'date-fns/isAfter';
import startOfDay from 'date-fns/startOfDay';
import { trackEvent } from 'services/analytics';
import formatRetreatDate from 'lib/formatRetreatDate';
import { media } from 'styles/mixins';
import Icon from 'components/Icon';
import useModalActions from 'graphql/hooks/useModalActions';
import DismissRetreat from 'containers/Modals/DismissRetreat';
import LearnMore from './LearnMore';
import Start from './Start';
import {
  Attending,
  Container,
  Content,
  ContentContainer,
  IconButton,
  Image,
  Overline,
  Row,
  Subtitle,
  Title,
} from './styles';
import type { Props } from './types';

const Retreat: FC<Props> = (retreat) => {
  const { openDismissRetreatModal } = useModalActions();
  const isPhoneLandscape = useMediaQuery(media.phoneLandscape);

  const {
    endDate,
    hash,
    imageUrl,
    startDate,
    subtitle,
    title,
    usersAttending,
  } = retreat;
  const overline = formatRetreatDate(startDate, endDate);
  const isRetreatDay = isAfter(new Date(), startOfDay(parseISO(startDate)));

  const handleClose = useCallback(() => {
    trackEvent('Home CTA Dismiss', {
      button_text: 'Learn More',
      event_hash: hash,
      message: subtitle,
      title,
    });

    openDismissRetreatModal();
  }, [hash, openDismissRetreatModal, subtitle, title]);

  const button = isRetreatDay ? (
    <Start {...retreat} />
  ) : (
    <LearnMore {...retreat} />
  );

  return (
    <Container layout>
      <IconButton aria-label="Close banner" onClick={handleClose}>
        <Icon name="xCircleLight" />
      </IconButton>
      <Content layout="position">
        <Row>
          <Image alt="" src={imageUrl} />
          <ContentContainer>
            <Overline>{overline}</Overline>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            {!isPhoneLandscape && <>{button}</>}
          </ContentContainer>
        </Row>
        {isPhoneLandscape && <>{button}</>}
        <DismissRetreat />
      </Content>
      {usersAttending >= 500 && (
        <Attending>
          {new Intl.NumberFormat('en-US').format(usersAttending)} are attending
          the retreat
        </Attending>
      )}
    </Container>
  );
};

export default memo(Retreat);
