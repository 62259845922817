/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Body } from 'components/Typography';

export const Container = styled(Body).attrs(() => ({
  $size: 'large',
}))`
  * {
    font-family: 'Inter';
    font-size: 14px;
  }

  a,
  a:hover,
  a:active,
  a:visited {
    color: inherit;
  }

  .faq {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderBase};
    margin-bottom: 40px;
    padding-bottom: 24px;
  }

  .question {
    align-items: center;
    background: transparent;
    border: none;
    box-sizing: content-box;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    outline: none;
    padding: 0;
    text-align: left;
    width: 100%;

    h2 {
      font-family: 'Inter';
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
      letter-spacing: 0.01em;
      line-height: 142.9%;
    }

    &:focus {
      outline: -webkit-focus-ring-color auto 1px;
      outline-offset: 8px;
    }
  }
  .question.active::after {
    content: ${({ theme }) =>
      `url("data:image/svg+xml;charset=utf8,%3Csvg width='13' height='14' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 8H14' stroke='${theme.colors.fontEmphasized.replace(
        '#',
        '%23',
      )}' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")`};
    margin-left: 36px;
    height: 16px;
  }
  .question:not(.active)::after {
    content: ${({ theme }) =>
      `url("data:image/svg+xml;charset=utf8,%3Csvg width='12' height='12' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 6V7H7V13H6V7H0V6H6V0H7V6H13Z' fill='${theme.colors.fontEmphasized.replace(
        '#',
        '%23',
      )}'/%3E%3C/svg%3E%0A")`};
    margin-left: 36px;
    height: 16px;
  }

  .answer {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.01em;
    line-height: 142.86%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

  .answer p {
    display: block;
    margin-block-start: 16px;
    margin-block-end: 16px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .answer p:first-child {
    margin-block-start: 16px;
  }

  .answer p:last-of-type {
    margin-block-end: 0;
  }

  .answer a,
  .answer strong,
  .answer b {
    font-weight: bold;
  }

  .answer em,
  .answer i {
    font-style: italic;
  }

  .answer ul {
    list-style-position: inside;
    margin: 0.8rem 0;
  }
`;
