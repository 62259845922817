import { ComponentType } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const commonStyles = css`
  color: ${({ theme }) => theme.colors.coreNeutralWhite};
`;

export const RouterLink = styled(Link)`
  ${commonStyles};
`;

export const Anchor = styled.a`
  ${commonStyles};
` as unknown as ComponentType;
