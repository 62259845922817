import styled from 'styled-components';
import DefaultPanels from 'components/Panels';

export const Container = styled.div``;

export const Header = styled.div`
  padding-top: 1rem;
`;

export const Overline = styled.p`
  color: ${({ theme }) => theme.colors.fontEmphasized};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.2em;
  margin-bottom: 0.325rem;
  text-transform: uppercase;
`;

export const Title = styled.h1`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 1.7rem;
  line-height: 1.075;
  letter-spacing: 0.01em;
`;

export const Subtitle = styled.p`
  font-size: 1rem;
  line-height: 1.33;
  letter-spacing: 0.01em;
  margin-top: 0.45rem;
`;

export const Panels = styled(DefaultPanels)`
  align-self: stretch;
  font-size: 0.875rem;
  margin-top: 2.5rem;
`;
