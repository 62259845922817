import styled, { css } from 'styled-components';
import { media } from 'styles/mixins';
import colorWithAlpha from 'lib/color';
import DefaultModal from 'components/Modal';
import {
  Display,
  Heading,
  Overline as DefaultOverline,
  Title2,
} from 'components/Typography';
import DefaultButton from 'components/Button';
import DefaultLink from 'components/Link';

export const Modal = styled(DefaultModal)`
  box-shadow: ${({ theme }) =>
    theme.name === 'LIGHT' ? 'none' : theme.elevation.web4};

  .MuiPaper-root {
    align-items: center;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    justify-content: center;
    padding: 6rem 2rem 5rem 2rem;
    text-align: center;
    width: 24rem;
  }

  ${media.phoneLandscape} {
    .MuiPaper-root {
      background-color: ${({ theme }) =>
        colorWithAlpha({
          color:
            theme.name === 'LIGHT'
              ? theme.colors.coreNeutralAurora
              : theme.colors.coreNeutralBlack,
          alpha: 0.85,
        })};
      padding: 0 1rem;
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;

  @media (max-height: 450px) {
    justify-content: flex-start;
    margin-top: -3rem;
  }

  ${media.phoneLandscape} {
    justify-content: center;
    margin-top: 0;
  }
`;

export const Overline = styled(DefaultOverline)`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreTeal700
      : theme.colors.coreTeal300};
`;

export const RetreatTitle = styled(Heading).attrs(() => ({
  $weight: 'bold',
}))`
  margin-top: 0.5625rem;
`;

export const Title = styled(Title2)`
  margin-top: 0.5625rem;
  text-transform: uppercase;
`;

export const Highlight = styled(Display).attrs(() => ({
  $size: 'small',
}))``;

const textStyles = css`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.01em;
  line-height: 1.4286;
`;

export const StartDate = styled.p`
  ${textStyles}
  margin-top: 1.5rem;
`;

export const Description = styled.p`
  ${textStyles}
  margin-bottom: 2.5rem;
  margin-top: 1.5rem;
  max-width: 24rem;
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  width: 100%;
`;

export const Button = styled(DefaultButton)`
  max-width: 24rem;
`;

export const Link = styled(DefaultLink)`
  ${textStyles}
  color: inherit;
  margin-top: 1.5rem;
`;
