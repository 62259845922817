import type { FC } from 'react';
import { Props } from './types';

const NumberPlaybackSpeed14xLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M4.464 8h-1.59L.894 9.254v1.5l1.831-1.149h.047V16h1.692V8zm2.91 8.102a.95.95 0 00.938-.938.949.949 0 00-.938-.93.934.934 0 100 1.868zm2.607-1.508h3.84V16h1.617v-1.406h.992v-1.356h-.992V8h-2.117l-3.34 5.262v1.332zm3.871-1.356h-2.168v-.062l2.106-3.332h.062v3.394zM19.404 10h-1.72l1.72 3-1.782 3h1.72l1.163-2.074L21.69 16h1.699l-1.785-3 1.738-3h-1.707l-1.129 2.098L19.404 10z"
      fill="currentColor"
    />
  </svg>
);

export default NumberPlaybackSpeed14xLight;
