import styled from 'styled-components';
import { Body } from 'components/Typography';
import DefaultModal from 'components/Modal';
import { prettyScrollbar } from 'styles/mixins';
import colorWithAlpha from 'lib/color';

export const Modal = styled(DefaultModal)`
  .MuiPaper-root {
    max-width: 40rem;
    width: 100%;
    padding: 0;
  }
`;

export const Title = styled(Body).attrs(() => ({
  $size: 'large',
  $weight: 'bold',
}))`
  color: ${({ theme }) => theme.colors.fontBase};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderBase};
  flex: 0 0 auto;
  padding: ${({ theme }) =>
    `${theme.measures.x} ${theme.measures.x} ${theme.measures.l} ${theme.measures.x}`};
`;

export const Content = styled.div`
  ${prettyScrollbar({ vertical: true })};
  background-color: ${({ theme }) =>
    colorWithAlpha({
      color:
        theme.name === 'LIGHT'
          ? theme.colors.coreNeutralBlack
          : theme.colors.coreNeutralWhite,
      alpha: 0.01,
    })};
  flex: 1 1 auto;
  overflow-y: auto;
  padding: ${({ theme }) => `${theme.measures.x} ${theme.measures.x}`};
`;
