import { screenSizes } from 'styles/metrics';

export const SLIDES = [
  {
    title: 'Not just another meditation app',
    description:
      'While there is nothing wrong with reducing stress and sleeping better, at Waking Up we have a very different focus.',
    image: '/images/onboarding/onboarding-slides-1.png',
  },
  {
    title: 'Unlock your mind',
    description:
      'Our goal is to fundamentally change the way our members see the world—and to improve their lives and relationships in the process',
    image: '/images/onboarding/onboarding-slides-2.png',
  },
  {
    title: 'Ancient wisdom, clarified by modern science',
    description:
      'We bring together contemporary teachers, scientists, and scholars to pressure—test traditional practices in ways that no other resource does.',
    image: '/images/onboarding/onboarding-slides-3.png',
  },
  {
    title: 'Start where you are',
    description: `Whether you're a beginner struggling with negative thoughts, or an experienced meditator ready for a profound shift in perspective.`,
    image: '/images/onboarding/onboarding-slides-4.png',
  },
];

export const NSLIDES = SLIDES.length;

export const BREAKPOINTS = {
  [`${screenSizes.tabletLandscape}`]: {
    allowTouchMove: false,
  },
};
