import type { FC } from 'react';
import Icon from 'components/Icon';
import { Container } from './styles';
import type { Props } from '../types';

const CloseButtonMinimized: FC<Props> = ({ innerRef, ...props }) => (
  <Container aria-label="close player" ref={innerRef} {...props}>
    <Icon aria-hidden="true" name="xCircleLight" />
  </Container>
);

export default CloseButtonMinimized;
