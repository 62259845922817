import { useEffect } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import type { NavigateProps } from 'react-router-dom';

const Navigate: FC<NavigateProps> = ({ replace, state, to }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to, { replace, state });
  }, [navigate, replace, state, to]);

  return null;
};

export default Navigate;
