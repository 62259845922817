import { memo, type FC } from 'react';
import OptionItem from './OptionItem';
import { Title } from '../styles';
import { Slider, SliderContainer, Volume } from './styles';
import useConnect from './connect';

const BackgroundSounds: FC = () => {
  const { handleChangeVolume, handleEnableAudio, isAudioEnable, volume } =
    useConnect();

  return (
    <>
      <Title>Background sounds</Title>
      <OptionItem
        active={isAudioEnable}
        onClick={handleEnableAudio}
        value="On"
      />
      <OptionItem
        active={!isAudioEnable}
        onClick={handleEnableAudio}
        value="Off"
      />
      <Title>Volume mix</Title>
      <SliderContainer>
        <Slider
          disabled={!isAudioEnable}
          max={50}
          min={0}
          onChange={handleChangeVolume}
          value={volume}
        />
        <Volume $disabled={!isAudioEnable}>{volume}%</Volume>
      </SliderContainer>
    </>
  );
};

export default memo(BackgroundSounds);
