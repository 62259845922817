import { Course, CourseType } from 'models/Course';
import getPackCourses from './getPackCourses';
import getSettings from './getSettings';

const getRecentlyPlayed = async (courseType?: CourseType) => {
  const { packCoursesWithPrimaryPackObj } = await getPackCourses();
  const { recentlyPlayed } = await getSettings();

  return recentlyPlayed
    .filter(({ course_hash }) => {
      const course = packCoursesWithPrimaryPackObj[course_hash];
      return !!course && !course.isIntroCourse;
    })
    .slice(0, 8)
    .reduce<Course[]>((t, { course_hash, last_known_location }) => {
      const course = packCoursesWithPrimaryPackObj[course_hash];
      if (!courseType || course.courseType === courseType) {
        t.push({ ...course, progress: last_known_location });
      }
      return t;
    }, []);
};

export default getRecentlyPlayed;
