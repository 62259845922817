import { memo, type FC } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { ClearButton, Icon, TextField } from './styles';
import useLogic from './logic';
import type { Props } from './types';

const Search: FC<Props> = ({
  className,
  debounce,
  id,
  onChange,
  onClear,
  placeholder,
  value,
}) => {
  const { localOnChange, localValue } = useLogic({
    debounce,
    onChange,
    value,
  });

  const startAdornment = (
    <InputAdornment position="start">
      <Icon aria-hidden="true" name="search" size={20} />
    </InputAdornment>
  );

  const endAdornment = (
    <ClearButton aria-label="Clear field" type="button" onClick={onClear}>
      <Icon aria-hidden="true" name="xCircleLight" size={20} />
    </ClearButton>
  );

  return (
    <TextField
      $hasIcon
      className={className}
      hiddenLabel
      id={id}
      InputProps={{
        endAdornment: debounce
          ? localValue && endAdornment
          : value && endAdornment,
        startAdornment,
      }}
      onChange={debounce ? localOnChange : onChange}
      placeholder={placeholder || 'Search...'}
      size="small"
      type="text"
      value={debounce ? localValue : value}
      variant="filled"
    />
  );
};

export default memo(Search);
