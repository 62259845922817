import { memo } from 'react';
import type { FC } from 'react';
import { Button, Description, List, ListItem, Modal, Title } from './styles';
import useConnect from './connect';

const OnboardingOpenAccess: FC = () => {
  const { description, handleClose, isOpen, nDays } = useConnect();

  return (
    <Modal hideClose onClose={handleClose} open={isOpen}>
      <Title>Welcome! Your {nDays}-day trial starts now.</Title>
      <List>
        <ListItem>Completely free for {nDays} days.</ListItem>
        <ListItem>Enjoy unlimited access during your trial.</ListItem>
        <ListItem>
          No need to cancel. You will not be charged unless you complete
          subscription checkout.
        </ListItem>
      </List>
      <Description>{description}</Description>
      <Button onClick={handleClose}>OK</Button>
    </Modal>
  );
};

export default memo(OnboardingOpenAccess);
