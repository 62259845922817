import type { FC } from 'react';
import Icon from 'components/Icon';
import { Title } from '../styles';
import { Header, IconButton, TextField } from './styles';
import type { Props } from './types';

const Message: FC<Props> = ({ onClose, titleId }) => (
  <>
    <Header>
      <IconButton aria-label="Go back" onClick={onClose} type="button">
        <Icon name="arrowLeftLight" />
      </IconButton>
      <Title id={titleId}>Contact Us</Title>
    </Header>
    <TextField label="subject" maxLength={100} name="subject" />
    <TextField label="message" maxLength={500} multiline name="message" />
  </>
);

export default Message;
