import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { media } from 'styles/mixins';
import DefaultButtonIcon from 'components/ButtonIcon';
import DefaultButtonPlay from 'components/ButtonPlay';
import { Props } from '../types';

export const Container = styled.div`
  align-items: center;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: 1.6rem;
`;

export const ButtonPlayContainer = styled.div`
  flex: 0 0 auto;
  height: 4rem;
  position: relative;
  width: 4rem;
`;

export const ButtonPlay = styled(DefaultButtonPlay)<{
  $isLoading: Props['isLoading'];
}>`
  height: 100%;
  pointer-events: ${({ $isLoading }) => ($isLoading ? 'none' : 'default')};
  width: 100%;
`;

export const Loading = styled(CircularProgress).attrs(() => ({
  color: 'inherit',
  size: '100%',
  thickness: 1,
}))`
  color: ${({ theme }) => theme.colors.fontEmphasized};
  left: 0;
  position: absolute;
  top: 0;
`;

const ButtonIcon = styled(DefaultButtonIcon)<{
  $isLoading: Props['isLoading'];
}>`
  height: 3rem;
  pointer-events: ${({ $isLoading }) => ($isLoading ? 'none' : 'default')};
  width: 3rem;
`;

export const JumpBackButton = styled(ButtonIcon)`
  margin-right: 4rem;

  ${media.phonePortrait} {
    margin-right: 2.6rem;
  }
`;

export const JumpForwardButton = styled(ButtonIcon)`
  margin-left: 4rem;

  ${media.phonePortrait} {
    margin-left: 2.6rem;
  }
`;
