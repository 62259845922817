import { memo, type FC } from 'react';
import createPersonsLine from 'lib/createPersonsLine';
import { Container, Credits, Image } from './styles';
import type { Props } from './types';

const Artwork: FC<Props> = ({ artists, imageUrl }) => (
  <Container>
    {imageUrl && <Image alt="" src={imageUrl} />}
    {artists.length > 0 && (
      <Credits>Artwork {createPersonsLine(artists)}</Credits>
    )}
  </Container>
);

export default memo(Artwork);
