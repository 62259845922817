import styled, { css } from 'styled-components';
import DefaultLink from 'components/Link';
import colorWithAlpha from 'lib/color';
import { blink, media } from 'styles/mixins';
import DefaultImage from 'components/Image';
import DefaultIcon from 'components/Icon';
import {
  Body as DefaultBody,
  Overline as DefaultOverline,
  Title2 as DefaultTitle2,
} from 'components/Typography';
import { Props } from './types';

const containerStyles = css`
  background: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralWhite
      : colorWithAlpha({
          color: theme.colors.coreNeutralBlack,
          alpha: 0.25,
        })};
  border: none;
  border-radius: ${({ theme }) => theme.radius.md};
  color: inherit;
  cursor: pointer;
  display: block;
  height: 100%;
  padding: ${({ theme }) => theme.measures.xl};
  position: relative;
  text-align: unset;
  text-decoration: none;
  transition: background 100ms ease;
  width: 100%;

  :active {
    background: ${({ theme }) =>
      theme.name === 'LIGHT'
        ? colorWithAlpha({
            color: theme.colors.coreNeutralWhite,
            alpha: 0.5,
          })
        : colorWithAlpha({
            color: theme.colors.coreNeutralBlack,
            alpha: 0.6,
          })};
  }

  ${media.phoneLandscape} {
    background: ${({ theme }) =>
      theme.name === 'LIGHT'
        ? colorWithAlpha({
            color: theme.colors.coreNeutralWhite,
            alpha: 0.6,
          })
        : colorWithAlpha({
            color: theme.colors.coreNeutralBlack,
            alpha: 0.25,
          })};
    box-shadow: ${({ theme }) =>
      theme.name === 'LIGHT' ? 'none' : theme.elevation.web4};
  }
`;

const containerHorizontalStyles = css`
  height: auto;
  padding: ${({ theme }) =>
    `${theme.measures.l} 0 ${theme.measures.l} ${theme.measures.m}`};
`;

export const Container = styled.div<{ $layout: Props['layout'] }>`
  ${containerStyles}
  ${({ $layout }) => $layout === 'horizontal' && containerHorizontalStyles}
`;

export const ContainerLink = styled(DefaultLink)<{ $layout: Props['layout'] }>`
  ${containerStyles}
  ${({ $layout }) => $layout === 'horizontal' && containerHorizontalStyles}
`;

export const ContainerButton = styled.button<{ $layout: Props['layout'] }>`
  ${containerStyles}
  ${({ $layout }) => $layout === 'horizontal' && containerHorizontalStyles}
`;

export const Image = styled(DefaultImage)<{ $layout: Props['layout'] }>`
  display: block;
  width: 8.75rem;
  margin-bottom: ${({ theme }) => theme.measures.x};

  ${({ $layout }) =>
    $layout === 'horizontal' &&
    css`
      margin-bottom: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    `}
`;

export const Info = styled.div`
  min-width: 0;
  position: relative;
  z-index: 1;
`;

export const Overline = styled(DefaultOverline)<{ $layout: Props['layout'] }>`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutral600
      : theme.colors.coreNeutral100};
  margin-bottom: ${({ theme }) => theme.measures.xs};

  ${({ $layout }) =>
    $layout === 'horizontal' &&
    css`
      margin-bottom: ${({ theme }) => theme.measures.xxs};
    `}
`;

export const Title = styled(DefaultTitle2)<{ $layout: Props['layout'] }>`
  font-size: 4.25rem;
  margin-bottom: ${({ theme }) => theme.measures.xxs};
  max-width: 12.75rem;

  ${media.tabletLandscape} {
    font-size: 3.8rem;
  }

  ${media.tabletPortrait} {
    font-size: 4.25rem;
  }

  ${({ $layout }) =>
    $layout === 'horizontal' &&
    css`
      font-size: 3rem;

      ${media.tabletLandscape} {
        font-size: 3rem;
      }

      ${media.tabletPortrait} {
        font-size: 3rem;
      }
    `}
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
`;

export const Play = styled(DefaultIcon)`
  color: ${({ theme }) => theme.colors.fontEmphasized};
  margin-right: ${({ theme }) => theme.measures.xxs};
`;

export const Lock = styled(DefaultIcon).attrs(() => ({ size: 24 }))`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutral600
      : theme.colors.coreNeutral100};
  margin-right: ${({ theme }) => theme.measures.xxs};
`;

export const Subtitle = styled(DefaultBody).attrs(() => ({
  $size: 'small',
  $weight: 'regular',
}))`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutral600
      : theme.colors.coreNeutral100};
`;

export const Placeholder = styled.div`
  animation: ${blink} 3s linear infinite;
  background: ${({ theme }) =>
    colorWithAlpha({
      color: theme.colors.fontDeemphasized,
      alpha: 0.8,
    })};
  border-radius: ${({ theme }) => theme.radius.md};
  min-height: 11rem;
  height: 100%;
`;
