import type { FC } from 'react';
import { Props } from './types';

const GearLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M12 16.5a4.5 4.5 0 100-9 4.5 4.5 0 000 9z"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.507 12.128l.003.151-1.422 1.896a.625.625 0 00-.104.538c.231.857.572 1.68 1.015 2.45a.62.62 0 00.453.308l2.34.334c.13.14.264.274.403.404l.334 2.338c.027.19.14.358.307.453.77.443 1.593.785 2.45 1.017.186.05.385.012.539-.104l1.89-1.418c.19.007.38.007.57 0l1.89 1.417a.625.625 0 00.538.104 10.315 10.315 0 002.45-1.014.625.625 0 00.308-.454l.335-2.343a14.367 14.367 0 00.395-.399l2.346-.335a.625.625 0 00.453-.307 10.32 10.32 0 001.017-2.45.625.625 0 00-.104-.538l-1.42-1.894a14.72 14.72 0 00-.003-.56l1.422-1.897a.625.625 0 00.104-.538 10.315 10.315 0 00-1.014-2.45.625.625 0 00-.454-.308l-2.339-.334a8.595 8.595 0 00-.404-.404l-.334-2.338A.625.625 0 0017.164 3a10.317 10.317 0 00-2.45-1.016.625.625 0 00-.538.103l-1.89 1.418a8.553 8.553 0 00-.572 0L9.825 2.088a.625.625 0 00-.538-.104c-.857.231-1.68.572-2.45 1.015a.625.625 0 00-.308.453l-.334 2.34a8.55 8.55 0 00-.404.403l-2.338.334A.625.625 0 003 6.836c-.443.77-.785 1.593-1.016 2.45a.625.625 0 00.103.539l1.42 1.893c-.002.139-.001.286 0 .41z"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default GearLight;
