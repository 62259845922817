import { useMemo, useCallback } from 'react';
import {
  MAX_DURATION_MINS,
  SELF_TIMER_INTERVALS,
  getValidIntervalTimes,
} from 'models/SelfTimerInterval';
import useSettingsActions from 'graphql/hooks/useSettingsActions';
import useModalActions from 'graphql/hooks/useModalActions';
import usePlayerActions from 'graphql/hooks/usePlayerActions';
import { Props } from './types';

const useConnect = ({
  duration,
  intervalTime,
  setDuration,
  setIsTimeSectionOpen,
}: {
  duration: Props['duration'];
  intervalTime: Props['intervalTime'];
  setDuration: Props['setDuration'];
  setIsTimeSectionOpen: Props['setIsTimeSectionOpen'];
}) => {
  const { updateSettings } = useSettingsActions();
  const { closeModal } = useModalActions();
  const { openSelfTimerPlayer } = usePlayerActions();

  const { validIntervalTime, validIntervalTimeText } = useMemo(() => {
    const _validIntervalTime = getValidIntervalTimes(duration, intervalTime);
    return {
      validIntervalTime: _validIntervalTime,
      validIntervalTimeText: SELF_TIMER_INTERVALS[_validIntervalTime].text,
    };
  }, [duration, intervalTime]);

  const begin = useCallback(async () => {
    closeModal();
    updateSettings({
      lastTimerInterval: validIntervalTime,
      lastTimerDuration: duration,
    });

    // We need to allow time for Material-ui to end its animation
    setTimeout(() => {
      openSelfTimerPlayer({
        duration: duration * 60,
        interval: validIntervalTime,
      });
    }, 250);
  }, [
    closeModal,
    updateSettings,
    duration,
    validIntervalTime,
    openSelfTimerPlayer,
  ]);

  const decrementDuration = useCallback(() => {
    setDuration((value) => (value > 1 ? value - 1 : value));
  }, [setDuration]);

  const incrementDuration = useCallback(() => {
    setDuration((value) => (value < MAX_DURATION_MINS ? value + 1 : value));
  }, [setDuration]);

  const toogleTimeSection = useCallback(() => {
    setIsTimeSectionOpen((v) => !v);
  }, [setIsTimeSectionOpen]);

  return {
    duration,
    begin,
    closeModal: close,
    decrementDuration,
    incrementDuration,
    setDuration,
    toogleTimeSection,
    validIntervalTimeText,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
