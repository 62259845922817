import { apolloClient } from 'services/api';
import { normalizeAvatar } from 'models/Avatar';
import { AvatarsDocument } from '../generated';

const getAvatars = async () => {
  const { data } = await apolloClient.query({
    query: AvatarsDocument,
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
  });

  return data?.avatars ? data.avatars.map(normalizeAvatar) : [];
};

export default getAvatars;
