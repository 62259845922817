import {
  type KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'styled-components';
import { updateSettings } from 'graphql/requests';
import {
  backgroundSoundsVar,
  optionsMenuVar,
  resetOptionsMenuVar,
} from 'graphql/reactive';
import { MENU_WIDTH } from './constants';

const useConnect = () => {
  const optionsMenu = useReactiveVar(optionsMenuVar);
  const { backgroundAudioEnabled, backgroundAudioVolume } =
    useReactiveVar(backgroundSoundsVar);
  const theme = useTheme();
  const menuListRef = useRef<HTMLUListElement>(null!);

  const anchorEl = optionsMenu?.anchorEl;
  const type = optionsMenu?.type;
  const activeColor =
    theme.name === 'LIGHT'
      ? theme.colors.coreBlue500
      : theme.colors.coreTeal500;

  const { menuWidth, offsetMenu, placementMenu } = useMemo(() => {
    if (optionsMenu) {
      return {
        menuWidth: MENU_WIDTH[optionsMenu.type],
        offsetMenu: {
          offset:
            optionsMenu.type === 'backgroundSounds' ||
            optionsMenu.type === 'coursePlayer' ||
            optionsMenu.type === 'dailyPlayer' ||
            optionsMenu.type === 'speed' ||
            optionsMenu.type === 'autoplay'
              ? optionsMenu.offset
              : 0,
        },
        placementMenu:
          optionsMenu.type === 'backgroundSounds' ||
          optionsMenu.type === 'coursePlayer' ||
          optionsMenu.type === 'dailyPlayer' ||
          optionsMenu.type === 'speed' ||
          optionsMenu.type === 'autoplay'
            ? optionsMenu.placement
            : 'left-start',
      };
    }
    return {};
  }, [optionsMenu]);

  useEffect(() => {
    if (anchorEl) {
      anchorEl.style.color = activeColor;
      anchorEl.setAttribute('aria-describedby', 'options-menu');
      return () => {
        anchorEl.style.color = '';
      };
    }
  }, [activeColor, anchorEl]);

  useEffect(() => {
    if (optionsMenu) {
      menuListRef.current?.focus();
    }
  }, [optionsMenu]);

  useEffect(() => {
    window.addEventListener('scroll', resetOptionsMenuVar, { passive: true });
    return () => window.removeEventListener('scroll', resetOptionsMenuVar);
  }, []);

  const handleClickAway = useCallback(() => {
    if (type === 'backgroundSounds') {
      updateSettings(
        {
          backgroundAudioEnabled,
          backgroundAudioVolumeWeb: backgroundAudioVolume,
        },
        true,
      );
    }
    resetOptionsMenuVar();
  }, [backgroundAudioEnabled, backgroundAudioVolume, type]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLUListElement>) => {
      if (anchorEl) {
        e.preventDefault();
        if (e.key === 'Tab') {
          anchorEl?.focus();
          resetOptionsMenuVar();
        } else if (e.key === 'Escape') {
          resetOptionsMenuVar();
        }
      }
    },
    [anchorEl],
  );

  return {
    handleClickAway,
    handleKeyDown,
    menuListRef,
    menuWidth,
    offsetMenu,
    optionsMenu,
    placementMenu,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
