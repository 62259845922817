import {
  number as yupNumber,
  object as yupObject,
  string as yupString,
} from 'yup';

export const HEAR_OPTIONS = [
  { label: 'Making Sense Podcast' },
  { label: 'FB/IG/Twitter' },
  { label: 'Google Search' },
  { label: 'YouTube' },
  { label: 'Other Podcast' },
  { label: 'Friend (Personal)' },
  { label: 'Friend (Work)' },
  { label: 'Colleague' },
  { label: 'Conference' },
  { label: 'Other' },
].map((x) => ({
  label: x.label,
  value: x.label,
}));

export const validationSchema = yupObject({
  email: yupString().max(150).email('Invalid email address'),
  firstName: yupString().max(150).required('The first name must not be empty'),
  lastName: yupString().max(150).required('The last name must not be empty'),
  hear: yupString().required('Please, choose one of the options'),
  hearOtherInfo: yupString()
    .max(250)
    .test(
      'required-if-hear-other-selected',
      'This field is required',
      function hearOtherSelected(value) {
        return ['Other', 'Other Podcast'].includes(this.parent.hear)
          ? !!value
          : true;
      },
    ),
  price: yupNumber().required('Please, choose one of the options'),
});
