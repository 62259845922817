import { useCallback } from 'react';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import { ModalType, setShowTrialBannerVar } from 'graphql/reactive';
import { updateSettings } from 'graphql/requests';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import useMe from 'graphql/hooks/useMe';

const useConnect = () => {
  const { isOpen } = useModal(ModalType.ONBOARDING_OPEN_ACCESS);
  const { closeModal } = useModalActions();
  const {
    profile: { subscriptionSubtype },
  } = useMe();

  const nDays = subscriptionSubtype === 'SHARE' ? 30 : 7;
  const endDate = addDays(new Date(), nDays);
  const description = `Your trial ends ${format(
    endDate,
    'EEEE M/dd',
  )} at 11:59PM.`;

  const handleClose = useCallback(async () => {
    closeModal();
    await updateSettings({ hasPresentedHome: true }, true);
    setShowTrialBannerVar(true);
  }, [closeModal]);

  return { description, handleClose, isOpen, nDays };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
