import styled from 'styled-components';
import { media } from 'styles/mixins';
import { HEADER_HEIGHT } from 'styles/metrics';
import DefaultSearch from 'components/Inputs/Search';

export const Container = styled.div`
  margin-bottom: 0.2rem;

  &::after {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderBase};
    content: '';
    display: block;
  }

  ${media.tabletPortrait} {
    background-color: ${({ theme }) => theme.colors.backgroundBase};
    position: sticky;
    top: calc(${HEADER_HEIGHT} + 3rem);
    z-index: 7;

    &::after {
      margin-left: 50%;
      transform: translateX(-50vw);
      width: 100vw;
    }
  }
`;

export const Label = styled.label``;

export const Search = styled(DefaultSearch)`
  width: 100%;

  & input[type='search'] {
    appearance: none;
  }

  &&& {
    .MuiFilledInput-underline:before {
      border-bottom: none !important;
    }
  }
`;
