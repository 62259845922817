import { memo } from 'react';
import type { FC } from 'react';
import { Formik } from 'formik';
import { Container, ContinueButton, RadioGroup } from './styles';
import { validationSchema } from './constants';
import type { Props } from './types';

const ScholarshipForm: FC<Props> = ({ onSubmit, options, loading }) => (
  <Formik
    initialValues={{
      scholarshipOption: options?.[0]?.value,
    }}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ values: { scholarshipOption } }) => {
      const disabledButton = !scholarshipOption;
      return (
        <Container id="scholarship-form">
          <RadioGroup
            options={options}
            name="scholarshipOption"
            id="scholarshipOption"
          />
          <ContinueButton
            disabled={disabledButton}
            isLoading={loading}
            type="submit"
          >
            Continue
          </ContinueButton>
        </Container>
      );
    }}
  </Formik>
);

export default memo(ScholarshipForm);
