import { useMemo } from 'react';
import flatMap from 'lodash/flatMap';
import type { Course } from 'models/Course';
import usePacks from './usePacks';

const usePackCourses = () => {
  const { packs, loading: isLoadingPacks } = usePacks();

  const { packCourses, packCoursesWithPrimaryPackObj } = useMemo(() => {
    const allPackCourses =
      packs.length > 0 ? flatMap(packs, ({ courses }) => courses) : [];

    return {
      packCourses: allPackCourses,
      packCoursesWithPrimaryPackObj: allPackCourses.reduce<
        Record<string, Course>
      >((t, v) => {
        if (v.packIsPrimaryPack) t[v.hash] = v;
        return t;
      }, {}),
    };
  }, [packs]);

  return {
    loading: isLoadingPacks,
    packCourses,
    packCoursesWithPrimaryPackObj,
  };
};

export default usePackCourses;
export type UseCourses = ReturnType<typeof usePackCourses>;
