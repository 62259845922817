import styled from 'styled-components';
import DefaultImage from 'components/Image';
import colorWithAlpha from 'lib/color';

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralWhite
      : colorWithAlpha({
          color: theme.colors.coreNeutralBlack,
          alpha: 0.25,
        })};
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? 'none'
      : '0px 111px 85px rgba(0, 0, 0, 0.11), 0px 71.9444px 49.7801px rgba(0, 0, 0, 0.0835185), 0px 42.7556px 27.0741px rgba(0, 0, 0, 0.0668148), 0px 22.2px 13.8125px rgba(0, 0, 0, 0.055), 0px 9.04444px 6.92593px rgba(0, 0, 0, 0.0431852), 0px 2.05556px 3.34491px rgba(0, 0, 0, 0.0264815)'};
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 2rem;
  max-width: 21.375rem;
  padding: 1.5625rem 1.375rem 1.8013rem;
  text-align: center;
  width: 100%;
`;

export const Image = styled(DefaultImage)`
  border-radius: 0.25rem;
  box-shadow: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? 'none'
      : '17.3946px 41.7075px 51.9417px rgba(0, 0, 0, 0.115977), 10.4143px 24.9707px 29.6583px rgba(0, 0, 0, 0.0978192), 5.45406px 13.0773px 16.4152px rgba(0, 0, 0, 0.0821808), 2.24447px 5.38162px 9.11953px rgba(0, 0, 0, 0.0640233), 0.516228px 1.23777px 4.67872px rgba(0, 0, 0, 0.038309)'};
  display: block;
  margin-bottom: 1.5625rem;
  width: 15.1875rem;
`;

export const Overline = styled.p`
  color: ${({ theme }) => theme.colors.fontEmphasized};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.2em;
  margin-bottom: 0.325rem;
  text-transform: uppercase;
`;

export const Title = styled.h1`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 1.7rem;
  line-height: 1.075;
  letter-spacing: 0.01em;
`;

export const Subtitle = styled.p`
  font-size: 1rem;
  line-height: 1.33;
  letter-spacing: 0.01em;
  margin-top: 0.45rem;
`;
