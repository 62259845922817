import { makeVar } from '@apollo/client';
import { PlayerSource } from 'models/Player';

export type PreviewPlayer = {
  hash: string;
  packId?: string;
  queryString?: string;
  showFinalScreen: boolean;
  source: PlayerSource;
};

export const emptyPreviewPlayer: PreviewPlayer = {
  hash: '',
  packId: '',
  showFinalScreen: false,
  source: PlayerSource.DEEPLINK,
};

export const previewPlayerVar = makeVar<PreviewPlayer>(emptyPreviewPlayer);

export const resetPreviewPlayerVar = () => {
  previewPlayerVar(emptyPreviewPlayer);
};

export const setPreviewPlayerVar = (data: Partial<PreviewPlayer>) => {
  previewPlayerVar({ ...emptyPreviewPlayer, ...data });
};

export const updatePreviewPlayerVar = (data: Partial<PreviewPlayer>) => {
  previewPlayerVar({ ...previewPlayerVar(), ...data });
};
