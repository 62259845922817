import { memo, type FC } from 'react';
import DropdownIndicator from './DropdownIndicator';
import { Container, Error, ReactSelect, Wrapper } from './styles';
import useLogic from './logic';
import type { Props } from './types';

const Select: FC<Props> = ({
  className,
  hideSeparator = false,
  iconName,
  id,
  isClearable = false,
  isSearchable = false,
  label,
  name,
  options,
  placeholder,
  readOnly = false,
}) => {
  const { handleChange, error, showError, value } = useLogic({ name, options });

  return (
    <Container className={className}>
      <Wrapper id={id || name} label={label} readOnly={readOnly}>
        <ReactSelect
          $hasLabel={!!label}
          $hideSeparator={hideSeparator}
          classNamePrefix="react-select"
          components={iconName ? { DropdownIndicator } : {}}
          // @ts-ignore
          iconName={iconName}
          isClearable={isClearable}
          isSearchable={isSearchable}
          options={options}
          onChange={handleChange}
          placeholder={placeholder}
          inputId={id}
          value={value}
        />
      </Wrapper>
      {showError && <Error>{error}</Error>}
    </Container>
  );
};

export default memo(Select);
