import type { FC } from 'react';
import { RetreatCard, Container, Subtitle, Title } from './styles';
import type { Props } from './types';

const Calendar: FC<Props> = ({
  className,
  descriptionId,
  formattedDate,
  subtitle,
  title,
  titleId,
}) => (
  <Container className={className}>
    <Title id={titleId}>Welcome! You've signed up.</Title>
    <Subtitle id={descriptionId}>
      We'll send you more information about the retreat soon.
      <br />
      <br /> Please note: If you're in a free trial and it ends before the
      retreat date, you must become a subscriber to attend.
    </Subtitle>
    <RetreatCard
      formattedDate={formattedDate}
      subtitle={subtitle}
      title={title}
    />
  </Container>
);

export default Calendar;
