import type { FC } from 'react';
import { Props } from './types';

const ArrowCounterClockwiseLight15s: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M7 9.349H3.485V5.5"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.668 17.834a8.25 8.25 0 100-11.668L3.486 9.348"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.218 14.8h-.988v-3.808l-1.179.366v-.803l2.061-.738h.106V14.8zm1.767-2.447l.288-2.53h2.788v.824h-1.979l-.123 1.07c.235-.125.485-.188.749-.188.474 0 .845.147 1.114.44.27.295.404.706.404 1.235 0 .321-.069.61-.206.864-.134.254-.328.45-.58.592a1.833 1.833 0 01-.896.208c-.3 0-.58-.06-.838-.18a1.511 1.511 0 01-.611-.517 1.41 1.41 0 01-.236-.755h.977c.02.207.093.369.216.485a.697.697 0 00.489.171c.223 0 .395-.08.516-.24.12-.161.18-.389.18-.683 0-.282-.069-.499-.208-.65-.139-.15-.336-.225-.591-.225-.235 0-.425.062-.57.185l-.097.088-.786-.194z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowCounterClockwiseLight15s;
