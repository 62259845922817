import styled, { css } from 'styled-components';
import DefaultIcon from 'components/Icon';

export const Button = styled.button<{ $selected: boolean }>`
  background-color: ${({ theme }) => theme.colors.coreNeutralWhite};
  border-radius: 0.25rem;
  border: 1px solid #215adb;
  cursor: pointer;
  min-width: max-content;
  outline: none;
  padding: 1rem 1.25rem;
  position: relative;
  text-decoration: none;
  transition: background-color 200ms ease;

  &:focus {
    box-shadow: 0 0 0 2px #f4f4f4, 0 0 0 3.5px rgba(0, 96, 103, 0.25);
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.coreNeutral50};
    border-color: transparent;
    color: ${({ theme }) => theme.colors.coreNeutral400};
  }

  ${({ $selected, theme }) =>
    $selected &&
    css`
      background-color: #215adb;
      color: ${theme.colors.coreNeutralWhite};
    `}
`;

export const Icon = styled(DefaultIcon).attrs(() => ({
  size: 20,
}))`
  color: #215adb;
  display: block;
  left: 0.5rem;
  position: absolute;
  top: 0.5rem;
`;

export const Price = styled.div`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 1.1875;
`;

export const Description = styled.div`
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 1.25;
  margin-top: 0.1875rem;
`;
