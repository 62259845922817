import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { trackEvent } from 'services/analytics';
import { PurchaseGiftCardDocument, GiftCardType } from '../generated';

const useGiftCardsActions = () => {
  const [purchaseGiftCardMutation, { loading: isPurchasing }] = useMutation(
    PurchaseGiftCardDocument,
  );

  const purchaseGiftCard = useCallback(
    async ({
      message,
      paymentMethodId,
      recipientEmail,
      recipientName,
      senderEmail,
      senderName,
      shouldBeSentAt,
      type,
    }: {
      message: string;
      paymentMethodId: string;
      recipientEmail: string;
      recipientName: string;
      senderEmail: string;
      senderName: string;
      shouldBeSentAt?: Date;
      type: GiftCardType;
    }) => {
      const { data } = await purchaseGiftCardMutation({
        variables: {
          data: {
            message,
            payment_method_id: paymentMethodId,
            recipient_email: recipientEmail,
            recipient_name: recipientName,
            sender_email: senderEmail,
            sender_name: senderName,
            should_be_sent_at: shouldBeSentAt,
            type,
          },
        },
      });

      trackEvent('Purchase Gift Card Completed', {
        recipient_email: recipientEmail,
        sender_email: senderEmail,
        sender_name: senderName,
        type,
      });

      return data?.purchaseGiftCard;
    },
    [purchaseGiftCardMutation],
  );

  return {
    isPurchasing,
    purchaseGiftCard,
  };
};

export default useGiftCardsActions;
export type UseGiftCardsActions = ReturnType<typeof useGiftCardsActions>;
