import type { FC } from 'react';
import { Props } from './types';

const LockSimpleLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M19.501 8.25h-15a.75.75 0 00-.75.75v10.5c0 .414.336.75.75.75h15a.75.75 0 00.75-.75V9a.75.75 0 00-.75-.75zm-10.875 0V4.875a3.375 3.375 0 116.75 0V8.25"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LockSimpleLight;
