import type { FC } from 'react';
import { isProduction } from 'config';
import LiveAudio from './LiveAudio';
import UpNext from './Widgets/UpNext';
import Cta from './Widgets/Cta';
import Spotlight from './Widgets/Spotlight';
import ContinueListening from './Widgets/ContinueListening';
import Carousel from './Widgets/Carousel';
import Image from './Widgets/Image';
import { Container, Content, PresetWidgets } from './styles';
import useConnect from './connect';

const Home: FC = () => {
  const {
    ctaWidget,
    customWidgets,
    isPhoneLandscape,
    spotlightWidget,
    upNextWidget,
  } = useConnect();

  return (
    <Container>
      <Content>
        {!isProduction && <LiveAudio />}
        <PresetWidgets>
          <UpNext widgetId={upNextWidget?.id} />
          {isPhoneLandscape && <Cta widgetId={ctaWidget?.id} />}
          <Spotlight widgetId={spotlightWidget?.id} />
        </PresetWidgets>
        {!isPhoneLandscape && <Cta widgetId={ctaWidget?.id} />}
        {customWidgets.map((widget) => {
          if (widget.typename === 'WidgetContinueListening') {
            return <ContinueListening key={widget.id} {...widget} />;
          }
          if (widget.typename === 'WidgetCarousel') {
            return <Carousel key={widget.id} {...widget} />;
          }
          if (widget.typename === 'WidgetImage') {
            return <Image key={widget.id} {...widget} />;
          }
          return null;
        })}
      </Content>
    </Container>
  );
};

export default Home;
