import { FC } from 'react';
import { Label, Container } from './styles';
import { Props } from './types';

const Wrapper: FC<Props> = ({
  children,
  className,
  id,
  label,
  readOnly = false,
}) => (
  <Container $hasLabel={!!label} $readOnly={readOnly} className={className}>
    {label && <Label htmlFor={id}>{label}</Label>}
    {children}
  </Container>
);

export default Wrapper;
