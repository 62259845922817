import { memo } from 'react';
import type { FC } from 'react';
import Placeholder from './Placeholder';
import {
  Container,
  Description,
  Footer,
  Image,
  Info,
  InfoAndProgress,
  Overline,
  PlayWithProgress,
  Subtitle,
  Title,
} from './styles';
import type { Props } from './types';

const CardPrimary: FC<Props> = ({
  boldSubtitle,
  className,
  description,
  hideImageLoader,
  imageAlt = '',
  imageUrl,
  isLoading,
  onClick,
  overline,
  progress,
  subtitle,
  title,
}) => {
  if (isLoading) {
    return <Placeholder className={className} />;
  }

  const showProgress = !Number.isNaN(Number(progress));

  return (
    <Container
      $hasNoImage={!imageUrl}
      as={onClick ? 'button' : undefined}
      className={className}
      onClick={onClick}
    >
      {imageUrl && (
        <Image alt={imageAlt} hideLoader={hideImageLoader} src={imageUrl} />
      )}
      <Footer>
        <InfoAndProgress>
          <Info>
            {overline && <Overline>{overline}</Overline>}
            {title && <Title as="h3">{title}</Title>}
            {subtitle && <Subtitle $bold={boldSubtitle}>{subtitle}</Subtitle>}
          </Info>
          {showProgress && <PlayWithProgress progress={progress || 0} />}
        </InfoAndProgress>
        {description && <Description>{description}</Description>}
      </Footer>
    </Container>
  );
};

export default memo(CardPrimary);
