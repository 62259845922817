/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { AUDIO_DATA_FRAGMENT } from './audio';

export const DAILY_DATA_FRAGMENT = gql`
  fragment DailyData on Daily {
    __typename
    bookmarked_at
    daily_audio {
      audio1 {
        ...AudioData
      }
      audio2 {
        ...AudioData
      }
      id
      is_guest
      subtitle
    }
    date
    hash
    id
    image {
      background_color
      id
      thumbnails {
        thumb_x1
        thumb_x2
      }
      url
      thumbnails {
        thumb_x1
        thumb_x2
      }
    }
    is_bookmark
    is_special
    status
  }
  ${AUDIO_DATA_FRAGMENT}
`;
