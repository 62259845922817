import { screenSizes } from 'styles/metrics';

export const SLIDES = [
  {
    title: 'Prepare for your retreat.',
    description:
      'Turn off notifications. Make sure your phone is fully charged or plugged in.',
  },
  {
    title: 'Make yourself comfortable.',
    description: 'Eat a light snack. Grab some water. Use the bathroom.',
  },
  {
    title: 'Grab a notebook and a pen.',
    description:
      'So you can capture any questions you have for Sam during the live Q&A.',
  },
  {
    title: 'Maintain silence.',
    description:
      'Bring your full attention to the retreat—abstain from talking, reading, working, or any other communication. And enjoy.',
  },
];

export const STEPS = SLIDES.length;

export const BREAKPOINTS = {
  [`${screenSizes.tabletLandscape}`]: {
    allowTouchMove: false,
  },
};
