import { useReactiveVar } from '@apollo/client';
import {
  ModalType,
  modalVar,
  type ModalDialog,
  type ModalGiveFreeTrial,
  type ModalIntroCourseSectionCompleted,
  type ModalShareApp,
  type ModalShareLink,
  type ModalStripePayment,
  type ModalUnlockFreeMonth,
  type ModalUnlockFreeMonthSuccess,
  type ModalUpgradeToAnnual,
} from '../reactive';

function useModal(
  modalType:
    | ModalType.ADD_FAMILY_MEMBER
    | ModalType.DAILY_DURATION
    | ModalType.DISMISS_RETREAT
    | ModalType.END_RETREAT
    | ModalType.FULL_SCHOLARSHIP
    | ModalType.JOIN_RETREAT
    | ModalType.LEAVE_RETREAT
    | ModalType.ONBOARDING_EXPERIENCE
    | ModalType.ONBOARDING_OPEN_ACCESS
    | ModalType.ONBOARDING_SLIDES
    | ModalType.RETREAT_LIVE_MEETING
    | ModalType.RESET_CONTENT_MENU
    | ModalType.RESERVE_RETREAT
    | ModalType.SCHOLARSHIP
    | ModalType.SELF_TIMER_CONFIGURATION
    | ModalType.SEND_EMAIL
    | ModalType.SUPPORT,
): {
  isOpen: boolean;
};

function useModal(modalType: ModalType.DIALOG): {
  isOpen: boolean;
  payload?: ModalDialog['payload'];
};

function useModal(modalType: ModalType.GIVE_FREE_TRIAL): {
  isOpen: boolean;
  payload?: ModalGiveFreeTrial['payload'];
};

function useModal(modalType: ModalType.INTRO_COURSE_SECTION_COMPLETED): {
  isOpen: boolean;
  payload?: ModalIntroCourseSectionCompleted['payload'];
};

function useModal(modalType: ModalType.SHARE_APP): {
  isOpen: boolean;
  payload?: ModalShareApp['payload'];
};

function useModal(modalType: ModalType.SHARE_LINK): {
  isOpen: boolean;
  payload?: ModalShareLink['payload'];
};

function useModal(modalType: ModalType.STRIPE_PAYMENT): {
  isOpen: boolean;
  payload?: ModalStripePayment['payload'];
};

function useModal(modalType: ModalType.UNLOCK_FREE_MONTH): {
  isOpen: boolean;
  payload?: ModalUnlockFreeMonth['payload'];
};

function useModal(modalType: ModalType.UNLOCK_FREE_MONTH_SUCCESS): {
  isOpen: boolean;
  payload?: ModalUnlockFreeMonthSuccess['payload'];
};

function useModal(modalType: ModalType.UPGRADE_TO_ANNUAL): {
  isOpen: boolean;
  payload?: ModalUpgradeToAnnual['payload'];
};

function useModal(modalType: ModalType): any {
  const modal = useReactiveVar(modalVar);

  const isOpen = modal?.type === modalType;

  return {
    isOpen,
    payload: isOpen && 'payload' in modal ? modal.payload : undefined,
  };
}

export default useModal;
export type UseModal = ReturnType<typeof useModal>;
