import styled from 'styled-components';
import { motion } from 'framer-motion';
import DefaultNavTabList from 'components/NavTabList';

export const Container = styled.div``;

export const Tabs = styled(DefaultNavTabList)`
  font-size: inherit;

  * {
    font-size: inherit;
  }
`;

export const Content = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.6 },
}))`
  font-size: inherit;
`;

export const Html = styled.div`
  font-size: inherit;

  * {
    font-size: inherit;
  }

  a,
  a:hover,
  a:active,
  a:visited {
    color: inherit;
  }

  p {
    display: block;
    margin-block-start: 1.5rem;
    margin-block-end: 1.5rem;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  strong,
  b {
    font-weight: bold;
  }

  em,
  i {
    font-style: italic;
  }

  ul {
    list-style-position: inside;
    margin: 0.8rem 0;
  }
`;
