import { useReactiveVar } from '@apollo/client';
import {
  audioDurationVar,
  audioIsLoadingVar,
  audioIsPlayingVar,
  audioPositionVar,
  audioSpeedVar,
} from 'graphql/reactive';

export const useAudio = () => {
  const audioIsLoading = useReactiveVar(audioIsLoadingVar);
  const audioIsPlaying = useReactiveVar(audioIsPlayingVar);
  const audioDuration = useReactiveVar(audioDurationVar);
  const audioPosition = useReactiveVar(audioPositionVar);
  const audioSpeed = useReactiveVar(audioSpeedVar);

  return {
    audioDuration,
    audioIsLoading,
    audioIsPlaying,
    audioPosition,
    audioSpeed,
  };
};

export const useAudioIsLoading = () => {
  const isLoading = useReactiveVar(audioIsLoadingVar);
  return isLoading;
};

export const useAudioIsPlaying = () => {
  const isPlaying = useReactiveVar(audioIsPlayingVar);
  return isPlaying;
};

export const useAudioDuration = () => {
  const duration = useReactiveVar(audioDurationVar);
  return duration;
};

export const useAudioPosition = () => {
  const position = useReactiveVar(audioPositionVar);
  return position;
};

export const useAudioSpeed = () => {
  const speed = useReactiveVar(audioSpeedVar);
  return speed;
};
