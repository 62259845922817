import styled, { css } from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { screenSizes } from 'styles/metrics';
import { media } from 'styles/mixins';
import colorWithAlpha from 'lib/color';
import DefaultSwitch from 'components/Switch';
import DefaultUserInfo from 'components/UserInfo';
import DefaultUserStats from 'components/UserStats';
import DefaultLogoutLink from 'components/Links/LogoutLink';
import InputListItemLarge from 'components/InputListItemLarge';
import { Body, NavLabel } from 'components/Typography';

export const Content = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: 3.75rem;
  width: 100%;

  ${media.tabletLandscape} {
    margin-top: 1.25rem;
  }
`;

export const Loader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 2rem 0;
`;

export const ProgressText = styled.div`
  font-weight: bold;
  font-size: 1rem;
  padding-top: 0.6rem;
  text-align: center;
`;

export const Progress = styled(CircularProgress).attrs(() => ({
  size: 50,
}))``;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 600px;

  ${media.tabletPortrait} {
    width: 100vw;
  }
`;

export const UserInfo = styled(DefaultUserInfo)`
  margin-top: ${({ theme }) => theme.measures.l};

  ${media.phonePortrait} {
    width: 100%;
    padding-left: ${({ theme }) => theme.measures.l};
  }
`;

export const UserStats = styled(DefaultUserStats)`
  margin-top: 2.25rem;
  max-width: 400px;
  width: 100%;

  ${media.phonePortrait} {
    max-width: 100%;
    padding: 0 ${({ theme }) => theme.measures.m};
  }
`;

export const Links = styled.div`
  margin-top: 3rem;
  width: 100%;
`;

const fullWidthStyles = css`
  @media (min-width: ${screenSizes.tabletPortrait}px) {
    background-color: transparent;
  }

  ${media.tabletPortrait} {
    width: 100vw;
  }
`;

export const GroupHeader = styled.h2`
  background-color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.015 })
      : colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.1 })};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.borderBase}`};
  color: ${({ theme }) => theme.colors.fontEmphasized};
  padding: 1.875rem ${({ theme }) => theme.measures.l};

  ${NavLabel}
  ${fullWidthStyles}
`;

export const LogoutLink = styled(DefaultLogoutLink)`
  margin: 3.75rem 0 3rem 0;
`;

export const ItemLarge = styled(InputListItemLarge)`
  ${fullWidthStyles}
`;

export const SwitchThemeButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.015 })
      : colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.1 })};
  border: none;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.borderBase}`};
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  cursor: pointer;
  display: flex;
  min-width: max-content;
  padding: 1.375rem ${({ theme }) => theme.measures.l};
  justify-content: space-between;
  text-decoration: none;
  width: 100%;

  &:disabled {
    color: ${({ theme }) =>
      colorWithAlpha({
        color:
          theme.name === 'LIGHT'
            ? theme.colors.coreNeutralBlack
            : theme.colors.coreNeutralWhite,
        alpha: 0.33,
      })};
  }

  &:not(:disabled):hover {
    background-color: ${({ theme }) =>
      colorWithAlpha({
        color: theme.colors.coreNeutralWhite,
        alpha: theme.name === 'LIGHT' ? 0.5 : 0.05,
      })};
  }

  &:not(:disabled):active {
    background-color: ${({ theme }) =>
      colorWithAlpha({
        color: theme.colors.coreNeutralWhite,
        alpha: theme.name === 'LIGHT' ? 1 : 0.15,
      })};
  }

  &:focus {
    color: ${({ theme }) => theme.colors.fontEmphasized};

    &:hover {
      background-color: ${({ theme }) =>
        theme.name === 'LIGHT' &&
        colorWithAlpha({
          color: theme.colors.coreNeutralBlack,
          alpha: 0.05,
        })};
    }

    &:active {
      background-color: ${({ theme }) =>
        theme.name === 'LIGHT' && theme.colors.coreTeal50};
    }
  }

  ${fullWidthStyles}
`;

export const SwitchText = styled(Body)``;

export const Switch = styled(DefaultSwitch)``;
