import type { FeatureContentDataFragment } from 'graphql/generated';

export const normalizeFeatureContent = (
  featuredContent: FeatureContentDataFragment,
) => ({
  deepLink: (featuredContent.deep_link || '').replace(
    /(https|wakingup):\/\/(app|dynamic)(\.dev|-dev|\.test|-test|\.staging|-staging)?\.wakingup\.com\//,
    '/',
  ),
  hash: featuredContent.hash || null,
  imageUrl: featuredContent.image_url || '',
  isUnlocked: featuredContent.is_unlocked || false,
  length: featuredContent.length || 0,
  packType: featuredContent.pack_type || null,
  preTitle: featuredContent.pre_title || '',
  title: featuredContent.title || '',
  subtitle: featuredContent.subtitle || '',
});

export type FeatureContent = ReturnType<typeof normalizeFeatureContent>;
