import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse } from 'query-string';
import { getTrialDays } from 'models/TrialUser';
import useSubscriptionPlans from 'graphql/hooks/useSubscriptionPlans';
import useTrialUser from 'graphql/hooks/useTrialUser';

const useConnect = () => {
  const { search } = useLocation();
  const { uuid } = parse(search);
  const userUuid = uuid && typeof uuid === 'string' ? uuid : '';

  const { loading: loadingSubscriptionPlans, plans } = useSubscriptionPlans({
    skip: !userUuid,
  });
  const {
    loading: loadingTrialUser,
    trialUser,
    error: trialUserError,
  } = useTrialUser({ skip: !userUuid, variables: { uuid: userUuid } });
  const navigate = useNavigate();

  const email = trialUser?.email || '';
  const trialDuration = trialUser?.trial_duration;
  const trialDays = getTrialDays(trialUser?.trial_duration);

  const yearlyPlan = plans.find(({ interval }) => interval === 'year');
  const planAmount = yearlyPlan?.amount || 0;
  const planInterval = yearlyPlan?.interval || '';
  const planId = yearlyPlan?.id || '';

  useEffect(() => {
    if (!userUuid || trialUserError) {
      navigate('/checkout', { replace: true });
    }
  }, [navigate, trialUserError, userUuid]);

  return {
    email,
    isLoading: loadingSubscriptionPlans || loadingTrialUser,
    planAmount,
    planId,
    planInterval,
    trialDays,
    trialDuration,
    trialUserError,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
