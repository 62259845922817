import styled from 'styled-components';
import { Form as DefaultForm } from 'formik';
import { motion } from 'framer-motion';
import { CardElement as StripeCardElement } from '@stripe/react-stripe-js';
import { CARD_ELEMENT_OPTIONS } from './constants';

export const FormikForm = styled(DefaultForm)`
  padding-bottom: 0.6056rem;
`;

export const CardElement = styled(StripeCardElement).attrs(() => ({
  options: CARD_ELEMENT_OPTIONS,
}))`
  &.StripeElement {
    background-color: ${({ theme }) => theme.colors.coreNeutralWhite};
    border: 1px solid transparent;
    border-radius: ${({ theme }) => theme.radius.sm};
    box-shadow: 0 1px 3px 0 #e6ebf1;
    box-sizing: border-box;
    padding: 0.8rem 0.75rem;
    transition: box-shadow 150ms ease;
  }

  &.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  &.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
`;

export const ErrorAndActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 0.375rem;
  padding-right: 0.25rem;
`;

export const Error = styled(motion.div).attrs(() => ({
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  transition: { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] },
}))`
  color: ${({ theme }) => theme.colors.fontLabelError};
  font-size: 0.75rem;
  letter-spacing: 0.01rem;
  line-height: 1.33;
  margin-right: 1rem;
  padding-left: 0.25rem;
  width: 100%;
`;

export const Button = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.coreNeutralBlack};
  cursor: pointer;
  font-size: 0.78rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  height: 1.5625rem;
  letter-spacing: -0.04em;
  line-height: 1.97;
  opacity: 0.7;
  text-align: center;
  text-decoration-line: underline;
  text-transform: uppercase;
  max-width: 5.375rem;

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  &:not(:last-of-type) {
    margin-right: 1rem;
  }
`;

export const SaveButton = styled(Button)`
  color: ${({ theme }) => theme.colors.coreBlue500};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  opacity: 1;
`;
