import type { FC } from 'react';
import type { Props } from './types';

const StarActive: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 17 17"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M8.412.252l1.956 6.023h6.334l-5.124 3.722 1.957 6.023-5.123-3.722-5.124 3.722 1.957-6.023L.121 6.275h6.334L8.412.252z"
      fill="currentColor"
    />
  </svg>
);

export default StarActive;
