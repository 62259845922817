/* eslint-disable import/prefer-default-export */
import { ApolloError } from '@apollo/client';

export const formatErrorMessage = (
  error?: ApolloError,
  defaultMessage: string = 'Something went wrong!',
) => {
  if (error?.networkError) {
    return 'The server is not available';
  }

  return error?.graphQLErrors?.[0].message || defaultMessage;
};
