import {
  useCallback,
  useEffect,
  useMemo,
  type KeyboardEvent,
  type MouseEvent,
} from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import kebabCase from 'lodash/kebabCase';
import getCourseProgressPercent from 'lib/getCourseProgressPercent';
import { PlayerAutoplayType } from 'models/Player';
import { PackType } from 'graphql/generated';
import { setOptionsMenuVar } from 'graphql/reactive';
import useCourseActions from 'graphql/hooks/useCourseActions';
import usePacks from 'graphql/hooks/usePacks';
import useRefreshSettingsEffect from 'graphql/hooks/useRefreshSettingsEffect';
import useRemoteConfig from 'graphql/hooks/useRemoteConfig';
import useSettings from 'graphql/hooks/useSettings';
import { trackEvent } from 'services/analytics';

const useConnect = () => {
  const {
    loading: isLoadingSettings,
    settings: { recentlyPlayed },
  } = useSettings();
  const { packs, loading: isLoadingPacks } = usePacks();
  const { remoteConfig } = useRemoteConfig();
  const { play } = useCourseActions();
  const { pathname } = useLocation();
  const { pack, subpack, subpack2 } = useParams();
  const navigate = useNavigate();

  const packParam = pack;
  const subpackParam = subpack2 || subpack;
  const titleDescription = remoteConfig?.sectionLifeDescription;

  const { defaultPackTitle, theoryPacks } = useMemo(() => {
    const lifePacksList = packs.filter((p) => p.type === PackType.life);

    return {
      defaultPackTitle:
        lifePacksList?.filter((p) => !p.parentPackId)?.[0]?.title || '',
      theoryPacks: lifePacksList,
    };
  }, [packs]);

  useRefreshSettingsEffect();

  useEffect(() => {
    if (!packParam && defaultPackTitle) {
      navigate(`/life/${kebabCase(defaultPackTitle)}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPackTitle, packParam]);

  useEffect(() => {
    trackEvent('Selected Life Tab');
  }, []);

  const {
    backUrl,
    filteredPacks,
    selectedPackCategory,
    selectedPackCourses,
    selectedPackDescription,
    selectedPackDisplayCourseImages,
    selectedPackId,
    selectedPackImageUrl,
    selectedPackParentPackId,
    selectedPackSubpacks,
    selectedPackSubtitle,
    selectedPackTitle,
  } = useMemo(() => {
    const selectedPack = theoryPacks.find(({ title }) =>
      subpackParam
        ? kebabCase(title) === subpackParam
        : kebabCase(title) === packParam,
    );

    const filteredPacksList = subpackParam
      ? theoryPacks.filter((p) => p.parentPackId === selectedPack?.parentPackId)
      : theoryPacks.filter((p) => !p.parentPackId);

    const subpacks = selectedPack?.nSubpacks
      ? theoryPacks.filter(
          ({ parentPackId }) =>
            parentPackId && parentPackId === selectedPack.id,
        )
      : [];

    const coursesWithProgress = selectedPack?.courses
      ? selectedPack.courses.map((c) => ({
          ...c,
          progress: getCourseProgressPercent({
            hash: c.hash,
            audioLength: c.audioLength,
            recentlyPlayed,
            status: c.status,
          }),
        }))
      : [];

    return {
      backUrl: subpackParam
        ? `${selectedPack?.urlBase}/${packParam}`
        : undefined,
      filteredPacks: filteredPacksList,
      selectedPackCategory: selectedPack?.category,
      selectedPackCourses: coursesWithProgress,
      selectedPackDescription: selectedPack?.description,
      selectedPackDisplayCourseImages: selectedPack?.displayCourseImages,
      selectedPackId: selectedPack?.id,
      selectedPackImageUrl: selectedPack?.imageUrl,
      selectedPackParentPackId: selectedPack?.parentPackId,
      selectedPackSubpacks: subpacks,
      selectedPackSubtitle: selectedPack?.subtitle,
      selectedPackTitle: selectedPack?.title,
    };
  }, [packParam, recentlyPlayed, subpackParam, theoryPacks]);

  const handlePlayCourse = useCallback(
    async (courseId: string, packId: string) => {
      await play({ courseId, autoplayType: PlayerAutoplayType.PACK, packId });
    },
    [play],
  );

  const handleOpenCourseOptionsMenu = useCallback(
    (
      event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLButtonElement>,
      courseId: string,
      packId: string,
    ) => {
      event.stopPropagation();
      setOptionsMenuVar({
        anchorEl: event.currentTarget,
        id: courseId,
        packId,
        type: 'course',
      });
    },
    [],
  );

  const handleOpenPackOptionsMenu = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (selectedPackId) {
        event.stopPropagation();
        setOptionsMenuVar({
          anchorEl: event.currentTarget,
          id: selectedPackId,
          type: 'pack',
        });
      }
    },
    [selectedPackId],
  );

  return {
    backUrl,
    currentUrl: pathname,
    handleOpenCourseOptionsMenu,
    handleOpenPackOptionsMenu,
    handlePlayCourse,
    isLoading: isLoadingSettings || isLoadingPacks,
    isSubpack: !!subpackParam,
    packs: filteredPacks,
    selectedPackCategory,
    selectedPackCourses,
    selectedPackDescription,
    selectedPackDisplayCourseImages,
    selectedPackId,
    selectedPackImageUrl,
    selectedPackParentPackId,
    selectedPackSubpacks,
    selectedPackSubtitle,
    selectedPackTitle,
    titleDescription,
  };
};

export default useConnect;
export type UseConnectReturnType = ReturnType<typeof useConnect>;
