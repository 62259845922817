import type { FC } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { stringify } from 'query-string';
import { GiftCardType } from 'graphql/generated';
import Navigate from 'components/Navigate';
import Trial from 'containers/Trial';
import NewScholarship from 'containers/NewScholarship';
import Scholarship from './Scholarship';
import SignUp from './Auth/SignUp';
import SignIn from './Auth/SignIn';
import MagicLink from './Auth/MagicLink';
import MagicLinkLoginSuccess from './Auth/MagicLinkLoginSuccess';
import MagicLinkLoginExpired from './Auth/MagicLinkLoginExpired';
import Welcome from './Auth/Welcome';
import Subscription from './Subscription';
import SubscriptionSuccess from './Subscriptions/SubscriptionSuccess';
import SubscriptionCheckoutSessionSuccess from './Subscriptions/SubscriptionCheckoutSessionSuccess';
import Redeem from './Subscriptions/Redeem';
import Payments from './Subscriptions/Payments';
import RedeemSuccess from './Subscriptions/RedeemSuccess';
import GiftCardPurchase from './GiftCardPurchase';
import HolidayGiftCardPurchase from './HolidayGiftCardPurchase';
import JoinNow from './JoinNow';

const Guest: FC = () => {
  const { pathname, search } = useLocation();

  return (
    <Routes>
      <Route element={<SignIn />} path="/" />
      <Route element={<Scholarship />} path="/scholarship" />
      <Route element={<SignUp queryString="" token="" />} path="/sign-up" />
      <Route element={<SignUp queryString="" token="" />} path="/sign_up" />
      <Route element={<SignIn />} path="/guest" />
      <Route element={<MagicLink />} path="/magic-link/:token" />
      <Route
        element={<MagicLinkLoginSuccess />}
        path="/magic-link-login-success"
      />
      <Route
        element={<MagicLinkLoginExpired />}
        path="/magic-link-login-expired"
      />
      <Route element={<Welcome />} path="/magic-link-welcome" />
      <Route path="/checkout">
        <Route element={<Subscription />} index />
        <Route element={<Subscription />} path=":code" />
      </Route>
      <Route element={<JoinNow />} path="/joinnow" />
      <Route element={<GiftCardPurchase />} path="/purchase-gift-card" />
      <Route element={<Trial />} path="/free-trial" />
      <Route
        element={<NewScholarship isPublic />}
        path="/scholarship-options"
      />
      <Route
        element={<HolidayGiftCardPurchase code={GiftCardType.ONE_YEAR30} />}
        path="/holiday-gift-card"
      />
      <Route element={<SubscriptionSuccess />} path="/subscription/:status" />
      <Route
        element={<SubscriptionCheckoutSessionSuccess />}
        path="/checkout-success"
      />
      <Route element={<Redeem />} path="/gifts/redeem" />
      <Route element={<Redeem />} path="/promotions/redeem" />
      <Route element={<Payments />} path="/payments" />
      <Route element={<RedeemSuccess />} path="/redeem/success" />
      <Route
        element={
          <Navigate
            replace
            to={{ pathname: '/scholarship', search: location.search }}
          />
        }
        path="/free-account"
      />
      <Route
        element={
          <Navigate
            replace
            to={{ pathname: '/request-scholarship', search: location.search }}
          />
        }
        path="/request-free-account"
      />
      <Route
        element={
          <Navigate
            replace
            to={{
              pathname: '/scholarship',
              search: location.search
                ? `${location.search}&options=1`
                : '?options=1',
            }}
          />
        }
        path="/request-scholarship"
      />
      <Route
        element={
          <Navigate
            replace
            to={{ pathname: '/checkout', search: location.search }}
          />
        }
        path="/account/subscription"
      />
      <Route
        element={
          <Navigate
            replace
            to={{ pathname: '/checkout', search: location.search }}
          />
        }
        path="/membership"
      />
      <Route
        element={
          <Navigate
            replace
            to={{ pathname: '/checkout', search: location.search }}
          />
        }
        path="/subscription"
      />
      <Route
        element={
          <Navigate
            replace
            to={{ pathname: '/checkout', search: location.search }}
          />
        }
        path="/openview/subscriptions"
      />
      <Route
        element={<Navigate replace to="/purchase-gift-card" />}
        path="/gifts"
      />
      <Route element={<Navigate replace to="/" />} path="/visitor" />
      <Route element={<Navigate replace to="/" />} path="/login" />
      <Route
        element={
          <Navigate
            replace
            to={{
              pathname: '/',
              search: stringify({
                return_to:
                  pathname === '/' ? undefined : `${pathname}${search}`,
              }),
            }}
          />
        }
        path="*"
      />
    </Routes>
  );
};

export default Guest;
