import { FC } from 'react';
import { Formik } from 'formik';
import {
  Container,
  Content,
  Form,
  HeadPickerLink,
  SaveLink,
  SaveLinkContainer,
  SectionHeader,
  TextField,
} from './styles';
import useConnect from './connect';

const AccountEditPage: FC = () => {
  const { backHref, initialValues, isLoading, submit, validationSchema } =
    useConnect();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={submit}
    >
      {({ handleSubmit, values }) => (
        <Container>
          <SectionHeader
            actionOnClick={handleSubmit}
            actionTitle="Save"
            id="edit-profile-save-button"
            backHref={backHref}
            testId="account-header"
            title="Cancel"
          />
          <Content>
            <Form>
              <HeadPickerLink
                avatarId={values.avatarId}
                avatarAlt={values.avatarAlt}
                avatarUrl={values.avatarUrl}
                city={values.city}
                email={values.email}
                firstName={values.firstName}
                isLoading={isLoading}
                lastName={values.lastName}
              />
              <TextField
                data-cypress-id="account-edit-first-name"
                data-jest-id="first-name"
                id="firstName"
                label="First Name"
                maxLength={150}
                name="firstName"
              />
              <TextField
                data-cypress-id="account-edit-last-name"
                data-jest-id="last-name"
                id="lastName"
                label="Last Name"
                maxLength={150}
                name="lastName"
              />
              <TextField
                data-cypress-id="account-edit-city"
                data-jest-id="city"
                id="city"
                label="City"
                maxLength={150}
                name="city"
              />
              <TextField
                data-cypress-id="account-edit-email"
                data-jest-id="email"
                label="Email"
                maxLength={150}
                name="email"
                placeholder="Email"
                readOnly
              />
            </Form>
          </Content>
          <SaveLinkContainer>
            <SaveLink title="Save" onClick={handleSubmit} />
          </SaveLinkContainer>
        </Container>
      )}
    </Formik>
  );
};

export default AccountEditPage;
