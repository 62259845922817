import { gql } from '@apollo/client';
import { IMAGE_DATA_FRAGMENT } from './image';
import { PERSON_INFO_DATA_FRAGMENT } from './person';

export const COURSE_INFO_DATA_FRAGMENT = gql`
  fragment CourseInfoData on CourseInfo {
    __typename
    course_hash
    id
    is_primary_pack
  }
`;

export const PACK_DATA_FRAGMENT = gql`
  fragment PackData on Pack {
    __typename
    active
    auto_play
    category_v2
    courses_info {
      ...CourseInfoData
    }
    description
    display_bylines
    display_content_count
    display_course_images
    display_title
    end_message
    has_new_courses_until
    hash
    id
    image {
      ...ImageData
    }
    info
    is_intro
    is_new_until
    overwrite_course_image
    pack_number
    parent_pack_id
    persons_info {
      ...PersonInfoData
    }
    publish_date
    subtitle
    summary
    title
    type
  }
  ${COURSE_INFO_DATA_FRAGMENT}
  ${IMAGE_DATA_FRAGMENT}
  ${PERSON_INFO_DATA_FRAGMENT}
`;
