import type { FC } from 'react';
import { Props } from './types';

const PlayCircleBold: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    viewBox="0 0 24 24"
    {...rest}
  >
    <path
      d="M20 12a8 8 0 11-16 0 8 8 0 0116 0z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.376 12.416a.5.5 0 000-.832l-3.099-2.066a.5.5 0 00-.777.416v4.132a.5.5 0 00.777.416l3.099-2.066z"
      fill="currentColor"
    />
  </svg>
);

export default PlayCircleBold;
