import { useReactiveVar } from '@apollo/client';
import { AnimatePresence } from 'framer-motion';
import { PlayerType, playerTypeVar } from 'graphql/reactive';
import Portal from 'components/Portal';
import CoursePlayer from './Course';
import PreviewPlayer from './Preview';
import DailyPlayer from './Daily';
import RetreatPlayer from './Retreat';
import SelfTimerPlayer from './SelfTimer';

const Player = () => {
  const playerType = useReactiveVar(playerTypeVar);

  return (
    <Portal>
      <AnimatePresence>
        {playerType !== null && (
          <>
            {playerType === PlayerType.COURSE && <CoursePlayer />}
            {playerType === PlayerType.PREVIEW && <PreviewPlayer />}
            {playerType === PlayerType.DAILY && <DailyPlayer />}
            {playerType === PlayerType.RETREAT && <RetreatPlayer />}
            {playerType === PlayerType.SELF_TIMER && <SelfTimerPlayer />}
          </>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default Player;
