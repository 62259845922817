import styled, { css } from 'styled-components';
import colorWithAlpha from 'lib/color';
import { media } from 'styles/mixins';
import { Body as DefaultBody, Heading } from 'components/Typography';
import DefaultPlayWithProgress from 'components/PlayWithProgress';

export const Container = styled.li<{ $isButton: boolean }>`
  align-items: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.measures.s} ${theme.measures.l}`};

  &:not(:last-child) {
    border-bottom: 1px solid
      ${({ theme }) =>
        colorWithAlpha({
          color:
            theme.name === 'LIGHT'
              ? theme.colors.coreNeutralBlack
              : theme.colors.coreNeutralWhite,
          alpha: theme.name === 'LIGHT' ? 0.1 : 0.25,
        })};
  }

  ${media.tabletPortrait} {
    padding: ${({ theme }) => `${theme.measures.s} ${theme.measures.m}`};
  }

  ${({ $isButton }) =>
    $isButton &&
    css`
      background: transparent;
      border: none;
      text-align: start;
      width: 100%;
    `}
`;

export const Info = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`;

export const Pretitle = styled(Heading).attrs(() => ({ $weight: 'bold' }))``;

export const Title = styled(DefaultBody)`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutral600
      : theme.colors.coreNeutral200};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Duration = styled(DefaultBody)`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutral600
      : theme.colors.coreNeutral200};
  margin-left: ${({ theme }) => theme.measures.m};
`;

export const PlayWithProgress = styled(DefaultPlayWithProgress)`
  flex: 0 0 auto;
  margin-left: ${({ theme }) => theme.measures.xs};
`;
