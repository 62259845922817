import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { normalizeStripeFamilyMember } from 'models/StripeFamilyMember';
import { GetFamilyMembersDocument } from '../generated';

const useFamilyMembers = () => {
  const { data, loading } = useQuery(GetFamilyMembersDocument);

  const familyMembers = useMemo(
    () => data?.familyMembers.map(normalizeStripeFamilyMember) ?? [],
    [data],
  );

  return {
    familyMembers,
    loading,
  };
};

export default useFamilyMembers;
export type UseFamilyMembers = ReturnType<typeof useFamilyMembers>;
