import { useCallback } from 'react';
import { resetOptionsMenuVar } from 'graphql/reactive';
import { updateSettings } from 'graphql/requests';
import useSettings from 'graphql/hooks/useSettings';

const useConnect = () => {
  const {
    settings: { autoplay },
  } = useSettings();

  const handleEnableAutoplay = useCallback(async () => {
    updateSettings({ autoplay: true }, true);
    resetOptionsMenuVar();
  }, []);

  const handleDisableAutoplay = useCallback(async () => {
    updateSettings({ autoplay: false }, true);
    resetOptionsMenuVar();
  }, []);

  return { autoplay, handleDisableAutoplay, handleEnableAutoplay };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
