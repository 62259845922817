import { gql } from '@apollo/client';
import {
  LIFETIME_PRICE_DATA_FRAGMENT,
  STRIPE_FAMILY_MEMBER_DATA_FRAGMENT,
  STRIPE_PAYMENT_METHOD_FRAGMENT,
  SUBSCRIPTION_PLAN_DATA_FRAGMENT,
} from '../fragments';

export const GET_SUBSCRIPTION_PLANS = gql`
  query GetSubscriptionPlans($code: String, $price_id: String) {
    subscriptionPlans(code: $code, price_id: $price_id) {
      ...SubscriptionPlanData
    }
  }
  ${SUBSCRIPTION_PLAN_DATA_FRAGMENT}
`;

export const GET_SCHOLARSHIP_PRICES = gql`
  query ScholarshipPrices {
    scholarshipPrices {
      ...SubscriptionPlanData
    }
  }
  ${SUBSCRIPTION_PLAN_DATA_FRAGMENT}
`;

export const GET_LIFETIME_PRICE = gql`
  query GetLifetimePrice($id: String, $price_id: String) {
    lifetimePrice(id: $id, price_id: $price_id) {
      ...LifetimePriceData
    }
  }
  ${LIFETIME_PRICE_DATA_FRAGMENT}
`;

export const GET_STRIPE_PAYMENT_METHOD = gql`
  query GetStripePaymentMethod {
    getStripePaymentMethod {
      ...StripePaymentMethodData
    }
  }
  ${STRIPE_PAYMENT_METHOD_FRAGMENT}
`;

export const GET_FAMILY_MEMBERS = gql`
  query GetFamilyMembers {
    familyMembers {
      ...StripeFamilyMemberData
    }
  }
  ${STRIPE_FAMILY_MEMBER_DATA_FRAGMENT}
`;
