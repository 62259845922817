import { useMemo } from 'react';
import { useQuery, type QueryHookOptions } from '@apollo/client';
import { getDailyPermission, normalizeDaily } from 'models/Daily';
import { normalizeDailyPack, getDailyPackPermission } from 'models/Pack';
import getCourseProgressPercent from 'lib/getCourseProgressPercent';
import {
  GetDailyDocument,
  type GetDailyQuery,
  type GetDailyQueryVariables,
} from '../generated';
import useSettings from './useSettings';
import useMe from './useMe';

const useDaily = (
  options: QueryHookOptions<GetDailyQuery, GetDailyQueryVariables> = {},
) => {
  const { isDailyUnlocked, isSubscribed } = useMe();
  const {
    loading: isLoadingSettings,
    settings: { recentlyPlayed },
  } = useSettings();

  const { data, loading: isLoadingDaily } = useQuery(GetDailyDocument, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    ...options,
  });

  const { dailyMeditation, dailyPack } = useMemo(() => {
    if (!data?.daily) {
      return { dailyMeditation: undefined, dailyPack: undefined };
    }

    const daily = normalizeDaily(data.daily);
    daily.permission = getDailyPermission(isSubscribed, isDailyUnlocked);

    daily.progress = getCourseProgressPercent({
      hash: daily.hash,
      audioLength: daily.dailyAudio1Length,
      recentlyPlayed,
      status: daily.status,
    });

    const pack = normalizeDailyPack(daily);
    pack.permission = getDailyPackPermission(isSubscribed, isDailyUnlocked);

    return { dailyMeditation: daily, dailyPack: pack };
  }, [data, isDailyUnlocked, isSubscribed, recentlyPlayed]);

  return {
    daily: dailyMeditation,
    dailyPack,
    loading: !data && (isLoadingDaily || isLoadingSettings),
  };
};

export default useDaily;
export type UseDaily = ReturnType<typeof useDaily>;
