import { useMemo } from 'react';
import { useQuery, type QueryHookOptions } from '@apollo/client';
import { normalizeFeatureContent } from 'models/FeatureContent';
import {
  GetFeatureContentDocument,
  type GetFeatureContentQuery,
  type GetFeatureContentQueryVariables,
} from '../generated';

const useFeatureContent = (
  options: QueryHookOptions<
    GetFeatureContentQuery,
    GetFeatureContentQueryVariables
  > = {},
) => {
  const { data, loading } = useQuery(GetFeatureContentDocument, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  const featureContent = useMemo(
    () =>
      data?.feature_content
        ? normalizeFeatureContent(data.feature_content)
        : undefined,
    [data],
  );

  return {
    featureContent,
    loading: !data && loading,
  };
};

export default useFeatureContent;
export type UseFeatureContent = ReturnType<typeof useFeatureContent>;
