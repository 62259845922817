/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Container = styled.div`
  ${media.tabletPortrait} {
    margin-left: -1.4rem;
    margin-right: -1.4rem;
  }

  ${media.phonePortrait} {
    margin-left: -1rem;
    margin-right: -1rem;
  }
`;
