import type { FC } from 'react';
import { AnimatePresence } from 'framer-motion';
import PracticeBanner from 'components/PracticeBanner';
import PracticeBannerBlur from '../PracticeBannerBlur';
import { Container } from './styles';
import type { Props } from './types';
import useConnect from './connect';

const Daily: FC<Props> = ({ widgetId }) => {
  const {
    bgImageUrl,
    daily,
    handleOpenDaily,
    imageUrl,
    isAvailable,
    isLoading,
    isPhoneLandscape,
    nowPracticing,
    today,
  } = useConnect({ widgetId });

  return (
    <AnimatePresence initial={false}>
      {isLoading || daily ? (
        <Container>
          {isPhoneLandscape && (
            <AnimatePresence>
              {bgImageUrl && <PracticeBannerBlur imageUrl={bgImageUrl} />}
            </AnimatePresence>
          )}
          <PracticeBanner
            imageUrl={imageUrl}
            isLoading={isLoading}
            locked={!isAvailable}
            onClick={handleOpenDaily}
            overline={nowPracticing}
            subtitle={today}
            title="YOUR DAILY MEDITATION"
            layout={isPhoneLandscape ? 'horizontal' : 'vertical'}
          />
        </Container>
      ) : null}
    </AnimatePresence>
  );
};

export default Daily;
