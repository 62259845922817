import styled from 'styled-components';
import { media } from 'styles/mixins';
import { Body } from 'components/Typography';
import DefaultButton from 'components/Button';

export const Container = styled.div`
  align-items: center;
  background: transparent;
  display: flex;
  flex-flow: column nowrap;
  padding: 1.25rem 0;
  position: relative;
  text-align: center;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
`;

export const Message = styled(Body)`
  margin-left: ${({ theme }) => theme.measures.xs};
`;

export const Button = styled(DefaultButton)`
  margin-top: ${({ theme }) => theme.measures.m};
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  width: 21.4375rem;

  ${media.phonePortrait} {
    width: 100%;
  }
`;
