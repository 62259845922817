import styled from 'styled-components';
import clamp from 'lodash/clamp';
import { media } from 'styles/mixins';
import {
  BAR_MARGIN_PX,
  BAR_WIDTH_PX,
  CONTAINER_MIN_HEIGHT_PX,
  CONTAINER_WIDTH_PX,
  SLIDER_HEIGHT_PX,
} from './constants';

export const Container = styled.div`
  display: flex;
  flex: 0 1 100%;
  flex-direction: column;
  height: auto;
  min-height: ${CONTAINER_MIN_HEIGHT_PX}px;
  justify-content: flex-end;
  margin: 6rem auto ${({ theme }) => theme.measures.x} auto;
  width: ${CONTAINER_WIDTH_PX}px;

  @media (max-height: 450px) {
    margin: ${({ theme }) =>
      `${theme.measures.x} auto ${theme.measures.x} auto`};
    width: 100%;
  }

  ${media.phoneLandscape} {
    margin: ${({ theme }) =>
      `${theme.measures.x} auto ${theme.measures.x} auto`};
    width: 100%;
  }
`;

export const DurationQuantity = styled.div`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  font-family: ${({ theme }) => theme.font.family.druk};
  font-size: 6rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: 0.02em;
  line-height: 7.5rem;
  margin-bottom: 1rem;
  text-align: center;

  @media (max-height: 450px) {
    font-size: 4rem;
    line-height: 5rem;
  }
`;

export const DurationSlider = styled.div`
  -ms-overflow-style: none;
  cursor: pointer;
  display: flex;
  flex: 0 1 100%;
  min-height: 80px;
  max-height: ${SLIDER_HEIGHT_PX}px;
  outline: none;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-left: 50%;
  scrollbar-width: none;
  user-select: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::after {
    content: '';
    display: block;
    padding-left: 100%;
  }
`;

export const VerticalBar = styled.div<{ index: number }>`
  background-color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.fontEmphasized
      : theme.colors.coreNeutralAurora};
  height: ${({ index }) =>
    clamp(index > 0 ? 100 - 9 * index : 100 + 9 * index, 33, 100)}%;
  margin-top: auto;
  min-width: ${BAR_WIDTH_PX}px;
  opacity: ${({ index }) =>
    clamp(index > 0 ? 1 - 0.2 * index : 1 + 0.2 * index, 0.1, 100)};

  &:not(:last-child) {
    margin-right: ${BAR_MARGIN_PX}px;
  }
`;
