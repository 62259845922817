import styled from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';
import { media } from 'styles/mixins';
import {
  Body as DefaultBody,
  Display as DefaultDisplay,
} from 'components/Typography';
import DefaultNavTabList from 'components/NavTabList';
import DefaultIcon from 'components/Icon';

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 3rem;
  padding: ${({ theme }) => theme.measures.m} 0;
  position: relative;

  ${media.phoneLandscape} {
    margin-bottom: 1rem;
    min-height: 0;
    padding: 0.25rem 0;
  }
`;

export const BackLink = styled(DefaultLink)`
  align-items: center;
  color: inherit;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;

  :active {
    color: ${({ theme }) => theme.colors.fontDeemphasized};
  }

  ${media.tabletPortrait} {
    display: none;
  }
`;

export const Title = styled(DefaultDisplay).attrs(() => ({ $size: 'small' }))``;

export const NavTabList = styled(DefaultNavTabList)`
  margin-bottom: ${({ theme }) => theme.measures.s};
`;

export const NoElements = styled(DefaultBody).attrs(() => ({
  $size: 'large',
}))`
  margin-top: ${({ theme }) => theme.measures.m};
  margin-bottom: ${({ theme }) => theme.measures.l};
`;

export const HeartIcon = styled(DefaultIcon).attrs(() => ({ size: 16 }))`
  margin-bottom: -0.1875rem;
`;

export const DailyContainer = styled.div``;
