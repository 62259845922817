import { memo, useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { AnimatePresence } from 'framer-motion';
import MenuItem from './MenuItem';
import { ButtonCTA, Container, Label, Menu } from './styles';
import { Props } from './types';

const DURATIONS = [15, 30, 45, 60, 90, 120];

const DurationSelect: FC<Props> = ({
  duration,
  onChangeDuration = () => null,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null!);

  useEffect(() => {
    const listener = (e: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };

    if (document) {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }
  }, []);

  const handleToggleOpen = useCallback(() => {
    setOpen((v) => !v);
  }, []);

  if (!duration) {
    return null;
  }

  return (
    <Container onClick={handleToggleOpen} ref={ref}>
      <Label htmlFor="minutes-selector">Select timer minutes</Label>
      <ButtonCTA
        active={open}
        aria-autocomplete="none"
        aria-controls="minutes-sessions"
        aria-expanded={open}
        data-active-option={duration.toString()}
        id="minutes-selector"
        role="combobox"
        size="small"
      >
        {duration} minute{duration > 1 ? 's' : ''} session
      </ButtonCTA>
      <AnimatePresence initial={false}>
        {open && (
          <Menu aria-label="timer minutes" id="minutes-sessions" role="listbox">
            {DURATIONS.map((d) => (
              <MenuItem
                key={d}
                active={d === duration}
                id={d}
                onClick={onChangeDuration}
                role="option"
                text={`${d} minute${d > 1 ? 's' : ''} session`}
                value={d}
              />
            ))}
          </Menu>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default memo(DurationSelect);
