import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { parse } from 'query-string';
import DOMPurify from 'dompurify';
import formatCents from 'lib/formatCents';
import { trackEvent } from 'services/analytics';
import useModalActions from 'graphql/hooks/useModalActions';
import useScholarshipPrices from 'graphql/hooks/useScholarshipPrices';
import { FULL_SCHOLARSHIP_PRICES } from './constants';
import type { FormFields } from './types';

const SCREEN = 'pay_what_you_want_email_landing_page';

const useConnect = () => {
  const { scholarshipPrices, loading } = useScholarshipPrices();
  const { openStripePaymentModal } = useModalActions();
  const { search } = useLocation();

  const { email: qpEmail } = parse(search);
  const emailParam =
    qpEmail && typeof qpEmail === 'string'
      ? DOMPurify.sanitize(qpEmail)
      : undefined;

  const initialValues = useMemo<FormFields>(
    () => ({
      email: emailParam || '',
      price: null,
    }),
    [emailParam],
  );

  const filteredPrices = useMemo(
    () =>
      FULL_SCHOLARSHIP_PRICES.filter(
        ({ price }) =>
          price === 0 ||
          scholarshipPrices.some(({ amount }) => amount === price),
      ),
    [scholarshipPrices],
  );

  useEffect(() => {
    trackEvent('Visit Scholarship Options Page');
    trackEvent('Scholarship Pay What you Want Display Options', {
      screen: SCREEN,
    });
  }, []);

  const handleSubmit = useCallback(
    (
      { email, price }: FormFields,
      { setSubmitting }: FormikHelpers<FormFields>,
    ) => {
      const subscriptionPlan = scholarshipPrices.find(
        ({ amount }) => amount === price,
      );

      if (subscriptionPlan) {
        trackEvent('Scholarship Pay What you Want Cost Submitted', {
          cost: formatCents(subscriptionPlan.amount).slice(1),
          screen: SCREEN,
        });

        trackEvent('Purchase Initiate Checkout', {
          screen: SCREEN,
          source: 'scholarship pay what you want',
          subscription_option: subscriptionPlan.sku,
        });

        setTimeout(() => {
          openStripePaymentModal({
            description: `You've been approved for one year of Waking Up for ${formatCents(
              subscriptionPlan.amount,
            )}`,
            email,
            isPublic: true,
            planAmount: subscriptionPlan.amount,
            planId: subscriptionPlan.id,
            planInterval: subscriptionPlan.interval,
            planType: subscriptionPlan.type,
            screen: SCREEN,
            title: 'Scholarship Checkout',
          });
          setSubmitting(false);
        }, 750);
      }
    },
    [openStripePaymentModal, scholarshipPrices],
  );

  return {
    fullScholarshipPrices: filteredPrices,
    handleSubmit,
    initialValues,
    isLoading: loading,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
