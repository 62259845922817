import { memo, type FC } from 'react';
import Icon from 'components/Icon';
import Element from './Element';
import { BackButton, ElementList, Container, Title, Content } from './styles';
import type { Props } from './types';
import useConnect from './connect';

const SelectInterval: FC<Props> = ({
  duration,
  intervalTime,
  setIntervalTime,
  setIsTimeSectionOpen,
}) => {
  const {
    closeInterval,
    intervalTimesOptions,
    selectIntervalTime,
    validIntervalTime,
  } = useConnect({
    duration,
    intervalTime,
    setIntervalTime,
    setIsTimeSectionOpen,
  });

  return (
    <Container id="intervals-section">
      <BackButton aria-label="Close interval selector" onClick={closeInterval}>
        <Icon aria-hidden="true" name="arrowLeftLight" />
      </BackButton>
      <Content>
        <Title data-cypress-id="self-timer-interval-time-section-title">
          Interval Time
        </Title>
        <ElementList data-cypress-id="self-timer-interval-time-section-element-list">
          {intervalTimesOptions.map(({ type, text }) => (
            <Element
              key={type}
              isSelected={validIntervalTime === type}
              onClick={selectIntervalTime}
              text={text}
              type={type}
            />
          ))}
        </ElementList>
      </Content>
    </Container>
  );
};

export default memo(SelectInterval);
