import { useCallback } from 'react';
import type { KeyboardEvent, MouseEvent } from 'react';
import type { LogicProps } from './types';

const useLogic = ({ hash, onClick, onOpenOptionsMenu }: LogicProps) => {
  const handleOpenOptionsMenu = useCallback(
    (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLButtonElement>) => {
      if (onOpenOptionsMenu) {
        onOpenOptionsMenu(event, hash);
      }
    },
    [hash, onOpenOptionsMenu],
  );

  const handleClick = useCallback(() => {
    onClick(hash);
  }, [hash, onClick]);

  return {
    handleClick,
    handleOpenOptionsMenu,
  };
};

export default useLogic;
