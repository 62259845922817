import { useCallback, memo } from 'react';
import type { FC, KeyboardEvent, MouseEvent } from 'react';
import CardListItem from 'components/CardListItem';
import type { Props } from './types';

const Item: FC<Props> = ({
  className,
  courseId,
  isForbidden,
  onOpenOptionsMenu,
  onPlay,
  packId,
  ...props
}) => {
  const handlePlay = useCallback(() => {
    if (onPlay) {
      onPlay(courseId, packId);
    }
  }, [courseId, onPlay, packId]);

  const handleOpenOptionsMenu = useCallback(
    (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLButtonElement>) => {
      if (onOpenOptionsMenu) {
        onOpenOptionsMenu(event, courseId, packId);
      }
    },
    [courseId, onOpenOptionsMenu, packId],
  );

  return (
    <CardListItem
      className={className}
      onOpenOptions={
        isForbidden || !onOpenOptionsMenu ? undefined : handleOpenOptionsMenu
      }
      onClick={isForbidden ? undefined : handlePlay}
      isForbidden={isForbidden}
      {...props}
    />
  );
};

export default memo(Item);
