import type { Daily } from 'models/Daily';
import type { Course } from 'models/Course';
import { BookmarkType } from '../generated';
import getBookmarks from './getBookmarks';

async function getBookmark(
  hash: string,
  type: BookmarkType.course,
): Promise<Course | undefined>;

async function getBookmark(
  hash: string,
  type: BookmarkType.daily,
): Promise<Daily | undefined>;

async function getBookmark(
  hash: string,
  type: BookmarkType,
): Promise<Course | Daily | undefined>;

async function getBookmark(hash: string, type: BookmarkType): Promise<any> {
  const bookmarks = await getBookmarks();

  if (type === BookmarkType.course) {
    return bookmarks.courses.find((course) => course.hash === hash);
  }

  if (type === BookmarkType.daily) {
    return bookmarks.dailies.find((daily) => daily.hash === hash);
  }
}

export default getBookmark;
