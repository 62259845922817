import { useCallback } from 'react';
import { FormikHelpers } from 'formik';
import { trackEvent } from 'services/analytics';
import useMe from 'graphql/hooks/useMe';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import useMiscActions from 'graphql/hooks/useMiscActions';
import { ModalType } from 'graphql/reactive';
import { type FormFields, Step } from './Form/types';

const useConnect = () => {
  const { profile } = useMe();
  const { isOpen } = useModal(ModalType.SEND_EMAIL);
  const { closeModal } = useModalActions();
  const { sendInviteEmail } = useMiscActions();

  const token = profile.shareCode || undefined;

  const handleSubmit = useCallback(
    async (
      { email, message, name, step }: FormFields,
      { setFieldError, setFieldValue }: FormikHelpers<FormFields>,
    ): Promise<void> => {
      try {
        if (step === Step.message) {
          await sendInviteEmail({
            message,
            recipientEmail: email,
            recipientName: name,
          });

          trackEvent('Share App 30 days', {
            message,
            method: 'email',
            recipient_email: email,
            recipient_name: name,
            token,
          });

          setFieldValue('step', Step.thanks);
        }
      } catch (error) {
        setFieldError(
          'submitError',
          error?.message ||
            `The email couldn't be sent. Try again in a few seconds.`,
        );
      }
    },
    [sendInviteEmail, token],
  );

  return { closeModal, handleSubmit, isOpen };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
