/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const STRIPE_PAYMENT_METHOD_FRAGMENT = gql`
  fragment StripePaymentMethodData on StripePaymentMethod {
    brand
    exp_month
    exp_year
    id
    last4
  }
`;
