import type { FC } from 'react';
import GiftCardPurchase from 'containers/GiftCardPurchase';
import { Container, Footer } from './styles';

const GiftCardPurchasePage: FC = () => (
  <Container>
    <GiftCardPurchase isPublic />
    <Footer />
  </Container>
);

export default GiftCardPurchasePage;
