import { memo } from 'react';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Container, Text } from './styles';
import type { Props } from './types';

const InputListItemLarge: FC<Props> = ({
  className,
  disabled,
  divider = 'both',
  iconName,
  text,
  to,
  ...rest
}) => (
  <Container
    as={to ? (Link as unknown as undefined) : undefined}
    className={className}
    disabled={disabled}
    to={to}
    {...rest}
  >
    {iconName && <Icon name={iconName} />}
    <Text $divider={divider} $hasIcon={iconName !== undefined}>
      {text}
    </Text>
  </Container>
);

export default memo(InputListItemLarge);
