import { memo, useCallback } from 'react';
import type { FC, MouseEvent } from 'react';
import { setOptionsMenuVar } from 'graphql/reactive';
import { ButtonIcon } from './styles';
import type { Props } from './types';

const Options: FC<Props> = ({ courseId, packId }) => {
  const handleOpen = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setOptionsMenuVar({
        anchorEl: event.currentTarget,
        id: courseId,
        offset: '0, -100%p',
        packId,
        placement: 'top-end',
        type: 'coursePlayer',
      });
    },
    [courseId, packId],
  );

  return (
    <ButtonIcon
      aria-label="player options menu"
      iconName="threeDotsVerticalLight"
      id="wu-player-options"
      onClick={handleOpen}
    />
  );
};

export default memo(Options);
