import styled, { css } from 'styled-components';
import { blink, media } from 'styles/mixins';
import colorWithAlpha from 'lib/color';
import {
  WidgetCarouselImageSize,
  WidgetCarouselLayout,
} from 'graphql/generated';
import DefaultTextPlaceholder from 'components/TextPlaceholder';
import {
  COLUMN_SEPARATION_PX,
  ITEM_HEIGHT_HORIZONTAL_PX,
  ITEM_HEIGHT_LARGE_PX,
  ITEM_HEIGHT_MEDIUM_PX,
  ITEM_WIDTH_HORIZONTAL_PX,
  ITEM_WIDTH_LARGE_PX,
  ITEM_WIDTH_MEDIUM_PX,
} from '../constants';
import { Props } from './types';

export const Container = styled.div<{
  $imageSize: Props['imageSize'];
  $layout: Props['layout'];
}>`
  display: flex;
  height: ${({ $imageSize, $layout }) => {
    if ($layout === WidgetCarouselLayout.horizontal) {
      return ITEM_HEIGHT_HORIZONTAL_PX;
    }

    return $imageSize === WidgetCarouselImageSize.large
      ? ITEM_HEIGHT_LARGE_PX
      : ITEM_HEIGHT_MEDIUM_PX;
  }}px;
  overflow-x: hidden;

  ${media.tabletPortrait} {
    margin-right: -1.4rem;
  }

  ${media.phonePortrait} {
    margin-right: -1rem;
  }
`;

export const Card = styled.div<{
  $imageSize: Props['imageSize'];
  $layout: Props['layout'];
}>`
  flex: 0 0 auto;
  width: ${({ $imageSize, $layout }) => {
    if ($layout === WidgetCarouselLayout.horizontal) {
      return ITEM_WIDTH_HORIZONTAL_PX;
    }

    return $imageSize === WidgetCarouselImageSize.large
      ? ITEM_WIDTH_LARGE_PX
      : ITEM_WIDTH_MEDIUM_PX;
  }}px;

  &:not(:last-child) {
    margin-right: ${COLUMN_SEPARATION_PX}px;
  }

  ${({ $layout }) =>
    $layout === WidgetCarouselLayout.horizontal &&
    css`
      align-items: center;
      display: flex;
    `}
`;

export const Image = styled.div<{
  $imageSize: Props['imageSize'];
  $layout: Props['layout'];
}>`
  animation: ${blink} 3s linear infinite;
  background-color: ${({ theme }) =>
    colorWithAlpha({ color: theme.colors.fontDeemphasized, alpha: 0.8 })};
  border-radius: ${({ theme }) => theme.radius.xsm};
  height: ${({ $imageSize }) =>
    $imageSize === WidgetCarouselImageSize.large
      ? ITEM_WIDTH_LARGE_PX
      : ITEM_WIDTH_MEDIUM_PX}px;
  width: 100%;

  ${({ $layout }) =>
    $layout === WidgetCarouselLayout.horizontal &&
    css`
      width: ${ITEM_WIDTH_MEDIUM_PX}px;
    `}
`;

export const Content = styled.div<{ $layout: Props['layout'] }>`
  padding-top: 0.75rem;

  ${({ $layout }) =>
    $layout === WidgetCarouselLayout.horizontal &&
    css`
      padding: 0.75rem;
    `}
`;

export const Title = styled(DefaultTextPlaceholder).attrs(() => ({
  typography: 'bodySmall',
}))`
  width: 8rem;
`;

export const Subtitle = styled(DefaultTextPlaceholder).attrs(() => ({
  typography: 'bodySmall',
}))`
  margin-top: 0.1875rem;
  width: 4.5rem;
`;
