import { memo, type FC } from 'react';
import { CircularProgress, Container } from './styles';

const Loader: FC = () => (
  <Container>
    <CircularProgress />
  </Container>
);

export default memo(Loader);
