import { memo, useCallback, type FC } from 'react';
import { useMediaQuery } from '@mui/material';
import { trackWidgetEvent } from 'services/analytics';
import { media } from 'styles/mixins';
import Link from 'components/Link';
import { Container, Image } from './styles';
import type { Props } from './types';

const WidgetImage: FC<Props> = ({ deepLink, id, urlDesktop, urlMobile }) => {
  const isMobile = useMediaQuery(media.phonePortrait);

  const handleClick = useCallback(() => {
    trackWidgetEvent(id);
  }, [id]);

  const isPublicDeeplink = !!deepLink?.includes('http');

  return (
    <Container
      as={deepLink ? Link : undefined}
      href={!!deepLink && isPublicDeeplink ? deepLink : undefined}
      onClick={handleClick}
      target={!!deepLink && isPublicDeeplink ? '_blank' : undefined}
      to={!!deepLink && !isPublicDeeplink ? deepLink : undefined}
    >
      <Image src={isMobile ? urlMobile : urlDesktop} />
    </Container>
  );
};

export default memo(WidgetImage);
