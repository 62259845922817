import styled, { css } from 'styled-components';
import MuiCircularProgress from '@mui/material/CircularProgress';
import { media } from 'styles/mixins';
import DefaultLink from 'components/Link';

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;

  ${media.tabletPortrait} {
    display: block;
  }
`;

export const Title = styled.h1`
  font-size: 1.875rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  letter-spacing: 0.01em;
  line-height: 1.27;

  ${media.tabletPortrait} {
    margin-bottom: 1.6875rem;
  }

  ${media.phoneLandscape} {
    font-size: 1.25rem;
    line-height: 1.4;
  }
`;

export const Information = styled.div`
  flex: 1 1 auto;
  padding-top: 2rem;
`;

export const Loader = styled(MuiCircularProgress).attrs(() => ({
  size: 35,
}))`
  margin-top: 3rem;
`;

export const ErrorMessage = styled.div`
  margin-top: 2rem;

  p {
    font-style: italic;
    margin-bottom: 1rem;
  }
`;

const buttonStyles = css`
  background-color: #2565f5;
  border: none;
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.coreNeutralAurora};
  cursor: pointer;
  display: inline-block;
  font-size: 1.0625rem;
  margin: 1.125rem 0;
  outline: none;
  padding: 0.75rem 2.25rem;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  width: auto;

  ${media.phonePortrait} {
    width: 100%;
  }
`;

export const Button = styled.button`
  ${buttonStyles}
`;

export const LinkButton = styled(DefaultLink)`
  ${buttonStyles}
`;
