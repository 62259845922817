import Text from 'components/Text';
import { FC } from 'react';
import { FormHeader, StyledForm, Title } from './styles';
import { Props } from './types';

const Form: FC<Props> = ({ children, heading, subHeading, ...rest }) => (
  <StyledForm {...rest} noValidate>
    {heading && (
      <FormHeader>
        <Title>{heading}</Title>
        {subHeading && <Text center>{subHeading}</Text>}
      </FormHeader>
    )}
    {children}
  </StyledForm>
);

export default Form;
