import type { FC } from 'react';
import { Button, Container, RemainingTime, Title } from './styles';
import { STEPS } from '../constants';
import type { Props } from './types';
import useLogic from './logic';

const Countdown: FC<Props> = ({
  currentSlide,
  isLoading,
  onContinue,
  startDate,
  title,
  titleId,
}) => {
  const { canBegin, remainingText } = useLogic({ startDate });

  return (
    <Container>
      <RemainingTime>{remainingText}</RemainingTime>
      <Title id={titleId}>{title}</Title>
      <Button
        disabled={!canBegin && currentSlide === STEPS - 1}
        isLoading={isLoading}
        onClick={onContinue}
      >
        {currentSlide === STEPS - 1 ? 'begin' : 'continue'}
      </Button>
    </Container>
  );
};

export default Countdown;
