import type { FC } from 'react';
import { Props } from './types';

const ArrowCounterClockwiseLight10s: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M6.5 9.349H2.985V5.5"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.168 17.834a8.25 8.25 0 100-11.668L2.986 9.348"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.718 14.8H9.73v-3.808l-1.179.366v-.803l2.061-.738h.106V14.8zm4.97-2.058c0 .688-.142 1.215-.427 1.58-.285.364-.702.546-1.251.546-.543 0-.957-.178-1.244-.536-.287-.358-.434-.87-.441-1.538v-.916c0-.695.143-1.223.43-1.583.29-.36.706-.54 1.248-.54s.957.179 1.244.537c.287.355.434.867.441 1.534v.916zm-.988-1.005c0-.412-.057-.712-.17-.898-.112-.19-.288-.284-.527-.284-.232 0-.405.09-.516.27-.11.178-.168.457-.175.837v1.21c0 .406.055.708.165.906.111.196.289.294.533.294.241 0 .416-.095.523-.284.107-.189.163-.478.167-.868v-1.182z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowCounterClockwiseLight10s;
