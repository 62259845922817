import { apolloClient } from 'services/api';
import { GetRemoteConfigDocument } from '../generated';

const getRemoteConfig = async () => {
  await apolloClient.query({
    query: GetRemoteConfigDocument,
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });
};

export default getRemoteConfig;
