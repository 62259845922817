import { css, keyframes } from 'styled-components';
import keysOf from 'lib/keysOf';
import { screenSizes } from './metrics';

const maxWidthQuery = (maxWidth: number) => `@media (max-width: ${maxWidth}px)`;

export const media = keysOf(screenSizes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: maxWidthQuery(screenSizes[key]),
  }),
  {} as { [key in keyof typeof screenSizes]: string },
);

export const prettyScrollbar = ({
  margin,
  vertical = true,
  size = '0.4rem',
}: {
  margin?: string;
  vertical?: boolean;
  size?: string;
}) => css`
  scrollbar-width: ${size};
  scrollbar-color: ${({ theme }) => theme.colors.scrollbarColor} transparent;

  &::-webkit-scrollbar {
    ${vertical
      ? css`
          width: ${size};
        `
      : css`
          height: ${size};
        `};
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.coreTeal50};
    ${margin &&
    css`
      margin: ${margin};
    `};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.scrollbarColor};
    border-radius: 4px;
  }
`;

export const dangerButtonStyles = css`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.coreRed500};
  cursor: pointer;
  font-size: 0.945rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 1.4;
  outline: none;
  position: relative;
  transition: all 0.2s ease;

  &::before {
    background-color: ${({ theme }) => theme.colors.coreRed500};
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    transform-origin: 100% 0;
    transform: scaleX(0);
    transition: transform 300ms ease-in-out;
    width: 100%;
  }

  &:hover {
    &::before {
      transform-origin: 0 0;
      transform: scaleX(1);
    }
  }

  &:active {
    transform: scaleX(0.97);
  }
`;

export const blink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;
