import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultModal from 'components/Modal';
import DefaultIcon from 'components/Icon';

export const Modal = styled(DefaultModal)`
  .MuiPaper-root {
    margin: 0;
    max-width: 95vw;
    padding: 3.25rem 2.8125rem;
    width: 32rem;

    ${media.phoneLandscape} {
      border-radius: 1.125rem 1.125rem 0 0;
      bottom: 0;
      left: 0;
      margin: 0;
      max-height: none;
      max-width: none;
      position: absolute;
      right: 0;
      top: 3.75rem;
      width: 100%;
    }
  }
`;

export const ClearButton = styled.button`
  background: transparent;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: block;
`;

export const CloseIcon = styled(DefaultIcon)`
  position: absolute;
  top: 0.6875rem;
  right: 0.75rem;
`;
