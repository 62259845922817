import type { FC } from 'react';
import { FamilyMemberType } from 'graphql/generated';
import Features from './Features';
import Info from './Info';
import Loader from './Loader';
import Manage from './Manage';
import Modify from './Modify';
import Plans from './Plans';
import { Container, Content, Header, InvalidCode, Title } from './styles';
import useConnect from './connect';

const Subscription: FC = () => {
  const {
    cancel,
    handleOpenStripePayment,
    hasInvalidCode,
    hasMonthlySubscription,
    hasYearlySubscription,
    isExpiredLifetimePromotion,
    isLoadingPlans,
    isManagingSubscription,
    isMobileWebview,
    isUpdatingSubscription,
    plans,
    reactivate,
    user,
  } = useConnect();

  const {
    isSubscribed,
    joinedAt,
    subscriptionExpiresAt,
    subscriptionFamilyMember,
    subscriptionInterval,
    subscriptionIsEligibleForYearlyUpgradeWithDiscount,
    subscriptionPriceUsd,
    subscriptionRenewIntent,
    subscriptionSource,
    subscriptionStripeTrialChargeDate,
    subscriptionSubtype,
  } = user;

  if (isMobileWebview) {
    return (
      <Container>
        <Content>
          {isSubscribed ? (
            <>
              <Title>Your subscription</Title>
              <Info
                endTime={subscriptionExpiresAt}
                isMobileWebview={isMobileWebview}
                joinedAt={joinedAt}
                planAmount={subscriptionPriceUsd}
                planInterval={subscriptionInterval}
                planSubtype={subscriptionSubtype}
                renewIntent={subscriptionRenewIntent}
                source={subscriptionSource}
                subscriptionFamilyMember={subscriptionFamilyMember}
              />
            </>
          ) : (
            <Title>There is no active subscription</Title>
          )}
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Header testId="info-header" title="Account" backHref="/account" />
      <Content>
        {isSubscribed ? (
          <>
            <Title>Your subscription</Title>
            <Info
              endTime={subscriptionExpiresAt}
              joinedAt={joinedAt}
              planAmount={subscriptionPriceUsd}
              planInterval={subscriptionInterval}
              planSubtype={subscriptionSubtype}
              renewIntent={subscriptionRenewIntent}
              source={subscriptionSource}
              stripeTrialChargeDate={subscriptionStripeTrialChargeDate}
              subscriptionFamilyMember={subscriptionFamilyMember}
            />
          </>
        ) : (
          <Features />
        )}
        {isLoadingPlans ? (
          <Loader />
        ) : (
          <>
            {(hasInvalidCode || isExpiredLifetimePromotion) && (
              <InvalidCode>
                Sorry, this offer is no longer available.
              </InvalidCode>
            )}
            {isSubscribed && subscriptionSource === 'stripe' && (
              <Modify subscriptionFamilyMember={subscriptionFamilyMember} />
            )}
            {plans.length > 0 && (
              <Plans
                hasMonthlySubscription={hasMonthlySubscription}
                hasYearlySubscription={hasYearlySubscription}
                isEligibleForYearlyUpgradeWithDiscount={
                  subscriptionIsEligibleForYearlyUpgradeWithDiscount
                }
                isLoading={isLoadingPlans || isUpdatingSubscription}
                onCreate={handleOpenStripePayment}
                plans={plans}
              />
            )}
            {isSubscribed &&
              subscriptionSource === 'stripe' &&
              subscriptionFamilyMember !== FamilyMemberType.MEMBER && (
                <Manage
                  isLoading={isManagingSubscription}
                  onCancel={cancel}
                  onReactivate={reactivate}
                  renewIntent={subscriptionRenewIntent}
                />
              )}
          </>
        )}
      </Content>
    </Container>
  );
};

export default Subscription;
