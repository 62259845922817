import { useMemo, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { trackEvent } from 'services/analytics';
import { up } from 'lib/paths';
import useMe from 'graphql/hooks/useMe';
import useMeActions from 'graphql/hooks/useMeActions';
import { ProfileFormFields } from 'models/User';
import { validationSchema } from './constants';

const trackProfilePropertyEvent = (
  fields: Partial<Record<keyof ProfileFormFields, string>>,
  oldValues: ProfileFormFields,
  newValues: ProfileFormFields,
) => {
  if (oldValues.avatarId !== newValues.avatarId) {
    trackEvent('Setting Update Profile Picture');
  }

  const fieldNames = Object.keys(fields) as Array<keyof ProfileFormFields>;
  fieldNames.forEach((fieldName) => {
    const oldValue = oldValues[fieldName];
    const newValue = newValues[fieldName];
    if (oldValue !== newValue)
      trackEvent('Setting Update Profile', {
        fieldName: fields[fieldName],
        oldValue,
        newValue,
      });
  });
};

const useConnect = () => {
  const { profile, loading: isLoading } = useMe();
  const { updateProfile } = useMeActions();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const state = location.state as ProfileFormFields;

  const { avatarAlt, avatarId, avatarUrl, city, email, firstName, lastName } =
    state || {};

  const {
    avatarAlt: profileAvatarAlt,
    avatarId: profileAvatarId,
    avatarUrl: profileAvatarUrl,
    city: profileCity,
    email: profileEmail,
    firstName: profileFirstName,
    lastName: profileLastName,
  } = profile;

  const initialValues: ProfileFormFields = useMemo(
    () => ({
      avatarAlt: avatarAlt || profileAvatarAlt,
      avatarId: avatarId || profileAvatarId,
      avatarUrl: avatarUrl || profileAvatarUrl,
      city: city || profileCity,
      email: email || profileEmail,
      firstName: firstName || profileFirstName,
      lastName: lastName || profileLastName,
    }),
    [
      avatarAlt,
      avatarId,
      avatarUrl,
      city,
      email,
      firstName,
      lastName,
      profileAvatarAlt,
      profileAvatarId,
      profileAvatarUrl,
      profileCity,
      profileEmail,
      profileFirstName,
      profileLastName,
    ],
  );

  const backHref = useMemo(() => up(pathname), [pathname]);

  const submit = useCallback(
    async (values: ProfileFormFields) => {
      try {
        await updateProfile({
          city: values.city,
          first_name: values.firstName,
          last_name: values.lastName,
          avatar_id: values.avatarId,
        });

        enqueueSnackbar('Your profile has been updated successfully', {
          variant: 'success',
        });

        trackProfilePropertyEvent(
          { firstName: 'First', lastName: 'Last', city: 'City' },
          initialValues,
          values,
        );

        navigate(up(pathname));
      } catch (error) {
        enqueueSnackbar(error?.message || 'Unable to update your profile', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, initialValues, navigate, pathname, updateProfile],
  );

  return {
    backHref,
    initialValues,
    isLoading,
    submit,
    updateProfile,
    validationSchema,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
