import Form from './Form';
import { Modal } from './styles';
import { initialValues } from './constants';
import useConnect from './connect';

const Support = () => {
  const { handleClose, handleSubmit, isOpen } = useConnect();

  return (
    <Modal
      descriptionId="support-description"
      fullscreenOnMobile
      hideClose
      onClose={handleClose}
      open={isOpen}
      titleId="support-title"
    >
      <Form
        descriptionId="support-description"
        initialValues={initialValues}
        onClose={handleClose}
        onSubmit={handleSubmit}
        titleId="support-title"
      />
    </Modal>
  );
};

export default Support;
