import { useMemo } from 'react';
import useRetreat from './useRetreat';

const useRetreatCourse = (courseKey?: string, packKey?: string) => {
  const { retreat, loading } = useRetreat();

  const course = useMemo(() => {
    if (!courseKey || !retreat?.pack?.courses) {
      return undefined;
    }

    const lwCourseKey = courseKey.toLowerCase();

    if (packKey) {
      const lwPackKey = packKey.toLowerCase();

      return retreat.pack.courses.find(
        ({ bdId, hash, id, packHash, packId }) =>
          (bdId.toLowerCase() === lwCourseKey ||
            hash.toLowerCase() === lwCourseKey ||
            id.toLowerCase() === lwCourseKey) &&
          (packHash.toLowerCase() === lwPackKey ||
            packId.toLowerCase() === lwPackKey),
      );
    }

    return retreat.pack.courses.find(
      ({ bdId, hash, id }) =>
        bdId.toLowerCase() === lwCourseKey ||
        hash.toLowerCase() === lwCourseKey ||
        id.toLowerCase() === lwCourseKey,
    );
  }, [courseKey, packKey, retreat]);

  return { course, loading };
};

export default useRetreatCourse;
export type UseRetreatCourse = ReturnType<typeof useRetreatCourse>;
