import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { trackEvent } from 'services/analytics';
import { RedeemFreeMonthTrialDocument } from '../generated';
import {
  GET_CONTENT,
  GET_DAILY,
  GET_FEATURE_CONTENT,
  GET_REMOTE_CONFIG,
} from '../queries';
import { getProfile } from '../requests';

const useRedeemFreeMonthTrialActions = () => {
  const [redeemFreeMonthTrialMutation, { loading: isRedeeming }] = useMutation(
    RedeemFreeMonthTrialDocument,
    {
      refetchQueries: [
        { query: GET_CONTENT },
        { query: GET_DAILY },
        { query: GET_FEATURE_CONTENT },
        { query: GET_REMOTE_CONFIG },
      ],
    },
  );

  const redeemFreeMonthTrial = useCallback(
    async ({ hash, source }: { hash: string; source?: string }) => {
      const { data } = await redeemFreeMonthTrialMutation({
        variables: { hash },
      });

      const profile = await getProfile({ fetchPolicy: 'network-only' });

      trackEvent('Subscription Redeem', {
        subscriptionEndTime: profile.subscriptionExpiresAt,
        subscriptionStartTime: profile.subscriptionStartTime,
        source: source || 'app share',
        type: 'promo',
      });

      return data?.redeemFreeMonthTrial?.message;
    },
    [redeemFreeMonthTrialMutation],
  );

  return {
    isRedeeming,
    redeemFreeMonthTrial,
  };
};

export default useRedeemFreeMonthTrialActions;
export type UseRedeemFreeMonthTrialActions = ReturnType<
  typeof useRedeemFreeMonthTrialActions
>;
