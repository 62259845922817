import { memo } from 'react';
import type { FC } from 'react';
import Placeholder from './Placeholder';
import {
  Container,
  ContainerButton,
  ContainerLink,
  Image,
  Info,
  Lock,
  Overline,
  Play,
  Row,
  Subtitle,
  Title,
} from './styles';
import type { Props } from './types';

const PracticeBanner: FC<Props> = ({
  className,
  imageUrl,
  isLoading,
  layout = 'horizontal',
  locked,
  onClick,
  overline,
  subtitle,
  title,
}) => {
  if (isLoading) {
    return (
      <Container $layout={layout} className={className}>
        <Placeholder layout={layout} />
      </Container>
    );
  }

  const content = (
    <>
      <Image $layout={layout} alt="" hideLoader src={imageUrl} />
      <Info>
        <Overline $layout={layout}>{overline}</Overline>
        <Title $layout={layout} as="h1">
          {title}
        </Title>
        <Row>
          {locked ? (
            <Lock name="lockSimpleLight" />
          ) : (
            <Play name="playCircleBold" />
          )}
          <Subtitle>
            {locked ? 'Unlock with a paid membership' : subtitle}
          </Subtitle>
        </Row>
      </Info>
    </>
  );

  return locked ? (
    <ContainerLink
      $layout={layout}
      className={className}
      onClick={onClick}
      to="/account/subscription"
    >
      {content}
    </ContainerLink>
  ) : (
    <ContainerButton $layout={layout} className={className} onClick={onClick}>
      {content}
    </ContainerButton>
  );
};

export default memo(PracticeBanner);
