import styled from 'styled-components';
import MuiCircularProgress from '@mui/material/CircularProgress';
import DefaultIsotype from 'components/Isotype';
import DefaultFooter from 'components/PublicPageFooter';
import { media } from 'styles/mixins';
import { MAX_CONTENT_WIDTH } from 'styles/metrics';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.coreTeal950};
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${MAX_CONTENT_WIDTH};
  min-height: 100vh;
  padding: 0 2rem;

  ${media.phoneLandscape} {
    padding: 0 1.25rem;
  }
`;

export const ContentContainer = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  padding-top: 8.7425rem;

  ${media.tabletLandscape} {
    padding-top: 5.8756rem;
  }

  ${media.phonePortrait} {
    flex-direction: column-reverse;
    padding-top: 4.4694rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-left: 4.8963rem;

  ${media.tabletPortrait} {
    margin-left: 3rem;
  }

  ${media.phonePortrait} {
    margin-left: 0;
  }
`;

export const Loader = styled(MuiCircularProgress).attrs(() => ({
  size: 35,
}))`
  margin-top: 3rem;
`;

export const Isotype = styled(DefaultIsotype)`
  display: block;
  width: 2.1094rem;
  margin-bottom: 2.5rem;
`;

export const Title = styled.h1`
  font-size: 1.6601rem;
  font-weight: inherit;
  line-height: 1.21;
`;

export const Overline = styled.p<{ $hasPromo?: boolean }>`
  color: ${({ theme }) => theme.colors.coreBlue500};
  font-size: 0.5625rem;
  font-weight: inherit;
  letter-spacing: 0.2em;
  line-height: 1.33;
  margin-top: ${({ theme }) => theme.measures.x};
  text-transform: uppercase;

  ${({ $hasPromo }) => $hasPromo && 'margin: 0 0 0.4894rem 0;'};
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.coreBlue500};
  border-radius: 0.25rem;
  border: none;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.coreNeutralAurora};
  cursor: pointer;
  display: inline-block;
  font-size: 1.0625rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  line-height: 1.235;
  margin-top: 1.625rem;
  outline: none;
  padding: 0.75rem 2.25rem;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  width: 11.5625rem;
`;

export const Description = styled.div`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: -0.03em;
  line-height: 1.4;
  margin-top: 1.375rem;
`;

export const Donate = styled.p`
  font-weight: 300;
  font-size: 0.8944rem;
  line-height: 1.61;
  letter-spacing: -0.03em;
  margin-top: 3.4581rem;
  max-width: 34.2119rem;
`;

export const Footer = styled(DefaultFooter)`
  ${media.phonePortrait} {
    margin-top: 4.2325rem;
  }
`;
