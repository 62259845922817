import { apolloClient } from 'services/api';
import { GetInitialDataDocument } from '../generated';

const getInitialData = async () => {
  await apolloClient.query({
    query: GetInitialDataDocument,
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });
};

export default getInitialData;
