import { makeVar } from '@apollo/client';
import {
  getDismissRetreatCheckedDate,
  setDismissRetreatCheckedDate,
} from 'services/storage';

export type DismissRetreat = {
  lastCheckedDates: Record<string, string>;
};

export const dismissRetreatVar = makeVar<DismissRetreat>({
  lastCheckedDates: getDismissRetreatCheckedDate() || {},
});

export const updateDismissRetreatLastCheckedDate = (
  userId: string,
  strDate: string = new Date().toISOString(),
) => {
  const currentValue = dismissRetreatVar()?.lastCheckedDates || {};
  const newValue = { ...currentValue, [userId]: strDate };

  dismissRetreatVar({ ...dismissRetreatVar(), lastCheckedDates: newValue });
  setDismissRetreatCheckedDate(newValue);
};
