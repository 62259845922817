import type { FC } from 'react';
import { Props } from './types';

const NumberPlaybackSpeed16xLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M5.727 8h-1.59l-1.98 1.254v1.5l1.831-1.149h.047V16h1.692V8zm2.91 8.102a.95.95 0 00.938-.938.949.949 0 00-.938-.93.934.934 0 100 1.868zm5.466.007c1.781.008 2.996-1.16 2.992-2.789.004-1.5-1.043-2.582-2.46-2.582-.88 0-1.657.422-2.017 1.13h-.054c.008-1.56.554-2.528 1.597-2.528.614 0 1.055.336 1.184.894h1.668c-.16-1.316-1.254-2.343-2.852-2.343-1.976 0-3.246 1.601-3.253 4.289-.004 2.93 1.55 3.922 3.195 3.93zm-.008-1.328c-.781 0-1.36-.629-1.36-1.41.005-.777.594-1.41 1.372-1.41.781 0 1.351.613 1.347 1.406.004.801-.578 1.414-1.359 1.414zM20.14 10h-1.718l1.718 3-1.78 3h1.718l1.164-2.074L22.426 16h1.699l-1.785-3 1.738-3h-1.707l-1.129 2.098L20.14 10z"
      fill="currentColor"
    />
  </svg>
);

export default NumberPlaybackSpeed16xLight;
