import { memo } from 'react';
import type { FC } from 'react';
import { Container, Feature, List, FeatureText, Text } from './styles';
import { FEATURES } from './constants';

const Features: FC = () => (
  <Container>
    <Text>Become a member today so you can:</Text>
    <List>
      {FEATURES.standard.map((v) => (
        <Feature key={v}>
          <FeatureText dangerouslySetInnerHTML={{ __html: v }} />
        </Feature>
      ))}
    </List>
    <Text>
      <strong>Risk-free guarantee</strong>. If you don't find Waking Up
      valuable, we'll give you a full refund. No questions asked. Also, we
      donate 10% of our profits to the most effective charities to help
      alleviate human suffering.
    </Text>
  </Container>
);

export default memo(Features);
