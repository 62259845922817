import styled from 'styled-components';
import {
  SIDEBAR_TABLET_LANDSCAPE_WIDTH,
  SIDEBAR_WIDTH,
  ZIndex,
} from 'styles/metrics';
import { media } from 'styles/mixins';
import DefaultIcon from 'components/Icon';
import { Heading } from 'components/Typography';

export const Container = styled.div<{ $isExpired?: boolean }>`
  align-items: center;
  background: ${({ $isExpired, theme }) =>
    $isExpired ? theme.colors.coreRed900 : theme.colors.coreBlue700};
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.coreNeutralAurora};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  left: calc(3.5rem + ${SIDEBAR_WIDTH});
  overflow: hidden;
  padding: 1rem 1rem 1rem 2.75rem;
  position: absolute;
  top: 4rem;
  width: calc(100% - ${SIDEBAR_WIDTH} - 3.5rem);
  z-index: ${ZIndex.FREE_TRIAL_NOTIFICATION};

  ${media.tabletLandscape} {
    left: calc(2rem + ${SIDEBAR_TABLET_LANDSCAPE_WIDTH});
    top: 2rem;
    width: calc(100% - ${SIDEBAR_TABLET_LANDSCAPE_WIDTH} - 2rem);
  }

  ${media.tabletPortrait} {
    border-radius: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: auto;
    width: auto;
  }
`;

export const ClearButton = styled.button`
  background: transparent;
  border: none;
  box-sizing: content-box;
  color: inherit;
  cursor: pointer;
  display: block;
  line-height: 1;
  outline: none;
`;

export const Button = styled(ClearButton)`
  flex: 0 1 auto;
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const Icon = styled(DefaultIcon)`
  display: block;
`;

export const Content = styled.div``;

export const Title = styled(Heading).attrs(() => ({
  $weight: 'bold',
}))``;

export const Description = styled(Heading).attrs(() => ({
  $weight: 'bold',
}))`
  font-size: 0.625rem;
  line-height: 1.25;
`;
