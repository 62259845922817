import { FC } from 'react';
import { Check, Container } from './styles';
import { Props } from './types';
import useLogic from './logic';

const Option: FC<Props> = ({
  id,
  option,
  selected,
  onProfileUpdate,
  onClose,
}) => {
  const { selectOption } = useLogic({ id, onClose, onProfileUpdate, selected });

  return (
    <Container
      id={`daily-duration-${option.toLowerCase().replace(/ /g, '-')}`}
      onClick={selectOption}
    >
      {option}
      {selected && <Check />}
    </Container>
  );
};

export default Option;
