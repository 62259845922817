import styled from 'styled-components';
import colorWithAlpha from 'lib/color';
import { media } from 'styles/mixins';

export const Container = styled.ul`
  border-bottom: 1px solid
    ${({ theme }) =>
      colorWithAlpha({
        color: theme.colors.coreNeutralAbyss,
        alpha: 0.34,
      })};
  list-style: none;
  padding: 2rem 0;
`;

export const Feature = styled.li`
  align-items: flex-start;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  svg {
    display: inline-block;
    flex: 0 0 auto;
    margin-right: 1rem;
    margin-top: 0.1875rem;

    > * {
      stroke-width: 0.1875rem;
    }
  }
`;

export const FeatureText = styled.p`
  color: ${({ theme }) => theme.colors.coreNeutralAbyss};
  font-size: 1.0821rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  letter-spacing: -0.03em;
  line-height: 1.21;
  max-width: 27.5rem;

  ${media.tabletPortrait} {
    max-width: none;
  }
`;
