import { useCallback } from 'react';
import type { ChangeEvent } from 'react';
import { useField, useFormikContext } from 'formik';
import { LogicProps } from './types';

const useConnect = ({ name, submitOnChange }: LogicProps) => {
  const [{ value, onChange }] = useField(name);
  const { submitForm } = useFormikContext();

  const handleChange = useCallback(
    (e: string | ChangeEvent<any>) => {
      onChange(e);
      if (submitOnChange) {
        submitForm();
      }
    },
    [onChange, submitForm, submitOnChange],
  );

  return { value, handleChange };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
