import styled from 'styled-components';

export const Container = styled.div``;

export const Image = styled.img`
  border-radius: 0.2rem;
  display: block;
  height: auto;
  margin-bottom: 0.8rem;
  max-height: 24rem;
  max-width: 24rem;
  width: 100%;
`;

export const Credits = styled.p`
  font-size: 0.875rem;
  line-height: 1.4;
  letter-spacing: 0.01em;
`;
