import {
  useRef,
  useCallback,
  useEffect,
  MouseEvent as ReactMouseEvent,
  KeyboardEvent,
} from 'react';
import { Props } from './types';
import { BAR_TOTAL_WIDTH_PX } from './constants';

const scrollLeftToDuration = (scrollLeft: number) =>
  Math.round(scrollLeft / BAR_TOTAL_WIDTH_PX) + 1;

const useConnect = (
  decrementDuration: Props['decrementDuration'],
  duration: Props['duration'],
  incrementDuration: Props['incrementDuration'],
  setDuration: Props['setDuration'],
) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const startXRef = useRef<number | null>(null);
  const originalScrollLeft = useRef<number | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = (duration - 1) * BAR_TOTAL_WIDTH_PX;
    }
  }, [duration]);

  const onMouseDown = useCallback(
    (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
      if (containerRef.current) {
        startXRef.current = e.pageX;
        originalScrollLeft.current = containerRef.current.scrollLeft;
      }
    },
    [],
  );
  const onMouseMove = useCallback(
    (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
      if (
        containerRef.current &&
        startXRef.current !== null &&
        originalScrollLeft.current !== null
      ) {
        const increment = startXRef.current - e.pageX;
        const newScrollLeft = originalScrollLeft.current + increment;

        containerRef.current.scrollLeft = newScrollLeft;
      }
    },
    [],
  );
  const onMouseUp = useCallback(() => {
    startXRef.current = null;
    originalScrollLeft.current = null;
  }, []);

  const onScroll = useCallback(() => {
    if (containerRef.current) {
      setDuration(scrollLeftToDuration(containerRef.current.scrollLeft));
    }
  }, [setDuration]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
        e.preventDefault();

        if (e.key === 'ArrowLeft') {
          decrementDuration();
        } else {
          incrementDuration();
        }
      }
    },
    [decrementDuration, incrementDuration],
  );

  return {
    containerRef,
    onKeyDown,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onScroll,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
