import type { FC } from 'react';
import useMediaQuery from 'lib/useMediaQuery';
import SubscriptionCoverImage from 'components/SubscriptionCoverImage';
import StripePaymentModal from 'containers/Modals/StripePayment';
import { media } from 'styles/mixins';
import { SubscriptionPlanType } from 'graphql/generated';
import Conditions from './Conditions';
import Features from './Features';
import Price from './Price';
import {
  Button,
  Container,
  ErrorMessage,
  Information,
  LinkButton,
  Loader,
  Title,
} from './styles';
import type { Props } from './types';
import { SUBSCRIPTION_LANDING_IMAGES } from '../constants';

const Standard: FC<Props> = ({
  hasPromo,
  isLoading,
  isPublic,
  onClick,
  planAmount,
  planInterval,
  planOriginalAmount,
  planPeriod,
  planType,
  search,
  showInvalidCode,
  showNoSubscriptionPlans,
  showPlanInfo,
  slashedOriginalPrice,
}) => {
  const isTabletPortrait = useMediaQuery(media.tabletPortrait);

  const imageAlt = SUBSCRIPTION_LANDING_IMAGES[planInterval]?.alt || '';
  const desktopImageUrl =
    SUBSCRIPTION_LANDING_IMAGES[planInterval]?.desktop || '';
  const mobileImageUrl =
    SUBSCRIPTION_LANDING_IMAGES[planInterval]?.mobile || '';

  const subscribeButtonText =
    planType === SubscriptionPlanType.family
      ? 'Start Family Yearly Plan'
      : `Start ${planPeriod} Plan`;

  return (
    <Container>
      {isTabletPortrait && <Title>Continue Your Journey</Title>}
      <SubscriptionCoverImage
        alt={imageAlt}
        caption="Artwork By: Look at the Parking Lot"
        desktopImageUrl={desktopImageUrl}
        isTabletPortrait={isTabletPortrait}
        mobileImageUrl={mobileImageUrl}
      />
      <Information>
        {!isTabletPortrait && <Title>Continue Your Journey</Title>}
        {isLoading && <Loader />}
        {showNoSubscriptionPlans && (
          <ErrorMessage>
            <p>
              The subscription system is currently unavailable for scheduled
              maintenance.
            </p>
            <p>Please try again in a few minutes.</p>
          </ErrorMessage>
        )}
        {showInvalidCode && (
          <ErrorMessage>
            <p>Sorry, this offer is no longer available.</p>
            <LinkButton to={search ? `/${search}` : '/'}>
              Try Waking Up for free
            </LinkButton>
          </ErrorMessage>
        )}
        {showPlanInfo && (
          <>
            {!isTabletPortrait && <Features />}
            <Price
              hasPromo={hasPromo}
              planAmount={planAmount}
              planInterval={planInterval}
              planOriginalAmount={planOriginalAmount}
              planType={planType}
              slashedOriginalPrice={slashedOriginalPrice}
            />
            <Button id="subscribe-button" onClick={onClick}>
              {subscribeButtonText}
            </Button>
            {isTabletPortrait && <Features />}
            <Conditions isPublic={isPublic} />
          </>
        )}
      </Information>
      <StripePaymentModal />
    </Container>
  );
};

export default Standard;
