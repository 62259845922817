import formatCents from 'lib/formatCents';
import { FC, memo } from 'react';
import { Container } from './styles';
import type { Props } from './types';

const Price: FC<Props> = ({ planAmount, planInterval, trialDays }) => {
  if (planInterval === 'month') {
    return (
      <Container>
        <strong>Web-only price: </strong> Billed monthly at{' '}
        {formatCents(planAmount)}/{planInterval} after your {trialDays}-day
        trial. <strong>Cancel anytime.</strong>
      </Container>
    );
  }

  return (
    <Container>
      <strong>Web-only price: </strong> {formatCents(planAmount / 12)} USD per
      month, billed annually at {formatCents(planAmount)}/{planInterval} after
      your {trialDays}-day trial. <strong>Cancel anytime.</strong>
    </Container>
  );
};

export default memo(Price);
