import { FC } from 'react';
import { Container } from './styles';
import { Props } from './types';
import useLogic from './logic';

const Faq: FC<Props> = ({ className, data }) => {
  const { ref } = useLogic({ data });

  return <Container className={className} ref={ref} />;
};

export default Faq;
