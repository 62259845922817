import { memo, type FC } from 'react';
import type { StripePaymentMethod } from 'models/StripePaymentMethod';
import { Container, Error, Text } from './styles';

const PaymentMethod: FC<StripePaymentMethod> = ({
  brand,
  expMonth,
  expYear,
  id,
  last4,
}) => {
  const showError = !brand || !expMonth || !expYear || !id || !last4;

  return (
    <Container>
      {showError ? (
        <Error>Current payment method data missing or incomplete.</Error>
      ) : (
        <Text>
          Your subscription is currently being billed to a {brand.toUpperCase()}{' '}
          ending in {last4} that expires on {expMonth}/{expYear}.
        </Text>
      )}
    </Container>
  );
};

export default memo(PaymentMethod);
