import { memo, type FC } from 'react';
import { formatDurationShort } from 'lib/formatTime';
import { Button, Container, ReservedSpot, SecondaryButton } from './styles';
import type { Props } from './types';
import useConnect from './connect';

const Buttons: FC<Props> = (retreat) => {
  const {
    handleJoin,
    handlePlayTrailer,
    handleReserve,
    handleResume,
    handleShare,
    isReservationOpen,
    isUpdatingUserRetreat,
    showIdle,
    showJoin,
    showReserved,
    showResume,
    waitingRoomCountdown,
  } = useConnect(retreat);

  return (
    <Container>
      {showResume && (
        <Button
          isLoading={isUpdatingUserRetreat}
          mode="full"
          onClick={handleResume}
        >
          Resume Retreat
        </Button>
      )}
      {showJoin && (
        <Button
          isLoading={isUpdatingUserRetreat}
          mode="full"
          onClick={handleJoin}
        >
          Join Retreat
        </Button>
      )}
      {showIdle && (
        <>
          <SecondaryButton iconName="playLight" onClick={handlePlayTrailer}>
            Play a Message from Sam
          </SecondaryButton>
          {isReservationOpen ? (
            <Button isLoading={isUpdatingUserRetreat} onClick={handleReserve}>
              Opt In
            </Button>
          ) : (
            <Button disabled mode="deemphasized">
              Opens in {formatDurationShort(waitingRoomCountdown / 1000)}
            </Button>
          )}
        </>
      )}
      {showReserved && (
        <>
          <Button onClick={handleShare}>Share with a Friend</Button>
          <ReservedSpot>Your spot is reserved.</ReservedSpot>
        </>
      )}
    </Container>
  );
};

export default memo(Buttons);
