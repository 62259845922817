import Cookie from 'js-cookie';
import { isProduction } from '../config';

export const TOKEN_KEY = 'token';
export const COOKIE_KEY = 'STYXKEY-token';
export const DEVELOPER_MODE_KEY = 'wu.app.developer';
export const STRIPE_CHECKOUT_SESSION_ENABLED_KEY = 'wu.app.stripe-checkout';
export const ONBOARDING_KEY = 'onboarding-finished';
export const PLAYER_VOLUME = 'player-volume';
export const TRIAL_CHECKED_DATE = 'wu.trial.checkeddate';
export const UPGRADE_TO_ANNUAL = 'wu.upgrade-to-annual.checkeddate';
export const DISMISS_RETREAT_DATE = 'wu.dismiss-retreat.checkeddate';
export const SKIP_MAINTENANCE_MODE = 'wu.webapp.skipMaintenanceMode';
export const EMAIL_KEY = 'wu.email';

const cookieOptions = {
  domain: 'wakingup.com',
  expires: 365,
};

export const deleteToken = () => {
  Cookie.remove(COOKIE_KEY, cookieOptions);
  return localStorage.removeItem(TOKEN_KEY);
};

export const getToken = () =>
  Cookie.get(COOKIE_KEY) || localStorage.getItem(TOKEN_KEY);

export const setToken = (token: string) => {
  Cookie.set(COOKIE_KEY, token, cookieOptions);
  return localStorage.setItem(TOKEN_KEY, token);
};

export const getDeveloperMode = () => {
  const value = localStorage.getItem(DEVELOPER_MODE_KEY);
  return value === '1';
};

export const isStripeCheckoutSessionEnabled = () => {
  const value = localStorage.getItem(STRIPE_CHECKOUT_SESSION_ENABLED_KEY);
  return value === 'true';
};

export const isOnboardingFinished = () =>
  localStorage.getItem(ONBOARDING_KEY) === 'true';

export const setOnboardingFinished = () => {
  localStorage.setItem(ONBOARDING_KEY, 'true');
};

export const getPlayerVolume = () => {
  const volume = localStorage.getItem(PLAYER_VOLUME);
  return volume ? Number(volume) : 100;
};
export const setPlayerVolume = (volume: number) => {
  localStorage.setItem(PLAYER_VOLUME, volume.toString());
};

export const getTrialCheckedDate = () => {
  const data = localStorage.getItem(TRIAL_CHECKED_DATE);
  return data ? JSON.parse(data) : {};
};
export const setTrialCheckedDate = (data: Record<string, string>) => {
  localStorage.setItem(TRIAL_CHECKED_DATE, JSON.stringify(data));
};

export const getUpgradeToAnnual = () => {
  const data = localStorage.getItem(UPGRADE_TO_ANNUAL);
  return data ? JSON.parse(data) : {};
};
export const setUpgradeToAnnual = (data: Record<string, string>) => {
  localStorage.setItem(UPGRADE_TO_ANNUAL, JSON.stringify(data));
};

export const skipMaintenanceMode = () =>
  localStorage.getItem(SKIP_MAINTENANCE_MODE) === '1';

export const getEmail = () => localStorage.getItem(EMAIL_KEY);

export const setEmail = (email: string) =>
  localStorage.setItem(EMAIL_KEY, email);

export const removeEmail = () => localStorage.removeItem(EMAIL_KEY);

export const getExperiment = (experimentId: string) =>
  localStorage.getItem(experimentId);

export const setExperiment = (experimentId: string, variantId: string) =>
  localStorage.setItem(experimentId, variantId);

export const isPreviewMode = () => !isProduction || getDeveloperMode();

export const getDismissRetreatCheckedDate = () => {
  const data = localStorage.getItem(DISMISS_RETREAT_DATE);
  return data ? JSON.parse(data) : {};
};
export const setDismissRetreatCheckedDate = (data: Record<string, string>) => {
  localStorage.setItem(DISMISS_RETREAT_DATE, JSON.stringify(data));
};
