import { FC, memo } from 'react';
import { Button, Content, Description, Modal, Options } from './styles';
import useConnect from './connect';

const OnboardingExperienceModal: FC = () => {
  const {
    handleClickMoreExperienced,
    handleClickNoExperience,
    handleClickSomePractice,
    handleClose,
    isOpen,
  } = useConnect();

  return (
    <Modal
      disableBackdropClick
      fullscreenOnMobile
      hideClose
      onClose={handleClose}
      open={isOpen}
    >
      <Content>
        <Description>
          Select the option that best describes your level of meditation
          experience.
        </Description>
        <Options>
          <Button onClick={handleClickNoExperience}>STARTING OUT</Button>
          <Button id="some-practice" onClick={handleClickSomePractice}>
            SOME PRACTICE
          </Button>
          <Button onClick={handleClickMoreExperienced}>MORE EXPERIENCED</Button>
        </Options>
      </Content>
    </Modal>
  );
};
export default memo(OnboardingExperienceModal);
