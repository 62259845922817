import type { FC } from 'react';
import CheckoutForm from './CheckoutForm';
import {
  Description,
  Header,
  Logo,
  LogoBox,
  Modal,
  StripeLogo,
  TextBox,
  Title,
} from './styles';
import useConnect from './connect';

const StripePayment: FC = () => {
  const {
    description,
    experiment,
    handleClose,
    isOpen,
    isPublic,
    isStripeCheckoutSession,
    planAmount,
    planId,
    planInterval,
    priceId,
    promoCode,
    screen,
    sharedBy,
    title,
    userEmail,
    variant,
  } = useConnect();

  return (
    <Modal
      descriptionId={description ? 'checkout-description' : undefined}
      id="stripe-modal"
      onClose={handleClose}
      open={isOpen}
      titleId={title ? 'checkout-title' : undefined}
    >
      <Header>
        <LogoBox>
          <Logo aria-hidden="true" />
        </LogoBox>
        <TextBox>
          {title && <Title id="checkout-title">{title}</Title>}
          {description && (
            <Description id="checkout-description">{description}</Description>
          )}
        </TextBox>
      </Header>
      <CheckoutForm
        experiment={experiment}
        isPublic={isPublic}
        isStripeCheckoutSession={isStripeCheckoutSession}
        planAmount={planAmount}
        planId={planId}
        planInterval={planInterval}
        priceId={priceId}
        promoCode={promoCode}
        screen={screen}
        sharedBy={sharedBy}
        title={title}
        userEmail={userEmail}
        variant={variant}
      />
      <StripeLogo />
    </Modal>
  );
};

export default StripePayment;
