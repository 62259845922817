import { gql } from '@apollo/client';
import { COURSE_DATA_FRAGMENT, PACK_DATA_FRAGMENT } from '.';

export const LIVE_MEETING_INFO_DATA_FRAGMENT = gql`
  fragment LiveMeetingInfoData on LiveMeetingInfo {
    description
    id
    link
    start_date
    title
  }
`;

export const RETREAT_DATA_FRAGMENT = gql`
  fragment RetreatData on Retreat {
    about
    active
    courses {
      ...CourseData
    }
    cta_reappear_hours
    description
    end_date
    faq
    hash
    id
    image {
      id
      thumbnails {
        thumb_x1
        thumb_x2
      }
      url
    }
    live_meeting_info {
      ...LiveMeetingInfoData
    }
    pack {
      ...PackData
    }
    start_date
    subtitle
    title
    trailer {
      ...CourseData
    }
    user_state
    users_attending
    waiting_room_available_date
  }
  ${COURSE_DATA_FRAGMENT}
  ${LIVE_MEETING_INFO_DATA_FRAGMENT}
  ${PACK_DATA_FRAGMENT}
`;
