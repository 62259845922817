import { memo } from 'react';
import type { FC } from 'react';
import Icon from 'components/Icon';
import { Container, BackLink, Title } from './styles';

const Header: FC = () => (
  <Container>
    <BackLink aria-label="Go back to settings page" to="/account">
      <Icon aria-hidden="true" name="arrowLeftLight" />
    </BackLink>
    <Title as="h1">Notifications</Title>
  </Container>
);

export default memo(Header);
