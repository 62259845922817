/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import DefaultInputListItemSmall from 'components/InputListItemSmall';
import colorWithAlpha from 'lib/color';

export const Container = styled(DefaultInputListItemSmall)`
  box-sizing: inherit;
  white-space: nowrap;
  width: 100%;

  :focus,
  :hover {
    background-color: ${({ theme }) =>
      colorWithAlpha({
        color: theme.colors.coreNeutralBlack,
        alpha: 0.04,
      })};
  }
`;
