import type { FC } from 'react';
import { formatDurationShort } from 'lib/formatTime';
import { CourseStatus } from 'models/Course';
import { Container, Dot, Icon, LinearProgress } from './styles';
import type { Props } from './types';

const CourseLinearProgress: FC<Props> = ({
  className,
  courseStatus,
  total = 0,
  progress = 0,
}) => {
  const formattedPending = formatDurationShort(
    total - total * (progress / 100),
  );
  const formattedTotal = formatDurationShort(total);

  const showPlayed = courseStatus === CourseStatus.FINISHED;
  const showProgress = !showPlayed && progress > 0 && progress < 100;

  return (
    <Container $highlight={!showPlayed} className={className}>
      {showPlayed && (
        <>
          <Icon name="checkCircleLightFilled" />
          Played
          <Dot>·</Dot>
        </>
      )}
      {showProgress && (
        <LinearProgress value={progress} variant="determinate" />
      )}
      {showProgress ? `${formattedPending} left` : formattedTotal}
    </Container>
  );
};

export default CourseLinearProgress;
