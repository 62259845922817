import type { FC } from 'react';
import { DevConfig } from 'config';
import { toggleHelpScout } from 'services/helpscout';
import SectionHeader from 'components/DeprecatedSectionHeader';
import DailyDurationModal from 'containers/Modals/DailyDuration';
import StripePaymentModal from 'containers/Modals/StripePayment';
import {
  Container,
  Content,
  GroupHeader,
  ItemLarge,
  Links,
  Loader,
  LogoutLink,
  Progress,
  ProgressText,
  Switch,
  SwitchText,
  SwitchThemeButton,
  UserInfo,
  UserStats,
} from './styles';
import useConnect from './connect';

const ProfilePage: FC = () => {
  const {
    handleChangeTheme,
    handleExportProgressToCSV,
    handleResetAllContent,
    handleResetIntroCourse,
    isDarkMode,
    isMobile,
    loading,
    logOut,
    openDailyDurationModal,
    openOnboardingExperienceModal,
    openOnboardingSlidesModal,
    openResetContentMenuModal,
    pathname,
    profile,
    updateProfile,
  } = useConnect();

  return (
    <>
      <SectionHeader
        actionHref={`${pathname}/edit`}
        actionTitle="Edit Profile"
        data-jest-id="section-header"
        id="account-edit-profile"
        testId="account-header"
        title="Account"
      />
      <Content>
        <Container data-jest-id="account-view">
          <UserInfo
            avatarUrl={profile.avatarUrl}
            city={profile.city}
            firstName={profile.firstName}
            inline
            joinedAt={profile.joinedAt}
            lastName={profile.lastName}
            shadow
          />
          {profile.isSubscribed && (
            <UserStats
              hideSeparators
              mindfulDays={profile.activityMindfulDays}
              mindfulMinutes={profile.activityMindfulMinutes}
              titlePosition="bottom"
              totalSessions={profile.activityTotalSessions}
            />
          )}
          {loading && (
            <Loader>
              <Progress />
              <ProgressText>Getting new data ...</ProgressText>
            </Loader>
          )}
          <Links data-cypress-id="account-view-links">
            <GroupHeader>GENERAL</GroupHeader>

            <ItemLarge
              divider="bottom"
              to="/account/notifications"
              text="Notifications"
            />
            <ItemLarge
              divider="bottom"
              id="settings-daily-meditation-duration"
              onClick={openDailyDurationModal}
              text="Daily Meditation Duration"
            />
            <ItemLarge
              data-jest-id="subscription"
              divider="bottom"
              id="manage-subscription"
              state={{
                source: 'account',
                trackID: 'none',
                type: 'button',
              }}
              text={
                profile.isSubscribed ? 'Manage Subscription' : 'Subscription'
              }
              to="/account/subscription"
            />
            <ItemLarge
              data-jest-id="gift-card"
              divider="bottom"
              id="purchase-gift-card"
              text="Purchase Gift Card"
              to="/account/purchase-gift-card"
            />
            <ItemLarge
              divider="bottom"
              id="redeem-gift-card"
              text="Redeem Gift Card"
              to="/gifts/redeem"
            />
            <ItemLarge
              divider="bottom"
              id="redeem-promo-code"
              text="Redeem Promo Code"
              to="/promotions/redeem"
            />
            {isMobile && (
              <ItemLarge
                divider="bottom"
                onClick={openResetContentMenuModal}
                text="Reset Content"
              />
            )}
            {!isMobile && (
              <>
                <ItemLarge
                  divider="bottom"
                  id="settings-reset-intro-course"
                  onClick={handleResetIntroCourse}
                  text="Reset Introductory Course"
                />
                <ItemLarge
                  divider="bottom"
                  id="settings-reset-all-content"
                  onClick={handleResetAllContent}
                  text="Reset All Content as unplayed"
                />
              </>
            )}
            <ItemLarge
              divider="bottom"
              id="settings-export-progress"
              onClick={handleExportProgressToCSV}
              text="Export progress"
            />
            <ItemLarge
              divider="bottom"
              id="settings-contact-support"
              onClick={toggleHelpScout}
              text="Technical Support"
            />
            <SwitchThemeButton onClick={handleChangeTheme}>
              <SwitchText>Dark Mode</SwitchText>
              <Switch checked={isDarkMode} disableRipple />
            </SwitchThemeButton>
          </Links>
          <Links data-cypress-id="account-view-links">
            <GroupHeader>MORE</GroupHeader>
            <ItemLarge
              divider="bottom"
              id="settings-about-the-course"
              text="About the Course"
              to="/account/course"
            />
            <ItemLarge
              divider="bottom"
              id="settings-about-sam-harris"
              text="About Sam Harris"
              to="/account/sam"
            />
            <ItemLarge divider="bottom" text="FAQ" to="/account/help" />
            <ItemLarge divider="bottom" text="Terms" to="/account/terms" />
            <ItemLarge divider="bottom" text="Privacy" to="/account/privacy" />
          </Links>
          {DevConfig.debugSection && (
            <Links data-cypress-id="account-view-links">
              <GroupHeader>DEBUG</GroupHeader>
              <ItemLarge
                divider="bottom"
                onClick={openOnboardingSlidesModal}
                text="Onboarding (slides)"
              />
              <ItemLarge
                divider="bottom"
                onClick={openOnboardingExperienceModal}
                text="Onboarding (experience)"
              />
            </Links>
          )}
          <LogoutLink
            data-cypress-id="account-view-log-out"
            data-jest-id="logout"
            onClick={logOut}
          />
          <DailyDurationModal
            dailyDurationType={profile.dailyDurationType}
            updateProfile={updateProfile}
          />
          <StripePaymentModal />
        </Container>
      </Content>
    </>
  );
};
export default ProfilePage;
