import { memo } from 'react';
import type { FC } from 'react';
import SpeedOptionItem from './SpeedOptionItem';
import { Title } from '../styles';
import { SPEEDS } from './constants';
import useConnect from './connect';

const Speed: FC = () => {
  const { currentSpeed, handleSetSpeed } = useConnect();

  return (
    <>
      <Title as="h2">Play Speed</Title>
      {SPEEDS.map((speed) => (
        <SpeedOptionItem
          key={speed}
          active={speed === currentSpeed}
          onClick={handleSetSpeed}
          value={speed}
        />
      ))}
    </>
  );
};

export default memo(Speed);
