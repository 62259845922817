import styled from 'styled-components';
import { CircularProgress as MuiCircularProgress } from '@mui/material';

export const Container = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.borderButtonEmphasized};
  display: flex;
  height: 2rem;
  justify-content: center;
  position: relative;
  width: 2rem;
`;

export const CircularTrack = styled(MuiCircularProgress).attrs(() => ({
  size: 24,
  thickness: 3.5,
  value: 100,
  variant: 'determinate',
}))`
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;

  &.MuiCircularProgress-colorPrimary {
    color: inherit;
  }
`;

export const CircularProgress = styled(MuiCircularProgress).attrs(() => ({
  size: 24,
  thickness: 3.5,
  variant: 'determinate',
}))`
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;

  &.MuiCircularProgress-colorPrimary {
    color: ${({ theme }) => theme.colors.borderButtonDeemphasized};
  }
`;
