import { memo, type FC } from 'react';
import { Formik, Form } from 'formik';
import {
  ButtonsContainer,
  Description,
  Select,
  Submit,
  TextField,
  Title,
} from './styles';
import { validationSchema, HEAR_OPTIONS } from './constants';
import ConfirmationMsg from '../ConfirmationMsg';
import PriceSelector from './PriceSelector';
import type { Props } from './types';

const FullScholarshipForm: FC<Props> = ({
  descriptionId,
  fullScholarshipPrices,
  initialValues,
  isLoading,
  onSubmit,
  titleId,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validateOnBlur={false}
    validationSchema={validationSchema}
  >
    {({ handleSubmit, isSubmitting, values: { email, finish, hear, price } }) =>
      !finish ? (
        <Form noValidate onSubmit={handleSubmit}>
          <Title id={titleId}>Scholarship Request</Title>
          <Description id={descriptionId}>
            Please fill out the form below, and someone from the Waking Up team
            will contact you within 10 days if necessary.
          </Description>
          <TextField
            autoComplete="email"
            errorId="email-error"
            id="email"
            label="Email"
            maxLength={150}
            name="email"
            placeholder="e.g user@wakingup.com"
            type="email"
          />
          <TextField
            autoComplete="given-name"
            errorId="firstName-error"
            id="firstName"
            label="First name"
            maxLength={150}
            name="firstName"
            placeholder="e.g John"
          />
          <TextField
            autoComplete="family-name"
            errorId="lastName-error"
            id="lastName"
            label="Last name"
            maxLength={150}
            name="lastName"
            placeholder="e.g Doe"
          />
          <Select
            id="hear"
            isClearable={false}
            label="How did you hear about us?"
            name="hear"
            options={HEAR_OPTIONS}
          />
          {['Other', 'Other Podcast'].includes(hear) && (
            <TextField
              errorId="hearOtherInfo-error"
              id="hearOtherInfo"
              label="other"
              maxLength={200}
              name="hearOtherInfo"
              placeholder={
                hear === 'Other Podcast'
                  ? 'e.g Joe Rogan Podcast'
                  : 'e.g Magazine interview'
              }
            />
          )}
          <PriceSelector
            description="Your choice will not affect the likelihood of approving your scholarship."
            isLoading={isLoading}
            label="Choose a membership cost that works for you."
            name="price"
            fullScholarshipPrices={fullScholarshipPrices}
          />
          <ButtonsContainer>
            <Submit
              disabled={isLoading || isSubmitting || !email || price === null}
              isLoading={isSubmitting}
              type="submit"
            >
              Submit request
            </Submit>
          </ButtonsContainer>
        </Form>
      ) : (
        <ConfirmationMsg />
      )
    }
  </Formik>
);

export default memo(FullScholarshipForm);
