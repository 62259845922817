import { memo, useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { AnimatePresence } from 'framer-motion';
import { AdditionalMeditationsFilter } from 'models/Pack';
import MenuItem from './MenuItem';
import { ButtonCTA, Container, Menu } from './styles';
import { Props } from './types';

const DurationSelect: FC<Props> = ({
  className,
  duration,
  onChangeDuration = () => null,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null!);

  const durationOptions = Object.values<AdditionalMeditationsFilter>(
    AdditionalMeditationsFilter,
  ).map((v) => ({ label: v, value: v }));

  const currentDurationText =
    durationOptions.find(({ value }) => value === duration)?.value || '';

  useEffect(() => {
    const listener = (e: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };

    if (document) {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }
  }, []);

  const handleToggleOpen = useCallback(() => {
    setOpen((v) => !v);
  }, []);

  if (!duration) {
    return null;
  }

  return (
    <Container className={className} onClick={handleToggleOpen} ref={ref}>
      <ButtonCTA active={open} size="small">
        {currentDurationText}
      </ButtonCTA>
      <AnimatePresence initial={false}>
        {open && (
          <Menu>
            {durationOptions.map(({ label, value }) => (
              <MenuItem
                key={value}
                active={value === duration}
                id={value}
                onClick={onChangeDuration}
                text={label}
              />
            ))}
          </Menu>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default memo(DurationSelect);
