import { useCallback } from 'react';
import { setAnalyticsUserProperties, trackEvent } from 'services/analytics';
import { ModalType } from 'graphql/reactive';
import useMe from 'graphql/hooks/useMe';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';

const useConnect = () => {
  const { isOpen } = useModal(ModalType.ONBOARDING_EXPERIENCE);
  const { closeModal, openOnboardingOpenAccessModal } = useModalActions();
  const {
    profile: { subscriptionSubtype },
  } = useMe();

  const showOnboardingOpenAccess =
    subscriptionSubtype === 'SHARE' || subscriptionSubtype === 'open_access';

  const handleClose = useCallback(async () => {
    closeModal();
    setTimeout(() => {
      if (showOnboardingOpenAccess) {
        openOnboardingOpenAccessModal();
      }
    }, 500);
  }, [closeModal, openOnboardingOpenAccessModal, showOnboardingOpenAccess]);

  const handleClickNoExperience = useCallback(() => {
    setAnalyticsUserProperties({ experience: 'Starting out' });
    trackEvent('Prompt Experience', {
      name: 'Experience',
      action: 'answer',
      value: 'Starting out',
    });
    handleClose();
  }, [handleClose]);

  const handleClickSomePractice = useCallback(() => {
    setAnalyticsUserProperties({ experience: 'Some Practice' });
    trackEvent('Prompt Experience', {
      name: 'Experience',
      action: 'answer',
      value: 'Some Practice',
    });
    handleClose();
  }, [handleClose]);

  const handleClickMoreExperienced = useCallback(() => {
    setAnalyticsUserProperties({ experience: 'More Experienced' });
    trackEvent('Prompt Experience', {
      name: 'Experience',
      action: 'answer',
      value: 'More Experienced',
    });
    handleClose();
  }, [handleClose]);

  return {
    handleClickNoExperience,
    handleClickSomePractice,
    handleClickMoreExperienced,
    handleClose,
    isOpen,
  };
};
export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
