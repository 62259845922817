import type { FC } from 'react';
import toUpperCase from 'lodash/toUpper';
import { Formik } from 'formik';
import TextField from 'components/Inputs/TextField';
import Layout from '../Layout';
import { Button, Container, Contact, Title, Form, Error } from './styles';
import useConnect from './connect';

const Redeem: FC = () => {
  const { initialValues, loading, onSubmit, title, validationSchema } =
    useConnect();

  return (
    <Layout>
      <Container>
        <Title>{title}</Title>
        <Formik
          initialValues={initialValues}
          validateOnBlur={false}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, errors }) => (
            <Form>
              <TextField
                autoFocus
                isClearable
                id="guest-redeem-gift-card-email-field"
                label="Email"
                name="email"
                type="email"
              />
              <TextField
                isClearable
                id="guest-redeem-gift-card-code-field"
                label="Code"
                name="code"
                normalize={toUpperCase}
              />
              <Button
                disabled={!values.email || !values.code}
                isLoading={loading}
                type="submit"
                id="guest-redeem-gift-card-submit-button"
              >
                Redeem
              </Button>
              {errors.submitError && (
                <>
                  <Error>{errors.submitError}</Error>
                  <Contact>
                    Please contact{' '}
                    <a href="mailto:support@wakingup.com">
                      support@wakingup.com
                    </a>{' '}
                    for help.
                  </Contact>
                </>
              )}
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  );
};

export default Redeem;
