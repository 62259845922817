import { useCallback } from 'react';
import { trackWidgetEvent } from 'services/analytics';
import { PACK_ICONS, PACK_TYPE_NAME } from 'models/Pack';
import type { Props as IconProps } from 'components/Icon/types';
import useFeatureContent from 'graphql/hooks/useFeatureContent';
import type { ConnectProps } from './types';

const useConnect = ({ widgetId }: ConnectProps) => {
  const { featureContent, loading } = useFeatureContent();

  const {
    deepLink,
    imageUrl = '',
    isUnlocked = false,
    length,
    packType,
    preTitle = '',
    subtitle = '',
    title = '',
  } = featureContent || {};

  let type: 'session' | 'series' | undefined;
  if (deepLink?.includes('/course/')) {
    type = 'session';
  } else if (deepLink?.includes('/pack/')) {
    type = 'series';
  }

  const isPublicDeeplink = !!deepLink?.includes('http');
  const deeplink = isUnlocked ? deepLink : '/account/subscription';
  const durationIcon = (
    isUnlocked ? 'playRoundedLight' : 'lockSimpleFilled'
  ) as IconProps['name'];
  const overline = packType ? PACK_TYPE_NAME[packType] : undefined;
  const overlineIcon = packType ? PACK_ICONS[packType] : undefined;

  const handleClick = useCallback(() => {
    if (widgetId) {
      const data: Record<string, unknown> = {
        deep_link: deeplink,
        name: title,
      };
      if (preTitle) data.caption = preTitle;
      if (overline) data.category = overline.toLowerCase();
      if (length) data.duration = length;
      if (type) data.content_type = type;

      trackWidgetEvent(widgetId, data);
    }
  }, [deeplink, length, overline, preTitle, title, type, widgetId]);

  return {
    caption: preTitle,
    deeplink,
    duration: length,
    durationIcon,
    handleClick,
    imageUrl,
    isPublicDeeplink,
    loading,
    overline,
    overlineIcon,
    subtitle,
    title,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
