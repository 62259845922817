import { useQuery, useReactiveVar } from '@apollo/client';
import { MeDocument } from '../generated';
import { initVar } from '../reactive';

const useAuth = () => {
  const init = useReactiveVar(initVar);

  const { data: tokenData } = useQuery(MeDocument, {
    fetchPolicy: 'cache-only',
  });
  const isAuthenticated = !!tokenData?.me?.token;

  return {
    init,
    isAuthenticated,
  };
};

export default useAuth;
export type UseAuth = ReturnType<typeof useAuth>;
