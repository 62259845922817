import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultLogoBlackStacked from 'components/LogoBlackStacked';
import DefaultModal from 'components/Modal';
import { ClearButton } from 'components/Modal/styles';
import DefaultStripeLogo from 'components/Icons/StripeLogo';

export const Modal = styled(DefaultModal)`
  .MuiPaper-root {
    margin: 0;
    max-width: 95vw;
    min-height: 555px;
    padding-top: 0;
    padding: 0 1.4rem 1rem;
    width: 570px;
  }

  ${ClearButton} {
    top: 1.4rem;
    right: 1.4rem;
  }
`;

export const Header = styled.div`
  background-color: ${({ theme }) =>
    theme.name === 'LIGHT' ? theme.colors.coreNeutralWhite : 'inherit'};
  margin: 0 -1.4rem 1rem;
  padding: 1rem 0;
  display: flex;

  ${media.phonePortrait} {
    flex-direction: column;
  }
`;

export const LogoBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1.4rem;
`;

export const Logo = styled(DefaultLogoBlackStacked).attrs(() => ({
  size: 128,
}))`
  margin: 2.5rem 0;

  ${media.phonePortrait} {
    margin: 2rem 0;
  }
`;

export const TextBox = styled.div`
  padding-left: 1.4rem;
  border-left: 1px solid ${({ theme }) => theme.colors.coreNeutral100};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  ${media.phonePortrait} {
    padding: 0 1.4rem;
    align-items: center;
    text-align: center;
  }
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.2;
`;

export const Description = styled.p`
  font-size: 1.15rem;
  line-height: 1.25;
  margin-top: -1.25rem;

  ${media.phonePortrait} {
    margin-top: 0;
  }
`;

export const StripeLogo = styled(DefaultStripeLogo).attrs(({ theme }) => ({
  color:
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralBlack
      : theme.colors.coreNeutralWhite,
  size: 100,
}))`
  align-self: flex-end;
  margin-top: 0.6rem;
`;
