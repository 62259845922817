import { useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import useRemoteConfig from 'graphql/hooks/useRemoteConfig';
import {
  backgroundSoundsVar,
  updateBackgroundSoundsVar,
  volumeVar,
} from 'graphql/reactive';
import { useAudioIsPlaying } from '../Audio/hooks';

const useConnect = () => {
  const { remoteConfig } = useRemoteConfig();
  const {
    backgroundAudioEnabled,
    backgroundAudioProgress,
    backgroundAudioTrackIndex,
    backgroundAudioVolume,
  } = useReactiveVar(backgroundSoundsVar);
  const audioIsPlaying = useAudioIsPlaying();
  const { isMuted, level } = useReactiveVar(volumeVar);

  const audioRef = useRef<HTMLVideoElement>(null);

  const backgroundAudiosChunks = remoteConfig?.backgroundAudiosChunks!!;
  const backgroundAudiosFilepath = remoteConfig?.backgroundAudiosFilepath;
  const audioUrl = backgroundAudiosFilepath?.replace(
    '{index}',
    `${backgroundAudioTrackIndex}`,
  )!!;

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      if (backgroundAudioEnabled && audioIsPlaying) {
        audio.play().finally(() => {
          const currentTime = (backgroundAudioProgress / 100) * audio.duration;
          audio.currentTime = isNaN(currentTime) ? 0 : currentTime;
        });
      } else {
        audio.pause();
        const audioProgress = (audio.currentTime / audio.duration) * 100;
        if (!isNaN(audioProgress)) {
          updateBackgroundSoundsVar({ backgroundAudioProgress: audioProgress });
        }
      }
    }
  }, [audioIsPlaying, backgroundAudioEnabled, backgroundAudioProgress]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.volume = backgroundAudioVolume * (level / 100);
      audio.muted = isMuted;
    }
  }, [backgroundAudioVolume, isMuted, level]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const handleCanPlay = () => {
        const audioDuration = audio.duration;
        const currentTime = (backgroundAudioProgress / 100) * audioDuration;
        if (currentTime && currentTime < audioDuration) {
          audio.currentTime = currentTime;
        }
      };

      const handleEnded = () => {
        audio.currentTime = 0;
        const newTrackIndex = backgroundAudioTrackIndex + 1;
        updateBackgroundSoundsVar({
          backgroundAudioProgress: 0,
          backgroundAudioTrackIndex:
            newTrackIndex < backgroundAudiosChunks ? newTrackIndex : 0,
        });
      };

      audio.addEventListener('canplay', handleCanPlay, { once: true });
      audio.addEventListener('ended', handleEnded);
      return () => {
        audio.removeEventListener('canplay', handleCanPlay);
        audio.removeEventListener('ended', handleEnded);
      };
    }
  }, [
    backgroundAudiosChunks,
    backgroundAudioProgress,
    backgroundAudioTrackIndex,
  ]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      return () => {
        audio.pause();
        const audioProgress = (audio.currentTime / audio.duration) * 100;
        if (!isNaN(audioProgress)) {
          updateBackgroundSoundsVar({ backgroundAudioProgress: audioProgress });
        }
      };
    }
  }, []);

  return {
    audioRef,
    audioUrl,
    backgroundAudioEnabled,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
