/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultModal from 'components/Modal';

export const Modal = styled(DefaultModal)`
  .MuiPaper-root {
    align-items: center;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    justify-content: center;
    padding: 4rem 1rem 2rem;
    text-align: center;
    width: 23.4375rem;
  }

  ${media.phoneLandscape} {
    .MuiPaper-root {
      background-color: ${({ theme }) =>
        theme.name === 'LIGHT'
          ? theme.colors.coreNeutralAurora
          : theme.colors.coreNeutralAbyss};
      padding: 1rem;
      width: 100%;
    }
  }
`;
