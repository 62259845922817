import { useCallback } from 'react';
import {
  ModalType,
  resetModalVar,
  setModalVar,
  type ModalDialog,
  type ModalGiveFreeTrial,
  type ModalIntroCourseSectionCompleted,
  type ModalShareApp,
  type ModalShareLink,
  type ModalStripePayment,
  type ModalUnlockFreeMonth,
  type ModalUnlockFreeMonthSuccess,
  type ModalUpgradeToAnnual,
} from '../reactive';

const useModalActions = () => {
  const closeModal = useCallback(() => {
    resetModalVar();
  }, []);

  const openAddFamilyMemberModal = useCallback(() => {
    setModalVar({ type: ModalType.ADD_FAMILY_MEMBER });
  }, []);

  const openDailyDurationModal = useCallback(() => {
    setModalVar({ type: ModalType.DAILY_DURATION });
  }, []);

  const openDialogModal = useCallback((payload: ModalDialog['payload']) => {
    setModalVar({ payload, type: ModalType.DIALOG });
  }, []);

  const openDismissRetreatModal = useCallback(() => {
    setModalVar({ type: ModalType.DISMISS_RETREAT });
  }, []);

  const openEndRetreatModal = useCallback(() => {
    setModalVar({ type: ModalType.END_RETREAT });
  }, []);

  const openFullScholarshipModal = useCallback(() => {
    setModalVar({ type: ModalType.FULL_SCHOLARSHIP });
  }, []);

  const openGiveFreeTrialModal = useCallback(
    (payload: ModalGiveFreeTrial['payload']) => {
      setModalVar({ payload, type: ModalType.GIVE_FREE_TRIAL });
    },
    [],
  );

  const openIntroCourseSectionCompletedModal = useCallback(
    (payload: ModalIntroCourseSectionCompleted['payload']) => {
      setModalVar({ payload, type: ModalType.INTRO_COURSE_SECTION_COMPLETED });
    },
    [],
  );

  const openJoinRetreatModal = useCallback(() => {
    setModalVar({ type: ModalType.JOIN_RETREAT });
  }, []);

  const openOnboardingExperienceModal = useCallback(() => {
    setModalVar({ type: ModalType.ONBOARDING_EXPERIENCE });
  }, []);

  const openOnboardingOpenAccessModal = useCallback(() => {
    setModalVar({ type: ModalType.ONBOARDING_OPEN_ACCESS });
  }, []);

  const openOnboardingSlidesModal = useCallback(() => {
    setModalVar({ type: ModalType.ONBOARDING_SLIDES });
  }, []);

  const openLeaveRetreatModal = useCallback(() => {
    setModalVar({ type: ModalType.LEAVE_RETREAT });
  }, []);

  const openReserveRetreatModal = useCallback(() => {
    setModalVar({ type: ModalType.RESERVE_RETREAT });
  }, []);

  const openResetContentMenuModal = useCallback(() => {
    setModalVar({ type: ModalType.RESET_CONTENT_MENU });
  }, []);

  const openRetreatLiveMeetingModal = useCallback(() => {
    setModalVar({ type: ModalType.RETREAT_LIVE_MEETING });
  }, []);

  const openScholarshipModal = useCallback(() => {
    setModalVar({ type: ModalType.SCHOLARSHIP });
  }, []);

  const openSelfTimerConfigurationModal = useCallback(() => {
    setModalVar({ type: ModalType.SELF_TIMER_CONFIGURATION });
  }, []);

  const openSendEmailModal = useCallback(() => {
    setModalVar({ type: ModalType.SEND_EMAIL });
  }, []);

  const openShareAppModal = useCallback((payload: ModalShareApp['payload']) => {
    setModalVar({ payload, type: ModalType.SHARE_APP });
  }, []);

  const openShareLinkModal = useCallback(
    (payload: ModalShareLink['payload']) => {
      setModalVar({ payload, type: ModalType.SHARE_LINK });
    },
    [],
  );

  const openStripePaymentModal = useCallback(
    (payload: ModalStripePayment['payload']) => {
      setModalVar({ payload, type: ModalType.STRIPE_PAYMENT });
    },
    [],
  );

  const openSupportModal = useCallback(() => {
    setModalVar({ type: ModalType.SUPPORT });
  }, []);

  const openUnlockFreeMonthModal = useCallback(
    (payload: ModalUnlockFreeMonth['payload']) => {
      setModalVar({ payload, type: ModalType.UNLOCK_FREE_MONTH });
    },
    [],
  );

  const openUnlockFreeMonthSuccessModal = useCallback(
    (payload: ModalUnlockFreeMonthSuccess['payload']) => {
      setModalVar({ payload, type: ModalType.UNLOCK_FREE_MONTH_SUCCESS });
    },
    [],
  );

  const openUpgradeToAnnualModal = useCallback(
    (payload: ModalUpgradeToAnnual['payload']) => {
      setModalVar({ payload, type: ModalType.UPGRADE_TO_ANNUAL });
    },
    [],
  );

  return {
    closeModal,
    openAddFamilyMemberModal,
    openDailyDurationModal,
    openDialogModal,
    openDismissRetreatModal,
    openEndRetreatModal,
    openFullScholarshipModal,
    openGiveFreeTrialModal,
    openIntroCourseSectionCompletedModal,
    openJoinRetreatModal,
    openLeaveRetreatModal,
    openOnboardingExperienceModal,
    openOnboardingOpenAccessModal,
    openOnboardingSlidesModal,
    openReserveRetreatModal,
    openResetContentMenuModal,
    openRetreatLiveMeetingModal,
    openScholarshipModal,
    openSelfTimerConfigurationModal,
    openSendEmailModal,
    openShareAppModal,
    openShareLinkModal,
    openStripePaymentModal,
    openSupportModal,
    openUnlockFreeMonthModal,
    openUnlockFreeMonthSuccessModal,
    openUpgradeToAnnualModal,
  };
};

export default useModalActions;
export type UseModalActions = ReturnType<typeof useModalActions>;
