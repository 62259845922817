import styled from 'styled-components';
import { motion } from 'framer-motion';
import DefaultButton from 'components/Button';
import DefaultTextField from 'components/Inputs/TextField';

export const TextField = styled(DefaultTextField)`
  margin-bottom: 1.25rem;
`;

export const ButtonContainer = styled.div`
  margin-top: 2.625rem;
`;

export const Button = styled(DefaultButton)`
  height: 3rem;
  background-color: ${({ theme }) => theme.colors.coreBlue500};
  border: 2px solid ${({ theme }) => theme.colors.coreBlue500};
  color: ${({ theme }) => theme.colors.coreNeutralWhite};

  &:not(:disabled):hover {
    background-color: ${({ theme }) => theme.colors.coreBlue700};
    color: ${({ theme }) => theme.colors.coreNeutralWhite};
  }
`;

export const Error = styled(motion.div).attrs(() => ({
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  transition: { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] },
}))`
  color: ${({ theme }) => theme.colors.fontLabelError};
  font-size: 0.8rem;
  letter-spacing: 0.01rem;
  line-height: 1.33;
  margin-top: ${({ theme }) => theme.measures.xs};
`;
