import styled from 'styled-components';
import { Body as DefaultBody } from 'components/Typography';

export const NoResults = styled.div`
  margin-top: 1rem;
`;

export const Body = styled(DefaultBody).attrs(() => ({ $weight: 'bold' }))`
  padding: 0.75rem 0;
`;

export const MailTo = styled.a`
  color: ${({ theme }) => theme.colors.fontEmphasized};
`;
