import { useMemo } from 'react';
import { format, isValid, parseISO } from 'date-fns/esm';
import { LogicProps } from './types';

const useLogic = ({ firstName, joinedAt, lastName }: LogicProps) => {
  const fullName = useMemo(
    () => `${firstName} ${lastName}`.trim(),
    [firstName, lastName],
  );

  const joinDate = useMemo(() => {
    const date = parseISO(joinedAt);

    if (!isValid(date)) return;

    return format(date, 'MM/yyyy');
  }, [joinedAt]);

  return {
    fullName,
    joinDate,
  };
};

export default useLogic;
