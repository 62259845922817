import { makeVar } from '@apollo/client';
import { PlayerAutoplayType, PlayerSource } from 'models/Player';

export type CoursePlayer = {
  autoplay: boolean;
  autoplayType: PlayerAutoplayType | null;
  endPosition: number | null;
  endPositionPaused: boolean;
  hash: string;
  initialPosition: number;
  isMinimized: boolean;
  packId: string;
  queryString?: string;
  source: PlayerSource;
};

export const emptyCoursePlayer: CoursePlayer = {
  autoplay: true,
  autoplayType: null,
  endPosition: null,
  endPositionPaused: false,
  hash: '',
  initialPosition: 0,
  isMinimized: false,
  packId: '',
  source: PlayerSource.PACK,
};

export const coursePlayerVar = makeVar<CoursePlayer>(emptyCoursePlayer);

export const resetCoursePlayerVar = () => {
  coursePlayerVar(emptyCoursePlayer);
};

export const setCoursePlayerVar = (data: Partial<CoursePlayer>) => {
  coursePlayerVar({ ...emptyCoursePlayer, ...data });
};

export const updateCoursePlayerVar = (data: Partial<CoursePlayer>) => {
  coursePlayerVar({ ...coursePlayerVar(), ...data });
};
