import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
  Body as DefaultBody,
  Overline as DefaultOverline,
  Title2 as DefaultTitle2,
} from 'components/Typography';
import DefaultButtonCTA from 'components/ButtonCTA';
import { media } from 'styles/mixins';

export const Container = styled.div`
  padding-bottom: 1.5rem;
`;

export const Flex = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  position: relative;

  ${media.phoneLandscape} {
    justify-content: space-between;
  }
`;

export const Info = styled.div`
  margin-right: 1.5625rem;
  margin-top: 0.5rem;
  max-width: 12.5rem;
`;

export const Overline = styled(DefaultOverline)`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutral600
      : theme.colors.coreNeutral100};
  margin-bottom: ${({ theme }) => theme.measures.xs};
  margin-bottom: 0.625rem;
`;

export const Title = styled(DefaultTitle2)``;

export const Image = styled.img`
  display: block;
  height: 6.6875rem;
  width: 6.6875rem;
`;

export const Collapsable = styled(motion.div).attrs(() => ({
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  transition: { duration: 0.5, ease: [0.55, 0.62, 0.23, 0.98] },
}))`
  overflow: hidden;
`;

export const Description = styled(DefaultBody).attrs(() => ({
  $size: 'regular',
  $weight: 'medium',
}))`
  margin-top: 0.6875rem;
  margin-bottom: 0.5rem;
`;

export const ButtonCTA = styled(DefaultButtonCTA)``;
