import { memo, useCallback, useState, type FC, type MouseEvent } from 'react';
import { useReactiveVar } from '@apollo/client';
import { AnimatePresence } from 'framer-motion';
import { backgroundSoundsVar, setOptionsMenuVar } from 'graphql/reactive';
import { ButtonIcon, Message } from './styles';
import type { Props } from './types';

const BackgroundSounds: FC<Props> = ({ disabled = false }) => {
  const { backgroundAudioEnabled } = useReactiveVar(backgroundSoundsVar);

  const [showMessage, setShowMessage] = useState<boolean>(false);

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOptionsMenuVar({
      anchorEl: event.currentTarget,
      offset: '0, -100%p',
      placement: 'top-start',
      type: 'backgroundSounds',
    });
  }, []);

  const handleOpenMessage = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 3000);
  }, []);

  return (
    <>
      {disabled && (
        <AnimatePresence initial={false}>
          {showMessage && (
            <Message>This track contains background sounds</Message>
          )}
        </AnimatePresence>
      )}
      <ButtonIcon
        $disabled={disabled}
        active={!disabled && backgroundAudioEnabled}
        aria-label={
          disabled
            ? 'open message indicating that this track already contains bacground sounds'
            : 'open background sounds menu'
        }
        iconName={disabled ? 'noMusicNoteLight' : 'musicNoteLight'}
        onClick={disabled ? handleOpenMessage : handleOpenMenu}
      />
    </>
  );
};

export default memo(BackgroundSounds);
