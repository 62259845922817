import type { FC } from 'react';
import {
  Container,
  Label,
  Slider,
  Time,
  TimePending,
  TimePlayed,
} from './styles';
import { Props } from '../types';
import useLogic from '../logic';

const ProgressBarMaximized: FC<Props> = ({
  className,
  duration,
  isLoading = false,
  isSeekDisabled = false,
  onChangePosition = () => null,
  position = 0,
}) => {
  const {
    handleSliderClick,
    handleSliderChange,
    handleSliderChangeCommitted,
    localPosition,
    timePending,
    timePlayed,
  } = useLogic({
    duration,
    onChangePosition,
    position,
  });

  return (
    <Container className={className}>
      <Label id="player-progress">Audio progress:</Label>
      <Slider
        aria-labelledby="player-progress"
        max={duration}
        min={0}
        onClick={handleSliderClick}
        onChange={!isSeekDisabled ? handleSliderChange : undefined}
        onChangeCommitted={
          !isSeekDisabled ? handleSliderChangeCommitted : undefined
        }
        step={1}
        value={!duration ? 0 : localPosition}
      />
      <Time>
        <TimePlayed>{isLoading ? '--:--' : timePlayed}</TimePlayed>
        <TimePending>- {isLoading ? '--:--' : timePending}</TimePending>
      </Time>
    </Container>
  );
};

export default ProgressBarMaximized;
