import styled, { css } from 'styled-components';
import Select from 'react-select';
import { prettyScrollbar } from 'styles/mixins';
import colorWithAlpha from 'lib/color';
import DefaultWrapper from '../Wrapper';

export const Container = styled.div``;

export const Wrapper = styled(DefaultWrapper)<{ readOnly: boolean }>`
  background-color: inherit;
  border-bottom: 1px solid
    ${({ theme }) =>
      theme.name === 'LIGHT'
        ? colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.33 })
        : colorWithAlpha({
            color: theme.colors.coreNeutralWhite,
            alpha: 0.33,
          })};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  pointer-events: ${({ readOnly }) => (readOnly ? 'none' : 'default')};
  user-select: ${({ readOnly }) => (readOnly ? 'none' : 'default')};
  & label {
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.font.weight.book};
    line-height: 1.25;
    margin: 0 0 0.25rem 0;
    text-transform: uppercase;
  }
`;

export const ReactSelect = styled(Select)<{
  $hasLabel: boolean;
  $hideSeparator: boolean;
}>`
  .react-select__control {
    background: transparent;
    cursor: pointer;
    min-height: 0;
  }
  .react-select__control,
  .react-select__control--is-focused,
  .react-select__control--menu-is-open {
    border: none;
    box-shadow: none;
  }
  .react-select__value-container,
  .react-select__value-container--has-value {
    margin: 0;
    padding: 0;
  }
  .react-select__placeholder,
  .react-select__single-value {
    margin: 0;
    padding: 0;
  }
  .react-select__single-value {
    font-size: 0.9375rem;
    font-weight: 400;
  }
  .react-select__placeholder {
    color: ${({ theme }) => theme.colors.fontDeemphasized};
    opacity: 0.8;
  }
  .react-select__indicator {
    color: ${({ theme }) => theme.colors.fontDeemphasized};
    cursor: pointer;
    padding-bottom: 0;
    padding-top: 0;
  }
  .react-select__indicator-separator {
    color: ${({ theme }) => theme.colors.fontDeemphasized};
    margin-bottom: 1px;
    margin-top: 1px;

    ${({ $hideSeparator }) =>
      $hideSeparator &&
      css`
        display: none;
      `}
  }
  .react-select__input {
    input {
      padding: 0;
      margin: 0;
    }
  }
  .react-select__menu {
    margin-top: ${({ $hasLabel }) => ($hasLabel ? '0.6rem' : '0')};
  }
  .react-select__menu-list {
    ${prettyScrollbar({})}
  }
  .react-select__option {
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.fontLabelError};
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.01rem;
  margin-top: ${({ theme }) => theme.measures.xxs};
  text-align: left;
`;
