import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from 'services/analytics';
import formatRetreatDate from 'lib/formatRetreatDate';
import {
  ModalType,
  updateDismissRetreatLastCheckedDate,
} from 'graphql/reactive';
import useMe from 'graphql/hooks/useMe';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import useRetreat from 'graphql/hooks/useRetreat';
import addDays from 'date-fns/addDays';
import parseISO from 'date-fns/parseISO';

const useConnect = () => {
  const { id: userId } = useMe();
  const { isOpen } = useModal(ModalType.DISMISS_RETREAT);
  const { closeModal } = useModalActions();
  const { retreat } = useRetreat();
  const navigate = useNavigate();

  const hash = retreat?.hash;
  const subtitle = retreat?.subtitle;
  const title = retreat?.title;
  const pretitle = retreat
    ? formatRetreatDate(retreat.startDate, retreat.endDate)
    : '';
  const startDate = retreat?.startDate;

  const handleClose = useCallback(() => {
    trackEvent('Retreat Dismiss Survey Submit', {
      event_hash: hash,
      name: title,
      response: 'Cancel',
    });

    closeModal();
  }, [closeModal, hash, title]);

  const handleRemind = useCallback(() => {
    trackEvent('Retreat Dismiss Survey Submit', {
      event_hash: hash,
      name: title,
      response: 'Remind me later',
    });

    updateDismissRetreatLastCheckedDate(userId);

    closeModal();
  }, [closeModal, hash, title, userId]);

  const handleNotInterested = useCallback(() => {
    trackEvent('Retreat Dismiss Survey Submit', {
      event_hash: hash,
      name: title,
      response: `I'm not interested`,
    });

    const unreachableCheckedDate = startDate
      ? addDays(parseISO(startDate), 1).toISOString()
      : undefined;
    updateDismissRetreatLastCheckedDate(userId, unreachableCheckedDate);

    closeModal();
    navigate('/practice/retreats');
  }, [closeModal, hash, navigate, startDate, title, userId]);

  return {
    handleClose,
    handleNotInterested,
    handleRemind,
    isOpen,
    pretitle,
    startDate,
    subtitle,
    title,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
