/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { NavLink as DefaultNavLink } from 'react-router-dom';
import colorWithAlpha from 'lib/color';

export const Tab = styled(DefaultNavLink)<{ $isActive?: boolean }>`
  background: none;
  border: none;
  border-bottom: solid 0.125rem transparent;
  cursor: pointer;
  text-decoration: none;
  padding: 0 0 ${({ theme }) => theme.measures.m} 0;
  outline: none;
  line-height: 1.429;
  letter-spacing: 0.01em;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 0.875rem;
  display: inline-block;
  white-space: nowrap;

  ${({ theme }) =>
    theme.name === 'LIGHT'
      ? css`
          color: ${theme.colors.coreNeutral500};

          &:hover {
            border-bottom: solid 0.125rem
              ${colorWithAlpha({
                color: theme.colors.coreNeutralBlack,
                alpha: 0.1,
              })};
            color: ${colorWithAlpha({
              color: theme.colors.coreNeutralBlack,
              alpha: 0.55,
            })};
          }
          &:focus {
            border-bottom: solid 0.125rem
              ${colorWithAlpha({
                color: theme.colors.coreNeutralBlack,
                alpha: 0.33,
              })};
            color: ${colorWithAlpha({
              color: theme.colors.coreNeutralBlack,
              alpha: 0.6,
            })};
          }
        `
      : css`
          color: ${theme.colors.coreNeutral400};

          &:hover {
            border-bottom: solid 0.125rem
              ${colorWithAlpha({
                color: theme.colors.coreNeutralWhite,
                alpha: 0.05,
              })};
            color: ${colorWithAlpha({
              color: theme.colors.coreNeutralWhite,
              alpha: 0.5,
            })};
          }
          &:focus {
            border-bottom: solid 0.125rem
              ${colorWithAlpha({
                color: theme.colors.coreNeutralWhite,
                alpha: 0.15,
              })};
            color: ${colorWithAlpha({
              color: theme.colors.coreNeutralWhite,
              alpha: 0.6,
            })};
          }
        `}

  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      border-bottom: solid 0.125rem ${theme.colors.fontEmphasized};
      color: ${theme.colors.fontEmphasized};

      &:focus,
      &:hover {
        border-bottom: solid 0.125rem ${theme.colors.fontEmphasized};
        color: ${theme.colors.fontEmphasized};
      }
    `}
`;
