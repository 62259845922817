import { useMemo } from 'react';
import { useQuery, type QueryHookOptions } from '@apollo/client';
import notEmpty from 'lib/notEmpty';
import {
  normalizeLifetimePlan,
  normalizeSubscriptionPlan,
  PLAN_ORDER_BY_TYPE,
} from 'models/SubscriptionPlan';
import { isPreviewMode } from 'services/storage';
import {
  GetLifetimePriceDocument,
  GetSubscriptionPlansDocument,
  SubscriptionPlanType,
  type GetLifetimePriceQuery,
  type GetLifetimePriceQueryVariables,
  type GetSubscriptionPlansQuery,
  type GetSubscriptionPlansQueryVariables,
} from '../generated';

const useSubscriptionPlans = (
  planOptions: QueryHookOptions<
    GetSubscriptionPlansQuery,
    GetSubscriptionPlansQueryVariables
  > = {},
  lifetimeOptions: QueryHookOptions<
    GetLifetimePriceQuery,
    GetLifetimePriceQueryVariables
  > = {},
) => {
  const {
    data: subscriptionPlansData,
    loading: isLoadingSubscriptionPlansData,
  } = useQuery(GetSubscriptionPlansDocument, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    ...planOptions,
  });

  const { data: lifetimePriceData, loading: isLoadingLifetimePriceData } =
    useQuery(GetLifetimePriceDocument, {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      ...lifetimeOptions,
    });

  const lifetimeEmail = lifetimePriceData?.lifetimePrice?.email || undefined;

  const plans = useMemo(() => {
    if (isLoadingSubscriptionPlansData || isLoadingLifetimePriceData) {
      return [];
    }

    const isPreview = isPreviewMode();

    const formattedPlans = subscriptionPlansData?.subscriptionPlans
      ? subscriptionPlansData.subscriptionPlans
          .filter(notEmpty)
          .map(normalizeSubscriptionPlan)
      : [];

    if (lifetimePriceData?.lifetimePrice) {
      formattedPlans.push(
        normalizeLifetimePlan(lifetimePriceData.lifetimePrice),
      );
    }
    return formattedPlans
      .filter((p) => isPreview || p.type !== SubscriptionPlanType.family)
      .sort((a, b) => PLAN_ORDER_BY_TYPE[a.type] - PLAN_ORDER_BY_TYPE[b.type]);
  }, [
    isLoadingLifetimePriceData,
    isLoadingSubscriptionPlansData,
    subscriptionPlansData,
    lifetimePriceData,
  ]);

  return {
    lifetimeEmail,
    loading:
      (plans.length === 0 && isLoadingSubscriptionPlansData) ||
      isLoadingLifetimePriceData,
    plans,
  };
};

export default useSubscriptionPlans;
export type UseSubscriptionPlans = ReturnType<typeof useSubscriptionPlans>;
