/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const FEATURE_CONTENT_DATA_FRAGMENT = gql`
  fragment FeatureContentData on FeatureContent {
    deep_link
    hash
    image_url
    is_unlocked
    length
    pack_type
    pre_title
    subtitle
    title
  }
`;
