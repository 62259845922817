import { useReactiveVar } from '@apollo/client';
import { timerIsPlayingVar, timerPositionVar } from 'graphql/reactive';

export const useTimer = () => {
  const timerIsPlaying = useReactiveVar(timerIsPlayingVar);
  const timerPosition = useReactiveVar(timerPositionVar);

  return {
    timerIsPlaying,
    timerPosition,
  };
};

export const useTimerIsPlaying = () => {
  const isPlaying = useReactiveVar(timerIsPlayingVar);
  return isPlaying;
};

export const useTimerPosition = () => {
  const position = useReactiveVar(timerPositionVar);
  return position;
};
