import styled from 'styled-components';
import DefaultMaterialSwitch from '@mui/material/Switch';
import { Body as DefaultBody } from 'components/Typography';
import { Props } from './types';

const WIDTH = 48;
const HEIGHT = 24;

export const Container = styled.div<{ $labelPosition: Props['labelPosition'] }>`
  align-items: center;
  display: flex;
  flex-flow: ${({ $labelPosition }) =>
    $labelPosition === 'right' ? 'row-reverse nowrap' : 'row nowrap'};
  justify-content: flex-start;
`;

export const Label = styled(DefaultBody)<{
  $labelPosition: Props['labelPosition'];
}>`
  margin-left: ${({ $labelPosition, theme }) =>
    $labelPosition === 'right' ? theme.measures.s : '0'};
  margin-right: ${({ $labelPosition, theme }) =>
    $labelPosition === 'right' ? '0' : theme.measures.m};
`;

export const MaterialSwitch = styled(DefaultMaterialSwitch)`
  &.MuiSwitch-root {
    height: ${HEIGHT}px;
    margin: 0;
    padding: 0;
    width: ${WIDTH}px;
  }
  .MuiSwitch-switchBase {
    color: ${({ theme }) =>
      theme.name === 'LIGHT'
        ? theme.colors.coreNeutral500
        : theme.colors.coreNeutral200};
    padding: 0.125rem;
    &.Mui-disabled {
      color: ${({ theme }) =>
        theme.name === 'LIGHT'
          ? theme.colors.coreNeutral500
          : theme.colors.coreNeutral200};
      opacity: 0.5;
      & + .MuiSwitch-track {
        background-color: ${({ theme }) =>
          theme.name === 'LIGHT'
            ? theme.colors.coreNeutral50
            : theme.colors.coreNeutral600};
        opacity: 0.5;
      }
    }
    &.Mui-checked {
      transform: translateX(${WIDTH - HEIGHT}px);
      color: ${({ theme }) =>
        theme.name === 'LIGHT'
          ? theme.colors.coreTeal700
          : theme.colors.coreTeal500};
      & + .MuiSwitch-track {
        background-color: ${({ theme }) =>
          theme.name === 'LIGHT'
            ? theme.colors.coreTeal500
            : theme.colors.coreTeal700};
        border: none;
        opacity: ${({ theme }) => (theme.name === 'LIGHT' ? '0.25' : '1')};
      }
      &.Mui-disabled {
        & + .MuiSwitch-track {
          background-color: ${({ theme }) =>
            theme.name === 'LIGHT'
              ? theme.colors.coreTeal500
              : theme.colors.coreTeal700};
          opacity: ${({ theme }) => (theme.name === 'LIGHT' ? '0.125' : '0.5')};
        }
      }
    }
  }
  .MuiSwitch-thumb {
    box-shadow: none;
    height: ${HEIGHT - 10}px;
    margin: 0.188rem 0.25rem 0 0.188rem;
    width: ${HEIGHT - 10}px;
  }
  .MuiIconButton-root:hover {
    background-color: inherit;
  }
  .MuiSwitch-track {
    border-radius: ${HEIGHT / 2}px;
    border: none;
    background-color: ${({ theme }) =>
      theme.name === 'LIGHT'
        ? theme.colors.coreNeutral50
        : theme.colors.coreNeutral600};
    opacity: 1;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .Mui-focusVisible {
    height: ${HEIGHT}px;

    .MuiSwitch-thumb {
      margin: 0.25rem 0.2rem 0.25rem 0.188rem;
    }
  }
`;
