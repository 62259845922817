import type { FC } from 'react';
import { Props } from './types';

const PlayCircleLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M12 21a9 9 0 100-18 9 9 0 000 18z"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.376 12.5a.5.5 0 000-.832l-3.099-2.066a.5.5 0 00-.777.416v4.132a.5.5 0 00.777.416l3.099-2.066z"
      fill="currentColor"
    />
  </svg>
);

export default PlayCircleLight;
