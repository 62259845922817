import { memo } from 'react';
import type { FC } from 'react';
import { media } from 'styles/mixins';
import useMediaQuery from 'lib/useMediaQuery';
import { Caption, Container, Image } from './styles';
import type { Props } from './types';

const CoverImage: FC<Props> = ({
  alt,
  caption,
  className,
  desktopImageUrl,
  mobileImageUrl,
}) => {
  const isPhonePortrait = useMediaQuery(media.phonePortrait);
  const imageSrc =
    isPhonePortrait && mobileImageUrl ? mobileImageUrl : desktopImageUrl;

  return (
    <Container className={className}>
      <Image $isLoading={!imageSrc} alt={alt} src={imageSrc} />
      {caption && <Caption>{caption}</Caption>}
    </Container>
  );
};

export default memo(CoverImage);
