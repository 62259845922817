import { useEffect } from 'react';
import type { FC } from 'react';
import lottieAnimation from 'assets/animations/confirmation-success.json';
import lottie from 'lottie-web/build/player/lottie_light';
import { Container, Message, Animation } from './styles';
import type { Props } from './types';

const animationName = 'lottie-confirmation-success';

const AnimatedConfirmation: FC<Props> = ({
  children,
  className,
  id,
  message,
}) => {
  useEffect(() => {
    const element = document.getElementById(animationName);
    lottie.loadAnimation({
      container: element!,
      renderer: 'svg',
      animationData: lottieAnimation,
      name: animationName,
      autoplay: true,
      loop: false,
    });
  }, []);

  return (
    <Container className={className}>
      <Animation id={animationName} />
      {message && <Message id={id}>{message}</Message>}
      {children}
    </Container>
  );
};

export default AnimatedConfirmation;
