import { memo, type FC } from 'react';
import { isDesktop } from 'react-device-detect';
import {
  AppsDownload,
  Container,
  Description,
  Overline,
  Title,
} from './styles';

const JoinLive: FC = () => {
  return (
    <Container>
      <Overline>JOIN LIVE</Overline>
      <Title>Live audio is only avaible in the app. </Title>
      {isDesktop ? (
        <>
          <Description>
            If you don't already have our mobile app installed, just open your
            phone's camera app to scan the QR code below.
          </Description>
          <AppsDownload campaign="live" mode="qr" qrCodeId="live" />
        </>
      ) : (
        <>
          <Description>
            If you don't already have our mobile app installed, just click the
            button below.
          </Description>
          <AppsDownload buttonsMode="platform" campaign="live" mode="buttons" />
        </>
      )}
    </Container>
  );
};

export default memo(JoinLive);
