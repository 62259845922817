import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultAppsDownload from 'components/AppsDownload';
import { QRCodeContainer } from 'components/AppsDownload/styles';

export const Container = styled.div`
  padding-top: 6rem;

  ${media.tabletPortrait} {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    padding-top: 2rem;
    text-align: center;
  }
`;

export const Overline = styled.h1`
  color: ${({ theme }) => theme.colors.fontEmphasized};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  letter-spacing: 0.1em;
  line-height: 1.5;
  text-transform: uppercase;
`;

export const Title = styled.p`
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.2;
  margin-top: 0.375rem;

  ${media.phonePortrait} {
    font-size: 1.4rem;
    max-width: 18rem;
  }
`;

export const Description = styled.p`
  font-size: 1rem;
  line-height: 1.2;
  margin-top: 0.6875rem;
  max-width: 28rem;

  ${media.tabletPortrait} {
    max-width: 95%;
  }
`;

export const AppsDownload = styled(DefaultAppsDownload)`
  margin-top: 2rem;
  min-width: 10rem;
  width: 10rem;

  ${QRCodeContainer} {
    width: 100%;
  }
`;
