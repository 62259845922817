/* eslint-disable import/prefer-default-export */
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultLoader from './Loader';

export const Container = styled.div``;

export const Label = styled.p`
  color: #303d4e;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 1.1875;
  margin-bottom: 1.625rem;
  text-align: start;
`;

export const Loader = styled(DefaultLoader)`
  height: 7.25rem;
  margin-bottom: 4.9375rem;
`;

export const Buttons = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);

  ${media.phonePortrait} {
    grid-template-columns: 1fr;
  }
`;

export const Error = styled(motion.div).attrs(() => ({
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  transition: { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] },
}))`
  color: ${({ theme }) => theme.colors.fontLabelError};
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.01rem;
  margin-top: ${({ theme }) => theme.measures.xxs};
  text-align: left;
`;
