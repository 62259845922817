import { memo, useEffect, type FC } from 'react';
import { isAndroid, isDesktop, isMobile, isIOS } from 'react-device-detect';
import {
  createAppsFlyerLinkV2,
  showAppsFlyerQRCodeV2,
} from 'services/analytics/appsflyerV2';
import type { Props } from './types';

const Attribution: FC<Props> = ({ children, qrCodeId, ...appsflyerProps }) => {
  const attributionData = createAppsFlyerLinkV2(appsflyerProps);
  const { all } = attributionData;

  useEffect(() => {
    if (qrCodeId && !isMobile && all) {
      showAppsFlyerQRCodeV2(qrCodeId);
    }
  }, [all, qrCodeId]);

  return (
    <>
      {children({
        ...attributionData,
        isAndroid,
        isDesktop,
        isIOS,
        isMobile,
      })}
    </>
  );
};

export default memo(Attribution);
