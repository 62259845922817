import styled from 'styled-components';
import DefaultModal from 'components/Modal';
import Text from 'components/Text';
import colorWithAlpha from 'lib/color';
import { prettyScrollbar } from 'styles/mixins';
import DefaultTextField from 'components/Inputs/TextField';

export const Modal = styled(DefaultModal)``;

export const Content = styled.div`
  ${prettyScrollbar({ vertical: true })};
  background-color: ${({ theme }) =>
    colorWithAlpha({
      color: theme.colors.coreNeutralBlack,
      alpha: 0.01,
    })};
  flex: 1 1 auto;
  overflow-y: visible;
  padding: 0;
`;

export const Errors = styled(Text)`
  padding-top: ${({ theme }) => theme.measures.m};
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: ${({ theme }) =>
    theme.name === 'LIGHT' ? theme.colors.coreRed700 : theme.colors.coreRed500};
  text-align: center;
`;

export const TextField = styled(DefaultTextField)`
  padding-bottom: ${({ theme }) => theme.measures.l};
`;
