import { useMemo } from 'react';
import usePackCourses from './usePackCourses';

const usePackCourse = (courseKey?: string, packKey?: string) => {
  const { packCourses, loading: isLoadingPackCourses } = usePackCourses();

  const course = useMemo(() => {
    if (!courseKey) return undefined;

    const lwCourseKey = courseKey.toLowerCase();
    const filteredPackCourses = packCourses.filter(
      ({ bdId, hash, id }) =>
        bdId.toLowerCase() === lwCourseKey ||
        hash.toLowerCase() === lwCourseKey ||
        id.toLowerCase() === lwCourseKey,
    );

    if (filteredPackCourses.length === 0) {
      return undefined;
    }

    if (filteredPackCourses.length === 1) {
      return filteredPackCourses[0];
    }

    if (packKey) {
      const lwPackKey = packKey.toLowerCase();
      const packCourse = filteredPackCourses.find(
        ({ packHash, packId }) =>
          packHash.toLowerCase() === lwPackKey ||
          packId.toLowerCase() === lwPackKey,
      );
      if (packCourse) return packCourse;
    }

    return (
      filteredPackCourses.find(({ packIsPrimaryPack }) => packIsPrimaryPack) ||
      filteredPackCourses.find(({ isIntroCourse }) => !isIntroCourse) ||
      filteredPackCourses[0]
    );
  }, [courseKey, packCourses, packKey]);

  return {
    course,
    loading: isLoadingPackCourses,
  };
};

export default usePackCourse;
export type UsePackCourse = ReturnType<typeof usePackCourse>;
