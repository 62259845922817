import { trackMediaEvent } from 'services/analytics';
import { getSettings, updateSettings } from '.';

const checkMediaInterrupted = async () => {
  const { player, recentlyPlayed } = await getSettings();
  const { code, courseId } = player || {};

  if (code && courseId) {
    await trackMediaEvent('Media Interrupted', courseId, { code });

    const { last_known_location } =
      recentlyPlayed?.find((rp) => rp.course_hash === courseId) || {};

    await updateSettings(
      {
        player: null,
        recentlyPlayed: [
          {
            course_hash: courseId,
            last_known_location:
              last_known_location === undefined ? -1 : last_known_location,
          },
          ...recentlyPlayed.filter((rp) => rp.course_hash !== courseId),
        ],
      },
      true,
    );
  }
};

export default checkMediaInterrupted;
