import {
  number as yupNumber,
  object as yupObject,
  string as yupString,
} from 'yup';
import type { FullScholarshipPrice } from './types';

export const FULL_SCHOLARSHIP_PRICES: FullScholarshipPrice[] = [
  { description: '(50% Scholarship)', price: 4900 },
  { description: '(60% Scholarship)', price: 3900 },
  { description: '(70% Scholarship)', price: 2900 },
];

export const VALIDATION_SCHEMA = yupObject({
  email: yupString()
    .max(150)
    .required('Please, enter an email address')
    .email('Invalid email address'),
  price: yupNumber().nullable().required('Please, choose one of the options'),
});
