import { object as yupObject, string as yupString } from 'yup';
import { Step, type FormFields } from './types';

export const initialValues: FormFields = {
  email: '',
  name: '',
  message: '',
  step: Step.message,
  submitError: '',
};

export const validationSchema = yupObject({
  email: yupString()
    .required('Please enter a valid email')
    .email('Please enter a valid email'),
  name: yupString(),
  message: yupString(),
});
