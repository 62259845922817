import { useCallback } from 'react';
import { trackEvent } from 'services/analytics';
import formatCents from 'lib/formatCents';
import { ModalType } from 'graphql/reactive';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import useSubscriptionPlans from 'graphql/hooks/useSubscriptionPlans';
import { ScholarshipFormFields } from './types';

const CODE = 'PARTIAL';
const SCREEN = 'scholarship_modal';

const useConnect = () => {
  const { isOpen } = useModal(ModalType.SCHOLARSHIP);
  const { closeModal, openFullScholarshipModal, openStripePaymentModal } =
    useModalActions();
  const { plans, loading } = useSubscriptionPlans({
    variables: { code: CODE },
  });

  const options = plans
    .filter((plan) => plan.interval === 'year')
    .map((plan) => ({
      label: `${formatCents(plan.amount)} / Annual`,
      value: plan.id,
      price: plan.amount,
    }))
    .sort((a, b) => b.price - a.price);

  const handleContactUs = useCallback(() => {
    trackEvent('Scholarship - Request a Free Year of Waking Up', {
      screen: SCREEN,
    });

    closeModal();
    setTimeout(() => openFullScholarshipModal(), 500);
  }, [closeModal, openFullScholarshipModal]);

  const handleClose = useCallback(() => {
    trackEvent('Scholarship Application Aborted', { screen: SCREEN });
    closeModal();
  }, [closeModal]);

  const handleSubmit = useCallback(
    (values: ScholarshipFormFields) => {
      const plan = plans.find((p) => p.id === values.scholarshipOption);
      if (plan) {
        trackEvent('Purchase Initiate Checkout', {
          screen: SCREEN,
          source: 'scholarship',
          subscription_option: plan.sku,
        });

        closeModal();
        setTimeout(() => {
          openStripePaymentModal({
            isPublic: true,
            planAmount: plan.amount,
            planId: plan.id,
            planInterval: plan.interval,
            planType: plan.type,
            promoCode: CODE,
            screen: SCREEN,
          });
        }, 500);
      }
    },
    [closeModal, openStripePaymentModal, plans],
  );

  return {
    handleClose,
    handleContactUs,
    handleSubmit,
    isOpen,
    loading,
    options,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
