/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import DefaultButtonIcon from 'components/ButtonIcon';

export const ButtonIcon = styled(DefaultButtonIcon)`
  > svg {
    height: 1.125rem;
    width: auto;
  }
`;
