import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { media } from 'styles/mixins';
import colorWithAlpha from 'lib/color';
import DefaultButtonIcon from 'components/ButtonIcon';

export const ButtonIcon = styled(DefaultButtonIcon)<{ $disabled: boolean }>`
  margin-right: 1rem;

  ${media.phoneLandscape} {
    margin-right: 0.625rem;
  }

  ${({ $disabled, theme }) =>
    $disabled &&
    css`
      color: ${colorWithAlpha({
        color: theme.colors.coreNeutralAurora,
        alpha: 0.5,
      })};
    `}
`;

export const Message = styled(motion.div).attrs(() => ({
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  initial: { opacity: 0 },
}))`
  align-items: center;
  background: rgba(61, 61, 61, 0.9);
  border-radius: 6.2rem;
  box-shadow: 17.3946px 41.7075px 51.9417px rgba(0, 0, 0, 0.115977),
    10.4143px 24.9707px 29.6583px rgba(0, 0, 0, 0.0978192),
    5.45406px 13.0773px 16.4152px rgba(0, 0, 0, 0.0821808),
    2.24447px 5.38162px 9.11953px rgba(0, 0, 0, 0.0640233),
    0.516228px 1.23777px 4.67872px rgba(0, 0, 0, 0.038309);
  color: ${({ theme }) => theme.colors.coreNeutralAurora};
  display: flex;
  flex-flow: row nowrap;
  font-size: 0.86rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  justify-content: center;
  left: 50%;
  letter-spacing: -0.0172rem;
  line-height: 1.25;
  max-width: 30rem;
  padding: 1.25rem 1.9rem;
  position: absolute;
  text-align: center;
  top: 0;
  transform: translate(-50%, -115%);
  width: 100%;
`;
