import { useCallback, ChangeEvent, ClipboardEvent, KeyboardEvent } from 'react';
import type { FC } from 'react';
import { Label, StyledInput } from './styles';
import { Props } from './types';

const Input: FC<Props> = ({
  id,
  index,
  inputRef,
  label,
  onChange,
  onKeyDown,
  onPaste,
  ...rest
}) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      onChange(e.target.value.slice(-1), index),
    [index, onChange],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => onKeyDown(e, index),
    [index, onKeyDown],
  );

  const handlePaste = useCallback(
    (e: ClipboardEvent<HTMLInputElement>) => onPaste(e, index),
    [index, onPaste],
  );

  return (
    <>
      <Label htmlFor={id}>{label}</Label>
      <StyledInput
        id={id}
        ref={inputRef}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        {...rest}
      />
    </>
  );
};

export default Input;
