import { TrialDuration } from 'graphql/generated';

export const trialDaysByTrialDuration: Record<TrialDuration, number> = {
  [TrialDuration.fourteen_days]: 14,
  [TrialDuration.thirty_days]: 30,
};

export const getTrialDays = (trialDuration: TrialDuration | undefined) => {
  if (!trialDuration) {
    return 0;
  }

  return trialDaysByTrialDuration[trialDuration] || 0;
};
