import { memo } from 'react';
import type { FC } from 'react';
import { Tab } from './styles';
import type { Props } from './types';

const NavTab: FC<Props> = ({
  children,
  className,
  end,
  id,
  isActive,
  onClick,
  to,
}) => (
  <Tab
    $isActive={isActive}
    aria-selected={isActive}
    as={onClick ? ('button' as unknown as undefined) : undefined}
    className={className}
    end={end}
    id={id}
    onClick={onClick}
    role="tab"
    to={to}
  >
    {children}
  </Tab>
);

export default memo(NavTab);
