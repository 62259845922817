import { FC, memo } from 'react';
import useLogic from './logic';
import { Button, ChevronLeft, ChevronRight } from './styles';
import { Props } from './types';

/* eslint-disable jsx-a11y/mouse-events-have-key-events */

const Control: FC<Props> = ({ className, onPress, type }) => {
  const { handleMouseDown, handleMouseOut, handleMouseUp } = useLogic({
    handleScroll: onPress,
    type,
  });

  return (
    <Button
      className={className}
      onMouseDown={handleMouseDown}
      onMouseOut={handleMouseOut}
      onMouseUp={handleMouseUp}
      type="button"
    >
      {type === 'back' ? <ChevronLeft /> : <ChevronRight />}
    </Button>
  );
};

export default memo(Control);
