import colorWithAlpha from 'lib/color';
import styled from 'styled-components';
import { blink, media } from 'styles/mixins';

export const Container = styled.div`
  align-items: center;
  animation: ${blink} 3s linear infinite;
  background: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralWhite
      : colorWithAlpha({
          color: theme.colors.coreNeutralBlack,
          alpha: 0.25,
        })};
  border-radius: ${({ theme }) => theme.radius.md};
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: ${({ theme }) => theme.measures.x};
  margin-top: ${({ theme }) => theme.measures.x};
  padding: 2rem;

  ${media.phoneLandscape} {
    margin-top: ${({ theme }) => theme.measures.s};
  }
`;

export const Title = styled.div`
  background: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? 'rgba(141, 141, 141, 0.8)'
      : 'rgba(141, 141, 141, 0.8)'};
  height: 2.25rem;
  max-width: 28rem;
  width: 100%;

  ${media.phoneLandscape} {
    height: 1.5rem;
  }
`;

export const Body1 = styled.div`
  background: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? 'rgba(141, 141, 141, 0.8)'
      : 'rgba(141, 141, 141, 0.8)'};
  height: 0.8rem;
  margin-top: ${({ theme }) => theme.measures.s};
  max-width: 30rem;
  width: 100%;

  ${media.phoneLandscape} {
    height: 0.7rem;
  }
`;

export const Body2 = styled(Body1)`
  max-width: 22.5rem;

  ${media.phonePortrait} {
    max-width: 13.75rem;
  }
`;

export const Button = styled.div`
  background: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? 'rgba(141, 141, 141, 0.8)'
      : 'rgba(141, 141, 141, 0.8)'};
  border-radius: 0.25rem;
  height: 3rem;
  margin-top: ${({ theme }) => theme.measures.l};
  max-width: 18.75rem;
  width: 100%;

  ${media.phoneLandscape} {
    max-width: 100%;
  }
`;
