import { formatDeepLink } from 'lib/formatDeepLink';
import type { HomeCtaQuery } from 'graphql/generated';

export const JOIN_WU_CTA = {
  buttonText: 'SEE OPTIONS',
  deepLink: '/account/subscription',
  description:
    'Get unlimited access to all our theory and practice sessions for #{price_value} / year.',
  title: 'Join Waking Up',
};

export const UPGRADE_YOUR_PLAN_CTA = {
  buttonText: 'SEE MEMBERSHIP OPTIONS',
  deepLink: '/account/subscription',
  description:
    'Get unlimited access to Daily Meditations and all lessons for #{price_value} / year.',
  title: 'Upgrade your plan',
};

export const INVITE_A_FRIEND_CTA = {
  buttonText: 'INVITE A FRIEND',
  deepLink: '/share?shareSource=home_cta',
  description:
    'You can give anyone 30 free days of unlimited access to the app.',
  title: 'Share Waking Up',
};

export const normalizeHomeCta = (
  homeCta: NonNullable<HomeCtaQuery['homeCta']>,
) => ({
  buttonText: homeCta.button_text || '',
  deepLink: homeCta.deeplink ? formatDeepLink(homeCta.deeplink) : '',
  description: homeCta.description || '',
  title: homeCta.title || '',
});

export type HomeCta = ReturnType<typeof normalizeHomeCta>;
