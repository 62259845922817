import { memo } from 'react';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { Body, Overline } from 'components/Typography';
import { ChevronIcon, Container } from './styles';
import type { Props } from './types';

const ButtonCTA: FC<Props> = ({
  active = false,
  children,
  hideChevron = false,
  size = 'default',
  onClick,
  to,
  ...rest
}) => {
  const Text = size === 'small' ? Overline : Body;

  return (
    <Container
      $active={active}
      $hideChevron={hideChevron}
      $size={size}
      as={to ? (Link as unknown as undefined) : undefined}
      onClick={onClick}
      to={to}
      {...rest}
    >
      <Text>{children}</Text>
      {!hideChevron && (
        <ChevronIcon
          size={size === 'small' ? 12 : 16}
          name={active ? 'caretUpLight' : 'caretDownLight'}
        />
      )}
    </Container>
  );
};

export default memo(ButtonCTA);
