import { memo } from 'react';
import type { FC } from 'react';
import Icon from 'components/Icon';
import { CircularTrack, CircularProgress, Container } from './styles';
import type { Props } from './types';

const PlayWithProgress: FC<Props> = ({ className, isPlaying, progress }) => (
  <Container aria-label={isPlaying ? 'Pause' : 'Play'} className={className}>
    {isPlaying ? (
      <Icon name="pauseLight" size={12} />
    ) : (
      <Icon name="playLight" size={10} />
    )}
    <CircularTrack />
    <CircularProgress value={progress} />
  </Container>
);

export default memo(PlayWithProgress);
