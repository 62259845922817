import type { FC } from 'react';
import { Formik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import {
  Button,
  CardElement,
  Error,
  ErrorAndActions,
  FormikForm,
  SaveButton,
} from './styles';
import { initialValues } from './constants';
import type { Props } from './types';

const Form: FC<Props> = ({ onCancel, onSubmit }) => (
  <Formik initialValues={initialValues} onSubmit={onSubmit}>
    {({ isSubmitting, errors }) => (
      <FormikForm>
        <CardElement />
        <ErrorAndActions>
          <AnimatePresence initial={false}>
            {errors.submitError && <Error>ERROR: {errors.submitError}</Error>}
          </AnimatePresence>
          {!isSubmitting && (
            <Button disabled={isSubmitting} onClick={onCancel} type="button">
              Cancel
            </Button>
          )}
          <SaveButton disabled={isSubmitting} type="submit">
            {isSubmitting ? <>Saving</> : <>Save</>}
          </SaveButton>
        </ErrorAndActions>
      </FormikForm>
    )}
  </Formik>
);

export default Form;
