import styled, { css } from 'styled-components';
import DefaultMuiTextField from '@mui/material/TextField';
import { motion } from 'framer-motion';
import colorWithAlpha from 'lib/color';
import DefaultIcon from 'components/Icon';

export const Container = styled.div``;

export const IconContainer = styled.div<{
  $hasValue: boolean;
  $disabled: boolean;
}>`
  bottom: 0.45rem;
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  position: absolute;
  right: 0.625rem;
  transform: ${({ $hasValue }) =>
    $hasValue ? 'translate(0, 0.5rem)' : 'translate(0, 0)'};
  transition: transform 0.3s ease;

  ${({ $disabled, theme }) =>
    $disabled &&
    css`
      color: ${theme.name === 'LIGHT'
        ? colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.33 })
        : colorWithAlpha({
            color: theme.colors.coreNeutralWhite,
            alpha: 0.25,
          })};
    `}
`;

export const ClearButton = styled.button`
  background: transparent;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: block;
  outline: none;
  position: absolute;
  right: 0.625rem;
  top: 1rem;
  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.fontEmphasized};
  }

  &:focus {
    > svg {
      border-radius: 50%;
      box-shadow: 0 0 0 2px #f4f4f4, 0 0 0 3.5px rgba(0, 96, 103, 0.25);
    }
  }
`;

export const Error = styled(motion.div).attrs(() => ({
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  transition: { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] },
}))`
  color: ${({ theme }) => theme.colors.fontLabelError};
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.01rem;
  margin-top: ${({ theme }) => theme.measures.xxs};
  text-align: left;
`;

export const Icon = styled(DefaultIcon)``;

export const InputContainer = styled.div`
  position: relative;

  :focus-within {
    ${IconContainer} {
      transform: translate(0, 0.5rem);
    }
  }
`;

export const MuiTextField = styled(DefaultMuiTextField)<{ $hasIcon?: boolean }>`
  color-scheme: ${({ theme }) => (theme.name === 'LIGHT' ? 'light' : 'dark')};

  &&& {
    * {
      transition: all 150ms ease;
    }
  }

  .MuiFormLabel-root {
    color: ${({ theme }) =>
      theme.name === 'LIGHT'
        ? theme.colors.coreNeutralAbyss
        : theme.colors.coreNeutralAurora};
    font-size: 1rem;
    line-height: 1;
    position: absolute;
    text-align: start;
    text-transform: uppercase;
    top: 1rem;

    &.MuiInputLabel-filled {
      transform: unset;
    }

    &.MuiInputLabel-shrink {
      font-weight: ${({ theme }) => theme.font.weight.bold};
      transform: translate(0, -0.75rem) scale(0.75);
      color: ${({ theme }) => theme.colors.fontEmphasized};
    }

    &.Mui-error {
      color: ${({ theme }) => theme.colors.fontLabelError}!important;
    }

    &.Mui-focused {
      color: ${({ theme }) => theme.colors.fontEmphasized};
    }

    &.Mui-disabled {
      color: ${({ theme }) =>
        theme.name === 'LIGHT'
          ? colorWithAlpha({
              color: theme.colors.coreNeutralBlack,
              alpha: 0.33,
            })
          : colorWithAlpha({
              color: theme.colors.coreNeutralWhite,
              alpha: 0.33,
            })};
    }
  }

  .MuiInputBase-root {
    color: ${({ theme }) =>
      theme.name === 'LIGHT'
        ? theme.colors.coreNeutralAbyss
        : theme.colors.coreNeutralAurora};
    padding-top: 1.25rem !important;
    padding-bottom: 0.5rem !important;
    background: none;

    &:hover,
    &.Mui-focused {
      background: none;
    }

    .MuiInputBase-input,
    .MuiInputFilledInput-input {
      padding: ${({ $hasIcon }) => ($hasIcon ? '0 2.8rem 0 0' : '0')};
      font-size: 1rem;
      font-weight: ${({ theme }) => theme.font.weight.book};
      height: auto;
      line-height: 1.25;

      &.Mui-disabled {
        -webkit-text-fill-color: ${({ theme }) =>
          theme.name === 'LIGHT'
            ? colorWithAlpha({
                color: theme.colors.coreNeutralBlack,
                alpha: 0.33,
              })
            : colorWithAlpha({
                color: theme.colors.coreNeutralWhite,
                alpha: 0.33,
              })};
      }
    }

    &.MuiInputBase-multiline {
      padding-left: 0;
      padding-right: 0;
    }

    &.MuiFilledInput-underline:before {
      border-bottom: 1px solid
        ${({ theme }) =>
          theme.name === 'LIGHT'
            ? colorWithAlpha({
                color: theme.colors.coreNeutralBlack,
                alpha: 0.33,
              })
            : colorWithAlpha({
                color: theme.colors.coreNeutralWhite,
                alpha: 0.33,
              })} !important;
      transition: border-color 200ms ease;
    }

    &.Mui-focused {
      &.MuiFilledInput-underline:before {
        border-bottom: 1px solid
          ${({ theme }) => theme.colors.fontEmphasized}!important;
      }
    }

    &.Mui-error {
      &.MuiFilledInput-underline:before {
        border-bottom: 1px solid
          ${({ theme }) => theme.colors.fontLabelError}!important;
      }
    }

    &.MuiFilledInput-underline.Mui-error:after {
      display: none;
    }

    &.MuiFilledInput-underline.Mui-focused:after {
      display: none;
    }

    &.Mui-disabled {
      background: none;
    }
  }

  .MuiFormHelperText-root {
    display: none !important;
    background: blue !important;
  }
`;
