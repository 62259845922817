import { memo, useCallback, type FC, type MouseEvent } from 'react';
import { setOptionsMenuVar } from 'graphql/reactive';
import { useAudioSpeed } from 'containers/Main/Player/Audio/hooks';
import { ICONS } from './constants';
import { ButtonIcon } from './styles';

const Speed: FC = () => {
  const playbackSpeed = useAudioSpeed();

  const handleOpen = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOptionsMenuVar({
      anchorEl: event.currentTarget,
      offset: '0, -100%p',
      placement: 'top-start',
      type: 'speed',
    });
  }, []);

  return (
    <ButtonIcon
      aria-label="select audio speed"
      iconName={ICONS[playbackSpeed.toFixed(1)]}
      onClick={handleOpen}
    />
  );
};

export default memo(Speed);
