import { apolloClient } from 'services/api';
import { getDailyPermission, normalizeDaily } from 'models/Daily';
import { GetBookmarksDocument } from '../generated';
import getPackCourses from './getPackCourses';
import getProfile from './getProfile';

const getBookmarks = async () => {
  const { isDailyUnlocked, isSubscribed } = await getProfile();
  const { packCourses } = await getPackCourses();

  const { data } = await apolloClient.query({
    query: GetBookmarksDocument,
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
  });

  const cacheBookmarkIds: Record<string, boolean> = {};
  const courses = packCourses.filter(
    ({ ephemeral, id, isBookmark, packIsPrimaryPack }) => {
      if (
        ephemeral ||
        !isBookmark ||
        !packIsPrimaryPack ||
        cacheBookmarkIds[id]
      ) {
        return false;
      }

      cacheBookmarkIds[id] = true;
      return true;
    },
  );

  const dailies =
    data?.bookmarks?.dailies
      ?.filter((daily) => !daily.is_special)
      .map((daily) => ({
        ...normalizeDaily(daily),
        permission: getDailyPermission(isSubscribed, isDailyUnlocked),
      }))
      .sort((a, b) => b.bookmarkedAt.localeCompare(a.bookmarkedAt)) || [];

  return {
    courses,
    dailies,
  };
};

export default getBookmarks;
