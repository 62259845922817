import getSubscriptionPlans from './getSubscriptionPlans';
import getScholarshipPrices from './getScholarshipPrices';

const getSubscriptionPlan = async (id: string, code?: string) => {
  const lwId = id.toLowerCase();
  const subscriptionPlans = await getSubscriptionPlans({ variables: { code } });

  const plan = subscriptionPlans.find(
    (subscriptionPlan) => subscriptionPlan.id.toLowerCase() === lwId,
  );

  if (plan) {
    return plan;
  }

  const scholarshipPrices = await getScholarshipPrices();

  return scholarshipPrices.find(
    (subscriptionPlan) => subscriptionPlan.id.toLowerCase() === lwId,
  );
};

export default getSubscriptionPlan;
