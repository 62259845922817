import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Container = styled.div<{ $isPublic: boolean }>`
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 15px 15px -15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${({ $isPublic }) => ($isPublic ? '0' : '2rem')};
  margin-right: ${({ $isPublic }) => ($isPublic ? '4.3125rem' : '0')};
  margin-top: 0.375rem;
  max-width: 29.75rem;
  padding: 2.0625rem 1.5625rem 1.5625rem 1.5625rem;
  position: relative;
  width: 100%;

  &:before {
    background: linear-gradient(0deg, #f4f4f4, #f4f4f4),
      url('https://assets.wakingup.com/web/images/giftcard-background.jpg'),
      #ffffff;
    background-blend-mode: color, normal, normal;
    background-repeat: repeat-y;
    background-size: 100% auto;
    content: ' ';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.55;
    position: absolute;
    top: 0;
    width: 100%;
  }

  ${media.tabletLandscape} {
    margin-right: 2.5rem;
  }

  ${media.phoneLandscape} {
    margin: -2.25rem -1.25rem 2.25rem;
    max-width: unset;
    width: 100vw;
  }
`;

export const Note = styled.p`
  color: ${({ theme }) => theme.colors.coreNeutralAbyss};
  font-family: 'Georgia';
  font-size: 1.125rem;
  font-style: italic;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 1.67;
  margin-bottom: 1.875rem;
  padding-left: 0.6875rem;
  position: relative;
  white-space: pre-wrap;
`;

export const SenderName = styled.p`
  color: ${({ theme }) => theme.colors.coreNeutralAbyss};
  font-family: 'Helvetica';
  font-size: 0.5rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.125rem;
  line-height: 1;
  margin-bottom: 3rem;
  padding-left: 0.6875rem;
  position: relative;
  text-transform: uppercase;
`;

export const Content = styled.div``;

export const ImageContainer = styled.div`
  height: 8.5712rem;
  position: relative;
  width: 15rem;
`;

export const CornerTopShadowImage = styled.img`
  left: -30px;
  opacity: 0.5;
  position: absolute;
  top: -18px;
  transform: rotate(-45deg);
  width: 3.9094rem;
`;

export const CornerBottomShadowImage = styled.img`
  bottom: -18px;
  opacity: 0.5;
  position: absolute;
  right: -30px;
  transform: rotate(135deg);
  width: 3.9094rem;
`;

export const Image = styled.img`
  clip-path: polygon(8% 0, 100% 0, 100% 86%, 92% 100%, 0 100%, 0 14%);
  position: absolute;
  top: 0;
  width: 100%;
`;

export const Caption = styled.p`
  color: #111111;
  font-size: 0.625rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  line-height: 0.75rem;
  margin-top: 0.9644rem;
  opacity: 0.4;
`;
