import { useCallback } from 'react';
import { RETREAT_LIVE_MEETING_POLL_INTERVAL } from 'models/Retreat';
import { ModalType } from 'graphql/reactive';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import useRetreat from 'graphql/hooks/useRetreat';
import useLiveMeetingInfo from 'graphql/hooks/useLiveMeetingInfo';

const useConnect = () => {
  const { isOpen } = useModal(ModalType.RETREAT_LIVE_MEETING);
  const { closeModal } = useModalActions();
  const { retreat } = useRetreat();
  const { id = '', title: retreatTitle } = retreat || {};
  const { liveMeetingInfo } = useLiveMeetingInfo({
    fetchPolicy: 'cache-first',
    pollInterval: RETREAT_LIVE_MEETING_POLL_INTERVAL,
    skip: !isOpen || !id,
    variables: { id },
  });

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return {
    handleClose,
    isOpen,
    liveMeetingInfo,
    retreatTitle,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
