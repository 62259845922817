import { memo, useCallback } from 'react';
import type { FC } from 'react';
import { AppConfig } from 'config';
import useModalActions from 'graphql/hooks/useModalActions';
import useDailyActions from 'graphql/hooks/useDailyActions';
import { ButtonIcon } from './styles';
import { Props } from './types';

const Share: FC<Props> = ({ hash, id }) => {
  const { openShareLinkModal } = useModalActions();
  const { createSharedDaily } = useDailyActions();

  const handleShare = useCallback(async () => {
    if (!hash) return;

    const newSharedHash = await createSharedDaily({ dailyId: id });
    if (!newSharedHash) return;

    openShareLinkModal({
      id: hash,
      title: 'Daily',
      description: 'Check out this guided meditation from the Waking Up app:',
      link: `${AppConfig.dynamicBaseUrl}/daily/${newSharedHash}`,
    });
  }, [createSharedDaily, hash, id, openShareLinkModal]);

  return (
    <ButtonIcon
      iconName="shareLight"
      id="wu-player-share-daily"
      onClick={handleShare}
    />
  );
};

export default memo(Share);
