import styled from 'styled-components';
import DefaultIsotype from 'components/Isotype';
import DefaultLink from 'components/Link';
import { media } from 'styles/mixins';

export const Container = styled.header`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

export const Isotype = styled(DefaultIsotype)`
  display: block;
  width: 2.1rem;

  ${media.phoneLandscape} {
    width: 1.5625rem;
  }
`;

export const Link = styled(DefaultLink)`
  color: inherit;
  font-size: 0.875rem;
  line-height: 1.21;
  margin-top: 0.4rem;
  opacity: 0.7;
  text-decoration: underline;

  ${media.tabletPortrait} {
    font-size: 0.9375rem;
  }
`;
