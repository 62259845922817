/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { ZIndex } from 'styles/metrics';
import DefaultSelect from 'components/Inputs/Select';
import { ReactSelect } from 'components/Inputs/Select/styles';
import { media } from 'styles/mixins';

export const Select = styled(DefaultSelect)`
  width: 100%;

  ${ReactSelect} {
    .react-select__value-container {
      padding: 6px 0 7px;
    }
    .react-select__indicators {
      position: relative;
    }
    .react-select__indicator {
      border-radius: 50%;
      color: ${({ theme }) => theme.colors.fontEmphasized};
      position: absolute;
      right: 0;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      > svg {
        margin: 0.5rem 0;
      }

      ${media.phonePortrait} {
        display: none;
      }
    }
    .react-select__placeholder {
      color: ${({ theme }) => theme.colors.fontDeemphasized};
      opacity: 0.8;
    }
    .react-select__menu {
      z-index: ${ZIndex.HEADER};
    }
  }
`;
