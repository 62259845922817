import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { Swiper } from 'swiper';
import { RetreatUserAction } from 'graphql/generated';
import { ModalType } from 'graphql/reactive';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import useRetreat from 'graphql/hooks/useRetreat';
import useRetreatActions from 'graphql/hooks/useRetreatActions';
import { STEPS } from './constants';

const useConnect = () => {
  const { isOpen } = useModal(ModalType.JOIN_RETREAT);
  const { closeModal } = useModalActions();
  const { retreat } = useRetreat();
  const { isUpdatingUserRetreat, updateUserRetreat } = useRetreatActions();
  const navigate = useNavigate();

  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const swiperRef = useRef<{ swiper: Swiper }>(null!);

  const retreatId = retreat?.id || '';
  const retreatTitle = retreat?.title || '';
  const retreatPackUrl = retreat?.pack
    ? `/practice/retreats/${retreat.slug}/${retreat.pack.slug}`
    : '';
  const startDate = retreat?.startDate;

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleNext = useCallback(async () => {
    if (retreatPackUrl && currentSlide === STEPS - 1) {
      setCurrentSlide(0);
      await updateUserRetreat({ action: RetreatUserAction.join, retreatId });
      navigate(retreatPackUrl);
      closeModal();
    } else {
      swiperRef.current.swiper.slideNext();
    }
  }, [
    closeModal,
    currentSlide,
    navigate,
    retreatId,
    retreatPackUrl,
    updateUserRetreat,
  ]);

  const handleBack = useCallback(() => {
    if (currentSlide > 0) {
      swiperRef.current.swiper.slidePrev();
    } else {
      closeModal();
    }
  }, [closeModal, currentSlide]);

  const handleInit = useCallback((swiper: Swiper) => {
    const { activeIndex, slides } = swiper;
    const activeSlide = slides[activeIndex];
    slides.forEach((slide) => slide.setAttribute('aria-hidden', 'true'));
    activeSlide.setAttribute('aria-hidden', 'false');
  }, []);

  const handleSlideChange = useCallback((swiper: Swiper) => {
    const { activeIndex, realIndex, slides } = swiper;
    setCurrentSlide(realIndex);
    const activeSlide = slides[activeIndex];
    slides.forEach((slide) => slide.setAttribute('aria-hidden', 'true'));
    setTimeout(() => {
      activeSlide.setAttribute('aria-hidden', 'false');
    }, 0);
  }, []);

  return {
    currentSlide,
    handleBack,
    handleClose,
    handleInit,
    handleNext,
    handleSlideChange,
    isOpen,
    isUpdatingUserRetreat,
    retreatTitle,
    startDate,
    swiperRef,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
