import { memo } from 'react';
import type { FC } from 'react';
import { Formik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import { LoginStatus } from 'graphql/reactive';
import Resend from './Resend';
import {
  Button,
  Error,
  Form,
  InputWithButton,
  Loader,
  LoginCodeField,
  Row,
  StepContent,
  Text,
} from './styles';
import { validationSchema } from './constants';
import { Props, Step } from './types';
import useConnect from './connect';

const EmailLogin: FC<Props> = ({ initialEmail, initialStep }) => {
  const { formikRef, handleSubmit, initialValues, loginStatus } = useConnect({
    initialEmail,
    initialStep,
  });

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, errors, values: { pin, step } }) => {
        const disabledButton =
          loginStatus === LoginStatus.STARTING_GOOGLE_MODE ||
          pin.join('').length < 4 ||
          isSubmitting;

        return (
          <Form noValidate>
            <AnimatePresence initial={false} mode="wait">
              {step === Step.email && (
                <StepContent key="email">
                  <Text>
                    Enter your email & we will send you a code to sign in to the
                    app
                  </Text>
                  <InputWithButton
                    disabled={
                      loginStatus === LoginStatus.STARTING_GOOGLE_MODE ||
                      isSubmitting
                    }
                    isLoading={isSubmitting}
                    label="Email address:"
                    labelId="email-address"
                    name="email"
                    placeholder="Email Address"
                  />
                </StepContent>
              )}
              {step === Step.pin && (
                <StepContent key="pin">
                  <Text>
                    We have sent a{' '}
                    <strong>four digit verification code to your email</strong>.
                    Please enter it below.
                  </Text>
                  <Row>
                    <LoginCodeField name="pin" />
                    <Button
                      $isLoading={isSubmitting}
                      disabled={disabledButton}
                      type="submit"
                    >
                      {isSubmitting ? <Loader /> : 'Next'}
                    </Button>
                  </Row>
                  <Resend />
                </StepContent>
              )}
              {errors.submitError && <Error>Error: {errors.submitError}</Error>}
            </AnimatePresence>
          </Form>
        );
      }}
    </Formik>
  );
};

export default memo(EmailLogin);
