import styled from 'styled-components';
import { Overline } from 'components/Typography';

export const Container = styled.div`
  pointer-events: none;
  position: relative;
`;

export const TimePending = styled.div`
  font-family: ${({ theme }) => theme.font.family.druk};
  font-size: 6rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 1.25rem;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
`;

export const TimeElapsed = styled(Overline)`
  left: 50%;
  position: absolute;
  text-align: center;
  top: 65%;
  transform: translateX(-50%);
`;
