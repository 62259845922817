import { memo } from 'react';
import type { FC } from 'react';
import { Container, Footer, Header } from './styles';
import type { Props } from './types';

const PublicPageLayout: FC<Props> = ({
  children,
  className,
  hideHeaderLogin = false,
}) => (
  <Container className={className}>
    <Header hideLogin={hideHeaderLogin} />
    {children}
    <Footer />
  </Container>
);

export default memo(PublicPageLayout);
