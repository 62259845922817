import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { ApiConfig } from 'config';
import { TypedTypePolicies } from 'graphql/generated';
import { getToken, setToken } from './storage';

const getTimezoneOffsetInSeconds = () =>
  new Date().getTimezoneOffset() * 60 * -1;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    Accept: 'application/json',
    Authorization: getToken(),
    'X-Application-Secret': ApiConfig.API_SECRET,
    timezone: getTimezoneOffsetInSeconds(),
    'app-client': 'web',
  },
}));

const uploadLink = createHttpLink({
  uri: `${ApiConfig.API}/api/graphql`,
  credentials: 'same-origin',
  fetchOptions: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
});

const typePolicies: TypedTypePolicies = {
  Course: {
    fields: {
      packs_info: { merge: false },
    },
    keyFields: ['hash'],
  },
  User: {
    fields: {
      notifications: { merge: false },
    },
  },
  Query: {
    fields: {
      course(_, { args, toReference }) {
        return toReference({ __typename: 'Course', hash: args?.code });
      },
      subscriptionPlans: { merge: false },
    },
  },
};

const apolloCache = new InMemoryCache({
  typePolicies,
});

export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, uploadLink]),
  cache: apolloCache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
  },
});

export const clearApolloStore = () => apolloClient.clearStore();

// @ts-ignore
window.__SET_AUTH_TOKEN__ = (token: string) => {
  setToken(token);
  window.location.reload();
};
