import { useMemo } from 'react';
import { useQuery, type QueryHookOptions } from '@apollo/client';
import notEmpty from 'lib/notEmpty';
import { normalizeSubscriptionPlan } from 'models/SubscriptionPlan';
import {
  ScholarshipPricesDocument,
  type ScholarshipPricesQueryVariables,
  type ScholarshipPricesQuery,
  SubscriptionPlanType,
} from '../generated';

const useScholarshipPrices = (
  options?: QueryHookOptions<
    ScholarshipPricesQuery,
    ScholarshipPricesQueryVariables
  >,
) => {
  const { data, loading } = useQuery(ScholarshipPricesDocument, options);

  const scholarshipPrices = useMemo(
    () =>
      data?.scholarshipPrices
        ? [
            ...data.scholarshipPrices
              .filter(notEmpty)
              .map(normalizeSubscriptionPlan),
            {
              amount: 0,
              id: 'fullScholarship',
              interval: '',
              nickname: '',
              originalAmount: 0,
              sku: 'fullScholarship',
              type: SubscriptionPlanType.scholarship,
            },
          ]
        : [],
    [data],
  );

  return {
    loading,
    scholarshipPrices,
  };
};

export default useScholarshipPrices;
export type UseScholarshipPrices = ReturnType<typeof useScholarshipPrices>;
