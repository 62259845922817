import styled from 'styled-components';
import { Heading } from 'components/Typography';
import DefaultChevronRight from 'components/Icons/ChevronRight';
import colorWithAlpha from 'lib/color';

export const NavItemContainer = styled.li``;

export const NavItem = styled.div`
  align-items: center;
  background: transparent;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderBase};
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.fontDeemphasized};
  cursor: default;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  line-height: 1;
  outline: none;
  padding: 0.8rem 1.8rem;
  text-align: left;
  text-decoration: none;
  width: 100%;

  &.active {
    color: ${({ theme }) => theme.colors.fontEmphasized};
  }

  &:focus {
    background: ${({ theme }) =>
      theme.name === 'LIGHT'
        ? colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.07 })
        : colorWithAlpha({
            color: theme.colors.coreNeutralWhite,
            alpha: 0.07,
          })};
  }
`;

export const Title = styled(Heading)`
  display: block;
  flex: 1 1 auto;
  margin-left: ${({ theme }) => theme.measures.m};
  width: 100%;
`;

export const ChevronRight = styled(DefaultChevronRight).attrs(() => ({
  size: 7.5,
}))``;
