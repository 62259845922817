import { memo } from 'react';
import type { FC } from 'react';
import { Formik } from 'formik';
import { LoginStatus } from 'graphql/reactive';
import { Error, Form, InputWithButton } from './styles';
import { validationSchema } from './constants';
import type { Props } from './types';
import useConnect from './connect';

const MarketingEmail: FC<Props> = ({ initialEmail, queryString }) => {
  const { formikRef, handleSubmit, initialValues, loginStatus } = useConnect({
    initialEmail,
    queryString,
  });

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, errors }) => {
        const disabled =
          loginStatus === LoginStatus.STARTING_GOOGLE_MODE || isSubmitting;

        return (
          <Form noValidate>
            <InputWithButton
              disabled={disabled}
              isLoading={isSubmitting}
              label="Email address:"
              labelId="email-address"
              name="email"
              placeholder="Email Address"
            />
            {errors.submitError && <Error>Error: {errors.submitError}</Error>}
          </Form>
        );
      }}
    </Formik>
  );
};

export default memo(MarketingEmail);
