import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { ProcessOfferDocument } from '../generated';
import {
  GET_CONTENT,
  GET_DAILY,
  GET_FEATURE_CONTENT,
  GET_LIFETIME_PRICE,
  GET_REMOTE_CONFIG,
  GET_SUBSCRIPTION_PLANS,
  ME,
} from '../queries';
import useAuth from './useAuth';

const useProcessOfferActions = () => {
  const { isAuthenticated } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [processOfferMutation] = useMutation(ProcessOfferDocument, {
    refetchQueries: isAuthenticated
      ? [
          { query: ME },
          { query: GET_CONTENT },
          { query: GET_DAILY },
          { query: GET_FEATURE_CONTENT },
          { query: GET_REMOTE_CONFIG },
          { query: GET_SUBSCRIPTION_PLANS },
          { query: GET_LIFETIME_PRICE },
        ]
      : undefined,
  });

  const processOffer = useCallback(
    async (code: string) => {
      try {
        const response = await processOfferMutation({ variables: { code } });
        const approved = response.data?.processOffer?.approved || false;
        const message = response.data?.processOffer?.message;

        if (approved) {
          enqueueSnackbar(message || 'Your code has been processed', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(message || 'Unable to process offer code', {
            variant: 'error',
          });
        }
      } catch (e) {
        const error =
          (e?.graphQLErrors?.length > 0 && e.graphQLErrors[0].message) ||
          e?.message ||
          'Unable to process offer code';

        enqueueSnackbar(error, { variant: 'error' });
      }
    },
    [enqueueSnackbar, processOfferMutation],
  );

  return {
    processOffer,
  };
};

export default useProcessOfferActions;
export type UseProcessOfferActions = ReturnType<typeof useProcessOfferActions>;
