import type { FC } from 'react';
import { Props } from './types';

const ThreeDotsVerticalLight: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 4 19"
    width={(size * 4) / 19}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M.155 9.152a1.83 1.83 0 1 0 3.662 0 1.83 1.83 0 0 0-3.662 0ZM.155 1.83a1.83 1.83 0 1 0 3.661 0 1.83 1.83 0 0 0-3.66 0ZM.155 16.474a1.83 1.83 0 1 0 3.661 0 1.83 1.83 0 0 0-3.66 0Z"
      fill="currentColor"
    />
  </svg>
);

export default ThreeDotsVerticalLight;
