import type { FC } from 'react';
import Selector from './Selector';
import { Container, Subtitle, Title } from './styles';
import type { Props } from './types';

const RemindersStep: FC<Props> = ({ className, descriptionId, titleId }) => (
  <Container className={className}>
    <Title id={titleId}>
      Would you like to receive push notifications for this retreat?
    </Title>
    <Subtitle id={descriptionId}>
      We will be emailing you a calendar invite and a ton of other things
    </Subtitle>
    <Selector name="sendReminders" />
  </Container>
);

export default RemindersStep;
