import { memo, useEffect, type FC } from 'react';
import type { Props } from './types';

const BASE_TITLE = 'Waking Up with Sam Harris';

const PageTitle: FC<Props> = ({ title }) => {
  useEffect(() => {
    document.title = `${title} | ${BASE_TITLE}`;
  }, [title]);

  return null;
};

export default memo(PageTitle);
