import type { FC } from 'react';
import { Props } from './types';

const LockSimpleFilled: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 16 16"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M11.455 5.813h-8.75a.438.438 0 0 0-.438.437v6.125c0 .242.196.438.438.438h8.75a.437.437 0 0 0 .437-.438V6.25a.437.437 0 0 0-.437-.438Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.111 5.813v-1.97a1.969 1.969 0 0 1 3.938 0v1.97"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LockSimpleFilled;
