import { useCallback } from 'react';
import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import { AppConfig } from 'config';
import { trackEvent } from 'services/analytics';
import useAuthActions from 'graphql/hooks/useAuthActions';
import Footer from 'components/PublicPageFooter';
import {
  Button,
  Container,
  Icon,
  Image,
  Main,
  Text,
  TextSmall,
  Title,
} from './styles';

const MagicLinkLoginSuccess: FC = () => {
  const { isLoadingGetPin, resend } = useAuthActions();
  const { search } = useLocation();

  const { e, ...restSearch } = parse(search);
  const email = typeof e === 'string' ? e : '';
  const queryString = stringify(restSearch);

  const handleResend = useCallback(async () => {
    if (email) {
      await resend({ email, queryString });
      trackEvent('Login Resend Magic Link Request');
    }
  }, [email, queryString, resend]);

  return (
    <Container>
      <Image alt="" src={`${AppConfig.imagesURL}/welcome-head-2.png`} />
      <Main>
        <Icon name="envelopeLight" />
        <Title>Check your email</Title>
        <Text>
          To complete sign in, tap the button in the email we’ve sent to{' '}
          <strong>{email}</strong>.
        </Text>
        <TextSmall>
          If you don’t see it, check your spam folder.
          <br />
          If you’re still having trouble, please contact support@wakingup.com.
        </TextSmall>
        {email && (
          <Button isLoading={isLoadingGetPin} onClick={handleResend}>
            Resend
          </Button>
        )}
      </Main>
      <Footer />
    </Container>
  );
};

export default MagicLinkLoginSuccess;
