import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultButton from 'components/Button';
import DefaultModal from 'components/Modal';

export const Modal = styled(DefaultModal)`
  &&& {
    .MuiPaper-root {
      padding: 2.5rem 1.8rem;
      width: 24rem;
    }

    ${media.phoneLandscape} {
      .MuiPaper-root {
        padding: 1.5rem 0.75rem;
      }
    }
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.01em;
  line-height: 1.5;
  margin-bottom: 2rem;
  text-align: center;
`;

export const List = styled.ul`
  margin-bottom: 2rem;
  padding-left: 1.4rem;
`;

export const ListItem = styled.li`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: 0.01em;
  line-height: 1.4286;

  :not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

export const Description = styled.p`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  letter-spacing: 0.01em;
  line-height: 1.4286;
  margin-bottom: 1rem;
  text-align: center;
`;

export const Button = styled(DefaultButton)`
  margin: 0 auto;
  max-width: 18.5625rem;
`;
