import { memo } from 'react';
import type { FC } from 'react';
import { Container, Icon } from './styles';
import { Props } from './types';

const ButtonIcon: FC<Props> = ({ active, iconName, ...rest }) => (
  <Container $active={active} aria-pressed={active} {...rest}>
    <Icon name={iconName} />
  </Container>
);

export default memo(ButtonIcon);
