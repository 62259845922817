import { useCallback, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useMediaQuery } from '@mui/material';
import addSeconds from 'date-fns/addSeconds';
import format from 'date-fns/format';
import { trackEvent } from 'services/analytics';
import {
  getIntervalSeconds,
  SELF_TIMER_INTERVALS,
} from 'models/SelfTimerInterval';
import {
  resetPlayerTypeVar,
  selfTimerPlayerVar,
  updateSelfTimerPlayerVar,
} from 'graphql/reactive';
import usePlayerActions from 'graphql/hooks/usePlayerActions';
import useMeActions from 'graphql/hooks/useMeActions';

const useConnect = () => {
  const { closePlayer } = usePlayerActions();
  const { addUserProgress } = useMeActions();
  const { duration, interval, isMinimized, startTime } =
    useReactiveVar(selfTimerPlayerVar);
  const hideCircle = useMediaQuery('@media (max-height: 450px)');

  const finishTimeString = useMemo(() => {
    const endTime = addSeconds(new Date(), duration);
    return format(endTime, 'h:mm aaa');
  }, [duration]);

  const intervalSeconds = useMemo(
    () => getIntervalSeconds({ duration, interval }),
    [duration, interval],
  );

  const handleClosePlayer = useCallback(() => {
    resetPlayerTypeVar();
  }, []);

  const handleInit = useCallback(() => {
    trackEvent('Self Timer Start', {
      duration: Math.round(duration / 60),
      intervalChime: SELF_TIMER_INTERVALS[interval].trackerValue,
    });
  }, [duration, interval]);

  const handleEnd = useCallback(() => {
    trackEvent('Self Timer Complete');

    addUserProgress({ duration_seconds: duration, finished_at: new Date() });

    setTimeout(() => closePlayer(), 750);
  }, [addUserProgress, closePlayer, duration]);

  const handleToggleDisplayStatus = useCallback(() => {
    updateSelfTimerPlayerVar({
      isMinimized: !selfTimerPlayerVar().isMinimized,
    });
  }, []);

  const handleChangeDuration = useCallback((durationMinutes: number) => {
    updateSelfTimerPlayerVar({
      duration: durationMinutes * 60,
      startTime: new Date().getTime(),
    });
  }, []);

  return {
    duration,
    finishTimeString,
    handleChangeDuration,
    handleClosePlayer,
    handleEnd,
    handleInit,
    handleToggleDisplayStatus,
    hideCircle,
    intervalSeconds,
    isMinimized,
    startTime,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
