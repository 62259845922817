import type { FC } from 'react';
import { formatDurationShort } from 'lib/formatTime';
import { CourseStatus } from 'models/Course';
import { Container, Icon, LinearProgress, Played, Total } from './styles';
import type { Props } from './types';

const CourseLinearProgress: FC<Props> = ({
  className,
  courseStatus,
  total = 0,
  progress = 0,
}) => {
  const formattedTotal = formatDurationShort(total - total * (progress / 100));
  const showPlayedMessage = courseStatus === CourseStatus.FINISHED;
  const showTotal = courseStatus !== CourseStatus.FINISHED && progress === 0;
  const showProgress =
    courseStatus !== CourseStatus.FINISHED && progress > 0 && progress < 100;

  return (
    <Container className={className}>
      {showProgress && (
        <>
          <LinearProgress value={progress} variant="determinate" />
          <Total>{formattedTotal} left</Total>
        </>
      )}
      {showTotal && <Total>{formattedTotal}</Total>}
      {showPlayedMessage && (
        <>
          <Icon name="checkCircleLightFilled" />
          <Played>played</Played>
        </>
      )}
    </Container>
  );
};

export default CourseLinearProgress;
