import type { FC } from 'react';
import Icon from 'components/Icon';
import useModalActions from 'graphql/hooks/useModalActions';
import { IconButton } from './styles';

const Close: FC = () => {
  const { closeModal } = useModalActions();

  return (
    <IconButton aria-label="Close modal" onClick={closeModal} type="button">
      <Icon name="xLight" />
    </IconButton>
  );
};

export default Close;
