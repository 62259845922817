import { makeVar } from '@apollo/client';
import { PopperPlacementType } from '@mui/material';

export type OptionsMenu =
  | {
      anchorEl: HTMLElement | null;
      id: string;
      packId: string;
      showTitle?: boolean;
      type: 'course';
    }
  | {
      anchorEl: HTMLElement | null;
      id: string;
      offset: number | string;
      packId: string;
      placement: PopperPlacementType;
      type: 'coursePlayer';
    }
  | {
      anchorEl: HTMLElement | null;
      offset: number | string;
      placement: PopperPlacementType;
      type: 'backgroundSounds';
    }
  | {
      anchorEl: HTMLElement | null;
      offset: number | string;
      placement: PopperPlacementType;
      type: 'speed';
    }
  | {
      anchorEl: HTMLElement | null;
      offset: number | string;
      placement: PopperPlacementType;
      type: 'autoplay';
    }
  | {
      anchorEl: HTMLElement | null;
      dailyId: string;
      sharedHash?: string;
      type: 'daily';
    }
  | {
      anchorEl: HTMLElement | null;
      dailyId: string;
      offset: number | string;
      placement: PopperPlacementType;
      sharedHash?: string;
      type: 'dailyPlayer';
    }
  | {
      anchorEl: HTMLElement | null;
      id: string;
      type: 'pack';
    }
  | {
      anchorEl: HTMLElement | null;
      type: 'retreat';
    }
  | null;

export const emptyOptionsMenu: OptionsMenu = null;

export const optionsMenuVar = makeVar<OptionsMenu>(emptyOptionsMenu);

export const setOptionsMenuVar = (data: OptionsMenu) => {
  optionsMenuVar(data);
};

export const resetOptionsMenuVar = () => {
  optionsMenuVar(emptyOptionsMenu);
};
