import { makeVar } from '@apollo/client';

export type Details =
  | { packId: string; type: 'pack' }
  | { courseId: string; packId?: string; type: 'course' };

export const detailsVar = makeVar<Details | null>(null);

export const setDetails = (data: Details) => {
  detailsVar(data);
};

export const resetDetails = () => {
  detailsVar(null);
};
