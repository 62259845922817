import { FC, memo } from 'react';
import formatMindfulMinutes from 'lib/formatMindfulMinutes';
import {
  Container,
  Row,
  Stat,
  StatSeparator,
  StatTitle,
  StatValue,
} from './styles';
import { Props } from './types';

const UserStats: FC<Props> = ({
  bigValues = false,
  className,
  forceRows = false,
  hideSeparators = false,
  mindfulDays,
  mindfulMinutes,
  reverseColors = false,
  titlePosition = 'top',
  totalSessions,
}) => (
  <Container
    $bigValues={bigValues}
    $forceRows={forceRows}
    $hideSeparators={hideSeparators}
    $reverseColors={reverseColors}
    $titlePosition={titlePosition}
    className={className}
  >
    <Row>
      <Stat>
        <StatTitle>Mindful Days</StatTitle>
        <StatValue id="settings-mindful-days">{mindfulDays}</StatValue>
      </Stat>
      <StatSeparator />
      <Stat>
        <StatTitle>Mindful Minutes</StatTitle>
        <StatValue id="settings-mindful-minutes">
          {formatMindfulMinutes(mindfulMinutes)}
        </StatValue>
      </Stat>
      <StatSeparator />
      <Stat>
        <StatTitle>Total Sessions</StatTitle>
        <StatValue id="settings-mindful-sessions">{totalSessions}</StatValue>
      </Stat>
    </Row>
  </Container>
);

export default memo(UserStats);
