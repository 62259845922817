import { apolloClient } from 'services/api';
import notEmpty from 'lib/notEmpty';
import { normalizeSubscriptionPlan } from 'models/SubscriptionPlan';
import { ScholarshipPricesDocument } from '../generated';

const getScholarshipPrices = async () => {
  const { data: scholarshipPricesData } = await apolloClient.query({
    query: ScholarshipPricesDocument,
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
  });

  return scholarshipPricesData?.scholarshipPrices
    ? scholarshipPricesData.scholarshipPrices
        .filter(notEmpty)
        .map(normalizeSubscriptionPlan)
    : [];
};

export default getScholarshipPrices;
