import type { FC } from 'react';
import {
  ContactUs,
  Description,
  Footer,
  FooterContent,
  FooterTitle,
  Modal,
  Title,
} from './styles';
import useConnect from './connect';
import Form from './Form';

const ScholarshipModal: FC = () => {
  const {
    handleClose,
    handleContactUs,
    handleSubmit,
    isOpen,
    loading,
    options,
  } = useConnect();

  return (
    <Modal
      descriptionId="scholarship-description"
      onClose={handleClose}
      open={isOpen}
      titleId="scholarship-title"
    >
      <Title id="scholarship-title">SCHOLARSHIP PROGRAM</Title>
      <Description id="scholarship-description">
        For those who can&apos;t afford the full subscription price, we offer a
        partial scholarship.
      </Description>
      <Form onSubmit={handleSubmit} options={options} loading={loading} />
      <Footer>
        <FooterContent>
          <FooterTitle>Need up to a full scholarship?</FooterTitle>
          <ContactUs onClick={handleContactUs}>Contact Us</ContactUs>
        </FooterContent>
      </Footer>
    </Modal>
  );
};

export default ScholarshipModal;
