import { memo } from 'react';
import type { FC } from 'react';
import {
  BackButton,
  BackIcon,
  BackPlace,
  Container,
  Logo,
  LogoLink,
  MenuButton,
  MenuIcon,
} from './styles';
import { Props } from './types';
import useConnect from './connect';

const Header: FC<Props> = ({ className, isOpen, onToggleSidebar }) => {
  const { backToUrl, showBackButton } = useConnect();

  return (
    <Container className={className}>
      {showBackButton ? (
        <BackButton aria-label="Go back" to={backToUrl}>
          <BackIcon aria-hidden="true" />
        </BackButton>
      ) : (
        <BackPlace />
      )}
      <LogoLink to="/">
        <Logo />
      </LogoLink>
      <MenuButton
        aria-controls="sidebar-panel"
        aria-expanded={isOpen}
        aria-label="Main menu"
        onClick={onToggleSidebar}
        role="button"
        tabIndex={0}
      >
        <MenuIcon aria-hidden="true" />
      </MenuButton>
    </Container>
  );
};

export default memo(Header);
