import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import flatMap from 'lodash/flatMap';
import kebabCase from 'lodash/kebabCase';
import { trackWidgetEvent } from 'services/analytics';
import { media } from 'styles/mixins';
import useMediaQuery from 'lib/useMediaQuery';
import { CoursePermission, getNextIntroCourse } from 'models/Course';
import usePacks from 'graphql/hooks/usePacks';
import usePlayerActions from 'graphql/hooks/usePlayerActions';
import useActiveSessions from 'gfirebase/hooks/useActiveSessions';
import type { ConnectProps } from './types';

const useConnect = ({ widgetId }: ConnectProps) => {
  const { packs, introPack, loading: loadingPacks } = usePacks();
  const { activeSessions, loading: loadingActiveSessions } =
    useActiveSessions();
  const { openCoursePlayer } = usePlayerActions();
  const navigate = useNavigate();
  const isPhoneLandscape = useMediaQuery(media.phoneLandscape);

  const nowPracticing = activeSessions
    ? `${new Intl.NumberFormat('en-US').format(activeSessions)} NOW PRACTICING`
    : '... NOW PRACTICING';

  const introPackSlug = introPack
    ? `${introPack.urlBase}/${kebabCase(introPack.title)}`
    : '';

  const nextCourse = useMemo(() => {
    if (!introPack) {
      return undefined;
    }

    const subpacks = packs.filter(
      ({ parentPackId }) => parentPackId && parentPackId === introPack?.id,
    );
    const courses = flatMap(subpacks, (subpack) => subpack.courses);

    return getNextIntroCourse(courses);
  }, [introPack, packs]);

  const isAvailable = nextCourse?.permission === CoursePermission.UNLOCKED;
  const courseHash = nextCourse?.hash || '';
  const courseTitle = nextCourse?.title || '';
  const coursePackId = nextCourse?.packId || '';
  const coursePackHash = nextCourse?.packHash || '';
  const coursePackTitle = nextCourse?.packTitle || '';
  const courseIsIntro = nextCourse?.isIntroCourse ?? false;

  const handleOpenCourse = useCallback(() => {
    if (courseHash && coursePackId && isAvailable) {
      openCoursePlayer({ hash: courseHash, packId: coursePackId });

      if (widgetId) {
        trackWidgetEvent(widgetId, {
          category: 'practice',
          content_type: 'session',
          hash: courseHash,
          name: courseTitle,
          pack_hash: coursePackHash,
          pack_name: coursePackTitle,
        });
      }

      if (courseIsIntro && introPackSlug) {
        setTimeout(() => navigate(introPackSlug), 750);
      }
    }
  }, [
    courseHash,
    courseIsIntro,
    coursePackHash,
    coursePackId,
    coursePackTitle,
    courseTitle,
    introPackSlug,
    isAvailable,
    navigate,
    openCoursePlayer,
    widgetId,
  ]);

  return {
    bgImageUrl:
      nextCourse?.imageThumbnailsThumbX1 ||
      nextCourse?.imageThumbnailsThumbX2 ||
      nextCourse?.imageUrl ||
      '',
    courseHash,
    courseTitle,
    handleOpenCourse,
    imageUrl: nextCourse?.headImageUrl || nextCourse?.imageUrl || '',
    isAvailable,
    isLoading: loadingPacks || loadingActiveSessions,
    isPhoneLandscape,
    nowPracticing,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
