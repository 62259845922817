import {
  AD,
  ADSET,
  APPSFLYER_LINK_DEFAULT,
  CAMPAIGN,
  CHANNEL,
  DEEPLINKVALUE,
  MEDIASOURCE,
  ONELINK_ALL,
  ONELINK_ANDROID,
  ONELINK_IOS,
} from './constants';
import type {
  CreateAppsFlyerLinkPropsV2,
  CreateAppsFlyerLinkV2,
} from './types';

export const createAppsFlyerLinkV2 = ({
  ad,
  adSet,
  campaign,
  channel,
  deepLinkValue,
  mediaSource = 'organic',
}: CreateAppsFlyerLinkPropsV2): CreateAppsFlyerLinkV2 => {
  if (
    typeof window === 'undefined' ||
    !window.AF_SMART_SCRIPT?.generateOneLinkURL
  ) {
    return APPSFLYER_LINK_DEFAULT;
  }

  const afParameters = {
    ad: { ...AD, defaultValue: ad },
    adSet: { ...ADSET, defaultValue: adSet },
    campaign: { ...CAMPAIGN, defaultValue: campaign },
    channel: { ...CHANNEL, defaultValue: channel },
    deepLinkValue: {
      ...DEEPLINKVALUE,
      defaultValue: deepLinkValue
        ? encodeURIComponent(`wakingup://dynamic.wakingup.com/${deepLinkValue}`)
        : undefined,
    },
    mediaSource: { ...MEDIASOURCE, defaultValue: mediaSource },
  };

  const link = window.AF_SMART_SCRIPT.generateOneLinkURL({
    afParameters,
    oneLinkURL: ONELINK_ALL,
  });

  return {
    all: link?.clickURL || '',
    android: link?.clickURL.replace(ONELINK_ALL, ONELINK_ANDROID) || '',
    ios: link?.clickURL.replace(ONELINK_ALL, ONELINK_IOS) || '',
    onelinkAll: ONELINK_ALL,
    onelinkAndroid: ONELINK_ANDROID,
    onelinkIos: ONELINK_IOS,
    queryString: link?.clickURL.replace(ONELINK_ALL, '') || '',
  };
};

export const showAppsFlyerQRCodeV2 = (containerId: string) => {
  if (typeof window === 'undefined' || !window.AF_SMART_SCRIPT?.displayQrCode) {
    return;
  }

  window.AF_SMART_SCRIPT.displayQrCode(containerId);
};

export type { CreateAppsFlyerLinkPropsV2, CreateAppsFlyerLinkV2 };
