import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultLink from 'components/Link';

export const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;

  ${media.tabletPortrait} {
    flex: 0 1 auto;
    justify-content: center;
    margin-top: 3.75rem;
  }

  ${media.phonePortrait} {
    flex-direction: column;
    margin-top: 1.6875rem;
  }
`;

export const Link = styled(DefaultLink)`
  color: inherit;
  display: block;
  font-size: 0.8125rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 1.21;
  margin-left: 1.4rem;
  opacity: 0.8;
  text-decoration: none;

  &:first-child {
    margin-left: 0;
  }

  ${media.phonePortrait} {
    line-height: 2.77;
    margin-left: 0;
  }
`;
