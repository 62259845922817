import styled from 'styled-components';
import colorWithAlpha from 'lib/color';
import DefaultIcon from 'components/Icon';
import { Body } from 'components/Typography';

export const Container = styled.button<{ $active: boolean }>`
  align-items: center;
  background: transparent;
  border: none;
  box-sizing: content-box;
  color: ${({ $active, theme }) => {
    if ($active) {
      return theme.name === 'LIGHT'
        ? theme.colors.coreTeal700
        : theme.colors.coreTeal500;
    }
    return theme.name === 'LIGHT'
      ? theme.colors.coreNeutral700
      : theme.colors.coreNeutralAurora;
  }};
  cursor: pointer;
  display: flex;
  line-height: 1;
  outline: none;
  padding: 0.875rem 1.25rem;
  position: relative;
  user-select: none;

  &:active {
    background: ${({ theme }) =>
      theme.name === 'LIGHT'
        ? theme.colors.coreTeal50
        : colorWithAlpha({
            color: theme.colors.coreNeutralWhite,
            alpha: 0.15,
          })};
  }

  &:disabled {
    color: ${({ theme }) =>
      theme.name === 'LIGHT'
        ? colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.33 })
        : colorWithAlpha({ color: theme.colors.coreNeutralWhite, alpha: 0.6 })};
    pointer-events: none;
  }
`;

export const Icon = styled(DefaultIcon)`
  height: 1rem;
  margin-right: 0.5rem;
  width: 1rem;
`;

export const Text = styled(Body)``;
