import { useState, useCallback } from 'react';

const COOKIES_CONSENT = 'cookies-consent';

const getCookiesConsent = () =>
  localStorage.getItem(COOKIES_CONSENT) === 'true';

const setCookiesConsent = () => {
  localStorage.setItem(COOKIES_CONSENT, 'true');
};

const useLogic = () => {
  const [hasCookiesConsent, setHasCookiesConsent] = useState<boolean>(
    getCookiesConsent(),
  );

  const consentCookies = useCallback(() => {
    setCookiesConsent();
    setHasCookiesConsent(true);
  }, []);

  return { consentCookies, hasCookiesConsent };
};

export default useLogic;

export type UseConnect = ReturnType<typeof useLogic>;
