import { memo } from 'react';
import type { FC } from 'react';
import { Container, Description, Image, Info, Title } from './styles';
import type { Props } from './types';

const Slide: FC<Props> = ({
  description,
  descriptionId,
  image,
  title,
  titleId,
}) => (
  <Container>
    <Image alt="" src={image} />
    <Info>
      <Title as="h1" id={titleId}>
        {title}
      </Title>
      <Description id={descriptionId}>{description}</Description>
    </Info>
  </Container>
);

export default memo(Slide);
