import { memo } from 'react';
import type { FC } from 'react';
import { AppConfig } from 'config';
import useMediaQuery from 'lib/useMediaQuery';
import { createAppsFlyerLink } from 'services/analytics/appsflyer';
import { media } from 'styles/mixins';
import Links from './Links';
import {
  AppStoreBadge,
  BadgeLink,
  BadgeLinks,
  Container,
  Content,
  Copyright,
  GooglePlayBadge,
  Logo,
  Wordmark,
} from './styles';
import type { Props } from './types';

const Footer: FC<Props> = ({ className, createAppsFlyerLinkOptions = {} }) => {
  const isTabletPortrait = useMediaQuery(media.tabletPortrait);
  const isPhonePortrait = useMediaQuery(media.phonePortrait);

  const { android, ios } = createAppsFlyerLink(createAppsFlyerLinkOptions);

  return (
    <Container className={className}>
      <Content>
        <Logo>
          <Wordmark />
          <Copyright>
            &copy;{new Date().getFullYear()} {AppConfig.companyName}
          </Copyright>
        </Logo>
        {(!isTabletPortrait || isPhonePortrait) && <Links />}
        <BadgeLinks>
          <BadgeLink
            aria-label="Link to App Store (opens in a new tab)"
            href={ios}
            target="_blank"
          >
            <AppStoreBadge />
          </BadgeLink>
          <BadgeLink
            aria-label="Link to Google Play (opens in a new tab)"
            href={android}
            target="_blank"
          >
            <GooglePlayBadge />
          </BadgeLink>
        </BadgeLinks>
      </Content>
      {isTabletPortrait && !isPhonePortrait && <Links />}
    </Container>
  );
};

export default memo(Footer);
