import type { FC } from 'react';
import format from 'date-fns/format';
import GiftCardPreview from '../GiftCardPreview';
import { Container, Content, ResultDescription, Title } from './styles';
import type { Props } from './types';

const ResultPage: FC<Props> = ({
  imageCaption,
  imageSrc,
  isPublic,
  message,
  recipientEmail,
  shouldBeSentAt,
  senderName,
}) => {
  const date = shouldBeSentAt ? (
    <>
      {' '}
      on <strong>{format(new Date(shouldBeSentAt), 'MM/dd/yyyy')}</strong>
    </>
  ) : (
    ''
  );

  return (
    <Container $isPublic={isPublic}>
      <GiftCardPreview
        imageCaption={imageCaption}
        imageSrc={imageSrc}
        isPublic={isPublic}
        message={message}
        senderName={senderName ?? ''}
        showMessage={message !== ''}
      />
      <Content>
        <Title>Thank you for purchasing a Waking Up gift card.</Title>
        <ResultDescription>
          We will send your gift to <strong>{recipientEmail}</strong>
          {date}, with full instructions on how to activate their subscription.
          If you have any questions, please reach out to{' '}
          <a href="mailto:support@wakingup.com">support@wakingup.com</a>.
        </ResultDescription>
      </Content>
    </Container>
  );
};

export default ResultPage;
