import styled from 'styled-components';
import colorWithAlpha from 'lib/color';
import { Body } from 'components/Typography';
import DefaultIcon from 'components/Icon';

export const Container = styled.button`
  align-items: center;
  background-color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.015 })
      : colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.1 })};
  border: none;
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  cursor: pointer;
  display: flex;
  min-width: max-content;
  text-decoration: none;
  width: 100%;

  &:disabled {
    color: ${({ theme }) =>
      colorWithAlpha({
        color:
          theme.name === 'LIGHT'
            ? theme.colors.coreNeutralBlack
            : theme.colors.coreNeutralWhite,
        alpha: 0.33,
      })};
  }

  &:not(:disabled):hover {
    background-color: ${({ theme }) =>
      colorWithAlpha({
        color: theme.colors.coreNeutralWhite,
        alpha: theme.name === 'LIGHT' ? 0.5 : 0.05,
      })};
  }

  &:not(:disabled):active {
    background-color: ${({ theme }) =>
      colorWithAlpha({
        color: theme.colors.coreNeutralWhite,
        alpha: theme.name === 'LIGHT' ? 1 : 0.15,
      })};
  }

  &:focus {
    color: ${({ theme }) => theme.colors.fontEmphasized};

    &:hover {
      background-color: ${({ theme }) =>
        theme.name === 'LIGHT' &&
        colorWithAlpha({
          color: theme.colors.coreNeutralBlack,
          alpha: 0.05,
        })};
    }

    &:active {
      background-color: ${({ theme }) =>
        theme.name === 'LIGHT' && theme.colors.coreTeal50};
    }
  }
`;

export const Icon = styled(DefaultIcon)`
  margin-left: ${({ theme }) => theme.measures.l};
  margin-right: ${({ theme }) => theme.measures.l};
`;

export const Text = styled(Body)<{
  $divider: 'both' | 'bottom' | 'top';
  $hasIcon: boolean;
}>`
  border-bottom: ${({ $divider, theme }) =>
    $divider !== 'top' && `1px solid ${theme.colors.borderBase}`};
  border-top: ${({ $divider, theme }) =>
    $divider !== 'bottom' && `1px solid ${theme.colors.borderBase}`};
  padding: ${({ $hasIcon, theme }) =>
    `1.375rem ${theme.measures.l} 1.375rem ${$hasIcon ? 0 : theme.measures.l}`};
  text-align: left;
  width: 100%;
`;
