/* eslint-disable import/prefer-default-export */
import { AppConfig } from 'config';

export const SUBSCRIPTION_LANDING_IMAGES: Record<
  string,
  Record<'alt' | 'desktop' | 'mobile', string>
> = {
  month: {
    alt: 'Boat in the sea with a balcony over a cloud',
    desktop: `${AppConfig.imagesURL}/subscription-monthly-desktop.jpg`,
    mobile: `${AppConfig.imagesURL}/subscription-monthly-mobile.jpg`,
  },
  year: {
    alt: 'Landscape with a big arch',
    desktop: `${AppConfig.imagesURL}/subscription-yearly-desktop.jpg`,
    mobile: `${AppConfig.imagesURL}/subscription-yearly-mobile.jpg`,
  },
  lifetime: {
    alt: 'Man in a big room with huge doors on every side',
    desktop: `${AppConfig.imagesURL}/subscription-lifetime-desktop.jpg`,
    mobile: `${AppConfig.imagesURL}/subscription-lifetime-mobile.jpg`,
  },
};
