import { useCallback } from 'react';
import { useField } from 'formik';
import { LogicProps } from './types';

const useLogic = ({ name }: LogicProps) => {
  const [{ value }, { error, touched }, { setValue }] = useField<number | null>(
    name,
  );

  const handleSelectPrice = useCallback(
    (price: number) => {
      setValue(price);
    },
    [setValue],
  );

  return {
    error,
    handleSelectPrice,
    touched,
    value,
  };
};

export default useLogic;
export type UseLogic = ReturnType<typeof useLogic>;
