import styled from 'styled-components';
import { media } from 'styles/mixins';
import { Display, Heading } from 'components/Typography';
import { Props } from '../types';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  ${media.phonePortrait} {
    width: 100%;
  }
`;

export const ImageContent = styled.div<{
  $backgroundColor: Props['imgBackgroundColor'];
  $shadow: Props['imgShadow'];
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: 0.125rem;
  box-shadow: ${({ $shadow, theme }) =>
    $shadow === 'box-shadow' ? theme.elevation.web3 : 'none'};
  flex: 0 0 auto;
  height: 35vh;
  margin-bottom: 1.6rem;
  margin-right: 0;
  max-height: 340px;
  max-width: 340px;
  overflow: ${({ $shadow }) =>
    $shadow === 'drop-shadow' ? 'visible' : 'hidden'};
  position: relative;
  width: 35vh;

  ${media.phonePortrait} {
    height: 0;
    margin-bottom: 1rem;
    padding-bottom: 100%;
    width: 100%;
  }

  @media (max-height: 650px) {
    height: 14rem;
    padding-bottom: 0;
    width: 14rem;
  }

  @media (max-height: 500px) {
    display: none;
  }
`;

export const Image = styled.img<{
  $objectFit: Props['imgObjectFit'];
  $objectPosition: Props['imgObjectPosition'];
  $shadow: Props['imgShadow'];
}>`
  display: block;
  filter: ${({ $shadow }) =>
    $shadow === 'drop-shadow'
      ? 'drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.24))'
      : 'none'};
  height: 100%;
  object-fit: ${({ $objectFit }) => $objectFit};
  object-position: ${({ $objectPosition }) => $objectPosition};
  position: absolute;
  width: 100%;
`;

export const TextContent = styled.div`
  width: 100%;
`;

export const Title = styled(Display).attrs(() => ({
  $size: 'small',
}))`
  flex-shrink: 0;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Subtitle = styled(Heading)`
  color: ${({ theme }) => theme.colors.coreNeutral100};
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
