/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { FEATURE_CONTENT_DATA_FRAGMENT } from '../fragments';

export const GET_FEATURE_CONTENT = gql`
  query GetFeatureContent {
    feature_content {
      ...FeatureContentData
    }
  }
  ${FEATURE_CONTENT_DATA_FRAGMENT}
`;
