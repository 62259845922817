import { createRoot } from 'react-dom/client';
import { setUpAnalytics } from 'services/analytics';
import { initializeSentry } from 'services/sentry';
import App from './App';
import './styles';

initializeSentry();
setUpAnalytics();

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(<App />);
}
