import { memo } from 'react';
import type { FC } from 'react';
import Icon from 'components/Icon';
import { Button, Container, Content, Message } from './styles';
import type { Props } from './types';

const LockedContentBanner: FC<Props> = ({ className }) => (
  <Container className={className}>
    <Content>
      <Icon aria-hidden="true" name="lockSimpleLight" />
      <Message>Unlock with a paid membership</Message>
    </Content>
    <Button to="/account/subscription">Show Membership Options</Button>
  </Container>
);

export default memo(LockedContentBanner);
