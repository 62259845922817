import { useCallback, useState } from 'react';
import type { FormikHelpers } from 'formik';
import { ActiveCampaigns } from 'graphql/generated';
import { ModalType } from 'graphql/reactive';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import useMe from 'graphql/hooks/useMe';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import useStripePaymentMethod from 'graphql/hooks/useStripePaymentMethod';
import useStripePaymentMethodActions from 'graphql/hooks/useStripePaymentMethodActions';
import useSubscriptionActions from 'graphql/hooks/useSubscriptionActions';
import type { FormFields } from './Form/types';

const useConnect = () => {
  const { isOpen, payload } = useModal(ModalType.UPGRADE_TO_ANNUAL);
  const { closeModal } = useModalActions();
  const {
    isEligibleForYearlyUpgradeWithDiscount,
    profile: { email, firstName, lastName, subscriptionSource },
  } = useMe();
  const stripe = useStripe();
  const elements = useElements();
  const { stripePaymentMethod } = useStripePaymentMethod({
    skip:
      !isEligibleForYearlyUpgradeWithDiscount ||
      subscriptionSource !== 'stripe',
  });
  const { update: updateCreditCard } = useStripePaymentMethodActions();
  const { isUpdating, update } = useSubscriptionActions();

  const [showCardForm, setShowCardForm] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { brand, last4 = '' } = stripePaymentMethod || {};
  const { planId } = payload || {};

  const handleClose = useCallback(() => {
    closeModal();
    setTimeout(() => {
      setShowCardForm(false);
    }, 300);
  }, [closeModal]);

  const handleChangeShowCardForm = useCallback(() => {
    setShowCardForm((prevValue) => !prevValue);
  }, []);

  const handleSubmit = useCallback(
    async (
      values: FormFields,
      { setFieldError }: FormikHelpers<FormFields>,
    ) => {
      if (stripe && elements) {
        try {
          const card = elements.getElement('card');

          if (!card) {
            throw new Error(
              'Unable to get card information. Try again in a few seconds',
            );
          }

          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card,
            billing_details: {
              email,
              name:
                firstName && lastName ? `${firstName} ${lastName}` : undefined,
            },
          });

          if (error || !paymentMethod) {
            throw new Error(error.message);
          }

          await updateCreditCard(paymentMethod.id);

          setShowCardForm(false);
        } catch (error) {
          setFieldError(
            'submitError',
            error.message ||
              'Unable to complete transaction. Try again in a few seconds',
          );
        }
      }
    },
    [elements, email, firstName, lastName, stripe, updateCreditCard],
  );

  const handleUpgrade = useCallback(async () => {
    if (planId) {
      try {
        await update({
          campaign: ActiveCampaigns.upgrade_to_yearly_discount,
          planId,
        });

        setSubmitError(null);
        closeModal();
      } catch (err) {
        setSubmitError(
          err?.message ||
            'Unable to upgrade subscription. Please, try again in a few minutes',
        );
      }
    }
  }, [closeModal, planId, update]);

  return {
    brand,
    handleChangeShowCardForm,
    handleClose,
    handleSubmit,
    handleUpgrade,
    isOpen,
    isUpdating,
    last4,
    planId,
    showCardForm,
    submitError,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
