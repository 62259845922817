import styled from 'styled-components';
import { media } from 'styles/mixins';
import { Form as DefaultForm } from 'formik';
import DefaultTextField from 'components/Inputs/TextField';
import DefaultPriceSelector from './PriceSelector';

export const FormikForm = styled(DefaultForm)`
  width: 100%;
  max-width: 45rem;
`;

export const TextField = styled(DefaultTextField)`
  margin-bottom: 4.0625rem;

  ${media.phoneLandscape} {
    margin-bottom: 3rem;
  }
`;

export const PriceSelector = styled(DefaultPriceSelector)`
  margin-bottom: 4.9375rem;

  ${media.phoneLandscape} {
    margin-bottom: 2rem;
  }
`;
