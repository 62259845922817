import { useCallback, memo } from 'react';
import type { FC, KeyboardEvent, MouseEvent } from 'react';
import { CardListItem } from './styles';
import type { Props } from './types';

const Course: FC<Props> = ({
  className,
  courseId,
  onOpenOptionsMenu,
  onPlay,
  packId,
  ...rest
}) => {
  const handlePlay = useCallback(() => {
    onPlay(courseId, packId);
  }, [courseId, onPlay, packId]);

  const handleOpenOptionsMenu = useCallback(
    (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLButtonElement>) => {
      onOpenOptionsMenu(event, courseId, packId);
    },
    [courseId, onOpenOptionsMenu, packId],
  );

  return (
    <CardListItem
      className={className}
      onClick={handlePlay}
      onOpenOptions={handleOpenOptionsMenu}
      {...rest}
    />
  );
};

export default memo(Course);
