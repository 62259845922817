export enum PlayerSource {
  CONTINUE_LISTENING = 'Continue Listening',
  DEEPLINK = 'Deep Link',
  FAVORITES = 'Favorites',
  FEATURED_CONTENT = 'Featured Content',
  HOME_WIDGET = 'Home Widget',
  INTRO_COURSE = 'Introductory Course',
  MEDIA_PREVIEW = 'Media Preview',
  PACK = 'Pack',
  RECENTLY_PLAYED = 'Recently Played',
  RECOMMENDED = 'Recommended',
  RETREAT = 'Retreat',
}

export enum PlayerAutoplayType {
  BOOKMARKS_DAILY = 'BOOKMARKS_DAILY',
  BOOKMARKS_LIFE = 'BOOKMARKS_LIFE',
  BOOKMARKS_PRACTICE = 'BOOKMARKS_PRACTICE',
  BOOKMARKS_THEORY = 'BOOKMARKS_THEORY',
  PACK = 'PACK',
  RECENTLY_PLAYED_LIFE = 'RECENTLY_PLAYED_LIFE',
  RECENTLY_PLAYED_PRACTICE = 'RECENTLY_PLAYED_PRACTICE',
  RECENTLY_PLAYED_THEORY = 'RECENTLY_PLAYED_THEORY',
}
