import type { FC } from 'react';
import type { BaseIconProps as Props } from '../../Icons';

const EqualizerStatic: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size ? size * (13 / 12) : undefined}
    viewBox="0 0 12 13"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#a)" fill="currentColor">
      <path d="M0 4.5a1.5 1.5 0 1 1 3 0V14H0V4.5Zm4 1a1.5 1.5 0 1 1 3 0V14H4V5.5Zm4 5a1.5 1.5 0 1 1 3 0V14H8v-3.5Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h12v13H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default EqualizerStatic;
