import { makeVar } from '@apollo/client';
import { updateSettings } from '../requests';

export const audioIsLoadingVar = makeVar<boolean>(true);
export const audioIsPlayingVar = makeVar<boolean>(false);
export const audioDurationVar = makeVar<number>(0);
export const audioPositionVar = makeVar<number>(0);
export const audioSpeedVar = makeVar<number>(1);

export const resetAudio = () => {
  audioIsLoadingVar(true);
  audioIsPlayingVar(false);
  audioDurationVar(0);
  audioPositionVar(0);
};

export const setAudioIsLoading = (data: boolean) => {
  audioIsLoadingVar(data);
};

export const setAudioIsPlaying = (data: boolean) => {
  audioIsPlayingVar(data);
};

export const setAudioDuration = (data: number) => {
  audioDurationVar(data);
};

export const setAudioPosition = (data: number) => {
  audioPositionVar(data);
};

export const setAudioSpeed = (data: number) => {
  audioSpeedVar(data);
  updateSettings({ playbackSpeed: data.toFixed(1) }, true);
};
