import { HitsProvided } from 'react-instantsearch-core';

export enum TabType {
  SERIES = 'Series',
  CONVERSATIONS = 'Conversations',
  SESSIONS = 'Sessions',
}

export type Result = {
  hash: string;
  type: 'Conversation' | 'Course' | 'Pack';
};

export type Props = HitsProvided<Result> & {
  onClick: (query: {}) => void;
};

export type ConnectProps = {
  hits: Props['hits'];
};
