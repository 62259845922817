import { useMemo } from 'react';
import { type QueryHookOptions, useQuery } from '@apollo/client';
import { normalizeAvatar } from 'models/Avatar';
import {
  AvatarsDocument,
  type AvatarsQuery,
  type AvatarsQueryVariables,
} from '../generated';

const useAvatars = (
  options?: QueryHookOptions<AvatarsQuery, AvatarsQueryVariables>,
) => {
  const { data, loading } = useQuery(AvatarsDocument, options);

  const avatars = useMemo(
    () => (data?.avatars ? data.avatars.map(normalizeAvatar) : []),
    [data],
  );

  return { avatars, loading };
};

export default useAvatars;
export type UseAvatars = ReturnType<typeof useAvatars>;
