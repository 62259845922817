import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultButton from 'components/Button';
import DefaultIcon from 'components/Icon';
import DefaultModal from 'components/Modal';
import DefaultPopup from './Popup';

export const Modal = styled(DefaultModal)`
  .MuiPaper-root {
    background-image: url('https://assets.wakingup.com/web/images/share-cta-background.jpg');
    background-size: cover;
    padding: 2rem 1.6rem;
  }
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.colors.fontBase};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
`;

export const IconButton = styled.button`
  background: transparent;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: block;
  left: 0;
  line-height: 1;
  outline: none;
  position: absolute;
  width: fit-content;

  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

export const ModalTitle = styled.h2`
  /* color: ${({ theme }) => theme.colors.coreNeutralAurora}; */
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.01em;
  line-height: 1.5;
  margin: 0 auto;
`;

export const Card = styled.div`
  background: #f4f4f4;
  border-radius: 0.328rem;
  box-shadow: ${({ theme }) => theme.elevation.web3};
  margin-bottom: 2.875rem;
  text-align: center;
  margin-top: 3.125rem;
  max-width: 20.3125rem;
  padding: 1.8125rem 1.4375rem;
  position: relative;
`;

export const Popup = styled(DefaultPopup)`
  position: absolute;
  left: 0;
  bottom: -3.125rem;
`;

export const Title = styled.h3`
  color: inherit;
  font-size: 28px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.01em;
  line-height: 0.96;
  margin-bottom: 0.6563rem;
`;

export const Subtitle = styled.p`
  color: inherit;
  font-size: 1.1638rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 1.24;
  margin-bottom: 1.4375rem;
`;

export const Overline = styled.p`
  color: #646464;
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.2em;
  line-height: 1.33;
  text-transform: uppercase;
`;

export const InviteOverline = styled(Overline)`
  margin-bottom: 0.75rem;
`;

export const EmailButton = styled(DefaultButton)`
  height: 2.5625rem;
  background-color: ${({ theme }) => theme.colors.coreBlue500};
  border: 2px solid ${({ theme }) => theme.colors.coreBlue500};
  color: ${({ theme }) => theme.colors.coreNeutralWhite};
  margin-bottom: 2.1681rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.coreBlue700};
    color: ${({ theme }) => theme.colors.coreNeutralWhite};
  }
`;

export const CopyOverline = styled(Overline)`
  margin-bottom: 0.4569rem;
`;

export const LinkContainer = styled.div`
  border-radius: 0.375rem;
  display: flex;
  justify-content: space-between;
  max-height: 2.5rem;
`;

export const Label = styled.label`
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  white-space: nowrap;
`;

export const TextArea = styled.input`
  background-color: #e5e5e5;
  border: none;
  border-radius: 0.375rem 0 0 0.375rem;
  color: #0e1836;
  outline: none;
  padding: 0.625rem;
  resize: none;
  width: 16.25rem;

  ${media.phonePortrait} {
    height: 2.5rem;
  }
`;

export const CopyButton = styled(DefaultButton)`
  background-color: ${({ theme }) => theme.colors.coreBlue500};
  border: none;
  border-radius: 0 0.375rem 0.375rem 0;
  cursor: pointer;
  height: 2.5rem;
  width: 3.6875rem;
  box-shadow: none;
  opacity: inherit;
  outline: none;
  text-decoration: none;
  transition: opacity 0.2s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.coreBlue700};
    color: ${({ theme }) => theme.colors.coreNeutralWhite};
  }
`;

export const CopyIcon = styled(DefaultIcon)`
  color: ${({ theme }) => theme.colors.coreNeutralAurora};
`;

export const Message = styled.h3`
  color: ${({ theme }) => theme.colors.coreNeutralWhite};
  font-size: 1.1413rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: -0.02em;
  line-height: 1.25;
  text-align: center;
`;

export const About = styled.p`
  color: ${({ theme }) => theme.colors.coreNeutralAurora};
  font-size: 0.8194rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  letter-spacing: 0.01em;
  line-height: 1.4286;
  text-align: center;
  margin-bottom: 1.05rem;
`;

export const ListenButton = styled(DefaultButton)`
  background-color: ${({ theme }) => theme.colors.coreNeutralWhite};
  border-color: ${({ theme }) => theme.colors.coreNeutralWhite};
  border-radius: 0.1533rem;
  color: ${({ theme }) => theme.colors.coreBlue500};
  cursor: pointer;
  width: 6.6875rem;
  box-shadow: ${({ theme }) => theme.elevation.web1};
  outline: none;
  text-decoration: none;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: 0.6452rem;
  line-height: 1.33;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: none;

  &:focus {
    svg {
      color: ${({ theme }) => theme.colors.coreNeutralWhite};
    }
  }
`;

export const PlayIcon = styled(DefaultIcon)`
  color: ${({ theme }) => theme.colors.coreBlue500};
  margin-right: 0.3575rem;
`;
