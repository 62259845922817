import { memo } from 'react';
import type { FC } from 'react';
import { NavLink } from 'react-router-dom';
import Icon from 'components/Icon';
import { Container, NavItem, Title } from './styles';
import type { Props } from './types';

const NavigationOption: FC<Props> = ({
  end,
  iconName,
  id,
  onClick,
  title,
  to,
}) => (
  <Container>
    <NavItem
      as={to ? NavLink : 'button'}
      end={to ? end : undefined}
      id={id}
      onClick={onClick}
      to={to}
    >
      <Icon aria-hidden="true" name={iconName} />
      <Title as="span">{title}</Title>
    </NavItem>
  </Container>
);

export default memo(NavigationOption);
