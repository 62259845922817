import styled, { css } from 'styled-components';
import { List as DefaultList } from 'react-virtualized';
import colorWithAlpha from 'lib/color';
import { media } from 'styles/mixins';
import DefaultLockedContentBanner from 'components/LockedContentBanner';

export const List = styled(DefaultList)<{ $hideTopBorder: boolean }>`
  border-top: 1px solid
    ${({ theme }) =>
      theme.name === 'LIGHT'
        ? colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.1 })
        : colorWithAlpha({
            color: theme.colors.coreNeutralWhite,
            alpha: 0.25,
          })};
  outline: none;
  overflow: visible !important;

  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }

  ${media.tabletPortrait} {
    margin-left: -1.4rem;
    margin-right: -1.4rem;
  }

  ${media.phonePortrait} {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  ${({ $hideTopBorder }) =>
    $hideTopBorder &&
    css`
      border-top: none;
    `}
`;

export const LockedContentBanner = styled(DefaultLockedContentBanner)`
  padding-bottom: 1.25rem;
`;
