import type { FC } from 'react';
import { Props } from './types';

const NumberPlaybackSpeed08xLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M3.276 16a.303.303 0 0 1-.204-.072.303.303 0 0 1-.072-.204v-.864a.26.26 0 0 1 .072-.192.278.278 0 0 1 .204-.084h.864c.08 0 .148.028.204.084a.24.24 0 0 1 .084.192v.864c0 .08-.028.148-.084.204A.303.303 0 0 1 4.14 16h-.864Zm6.242.12c-.624 0-1.172-.1-1.644-.3a2.55 2.55 0 0 1-1.104-.888c-.264-.384-.396-.832-.396-1.344 0-.472.116-.884.348-1.236.232-.36.548-.636.948-.828a2.011 2.011 0 0 1-.744-.72 2.148 2.148 0 0 1-.252-1.056c0-.704.256-1.256.768-1.656.52-.408 1.212-.612 2.076-.612.856 0 1.54.204 2.052.612.52.4.78.948.78 1.644 0 .4-.088.752-.264 1.056-.168.296-.408.54-.72.732.4.192.716.468.948.828.232.352.348.764.348 1.236 0 .512-.132.96-.396 1.344-.264.384-.636.68-1.116.888-.472.2-1.016.3-1.632.3Zm0-5.028c.48-.008.884-.132 1.212-.372.328-.24.492-.556.492-.948 0-.4-.164-.72-.492-.96-.32-.248-.724-.372-1.212-.372-.496 0-.908.12-1.236.36-.32.24-.48.564-.48.972 0 .4.16.72.48.96.328.24.74.36 1.236.36Zm0 4.068c.56 0 1.028-.144 1.404-.432.376-.288.564-.668.564-1.14 0-.472-.188-.848-.564-1.128-.376-.28-.844-.42-1.404-.42-.568 0-1.04.14-1.416.42-.376.28-.564.656-.564 1.128 0 .472.188.852.564 1.14.376.288.848.432 1.416.432Zm5.013.84a.272.272 0 0 1-.18-.072.244.244 0 0 1-.072-.18c0-.072.028-.144.084-.216l2.088-2.724-1.956-2.58a.32.32 0 0 1-.084-.216.244.244 0 0 1 .252-.252h.612c.12 0 .224.064.312.192l1.596 2.076 1.596-2.064a.802.802 0 0 1 .132-.144.272.272 0 0 1 .18-.06h.588a.244.244 0 0 1 .252.252.32.32 0 0 1-.084.216l-1.98 2.604 2.088 2.7a.351.351 0 0 1 .084.216.244.244 0 0 1-.252.252h-.636c-.12 0-.224-.06-.312-.18l-1.68-2.196-1.704 2.196a.72.72 0 0 1-.144.132.262.262 0 0 1-.168.048h-.612Z"
      fill="currentColor"
    />
  </svg>
);

export default NumberPlaybackSpeed08xLight;
