import type { FC } from 'react';
import Layout from '../Layout';
import { Container, Button } from './styles';
import useConnect from './connect';

const Payments: FC = () => {
  useConnect();

  return (
    <Layout>
      <Container>
        <Button to="/checkout">Purchase a subscription</Button>
        <Button to="/purchase-gift-card">Purchase Gift Card</Button>
        <Button to="/gifts/redeem">Redeem Gift Card</Button>
        <Button to="/promotions/redeem">Redeem Promo Code</Button>
        <Button to="/">Try the Web App!</Button>
      </Container>
    </Layout>
  );
};

export default Payments;
