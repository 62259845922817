import { useCallback } from 'react';
import type { FC } from 'react';
import { Container, Radio } from './styles';
import type { Props } from './types';

const Element: FC<Props> = ({ isSelected, onClick, text, type }) => {
  const handleClick = useCallback(() => onClick(type), [onClick, type]);

  return (
    <Container
      $isSelected={isSelected}
      data-cypress-class="self-timer-list-selector-element"
      onClick={handleClick}
    >
      {text}
      <Radio
        checked={isSelected}
        inputProps={{ 'aria-label': `Radio ${text.toLowerCase()}` }}
      />
    </Container>
  );
};

export default Element;
