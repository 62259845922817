/* eslint-disable import/prefer-default-export */
export const MENU_WIDTH: Record<string, string> = {
  backgroundSounds: '15.625rem',
  course: '14.85rem',
  coursePlayer: '14.85rem',
  daily: '14.85rem',
  dailyPlayer: '14.85rem',
  pack: '12.2rem',
  retreat: '13.4rem',
  speed: '8.8125rem',
};
