import styled from 'styled-components';
import DefaultButton from 'components/Button';
import { motion } from 'framer-motion';
import DefaultConfirmation from 'components/Confirmation';
import DefaultStoreButton from 'components/StoreButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 3rem;
`;

export const Confirmation = styled(DefaultConfirmation)``;

export const Info = styled.h1`
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: 3rem;
  margin-top: 1rem;
  text-align: center;
`;

export const Button = styled(DefaultButton)`
  &&& {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
`;

export const Content = styled(motion.div).attrs(() => ({
  animate: { opacity: 1 },
  transition: { duration: 1, delay: 0.25 },
}))`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  opacity: 0;
`;

export const Email = styled.div`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const StoreButton = styled(DefaultStoreButton)`
  &&& {
    margin-bottom: 1rem;
  }
`;
