import type { FC } from 'react';
import useAlgolia from 'graphql/hooks/useAlgolia';
import Tag from './Tag';
import Loader from '../Loader';
import { Body, Container, TagItem, TagsContainer } from './styles';
import type { Props } from './types';

const TopSearches: FC<Props> = ({ onClick, query, showTitle = true }) => {
  const { tags } = useAlgolia({ fetchPolicy: 'cache-first' });

  if (tags.length === 0) {
    return <Loader />;
  }

  return !query || query === '' ? (
    <Container role="region">
      {showTitle && <Body as="h2">Top Searches</Body>}
      <TagsContainer>
        {tags.map(({ value, id }) => (
          <TagItem key={id}>
            <Tag onClick={onClick} value={value} />
          </TagItem>
        ))}
      </TagsContainer>
    </Container>
  ) : null;
};

export default TopSearches;
