/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultButtonIcon from 'components/ButtonIcon';

export const ButtonIcon = styled(DefaultButtonIcon)`
  margin-right: 1rem;

  ${media.phoneLandscape} {
    margin-right: 0.625rem;
  }
`;
