import { useCallback, memo } from 'react';
import type { FC } from 'react';
import Icon from 'components/Icon';
import { Container } from './styles';
import { Props } from './types';

const Control: FC<Props> = ({ className, onTriggerScroll, type }) => {
  const handleTriggerScroll = useCallback(() => {
    onTriggerScroll(type);
  }, [onTriggerScroll, type]);

  return (
    <Container
      className={className}
      onClick={handleTriggerScroll}
      type="button"
    >
      <Icon
        aria-label={
          type === 'back' ? 'Select previous item' : 'Select next item'
        }
        name={type === 'back' ? 'arrowLeftLight' : 'arrowRightLight'}
      />
    </Container>
  );
};

export default memo(Control);
