import { useMemo } from 'react';
import { useQuery, type QueryHookOptions } from '@apollo/client';
import { normalizeLiveMeetingInfo } from 'models/Retreat';
import {
  GetLiveMeetingInfoDocument,
  type GetLiveMeetingInfoQuery,
  type GetLiveMeetingInfoQueryVariables,
} from '../generated';

const useLiveMeetingInfo = (
  options: QueryHookOptions<
    GetLiveMeetingInfoQuery,
    GetLiveMeetingInfoQueryVariables
  > = {},
) => {
  const { data, loading } = useQuery(GetLiveMeetingInfoDocument, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    ...options,
  });

  const liveMeetingInfo = useMemo(
    () =>
      data?.liveMeetingInfo
        ? normalizeLiveMeetingInfo(data.liveMeetingInfo)
        : undefined,
    [data],
  );

  return {
    liveMeetingInfo,
    loading: !data && loading,
  };
};

export default useLiveMeetingInfo;
export type UseLiveMeetingInfo = ReturnType<typeof useLiveMeetingInfo>;
