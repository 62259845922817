import CardListItem from 'components/CardListItem';
import { memo, type FC, useCallback } from 'react';
import type { Props } from './types';

const Pack: FC<Props> = ({ hash, imageUrl, onClick, subtitle, title }) => {
  const handleClick = useCallback(() => {
    onClick(hash);
  }, [hash, onClick]);

  return (
    <CardListItem
      imageUrl={imageUrl}
      onClick={handleClick}
      subtitle={subtitle}
      title={title}
    />
  );
};

export default memo(Pack);
