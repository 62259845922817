import { backgroundSoundsVar } from 'graphql/reactive';
import { getSettings, updateSettings } from '.';

const updatePlayerSettings = async (
  courseHash: string,
  lastKnownLocation: number,
) => {
  const settings = await getSettings();
  const {
    backgroundAudioEnabled,
    backgroundAudioProgress,
    backgroundAudioTrackIndex,
    backgroundAudioVolume,
  } = backgroundSoundsVar();

  const prevRecentlyPlayed =
    settings.recentlyPlayed?.filter(
      (rp, i) =>
        rp.course_hash !== courseHash && (i < 8 || rp.last_known_location > 0),
    ) || [];

  await updateSettings(
    {
      backgroundAudioEnabled,
      backgroundAudioProgress,
      backgroundAudioTrackIndex,
      backgroundAudioVolumeWeb: backgroundAudioVolume,
      player: null,
      recentlyPlayed: [
        {
          course_hash: courseHash,
          date: Date.now(),
          last_known_location:
            lastKnownLocation > 0 && lastKnownLocation < 1
              ? -1
              : Math.floor(lastKnownLocation),
        },
        ...prevRecentlyPlayed,
      ],
    },
    true,
  );
};

export default updatePlayerSettings;
