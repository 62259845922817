import type { FC } from 'react';
import { Props } from './types';

const NumberPlaybackSpeed1xLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M10.06 8H8.47L6.49 9.254v1.5l1.832-1.149h.046V16h1.692V8zm3.747 2H12.09l1.718 3-1.78 3h1.718l1.164-2.074L16.093 16h1.699l-1.785-3 1.738-3h-1.707l-1.129 2.098L13.807 10z"
      fill="currentColor"
    />
  </svg>
);

export default NumberPlaybackSpeed1xLight;
