import { useCallback } from 'react';
import { FormikHelpers } from 'formik';
import { ModalType } from 'graphql/reactive';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import useMiscActions from 'graphql/hooks/useMiscActions';
import { FormFields, Step } from './Form/types';

const useConnect = () => {
  const { isOpen } = useModal(ModalType.SUPPORT);
  const { closeModal } = useModalActions();
  const { createTicket } = useMiscActions();

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleSubmit = useCallback(
    async (
      { message, subject, step }: FormFields,
      { setErrors, setFieldValue }: FormikHelpers<FormFields>,
    ): Promise<void> => {
      try {
        if (step === Step.message) {
          await createTicket({ subject, message });
          setFieldValue('step', Step.thanks);
        } else if (step === Step.thanks) {
          handleClose();
        }
      } catch (error) {
        setErrors({
          submitError:
            error?.message ||
            `The message couldn't be sent. Try again in a few seconds.`,
        });
      }
    },
    [createTicket, handleClose],
  );

  return { handleClose, handleSubmit, isOpen };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
