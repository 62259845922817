import type { FC } from 'react';
import { Props } from './types';

const ArrowUUpLeftLight: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M7.5 12.75 3 8.25l4.5-4.5"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 18.75h8.25A5.25 5.25 0 0 0 21 13.5v0a5.251 5.251 0 0 0-5.25-5.25H3"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowUUpLeftLight;
