import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultIcon from 'components/Icon';
import {
  Overline as DefaultOverline,
  Heading as DefaultHeading,
  Body as DefaultBody,
} from 'components/Typography';

const FORBIDDEN_OPACITY = '0.4';

export const Container = styled.button<{ $color?: string }>`
  background-color: ${({ $color }) => $color || '#091C55'};
  border: none;
  color: ${({ theme }) => theme.colors.coreNeutralWhite};
  cursor: pointer;
  display: block;
  outline-offset: 0.25rem;
  padding: 2.625rem 1.4rem;
  text-align: left;
  width: 100%;

  ${media.phonePortrait} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const Flex = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

export const Info = styled.div<{ $isForbidden: boolean }>`
  opacity: ${({ $isForbidden }) => ($isForbidden ? FORBIDDEN_OPACITY : '1')};
`;

export const Overline = styled(DefaultOverline)`
  font-size: 0.75rem;
  line-height: 1.3;
  margin-bottom: 0.1875rem;
`;

export const Title = styled(DefaultHeading).attrs(() => ({
  $weight: 'bold',
}))`
  font-size: 1.125rem;
  line-height: 1.21;
  margin-bottom: 0.0625rem;
`;

export const Icon = styled(DefaultIcon)`
  flex: 0 0 auto;
  margin-left: 2.625rem;
`;

export const Description = styled(DefaultBody).attrs(() => ({
  $weight: 'regular',
  $size: 'medium',
}))<{ $isForbidden: boolean }>`
  margin-top: 0.5625rem;
  opacity: ${({ $isForbidden }) => ($isForbidden ? FORBIDDEN_OPACITY : '1')};
  padding-right: 4.125rem;
`;
