import type { FC } from 'react';
import { SubscriptionPeriod } from 'models/SubscriptionPlan';
import PublicPageLayout from 'components/PublicPageLayout';
import Lifetime from './Lifetime';
import Standard from './Standard';
import type { Props } from './types';
import useConnect from './connect';

const Subscription: FC<Props> = ({
  code,
  email,
  expiresAt,
  isPublic = false,
  originalAmount,
  period,
  priceId,
  userId,
}) => {
  const {
    handleOpenStripePayment,
    hasInvalidCode,
    isLoading,
    nPlans,
    planAmount,
    planId,
    planInterval,
    planOriginalAmount,
    planPeriod,
    planType,
    search,
    slashedOriginalPrice,
  } = useConnect({ code, email, originalAmount, period, priceId, userId });

  const hasValidCode = !!code && planAmount !== planOriginalAmount;
  const hasLifetimePromo = !!userId && planAmount !== planOriginalAmount;
  const hasCustomOriginalPrice = !!originalAmount;

  const isLifetime =
    period === SubscriptionPeriod.LIFETIME ||
    period === SubscriptionPeriod.MENU_LIFETIME;

  const showNoSubscriptionPlans = !isLoading && (nPlans === 0 || !planId);
  const showInvalidCode =
    !isLoading && nPlans > 0 && !!planId && hasInvalidCode;
  const showPlanInfo = !isLoading && nPlans > 0 && !!planId && !hasInvalidCode;

  return isLifetime ? (
    <Lifetime
      expiresAt={expiresAt}
      hasPromo={hasValidCode || hasLifetimePromo}
      isLoading={isLoading}
      onClick={handleOpenStripePayment}
      planAmount={planAmount}
      planOriginalAmount={planOriginalAmount}
      priceId={priceId}
      userId={userId}
    />
  ) : (
    <PublicPageLayout>
      <Standard
        hasPromo={hasValidCode || hasLifetimePromo || hasCustomOriginalPrice}
        isLoading={isLoading}
        isPublic={isPublic}
        onClick={handleOpenStripePayment}
        planAmount={planAmount}
        planInterval={planInterval}
        planOriginalAmount={planOriginalAmount}
        planPeriod={planPeriod}
        planType={planType}
        search={search}
        showInvalidCode={showInvalidCode}
        showNoSubscriptionPlans={showNoSubscriptionPlans}
        showPlanInfo={showPlanInfo}
        slashedOriginalPrice={slashedOriginalPrice}
      />
    </PublicPageLayout>
  );
};

export default Subscription;
