import type { FC } from 'react';
import { toggleHelpScout } from 'services/helpscout';
import useCheckoutSessionSuccess from 'lib/useCheckoutSessionSuccess';
import Layout from '../Layout';
import {
  Button,
  Confirmation,
  Container,
  Content,
  Email,
  StoreButton,
} from './styles';

const SCREEN = 'web_checkout_logged_out';

const SubscriptionCheckoutSessionSuccess: FC = () => {
  const { email } = useCheckoutSessionSuccess({ screen: SCREEN });

  return (
    <Layout>
      <Container>
        <Confirmation message="Thank you for subscribing to Waking Up!" />
        <Content id="purchase-subscription-success">
          {email && <Email>Account: {email}</Email>}
          <StoreButton type="ios" />
          <StoreButton type="android" />
          <Button
            to={`${email ? `/?email=${encodeURIComponent(email)}` : '/'}`}
            replace
          >
            Sign In
          </Button>
          <Button onClick={toggleHelpScout}>Contact Support</Button>
        </Content>
      </Container>
    </Layout>
  );
};

export default SubscriptionCheckoutSessionSuccess;
