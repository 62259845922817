import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div).attrs(() => ({
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  transition: { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] },
}))`
  color: ${({ theme }) => theme.colors.coreNeutral700};
  font-size: 0.85rem;
  overflow: hidden;
`;

export const Text = styled.p`
  color: inherit;
  font-size: inherit;
  padding-top: 1rem;
`;

export const Button = styled.button.attrs(() => ({
  type: 'button',
}))`
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline;
  font-size: inherit;
  margin: 0;
  padding: 0;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: underline;
  }
`;
