import { memo, type FC } from 'react';
import { useLocation } from 'react-router-dom';
import { up } from 'lib/paths';
import Icon from 'components/Icon';
import {
  BackLink,
  Container,
  Content,
  Description,
  Title,
  TitleAndDescription,
  TitleIcon,
  TitleRow,
} from './styles';
import type { Props } from './types';

const SectionHeader: FC<Props> = ({
  children,
  className,
  description,
  showBackLink,
  testId,
  title,
  titleIconName,
}) => {
  const { pathname } = useLocation();
  const backUrl = up(pathname);

  return (
    <Container className={className}>
      {showBackLink ? (
        <BackLink
          aria-label="Go back"
          data-cypress-id={`${testId}-back`}
          data-jest-id={`${testId}-back`}
          to={backUrl}
        >
          <Icon name="arrowLeftLight" />
        </BackLink>
      ) : (
        <TitleAndDescription>
          <TitleRow>
            {titleIconName && (
              <TitleIcon aria-hidden="true" name={titleIconName} />
            )}
            <Title
              as="h1"
              data-cypress-id={`${testId}-title`}
              data-jest-id={`${testId}-title`}
            >
              {title}
            </Title>
          </TitleRow>
          {description && <Description>{description}</Description>}
        </TitleAndDescription>
      )}
      <Content>{children}</Content>
    </Container>
  );
};

export default memo(SectionHeader);
