import { gql } from '@apollo/client';
import { IMAGE_DATA_FRAGMENT } from '.';
import { AUDIO_DATA_FRAGMENT } from './audio';
import { PERSON_INFO_DATA_FRAGMENT } from './person';

export const PACK_INFO_DATA_FRAGMENT = gql`
  fragment PackInfoData on PackInfo {
    __typename
    auto_play
    id
    image {
      ...ImageData
    }
    is_intro
    is_primary_pack
    overwrite_course_image
    pack_hash
    pack_id
  }
  ${IMAGE_DATA_FRAGMENT}
`;

export const COURSE_DATA_FRAGMENT = gql`
  fragment CourseData on Course {
    __typename
    audio {
      ...AudioData
    }
    background_audio_available
    bookmarked_at
    course_type
    created_at
    description
    ephemeral
    head_image {
      ...ImageData
    }
    hash
    highlighted
    id
    image {
      ...ImageData
    }
    info
    is_bookmark
    is_free
    packs_info {
      ...PackInfoData
    }
    persons_info {
      ...PersonInfoData
    }
    played_last_time_at
    previews {
      ...AudioData
    }
    shareable
    speed_control_level
    subtitle
    summary
    status
    tags {
      id
      value
    }
    title
  }
  ${AUDIO_DATA_FRAGMENT}
  ${IMAGE_DATA_FRAGMENT}
  ${PACK_INFO_DATA_FRAGMENT}
  ${PERSON_INFO_DATA_FRAGMENT}
`;
