import type { FC } from 'react';
import { BookmarkType } from 'models/Bookmarks';
import CourseList from 'components/CourseList';
import Icon from 'components/Icon';
import NavTab from 'components/NavTab';
import Daily from './Daily';
import NoBookmarks from './NoBookmarks';
import Loader from './Loader';
import { BackLink, DailyContainer, Header, NavTabList, Title } from './styles';
import useConnect from './connect';

const BookmarksPage: FC = () => {
  const {
    bookmarkDailies,
    bookmarkLife,
    bookmarkPractice,
    bookmarkTheory,
    handleDailyTrack,
    handleLifeTrack,
    handleOpenCourseOptionsMenu,
    handleOpenDailyOptionsMenu,
    handlePracticeTrack,
    handleSelectDailyTab,
    handleSelectLifeTab,
    handleSelectPracticeTab,
    handleSelectTheoryTab,
    handleTheoryTrack,
    loading,
    selectedType,
  } = useConnect();

  const isPractice = selectedType === BookmarkType.PRACTICE;
  const isTheory = selectedType === BookmarkType.THEORY;
  const isLife = selectedType === BookmarkType.LIFE;
  const isDaily = selectedType === BookmarkType.DAILY;

  const bookmarksPracticeEmpty = bookmarkPractice.length === 0;
  const bookmarksTheoryEmpty = bookmarkTheory.length === 0;
  const bookmarksLifeEmpty = bookmarkLife.length === 0;
  const bookmarksDailyEmpty = bookmarkDailies.length === 0;

  const showLoading = loading;
  const showPractice = !loading && isPractice && !bookmarksPracticeEmpty;
  const showPracticeEmpty = !loading && isPractice && bookmarksPracticeEmpty;
  const showTheory = !loading && isTheory && !bookmarksTheoryEmpty;
  const showTheoryEmpty = !loading && isTheory && bookmarksTheoryEmpty;
  const showLife = !loading && isLife && !bookmarksLifeEmpty;
  const showLifeEmpty = !loading && isLife && bookmarksLifeEmpty;
  const showDailies = !loading && isDaily && !bookmarksDailyEmpty;
  const showDailiesEmpty = !loading && isDaily && bookmarksDailyEmpty;

  return (
    <>
      <Header>
        <BackLink aria-label="Go back to home" to="/">
          <Icon aria-hidden="true" name="arrowLeftLight" />
        </BackLink>
        <Title as="h1">My Library</Title>
      </Header>
      <NavTabList>
        <NavTab
          aria-controls="practice-pannel"
          id="practice-tab"
          isActive={isPractice}
          onClick={handleSelectPracticeTab}
        >
          Practice
        </NavTab>
        <NavTab
          aria-controls="theory-pannel"
          id="theory-tab"
          isActive={isTheory}
          onClick={handleSelectTheoryTab}
        >
          Theory
        </NavTab>
        <NavTab
          aria-controls="life-pannel"
          id="life-tab"
          isActive={isLife}
          onClick={handleSelectLifeTab}
        >
          Life
        </NavTab>
        <NavTab
          aria-controls="daily-pannel"
          id="daily-tab"
          onClick={handleSelectDailyTab}
          isActive={isDaily}
        >
          Daily
        </NavTab>
      </NavTabList>
      {showLoading && <Loader />}
      {showPractice && (
        <CourseList
          ariaLabelledby="practice-tab"
          courses={bookmarkPractice}
          displayCourseImages
          hideTopBorder
          id="practice-panel"
          isLoading={loading}
          onOpenOptionsMenu={handleOpenCourseOptionsMenu}
          role="tabpanel"
          setTrack={handlePracticeTrack}
          showPretitle
          showProgress
        />
      )}
      {showPracticeEmpty && (
        <NoBookmarks
          ariaLabelledby="practice-tab"
          id="practice-panel"
          role="tabpanel"
          textType="Practice"
        />
      )}
      {showTheory && (
        <CourseList
          ariaLabelledby="theory-tab"
          courses={bookmarkTheory}
          displayCourseImages
          hideTopBorder
          id="theory-panel"
          isLoading={loading}
          onOpenOptionsMenu={handleOpenCourseOptionsMenu}
          role="tabpanel"
          setTrack={handleTheoryTrack}
          showPretitle
          showProgress
        />
      )}
      {showTheoryEmpty && (
        <NoBookmarks
          ariaLabelledby="theory-tab"
          id="theory-panel"
          role="tabpanel"
          textType="Theory"
        />
      )}
      {showLife && (
        <CourseList
          ariaLabelledby="life-tab"
          courses={bookmarkLife}
          displayCourseImages
          hideTopBorder
          id="life-panel"
          isLoading={loading}
          onOpenOptionsMenu={handleOpenCourseOptionsMenu}
          role="tabpanel"
          setTrack={handleLifeTrack}
          showPretitle
          showProgress
        />
      )}
      {showLifeEmpty && (
        <NoBookmarks
          ariaLabelledby="life-tab"
          id="life-panel"
          role="tabpanel"
          textType="Life"
        />
      )}
      {showDailies && (
        <DailyContainer
          aria-labelledby="daily-tab"
          id="daily-panel"
          role="tabpanel"
        >
          {bookmarkDailies.map((daily) => (
            <Daily
              key={daily.id}
              date={daily.bookmarkedAt}
              duration={daily.dailyAudio1Length}
              hash={daily.id}
              onClick={handleDailyTrack}
              onOpenOptionsMenu={handleOpenDailyOptionsMenu}
              permission={daily.permission}
              progress={daily.progress}
            />
          ))}
        </DailyContainer>
      )}
      {showDailiesEmpty && (
        <NoBookmarks
          ariaLabelledby="daily-tab"
          id="daily-panel"
          role="tabpanel"
          textType="Daily Meditation"
        />
      )}
    </>
  );
};

export default BookmarksPage;
