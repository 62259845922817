import { useCallback } from 'react';
import { trackWidgetEvent } from 'services/analytics';
import { media } from 'styles/mixins';
import formatDate from 'lib/formatDate';
import useMediaQuery from 'lib/useMediaQuery';
import { DailyPermission } from 'models/Daily';
import useDaily from 'graphql/hooks/useDaily';
import usePlayerActions from 'graphql/hooks/usePlayerActions';
import useActiveSessions from 'gfirebase/hooks/useActiveSessions';
import type { ConnectProps } from './types';

const useConnect = ({ widgetId }: ConnectProps) => {
  const { daily, loading: loadingDaily } = useDaily();
  const { activeSessions, loading: loadingActiveSessions } =
    useActiveSessions();
  const { openDailyPlayer } = usePlayerActions();
  const isPhoneLandscape = useMediaQuery(media.phoneLandscape);

  const isAvailable = daily?.permission === DailyPermission.UNLOCKED;

  const nowPracticing = activeSessions
    ? `${new Intl.NumberFormat('en-US').format(activeSessions)} NOW PRACTICING`
    : '... NOW PRACTICING';

  const today = formatDate(new Date());
  const hash = daily?.hash || '';
  const title = daily?.title || '';
  const imageUrl = daily?.imageUrl || '';
  const bgImageUrl =
    daily?.imageThumbnailsThumbX1 ||
    daily?.imageThumbnailsThumbX2 ||
    daily?.imageUrl ||
    '';

  const handleOpenDaily = useCallback(async () => {
    if (isAvailable) {
      await openDailyPlayer({});

      if (widgetId) {
        trackWidgetEvent(widgetId, {
          category: 'practice',
          content_type: 'daily',
          hash,
          name: title,
        });
      }
    }
  }, [hash, isAvailable, openDailyPlayer, title, widgetId]);

  return {
    daily,
    handleOpenDaily,
    imageUrl,
    bgImageUrl,
    isAvailable,
    isLoading: loadingDaily || loadingActiveSessions,
    isPhoneLandscape,
    nowPracticing,
    today,
  };
};

export default useConnect;
