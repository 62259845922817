import { memo } from 'react';
import type { FC } from 'react';
import { Props } from './types';

const LogoBlackHorizontal: FC<Props> = ({ size = 64, ...rest }) => (
  <svg
    fill="none"
    height={(size * 93) / 526}
    viewBox="0 0 526 93"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M183.23 20.77l25.47 52.69h-9.17l-5.43-11.6h-21.8l-5.43 11.6h-9.1l25.46-52.69zM176 54.09h14.46l-7.2-15.42L176 54.09zm43.1-32.74h8.51v26.2l22.95-26.2h10.12l-23.24 26.5 25.46 25.61h-10.71l-24.58-24.59v24.59h-8.51V21.35zm55.45 0h8.51v52.11h-8.51V21.35zm160.27 31.82V21.35h8.51v32.07c0 8.08 5.07 12.55 12.48 12.55s12.4-4.47 12.4-12.55V21.35h8.51v31.82c0 12.84-8.66 20.84-20.91 20.84s-20.99-7.97-20.99-20.84zm57.24-31.82h15.56c10.78 0 18.2 6.75 18.2 16.59 0 9.68-7.42 16.58-18.2 16.58h-7.05v18.94h-8.51V21.35zm15.19 25.25c6.24 0 9.91-3.45 9.91-8.66s-3.67-8.66-9.91-8.66h-6.68V46.6h6.68zM88.05 21.22h8.73l13.49 32L124 20.63l13.69 32.35L151 21.22h8.81l-21.7 52.69L124 40.17l-14 33.74-21.95-52.69zM311 40.51v33h-8.52V20.76l33.67 33.84V21.35h8.52v52.69L311 40.51zm68.38 10.66h16.52v12a20.499 20.499 0 01-10.64 2.79c-10.28 0-18-8.15-18-18.57s7.7-18.56 18-18.56a19.23 19.23 0 0112.06 4.21l5.82-5.82a26.998 26.998 0 00-44.52 20.17 26.39 26.39 0 007.731 18.909 26.397 26.397 0 0018.909 7.731 29.6 29.6 0 0019.15-6.67V42.93h-21.64l-3.39 8.24z"
      fill="currentColor"
    />
    <path
      d="M183.23 20.77l25.47 52.69h-9.17l-5.43-11.6h-21.8l-5.43 11.6h-9.1l25.46-52.69zM176 54.09h14.46l-7.2-15.42L176 54.09zm43.1-32.74h8.51v26.2l22.95-26.2h10.12l-23.24 26.5 25.46 25.61h-10.71l-24.58-24.59v24.59h-8.51V21.35zm55.45 0h8.51v52.11h-8.51V21.35zm160.27 31.82V21.35h8.51v32.07c0 8.08 5.07 12.55 12.48 12.55s12.4-4.47 12.4-12.55V21.35h8.51v31.82c0 12.84-8.66 20.84-20.91 20.84s-20.99-7.97-20.99-20.84zm57.24-31.82h15.56c10.78 0 18.2 6.75 18.2 16.59 0 9.68-7.42 16.58-18.2 16.58h-7.05v18.94h-8.51V21.35zm15.19 25.25c6.24 0 9.91-3.45 9.91-8.66s-3.67-8.66-9.91-8.66h-6.68V46.6h6.68zM88.05 21.22h8.73l13.49 32L124 20.63l13.69 32.35L151 21.22h8.81l-21.7 52.69L124 40.17l-14 33.74-21.95-52.69zM311 40.51v33h-8.52V20.76l33.67 33.84V21.35h8.52v52.69L311 40.51zm68.38 10.66h16.52v12a20.499 20.499 0 01-10.64 2.79c-10.28 0-18-8.15-18-18.57s7.7-18.56 18-18.56a19.23 19.23 0 0112.06 4.21l5.82-5.82a26.998 26.998 0 00-44.52 20.17 26.39 26.39 0 007.731 18.909 26.397 26.397 0 0018.909 7.731 29.6 29.6 0 0019.15-6.67V42.93h-21.64l-3.39 8.24zM61.54 43.28h6.22l-7.49-21.11C55.53 8.7 44.71.17 30.77.17a30.76 30.76 0 00-22 52.29l40.48 40.13V61.71h12.29V43.28z"
      fill="currentColor"
    />
  </svg>
);

export default memo(LogoBlackHorizontal);
