import styled from 'styled-components';
import colorWithAlpha from 'lib/color';
import { media } from 'styles/mixins';

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralWhite
      : colorWithAlpha({
          color: theme.colors.coreNeutralBlack,
          alpha: 0.25,
        })};
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) =>
    theme.name !== 'LIGHT' ? 'none' : theme.elevation.web1};
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  display: flex;
  flex-direction: column;
  height: 10.5rem;
  justify-content: flex-start;
  margin: 1rem 0;
  text-align: center;
  padding: 2.6875rem 1.5625rem 0 1.5625rem;

  ${media.phoneLandscape} {
    height: 7.5rem;
  }

  @media (max-height: 450px) {
    height: 7.5rem;
  }

  ${media.phonePortrait} {
    height: 10.4375rem;
  }
`;

export const Title = styled.h2`
  color: inherit;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.01em;
  line-height: 1.25;
  margin-bottom: 1.1875rem;
  max-width: 16.1875rem;
`;

export const Description = styled.p`
  color: inherit;
  font-size: 0.875rem;
  letter-spacing: 0.01em;
  line-height: 1.4286;
  max-width: 16.1875rem;
`;
