import styled from 'styled-components';
import {
  Radio as MUIRadio,
  RadioGroup as MUIRadioGroup,
  FormControlLabel as MUIFormControlLabel,
} from '@mui/material';

export const Container = styled.div``;

export const Group = styled(MUIRadioGroup)``;

export const Radio = styled(MUIRadio)`
  .MuiSvgIcon-root {
    height: 1.4286rem;
    width: 1.4286rem;
  }
`;

export const Option = styled(MUIFormControlLabel)`
  .MuiTypography-root {
    text-align: start;
    font-size: 1.1rem;
    margin: 0.4rem 0;
    line-height: 1.2;
  }
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.coreRed500};
  font-size: 0.9rem;
  line-height: 1;
  margin: 0.5rem 0.4rem 0 0.4rem;
`;
