/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import DefaultModal from 'components/Modal';
import { Icon } from 'components/Modal/styles';
import { media } from 'styles/mixins';

export const Modal = styled(DefaultModal)`
  .MuiPaper-root {
    margin: 0;
    max-width: 40rem;
    padding: 2.4rem 2.4rem 1rem 2.4rem;

    ${Icon} {
      color: #215adb;
    }

    ${media.phoneLandscape} {
      max-width: none;
    }

    ${media.phonePortrait} {
      padding: 2.4rem 1.4rem;
    }
  }
`;
