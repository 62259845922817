import { memo, type FC, useEffect } from 'react';
import { trackAppsflyerEvent } from 'services/analytics/appsflyer';
import StripePlan from './StripePlan';
import { Container, Loader, Plans, Progress } from './styles';
import type { Props } from './types';

const SubscriptionPlans: FC<Props> = ({
  hasMonthlySubscription,
  hasYearlySubscription,
  isEligibleForYearlyUpgradeWithDiscount,
  isLoading,
  onCreate,
  plans,
}) => {
  useEffect(() => {
    trackAppsflyerEvent('pba', 'event', {
      eventType: 'EVENT',
      eventName: 'af_initiated_checkout',
    });
  }, []);

  return (
    <Container>
      <Plans>
        {isLoading ? (
          <Loader>
            <Progress />
          </Loader>
        ) : (
          plans.map((plan) => (
            <StripePlan
              key={plan.id}
              hasMonthlySubscription={hasMonthlySubscription}
              hasYearlySubscription={hasYearlySubscription}
              isEligibleForYearlyUpgradeWithDiscount={
                isEligibleForYearlyUpgradeWithDiscount
              }
              onCreate={onCreate}
              {...plan}
            />
          ))
        )}
      </Plans>
    </Container>
  );
};

export default memo(SubscriptionPlans);
