import type { FC } from 'react';
import { Props } from './types';

const InfoLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M12.001 21a9 9 0 100-18 9 9 0 000 18z"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.25 11.25H12v5.25h.75"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 8.813a.937.937 0 100-1.875.937.937 0 000 1.875z"
      fill="currentColor"
    />
  </svg>
);

export default InfoLight;
