import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  backgroundSoundsVar,
  updateBackgroundSoundsVar,
} from 'graphql/reactive';

const useConnect = () => {
  const { backgroundAudioEnabled, backgroundAudioVolume } =
    useReactiveVar(backgroundSoundsVar);

  const handleChangeVolume = useCallback((e: any, nextValue: any) => {
    updateBackgroundSoundsVar({ backgroundAudioVolume: (2 * nextValue) / 100 });
  }, []);

  const handleEnableAudio = useCallback((value: boolean) => {
    updateBackgroundSoundsVar({ backgroundAudioEnabled: value });
  }, []);

  return {
    handleChangeVolume,
    handleEnableAudio,
    isAudioEnable: backgroundAudioEnabled,
    volume: Math.round((backgroundAudioVolume / 2) * 100),
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
