import type { GetRemoteConfigQuery } from 'graphql/generated';

export const normalizeRemoteConfig = (
  remoteConfig: NonNullable<GetRemoteConfigQuery['remoteConfig']>,
) => ({
  androidCurrentVersion: remoteConfig.android_current_version || '',
  androidMinVersion: remoteConfig.android_min_version || '',
  backgroundAudiosChunks: Number(remoteConfig.background_audios_chunks),
  backgroundAudiosFilepath: remoteConfig.background_audios_filepath || '',
  freeMonthCtaImageDark: remoteConfig.free_month_cta_image_dark || '',
  freeMonthCtaImageLight: remoteConfig.free_month_cta_image_light || '',
  freeMonthCtaImageWeb: remoteConfig.free_month_cta_image_web || '',
  iosCurrentVersion: remoteConfig.ios_current_version || '',
  iosMinVersion: remoteConfig.ios_min_version || '',
  sectionLifeDescription: remoteConfig.section_life_description || '',
  sectionPracticeDescription: remoteConfig.section_practice_description || '',
  sectionTheoryDescription: remoteConfig.section_theory_description || '',
});

export type RemoteConfig = ReturnType<typeof normalizeRemoteConfig>;
