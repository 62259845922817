import { FunctionComponent } from 'react';

import ChevronLeft from 'components/Icons/ChevronLeft';
import { LinkProps } from '..';

// UI
import { BackLinkBase } from './styles';

const BackLink: FunctionComponent<LinkProps> = ({
  children,
  title,
  ...props
}) => (
  <BackLinkBase aria-label={`Back to ${title}`} {...props}>
    <ChevronLeft aria-hidden="true" />
    {title || children}
  </BackLinkBase>
);

export default BackLink;
