import { useMemo } from 'react';
import { useQuery, type QueryHookOptions } from '@apollo/client';
import { getDailyPermission, normalizeDaily } from 'models/Daily';
import { GetBookmarksDocument, type GetBookmarksQuery } from '../generated';
import useMe from './useMe';
import usePackCourses from './usePackCourses';

const useBookmarks = (options: QueryHookOptions<GetBookmarksQuery> = {}) => {
  const { isDailyUnlocked, isSubscribed } = useMe();
  const { packCourses, loading: isLoadingPacks } = usePackCourses();

  const { data, loading: isLoadingBookmarks } = useQuery(GetBookmarksDocument, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  const bookmarks = useMemo(() => {
    const cacheBookmarkIds: Record<string, boolean> = {};
    const courses = packCourses.filter(
      ({ ephemeral, id, isBookmark, packIsPrimaryPack }) => {
        if (
          ephemeral ||
          !isBookmark ||
          !packIsPrimaryPack ||
          cacheBookmarkIds[id]
        ) {
          return false;
        }

        cacheBookmarkIds[id] = true;
        return true;
      },
    );

    const dailies =
      data?.bookmarks?.dailies
        ?.filter((daily) => !daily.is_special)
        .map((daily) => ({
          ...normalizeDaily(daily),
          permission: getDailyPermission(isSubscribed, isDailyUnlocked),
        }))
        .sort((a, b) => b.bookmarkedAt.localeCompare(a.bookmarkedAt)) || [];

    return { courses, dailies };
  }, [data, isDailyUnlocked, isSubscribed, packCourses]);

  return {
    bookmarks,
    loading: isLoadingBookmarks || isLoadingPacks,
  };
};

export default useBookmarks;
export type UseBookmarks = ReturnType<typeof useBookmarks>;
