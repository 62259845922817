import { memo } from 'react';
import type { FC } from 'react';
import {
  BackButton,
  BigText,
  Button,
  ChevronLeft,
  Content,
  Footer,
  Loader,
  Modal,
  Result,
  Text,
  Title,
} from './styles';
import useConnect from './connect';

const UnlockFreeMonthModal: FC = () => {
  const {
    alreadyRedeemedTrial,
    closeModal,
    errorMessage,
    handlePlayFullCourse,
    handleUnlockFreeMonth,
    isOpen,
    isRedeeming,
  } = useConnect();

  const showError = !!errorMessage;
  const showStart = !errorMessage && !alreadyRedeemedTrial && !isRedeeming;
  const showLoader = !errorMessage && !alreadyRedeemedTrial && isRedeeming;
  const showEnd = !errorMessage && alreadyRedeemedTrial && !isRedeeming;

  return (
    <Modal
      disableRestoreFocus
      fullscreenOnMobile
      hideClose
      onClose={closeModal}
      open={isOpen}
      titleId="unlock-free-month-title"
    >
      <BackButton onClick={closeModal}>
        <ChevronLeft />
      </BackButton>
      <Title id="unlock-free-month-title">WEEK TRIAL</Title>
      <Content>
        {showStart && (
          <>
            <BigText id="unlock-free-month-description">
              Unlock a free week of Waking Up and listen to the full content.
            </BigText>
            <Button onClick={handleUnlockFreeMonth}>Unlock a free week</Button>
          </>
        )}
        {showLoader && <Loader />}
        {showEnd && (
          <>
            <Result>Success!</Result>
            <Text>
              You now have a week to explore Waking Up. We think you'll find
              it's worth it.
            </Text>
            <Button onClick={handlePlayFullCourse}>Listen now</Button>
          </>
        )}
        {showError && (
          <>
            <Result>Error</Result>
            <Text>{errorMessage}</Text>
          </>
        )}
        <Footer>No credit card required. Cancel anytime.</Footer>
      </Content>
    </Modal>
  );
};

export default memo(UnlockFreeMonthModal);
