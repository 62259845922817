import type { FC } from 'react';
import { Props } from './types';

const InfinityLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M9.99632 14.2625L9.18198 15.182C8.55264 15.8113 7.75082 16.2399 6.87791 16.4135C6.00499 16.5872 5.10019 16.498 4.27792 16.1575C3.45566 15.8169 2.75285 15.2401 2.25839 14.5001C1.76392 13.76 1.5 12.89 1.5 12C1.5 11.11 1.76392 10.24 2.25839 9.49993C2.75285 8.75991 3.45566 8.18314 4.27792 7.84254C5.10019 7.50195 6.00499 7.41283 6.87791 7.58647C7.75082 7.7601 8.55264 8.18868 9.18198 8.81802L14.818 15.182C15.4474 15.8113 16.2492 16.2399 17.1221 16.4135C17.995 16.5872 18.8998 16.498 19.7221 16.1575C20.5443 15.8169 21.2471 15.2401 21.7416 14.5001C22.2361 13.76 22.5 12.89 22.5 12C22.5 11.11 22.2361 10.24 21.7416 9.49993C21.2471 8.75991 20.5443 8.18314 19.7221 7.84254C18.8998 7.50195 17.995 7.41283 17.1221 7.58647C16.2492 7.7601 15.4474 8.18868 14.818 8.81802L14.0037 9.73754"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default InfinityLight;
