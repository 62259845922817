export type Props = {
  id: string;
};

export type ConnectProps = {
  id: Props['id'];
};

export enum TabType {
  SERIES = 'Series',
  CONVERSATIONS = 'Conversations',
  SESSIONS = 'Sessions',
}
