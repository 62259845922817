import type { FC } from 'react';
import { Props } from './types';

const Search: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      clipRule="evenodd"
      d="M3.623 10.875a7.25 7.25 0 1 1 14.5 0 7.25 7.25 0 0 1-14.5 0Zm7.25-8.5a8.5 8.5 0 1 0 5.554 14.935l4.131 4.132a.625.625 0 1 0 .884-.884l-4.132-4.132a8.5 8.5 0 0 0-6.437-14.051Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default Search;
