import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultIsotype from 'components/Isotype';
import DefaultPublicPageFooter from 'components/PublicPageFooter';
import DefaultLink from 'components/Link';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;
  max-width: 1184px;
  min-height: 100vh;
  padding: 0 2rem 0 2rem;

  ${media.phoneLandscape} {
    padding: 0 1.25rem 0 1.25rem;
  }
`;

export const Main = styled.main`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding-top: 7rem;

  ${media.phoneLandscape} {
    padding-bottom: 4rem;
    padding-top: 3rem;
  }
`;

export const Isotype = styled(DefaultIsotype)`
  margin-bottom: 3.35rem;
  width: 3.45rem;

  ${media.phoneLandscape} {
    margin-bottom: 1.75rem;
    width: 2.4rem;
  }
`;

export const Title = styled.h1`
  font-size: 1.87rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.05em;
  line-height: 1.2;

  ${media.phoneLandscape} {
    font-size: 1.6rem;
  }
`;

export const Methods = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: 100%;
  width: 44rem;

  ${media.tabletPortrait} {
    width: 32rem;
  }
`;

export const Or = styled.p`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 1;
  padding: 1.2rem 0;
  white-space: pre-wrap;
`;

export const Link = styled(DefaultLink)`
  color: ${({ theme }) => theme.colors.coreBlue500};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.22;
  margin-top: 4.5rem;
  text-decoration: underline;
`;

export const Footer = styled(DefaultPublicPageFooter)``;
