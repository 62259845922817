import { memo } from 'react';
import type { FC } from 'react';
import { AnimatePresence } from 'framer-motion';
import { durationOptions } from 'models/DailyMeditationDuration';
import MenuItem from './MenuItem';
import { ButtonCTA, Container, Menu } from './styles';
import { Props } from './types';
import useLogic from './logic';

const DailyDurationSelect: FC<Props> = ({
  className,
  duration,
  onChangeDuration = () => null,
}) => {
  const { currentDurationText, handleToggleOpen, open, ref } = useLogic({
    duration,
  });

  if (!duration) {
    return null;
  }

  return (
    <Container className={className} onClick={handleToggleOpen} ref={ref}>
      <ButtonCTA active={open} size="small">
        {currentDurationText} session
      </ButtonCTA>
      <AnimatePresence initial={false}>
        {open && (
          <Menu>
            {durationOptions.map(({ id, text }) => (
              <MenuItem
                key={id}
                active={id === duration}
                id={id}
                onClick={onChangeDuration}
                text={`${text} session`}
              />
            ))}
          </Menu>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default memo(DailyDurationSelect);
