import useMediaQuery from 'lib/useMediaQuery';
import { media } from 'styles/mixins';
import useHome from 'graphql/hooks/useHome';
import {
  WidgetCarousel,
  WidgetContinueListening,
  WidgetCTA,
  WidgetImage,
  WidgetSpotlight,
  WidgetUpNext,
} from 'models/Widget';

const useConnect = () => {
  const { home, loading } = useHome();
  const isPhoneLandscape = useMediaQuery(media.phoneLandscape);

  let upNextWidget: WidgetUpNext | undefined;
  let ctaWidget: WidgetCTA | undefined;
  let spotlightWidget: WidgetSpotlight | undefined;
  const customWidgets: (
    | WidgetContinueListening
    | WidgetCarousel
    | WidgetImage
  )[] = [];
  home.forEach((widget) => {
    if (widget.typename === 'WidgetUpNext') {
      upNextWidget = widget;
    } else if (widget.typename === 'WidgetCTA') {
      ctaWidget = widget;
    } else if (widget.typename === 'WidgetSpotlight') {
      spotlightWidget = widget;
    } else {
      customWidgets.push(widget);
    }
  });

  return {
    ctaWidget,
    customWidgets,
    isPhoneLandscape,
    loading,
    spotlightWidget,
    upNextWidget,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
