import { useCallback } from 'react';
import { ModalType } from 'graphql/reactive';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import usePlayerActions from 'graphql/hooks/usePlayerActions';

const useConnect = () => {
  const { isOpen, payload } = useModal(ModalType.UNLOCK_FREE_MONTH_SUCCESS);
  const { closeModal } = useModalActions();
  const { openCoursePlayer } = usePlayerActions();

  const courseHash = payload?.courseHash;

  const handlePlayFullCourse = useCallback(() => {
    if (courseHash) {
      closeModal();
      setTimeout(() => openCoursePlayer({ hash: courseHash }), 500);
    }
  }, [closeModal, courseHash, openCoursePlayer]);

  return {
    closeModal,
    courseHash,
    handlePlayFullCourse,
    isOpen,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
