import { useCallback } from 'react';
import {
  coursePlayerVar,
  dailyPlayerVar,
  PlayerType,
  playerTypeVar,
  resetAudio,
  resetCoursePlayerVar,
  resetDailyPlayerVar,
  resetPlayerTypeVar,
  resetPreviewPlayerVar,
  resetRetreatPlayerVar,
  resetSelfTimerPlayerVar,
  resetTimer,
  setCoursePlayerVar,
  setDailyPlayerVar,
  setPlayerTypeVar,
  setPreviewPlayerVar,
  setRetreatPlayerVar,
  setSelfTimerPlayerVar,
  updateCoursePlayerVar,
  updateDailyPlayerVar,
  updateRetreatPlayerVar,
  type CoursePlayer,
  type DailyPlayer,
  type PreviewPlayer,
  type RetreatPlayer,
  type SelfTimerPlayer,
} from '../reactive';
import { getProfile } from '../requests';

const usePlayerActions = () => {
  const openCoursePlayer = useCallback((data: Partial<CoursePlayer>) => {
    const playerType = playerTypeVar();
    const { hash } = coursePlayerVar();

    if (playerType === PlayerType.COURSE && hash && hash === data.hash) {
      updateCoursePlayerVar({ isMinimized: false });
      return;
    }

    resetAudio();
    resetTimer();
    resetPreviewPlayerVar();
    resetDailyPlayerVar();
    resetRetreatPlayerVar();
    resetSelfTimerPlayerVar();

    setCoursePlayerVar(data);
    setPlayerTypeVar(PlayerType.COURSE);
  }, []);

  const openPreviewPlayer = useCallback((data: Partial<PreviewPlayer>) => {
    resetAudio();
    resetTimer();
    resetCoursePlayerVar();
    resetDailyPlayerVar();
    resetRetreatPlayerVar();
    resetSelfTimerPlayerVar();

    setPreviewPlayerVar(data);
    setPlayerTypeVar(PlayerType.PREVIEW);
  }, []);

  const openDailyPlayer = useCallback(async (data: Partial<DailyPlayer>) => {
    const playerType = playerTypeVar();
    const { hash, sharedHash } = dailyPlayerVar();

    if (
      playerType === PlayerType.DAILY &&
      ((!hash && !data.hash) ||
        (hash && hash === data.hash) ||
        (sharedHash && sharedHash === data.sharedHash))
    ) {
      updateDailyPlayerVar({ isMinimized: false });
      return;
    }

    resetAudio();
    resetTimer();
    resetCoursePlayerVar();
    resetPreviewPlayerVar();
    resetRetreatPlayerVar();
    resetSelfTimerPlayerVar();

    const { dailyDurationType } = await getProfile();
    data.duration = dailyDurationType;

    setDailyPlayerVar(data);
    setPlayerTypeVar(PlayerType.DAILY);
  }, []);

  const openSelfTimerPlayer = useCallback(
    ({
      duration,
      interval,
    }: Pick<SelfTimerPlayer, 'duration' | 'interval'>) => {
      resetAudio();
      resetTimer();
      resetCoursePlayerVar();
      resetPreviewPlayerVar();
      resetRetreatPlayerVar();
      resetDailyPlayerVar();

      const startTime = new Date().getTime();
      setSelfTimerPlayerVar({ duration, interval, startTime });
      setPlayerTypeVar(PlayerType.SELF_TIMER);
    },
    [],
  );

  const openRetreatPlayer = useCallback((data: Partial<RetreatPlayer>) => {
    const playerType = playerTypeVar();

    if (playerType === PlayerType.RETREAT) {
      updateRetreatPlayerVar({ isMinimized: false });
      return;
    }

    resetAudio();
    resetTimer();
    resetCoursePlayerVar();
    resetPreviewPlayerVar();
    resetDailyPlayerVar();
    resetSelfTimerPlayerVar();

    setRetreatPlayerVar(data);
    setPlayerTypeVar(PlayerType.RETREAT);
  }, []);

  const closePlayer = useCallback(() => {
    resetPlayerTypeVar();
    resetAudio();
    resetTimer();
  }, []);

  return {
    closePlayer,
    openCoursePlayer,
    openDailyPlayer,
    openPreviewPlayer,
    openRetreatPlayer,
    openSelfTimerPlayer,
  };
};

export default usePlayerActions;
export type UsePlayerActions = ReturnType<typeof usePlayerActions>;
