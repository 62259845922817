import { useEffect } from 'react';
import { apolloClient } from 'services/api';
import { GetSettingsDocument } from '../generated';

const useRefreshSettingsEffect = () => {
  useEffect(() => {
    apolloClient
      .query({
        query: GetSettingsDocument,
        fetchPolicy: 'network-only',
      })
      .catch(() => true);
  }, []);
};

export default useRefreshSettingsEffect;
