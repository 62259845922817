import type { FC } from 'react';
import { useLogic } from './logic';
import { DivRoot, LinkRoot } from './styles';
import type { IconButtonProps, LinkIconButtonProps } from './types';

const IconButton: FC<IconButtonProps> = ({ children, ...rest }) => {
  const { pressed, onMouseDown, onMouseUp } = useLogic();

  return (
    <DivRoot
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      pressed={pressed}
      {...rest}
    >
      {children}
    </DivRoot>
  );
};

export const LinkIconButton: FC<LinkIconButtonProps> = ({
  children,
  ...rest
}) => {
  const { pressed, onMouseDown, onMouseUp } = useLogic();

  return (
    <LinkRoot
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      pressed={pressed}
      {...rest}
    >
      {children}
    </LinkRoot>
  );
};

export default IconButton;
