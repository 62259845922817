import { apolloClient } from 'services/api';
import { MeDocument, type UserDataFragment } from '../generated';

function cacheSetMe(userData: UserDataFragment) {
  apolloClient.writeQuery({
    query: MeDocument,
    data: { __typename: 'Query', me: userData },
  });
}

export default cacheSetMe;
