import { object as yupObject, string as yupString } from 'yup';

export const initialValues = {
  code: '',
  email: '',
  submitError: '',
};

export const validationSchema = yupObject({
  code: yupString(),
  email: yupString().email('The email is not valid.'),
});
