import type { FormikHelpers } from 'formik';

export enum Step {
  message = 'message',
  thanks = 'thanks',
}

export type FormFields = {
  message: string;
  subject: string;
  step: Step;
  submitError?: string;
};

export type Props = {
  descriptionId: string;
  initialValues: FormFields;
  onClose: () => void;
  onSubmit: (
    values: FormFields,
    helpers: FormikHelpers<FormFields>,
  ) => Promise<void>;
  titleId: string;
};
