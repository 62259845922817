import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { trackEvent } from 'services/analytics';
import useModalActions from 'graphql/hooks/useModalActions';

const useConnect = () => {
  const { openScholarshipModal } = useModalActions();
  const { search } = useLocation();

  const { options } = parse(search);
  const showOptions = options === '1';

  const [isVideoPlayed, setIsVideoPlayed] = useState<boolean>(showOptions);

  useEffect(() => {
    trackEvent('Scholarship - Pageview');
  }, []);

  const handleVideoEnd = useCallback(() => {
    setIsVideoPlayed(true);
  }, []);

  const handleRequestFreeAccount = useCallback(() => {
    trackEvent('Scholarship Display Options');
    openScholarshipModal();
  }, [openScholarshipModal]);

  useEffect(() => {
    if (showOptions) {
      setTimeout(handleRequestFreeAccount, 500);
    }
  }, [handleRequestFreeAccount, showOptions]);

  return {
    handleRequestFreeAccount,
    handleVideoEnd,
    isVideoPlayed,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
