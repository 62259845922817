import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { useMediaQuery } from '@mui/material';
import { trackEvent } from 'services/analytics';
import { createAppsFlyerLink } from 'services/analytics/appsflyer';
import { media } from 'styles/mixins';
import type { ConnectProps } from './types';

const useConnect = ({ createAppsFlyerLinkOptions }: ConnectProps) => {
  const isTabletPortrait = useMediaQuery(media.tabletPortrait);
  const { search } = useLocation();

  const { email, af_adset, af_c_id, c, pid } = parse(search);
  const userEmail = email && typeof email === 'string' ? email : undefined;

  const { all, android, ios } = createAppsFlyerLink({
    ...createAppsFlyerLinkOptions,
    adSet: af_adset as string,
    cId: af_c_id as string,
    campaign: c as string,
    mediaSource: pid as string,
  });

  const sendEvent = useCallback(
    (store: 'apple' | 'google') => {
      trackEvent('Login Magic Link Download Click', {
        has_ad_blocker: !window?.AF_SDK,
        optionKey: `landing_page_${isTabletPortrait ? 'mobile' : 'desktop'}`,
        source: 'Welcome',
        store,
      });
    },
    [isTabletPortrait],
  );

  const handleAppleStoreClick = useCallback(() => {
    sendEvent('apple');
  }, [sendEvent]);

  const handleGooglePlayClick = useCallback(() => {
    sendEvent('google');
  }, [sendEvent]);

  return {
    all,
    android,
    handleAppleStoreClick,
    handleGooglePlayClick,
    ios,
    isTabletPortrait,
    userEmail,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
