import { memo, type FC } from 'react';
import { capitalize } from 'lodash';
import { PACK_ICONS } from 'models/Pack';
import {
  Container,
  Content,
  Icon,
  Image,
  Overline,
  Subtitle,
  Title,
  Type,
} from './styles';
import type { Props } from './types';

const Card: FC<Props> = ({ imageUrl, subtitle, title, packType }) => (
  <Container>
    {imageUrl && <Image alt="" src={imageUrl} />}
    <Content>
      <Overline>
        <Icon name={PACK_ICONS[packType]} />
        <Type>{capitalize(packType)}</Type>
      </Overline>
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Content>
  </Container>
);

export default memo(Card);
