export enum ZIndex {
  MAIN = 0,
  OVERLAY = 1,
  HEADER = 2,
  BACKGROUND = 3,
  SIDEBAR = 4,
  FREE_TRIAL_NOTIFICATION = 5,
  ONBOARDING = 6,
  PLAYER_OVERLAY = 6,
  PLAYER = 7,
  PLAYER_FULL_SCREEN_OVERLAY = 6,
  PLAYER_DEFAULT = 7,
  PLAYER_FULL_SCREEN = 7,
  PLAYER_CONTROLS = 8,
  PLAYER_FINAL_SCREEN = 10,
  PLAYER_CLOSE_BUTTON = 11,
  PLAYER_OPTIONS_MENU_OVERLAY = 12,
  PLAYER_DETAILS = 13,
  PLAYER_OPTIONS_MENU = 14,
  COURSE_ACTIONS_OVERLAY = 20,
  COURSE_ACTIONS = 21,
  COURSE_NOTES_OVERLAY = 22,
  COURSE_NOTES = 23,
  FULLSCREEN_LOADER = 30,
  COOKIES_CONSENT = 32,
}

// DESKTOP CONTENT WIDTH
export const MAX_CONTENT_WIDTH = '1160px';
export const MIN_CONTENT_WIDTH = '970px';

// SIDEBAR
export const SIDEBAR_WIDTH = '19.25rem';
export const SIDEBAR_TABLET_LANDSCAPE_WIDTH = '16rem';

// HEADER
export const HEADER_HEIGHT = '4rem';

// Screen sizes
export const screenSizes = {
  tabletLandscape: 1279.98,
  tabletPortrait: 991.98,
  phoneLandscape: 767.98,
  phonePortrait: 575.98,
};
