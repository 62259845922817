import styled from 'styled-components';
import { Display as DefaultDisplay } from 'components/Typography';
import DefaultLink from 'components/Links/HomeSectionLink';

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.measures.l};
`;

export const Title = styled(DefaultDisplay).attrs(() => ({
  $size: 'small',
}))``;

export const Subtitle = styled.p`
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: 0.01em;
  line-height: 1.25;
`;

export const Link = styled(DefaultLink)`
  display: inline-block;
`;

export const LeftSection = styled.div`
  margin-right: ${({ theme }) => theme.measures.m};
`;

export const RightSection = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.fontEmphasized};
  display: flex;
  margin-left: auto;
`;

export const Controls = styled.div`
  align-items: center;
  display: flex;
  margin-left: ${({ theme }) => theme.measures.l};
  margin-top: 0.15rem;
`;
