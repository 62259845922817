import type { FC } from 'react';
import OptionsMenuItem from '../OptionsMenuItem';
import { Title } from '../styles';
import useConnect from './connect';
import type { Props } from './types';

const DailyOptionsMenu: FC<Props> = ({ dailyId, sharedHash, type }) => {
  const { handleShare, handleToggleBookmark, isBookmark, isSpecial } =
    useConnect({ dailyId, sharedHash });

  const showMyLibrary = !isSpecial;
  const showShare = !isSpecial && type === 'daily';

  return (
    <>
      {type === 'dailyPlayer' && <Title as="h2">options</Title>}
      {showMyLibrary && (
        <OptionsMenuItem
          active={isBookmark}
          iconName={
            isBookmark ? 'bookmarkSingleActive' : 'bookmarkSingleInactive'
          }
          onClick={handleToggleBookmark}
          text={isBookmark ? 'Remove from My Library' : 'Add to My Library'}
        />
      )}
      {showShare && (
        <OptionsMenuItem
          iconName="shareLight"
          onClick={handleShare}
          text="Share"
        />
      )}
    </>
  );
};

export default DailyOptionsMenu;
