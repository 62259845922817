import { memo } from 'react';
import type { FC } from 'react';
import { BackButton, Icon, Menu, MenuItem, Modal, Text, Title } from './styles';
import useConnect from './connect';

const ResetContentMenuModal: FC = () => {
  const { closeModal, handleResetAllContent, handleResetIntroCourse, isOpen } =
    useConnect();

  return (
    <Modal
      disableRestoreFocus
      fullscreenOnMobile
      hideClose
      onClose={closeModal}
      open={isOpen}
    >
      <BackButton onClick={closeModal}>
        <Icon name="arrowLeftLight" />
      </BackButton>
      <Title>Reset Content</Title>
      <Menu>
        <MenuItem onClick={handleResetIntroCourse}>
          <Text>Reset Introductory Course</Text>
        </MenuItem>
        <MenuItem onClick={handleResetAllContent}>
          <Text>Reset All Content as unplayed</Text>
        </MenuItem>
      </Menu>
    </Modal>
  );
};

export default memo(ResetContentMenuModal);
