import {
  useCallback,
  useMemo,
  useState,
  type KeyboardEvent,
  type MouseEvent,
} from 'react';
import { useNavigate } from 'react-router-dom';
import getCourseProgressPercent from 'lib/getCourseProgressPercent';
import {
  resetDetails,
  resetPersonDetails,
  setOptionsMenuVar,
} from 'graphql/reactive';
import useCourseActions from 'graphql/hooks/useCourseActions';
import usePackCourses from 'graphql/hooks/usePackCourses';
import usePacks from 'graphql/hooks/usePacks';
import usePerson from 'graphql/hooks/usePerson';
import useSettings from 'graphql/hooks/useSettings';
import { TabType, type ConnectProps } from './types';

const useConnect = ({ id }: ConnectProps) => {
  const {
    settings: { recentlyPlayed },
  } = useSettings();
  const { person, loading: isLoadingPerson } = usePerson(id);
  const { packs, loading: isLoadingPacks } = usePacks();
  const { packCourses, loading: isLoadingPackCourses } = usePackCourses();
  const { play } = useCourseActions();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState<string>();

  const {
    bio = '',
    imageUrl: imgUrl,
    imageThumbnailsThumbX2,
    name,
  } = person || {};
  const imageUrl = imageThumbnailsThumbX2 || imgUrl || '';

  const { filteredConversations, filteredCourses, filteredPacks, types } =
    useMemo(() => {
      const coursesWithProgress = packCourses
        .filter(
          ({ packIsPrimaryPack, persons }) =>
            packIsPrimaryPack && persons.find((p) => p.id === id),
        )
        .map((c) => ({
          ...c,
          progress: getCourseProgressPercent({
            hash: c.hash,
            audioLength: c.audioLength,
            recentlyPlayed,
            status: c.status,
          }),
        }));

      const fPacks = packs.filter(({ persons }) =>
        persons.find((p) => p.id === id),
      );

      const fConversations = coursesWithProgress.filter(
        ({ isConversation }) => isConversation,
      );

      const fCourses = coursesWithProgress.filter(
        ({ isConversation }) => !isConversation,
      );

      const initialTypes = {
        [TabType.SERIES]: fPacks.length,
        [TabType.CONVERSATIONS]: fConversations.length,
        [TabType.SESSIONS]: fCourses.length,
      };

      const currentTypes = Object.fromEntries(
        Object.entries(initialTypes).filter(([, v]) => v !== 0),
      );

      if (!selectedTab || !currentTypes[selectedTab as TabType]) {
        setSelectedTab(Object.keys(currentTypes)[0]);
      }

      return {
        filteredConversations: fConversations,
        filteredCourses: fCourses,
        filteredPacks: fPacks,
        types: currentTypes,
      };
    }, [id, packCourses, packs, recentlyPlayed, selectedTab]);

  const handlePlayCourse = useCallback(
    (courseId: string, packId: string) => {
      resetDetails();
      resetPersonDetails();
      setTimeout(async () => {
        await play({ courseId, packId });
      }, 300);
    },
    [play],
  );

  const handleNavigateToPack = useCallback(
    (packHash: string) => {
      resetDetails();
      resetPersonDetails();
      setTimeout(() => {
        navigate(`/pack/${packHash}`);
      }, 300);
    },
    [navigate],
  );

  const handleClick = useCallback((tab: string) => {
    setSelectedTab(tab);
  }, []);

  const handleOpenOptionsMenu = useCallback(
    (
      event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLButtonElement>,
      courseId: string,
      packId: string,
    ) => {
      event.stopPropagation();
      setOptionsMenuVar({
        anchorEl: event.currentTarget,
        id: courseId,
        packId,
        type: 'course',
      });
    },
    [],
  );

  return {
    bio,
    filteredConversations,
    filteredCourses,
    filteredPacks,
    handleClick,
    handleNavigateToPack,
    handleOpenOptionsMenu,
    handlePlayCourse,
    imageUrl,
    isLoading: isLoadingPacks || isLoadingPerson || isLoadingPackCourses,
    name,
    selectedTab,
    types: Object.entries(types),
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
