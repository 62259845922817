import { memo } from 'react';
import type { FC } from 'react';
import { Container, BlurImage, GradientTop, GradientBottom } from './styles';
import type { Props } from './types';

const PracticeBannerBlur: FC<Props> = ({ className, imageUrl }) => (
  <Container className={className}>
    <BlurImage $bg={imageUrl} />
    <GradientTop />
    <GradientBottom />
  </Container>
);

export default memo(PracticeBannerBlur);
