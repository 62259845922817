import { captureMessage } from '@sentry/react';
import { DevConfig, isProduction } from 'config';
import SmartLink from './smartLink';
import { CreateAppsFlyerLink } from './types';

export const registerAppsflyerUser = (id: string) => {
  if (isProduction) {
    try {
      window.AF('pba', 'setCustomerUserId', id);
    } catch (error) {
      captureMessage(
        `Unable to set AppsFlyer CUID: ${id} (ERROR: ${
          error?.message || 'unknown'
        })`,
      );
    }
  }
};

export const trackAppsflyerEvent = (
  type: string = 'pba',
  method: string = 'event',
  value: Record<string, any>,
) => {
  if (isProduction) {
    try {
      window.AF(type, method, value);

      if (DevConfig.appsflyerLogging) {
        // eslint-disable-next-line no-console
        console.log('Appsflyer', type, method, value);
      }
    } catch (error) {
      captureMessage(
        `Unable to track AppsFlyer event (ERROR: ${
          error?.message || 'unknown'
        })`,
      );
    }
  }
};

export const createAppsFlyerLink = ({
  ad,
  adSet,
  campaign,
  cId,
  deeplink,
  mediaSource = 'organic',
}: CreateAppsFlyerLink) => {
  const oneLinkAll = 'https://wakingup.onelink.me/eerd';
  const oneLinkAndroid = 'https://wakingup.onelink.me/CA3I';
  const oneLinkIOS = 'https://wakingup.onelink.me/8fmX';
  const smartLink = new SmartLink({
    campaignKeysList: ['c', 'af_campaign', 'utm_campaign'],
    campaignStaticValue: campaign,
    oneLinkURL: oneLinkAll,
    pidKeysList: ['pid', 'af_pid', 'utm_source'],
    pidStaticValue: mediaSource,
  });

  if (ad) {
    smartLink.setAd('af_ad', ad);
  }
  if (adSet) {
    smartLink.setAdset('af_adset', adSet);
  }
  if (cId) {
    smartLink.setCId('af_c_id', cId);
  }
  if (deeplink) {
    smartLink.setDeepLinkValue(
      '',
      `wakingup://dynamic.wakingup.com/${deeplink}`,
    );
  }

  const ios = smartLink.generateUrl()?.replace(oneLinkAll, oneLinkIOS) || '';
  const android =
    smartLink.generateUrl()?.replace(oneLinkAll, oneLinkAndroid) || '';
  const all = smartLink.generateUrl() || '';

  return {
    ios,
    android,
    all,
  };
};
