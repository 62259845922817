import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Plans = styled.div`
  width: 100%;
`;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
  padding: 0.52rem 0;
`;

export const Progress = styled(CircularProgress).attrs(() => ({
  size: 30,
}))``;
