import { FC } from 'react';
import { durationOptions } from 'models/DailyMeditationDuration';
import Option from './Option';
import { Content, Modal, Title } from './styles';
import { Props } from './types';
import useConnect from './connect';

const DailyDurationModal: FC<Props> = ({
  dailyDurationType,
  updateProfile,
}) => {
  const { isOpen, closeModal } = useConnect();
  const title = 'Daily Meditation Duration';

  return (
    <Modal onClose={closeModal} open={isOpen} titleId="daily-meditation-title">
      <Title id="daily-meditation-title">{title}</Title>
      <Content>
        {durationOptions.map(({ id, text }) => (
          <Option
            id={id}
            key={id}
            option={text}
            selected={dailyDurationType === id}
            onProfileUpdate={updateProfile}
            onClose={closeModal}
          />
        ))}
      </Content>
    </Modal>
  );
};

export default DailyDurationModal;
