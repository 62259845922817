import styled from 'styled-components';
import { media } from 'styles/mixins';
import {
  SIDEBAR_WIDTH,
  SIDEBAR_TABLET_LANDSCAPE_WIDTH,
  ZIndex,
} from 'styles/metrics';
import Wordmark from 'components/Wordmark';
import DefaultLink from 'components/Link';
import Icon from 'components/Icon';

export const Container = styled.nav`
  align-items: stretch;
  background-color: ${({ theme }) => theme.colors.backgroundCard};
  border-radius: ${({ theme }) => theme.radius.md};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 90vh;
  overflow-y: auto;
  padding: 3rem 2.5rem 3rem 2.5rem;
  position: fixed;
  scrollbar-width: none;
  top: 4rem;
  width: ${SIDEBAR_WIDTH};
  z-index: ${ZIndex.SIDEBAR};

  &::-webkit-scrollbar {
    display: none;
  }

  ${media.tabletLandscape} {
    padding: 2.5rem 2rem;
    top: 2rem;
    width: ${SIDEBAR_TABLET_LANDSCAPE_WIDTH};
  }
`;

export const Logo = styled(Wordmark)`
  display: block;
  flex: 0 0 auto;
  margin-bottom: 2.25rem;
  width: 192px;

  ${media.tabletLandscape} {
    margin-bottom: 2rem;
    width: 175px;
  }
`;

export const Links = styled.ul``;

export const Separator = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.borderBase};
  height: ${({ theme }) => theme.measures.x};

  ${media.tabletLandscape} {
    height: ${({ theme }) => theme.measures.l};
  }
`;

export const AppsLinks = styled.div`
  align-items: center;
  display: flex;

  ${media.tabletLandscape} {
    transform: scale(0.88) translateX(-0.9rem);
  }
`;

export const AppLink = styled(DefaultLink)`
  color: inherit;
  display: block;
  line-height: 0;
  margin-left: 0.4rem;
  text-decoration: none;

  &:first-child {
    margin-left: 0;
  }
`;

export const AppStoreBadge = styled(Icon).attrs(() => ({
  color: '#6f6f6f',
  name: 'appStoreBadge',
  size: 105,
}))`
  display: block;
`;

export const GooglePlayBadge = styled(Icon).attrs(() => ({
  color: '#6f6f6f',
  name: 'googlePlayBadge',
  size: 114,
}))`
  display: block;
`;
