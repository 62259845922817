/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Placeholder = styled.div`
  align-items: center;
  border: 2px solid red;
  color: red;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
`;
