import { memo } from 'react';
import type { FC } from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { RETREAT_LIVE_MEETING_POLL_INTERVAL } from 'models/Retreat';
import useLiveMeetingInfo from 'graphql/hooks/useLiveMeetingInfo';
import { Container, Description, Icon, StartTime, Time, Title } from './styles';
import type { Props } from './types';

const LiveMeeting: FC<Props> = ({
  allCoursesCompleted,
  onClick,
  retreatId,
}) => {
  const { liveMeetingInfo } = useLiveMeetingInfo({
    pollInterval: RETREAT_LIVE_MEETING_POLL_INTERVAL,
    variables: { id: retreatId },
  });

  if (!liveMeetingInfo) {
    return null;
  }

  const { description, link, startDate, title } = liveMeetingInfo;
  const startTime = startDate
    ? format(parseISO(startDate), 'h:mma O')
    : '--:--';

  return (
    <Container onClick={onClick}>
      <Title>{title}</Title>
      {description && (
        <Description>
          {description.replace('{{START_TIME}}', startTime)}
        </Description>
      )}
      <Time>
        <StartTime>{startTime}</StartTime>
        {!allCoursesCompleted && !link && <Icon name="lockSimpleFilled" />}
      </Time>
    </Container>
  );
};

export default memo(LiveMeeting);
