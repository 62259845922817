import styled from 'styled-components';
import DefaultLink from 'components/Link';
import DefaultAppStore from './AppStore';
import DefaultGooglePlay from './GooglePlay';

export const Container = styled(DefaultLink)`
  align-items: center;
  background-color: #2565f5;
  border-radius: 0.25rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  max-width: 10rem;
  text-decoration: none;

  &:first-child {
    margin-right: 1.5%;
  }

  &:last-child {
    margin-left: 1.5%;
  }
`;

export const AppStore = styled(DefaultAppStore)`
  display: block;
  height: 100%;
  margin: 5% 6.7% 4.8% 6.7%;
  width: 100%;
`;

export const GooglePlay = styled(DefaultGooglePlay)`
  display: block;
  height: 100%;
  margin: 5% 6.7% 4.8% 6.7%;
  width: 100%;
`;
