import type { FC } from 'react';
import { Container, Dot, Icon, LinearProgress } from './styles';
import type { Props } from './types';

const PackLinearProgress: FC<Props> = ({
  className,
  nSubpacks = 0,
  progress = 0,
  total = 0,
}) => {
  const formattedPending = Math.round(total - total * (progress / 100));

  const showNSubpacks = nSubpacks > 0;
  const showPlayed = nSubpacks === 0 && progress === 100;
  const showProgress = nSubpacks === 0 && progress > 0 && progress < 100;

  return (
    <Container $highlight={!showNSubpacks && !showPlayed} className={className}>
      {showNSubpacks ? (
        `${nSubpacks} series`
      ) : (
        <>
          {showPlayed && (
            <>
              <Icon name="checkCircleLightFilled" />
              Played
              <Dot>·</Dot>
            </>
          )}
          {showProgress && (
            <LinearProgress value={progress} variant="determinate" />
          )}
          {showProgress
            ? `${formattedPending} Sessions left`
            : `${total} Sessions`}
        </>
      )}
    </Container>
  );
};

export default PackLinearProgress;
