import styled, { css } from 'styled-components';
import {
  MenuList as MUIMenuList,
  Paper as MUIPaper,
  Popper as MUIPopper,
} from '@mui/material';
import { ZIndex } from 'styles/metrics';
import colorWithAlpha from 'lib/color';

export const Popper = styled(MUIPopper)`
  z-index: ${ZIndex.PLAYER_OPTIONS_MENU};
`;

export const Card = styled(MUIPaper)<{ $width: string }>`
  &.MuiPaper-root {
    background-color: ${({ theme }) =>
      theme.name === 'LIGHT'
        ? theme.colors.coreNeutralAurora
        : theme.colors.coreNeutralAbyss};
    border-radius: 1.45rem;
    box-shadow: ${({ theme }) => theme.elevation.web2};
    color: ${({ theme }) =>
      theme.name === 'LIGHT'
        ? theme.colors.coreNeutralAbyss
        : theme.colors.coreNeutralAurora};
    width: ${({ $width }) => $width};

    ${({ theme }) =>
      theme.name === 'DARK' &&
      css`
        border: 1px solid
          ${colorWithAlpha({
            color: theme.colors.coreNeutralWhite,
            alpha: 0.25,
          })};
      `}
  }
`;

export const MenuList = styled(MUIMenuList)`
  &.MuiList-root {
    padding: 0.75rem 0;
  }
`;

export const Title = styled.div`
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.2em;
  line-height: 1.33;
  padding: 0.375rem 1.25rem;
  text-transform: uppercase;
`;
