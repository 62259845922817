import styled from 'styled-components';
import { motion } from 'framer-motion';
import DefaultButton from 'components/Button';

export const Container = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.5, ease: 'easeInOut' },
}))`
  align-items: center;
  display: flex;
  flex: 1 1 0;
  flex-flow: column nowrap;
  justify-content: center;
`;

export const Buttons = styled.div`
  max-width: 95%;
  width: 22rem;
`;

export const Button = styled(DefaultButton)`
  border-color: ${({ theme }) => theme.colors.coreTeal500};
  color: ${({ theme }) => theme.colors.coreNeutralAurora};
  margin: ${({ theme }) => theme.measures.s} 0;
  width: 100%;
`;
