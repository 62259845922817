import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { blink } from 'styles/mixins';
import colorWithAlpha from 'lib/color';
import DefaultIcon from 'components/Icon';

export const Container = styled(Link)<{ $isLoading?: boolean }>`
  border-radius: ${({ theme }) => theme.radius.md};
  color: ${({ theme }) => theme.colors.fontBase};
  cursor: pointer;
  position: relative;

  &:active,
  &:visited {
    color: ${({ theme }) => theme.colors.fontBase};
  }

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      animation: ${blink} 3s linear infinite;
      background-color: ${({ theme }) =>
        colorWithAlpha({ color: theme.colors.fontDeemphasized, alpha: 0.8 })};
      cursor: inherit;
      pointer-events: none;
    `}
`;

export const Content = styled.div`
  border-radius: ${({ theme }) => theme.radius.md};
  border: 1px solid ${({ theme }) => theme.colors.coreNeutral300};
  height: 10rem;
  overflow: hidden;
  padding: ${({ theme }) => `${theme.measures.m} ${theme.measures.m}`};
  width: 10rem;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
`;

export const Target = styled.div<{ $hidden?: boolean }>`
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundBase};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.coreNeutral300};
  bottom: -1rem;
  display: flex;
  height: 2rem;
  justify-content: center;
  left: calc(100% - 1rem);
  opacity: ${({ $hidden }) => ($hidden ? '0' : '1')};
  position: absolute;
  transition: opacity ease 200ms;
  width: 2rem;
`;

export const Icon = styled(DefaultIcon).attrs(({ theme }) => ({
  color:
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralBlack
      : theme.colors.coreNeutralWhite,
}))``;
