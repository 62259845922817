import type { FC } from 'react';
import { LoginStatus } from 'graphql/reactive';
import GoogleLogin from '../GoogleLogin';
import EmailLogin from './EmailLogin';
import {
  Container,
  Footer,
  Isotype,
  Link,
  Main,
  Methods,
  Or,
  Title,
} from './styles';
import useConnect from './connect';

const SignIn: FC = () => {
  const { initialEmail, initialStep, loginStatus, title } = useConnect();

  return (
    <Container>
      <Main>
        <Isotype />
        <Title>{title}</Title>
        <Methods>
          <EmailLogin initialEmail={initialEmail} initialStep={initialStep} />
          {loginStatus !== LoginStatus.STARTED && (
            <>
              <Or>or</Or>
              <GoogleLogin mode="signin" />
            </>
          )}
        </Methods>
        <Link aria-label="Go to sign up page" to="/sign-up">
          Sign Up to Be a Member
        </Link>
      </Main>
      <Footer />
    </Container>
  );
};

export default SignIn;
