import { memo, type FC } from 'react';
import { ClickAwayListener, Fade } from '@mui/material';
import AutoplayMenu from './Autoplay';
import BackgroundSoundsMenu from './BackgroundSounds';
import CourseMenu from './Course';
import DailyMenu from './Daily';
import PackMenu from './Pack';
import RetreatMenu from './Retreat';
import SpeedMenu from './Speed';
import { Card, MenuList, Popper } from './styles';
import useConnect from './connect';

const OptionsMenu: FC = () => {
  const {
    handleClickAway,
    handleKeyDown,
    menuListRef,
    menuWidth,
    offsetMenu,
    optionsMenu,
    placementMenu,
  } = useConnect();

  return (
    optionsMenu && (
      <Popper
        anchorEl={optionsMenu.anchorEl}
        disablePortal
        id="options-menu"
        modifiers={[
          {
            name: 'offset',
            enabled: true,
            options: {
              offsetMenu,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              escapeWithReference: true,
              boundariesElement: 'viewport',
            },
          },
          {
            name: 'flip',
            enabled: true,
            options: {
              flipVariationsByContent: true,
            },
          },
        ]}
        open={optionsMenu.type !== null}
        placement={placementMenu}
        transition
      >
        {({ TransitionProps }) => (
          <Fade timeout={350} {...TransitionProps}>
            <Card $width={menuWidth!!}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <MenuList
                  autoFocusItem
                  onKeyDown={handleKeyDown}
                  ref={menuListRef}
                  variant="selectedMenu"
                >
                  {(optionsMenu.type === 'course' ||
                    optionsMenu.type === 'coursePlayer') && (
                    <CourseMenu
                      courseId={optionsMenu.id}
                      packId={optionsMenu.packId}
                      type={optionsMenu.type}
                    />
                  )}
                  {optionsMenu.type === 'speed' && <SpeedMenu />}
                  {optionsMenu.type === 'backgroundSounds' && (
                    <BackgroundSoundsMenu />
                  )}
                  {optionsMenu.type === 'autoplay' && <AutoplayMenu />}
                  {(optionsMenu.type === 'daily' ||
                    optionsMenu.type === 'dailyPlayer') && (
                    <DailyMenu
                      dailyId={optionsMenu.dailyId}
                      sharedHash={optionsMenu.sharedHash}
                      type={optionsMenu.type}
                    />
                  )}
                  {optionsMenu.type === 'pack' && optionsMenu.id && (
                    <PackMenu packId={optionsMenu.id} />
                  )}
                  {optionsMenu.type === 'retreat' && <RetreatMenu />}
                </MenuList>
              </ClickAwayListener>
            </Card>
          </Fade>
        )}
      </Popper>
    )
  );
};

export default memo(OptionsMenu);
