import { useMemo } from 'react';
import usePacks from './usePacks';

const usePack = (packKey?: string) => {
  const { loading, packs } = usePacks();

  const pack = useMemo(
    () =>
      packKey && packs && packs.length > 0
        ? packs.find(({ hash, id }) => packKey === hash || packKey === id)
        : undefined,
    [packKey, packs],
  );

  return { loading, pack };
};

export default usePack;
export type UsePack = ReturnType<typeof usePack>;
