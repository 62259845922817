import { apolloClient } from 'services/api';
import type { DailyDataFragment } from '../generated';
import { DAILY_DATA_FRAGMENT } from '../fragments';

function cacheUpdateDailyFragment(
  hash: string,
  data: Partial<DailyDataFragment>,
) {
  const id = `Daily:${hash}`;

  const cacheData = apolloClient.readFragment<DailyDataFragment>({
    fragment: DAILY_DATA_FRAGMENT,
    fragmentName: 'DailyData',
    id,
  });

  if (cacheData) {
    apolloClient.writeFragment<DailyDataFragment>({
      fragment: DAILY_DATA_FRAGMENT,
      fragmentName: 'DailyData',
      id,
      data: {
        ...cacheData,
        ...data,
      },
    });
  }
}

export default cacheUpdateDailyFragment;
