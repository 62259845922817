import type { FC } from 'react';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import parseISO from 'date-fns/parseISO';
import { useLocation } from 'react-router-dom';
import { toggleHelpScout } from 'services/helpscout';
import Layout from '../Layout';
import {
  Confirmation,
  Content,
  Button,
  Container,
  Email,
  StoreButton,
} from './styles';
import type { RouteState } from './types';

const RedeemSuccess: FC = () => {
  const location = useLocation();
  const state = location.state as RouteState;
  const email = state?.email;

  const expirationDays = differenceInCalendarDays(
    parseISO(state?.expiresAt),
    new Date(),
  );
  const confirmationMessage = `Your code has been redeemed successfully!${
    expirationDays <= 1000 ? `\n It expires in ${expirationDays} days` : ''
  }`;

  return (
    <Layout>
      <Container>
        <Confirmation
          id="redeem-gift-card-code-success"
          message={confirmationMessage}
        />
        <Content>
          <Email>Account: {email}</Email>
          <StoreButton type="ios" />
          <StoreButton type="android" />
          <Button to="/" replace>
            Sign In
          </Button>
          <Button onClick={toggleHelpScout}>Contact Support</Button>
        </Content>
      </Container>
    </Layout>
  );
};

export default RedeemSuccess;
