import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { trackEvent } from 'services/analytics';
import { ValidateSharedTokenDocument } from '../generated';
import {
  GET_CONTENT,
  GET_DAILY,
  GET_FEATURE_CONTENT,
  GET_REMOTE_CONFIG,
} from '../queries';
import { getProfile } from '../requests';

const useFreeMonthActions = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [validateSharedTokenMutation] = useMutation(
    ValidateSharedTokenDocument,
    {
      refetchQueries: [
        { query: GET_CONTENT },
        { query: GET_DAILY },
        { query: GET_FEATURE_CONTENT },
        { query: GET_REMOTE_CONFIG },
      ],
    },
  );

  const validateSharedToken = useCallback(
    async ({ source, token }: { source?: string; token: string }) => {
      try {
        const response = await validateSharedTokenMutation({
          variables: { token },
        });

        if (response?.data?.validateSharedToken) {
          const profile = await getProfile({ fetchPolicy: 'network-only' });

          trackEvent('Subscription Redeem', {
            code: token,
            subscriptionEndTime: profile.subscriptionExpiresAt,
            subscriptionStartTime: profile.subscriptionStartTime,
            source: source || 'app share',
            type: 'promo',
          });

          enqueueSnackbar('Your subscription has been updated', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar('Unable to validate the token', { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar(error.message || 'Unable to validate the token', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, validateSharedTokenMutation],
  );

  return {
    validateSharedToken,
  };
};

export default useFreeMonthActions;
export type UseFreeMonthActions = ReturnType<typeof useFreeMonthActions>;
