import { makeVar } from '@apollo/client';
import { resetCoursePlayerVar } from './coursePlayer';
import { resetPreviewPlayerVar } from './previewPlayer';
import { resetDailyPlayerVar } from './dailyPlayer';
import { resetRetreatPlayerVar } from './retreatPlayer';
import { resetSelfTimerPlayerVar } from './selfTimerPlayer';

export enum PlayerType {
  COURSE,
  DAILY,
  PREVIEW,
  RETREAT,
  SELF_TIMER,
}

export const playerTypeVar = makeVar<PlayerType | null>(null);

export const resetPlayerTypeVar = () => {
  playerTypeVar(null);

  setTimeout(() => {
    resetCoursePlayerVar();
    resetPreviewPlayerVar();
    resetDailyPlayerVar();
    resetRetreatPlayerVar();
    resetSelfTimerPlayerVar();
  }, 500);
};

export const setPlayerTypeVar = (data: PlayerType | null) => {
  playerTypeVar(data);
};
