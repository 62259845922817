import { memo } from 'react';
import type { FC } from 'react';
import useConnect from './connect';
import {
  ButtonPrimary,
  Buttons,
  ButtonSecondary,
  Content,
  Description,
  Modal,
} from './styles';

const EndRetreat: FC = () => {
  const { handleClose, handleEnd, isOpen, isUpdatingUserRetreat } =
    useConnect();

  return (
    <Modal
      descriptionId="end-retreat-description"
      fullscreenOnMobile
      hideClose
      onClose={handleClose}
      open={isOpen}
    >
      <Content>
        <Description id="end-retreat-description">
          This retreat is intended to be completed in one session. Are you sure
          you want to leave?
        </Description>
      </Content>
      <Buttons>
        <ButtonPrimary onClick={handleClose}>Stay in Retreat</ButtonPrimary>
        <ButtonSecondary disabled={isUpdatingUserRetreat} onClick={handleEnd}>
          End Retreat
        </ButtonSecondary>
      </Buttons>
      <Content />
    </Modal>
  );
};

export default memo(EndRetreat);
