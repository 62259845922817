import { memo, useCallback, useState } from 'react';
import type { FC } from 'react';
import { AnimatePresence } from 'framer-motion';
import Tab from './Tab';
import { Container, Content, Html, Tabs } from './styles';
import type { Props } from './types';

const Panels: FC<Props> = ({ className, data, onTabChange, tabIndex }) => {
  const [localIndex, setLocalIndex] = useState(0);

  const handleSelectTab = useCallback(
    (newIndex: number) => {
      setLocalIndex(newIndex);
      if (onTabChange) onTabChange(newIndex);
    },
    [onTabChange],
  );

  if (!data || data.length === 0) {
    return null;
  }

  const currentIndex = tabIndex ?? localIndex;
  const { info, title } = data[currentIndex];

  return (
    <Container className={className}>
      <Tabs>
        {data.map((v, i) => (
          <Tab
            key={v.title}
            active={i === currentIndex}
            index={i}
            onClick={handleSelectTab}
            title={v.title}
          />
        ))}
      </Tabs>
      <AnimatePresence initial={false}>
        <Content key={title}>
          {typeof info === 'string' ? (
            <Html dangerouslySetInnerHTML={{ __html: info }} />
          ) : (
            <>{info}</>
          )}
        </Content>
      </AnimatePresence>
    </Container>
  );
};

export default memo(Panels);
