import styled from 'styled-components';
import { blink } from 'styles/mixins';
import colorWithAlpha from 'lib/color';
import DefaultTextPlaceholder from 'components/TextPlaceholder';

export const Container = styled.div`
  width: auto;
`;

export const Image = styled.div`
  height: 0;
  margin-bottom: 1rem;
  padding-top: 100%;
  position: relative;

  &::before {
    animation: ${blink} 3s linear infinite;
    background-color: ${({ theme }) =>
      colorWithAlpha({
        color: theme.colors.fontDeemphasized,
        alpha: 0.8,
      })};
    border-radius: 0.5rem;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const Overline = styled(DefaultTextPlaceholder).attrs(() => ({
  typography: 'overline',
}))`
  width: 40%;
`;

export const Title = styled(DefaultTextPlaceholder).attrs(() => ({
  typography: 'displaySmall',
}))`
  width: 75%;
`;

export const Body = styled(DefaultTextPlaceholder).attrs(() => ({
  typography: 'bodyMedium',
}))`
  width: 55%;
`;
