import type { FC } from 'react';
import { AnimatePresence } from 'framer-motion';
import { CoursePermission, CourseStatus, CourseType } from 'models/Course';
import { dailySectionColor, sectionColors } from 'models/IntroCourse';
import NextCourse from './NextCourse';
import Course from './Course';
import Chapter from './Chapter';
import Banner from './Banner';
import CompleteButton from './CompleteButton';
import {
  ChapterContent,
  Collapsable,
  Container,
  LockedContentBanner,
} from './styles';
import type { Props } from './types';
import useConnect from './connect';

const IntroCourse: FC<Props> = ({
  chapters,
  onOpenOptionsMenu,
  onPlayCourse,
  onPlayDaily,
}) => {
  const {
    expandedChapters,
    handleExpandChapter,
    handleOpenSectionCompleted,
    introPack,
    isDailyUnlocked,
    isPhoneLandscape,
    isSubscribed,
    nextIntroCourseCode,
    nextIntroCourseDuration,
    nextIntroCourseImage,
    nextIntroCourseOverline,
    nextIntroCoursePackId,
    nextIntroCourseTitle,
    nextIntroCourseType,
  } = useConnect({ chapters });

  if (!introPack) return null;

  const showSubscriptionCTA = !isSubscribed;
  const showNextCourse =
    isSubscribed && nextIntroCourseCode && !isDailyUnlocked;
  const showDailyLocked = !isSubscribed || !isDailyUnlocked;

  return (
    <Container>
      <Banner
        imageUrl={nextIntroCourseImage || introPack.imageUrl}
        description={introPack.description}
        showFullDescription={!isPhoneLandscape}
      />
      {showSubscriptionCTA && <LockedContentBanner />}
      {showNextCourse && (
        <NextCourse
          courseCode={nextIntroCourseCode}
          duration={nextIntroCourseDuration}
          isPractice={nextIntroCourseType === CourseType.MEDITATION}
          overline={nextIntroCourseOverline}
          packId={nextIntroCoursePackId}
          setTrack={onPlayCourse}
          title={nextIntroCourseTitle}
        />
      )}
      <>
        {chapters.map(({ id, title, courses, endMessage }, i) => {
          const lastCourseCompleted =
            courses.find((c) => c.isLastCourse)?.status ===
            CourseStatus.FINISHED;
          const showEndMessage = !!endMessage && lastCourseCompleted;
          const isExpanded = expandedChapters.includes(i + 1);
          const chapterNumber = i + 1;

          return (
            <ChapterContent key={title}>
              <Chapter
                aria-controls={`intro-course-section-${chapterNumber}-sessions-list`}
                aria-expanded={isExpanded}
                aria-label={`${
                  isExpanded ? 'Collapse' : 'Expand'
                } section ${chapterNumber} sessions list`}
                color={sectionColors[i]}
                isExpanded={isExpanded}
                number={chapterNumber}
                onClick={handleExpandChapter}
                overline={`SECTION ${chapterNumber} OF ${chapters.length}`}
                title={title}
              />
              <AnimatePresence initial={false}>
                {isExpanded && (
                  <Collapsable
                    id={`intro-course-section-${chapterNumber}-sessions-list`}
                  >
                    {courses.map((course) => (
                      <Course
                        key={course.id}
                        courseId={course.id}
                        courseStatus={course.status}
                        description={course.description}
                        isBookmark={course.isBookmark}
                        isForbidden={
                          course.permission === CoursePermission.FORBIDDEN
                        }
                        isHighlighted={!!course.isHighlighted}
                        onOpenOptionsMenu={onOpenOptionsMenu}
                        onPlay={onPlayCourse}
                        packId={course.packId}
                        progress={course.progress}
                        subtitle={course.subtitle}
                        title={course.title}
                        titleIcon={
                          course.courseType === CourseType.MEDITATION
                            ? 'lotusLight'
                            : 'bookOpenLight'
                        }
                        total={course.audioLength}
                        type="course"
                      />
                    ))}
                    {showEndMessage && (
                      <CompleteButton
                        onOpenEndSection={handleOpenSectionCompleted}
                        packId={id}
                        section={chapterNumber}
                      />
                    )}
                  </Collapsable>
                )}
              </AnimatePresence>
            </ChapterContent>
          );
        })}
        <ChapterContent>
          <Chapter
            aria-label="Play daily"
            color={dailySectionColor}
            isBonusContent
            isBonusContentLocked={showDailyLocked}
            isForbidden={!isSubscribed}
            number={chapters.length + 1}
            onClick={onPlayDaily}
            overline="BONUS CONTENT"
            title="Daily Meditation"
          />
        </ChapterContent>
      </>
    </Container>
  );
};

export default IntroCourse;
