import getCourseProgressPercent from 'lib/getCourseProgressPercent';
import useSettings from './useSettings';
import usePackCourses from './usePackCourses';

const useRecentlyPlayed = () => {
  const { packCoursesWithPrimaryPackObj, loading: isLoadingPackCourses } =
    usePackCourses();
  const {
    loading: isLoadingSettings,
    settings: { recentlyPlayed },
  } = useSettings();

  const recentlyPlayedItems = recentlyPlayed
    .filter(({ course_hash }) => {
      const course = packCoursesWithPrimaryPackObj[course_hash];
      return !!course && !course.isIntroCourse && !course.ephemeral;
    })
    .slice(0, 8)
    .map(({ course_hash }) => {
      const course = packCoursesWithPrimaryPackObj[course_hash];

      return {
        ...course,
        progress: getCourseProgressPercent({
          hash: course.hash,
          audioLength: course.audioLength,
          recentlyPlayed,
          status: course.status,
        }),
      };
    });

  return {
    loading: isLoadingPackCourses || isLoadingSettings,
    recentlyPlayed: recentlyPlayedItems,
  };
};

export default useRecentlyPlayed;
export type UseRecentlyPlayed = ReturnType<typeof useRecentlyPlayed>;
