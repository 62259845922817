import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { Display as DefaultDisplay } from 'components/Typography';
import DefaultIsotype from 'components/Isotype';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
`;

export const Isotype = styled(DefaultIsotype)`
  margin-bottom: 1rem;
  width: 2.6rem;
`;

export const Progress = styled(CircularProgress).attrs(() => ({
  size: 40,
}))``;

export const Message = styled(DefaultDisplay).attrs(() => ({
  $size: 'medium',
}))`
  margin-bottom: 2rem;
`;
