import styled, { css } from 'styled-components';
import colorWithAlpha from 'lib/color';
import { media } from 'styles/mixins';
import DefaultButton from 'components/Button';
import ButtonCTA from 'components/ButtonCTA';
import { Heading } from 'components/Typography';
import type { Props } from './types';

export const Container = styled.div<{
  $isTimeSectionOpen: Props['isTimeSectionOpen'];
}>`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-height: 450px) {
    max-height: 100px;
  }

  ${({ $isTimeSectionOpen }) =>
    $isTimeSectionOpen &&
    css`
      ${media.tabletPortrait} {
        display: none;
      }
    `}
`;

export const StartButton = styled(DefaultButton)`
  margin-bottom: ${({ theme }) => theme.measures.x};
`;

export const IntervalButton = styled(ButtonCTA).attrs(() => ({
  size: 'small',
}))`
  background: none !important;
  color: ${({ theme }) => theme.colors.fontBase};
  padding: 0.3rem 0;
`;

export const FinishTime = styled(Heading)`
  align-items: center;
  background-color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? colorWithAlpha({
          color: theme.colors.coreNeutralBlack,
          alpha: 0.04,
        })
      : colorWithAlpha({
          color: theme.colors.coreNeutralBlack,
          alpha: 0.15,
        })};
  border-radius: 0.25rem;
  display: flex;
  font-size: 0.95rem;
  height: 2.5rem;
  justify-content: center;
  margin-top: auto;
  padding: 0.4rem 0;
`;
