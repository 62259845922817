import { useMemo } from 'react';
import { useQuery, type QueryHookOptions } from '@apollo/client';
import { isProduction } from 'config';
import { normalizeRetreat } from 'models/Retreat';
import {
  GetRetreatDocument,
  type GetRetreatQuery,
  type GetRetreatQueryVariables,
} from '../generated';

const useRetreat = (
  options: QueryHookOptions<GetRetreatQuery, GetRetreatQueryVariables> = {},
) => {
  const { data, loading } = useQuery(GetRetreatDocument, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    ...options,
    skip: isProduction,
  });

  const retreat = useMemo(
    () => (data?.retreat ? normalizeRetreat(data.retreat) : undefined),
    [data],
  );

  return {
    retreat,
    loading: !data && loading,
  };
};

export default useRetreat;
export type UseRetreat = ReturnType<typeof useRetreat>;
