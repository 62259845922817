import { makeVar } from '@apollo/client';

export enum LoginStatus {
  WAITING = 'waiting',
  STARTING_PIN_MODE = 'starting_pin_mode',
  STARTING_GOOGLE_MODE = 'starting_google_mode',
  STARTED = 'started',
}

export const loginStatusVar = makeVar<LoginStatus>(LoginStatus.WAITING);

export const setLoginStatusVar = (newStatus: LoginStatus) =>
  loginStatusVar(newStatus);

export const resetLoginStatusVar = () => loginStatusVar(LoginStatus.WAITING);
