import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse } from 'query-string';

const useConnect = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { 'open-gift': openGift } = parse(search);

  // Support old link with 'open-gift' param (used with lightrail).
  useEffect(() => {
    if (openGift) {
      navigate('/purchase-gift-card', { replace: true });
    }
  }, [navigate, openGift]);

  return {};
};

export default useConnect;
