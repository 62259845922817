import { memo, type FC, useCallback } from 'react';
import { AppConfig, isDevelopment } from 'config';
import { isAndroid, isIOS } from 'services/browser';
import useMe from 'graphql/hooks/useMe';
import useModalActions from 'graphql/hooks/useModalActions';
import NavLink from './NavLink';
import {
  AppLink,
  AppsLinks,
  AppStoreBadge,
  GooglePlayBadge,
  CloseButton,
  Icon,
  Container,
  Info,
  Links,
  UserInfo,
  UserStats,
} from './styles';
import type { Props } from './types';

const MobileNav: FC<Props> = ({ isOpen, onClose }) => {
  const { profile } = useMe();
  const {
    openGiveFreeTrialModal,
    openSelfTimerConfigurationModal,
    openShareAppModal,
    openSupportModal,
  } = useModalActions();

  const handleOpenSupport = useCallback(() => {
    onClose();
    setTimeout(() => openSupportModal(), 400);
  }, [onClose, openSupportModal]);

  const handleOpenSelfTimer = useCallback(() => {
    onClose();
    setTimeout(() => openSelfTimerConfigurationModal(), 400);
  }, [onClose, openSelfTimerConfigurationModal]);

  const handleOpenShareTheApp = useCallback(() => {
    onClose();
    setTimeout(() => {
      if (isDevelopment) {
        openShareAppModal({ source: 'menu' });
      } else {
        openGiveFreeTrialModal({ source: 'menu' });
      }
    }, 400);
  }, [onClose, openGiveFreeTrialModal, openShareAppModal]);

  return (
    <Container
      aria-label="Main menu"
      id="sidebar-panel"
      open={isOpen}
      role="navigation"
    >
      <Info>
        <CloseButton
          aria-controls="sidebar-panel"
          aria-expanded="true"
          aria-label="Close main menu"
          onClick={onClose}
          role="button"
          tabIndex={0}
        >
          <Icon aria-hidden="true" name="xLight" />
        </CloseButton>
        <UserInfo
          avatarUrl={profile.avatarUrl}
          city={profile.city}
          firstName={profile.firstName}
          inline
          joinedAt={profile.joinedAt}
          lastName={profile.lastName}
        />
        <UserStats
          bigValues
          forceRows
          hideSeparators
          mindfulDays={profile.activityMindfulDays}
          mindfulMinutes={profile.activityMindfulMinutes}
          titlePosition="bottom"
          totalSessions={profile.activityTotalSessions}
        />
      </Info>
      <Links>
        <NavLink
          end
          iconName="houseLineLight"
          id="nav-mobile-home"
          onClick={onClose}
          to="/"
          title="Home"
        />
        <NavLink
          iconName="bookOpenLight"
          id="nav-mobile-theory"
          onClick={onClose}
          title="Theory"
          to="/theory"
        />
        <NavLink
          iconName="lotusLight"
          id="nav-mobile-practice"
          onClick={onClose}
          title="Practice"
          to="/practice"
        />
        <NavLink
          iconName="lifeLight"
          id="nav-mobile-life"
          onClick={onClose}
          title="Life"
          to="/life"
        />
        <NavLink
          iconName="timerLight"
          id="nav-mobile-meditationtimer"
          onClick={handleOpenSelfTimer}
          title="Meditation Timer"
        />
        <NavLink
          iconName="bookmarkSingleInactive"
          id="nav-mobile-mylibrary"
          onClick={onClose}
          title="My Library"
          to="/my-library"
        />
        <NavLink
          iconName="arrowUUpLeftLight"
          id="nav-mobile-recentlyplayed"
          onClick={onClose}
          title="Recently Played"
          to="/recently-played"
        />
        <NavLink
          iconName="search"
          id="nav-mobile-search"
          onClick={onClose}
          title="Search"
          to="/search"
        />
        <NavLink
          iconName="userCircleLight"
          id="nav-desktop-account"
          onClick={onClose}
          title="Account"
          to="/account"
        />
        <NavLink
          iconName="chatCircleDotsLightInverted"
          id="nav-desktop-support"
          title="Contact Support"
          onClick={handleOpenSupport}
        />
        {profile.subscriptionIsElegibleForSharing && (
          <NavLink
            iconName="shareLight"
            id="nav-desktop-inviteafriend"
            onClick={handleOpenShareTheApp}
            title="Invite a Friend"
          />
        )}
      </Links>
      <AppsLinks>
        {isIOS && (
          <AppLink
            aria-label="Link to App Store (opens in a new tab)"
            href={AppConfig.appStoreURL}
            target="_blank"
          >
            <AppStoreBadge />
          </AppLink>
        )}
        {isAndroid && (
          <AppLink
            aria-label="Link to Google Play (opens in a new tab)"
            href={AppConfig.playStoreURL}
            target="_blank"
          >
            <GooglePlayBadge />
          </AppLink>
        )}
      </AppsLinks>
    </Container>
  );
};

export default memo(MobileNav);
