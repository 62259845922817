import type { FC } from 'react';
import type { Props } from './types';

const BookOpenLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M12.005 4.643H12A8.48 8.48 0 0 0 6.994 3 8.464 8.464 0 0 0 2 4.633v15.67a8.485 8.485 0 0 1 4.993-1.632c1.85 0 3.582.606 5.008 1.64a8.498 8.498 0 0 1 5.006-1.64A8.47 8.47 0 0 1 22 20.304V4.634A8.485 8.485 0 0 0 17.007 3c-1.85 0-3.579.606-5.006 1.64l-.006.003.007 15.667"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.13}
    />
  </svg>
);

export default BookOpenLight;
