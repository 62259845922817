import type { FC } from 'react';
import { Formik } from 'formik';
import Button from 'components/Button';
import { FormikForm, PriceSelector, TextField } from './styles';
import { VALIDATION_SCHEMA } from './constants';
import useConnect from './connect';

const Form: FC = () => {
  const { fullScholarshipPrices, handleSubmit, initialValues, isLoading } =
    useConnect();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validationSchema={VALIDATION_SCHEMA}
    >
      {({ isSubmitting, values: { email, price } }) => (
        <FormikForm noValidate>
          <TextField
            autoComplete="email"
            errorId="email-error"
            id="email"
            label="Email"
            maxLength={150}
            name="email"
            placeholder="e.g user@wakingup.com"
            type="email"
          />
          <PriceSelector
            isLoading={isLoading}
            label="Choose an option."
            name="price"
            prices={fullScholarshipPrices}
          />
          <Button
            disabled={isLoading || isSubmitting || !email || price === null}
            isLoading={isSubmitting}
            type="submit"
          >
            Continue
          </Button>
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
