import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.font.family.inter};
  height: 100%;
  width: 100%;

  section,
  p:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  strong {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  a {
    color: ${({ theme }) => theme.colors.fontEmphasized};
  }
`;

export const Title = styled.h1`
  color: inherit;
  font-size: 2.25rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: 1.75rem;
  margin-top: 3.75rem;

  ${media.tabletPortrait} {
    margin-top: 1.25rem;
  }

  ${media.phoneLandscape} {
    margin-top: 0;
  }
`;
