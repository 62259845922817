import type { FC } from 'react';
import ExpiredPromotion from 'components/ExpiredPromotion';
import { Container, Header } from './styles';
import { Props } from './types';

const HolidayGiftCardPurchasePage: FC<Props> = () => (
  <>
    <Header testId="info-header" title="Account" backHref="/account" />
    <Container>
      <ExpiredPromotion
        description="Click the link below to continue with your gift card purchase at the standard price."
        linkText="Purchase a Gift Card"
        linkTo="/purchase-gift-card"
      />
    </Container>
  </>
);

export default HolidayGiftCardPurchasePage;
