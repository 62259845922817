import { makeVar } from '@apollo/client';

export const timerIsPlayingVar = makeVar<boolean>(true);
export const timerPositionVar = makeVar<number>(0);

export const resetTimer = () => {
  timerIsPlayingVar(true);
  timerPositionVar(0);
};

export const setTimerIsPlaying = (data: boolean) => {
  timerIsPlayingVar(data);
};

export const setTimerPosition = (data: number) => {
  timerPositionVar(data);
};
