import { memo, type FC } from 'react';
import { useLocation } from 'react-router-dom';
import useMediaQuery from 'lib/useMediaQuery';
import { media } from 'styles/mixins';
import { ProfileFormFields } from 'models/User';
import useAvatars from 'graphql/hooks/useAvatars';
import Icon from 'components/Icon';
import {
  BackLink,
  BackLinkContainer,
  Container,
  Head,
  Header,
  Heads,
  HeadImage,
  LoaderContainer,
  Loader,
  Title,
} from './styles';

const HeadPicker: FC = () => {
  const { avatars, loading: isLoading } = useAvatars();
  const location = useLocation();
  const state = location.state as ProfileFormFields;
  const isTabletPortrait = useMediaQuery(media.tabletPortrait);

  const { avatarId } = state || {};
  const backUrl = '/account/edit';

  return (
    <Container>
      <Header>
        {!isTabletPortrait && (
          <BackLinkContainer>
            <BackLink
              aria-label="Go back to profile form"
              state={state}
              to={backUrl}
            >
              <Icon name="arrowLeftLight" />
            </BackLink>
          </BackLinkContainer>
        )}
        <Title>Pick a Head</Title>
      </Header>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <Heads>
          {avatars.map(({ alt, id, url }) => (
            <Head
              key={id}
              $isCurrentHead={avatarId === id}
              aria-label={`Select head ${id} and go back to profile form`}
              state={{
                ...state,
                avatarAlt: alt,
                avatarId: id,
                avatarUrl: url,
              }}
              to={backUrl}
            >
              <HeadImage alt={alt} src={url} />
            </Head>
          ))}
        </Heads>
      )}
    </Container>
  );
};

export default memo(HeadPicker);
