import type { FC } from 'react';
import { Formik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import useMe from 'graphql/hooks/useMe';
import Message from './Message';
import Thanks from './Thanks';
import { Button, ButtonContainer, Content, Error, FormikForm } from './styles';
import { validationSchema } from '../constants';
import { Props, Step } from './types';

const Form: FC<Props> = ({
  descriptionId,
  initialValues,
  onClose,
  onSubmit,
  titleId,
}) => {
  const {
    profile: { email },
  } = useMe();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ errors, isSubmitting, values }) => (
        <FormikForm $centerContent={values.step === Step.thanks}>
          <Content>
            {values.step === Step.message && (
              <Message onClose={onClose} titleId={titleId} />
            )}
            {values.step === Step.thanks && (
              <Thanks
                descriptionId={descriptionId}
                email={email}
                titleId={titleId}
              />
            )}
          </Content>
          <ButtonContainer>
            <Button
              type="submit"
              isLoading={isSubmitting}
              disabled={!values.subject || !values.message}
            >
              {values.step === Step.message ? 'Send' : 'Done'}
            </Button>
            <AnimatePresence initial={false}>
              {errors.submitError && <Error>{errors.submitError}</Error>}
            </AnimatePresence>
          </ButtonContainer>
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
