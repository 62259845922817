import { memo } from 'react';
import type { FC } from 'react';
import { Container, Image, Overline, Title, Body } from './styles';
import type { Props } from './types';

const Placeholder: FC<Props> = ({ className }) => (
  <Container className={className}>
    <Image />
    <Overline />
    <Title />
    <Body />
  </Container>
);

export default memo(Placeholder);
