import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { FormikHelpers, FormikProps } from 'formik';
import useAuthActions from 'graphql/hooks/useAuthActions';
import {
  LoginStatus,
  loginStatusVar,
  resetLoginStatusVar,
  setLoginStatusVar,
} from 'graphql/reactive';
import { ConnectProps, FormFields, Step } from './types';

const useConnect = ({ initialEmail, initialStep }: ConnectProps) => {
  const { getPin, loginWithPin } = useAuthActions();
  const loginStatus = useReactiveVar(loginStatusVar);
  const formikRef = useRef<FormikProps<FormFields>>(null);

  const initialValues: FormFields = useMemo(
    () => ({
      email: initialEmail,
      pin: ['', '', '', ''],
      step: initialStep,
    }),
    [initialEmail, initialStep],
  );

  useEffect(() => {
    if (initialStep === Step.pin) {
      const sendPin = async () => {
        try {
          await getPin({ email: initialEmail });
        } catch (error) {
          resetLoginStatusVar();
          if (formikRef) {
            formikRef.current?.setErrors({ submitError: error.message });
          }
        }
      };
      sendPin();
    }
  }, [getPin, initialEmail, initialStep]);

  const handleSubmit = useCallback(
    async (
      { email, pin, step }: FormFields,
      { setErrors, setFieldValue }: FormikHelpers<FormFields>,
    ) => {
      try {
        if (email && step === Step.email) {
          setLoginStatusVar(LoginStatus.STARTING_PIN_MODE);
          await getPin({ email });
          setFieldValue('step', Step.pin);
          setLoginStatusVar(LoginStatus.STARTED);
        } else if (pin && step === Step.pin) {
          await loginWithPin(email, pin.join(''));
          resetLoginStatusVar();
        }
      } catch (error) {
        if (email && step === Step.email) {
          resetLoginStatusVar();
        }
        setErrors({ submitError: error.message });
      }
    },
    [getPin, loginWithPin],
  );

  return {
    formikRef,
    handleSubmit,
    initialValues,
    loginStatus,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
