import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultLink from 'components/Link';
import {
  Button as DefaultButton,
  Container as DefaultContainer,
  Content as DefaultContent,
} from '../styles';

export const Container = styled(DefaultContainer)`
  border-radius: 0.625rem;
  padding: 1rem 1.4rem 1rem 2.85rem;

  ${media.tabletPortrait} {
    border-radius: 0;
  }

  ${media.phonePortrait} {
    align-items: stretch;
    flex-direction: column;
  }
`;

export const CloseButton = styled(DefaultButton)`
  left: 0.75rem;
  top: 0.9rem;
  transform: none;
`;

export const Content = styled(DefaultContent)`
  flex: 1 1 auto;
`;

export const Overline = styled.p`
  color: ${({ theme }) => theme.colors.coreNeutralAurora};
  font-size: 0.875rem;
  letter-spacing: -0.04em;
  line-height: 1.23;

  strong {
    font-size: inherit;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  ${media.phonePortrait} {
    font-size: 0.85rem;
  }
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.coreNeutralAurora};
  font-size: 1.375rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  letter-spacing: -0.04em;
  line-height: 1.4;
  text-shadow: 0px 0px 6.21846px rgba(27, 94, 172, 0.33);

  ${media.phonePortrait} {
    font-size: 1.15rem;
  }
`;

export const Link = styled(DefaultLink)`
  border: 1px solid ${({ theme }) => theme.colors.coreNeutralAurora};
  border-radius: 0.2rem;
  font-size: 0.75rem;
  letter-spacing: 0.1em;
  line-height: 1.5;
  padding: 0.55rem 0.7rem;
  text-align: center;
  text-decoration: none;
  text-shadow: 0px 0px 5.19412px rgba(27, 94, 172, 0.33);
  text-transform: uppercase;

  ${media.phonePortrait} {
    font-size: 0.85rem;
    margin-top: 0.8rem;
    padding: 0.7rem 0.7rem;
  }
`;
