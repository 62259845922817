import { useMemo, useCallback } from 'react';
import {
  getValidIntervalTimes,
  SELF_TIMER_INTERVALS,
  SelfTimerInterval,
} from 'models/SelfTimerInterval';
import useMediaQuery from 'lib/useMediaQuery';
import { media } from 'styles/mixins';
import { Props } from './types';

const useConnect = ({
  duration,
  intervalTime,
  setIntervalTime,
  setIsTimeSectionOpen,
}: {
  duration: Props['duration'];
  intervalTime: Props['intervalTime'];
  setIntervalTime: Props['setIntervalTime'];
  setIsTimeSectionOpen: Props['setIsTimeSectionOpen'];
}) => {
  const isTablet = useMediaQuery(media.tabletPortrait);

  const validIntervalTime = useMemo(
    () => getValidIntervalTimes(duration, intervalTime),
    [duration, intervalTime],
  );

  const intervalTimesOptions = useMemo(
    () =>
      Object.values(SELF_TIMER_INTERVALS).filter(
        ({ minutes }) => !minutes || minutes < duration,
      ),
    [duration],
  );

  const selectIntervalTime = useCallback(
    (type: SelfTimerInterval) => {
      setIntervalTime(type);

      if (isTablet) {
        setIsTimeSectionOpen(false);
      }
    },
    [setIntervalTime, setIsTimeSectionOpen, isTablet],
  );

  const closeInterval = useCallback(
    () => setIsTimeSectionOpen(false),
    [setIsTimeSectionOpen],
  );

  return {
    closeInterval,
    intervalTimesOptions,
    selectIntervalTime,
    validIntervalTime,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
