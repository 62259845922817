import styled from 'styled-components';
import DefaultIcon from 'components/Icon';
import DefaultStoreLink from './StoreLink';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: 20rem;
  min-width: 12rem;
`;

export const QRCodeContainer = styled.div`
  background: ${({ theme }) => theme.colors.coreNeutralAurora};
  border: 1px solid #fbfbfb;
  border-radius: 0.25rem;
  box-sizing: border-box;
  box-shadow: 0rem 0.25rem 0.5rem -0.25rem rgba(0, 0, 0, 0.16),
    0rem 1rem 2.625rem 0.125rem rgba(0, 0, 0, 0.14),
    0rem 1.5rem 2.25rem rgba(0, 0, 0, 0.12);
  margin-bottom: 3rem;
  padding: 0.625rem;
  width: 85%;
`;

export const QRCode = styled.div`
  canvas {
    width: 100%;
  }
`;

export const QRCodeText = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.coreNeutralAbyss};
  display: flex;
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  justify-content: center;
  line-height: 1.21;
  margin-top: 1.125rem;
`;

export const Icon = styled(DefaultIcon)`
  margin-right: 0.6rem;
`;

export const Buttons = styled.div`
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
`;

export const StoreLink = styled(DefaultStoreLink)``;
