import styled from 'styled-components';
import { motion } from 'framer-motion';
import colorWithAlpha from 'lib/color';
import { media } from 'styles/mixins';
import { Body, Overline as DefaultOverline } from 'components/Typography';
import DefaultButton from 'components/Button';
import DefaultIcon from 'components/Icon';

export const Container = styled(motion.div)`
  background: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralWhite
      : colorWithAlpha({
          color: theme.colors.coreNeutralBlack,
          alpha: 0.25,
        })};
  border-radius: ${({ theme }) => theme.radius.md};
  overflow: hidden;
  padding: 1.8125rem 1.6rem 1.6rem 1.6rem;
  position: relative;

  ${media.phoneLandscape} {
    padding: 1.8125rem 0.75rem 1rem 0.75rem;
  }
`;

const ClearButton = styled.button`
  background: transparent;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: block;
  line-height: 1;
  outline: none;
`;

export const IconButton = styled(ClearButton)`
  position: absolute;
  right: 1.1875rem;
  top: 1.1875rem;

  ${media.phoneLandscape} {
    right: 0.6875rem;
    top: 0.625rem;
  }

  &:focus {
    > svg {
      border-radius: 50%;
      box-shadow: 0 0 0 2px #f4f4f4, 0 0 0 3.5px rgba(0, 96, 103, 0.25);
    }
  }
`;

export const Content = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
}))`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  align-items: flex-start;
  display: flex;
  width: 100%;
`;

export const Image = styled.img`
  margin-right: 0.9637rem;
  width: 7.7519rem;

  ${media.phonePortrait} {
    width: 5.1619rem;
  }
`;

export const ContentContainer = styled.div`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  display: flex;
  flex-flow: column nowrap;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  width: 100%;
`;

export const Overline = styled(DefaultOverline)`
  color: ${({ theme }) => theme.colors.fontEmphasized};
  margin-bottom: 0.25rem;
`;

export const Title = styled.h2`
  color: inherit;
  font-size: 1.3125rem;
  font-weight: inherit;
  letter-spacing: 0.01em;
  line-height: 1.075;
  margin-bottom: 0.3475rem;
`;

export const Subtitle = styled(Body).attrs(() => ({ $size: 'small' }))``;

export const Button = styled(DefaultButton)`
  margin-top: 0.9rem;
`;

export const Icon = styled(DefaultIcon).attrs(() => ({
  size: 16,
}))`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreTeal700
      : theme.colors.coreTeal300};
  margin-right: 0.5rem;
`;

export const Attending = styled(DefaultOverline)`
  font-size: 0.625rem;
  margin-top: 1.1875rem;
  text-align: center;

  ${media.phoneLandscape} {
    font-size: 0.5625rem;
  }
`;
