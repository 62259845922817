import { memo } from 'react';
import type { FC } from 'react';
import Timer from '../Timer';
import DurationSelect from './DurationSelect';
import {
  Container,
  ControlButtons,
  ProgressBar,
  ProgressCircle,
  Title,
  TimerFinishesAt,
  VolumeControls,
} from './styles';
import useConnect from './connect';

const SelfTimerPlayer: FC = () => {
  const {
    duration,
    finishTimeString,
    handleChangeDuration,
    handleClosePlayer,
    handleEnd,
    handleInit,
    handleToggleDisplayStatus,
    hideCircle,
    intervalSeconds,
    isMinimized,
    startTime,
  } = useConnect();

  const showProgressBar = isMinimized || hideCircle;

  return (
    <Container
      id="wu-player-timer"
      isMinimized={isMinimized}
      onClose={handleClosePlayer}
      onToggleDisplayStatus={handleToggleDisplayStatus}
    >
      <Title $isMinimized={isMinimized}>Timer</Title>
      <Timer
        duration={duration}
        interval={intervalSeconds}
        onEnd={handleEnd}
        onInit={handleInit}
        startTime={startTime}
      >
        {({
          handleSetVolume,
          handleTogglePlay,
          isPlaying,
          position,
          volume,
        }) => (
          <>
            {!isMinimized && (
              <DurationSelect
                duration={duration / 60}
                onChangeDuration={handleChangeDuration}
              />
            )}
            {showProgressBar ? (
              <ProgressBar
                duration={duration}
                isMinimized={isMinimized}
                isSeekDisabled
                position={position}
              />
            ) : (
              <ProgressCircle duration={duration} position={position} />
            )}
            <ControlButtons
              isMinimized={isMinimized}
              isPlaying={isPlaying}
              isSeekDisabled
              onPlayChange={handleTogglePlay}
            />
            {!isMinimized && (
              <VolumeControls onSetVolume={handleSetVolume} volume={volume} />
            )}
            <TimerFinishesAt $isMinimized={isMinimized}>
              Timer finishes at {finishTimeString}
            </TimerFinishesAt>
          </>
        )}
      </Timer>
    </Container>
  );
};

export default memo(SelfTimerPlayer);
