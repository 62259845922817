import styled from 'styled-components';
import { media } from 'styles/mixins';
import SectionHeader from 'components/DeprecatedSectionHeader';

export const Container = styled.div`
  height: 100%;

  ${media.phoneLandscape} {
    padding: ${({ theme }) => theme.measures.m};
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 32rem;

  ${media.tabletPortrait} {
    padding-bottom: 2.4rem;
  }
`;

export const Header = styled(SectionHeader)`
  ${media.tabletPortrait} {
    display: none;
  }
`;
