import { memo } from 'react';
import type { FC } from 'react';
import useConnect from './connect';
import Form from './Form';
import { Modal } from './styles';
import { initialValues } from './constants';

const ReserveRetreat: FC = () => {
  const { handleClose, handleSubmit, isOpen } = useConnect();

  return (
    <Modal
      descriptionId="reserve-retreat-description"
      fullscreenOnMobile
      hideClose
      onClose={handleClose}
      open={isOpen}
      titleId="reserve-retreat-title"
    >
      <Form
        descriptionId="reserve-retreat-description"
        initialValues={initialValues}
        onClose={handleClose}
        onSubmit={handleSubmit}
        titleId="reserve-retreat-title"
      />
    </Modal>
  );
};

export default memo(ReserveRetreat);
