import type { FetchPolicy } from '@apollo/client';
import { apolloClient } from 'services/api';
import { normalizeFeatureContent } from 'models/FeatureContent';
import { GetFeatureContentDocument } from '../generated';

const getFeatureContent = async (fetchPolicy: FetchPolicy = 'cache-first') => {
  const { data: featureContentData } = await apolloClient.query({
    query: GetFeatureContentDocument,
    errorPolicy: 'all',
    fetchPolicy,
  });

  return featureContentData?.feature_content
    ? normalizeFeatureContent(featureContentData.feature_content)
    : undefined;
};

export default getFeatureContent;
