import { memo } from 'react';
import type { FC } from 'react';
import { Container, Icon, Text } from './styles';
import { Props } from './types';

const InputListItemSmall: FC<Props> = ({
  active = false,
  iconName,
  text,
  ...rest
}) => {
  return (
    <Container $active={active} {...rest}>
      {iconName && <Icon name={iconName} />}
      <Text>{text}</Text>
    </Container>
  );
};

export default memo(InputListItemSmall);
