import { memo } from 'react';
import type { FC } from 'react';
import OptionItem from './OptionItem';
import { Title } from '../styles';
import useConnect from './connect';

const Autoplay: FC = () => {
  const { autoplay, handleDisableAutoplay, handleEnableAutoplay } =
    useConnect();

  return (
    <>
      <Title as="h2">Autoplay</Title>
      <OptionItem
        active={autoplay}
        onClick={autoplay ? undefined : handleEnableAutoplay}
        value="On"
      />
      <OptionItem
        active={!autoplay}
        onClick={autoplay ? handleDisableAutoplay : undefined}
        value="Off"
      />
    </>
  );
};

export default memo(Autoplay);
