import styled from 'styled-components';
import { media } from 'styles/mixins';
import { Display, Heading } from 'components/Typography';
import { Props } from '../types';

export const Container = styled.div`
  align-items: center;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  left: 3rem;
  max-width: 40vw;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  ${media.tabletPortrait} {
    align-items: stretch;
    flex-direction: column;
    overflow: hidden;
    max-width: 75vw;
  }
`;

export const ImageContent = styled.div<{
  $backgroundColor: Props['imgBackgroundColor'];
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: 0.125rem;
  flex: 0 0 auto;
  height: 3.2rem;
  margin-bottom: 0;
  margin-right: 0.8rem;
  overflow: hidden;
  width: 3.2rem;

  ${media.tabletPortrait} {
    display: none;
  }
`;

export const Image = styled.img<{
  $objectFit: Props['imgObjectFit'];
  $objectPosition: Props['imgObjectPosition'];
}>`
  display: block;
  height: 100%;
  object-fit: ${({ $objectFit }) => $objectFit};
  object-position: ${({ $objectPosition }) => $objectPosition};
  width: 100%;
`;

export const TextContent = styled.div`
  overflow: hidden;
  width: 100%;

  ${media.tabletPortrait} {
    margin: 0;
  }
`;

export const Title = styled(Display).attrs(() => ({
  $size: 'small',
}))`
  flex-shrink: 0;
  font-size: 0.9em;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Subtitle = styled(Heading)`
  color: ${({ theme }) => theme.colors.coreNeutral400};
  font-size: 0.8em;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${media.tabletPortrait} {
    display: none;
  }
`;
