import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import colorWithAlpha from 'lib/color';
import { media } from 'styles/mixins';

export const Loader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: flex-start;
  padding: 3rem 2rem 0 2rem;
`;

export const Progress = styled(CircularProgress).attrs(() => ({
  size: 40,
}))`
  color: ${({ theme }) => theme.colors.fontEmphasized};
`;

export const Container = styled.main`
  display: flex;

  strong {
    font-size: inherit;
    font-weight: ${({ theme }) => theme.font.weight.semibold};
  }

  ${media.tabletPortrait} {
    display: block;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  flex: 1;
  margin-bottom: 2rem;
`;

export const Information = styled.div`
  border-right: 1px solid
    ${({ theme }) =>
      colorWithAlpha({
        color: theme.colors.coreNeutralAbyss,
        alpha: 0.34,
      })};
  padding-right: 2.75rem;

  ${media.tabletPortrait} {
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid
    ${({ theme }) =>
      colorWithAlpha({
        color: theme.colors.coreNeutralAbyss,
        alpha: 0.34,
      })};
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.coreNeutralAbyss};
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.21;
  margin-bottom: 1rem;
`;

export const TrialPrice = styled.p`
  color: ${({ theme }) => theme.colors.coreBlue500};
  font-size: 2.25rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  line-height: 1.19;
  margin-bottom: 0.875rem;
`;

export const RefundInfo = styled.p`
  color: ${({ theme }) => theme.colors.coreNeutralAbyss};
  font-size: 17.3144px;
  letter-spacing: -0.03rem;
  line-height: 1.21;
  max-width: 29.75rem;
  padding-top: 1.5625rem;

  ${media.tabletPortrait} {
    max-width: none;
  }
`;
