import styled from 'styled-components';
import { Body as DefaultBody } from 'components/Typography';
import { MuiTextField } from 'components/Inputs/TextField/styles';
import DefaultIcon from 'components/Icon';

export const Container = styled.div``;

export const Content = styled.div<{ $readOnly: boolean }>`
  align-items: stretch;
  background: transparent;
  display: flex;
  flex-flow: column nowrap;
  pointer-events: ${({ $readOnly }) => ($readOnly ? 'none' : 'default')};
  user-select: ${({ $readOnly }) => ($readOnly ? 'none' : 'default')};
`;

export const TextField = styled(MuiTextField)``;

export const Icon = styled(DefaultIcon)`
  color: ${({ theme }) => theme.colors.fontEmphasized};
`;

export const Error = styled(DefaultBody).attrs(() => ({
  $size: 'small',
  $weight: 'medium',
}))`
  color: ${({ theme }) =>
    theme.name === 'LIGHT' ? theme.colors.coreRed700 : theme.colors.coreRed500};
  margin: ${({ theme }) => theme.measures.xxs} 0;
`;
