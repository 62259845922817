import styled from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';
import { media } from 'styles/mixins';
import { Display as DefaultDisplay } from 'components/Typography';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.measures.l};
  min-height: 3rem;
  padding: ${({ theme }) => theme.measures.m} 0;
  position: relative;

  ${media.tabletPortrait} {
    min-height: unset;
    padding: 0 0 ${({ theme }) => theme.measures.m} 0;
  }

  ${media.phonePortrait} {
    margin-bottom: ${({ theme }) => theme.measures.xs};
  }

  @media (max-height: 500px) {
    padding: 0;
  }
`;

export const Title = styled(DefaultDisplay).attrs(() => ({ $size: 'small' }))``;

export const BackLink = styled(DefaultLink)`
  color: inherit;
  left: 0;
  line-height: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  ${media.tabletPortrait} {
    display: none;
  }
`;
