import { useCallback, useMemo, useState } from 'react';
import type { KeyboardEvent, MouseEvent } from 'react';
import getCourseProgressPercent from 'lib/getCourseProgressPercent';
import { BookmarkType } from 'models/Bookmarks';
import { PlayerAutoplayType, PlayerSource } from 'models/Player';
import { DailyStatus, PackType } from 'graphql/generated';
import { setOptionsMenuVar } from 'graphql/reactive';
import useBookmarks from 'graphql/hooks/useBookmarks';
import useSettings from 'graphql/hooks/useSettings';
import useCourseActions from 'graphql/hooks/useCourseActions';
import useDailyActions from 'graphql/hooks/useDailyActions';
import useRefreshSettingsEffect from 'graphql/hooks/useRefreshSettingsEffect';

const useConnect = () => {
  const { bookmarks, loading: isLoadingBookmarks } = useBookmarks();
  const {
    loading: isLoadingSettings,
    settings: { dailyDurationChangeTo20Count, recentlyPlayed },
  } = useSettings();
  const { play } = useCourseActions();
  const { playDaily } = useDailyActions();

  const [selectedType, setSelectedType] = useState<BookmarkType>(
    BookmarkType.PRACTICE,
  );

  useRefreshSettingsEffect();

  const { bookmarkDailies, bookmarkLife, bookmarkPractice, bookmarkTheory } =
    useMemo(() => {
      const courses = bookmarks.courses.map((course) => ({
        ...course,
        progress: getCourseProgressPercent({
          audioLength: course.audioLength,
          recentlyPlayed,
          hash: course.hash,
          status: course.status,
        }),
      }));

      const dailies = bookmarks.dailies.map((daily) => ({
        ...daily,
        progress: getCourseProgressPercent({
          audioLength: dailyDurationChangeTo20Count
            ? daily.dailyAudio2Length
            : daily.dailyAudio1Length,
          hash: daily.hash,
          recentlyPlayed,
          status: daily.status || DailyStatus.unstarted,
        }),
      }));

      return {
        bookmarkDailies: dailies,
        bookmarkLife: courses.filter(
          ({ packType }) => packType === PackType.life,
        ),
        bookmarkPractice: courses.filter(
          ({ packType }) => packType === PackType.meditation,
        ),
        bookmarkTheory: courses.filter(
          ({ packType }) => packType === PackType.lesson,
        ),
      };
    }, [bookmarks, dailyDurationChangeTo20Count, recentlyPlayed]);

  const handlePracticeTrack = useCallback(
    async (courseId: string, packId: string) => {
      await play({
        autoplayType: PlayerAutoplayType.BOOKMARKS_PRACTICE,
        courseId,
        packId,
        source: PlayerSource.FAVORITES,
      });
    },
    [play],
  );

  const handleTheoryTrack = useCallback(
    async (courseId: string, packId: string) => {
      await play({
        autoplayType: PlayerAutoplayType.BOOKMARKS_THEORY,
        courseId,
        packId,
        source: PlayerSource.FAVORITES,
      });
    },
    [play],
  );

  const handleLifeTrack = useCallback(
    async (courseId: string, packId: string) => {
      await play({
        autoplayType: PlayerAutoplayType.BOOKMARKS_LIFE,
        courseId,
        packId,
        source: PlayerSource.FAVORITES,
      });
    },
    [play],
  );

  const handleDailyTrack = useCallback(
    async (dailyHash: string) => {
      await playDaily({
        autoplayType: PlayerAutoplayType.BOOKMARKS_DAILY,
        source: PlayerSource.FAVORITES,
        hash: dailyHash,
      });
    },
    [playDaily],
  );

  const handleSelectPracticeTab = useCallback(() => {
    setSelectedType(BookmarkType.PRACTICE);
  }, []);

  const handleSelectTheoryTab = useCallback(() => {
    setSelectedType(BookmarkType.THEORY);
  }, []);

  const handleSelectLifeTab = useCallback(() => {
    setSelectedType(BookmarkType.LIFE);
  }, []);

  const handleSelectDailyTab = useCallback(() => {
    setSelectedType(BookmarkType.DAILY);
  }, []);

  const handleOpenCourseOptionsMenu = useCallback(
    (
      event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLButtonElement>,
      courseId: string,
      packId: string,
    ) => {
      event.stopPropagation();
      setOptionsMenuVar({
        anchorEl: event.currentTarget,
        id: courseId,
        packId,
        type: 'course',
      });
    },
    [],
  );

  const handleOpenDailyOptionsMenu = useCallback(
    (
      event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLButtonElement>,
      dailyId: string,
    ) => {
      event.stopPropagation();
      setOptionsMenuVar({
        anchorEl: event.currentTarget,
        dailyId,
        sharedHash: undefined,
        type: 'daily',
      });
    },
    [],
  );

  return {
    bookmarkDailies,
    bookmarkLife,
    bookmarkPractice,
    bookmarkTheory,
    handleDailyTrack,
    handleLifeTrack,
    handleOpenCourseOptionsMenu,
    handleOpenDailyOptionsMenu,
    handlePracticeTrack,
    handleSelectDailyTab,
    handleSelectLifeTab,
    handleSelectPracticeTab,
    handleSelectTheoryTab,
    handleTheoryTrack,
    loading: isLoadingBookmarks || isLoadingSettings,
    selectedType,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
