import { memo } from 'react';
import type { FC } from 'react';
import { Container, Content, Description, Title } from '../styles';

const TrialExpired: FC = () => (
  <Container $isExpired>
    <Content>
      <Title $weight="bold">Your 7 day free trial has ended.</Title>
      <Description>
        Become a subscriber to continue your mindfulness journey.
      </Description>
    </Content>
  </Container>
);

export default memo(TrialExpired);
