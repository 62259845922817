import type { FC } from 'react';
import StripePaymentModal from 'containers/Modals/StripePayment';
import Form from './Form';
import { Container, Isotype, SectionHeader, Subtitle, Title } from './styles';
import type { Props } from './types';

const NewScholarship: FC<Props> = ({ isPublic = false }) => (
  <>
    {!isPublic && <SectionHeader showBackLink />}
    <Container $isPublic={isPublic}>
      {isPublic && <Isotype />}
      <Title>Scholarship Options</Title>
      <Subtitle>
        Choose a cost for one year of membership that starts today.
      </Subtitle>
      <Form />
      <StripePaymentModal />
    </Container>
  </>
);

export default NewScholarship;
