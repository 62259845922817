import { memo, type FC } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  WidgetCarouselImageSize,
  WidgetCarouselLayout,
} from 'graphql/generated';
import Control from './Control';
import Placeholder from './Placeholder';
import {
  COLUMN_SEPARATION_PX,
  ITEM_HEIGHT_HORIZONTAL_PX,
  ITEM_HEIGHT_LARGE_PX,
  ITEM_HEIGHT_MEDIUM_PX,
  ITEM_WIDTH_HORIZONTAL_PX,
  ITEM_WIDTH_LARGE_PX,
  ITEM_WIDTH_MEDIUM_PX,
} from './constants';
import { Container, Item, List } from './styles';
import type { Props } from './types';

const Carousel: FC<Props> = ({
  carouselRef,
  className,
  data,
  imageSize = WidgetCarouselImageSize.medium,
  isLoading,
  layout = WidgetCarouselLayout.vertical,
  onItemClick,
}) => {
  if (isLoading) {
    return (
      <Placeholder
        className={className}
        imageSize={imageSize}
        layout={layout}
      />
    );
  }

  if (!data || data.length === 0) {
    return null;
  }

  let itemHeight = ITEM_HEIGHT_MEDIUM_PX;
  if (imageSize === WidgetCarouselImageSize.large) {
    itemHeight = ITEM_HEIGHT_LARGE_PX;
  } else if (layout === WidgetCarouselLayout.horizontal) {
    itemHeight = ITEM_HEIGHT_HORIZONTAL_PX;
  }

  let itemWidth = ITEM_WIDTH_MEDIUM_PX;
  if (imageSize === WidgetCarouselImageSize.large) {
    itemWidth = ITEM_WIDTH_LARGE_PX;
  } else if (layout === WidgetCarouselLayout.horizontal) {
    itemWidth = ITEM_WIDTH_HORIZONTAL_PX;
  }

  return (
    <Container className={className}>
      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            height={itemHeight}
            itemCount={data.length}
            itemData={data}
            itemSize={COLUMN_SEPARATION_PX + itemWidth}
            layout="horizontal"
            outerRef={carouselRef}
            width={width}
          >
            {({ index, style }) => (
              <div style={style}>
                <Item
                  {...data[index]}
                  imageSize={imageSize}
                  layout={layout}
                  onClick={onItemClick}
                />
              </div>
            )}
          </List>
        )}
      </AutoSizer>
    </Container>
  );
};

export default memo(Carousel);
export { Control };
