import { memo } from 'react';
import soundRoomTone from 'assets/audio/selftimer/room_tone.mp3';
import soundBeginEndBell from 'assets/audio/selftimer/begin_end_bell.mp3';
import soundIntervalBell from 'assets/audio/selftimer/interval_bell.mp3';
import { Props } from './types';
import { Audio } from './styles';
import useConnect from './connect';

const Timer = ({
  children,
  duration,
  interval,
  onComplete,
  onEnd,
  onInit,
  onInterval,
  onPause,
  onPlay,
  onVolumeChange,
  startTime,
}: Props) => {
  const connect = useConnect({
    duration,
    interval,
    onComplete,
    onEnd,
    onInit,
    onInterval,
    onPause,
    onPlay,
    onVolumeChange,
    startTime,
  });

  return (
    <>
      <Audio loop ref={connect.audioAmbientRef} src={soundRoomTone} />
      <Audio ref={connect.audioInitRef} src={soundBeginEndBell} />
      <Audio ref={connect.audioIntervalRef} src={soundIntervalBell} />
      {children(connect)}
    </>
  );
};

export default memo(Timer);
