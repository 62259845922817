import { FC } from 'react';
import Desktop from './Desktop';
import Mobile from './Mobile';
import { Props } from './types';
import useConnect from './connect';

const Navigation: FC<Props> = () => {
  const { isTabletPortrait, matchNoNavigationRoute } = useConnect();

  if (matchNoNavigationRoute) {
    return null;
  }

  return isTabletPortrait ? <Mobile /> : <Desktop />;
};

export default Navigation;
