import { memo } from 'react';
import type { FC } from 'react';
import useCountdown from 'lib/useCountdown';
import { formatDurationShort } from 'lib/formatTime';
import { Button } from '../styles';
import type { Props } from './types';

const Start: FC<Props> = ({ slug, waitingRoomAvailableDate }) => {
  const { countdown } = useCountdown(waitingRoomAvailableDate);

  return (
    <Button state={{ screen: 'home_cta' }} to={`/practice/retreats/${slug}`}>
      {countdown > 0
        ? `Starts in ${formatDurationShort(countdown / 1000)}`
        : 'Start Retreat'}
    </Button>
  );
};

export default memo(Start);
