import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Container = styled.main`
  align-items: center;
  display: flex;
  font-family: 'Circular Std', Arial, Helvetica, sans-serif, serif;
  justify-content: center;
  min-height: 100vh;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: 30rem;
  text-align: center;
  transform: translateY(-2rem);
  width: 90%;

  ${media.phoneLandscape} {
    transform: translateY(-1rem);
  }

  ${media.phonePortrait} {
    transform: translateY(-2rem);
  }
`;

export const Logo = styled.img`
  height: 15rem;
  width: 15rem;

  ${media.phoneLandscape} {
    height: 13rem;
    width: 13rem;
  }

  ${media.phonePortrait} {
    height: 15rem;
    width: 15rem;
  }
`;

export const Title = styled.h1`
  font-size: 2.2rem;
  font-weight: bold;
`;

export const Description = styled.p`
  font-size: 1.4rem;
  margin-top: 1rem;
`;
