import styled from 'styled-components';
import DefaultIcon from 'components/Icon';
import {
  Heading as DefaultHeading,
  Body as DefaultBody,
} from 'components/Typography';
import { media } from 'styles/mixins';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-top: 5rem;
  text-align: center;
`;

export const Icon = styled(DefaultIcon).attrs(() => ({ size: 40 }))`
  display: block;
`;

export const Title = styled(DefaultHeading).attrs(() => ({ $weight: 'bold' }))`
  margin-top: 1rem;

  span {
    font-size: inherit;
    font-weight: inherit;
    white-space: nowrap;
  }

  ${media.phonePortrait} {
    max-width: 16rem;
  }
`;

export const Subtitle = styled(DefaultBody).attrs(() => ({
  $size: 'medium',
  $weight: 'regular',
}))`
  margin-top: 0.5625rem;
  max-width: 35rem;
  text-align: center;

  ${media.phonePortrait} {
    max-width: 18rem;
  }
`;
