import { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import type { PaymentRequest } from '@stripe/stripe-js';
import type { FormikHelpers, FormikProps } from 'formik';
import useSubscriptionActions from 'graphql/hooks/useSubscriptionActions';
import type { LogicProps, FormValues } from './types';

const useConnect = ({
  isPublic,
  planAmount,
  planId,
  planInterval,
  priceId,
  promoCode,
  screen,
  sharedBy,
  trialDuration,
  userEmail,
}: LogicProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const { create, purchaseLifetime } = useSubscriptionActions();
  const navigate = useNavigate();

  const [isDelayingRender, setIsDelayingRender] = useState<boolean>(true);
  const [isCreatingPaymentRequest, setIsCreatingPaymentRequest] =
    useState<boolean>(true);
  const [hasApplePay, setHasApplePay] = useState<boolean>(false);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null,
  );

  const formikRef = useRef<FormikProps<FormValues>>(null);

  const initialValues: FormValues = useMemo(
    () => ({
      email: userEmail || '',
      submitError: '',
    }),
    [userEmail],
  );

  useEffect(() => {
    if (!trialDuration) {
      navigate('/checkout', { replace: true });
    }
  }, [trialDuration, navigate]);

  useEffect(() => {
    setTimeout(() => {
      setIsDelayingRender(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: `Waking Up`,
          amount: planAmount,
        },
        requestPayerName: false,
        requestPayerEmail: false,
        requestPayerPhone: false,
        requestShipping: false,
      });

      pr.on('paymentmethod', async ({ complete, paymentMethod }) => {
        if (formikRef.current) {
          const {
            errors,
            setErrors,
            values: { email },
          } = formikRef.current;

          if (!!email && !errors.email) {
            try {
              let subscriptionChargeDate: string;
              if (planInterval === 'lifetime') {
                const data = await purchaseLifetime({
                  amount: planAmount,
                  email,
                  isPublic,
                  paymentMethodId: paymentMethod.id,
                  priceId,
                });
                subscriptionChargeDate = data.subscriptionChargeDate;
              } else {
                const data = await create({
                  email,
                  isPublic,
                  paymentMethodId: paymentMethod.id,
                  planAmount,
                  planId,
                  promoCode,
                  sharedBy,
                  screen,
                });
                subscriptionChargeDate = data.subscriptionChargeDate;
              }

              if (isPublic) {
                navigate('/subscription/success', {
                  state: {
                    chargeDate: subscriptionChargeDate,
                    email,
                  },
                });
              } else {
                navigate('/account/subscription');
              }

              complete('success');
            } catch (error) {
              complete('fail');
              setErrors({
                submitError:
                  error?.message ||
                  `The payment hasn't been processed. Try it again in a few seconds.`,
              });
            }
          } else {
            complete('invalid_payer_email');
            setErrors({ submitError: 'Subscription email not found.' });
          }
        }
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment()
        .then((result) => {
          if (result) {
            if (result.applePay) {
              setHasApplePay(true);
            }
            setPaymentRequest(pr);
          }
          setIsCreatingPaymentRequest(false);
        })
        .catch(() => {
          setIsCreatingPaymentRequest(false);
        });
    }
  }, [
    create,
    isPublic,
    navigate,
    planAmount,
    planId,
    planInterval,
    priceId,
    promoCode,
    purchaseLifetime,
    screen,
    sharedBy,
    stripe,
  ]);

  const handleSubmit = useCallback(
    async (values: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
      try {
        if (stripe && elements) {
          const card = elements.getElement('card');

          if (!card) {
            throw new Error(
              'Unable to get card information. Try again in a few seconds',
            );
          }

          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card,
            billing_details: isPublic ? { email: values.email } : undefined,
          });

          if (error || !paymentMethod) {
            throw new Error(error ? error.message : undefined);
          }

          let subscriptionChargeDate: string;

          if (planInterval === 'lifetime') {
            const data = await purchaseLifetime({
              amount: planAmount,
              email: values.email,
              isPublic,
              paymentMethodId: paymentMethod.id,
              priceId,
            });
            subscriptionChargeDate = data.subscriptionChargeDate;
          } else {
            const data = await create({
              email: values.email,
              isPublic,
              paymentMethodId: paymentMethod.id,
              planAmount,
              planId,
              promoCode,
              sharedBy,
              screen,
              trialDuration,
            });
            subscriptionChargeDate = data.subscriptionChargeDate;
          }

          if (isPublic) {
            navigate('/subscription/success', {
              state: {
                chargeDate: subscriptionChargeDate,
                email: values.email,
              },
            });
          } else {
            navigate('/account/subscription');
          }
        }
      } catch (error) {
        setErrors({
          submitError:
            error?.message ||
            `The payment hasn't been processed. Try it again in a few seconds.`,
        });
      }
    },
    [
      create,
      elements,
      isPublic,
      navigate,
      planAmount,
      planId,
      planInterval,
      priceId,
      promoCode,
      purchaseLifetime,
      screen,
      sharedBy,
      stripe,
      trialDuration,
    ],
  );

  return {
    elements,
    formikRef,
    handleSubmit,
    hasApplePay,
    initialValues,
    isLoading: isDelayingRender || isCreatingPaymentRequest,
    paymentRequest,
    stripe,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
