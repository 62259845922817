import styled from 'styled-components';
import MaterialSlider from '@mui/material/Slider';
import colorWithAlpha from 'lib/color';
import DefaultVolumeIcon from 'components/Icons/Volume';

export const Container = styled.div`
  align-items: center;
  display: inline-flex;
  flex: 0 1 auto;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: 0.75rem;
  margin-left: 1.6rem;
  min-width: 6rem;
`;

export const Label = styled.label`
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  white-space: nowrap;
`;

export const Volume = styled.button.attrs(() => ({ type: 'button' }))`
  align-items: center;
  background: transparent;
  border-radius: 50%;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  outline-offset: 3px;
`;

export const VolumeIcon = styled(DefaultVolumeIcon).attrs(() => ({
  size: 16,
}))``;

export const Slider = styled(MaterialSlider)`
  &.MuiSlider-root {
    color: ${({ theme }) => theme.colors.coreNeutralWhite};
    display: block;
    flex: 1 1 auto;
    margin-left: 0.75rem;
    padding: 5px 0;
    width: auto;
  }

  & .MuiSlider-rail,
  & .MuiSlider-track,
  & .MuiSlider-thumb {
    background-color: ${({ theme }) => theme.colors.coreNeutralWhite};
    border: none;
  }

  & .MuiSlider-thumb {
    height: 10px;
    width: 10px;

    &::after {
      top: -8px;
      left: -8px;
      right: -8px;
      bottom: -8px;
    }

    &.Mui-focusVisible,
    &:hover {
      box-shadow: 0px 0px 0px 6px
        ${({ theme }) =>
          colorWithAlpha({ color: theme.colors.fontEmphasized, alpha: 0.35 })};
    }

    &.Mui-active {
      box-shadow: 0px 0px 0px 8px
        ${({ theme }) =>
          colorWithAlpha({ color: theme.colors.fontEmphasized, alpha: 0.35 })};
    }

    &:focus {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }
`;
