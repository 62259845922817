import { memo } from 'react';
import type { FC } from 'react';
import ControlButtonsMaximized from './ControlButtonsMaximized';
import ControlButtonsMinimized from './ControlButtonsMinimized';
import { Props } from './types';

const ControlButtons: FC<Props> = ({ isMinimized = false, ...rest }) =>
  isMinimized ? (
    <ControlButtonsMinimized {...rest} />
  ) : (
    <ControlButtonsMaximized {...rest} />
  );

export default memo(ControlButtons);
