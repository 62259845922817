import { memo, type FC } from 'react';
import { isPreviewMode } from 'services/storage';
import { FamilyMemberType } from 'graphql/generated';
import { Button } from './styles';
import type { Props } from './types';
import useConnect from './connect';

const SubscriptionModify: FC<Props> = ({ subscriptionFamilyMember }) => {
  const { handleChangePaymentMethod, handleManageFamilyMembers } = useConnect();

  if (subscriptionFamilyMember === FamilyMemberType.MEMBER) {
    return null;
  }

  return (
    <>
      <Button
        id="stripe-change-payment-card-button"
        onClick={handleChangePaymentMethod}
      >
        Change Payment Card
      </Button>
      {isPreviewMode() && subscriptionFamilyMember === FamilyMemberType.OWNER && (
        <Button
          id="stripe-manage-family-members-button"
          onClick={handleManageFamilyMembers}
          type="button"
        >
          Manage Family Members
        </Button>
      )}
    </>
  );
};

export default memo(SubscriptionModify);
