import { useMemo } from 'react';
import { useQuery, type QueryHookOptions } from '@apollo/client';
import algoliasearch from 'algoliasearch';
import { ALGOLIA_APP_ID } from 'services/algolia';
import { normalizeTag } from 'models/Tag';
import { AlgoliaDocument, type AlgoliaQuery } from '../generated';

const useAlgolia = (options?: QueryHookOptions<AlgoliaQuery>) => {
  const { data, loading } = useQuery(AlgoliaDocument, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    ...options,
  });

  const { searchClient, tags } = useMemo(
    () =>
      data
        ? {
            searchClient: algoliasearch(ALGOLIA_APP_ID, data.algoliaSearchKey),
            tags: data.algoliaSearchTerms.tags?.map(normalizeTag) || [],
          }
        : {
            tags: [],
          },
    [data],
  );

  return { searchClient, tags, loading };
};

export default useAlgolia;
export type UseAlgolia = ReturnType<typeof useAlgolia>;
