import styled from 'styled-components';
import { motion } from 'framer-motion';
import { media } from 'styles/mixins';
import DefaultLogoBlackStacked from 'components/LogoBlackStacked';
import DefaultVideoPlayer from 'components/VideoPlayer';
import DefaultButton from 'components/Button';

export const Container = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
}))`
  align-items: center;
  background-color: #f4f4f8;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 90vh;
  padding: 1rem 0;
`;

export const Logo = styled(DefaultLogoBlackStacked).attrs(() => ({
  size: 220,
}))`
  margin-bottom: 3rem;
  width: 220px;

  ${media.phonePortrait} {
    margin-bottom: 1rem;
    width: 180px;
  }
`;

export const Text = styled.div`
  font-size: 2.3125rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 1.2;
  margin-bottom: 2.4rem;
  text-align: center;
  width: 740px;

  ${media.tabletPortrait} {
    width: 700px;
  }

  ${media.phoneLandscape} {
    font-size: 1.4rem;
    width: 28rem;
  }

  ${media.phonePortrait} {
    font-size: 1.3rem;
    width: 95%;
  }
`;

export const NoWrap = styled.span`
  font-size: inherit;
  font-weight: inherit;
  white-space: nowrap;
`;

export const VideoPlayer = styled(DefaultVideoPlayer)`
  height: 405px;
  margin-bottom: 2rem;
  max-width: 90%;
  width: 720px;

  ${media.phoneLandscape} {
    height: 240px;
    width: 426px;
  }

  ${media.phonePortrait} {
    height: calc(90vw * (9 / 16));
    width: 90vw;
  }
`;

export const Buttons = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  /* width: 860px; */
  width: 400px;

  ${media.phoneLandscape} {
    flex-flow: column nowrap;
    /* width: 390px; */
    width: 350px;
  }

  ${media.phonePortrait} {
    align-items: stretch;
    width: 90vw;
  }
`;

export const Button = styled(DefaultButton).attrs(() => ({
  disableElevation: true,
}))`
  flex: 1 1 auto;
  margin: 0 0.5rem;
  min-width: 0;
  text-align: center;

  ${media.phoneLandscape} {
    margin: 0.4rem 0;
  }

  ${media.phonePortrait} {
    flex: 0 1 auto;
    padding: 0.8rem 3rem;
  }
`;
