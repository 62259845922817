import type { FC } from 'react';
import { Props } from './types';

const NumberPlaybackSpeed12xLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M4.727 8h-1.59l-1.98 1.254v1.5l1.831-1.149h.047V16h1.692V8zm2.91 8.102a.95.95 0 00.938-.938.949.949 0 00-.938-.93.934.934 0 100 1.868zM10.366 16h5.703v-1.383h-3.36v-.055l1.168-1.144c1.645-1.5 2.086-2.25 2.086-3.16 0-1.387-1.133-2.367-2.847-2.367-1.68 0-2.832 1.004-2.829 2.574h1.606c-.004-.766.48-1.235 1.21-1.235.704 0 1.227.438 1.227 1.141 0 .637-.39 1.074-1.117 1.774l-2.847 2.636V16zm8.774-6h-1.718l1.718 3-1.78 3h1.718l1.164-2.074L21.426 16h1.699l-1.785-3 1.738-3h-1.707l-1.129 2.098L19.14 10z"
      fill="currentColor"
    />
  </svg>
);

export default NumberPlaybackSpeed12xLight;
