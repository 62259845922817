/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Button = styled.button<{ $isLoading: boolean }>`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.fontBase};
  cursor: ${({ $isLoading }) => ($isLoading ? 'default' : 'pointer')};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  height: 2.8125rem;
  letter-spacing: 0.1em;
  line-height: 1.5;
  margin-bottom: 1.25rem;
  opacity: 0.7;
  padding: 0.75rem;
  position: relative;
  text-align: center;
  text-decoration-line: underline;
  text-transform: uppercase;
  width: auto;

  :disabled {
    opacity: 0.4;
  }
`;
