import { memo, type FC } from 'react';
import useMe from 'graphql/hooks/useMe';
import IntroCourse from './IntroCourse';
import Daily from './Daily';
import { Props } from './types';

const WidgetUpNext: FC<Props> = ({ widgetId }) => {
  const { isDailyUnlocked } = useMe();

  return isDailyUnlocked ? (
    <Daily widgetId={widgetId} />
  ) : (
    <IntroCourse widgetId={widgetId} />
  );
};

export default memo(WidgetUpNext);
