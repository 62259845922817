import type { FC } from 'react';
import type { Props } from './types';

const LifeLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m12.621 3 .357-.425a.555.555 0 0 0-.714 0l.357.425Zm-.789 1.453c.32-.3.592-.543.79-.718a40.24 40.24 0 0 1 3.323 3.302c.921 1.035 1.83 2.187 2.506 3.327.682 1.153 1.09 2.234 1.09 3.142 0 2.696-1.775 4.698-3.997 5.643.61-.692 1.011-1.62 1.011-2.738 0-.69-.267-1.403-.613-2.048-.35-.654-.812-1.292-1.262-1.844a20.02 20.02 0 0 0-1.763-1.89l-.031-.028-.009-.008-.003-.003-.374.41.373-.41a.555.555 0 0 0-.746 0l.373.41-.374-.41-.003.003-.009.008-.032.03-.116.109a20.042 20.042 0 0 0-1.647 1.78c-.45.552-.91 1.19-1.261 1.844-.346.645-.613 1.357-.613 2.047 0 1.023.337 1.888.863 2.56-2.036-1.007-3.607-2.929-3.607-5.465 0-.908.408-1.99 1.09-3.142.677-1.14 1.586-2.292 2.507-3.327a40.227 40.227 0 0 1 2.534-2.584ZM12.622 3l-.358-.425-.002.002-.004.004-.017.014-.065.056-.243.21A41.319 41.319 0 0 0 8.47 6.3c-.947 1.063-1.906 2.274-2.632 3.5-.719 1.213-1.246 2.502-1.246 3.707 0 4.628 4.278 7.36 8.03 7.36s8.03-2.732 8.03-7.36c0-1.205-.526-2.494-1.245-3.707-.726-1.226-1.686-2.437-2.633-3.5a41.326 41.326 0 0 0-3.706-3.648l-.065-.056-.017-.014-.005-.004-.001-.001L12.62 3Zm-.273 8.922c.053-.055.104-.106.151-.152l.152.151c.32.325.744.78 1.168 1.3.425.52.839 1.097 1.144 1.666.31.579.481 1.1.481 1.524a3 3 0 0 1-2.945 3.034 3.003 3.003 0 0 1-2.945-3.034c0-.423.17-.944.481-1.523.305-.569.72-1.145 1.144-1.667a18.9 18.9 0 0 1 1.169-1.3Z"
      fill="currentColor"
    />
  </svg>
);

export default LifeLight;
