import styled from 'styled-components';
import DefaultNavTabList from 'components/NavTabList';
import { media } from 'styles/mixins';

export const Container = styled.div``;

export const Image = styled.img`
  display: block;
  height: 24rem;
  margin-bottom: 0.625rem;
  width: 24rem;

  ${media.phonePortrait} {
    height: 21rem;
    width: 21rem;
  }
`;

export const Name = styled.h2`
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.01em;
  line-height: 1.5;
  margin-bottom: 1px;
`;

export const NavTabList = styled(DefaultNavTabList)`
  background-color: ${({ theme }) => theme.colors.backgroundBase};
  margin-top: 1.625rem;
  margin-bottom: ${({ theme }) => theme.measures.s};
`;

export const Packs = styled.div`
  ${media.tabletPortrait} {
    margin-left: -1rem;
    margin-right: -1rem;
  }
`;
