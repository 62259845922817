import { useCallback } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { trackMediaEvent } from 'services/analytics';
import { PlayerSource } from 'models/Player';
import { resetPlayerTypeVar } from 'graphql/reactive';
import { getCourse } from 'graphql/requests';
import useMe from 'graphql/hooks/useMe';
import usePlayerActions from 'graphql/hooks/usePlayerActions';
import { Button, Buttons, Container } from './styles';
import { Props } from './types';

const FinalScreen: FC<Props> = ({ className, courseHash, packId }) => {
  const { isSubscribed } = useMe();
  const { openCoursePlayer } = usePlayerActions();
  const navigate = useNavigate();

  const handleListenFullLesson = useCallback(async () => {
    if (courseHash) {
      trackMediaEvent('Media Request Full Length', courseHash);

      resetPlayerTypeVar();

      const course = await getCourse(courseHash);
      const isFree = course?.isFree ?? false;

      if (isFree || isSubscribed) {
        setTimeout(
          () =>
            openCoursePlayer({
              hash: courseHash,
              packId,
              source: PlayerSource.MEDIA_PREVIEW,
            }),
          750,
        );
      } else {
        navigate('/account/subscription');
      }
    }
  }, [courseHash, isSubscribed, navigate, openCoursePlayer, packId]);

  return (
    <Container className={className}>
      <Buttons>
        <Button iconName="playCircleLight" onClick={handleListenFullLesson}>
          Listen to full session
        </Button>
      </Buttons>
    </Container>
  );
};

export default FinalScreen;
