import { UseConnect } from '../connect';

export type Props = {
  onSubmit: UseConnect['handleSubmit'];
};

export enum Step {
  message,
  thanks,
}

export type FormFields = {
  email: string;
  name?: string;
  message?: string;
  step: Step;
  submitError?: string;
};
