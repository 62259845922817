import { memo, type FC } from 'react';
import CreditCard from './CreditCard';
import CreditCardForm from './Form';
import {
  Actions,
  AnimatedContainer,
  Button,
  Error,
  Icon,
  Modal,
  Price,
  Separator,
  Subtitle,
  Text,
  Title,
} from './styles';
import useConnect from './connect';

const UpgradeToAnnual: FC = () => {
  const {
    brand,
    handleChangeShowCardForm,
    handleClose,
    handleSubmit,
    handleUpgrade,
    isOpen,
    isUpdating,
    last4,
    showCardForm,
    submitError,
  } = useConnect();

  return (
    <Modal
      descriptionId="upgrade-to-annual-description"
      fullscreenOnMobile
      onClose={handleClose}
      open={isOpen}
      titleId="upgrade-to-annual-title"
    >
      <Title id="upgrade-to-annual-title">
        Upgrade to annual today—and save.
      </Title>
      <Subtitle>
        Switch from monthly to annual, and receive a $19.99 credit toward your
        subscription.
      </Subtitle>
      <Text id="upgrade-to-annual-description">
        Starting today, you’ll get a full 12 months of unlimited access for
        $79.99. That means you’ll <strong>save $140</strong> on our web-only
        rate over the course of the next year.
      </Text>
      <Separator />
      <Price>
        $79.99<span>for the first year</span>
      </Price>
      <Actions>
        {showCardForm ? (
          <AnimatedContainer key="form">
            <CreditCardForm
              onCancel={handleChangeShowCardForm}
              onSubmit={handleSubmit}
            />
          </AnimatedContainer>
        ) : (
          <AnimatedContainer key="button-and-card">
            <Button
              $isUpdating={isUpdating}
              disabled={showCardForm}
              onClick={handleUpgrade}
            >
              {isUpdating ? (
                <>
                  <Icon name="arrowCounterClockwiseLight" />
                  Processing
                </>
              ) : (
                'Subscribe Now'
              )}
            </Button>
            <CreditCard
              brand={brand}
              last4={last4}
              onChangeShowCardForm={handleChangeShowCardForm}
            />
          </AnimatedContainer>
        )}
        {submitError && <Error>{submitError}</Error>}
      </Actions>
      <Subtitle>
        You can cancel anytime, and receive a full refund, no questions asked.
      </Subtitle>
      <Text>
        After your first year, you will be charged the then-standard rate.
      </Text>
    </Modal>
  );
};

export default memo(UpgradeToAnnual);
