import { apolloClient } from 'services/api';
import { normalizeDaily } from 'models/Daily';
import type { QueryOptions } from 'types/utils';
import {
  GetSharedDailyDocument,
  type GetSharedDailyQuery,
  type GetSharedDailyQueryVariables,
} from '../generated';

const getSharedDaily = async (
  options: QueryOptions<GetSharedDailyQueryVariables, GetSharedDailyQuery> = {},
) => {
  const { data } = await apolloClient.query({
    query: GetSharedDailyDocument,
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    ...options,
  });

  return data?.sharedDaily ? normalizeDaily(data.sharedDaily) : undefined;
};

export default getSharedDaily;
