import { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { setToken, deleteToken, getToken } from 'services/storage';
import {
  registerAnalyticsUser,
  unregisterAnalyticsUser,
} from 'services/analytics';
import { MeDocument } from 'graphql/generated';
import {
  setAudioSpeed,
  setBackgroundSoundsVar,
  setShowTrialBannerVar,
  setThemeVar,
} from 'graphql/reactive';
import {
  checkMediaInterrupted,
  getRemoteConfig,
  getSettings,
} from 'graphql/requests';
import useAuth from 'graphql/hooks/useAuth';
import useAuthActions from 'graphql/hooks/useAuthActions';

const useConnect = () => {
  const { isAuthenticated, init } = useAuth();
  const { setInit } = useAuthActions();
  const apolloClient = useApolloClient();

  useEffect(() => {
    const handleAuth = async () => {
      try {
        setInit(false);

        if (!getToken()) {
          throw new Error();
        }

        const { data } = await apolloClient.query({ query: MeDocument });

        if (!data?.me) {
          throw new Error();
        }

        if (data.me.token) {
          setToken(data.me.token);
        } else {
          deleteToken();
        }

        const {
          backgroundAudioEnabled,
          backgroundAudioProgress,
          backgroundAudioTrackIndex,
          backgroundAudioVolumeWeb,
          hasPresentedHome,
          playbackSpeed,
          theme,
        } = await getSettings();
        setShowTrialBannerVar(hasPresentedHome);
        setBackgroundSoundsVar({
          backgroundAudioEnabled,
          backgroundAudioProgress,
          backgroundAudioTrackIndex,
          backgroundAudioVolume:
            backgroundAudioVolumeWeb > 1
              ? backgroundAudioVolumeWeb / 100
              : backgroundAudioVolumeWeb,
        });
        setAudioSpeed(Number(playbackSpeed));

        if (theme) {
          setThemeVar(theme);
        }

        const user = data.me;

        registerAnalyticsUser({ id: user.id, uuid: user.uuid });

        await getRemoteConfig();
        await checkMediaInterrupted();
      } catch (e) {
        deleteToken();
        unregisterAnalyticsUser();
      } finally {
        setInit(true);
      }
    };

    handleAuth();
  }, [apolloClient, setInit]);

  return { isAuthenticated, init };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
