import styled from 'styled-components';
import DefaultFooter from 'components/PublicPageFooter';
import { MAX_CONTENT_WIDTH } from 'styles/metrics';
import { media } from 'styles/mixins';

export const Container = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.coreTeal950};
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${MAX_CONTENT_WIDTH};
  min-height: 100vh;
  padding: 0 2rem;

  ${media.phoneLandscape} {
    padding: 0 1.25rem;
  }
`;

export const Footer = styled(DefaultFooter)`
  width: 100%;

  ${media.phonePortrait} {
    margin: 0 -1.25rem;
    width: 100vw;
  }
`;
