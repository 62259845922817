import styled from 'styled-components';
import DefaultButton from 'components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`;

export const Button = styled(DefaultButton)`
  &&& {
    margin-bottom: 1.25rem;
    min-width: 18.75rem;
    max-width: 100%;
    width: 100%;
  }
`;
