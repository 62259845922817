import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import capitalize from 'lib/capitalize';
import { trackEvent } from 'services/analytics';
import { trackAppsflyerEvent } from 'services/analytics/appsflyer';
import { isStripeCheckoutSessionEnabled } from 'services/storage';
import { SubscriptionPeriod } from 'models/SubscriptionPlan';
import { SubscriptionPlanType } from 'graphql/generated';
import useModalActions from 'graphql/hooks/useModalActions';
import useSubscriptionPlans from 'graphql/hooks/useSubscriptionPlans';
import type { ConnectProps } from './types';

const SCREEN = 'web_checkout_logged_out';

const useConnect = ({
  code,
  email,
  originalAmount,
  period,
  priceId,
  userId,
}: ConnectProps) => {
  const { lifetimeEmail, loading, plans } = useSubscriptionPlans(
    { variables: { code, price_id: priceId } },
    { variables: { id: userId, price_id: priceId } },
  );
  const { openStripePaymentModal } = useModalActions();
  const { pathname, search } = useLocation();

  const {
    experiment: queryExperiment,
    shared_by,
    id,
    variant: queryVariant,
  } = parse(search);
  const sharedBy =
    shared_by && typeof shared_by === 'string' ? shared_by : undefined;
  const slashedOriginalPrice = pathname.includes('joinnow');
  const experiment =
    queryExperiment && typeof queryExperiment === 'string'
      ? queryExperiment
      : undefined;
  const variant =
    queryVariant && typeof queryVariant === 'string' ? queryVariant : undefined;

  const hasInvalidCode =
    !!code &&
    plans.length > 0 &&
    plans.every((plan) => plan.amount === plan.originalAmount);

  const plan = loading
    ? undefined
    : plans.find((v) => {
        if (
          period === SubscriptionPeriod.MONTH ||
          period === SubscriptionPeriod.MENU_MONTH
        ) {
          return v.interval === 'month';
        }

        if (
          period === SubscriptionPeriod.LIFETIME ||
          period === SubscriptionPeriod.MENU_LIFETIME
        ) {
          return v.interval === 'lifetime';
        }

        if (period === SubscriptionPeriod.FAMILY) {
          return v.type === 'family';
        }

        return v.interval === 'year';
      });
  const planId = plan?.id ?? '';
  const planAmount = plan?.amount ?? 0;
  const planOriginalAmount = (originalAmount || plan?.originalAmount) ?? 0;
  const planInterval = plan?.interval || '';
  const planPeriod =
    plan?.interval === 'lifetime'
      ? 'Lifetime'
      : `${capitalize(plan?.interval || 'year')}ly`;
  const planSku = plan?.sku ?? '';
  const planType = plan?.type ?? SubscriptionPlanType.standard;

  useEffect(() => {
    if (planId) {
      const experimentData =
        experiment && variant ? { experiment, variant } : {};

      trackEvent('Purchase Display Checkout Options', {
        ...experimentData,
        ID: planSku || planId,
        screen: SCREEN,
        type: planPeriod?.toLowerCase(),
        uuid: id,
      });
    }
  }, [experiment, id, planId, planPeriod, planSku, variant]);

  useEffect(() => {
    trackAppsflyerEvent('pba', 'event', {
      eventType: 'EVENT',
      eventName: 'af_initiated_checkout',
    });
  }, []);

  const handleOpenStripePayment = useCallback(() => {
    const experimentData = experiment && variant ? { experiment, variant } : {};

    trackEvent('Purchase Initiate Checkout', {
      ...experimentData,
      screen: SCREEN,
      subscription_option: planSku,
    });

    openStripePaymentModal({
      ...experimentData,
      email:
        planType === SubscriptionPlanType.lifetime && lifetimeEmail
          ? lifetimeEmail
          : email,
      isPublic: !email,
      isStripeCheckoutSession: isStripeCheckoutSessionEnabled(),
      planAmount,
      planId,
      planInterval,
      planType,
      priceId,
      promoCode: code,
      screen: 'checkout',
      sharedBy,
    });
  }, [
    code,
    email,
    experiment,
    lifetimeEmail,
    openStripePaymentModal,
    planAmount,
    planId,
    planInterval,
    planSku,
    planType,
    priceId,
    sharedBy,
    variant,
  ]);

  return {
    handleOpenStripePayment,
    hasInvalidCode,
    isLoading: loading,
    nPlans: plans.length,
    planAmount,
    planId,
    planInterval,
    planOriginalAmount,
    planPeriod,
    planType,
    search,
    slashedOriginalPrice,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
