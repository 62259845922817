import { useCallback } from 'react';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import { ModalType } from 'graphql/reactive';

const useConnect = () => {
  const { isOpen, payload } = useModal(ModalType.DIALOG);
  const { closeModal } = useModalActions();

  const buttonsPosition = payload?.buttonsPosition || 'right';
  const cancelButtonId = payload?.cancelButtonId;
  const cancelButtonText = payload?.cancelButtonText || 'Cancel';
  const confirmButtonId = payload?.confirmButtonId;
  const confirmButtonText = payload?.confirmButtonText || 'Confirm';
  const description = payload?.description;
  const onCancel = payload?.onCancel;
  const onConfirm = payload?.onConfirm;
  const title = payload?.title;

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return {
    buttonsPosition,
    cancelButtonId,
    cancelButtonText,
    confirmButtonId,
    confirmButtonText,
    description,
    handleClose,
    handleCancel: onCancel,
    handleConfirm: onConfirm,
    open: isOpen,
    title,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
