import styled from 'styled-components';
import { motion } from 'framer-motion';
import colorWithAlpha from 'lib/color';
import { media } from 'styles/mixins';
import DefaultButton from 'components/Button';
import { Body, Display, Heading } from 'components/Typography';

export const Container = styled(motion.div)`
  background: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralWhite
      : colorWithAlpha({
          color: theme.colors.coreNeutralBlack,
          alpha: 0.25,
        })};
  border-radius: ${({ theme }) => theme.radius.md};
  overflow: hidden;
  padding: ${({ theme }) => `${theme.measures.x} ${theme.measures.x}`};
  position: relative;

  ${media.phoneLandscape} {
    padding: ${({ theme }) => `${theme.measures.s} ${theme.measures.s}`};
  }
`;

const ClearButton = styled.button`
  background: transparent;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: block;
  line-height: 1;
  outline: none;
`;

export const IconButton = styled(ClearButton)`
  position: absolute;
  right: 1.1875rem;
  top: 1.1875rem;

  ${media.phoneLandscape} {
    right: ${({ theme }) => theme.measures.s};
    top: ${({ theme }) => theme.measures.s};
  }
`;

export const Content = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
}))`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
`;

export const TitleDesktop = styled(Display)`
  width: 33rem;
  white-space: pre-line;
`;

export const TitleMobile = styled(Body).attrs(() => ({
  $size: 'large',
  $weight: 'bold',
}))`
  margin: 0 2.25rem;
  white-space: pre-line;
`;

export const DescriptionDesktop = styled(Heading)`
  color: ${({ theme }) =>
    theme.name === 'LIGHT' && theme.colors.coreNeutral700};
  margin-top: ${({ theme }) => theme.measures.s};
  white-space: pre-line;
  width: 30rem;
`;

export const DescriptionMobile = styled(Body)`
  margin-top: ${({ theme }) => theme.measures.s};
  white-space: pre-line;
`;

export const Button = styled(DefaultButton)`
  margin-top: ${({ theme }) => theme.measures.l};
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  width: auto;

  ${media.phoneLandscape} {
    width: 100%;
  }
`;
