import { UserProgressDataFragment } from 'graphql/generated';

export const normalizeUserProgress = (progress: UserProgressDataFragment) => ({
  id: progress.id || '',
  userId: progress.user_id || '',
  progressId: progress.progress_id || '',
  progressHash: progress.progress_hash || '',
  title: progress.title || '',
  courseType: progress.course_type || '',
  durationSeconds: progress.duration_seconds ?? null,
  finishedAt: progress.finished_at || '',
  progressType: progress.progress_type || '',
  createdAt: (progress.created_at as string) || '',
});

export type UserProgress = ReturnType<typeof normalizeUserProgress>;
