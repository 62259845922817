import type { FC } from 'react';
import { A11y, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Pagination from './Pagination';
import Slide from './Slide';
import { Slider, Button, Modal } from './styles';
import { BREAKPOINTS, SLIDES } from './constants';
import useConnect from './connect';

import 'swiper/css';
import 'swiper/css/a11y';

const OnboardingSlidesModal: FC = () => {
  const {
    handleClose,
    handleInit,
    handleNextSlide,
    handleSetIndex,
    handleSlideChange,
    index,
    isOpen,
    swiperRef,
  } = useConnect();

  return (
    <Modal
      descriptionId="onboarding-slides-description"
      disableBackdropClick
      fullscreenOnMobile
      hideClose
      onClose={handleClose}
      open={isOpen}
      titleId="onboarding-slides-title"
    >
      <Slider>
        <Swiper
          allowTouchMove
          breakpoints={BREAKPOINTS}
          keyboard={{
            enabled: true,
          }}
          modules={[A11y, Keyboard]}
          onInit={handleInit}
          onSlideChange={handleSlideChange}
          // @ts-ignore
          ref={swiperRef}
        >
          {SLIDES.map(({ title, description, image }, i) => (
            <SwiperSlide key={title}>
              <Slide
                description={description}
                descriptionId="onboarding-slides-description"
                image={image}
                index={i}
                title={title}
                titleId="onboarding-slides-title"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Slider>
      <Pagination onSelectIndex={handleSetIndex} selectedIndex={index} />
      <Button onClick={handleNextSlide}>CONTINUE</Button>
    </Modal>
  );
};

export default OnboardingSlidesModal;
