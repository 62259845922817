import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MenuItem, Typography } from '@mui/material';

export const Item = styled(MenuItem)<{
  $active?: boolean;
  component?: typeof Link | string;
  href?: string;
  to?: string;
}>`
  &.MuiMenuItem-root {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    min-height: 2rem;

    ${({ $active, theme }) =>
      $active &&
      `color: ${
        theme.name === 'LIGHT'
          ? theme.colors.fontEmphasized
          : theme.colors.coreTeal500
      }`}
  }
`;

export const Text = styled(Typography)`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: 0.01em;
  line-height: 1.4286;

  &.MuiTypography-root {
    margin: 0 0.5rem;
  }
`;
