/* eslint-disable import/prefer-default-export */
import { captureMessage } from '@sentry/react';
import { isProduction } from 'config';

export const trackFacebookEvent = (
  type: string,
  eventName: string,
  params?: Record<string, any>,
) => {
  if (isProduction) {
    try {
      window.fbq(type, eventName, params);
    } catch (error) {
      captureMessage(
        `Unable to track Facebook event (ERROR: ${
          error?.message || 'unknown'
        })`,
      );
    }
  }
};
