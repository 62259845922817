import type { FC } from 'react';
import Icon from 'components/Icon';
import NavTab from 'components/NavTab';
import CourseList from 'components/CourseList';
import { PackType } from 'graphql/generated';
import NoRecentlyPlayed from './NoRecentlyPlayed';
import { BackLink, Header, NavTabList, Title } from './styles';
import useConnect from './connect';

const RecentlyPlayedPage: FC = () => {
  const {
    handleOpenOptionsMenu,
    handleSelectTheoryTab,
    handleSelectLifeTab,
    handleSelectPracticeTab,
    loading,
    handleRecentlyPlayedLifeTrack,
    handleRecentlyPlayedPracticeTrack,
    handleRecentlyPlayedTheoryTrack,
    recentlyPlayedLife,
    recentlyPlayedPractice,
    recentlyPlayedTheory,
    selectedType,
  } = useConnect();

  const hasPractice = !!recentlyPlayedPractice.length;
  const hasTheory = !!recentlyPlayedTheory.length;
  const hasLife = !!recentlyPlayedLife.length;
  const hasItems = hasPractice || hasTheory || hasLife;
  const isPractice = selectedType === PackType.meditation;
  const isTheory = selectedType === PackType.lesson;
  const isLife = selectedType === PackType.life;

  let selectedCourseListProps = {};

  if (isPractice) {
    selectedCourseListProps = {
      ariaLabelledBy: 'practice-tab',
      courses: recentlyPlayedPractice,
      id: 'practice-pannel',
      setTrack: handleRecentlyPlayedPracticeTrack,
    };
  } else if (isTheory) {
    selectedCourseListProps = {
      ariaLabelledBy: 'theory-tab',
      courses: recentlyPlayedTheory,
      id: 'theory-pannel',
      setTrack: handleRecentlyPlayedTheoryTrack,
    };
  } else if (isLife) {
    selectedCourseListProps = {
      ariaLabelledBy: 'life-tab',
      courses: recentlyPlayedLife,
      id: 'life-pannel',
      setTrack: handleRecentlyPlayedLifeTrack,
    };
  }

  if (!hasItems && !loading)
    return (
      <>
        <Header>
          <BackLink aria-label="Go back to home" to="/">
            <Icon name="arrowLeftLight" />
          </BackLink>
          <Title>Recently Played</Title>
        </Header>
        <NoRecentlyPlayed />
      </>
    );

  return (
    <>
      <Header>
        <BackLink aria-label="Go back to home" to="/">
          <Icon name="arrowLeftLight" />
        </BackLink>
        <Title>Recently Played</Title>
      </Header>
      {!loading && (
        <NavTabList>
          <>
            {hasPractice && (
              <NavTab
                aria-controls="practice-pannel"
                id="practice-tab"
                isActive={isPractice}
                onClick={handleSelectPracticeTab}
              >
                Practice {!loading && `(${recentlyPlayedPractice.length})`}
              </NavTab>
            )}
            {hasTheory && (
              <NavTab
                aria-controls="theory-pannel"
                id="theory-tab"
                isActive={isTheory}
                onClick={handleSelectTheoryTab}
              >
                Theory {!loading && `(${recentlyPlayedTheory.length})`}
              </NavTab>
            )}
            {hasLife && (
              <NavTab
                aria-controls="life-pannel"
                id="life-tab"
                isActive={isLife}
                onClick={handleSelectLifeTab}
              >
                Life {!loading && `(${recentlyPlayedLife.length})`}
              </NavTab>
            )}
          </>
        </NavTabList>
      )}
      {(loading || hasItems) && (
        <CourseList
          displayCourseImages
          hideTopBorder
          isLoading={loading}
          onOpenOptionsMenu={handleOpenOptionsMenu}
          role="tabpanel"
          showPretitle
          showProgress
          {...selectedCourseListProps}
        />
      )}
    </>
  );
};

export default RecentlyPlayedPage;
