import { memo } from 'react';
import type { FC } from 'react';
import Icon from 'components/Icon';
import { FEATURES } from './constants';
import { Container, Feature, FeatureText } from './styles';

const Features: FC = () => (
  <Container>
    {FEATURES.map((v) => (
      <Feature key={v}>
        <Icon aria-hidden="true" color="#2565F5" name="checkLight" size={16} />
        <FeatureText dangerouslySetInnerHTML={{ __html: v }} />
      </Feature>
    ))}
  </Container>
);

export default memo(Features);
