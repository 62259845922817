/* eslint-disable import/prefer-default-export */
import { AppConfig } from 'config';

export const GIFT_CARD_IMAGES: Record<
  string,
  { imageCaption: string; imageSrc: string }
> = {
  all: {
    imageCaption: 'Artwork by Amber Vittoria / David Habben / Jeffrey Thelin',
    imageSrc: `${AppConfig.imagesURL}/gift-cards.jpg`,
  },
  '3m': {
    imageCaption: 'Artwork by Amber Vittoria',
    imageSrc: `${AppConfig.imagesURL}/gift-card-3months.png`,
  },
  '1y': {
    imageCaption: 'Artwork by David Habben',
    imageSrc: `${AppConfig.imagesURL}/gift-card-1year.png`,
  },
  l: {
    imageCaption: 'Artwork by Jeffrey Thelin',
    imageSrc: `${AppConfig.imagesURL}/gift-card-lifetime.png`,
  },
};
