import styled from 'styled-components';
import { CardElement as StripeCardElement } from '@stripe/react-stripe-js';
import Text from 'components/Text';
import DefaultButton from 'components/Button';

export const Container = styled.form.attrs(() => ({ noValidate: true }))``;

export const Title = styled(Text)`
  color: inherit;
  text-align: center;
`;

export const CardElement = styled(StripeCardElement)`
  &.StripeElement {
    background-color: ${({ theme }) => theme.colors.coreNeutralWhite};
    color: inherit;
    height: 40px;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    transition: box-shadow 150ms ease;
  }

  &.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  &.StripeElement--invalid {
    border-color: #fa755a;
  }

  &.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
`;

export const FormErrors = styled(Text)`
  color: ${({ theme }) => theme.colors.coreRed500};
  margin-top: ${({ theme }) => theme.measures.xs};
`;

export const Button = styled(DefaultButton)`
  &&& {
    margin-top: 1.25rem;
  }
`;
