import type { FC } from 'react';
import { Props } from './types';

const PauseLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M18.75 3.75h-3.375a.75.75 0 00-.75.75v15c0 .414.336.75.75.75h3.375a.75.75 0 00.75-.75v-15a.75.75 0 00-.75-.75zm-10.125 0H5.25a.75.75 0 00-.75.75v15c0 .414.336.75.75.75h3.375a.75.75 0 00.75-.75v-15a.75.75 0 00-.75-.75z"
      fill="currentColor"
    />
  </svg>
);

export default PauseLight;
