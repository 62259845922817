import { memo } from 'react';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { Container, Icon, LoaderContainer, Loader, Label } from './styles';
import type { Props } from './types';

const Button: FC<Props> = ({
  children,
  disabled,
  iconName,
  isLoading,
  mode = 'default',
  onClick,
  to,
  ...rest
}) => {
  let element: 'button' | 'a' | typeof Link = 'button';
  if (to) {
    if (typeof to === 'string') {
      if (to[0] === '/') {
        element = Link;
      } else {
        element = 'a';
      }
    } else if (to.pathname?.[0] === '/') {
      element = Link;
    } else {
      element = 'a';
    }
  }

  const href = element === 'a' ? String(to) : undefined;
  const target = element === 'a' ? '_blank' : undefined;

  return (
    <Container
      $isLoading={isLoading}
      as={element}
      disabled={isLoading || disabled}
      href={href}
      mode={mode}
      onClick={onClick}
      target={target}
      to={to}
      {...rest}
    >
      {isLoading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      <Label $hidden={isLoading}>
        {iconName && <Icon name={iconName} />}
        {children}
      </Label>
    </Container>
  );
};

export default memo(Button);
