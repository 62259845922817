import { memo, type FC } from 'react';
import { Carousel, Container, FadeOut, HomeSectionHeader } from './styles';
import type { Props } from './types';
import useConnect from './connect';

const WidgetCarousel: FC<Props> = ({
  id,
  imageSize,
  items,
  layout,
  subtitle,
  title,
}) => {
  const {
    carouselItems,
    carouselRef,
    handleControlClick,
    handleItemClick,
    loading,
  } = useConnect({ id, imageSize, items });

  if (!loading && (!items || items.length === 0)) {
    return null;
  }

  return (
    <Container>
      <HomeSectionHeader
        onControlClick={handleControlClick}
        subtitle={subtitle}
        title={title}
      />
      <Carousel
        carouselRef={carouselRef}
        data={carouselItems}
        imageSize={imageSize}
        isLoading={loading}
        layout={layout}
        onItemClick={handleItemClick}
      />
      <FadeOut />
    </Container>
  );
};

export default memo(WidgetCarousel);
