import {
  CircularProgress as MUICircularProgress,
  type CircularProgressProps,
} from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

export const CircularProgress = styled(
  MUICircularProgress,
).attrs<CircularProgressProps>(() => ({ size: 36, color: 'inherit' }))`
  color: ${({ theme }) => theme.colors.fontButtonEmphasized};
`;
