import { FC } from 'react';
import { Root, Title } from './styles';
import { Props } from './types';

const Page: FC<Props> = ({ children, title }) => (
  <Root>
    <Title data-cypress-id="info-title">{title}</Title>
    {children}
  </Root>
);

export default Page;
