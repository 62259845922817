import type { FC } from 'react';
import { Subtitle } from './styles';
import { Description, Title } from '../styles';
import type { Props } from './types';

const Thanks: FC<Props> = ({ descriptionId, email, titleId }) => (
  <>
    <Title id={titleId}>Thank you</Title>
    <Subtitle>Your support request has been received</Subtitle>
    <Description id={descriptionId}>
      You will receive updates via email to: {email}
    </Description>
  </>
);

export default Thanks;
