import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultSectionHeader from 'components/DeprecatedSectionHeader';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;

  ${media.tabletPortrait} {
    padding: 1.4rem;
  }

  ${media.phoneLandscape} {
    padding: ${({ theme }) => theme.measures.m};
  }
`;

export const SectionHeader = styled(DefaultSectionHeader)``;

export const Content = styled.div`
  color: inherit;
  max-width: 26rem;
`;

export const Title = styled.h2`
  color: inherit;
  font-size: 1.125rem;
  margin: 1.25rem;
  text-align: center;
  text-transform: uppercase;
`;
