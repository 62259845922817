import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultLink from 'components/Link';
import Icon from 'components/Icon';

export const Container = styled.div`
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;
  max-width: 1184px;
  min-height: 100vh;
  padding: 0 2rem 0 2rem;

  ${media.phoneLandscape} {
    padding: 0 1.25rem 0 1.25rem;
  }
`;

export const Image = styled.img`
  align-self: center;
  display: block;
  height: 3.8984rem;
  margin-top: 2.5625rem;
  width: 2.875rem;

  ${media.phoneLandscape} {
    height: 2.9814rem;
    width: 2.2rem;
    margin-top: 2rem;
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding: 2rem 0;
  text-align: center;

  ${media.phoneLandscape} {
    padding-bottom: 4rem;
  }
`;

export const Title = styled.h1`
  font-size: 1.5625rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.21;
  margin-bottom: 0.375rem;

  ${media.phonePortrait} {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }
`;

export const Description = styled.p`
  font-size: 1rem;
  line-height: 1.21;
  margin-bottom: 1rem;
  max-width: 32rem;

  ${media.phonePortrait} {
    font-size: 0.9rem;
    margin-bottom: 3rem;
    max-width: 95%;
  }
`;

export const QRCodeContainer = styled.div`
  background: ${({ theme }) => theme.colors.coreNeutralAurora};
  border: 1px solid #fbfbfb;
  border-radius: 0.25rem;
  box-sizing: border-box;
  box-shadow: 0rem 0.25rem 0.5rem -0.25rem rgba(0, 0, 0, 0.16),
    0rem 1rem 2.625rem 0.125rem rgba(0, 0, 0, 0.14),
    0rem 1.5rem 2.25rem rgba(0, 0, 0, 0.12);
  margin-bottom: 3.625rem;
  padding: 0.625rem;
`;

export const CameraIcon = styled(Icon).attrs(() => ({ name: 'cameraLight' }))`
  margin-right: 0.6875rem;
`;

export const ScanMe = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.coreNeutralAbyss};
  display: flex;
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  justify-content: center;
  line-height: 1.21;
  margin-top: 1.125rem;
`;

export const StoresContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const BadgeLink = styled(DefaultLink)`
  color: inherit;
  display: block;
  line-height: 0;
  margin: 0 0.25rem;
  text-decoration: none;
`;

export const AppStoreBadge = styled(Icon).attrs(() => ({
  color: '#2565f5',
  name: 'appStoreBadge',
}))`
  ${media.phonePortrait} {
    border-radius: 0.25rem;
  }
`;

export const GooglePlayBadge = styled(Icon).attrs(() => ({
  color: '#2565f5',
  name: 'googlePlayBadge',
}))`
  ${media.phonePortrait} {
    border-radius: 0.25rem;
  }
`;

export const WebappLink = styled(DefaultLink)`
  color: #2565f5;
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.21;
  margin-top: 2.65rem;

  ${media.phonePortrait} {
    margin-bottom: 5rem;
  }
`;
