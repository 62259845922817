import type { FC } from 'react';
import { connectStateResults } from 'react-instantsearch-dom';
import Hits from './Hits';
import TopSearches from '../TopSearches';
import { Body, MailTo, NoResults } from './styles';
import type { Props } from './types';

const Results: FC<Props> = ({ onClick, searchResults, searchState }) => {
  const hasSearch = searchResults && searchResults.query && searchState.query;
  const hasResults = searchResults && searchResults.nbHits !== 0;

  if (hasSearch && !hasResults) {
    return (
      <NoResults role="region">
        <Body>0 RESULTS</Body>
        <Body>
          Sorry, no results. Try making your search less specific or using less
          words, or try one of these
        </Body>
        <Body>
          If you can't find what you are looking for our{' '}
          <MailTo href="mailto:support@wakingup.com">
            support@wakingup.com
          </MailTo>{' '}
          would love to hear from you during the beta period
        </Body>
        <TopSearches onClick={onClick} showTitle={false} />
      </NoResults>
    );
  }

  return hasSearch ? (
    <Hits onClick={onClick} />
  ) : (
    <TopSearches onClick={onClick} />
  );
};

export default connectStateResults(Results);
