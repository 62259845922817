import { useCallback, useMemo } from 'react';
import { trackEvent, trackWidgetEvent } from 'services/analytics';
import formatCents from 'lib/formatCents';
import { INVITE_A_FRIEND_CTA, JOIN_WU_CTA } from 'models/HomeCta';
import useMe from 'graphql/hooks/useMe';
import useSubscriptionPlans from 'graphql/hooks/useSubscriptionPlans';
import useHomeCta from 'graphql/hooks/useHomeCta';
import type { ConnectProps } from './types';

const useConnect = ({ widgetId }: ConnectProps) => {
  const { homeCta, loading: isLoading } = useHomeCta();
  const { isElegibleForSharing, isSubscribed } = useMe();
  const { plans } = useSubscriptionPlans();

  const isApiCta = !isLoading && !!homeCta;
  const showInviteAFriend = isSubscribed && isElegibleForSharing;

  const yearlyPlan = plans.find(({ interval }) => interval === 'year');
  const priceByYear = yearlyPlan?.amount
    ? formatCents(yearlyPlan.amount)
    : '...';

  const { buttonText, deepLink, description, title } = useMemo(() => {
    if (!homeCta) {
      if (showInviteAFriend) {
        return INVITE_A_FRIEND_CTA;
      }
      return {
        ...JOIN_WU_CTA,
        description: JOIN_WU_CTA.description.replace(
          '#{price_value}',
          priceByYear,
        ),
      };
    }

    return {
      buttonText: homeCta.buttonText,
      deepLink: homeCta.deepLink,
      description: homeCta.description.replace('#{price_value}', priceByYear),
      title: homeCta.title,
    };
  }, [homeCta, priceByYear, showInviteAFriend]);

  const handleButtonClick = useCallback(() => {
    trackEvent('Home CTA Click', {
      button_text: buttonText,
      deep_link: deepLink,
      message: description,
      title,
    });

    if (widgetId) {
      trackWidgetEvent(widgetId, { deep_link: deepLink, name: title });
    }
  }, [buttonText, deepLink, description, title, widgetId]);

  const handleClose = useCallback(() => {
    if (isApiCta) {
      trackEvent('Home CTA Dismiss', {
        button_text: buttonText,
        deep_link: deepLink,
        message: description,
        title,
      });
    }
  }, [buttonText, deepLink, description, isApiCta, title]);

  return {
    buttonText,
    deepLink,
    description,
    handleButtonClick,
    handleClose,
    isApiCta,
    isLoading,
    title,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
