import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { formatErrorMessage } from '../helpers';
import { CreateTicketDocument, InviteEmailDocument } from '../generated';

const useMiscActions = () => {
  const [createTicketMutation, { loading: isCreatingTicket }] =
    useMutation(CreateTicketDocument);
  const [inviteEmailMutation, { loading: isSendingInviteEmail }] =
    useMutation(InviteEmailDocument);

  const createTicket = useCallback(
    async ({ subject, message }: { subject: string; message: string }) => {
      try {
        await createTicketMutation({
          variables: { data: { subject, message } },
        });
      } catch (error) {
        throw new Error(
          formatErrorMessage(error, 'It was not possible to create the ticket'),
        );
      }
    },
    [createTicketMutation],
  );

  const sendInviteEmail = useCallback(
    async ({
      recipientEmail,
      recipientName,
      message,
    }: {
      recipientEmail: string;
      recipientName?: string;
      message?: string;
    }) => {
      try {
        await inviteEmailMutation({
          variables: {
            data: {
              email: recipientEmail,
              name: recipientName,
              message,
            },
          },
        });
      } catch (error) {
        throw new Error(
          formatErrorMessage(
            error,
            'An error occurred sending the email, try again later',
          ),
        );
      }
    },
    [inviteEmailMutation],
  );

  return {
    createTicket,
    sendInviteEmail,
    isCreatingTicket,
    isSendingInviteEmail,
  };
};

export default useMiscActions;
export type UseMiscActions = ReturnType<typeof useMiscActions>;
