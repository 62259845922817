import { useEffect, useRef } from 'react';
import DOMPurify from 'dompurify';
import nextUntil from 'lib/nextUntil';
import { LogicProps } from './types';

const useLogic = ({ data }: LogicProps) => {
  const ref = useRef<HTMLDivElement>(null!);

  useEffect(() => {
    if (data && ref.current) {
      const formatContent = () => {
        const content = ref.current;
        content.innerHTML = '';

        const originalData = document.createElement('div');
        originalData.innerHTML = DOMPurify.sanitize(data);

        const headings = originalData.querySelectorAll('h1');

        if (headings.length > 0) {
          headings.forEach((heading, i) => {
            const answerId = `answer-${i + 1}`;

            const faq = document.createElement('div');
            faq.classList.add(`faq`);

            const question = document.createElement('button');
            const questionTitle = document.createElement('h2');
            questionTitle.innerText = heading.innerText;
            question.className = `question`;
            question.appendChild(questionTitle);
            question.setAttribute('aria-expanded', 'false');
            question.setAttribute('aria-controls', answerId);

            question.addEventListener('click', () => {
              question.classList.toggle('active');
              const isExpanded =
                question.getAttribute('aria-expanded') === 'true';
              question.setAttribute(
                'aria-expanded',
                isExpanded ? 'false' : 'true',
              );

              const answer = content.querySelector(
                `#${answerId}`,
              ) as HTMLElement | null;
              if (answer) {
                answer.classList.toggle('active');
                answer.style.display = isExpanded ? 'none' : 'block';
                if (
                  answer.style.maxHeight &&
                  answer.style.maxHeight !== '0px'
                ) {
                  answer.style.maxHeight = '0px';
                } else {
                  answer.style.maxHeight = `${answer.scrollHeight}px`;
                }
              }
            });

            const answerElements = nextUntil(heading, 'h1');
            const answer = document.createElement('div');
            answer.className = `answer`;
            answer.id = answerId;
            answer.style.display = 'none';
            answer.append(...answerElements);

            faq.append(question, answer);
            content.appendChild(faq);
          });
        }
      };

      setTimeout(formatContent, 1);
    }
  }, [data]);

  return {
    ref,
  };
};

export default useLogic;
export type UseLogic = ReturnType<typeof useLogic>;
