import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultWordmark from 'components/Wordmark';
import DefaultLink from 'components/Link';
import Icon from 'components/Icon';

export const Container = styled.footer`
  color: ${({ theme }) => theme.colors.fontDeemphasized};
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
  padding-top: 3rem;

  ${media.phonePortrait} {
    background-color: #edeeef;
    margin: 0 -1.4rem;
    padding: 2.2rem 1.4rem 6.8rem;
    width: 100vw;
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  ${media.phonePortrait} {
    align-items: flex-start;
    flex-direction: column-reverse;
  }
`;

export const Logo = styled.div`
  margin-top: 0.6rem;

  ${media.phonePortrait} {
    margin-top: 1.8188rem;
  }
`;

export const Wordmark = styled(DefaultWordmark)`
  display: block;
  width: 10rem;

  ${media.phonePortrait} {
    width: 8.75rem;
  }
`;

export const Copyright = styled.div`
  color: ${({ theme }) => theme.colors.fontDeemphasized};
  font-size: 0.75rem;
  line-height: 1.25;
  margin-top: 0.6231rem;
  opacity: 0.8;

  ${media.phoneLandscape} {
    font-size: 0.625rem;
    line-height: 1.2;
  }
`;

export const BadgeLinks = styled.div`
  align-items: center;
  display: flex;
  margin-left: 2.5rem;

  ${media.phonePortrait} {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin-left: 0;
    padding-bottom: 2.3506rem;
    width: 100%;
  }
`;

export const BadgeLink = styled(DefaultLink)`
  color: inherit;
  display: block;
  line-height: 0;
  margin-left: 0.9375rem;
  text-decoration: none;

  &:first-child {
    margin-left: 0;
  }
`;

export const AppStoreBadge = styled(Icon).attrs(() => ({
  color: '#6f6f6f',
  name: 'appStoreBadge',
  size: 115.29,
}))`
  ${media.phonePortrait} {
    border-radius: ${({ theme }) => theme.measures.xxs};
    color: #2565f5;
  }
`;

export const GooglePlayBadge = styled(Icon).attrs(() => ({
  color: '#6f6f6f',
  name: 'googlePlayBadge',
  size: 128.09,
}))`
  ${media.phonePortrait} {
    border-radius: ${({ theme }) => theme.measures.xxs};
    color: #2565f5;
  }
`;
