import { memo } from 'react';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { Container, Icon } from './styles';
import { Props } from './types';

const ButtonSecondary: FC<Props> = ({
  children,
  disabled = false,
  iconName = 'xCircleLight',
  mode = 'default',
  to,
  ...rest
}) => (
  <Container
    as={to ? (Link as unknown as undefined) : undefined}
    disabled={disabled}
    mode={mode}
    to={to}
    {...rest}
  >
    <Icon $disabled={disabled} $mode={mode} name={iconName} />
    {children}
  </Container>
);

export default memo(ButtonSecondary);
