import styled, { css } from 'styled-components';
import { media } from 'styles/mixins';

export const Title1 = styled.div`
  font-family: ${({ theme }) => theme.font.family.druk};
  font-size: 3.75rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  letter-spacing: 0;
  line-height: 0.867;
`;

export const Title2 = styled.div`
  font-family: ${({ theme }) => theme.font.family.druk};
  font-size: 3rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  letter-spacing: 0;
  line-height: 0.867;
`;

export const Display = styled.div<{ $size?: 'large' | 'medium' | 'small' }>`
  font-family: ${({ theme }) => theme.font.family.inter};
  font-size: ${({ $size }) => {
    switch ($size) {
      case 'large':
        return '2rem';
      case 'small':
        return '1.25rem';
      case 'medium':
      default:
        return '1.5rem';
    }
  }};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.01em;
  line-height: 1.5;
`;

export const Heading = styled.div<{ $weight?: 'regular' | 'bold' }>`
  font-family: ${({ theme }) => theme.font.family.inter};
  font-size: 1rem;
  font-weight: ${({ $weight, theme }) =>
    $weight === 'bold' ? theme.font.weight.bold : theme.font.weight.book};
  letter-spacing: 0.01em;
  line-height: 1.25;
`;

export const Body = styled.p<{
  $size?: 'large' | 'medium' | 'small';
  $weight?: 'regular' | 'bold';
}>`
  font-family: ${({ theme }) => theme.font.family.inter};
  font-weight: ${({ $weight, theme }) =>
    $weight === 'bold' ? theme.font.weight.bold : theme.font.weight.book};
  letter-spacing: 0.01em;
  ${({ $size }) => {
    switch ($size) {
      case 'large':
        return css`
          font-size: 1.125rem;
          line-height: 1.444;

          ${media.tabletPortrait} {
            font-size: 1rem;
            line-height: 1.5;
          }
        `;
      case 'small':
        return css`
          font-size: 0.75rem;
          line-height: 1.334;
        `;
      case 'medium':
      default:
        return css`
          font-size: 0.875rem;
          line-height: 1.429;
        `;
    }
  }}
`;

export const Overline = styled.p`
  font-family: ${({ theme }) => theme.font.family.inter};
  font-size: 0.5625rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.2em;
  line-height: 1.334;
  text-transform: uppercase;
`;

export const ButtonPrimary = css`
  font-family: ${({ theme }) => theme.font.family.inter};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  letter-spacing: 0.1em;
  line-height: 1.5;
  text-transform: uppercase;
`;

export const ButtonSecondary = css`
  font-family: ${({ theme }) => theme.font.family.inter};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  letter-spacing: 0.05em;
  line-height: 1.5;
  text-decoration: underline;
`;

export const InputRegular = css`
  font-family: ${({ theme }) => theme.font.family.inter};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: 0;
  line-height: 1.25;
`;

export const InputLabelActivated = css`
  font-family: ${({ theme }) => theme.font.family.inter};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.02em;
  line-height: 1.25;
  text-transform: uppercase;
`;

export const InputLabelRest = css`
  font-family: ${({ theme }) => theme.font.family.inter};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: 0.02em;
  line-height: 1.25;
  text-transform: uppercase;
`;

export const NavLabel = css`
  font-family: ${({ theme }) => theme.font.family.inter};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  letter-spacing: 0.1em;
  line-height: 1.5;
  text-transform: uppercase;
`;
