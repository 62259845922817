import { useCallback } from 'react';
import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { AppConfig } from 'config';
import { trackEvent } from 'services/analytics';
import { getEmail } from 'services/storage';
import useAuthActions from 'graphql/hooks/useAuthActions';
import Footer from 'components/PublicPageFooter';
import { Button, Container, Icon, Image, Main, Text, Title } from './styles';

const MagicLinkLoginExpired: FC = () => {
  const { isLoadingGetPin, resend } = useAuthActions();
  const { search } = useLocation();

  const email = getEmail();

  const handleResend = useCallback(async () => {
    if (email) {
      await resend({ email, queryString: search });
      trackEvent('Login Resend Magic Link Request');
    }
  }, [email, resend, search]);

  return (
    <Container>
      <Image alt="" src={`${AppConfig.imagesURL}/welcome-head-2.png`} />
      <Main>
        <Icon name="linkBreakLight" />
        <Title>This link has expired</Title>
        <Text>
          We’re sorry but your sign in link has expired. Please try again
        </Text>
        {email && (
          <Button isLoading={isLoadingGetPin} onClick={handleResend}>
            Resend
          </Button>
        )}
      </Main>
      <Footer />
    </Container>
  );
};

export default MagicLinkLoginExpired;
