import styled from 'styled-components';
import colorWithAlpha from 'lib/color';
import { media } from 'styles/mixins';

export const Container = styled.div`
  overflow-x: visible;
  min-height: 2.3125rem;
  position: relative;
`;

export const Scrollable = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
  padding-right: ${({ theme }) => theme.measures.m};
  padding-top: ${({ theme }) => theme.measures.m};

  &::-webkit-scrollbar {
    height: 0;
  }

  > :not(:last-child) {
    margin-right: ${({ theme }) => theme.measures.x};
  }

  ${media.tabletPortrait} {
    bottom: 0;
    left: 0;
    position: absolute;
    right: -1rem;
  }
`;

export const FadeOut = styled.div`
  background: linear-gradient(
    90deg,
    rgba(244, 244, 244, 0) 0%,
    ${({ theme }) => theme.colors.backgroundBase} 100%
  );
  height: calc(100% - 1px);
  position: absolute;
  right: -1rem;
  top: 0;
  width: 3rem;
`;

export const BorderBottom = styled.div`
  background: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? colorWithAlpha({
          color: theme.colors.coreNeutralBlack,
          alpha: 0.1,
        })
      : colorWithAlpha({
          color: theme.colors.coreNeutralWhite,
          alpha: 0.15,
        })};
  bottom: 0;
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;

  ${media.tabletPortrait} {
    box-shadow: ${({ theme }) =>
      `1rem 0 0 0 ${
        theme.name === 'LIGHT'
          ? colorWithAlpha({
              color: theme.colors.coreNeutralBlack,
              alpha: 0.1,
            })
          : colorWithAlpha({
              color: theme.colors.coreNeutralWhite,
              alpha: 0.15,
            })
      }, -1rem 0 0 0 ${
        theme.name === 'LIGHT'
          ? colorWithAlpha({
              color: theme.colors.coreNeutralBlack,
              alpha: 0.1,
            })
          : colorWithAlpha({
              color: theme.colors.coreNeutralWhite,
              alpha: 0.15,
            })
      }`};
  }
`;

export const Gradient = styled.div`
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
`;
