import { useCallback } from 'react';
import type { FC } from 'react';
import {
  Container,
  Description,
  Flex,
  Icon,
  Info,
  Overline,
  Title,
} from './styles';
import type { Props } from './types';

const Chapter: FC<Props> = ({
  color,
  className,
  isBonusContent,
  isBonusContentLocked,
  isExpanded,
  isForbidden = false,
  number,
  onClick,
  overline,
  title,
  ...rest
}) => {
  const handleClick = useCallback(() => {
    if (number && onClick) {
      onClick(number);
    }
  }, [number, onClick]);

  return (
    <Container
      $color={color}
      className={className}
      onClick={handleClick}
      {...rest}
    >
      <Flex>
        <Info $isForbidden={isForbidden}>
          <Overline>{overline}</Overline>
          <Title>{title}</Title>
        </Info>
        {isBonusContent ? (
          <Icon
            aria-hidden="true"
            name={isBonusContentLocked ? 'lockSimpleLight' : 'starLight'}
          />
        ) : (
          <Icon
            aria-hidden="true"
            name={isExpanded ? 'minusLight' : 'plusLight'}
            size={22}
          />
        )}
      </Flex>
      {isBonusContent && (
        <Description $isForbidden={isForbidden}>
          {isBonusContentLocked
            ? 'Finish the Introductory Course to unlock the next step of your journey'
            : 'You have finished the Introductory Course and can now continue your journey with Daily Meditations'}
        </Description>
      )}
    </Container>
  );
};

export default Chapter;
