import type { FC } from 'react';
import { Props } from './types';

const NumberPlaybackSpeed2xLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M5.806 16h5.703v-1.383h-3.36v-.055l1.169-1.144c1.644-1.5 2.085-2.25 2.085-3.16 0-1.387-1.132-2.367-2.847-2.367-1.68 0-2.832 1.004-2.828 2.574h1.605c-.004-.766.48-1.235 1.211-1.235.703 0 1.227.438 1.227 1.141 0 .637-.39 1.074-1.117 1.774L5.806 14.78V16zm8.775-6h-1.719l1.719 3-1.781 3h1.718l1.164-2.074L16.866 16h1.7l-1.786-3 1.738-3h-1.707l-1.129 2.098L14.581 10z"
      fill="currentColor"
    />
  </svg>
);

export default NumberPlaybackSpeed2xLight;
