import { memo } from 'react';
import { AudioHtmlElement } from './styles';
import { Props } from './types';
import useConnect from './connect';

const Audio = ({
  audioUrl,
  autoPlay = false,
  children,
  endTime,
  hasSpeedControl = false,
  onClose,
  onComplete,
  onEnd,
  onEndTime,
  onInit,
  onPause,
  onPlay,
  onPlaybackRateChange,
  onVolumeChange,
  preload = 'auto',
  startTime = 0,
}: Props) => {
  const connect = useConnect({
    audioUrl,
    autoPlay,
    endTime,
    hasSpeedControl,
    onClose,
    onComplete,
    onEnd,
    onEndTime,
    onInit,
    onPause,
    onPlay,
    onPlaybackRateChange,
    onVolumeChange,
    startTime,
  });

  return (
    <>
      <AudioHtmlElement
        autoPlay={false}
        controls={false}
        preload={preload}
        ref={connect.audioRef}
        src={audioUrl}
      />
      {children(connect)}
    </>
  );
};

export default memo(Audio);
