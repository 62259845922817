import { FC } from 'react';
import Form from './Form';
import Header from './Header';
import useConnect from './connect';
import { Container } from './styles';

const Notifications: FC = () => {
  const { initialValues, handleSubmit } = useConnect();

  return (
    <Container>
      <Header />
      <Form initialValues={initialValues} onSubmit={handleSubmit} />
    </Container>
  );
};

export default Notifications;
