import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Container = styled.div`
  font-size: 0.875rem;
  line-height: 1.286;
  margin: 2.5rem 0;

  strong {
    font-size: inherit;
    font-weight: ${({ theme }) => theme.font.weight.semibold};
  }

  ${media.tabletPortrait} {
    margin: 1rem 0 2.5rem 0;
    padding: 0 0.5rem;
  }

  ${media.phoneLandscape} {
    font-size: 0.8125rem;
    line-height: 1.23;
  }
`;

export const Text = styled.p`
  font-size: inherit;
  margin: 1.4rem 0;

  ${media.phoneLandscape} {
    margin: 1rem 0;
  }
`;

export const List = styled.ul`
  font-size: inherit;
  list-style: none;
`;

export const Feature = styled.li`
  align-items: flex-start;
  display: flex;
  font-size: inherit;
  margin: 1.125rem 0;

  &::before {
    background-color: #2f73d8;
    border-radius: 50%;
    content: '';
    display: inline-block;
    flex: 0 0 auto;
    height: 0.5rem;
    margin-right: 1rem;
    margin-top: 0.3rem;
    width: 0.5rem;
  }

  ${media.phoneLandscape} {
    margin: 0.8125rem 0;
  }
`;

export const FeatureText = styled.p`
  font-size: inherit;
`;
