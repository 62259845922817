import type { FC } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import QRCode from 'qrcode.react';
import { AppConfig } from 'config';
import Footer from 'components/PublicPageFooter';
import {
  AppStoreBadge,
  BadgeLink,
  CameraIcon,
  Container,
  Content,
  Description,
  GooglePlayBadge,
  Image,
  QRCodeContainer,
  ScanMe,
  StoresContainer,
  Title,
  WebappLink,
} from './styles';
import type { Props } from './types';
import useConnect from './connect';

const Welcome: FC<Props> = ({
  createAppsFlyerLinkFooterOptions = {},
  createAppsFlyerLinkOptions = {},
}) => {
  const {
    all,
    android,
    handleAppleStoreClick,
    handleGooglePlayClick,
    ios,
    isTabletPortrait,
    userEmail,
  } = useConnect({ createAppsFlyerLinkOptions });

  const showQRCode = !isTabletPortrait;
  const showAppStoreButton = !isTabletPortrait || isIOS;
  const showGooglePlayButton = !isTabletPortrait || isAndroid;

  return (
    <Container>
      <Image alt="" src={`${AppConfig.imagesURL}/welcome-head-2.png`} />
      <Content>
        <Title>Welcome to Waking Up.</Title>
        <Description>
          You're almost there. To download the app, just open your phone's
          camera app to scan this QR code, or check your email for a link.
        </Description>
        {showQRCode && (
          <QRCodeContainer>
            <QRCode
              aria-label="QR code to open on store"
              fgColor="#0E1529"
              renderAs="svg"
              size={144}
              value={all}
            />
            <ScanMe>
              <CameraIcon aria-hidden="true" />
              Scan me
            </ScanMe>
          </QRCodeContainer>
        )}
        <StoresContainer>
          {showAppStoreButton && (
            <BadgeLink
              aria-label="Open on App Store (opens in a new tab)"
              href={ios}
              onClick={handleAppleStoreClick}
              target="_blank"
            >
              <AppStoreBadge aria-hidden="true" size={showQRCode ? 95 : 130} />
            </BadgeLink>
          )}
          {showGooglePlayButton && (
            <BadgeLink
              aria-label="Open on Google Play (opens in a new tab)"
              href={android}
              onClick={handleGooglePlayClick}
              target="_blank"
            >
              <GooglePlayBadge
                aria-hidden="true"
                size={showQRCode ? 105 : 145}
              />
            </BadgeLink>
          )}
        </StoresContainer>
        <WebappLink
          to={`/${userEmail ? `?email=${encodeURIComponent(userEmail)}` : ''}`}
        >
          Log in with the desktop app
        </WebappLink>
      </Content>
      <Footer createAppsFlyerLinkOptions={createAppsFlyerLinkFooterOptions} />
    </Container>
  );
};

export default Welcome;
