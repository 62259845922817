/* eslint-disable import/prefer-default-export */
import AppStoreBadge from './Icons/AppStoreBadge';
import ArrowCircleDownLight from './Icons/ArrowCircleDownLight';
import ArrowClockwiseLight10s from './Icons/ArrowClockwiseLight10s';
import ArrowClockwiseLight15s from './Icons/ArrowClockwiseLight15s';
import ArrowCounterClockwiseLight from './Icons/ArrowCounterClockwiseLight';
import ArrowCounterClockwiseLight10s from './Icons/ArrowCounterClockwiseLight10s';
import ArrowCounterClockwiseLight15s from './Icons/ArrowCounterClockwiseLight15s';
import ArrowLeftLight from './Icons/ArrowLeftLight';
import ArrowRightLight from './Icons/ArrowRightLight';
import ArrowUUpLeftLight from './Icons/ArrowUUpLeftLight';
import BookmarkLight from './Icons/BookmarkLight';
import BookmarkSingleActive from './Icons/BookmarkSingleActive';
import BookmarkSingleInactive from './Icons/BookmarkSingleInactive';
import BookOpenLight from './Icons/BookOpenLight';
import CalendarBlankLight from './Icons/CalendarBlankLight';
import CameraLight from './Icons/CameraLight';
import CaretDownLight from './Icons/CaretDownLight';
import CaretRightLight from './Icons/CaretRightLight';
import CaretUpLight from './Icons/CaretUpLight';
import ChatCircleDotsLight from './Icons/ChatCircleDotsLight';
import ChatCircleDotsLightInverted from './Icons/ChatCircleDotsLightInverted';
import CheckCircleLight from './Icons/CheckCircleLight';
import CheckCircleLightFilled from './Icons/CheckCircleLightFilled';
import CheckCircleLightInactive from './Icons/CheckCircleLightInactive';
import CheckLight from './Icons/CheckLight';
import CheckSquareLight from './Icons/CheckSquareLight';
import CircleLight from './Icons/CircleLight';
import ClockLight from './Icons/ClockLight';
import CloudCheckLight from './Icons/CloudCheckLight';
import CopySimpleLight from './Icons/CopySimpleLight';
import CreditCardLight from './Icons/CreditCardLight';
import EnvelopeLight from './Icons/EnvelopeLight';
import EqualizerStatic from './Icons/EqualizerStatic';
import GearLight from './Icons/GearLight';
import GearSixLight from './Icons/GearSixLight';
import GlobeHemisphereWestLight from './Icons/GlobeHemisphereWestLight';
import GooglePlayBadge from './Icons/GooglePlayBadge';
import HeartLightActive from './Icons/HeartLightActive';
import HeartLightInactive from './Icons/HeartLightInactive';
import HouseLineLight from './Icons/HouseLineLight';
import InfinityLight from './Icons/InfinityLight';
import InfoLight from './Icons/InfoLight';
import LifeLight from './Icons/LifeLight';
import LinkBreakLight from './Icons/LinkBreakLight';
import LockSimpleFilled from './Icons/LockSimpleFilled';
import LockSimpleLight from './Icons/LockSimpleLight';
import LotusLight from './Icons/LotusLight';
import MinusLight from './Icons/MinusLight';
import MusicNoteLight from './Icons/MusicNoteLight';
import NoMusicNoteLight from './Icons/NoMusicNoteLight';
import NumberPlaybackSpeed08xLight from './Icons/NumberPlaybackSpeed08xLight';
import NumberPlaybackSpeed12xLight from './Icons/NumberPlaybackSpeed12xLight';
import NumberPlaybackSpeed14xLight from './Icons/NumberPlaybackSpeed14xLight';
import NumberPlaybackSpeed16xLight from './Icons/NumberPlaybackSpeed16xLight';
import NumberPlaybackSpeed18xLight from './Icons/NumberPlaybackSpeed18xLight';
import NumberPlaybackSpeed1xLight from './Icons/NumberPlaybackSpeed1xLight';
import NumberPlaybackSpeed2xLight from './Icons/NumberPlaybackSpeed2xLight';
import PauseLight from './Icons/PauseLight';
import PencilLight from './Icons/PencilLight';
import PlayCircleBold from './Icons/PlayCircleBold';
import PlayCircleLight from './Icons/PlayCircleLight';
import PlayLight from './Icons/PlayLight';
import PlayRoundedLight from './Icons/PlayRoundedLight';
import PlusLight from './Icons/PlusLight';
import RepeatLight from './Icons/RepeatLight';
import Search from './Icons/Search';
import ShareLight from './Icons/ShareLight';
import ShareNetworkLight from './Icons/ShareNetworkLight';
import StackLight from './Icons/StackLight';
import StarActive from './Icons/StarActive';
import StarLight from './Icons/StarLight';
import ThreeDotsLight from './Icons/ThreeDotsLight';
import ThreeDotsVerticalLight from './Icons/ThreeDotsVerticalLight';
import TimerLight from './Icons/TimerLight';
import UserCircleLight from './Icons/UserCircleLight';
import XCircleLight from './Icons/XCircleLight';
import XLight from './Icons/XLight';

export const ICONS = {
  appStoreBadge: AppStoreBadge,
  arrowCircleDownLight: ArrowCircleDownLight,
  arrowClockwiseLight10s: ArrowClockwiseLight10s,
  arrowClockwiseLight15s: ArrowClockwiseLight15s,
  arrowCounterClockwiseLight: ArrowCounterClockwiseLight,
  arrowCounterClockwiseLight10s: ArrowCounterClockwiseLight10s,
  arrowCounterClockwiseLight15s: ArrowCounterClockwiseLight15s,
  arrowLeftLight: ArrowLeftLight,
  arrowRightLight: ArrowRightLight,
  arrowUUpLeftLight: ArrowUUpLeftLight,
  bookmarkLight: BookmarkLight,
  bookmarkSingleActive: BookmarkSingleActive,
  bookmarkSingleInactive: BookmarkSingleInactive,
  bookOpenLight: BookOpenLight,
  calendarBlankLight: CalendarBlankLight,
  cameraLight: CameraLight,
  caretDownLight: CaretDownLight,
  caretRightLight: CaretRightLight,
  caretUpLight: CaretUpLight,
  chatCircleDotsLight: ChatCircleDotsLight,
  chatCircleDotsLightInverted: ChatCircleDotsLightInverted,
  checkCircleLight: CheckCircleLight,
  checkCircleLightFilled: CheckCircleLightFilled,
  checkCircleLightInactive: CheckCircleLightInactive,
  checkLight: CheckLight,
  checkSquareLight: CheckSquareLight,
  circleLight: CircleLight,
  clockLight: ClockLight,
  cloudCheckLight: CloudCheckLight,
  copySimpleLight: CopySimpleLight,
  creditCardLight: CreditCardLight,
  envelopeLight: EnvelopeLight,
  equalizerStatic: EqualizerStatic,
  gearLight: GearLight,
  gearSixLight: GearSixLight,
  globeHemisphereWestLight: GlobeHemisphereWestLight,
  googlePlayBadge: GooglePlayBadge,
  heartLightActive: HeartLightActive,
  heartLightInactive: HeartLightInactive,
  houseLineLight: HouseLineLight,
  infinityLight: InfinityLight,
  infoLight: InfoLight,
  lifeLight: LifeLight,
  linkBreakLight: LinkBreakLight,
  lockSimpleFilled: LockSimpleFilled,
  lockSimpleLight: LockSimpleLight,
  lotusLight: LotusLight,
  minusLight: MinusLight,
  musicNoteLight: MusicNoteLight,
  noMusicNoteLight: NoMusicNoteLight,
  numberPlaybackSpeed08xLight: NumberPlaybackSpeed08xLight,
  numberPlaybackSpeed12xLight: NumberPlaybackSpeed12xLight,
  numberPlaybackSpeed14xLight: NumberPlaybackSpeed14xLight,
  numberPlaybackSpeed16xLight: NumberPlaybackSpeed16xLight,
  numberPlaybackSpeed18xLight: NumberPlaybackSpeed18xLight,
  numberPlaybackSpeed1xLight: NumberPlaybackSpeed1xLight,
  numberPlaybackSpeed2xLight: NumberPlaybackSpeed2xLight,
  pauseLight: PauseLight,
  pencilLight: PencilLight,
  playCircleBold: PlayCircleBold,
  playCircleLight: PlayCircleLight,
  playLight: PlayLight,
  playRoundedLight: PlayRoundedLight,
  plusLight: PlusLight,
  repeatLight: RepeatLight,
  search: Search,
  shareLight: ShareLight,
  shareNetworkLight: ShareNetworkLight,
  stackLight: StackLight,
  starActive: StarActive,
  starLight: StarLight,
  threeDotsLight: ThreeDotsLight,
  threeDotsVerticalLight: ThreeDotsVerticalLight,
  timerLight: TimerLight,
  userCircleLight: UserCircleLight,
  xCircleLight: XCircleLight,
  xLight: XLight,
};
