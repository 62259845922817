import { memo, type FC } from 'react';
import Icon from 'components/Icon';
import Main from './Main';
import Interval from './Interval';
import { CloseButton, Modal } from './styles';
import useConnect from './connect';

const SelfTimerConfiguration: FC = () => {
  const {
    duration,
    handleClose,
    intervalTime,
    isOpen,
    isTimeSectionOpen,
    setDuration,
    setIntervalTime,
    setIsTimeSectionOpen,
  } = useConnect();

  return (
    <Modal
      $isTimeSectionOpen={isTimeSectionOpen}
      animation="fade"
      fullscreenOnMobile
      hideClose
      onClose={handleClose}
      open={isOpen}
    >
      <CloseButton
        $isTimeSectionOpen={isTimeSectionOpen}
        aria-label="Close self-timer modal"
        onClick={handleClose}
      >
        <Icon aria-hidden="true" name="xLight" />
      </CloseButton>
      <Main
        duration={duration}
        intervalTime={intervalTime}
        isTimeSectionOpen={isTimeSectionOpen}
        setDuration={setDuration}
        setIsTimeSectionOpen={setIsTimeSectionOpen}
      />
      {isTimeSectionOpen && (
        <Interval
          duration={duration}
          intervalTime={intervalTime}
          setIntervalTime={setIntervalTime}
          setIsTimeSectionOpen={setIsTimeSectionOpen}
        />
      )}
    </Modal>
  );
};

export default memo(SelfTimerConfiguration);
