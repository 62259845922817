import { useEffect, useRef, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { GOOGLE_CLIENT_ID } from 'config';
import {
  LoginStatus,
  loginStatusVar,
  resetLoginStatusVar,
  setLoginStatusVar,
} from 'graphql/reactive';
import useAuthActions from 'graphql/hooks/useAuthActions';
import { trackEvent } from 'services/analytics';
import { ConnectProps } from './types';

const useConnect = ({ mode }: ConnectProps) => {
  const { loginWithGoogle } = useAuthActions();
  const loginStatus = useReactiveVar(loginStatusVar);
  const { enqueueSnackbar } = useSnackbar();

  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);

  const buttonRef = useRef<HTMLDivElement>(null!);

  useEffect(() => {
    const loadGoogle = () => {
      window.google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: async (response) => {
          try {
            setLoginStatusVar(LoginStatus.STARTING_GOOGLE_MODE);
            trackEvent('Login Submit', { method: 'GoogleSignIn' });
            await loginWithGoogle(response.credential);
            setLoginStatusVar(LoginStatus.STARTED);
          } catch (error) {
            resetLoginStatusVar();
            enqueueSnackbar(
              error?.message ||
                'Unable to sign in with google. Please try again later.',
              { variant: 'error' },
            );
          }
        },
      });

      window.google.accounts.id.renderButton(buttonRef.current, {
        size: 'medium',
        text: `${mode}_with`,
        theme: 'outline',
      });

      setIsGoogleLoaded(true);
    };

    try {
      loadGoogle();
    } catch {
      setTimeout(() => loadGoogle(), 1000);
    }
  }, [enqueueSnackbar, loginWithGoogle, mode]);

  return {
    buttonRef,
    isGoogleLoaded,
    loginStatus,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
