import styled from 'styled-components';
import { media } from 'styles/mixins';
import { Heading, Body } from 'components/Typography';
import DefaultIcon from 'components/Icon';

export const Container = styled.button`
  background: transparent;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderBase};
  box-sizing: content-box;
  cursor: pointer;
  line-height: 1;
  outline: none;
  padding: 1.25rem 0;
  text-align: left;
  width: 100%;

  ${media.tabletPortrait} {
    margin-left: -1.4rem;
    margin-right: -1.4rem;
    padding: 1.25rem 1.4rem;
  }

  ${media.phonePortrait} {
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 1.25rem 1rem;
  }
`;

export const Title = styled(Heading).attrs(() => ({
  $weight: 'bold',
}))`
  word-break: break-word;
`;

export const Description = styled(Body).attrs(() => ({
  $weight: 'regular',
}))`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutral600
      : theme.colors.coreNeutral200};
  margin-top: 0.15rem;
  word-break: break-word;
`;

export const Time = styled.div`
  align-items: center;
  display: flex;
  margin-top: 0.25rem;
`;

export const StartTime = styled(Body).attrs(() => ({
  $size: 'small',
  $weight: 'bold',
}))`
  color: ${({ theme }) => theme.colors.fontEmphasized};
`;

export const Icon = styled(DefaultIcon).attrs(({ theme }) => ({
  color: theme.colors.coreNeutral400,
  size: 16,
}))`
  display: block;
  margin-left: 0.28rem;
`;
