import { memo } from 'react';
import type { FC } from 'react';
import { Caption, Container, Image } from './styles';
import type { Props } from './types';

const SubscriptionCoverImage: FC<Props> = ({
  alt,
  caption,
  className,
  desktopImageUrl,
  isTabletPortrait = false,
  mobileImageUrl,
}) => {
  const imageUrl =
    isTabletPortrait && mobileImageUrl ? mobileImageUrl : desktopImageUrl;

  return (
    <Container className={className}>
      <Image $isLoading={!imageUrl} alt={alt} src={imageUrl} />
      {caption && <Caption>{caption}</Caption>}
    </Container>
  );
};

export default memo(SubscriptionCoverImage);
