export const COLUMN_SEPARATION_PX = 20;

export const ITEM_HEIGHT_MEDIUM_PX = 250;
export const ITEM_WIDTH_MEDIUM_PX = 151;

export const ITEM_HEIGHT_LARGE_PX = 420;
export const ITEM_WIDTH_LARGE_PX = 322;

export const ITEM_HEIGHT_HORIZONTAL_PX = 151;
export const ITEM_WIDTH_HORIZONTAL_PX = 325;
