import { CreditCardBrand } from 'models/StripePaymentMethod';
import { memo, type FC } from 'react';
import { BrandIcon, ChangeCardButton, Container, Last4 } from './styles';
import type { Props } from './types';

const CreditCard: FC<Props> = ({ brand, last4, onChangeShowCardForm }) => (
  <Container>
    <BrandIcon
      $isGeneric={brand === CreditCardBrand.GENERIC}
      aria-label={`${brand}-card`}
      name={brand}
    />
    <Last4>***{last4}</Last4>
    <ChangeCardButton onClick={onChangeShowCardForm}>
      Change card
    </ChangeCardButton>
  </Container>
);

export default memo(CreditCard);
