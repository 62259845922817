/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-flex;
  color: inherit;

  &:hover {
    opacity: 0.7;
    transition: opacity 0.5s ease-in-out;
  }
`;
