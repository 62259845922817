import type { FC } from 'react';
import type { Props } from './types';

const CopySimpleLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...rest}
  >
    <g
      clipPath="url(#a)"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20.251 17.25V3.75h-13.5" />
      <path d="M17.252 6.749H3.75v13.5h13.5V6.75Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default CopySimpleLight;
