import styled from 'styled-components';
import { Form as DefaultForm } from 'formik';
import { media } from 'styles/mixins';
import DefaultButton from 'components/Button';

export const Container = styled.div`
  flex: 1;
  max-width: 25rem;
`;

export const Title = styled.h1`
  font-size: 1.15rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: 1.75rem;
  text-align: center;

  ${media.tabletPortrait} {
    margin-top: 1.25rem;
  }

  ${media.phoneLandscape} {
    margin-top: 0;
  }
`;

export const Form = styled(DefaultForm).attrs(() => ({ noValidate: true }))`
  & > * {
    margin-bottom: 1rem;
  }
`;

export const Error = styled.p`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.coreRed500};
  font-weight: ${({ theme }) => theme.font.weight.book};
  text-align: center;
`;

export const Contact = styled.p`
  font-weight: ${({ theme }) => theme.font.weight.book};
  margin-top: 0.5rem;
  text-align: center;

  a {
    color: inherit;
  }
`;

export const Button = styled(DefaultButton)`
  margin-top: ${({ theme }) => theme.measures.x};
`;
