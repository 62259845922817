import { memo, type FC } from 'react';
import { CheckIcon, Container } from './styles';
import type { Props } from './types';

const Popup: FC<Props> = ({ className }) => (
  <Container className={className}>
    <CheckIcon aria-hidden="true" name="checkCircleLightFilled" />
    Link copied. Now just paste and send.
  </Container>
);

export default memo(Popup);
