import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 21.875rem;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  right: -1rem;
  top: -7.1875rem;
  width: calc(100% + 2rem);
  z-index: -1;

  ::after {
    background-color: ${({ theme }) => theme.colors.backgroundBase};
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.6;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const GradientTop = styled.div`
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? 'linear-gradient(180deg, #F4F4F4 0%, rgba(244, 244, 244, 0) 100%)'
      : 'linear-gradient(180deg, #0E1529 0%, rgba(14, 21, 41, 0) 100%)'};
`;

export const GradientBottom = styled.div`
  background: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? 'linear-gradient(180deg, rgba(244, 244, 244, 0) 0%, #F4F4F4 100%)'
      : 'linear-gradient(180deg, rgba(14, 21, 41, 0) 0%, #0E1529 100%)'};
  height: 50%;
  position: absolute;
  right: 0;
  top: 50%;
  width: 100%;
`;

export const BlurImage = styled.div<{ $bg: string }>`
  background-image: ${({ $bg }) => `url(${$bg})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 589%;
  filter: blur(6.25rem);
  height: 51.5625rem;
  transform: translateZ(0);
  width: 51.5625rem;
`;
