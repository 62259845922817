import type { FC } from 'react';
import ResultPage from './ResultPage';
import CheckoutForm from './CheckoutForm';
import Buttons from './Buttons';
import {
  Artwork,
  Container,
  Description,
  Image,
  ImageContainer,
  Information,
  Loader,
  StepContent,
  Title,
} from './styles';
import type { Props } from './types';
import useConnect from './connect';

const GiftCardPurchase: FC<Props> = ({
  className,
  isPublic = false,
  userEmail,
  userName,
}) => {
  const {
    currentUrl,
    giftCardAmount,
    giftCardPrices,
    giftCardType,
    imageCaption,
    imageSrc,
    isLoading,
    isTabletPortrait,
    message,
    name,
    recipientEmail,
    resultParam,
    shouldBeSentAt,
    typeParam,
  } = useConnect();

  const showCheckoutForm = typeParam;
  const showGiftCardSelection = !typeParam && !resultParam;
  const showResult = !typeParam && resultParam;

  return (
    <Container $isPublic={isPublic} className={className}>
      {!isTabletPortrait && !showCheckoutForm && !showResult && (
        <ImageContainer>
          <Image $isPublic={isPublic} alt="Three credit cards" src={imageSrc} />
          <Artwork>{imageCaption}</Artwork>
        </ImageContainer>
      )}
      {showGiftCardSelection && (
        <Information $isPublic={isPublic}>
          <Title>Give presence.</Title>
          <StepContent>
            <Description>
              Help someone discover the true purpose of meditation—and the joy
              of an examined life—with a subscription to Waking Up.
            </Description>
            {isLoading ? (
              <Loader />
            ) : (
              <Buttons
                currentUrl={currentUrl}
                giftCardPrices={giftCardPrices}
              />
            )}
          </StepContent>
        </Information>
      )}
      {showResult && (
        <ResultPage
          imageCaption={imageCaption}
          imageSrc={imageSrc}
          isPublic={isPublic}
          message={message}
          recipientEmail={recipientEmail}
          shouldBeSentAt={shouldBeSentAt}
          senderName={name}
        />
      )}
      {showCheckoutForm && (
        <StepContent>
          <CheckoutForm
            cardAmount={giftCardAmount}
            cardType={giftCardType}
            imageCaption={imageCaption}
            imageSrc={imageSrc}
            isLoading={isLoading}
            isPublic={isPublic}
            userEmail={userEmail}
            userName={userName}
          />
        </StepContent>
      )}
      {isTabletPortrait && !showCheckoutForm && !showResult && (
        <ImageContainer>
          <Image $isPublic={isPublic} alt="Three credit cards" src={imageSrc} />
          <Artwork>{imageCaption}</Artwork>
        </ImageContainer>
      )}
    </Container>
  );
};

export default GiftCardPurchase;
