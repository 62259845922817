function concatenateWords(words: string[]) {
  if (words.length === 0) {
    return '';
  }

  if (words.length === 1) {
    return words[0];
  }

  if (words.length === 2) {
    return words.join(' and ');
  }

  const wordsByComma = words.slice(0, -1);
  const lastWord = words.slice(-1);
  return `${wordsByComma.join(`, `)} and ${lastWord}`;
}

export default concatenateWords;
