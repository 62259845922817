import { useCallback } from 'react';
import type { FC } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { toggleHelpScout } from 'services/helpscout';
import Layout from '../Layout';
import {
  Info,
  Button,
  Container,
  Email,
  Confirmation,
  Content,
  StoreButton,
} from './styles';
import { SubscriptionStatus, RouteParams, RouteState } from './types';

const SubscriptionSuccess: FC = () => {
  const navigate = useNavigate();
  const { status } = useParams<RouteParams>();
  const location = useLocation();
  const state = location.state as RouteState;

  const email = state?.email;

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (status === SubscriptionStatus.success) {
    return (
      <Layout>
        <Container>
          <Confirmation message="Thank you for subscribing to Waking Up!" />
          <Content id="purchase-subscription-success">
            <Email>Account: {email}</Email>
            <StoreButton type="ios" />
            <StoreButton type="android" />
            <Button to={`/?email=${encodeURIComponent(email)}`} replace>
              Sign In
            </Button>
            <Button onClick={toggleHelpScout}>Contact Support</Button>
          </Content>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container>
        <Info>
          Sorry! We couldn't finish your request. Something went wrong
        </Info>
        <Button onClick={handleGoBack}>Try again</Button>
      </Container>
    </Layout>
  );
};

export default SubscriptionSuccess;
