import type { FC } from 'react';
import { Props } from './types';

const GearSixLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M12.5 16.5c2.382 0 4.313-2.014 4.313-4.5 0-2.485-1.931-4.5-4.313-4.5-2.382 0-4.312 2.015-4.312 4.5 0 2.486 1.93 4.5 4.312 4.5z"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.244 9.806a.625.625 0 00-.299-.413L19.782 8.14a8.588 8.588 0 00-.418-.756l.041-2.596a.625.625 0 00-.195-.464 9.873 9.873 0 00-3.661-2.202.625.625 0 00-.53.063L12.905 3.51a7.85 7.85 0 00-.81-.001L9.979 2.184a.625.625 0 00-.53-.063A9.875 9.875 0 005.79 4.328a.625.625 0 00-.194.463l.041 2.594a8.584 8.584 0 00-.419.756L3.052 9.395a.625.625 0 00-.298.414 10.76 10.76 0 00.003 4.385.625.625 0 00.298.413l2.163 1.253c.127.26.267.512.419.757l-.042 2.596a.625.625 0 00.195.464 9.873 9.873 0 003.661 2.201.625.625 0 00.53-.063l2.115-1.324c.27.014.54.014.81 0l2.116 1.326a.627.627 0 00.53.063 9.873 9.873 0 003.659-2.207.625.625 0 00.195-.464l-.042-2.594a8.57 8.57 0 00.419-.756l2.165-1.254a.625.625 0 00.299-.414c.299-1.445.298-2.94-.003-4.385z"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default GearSixLight;
