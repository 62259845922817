import { useCallback, useEffect, useMemo, type FC } from 'react';
import {
  CellMeasurer,
  CellMeasurerCache,
  ListRowProps,
  WindowScroller,
} from 'react-virtualized';
import { CoursePermission } from 'models/Course';
import { PACK_ICONS } from 'models/Pack';
import Item from './Item';
import Loader from './Loader';
import { List, LockedContentBanner } from './styles';
import type { Props } from './types';

const CourseList: FC<Props> = ({
  ariaLabelledby,
  courses = [],
  currentUrl,
  displayCourseImages,
  hideOptionsMenu = false,
  hideTopBorder = false,
  hideUnlockCta = false,
  id = 'course-list',
  isLoading,
  linkCourses,
  onOpenOptionsMenu,
  packId,
  role,
  setTrack,
  showPretitle = false,
  showProgress = true,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cache = new CellMeasurerCache({ defaultHeight: 92, fixedWidth: true });

  const coursesWithLock = useMemo(() => {
    if (hideUnlockCta) {
      return courses;
    }

    const firstForbiddenCourseIndex = courses.findIndex(
      (c) => c.permission === CoursePermission.FORBIDDEN,
    );

    return firstForbiddenCourseIndex !== -1
      ? [
          ...courses.slice(0, firstForbiddenCourseIndex),
          { lock: true },
          ...courses.slice(firstForbiddenCourseIndex),
        ]
      : courses;
  }, [courses, hideUnlockCta]);

  useEffect(() => {
    const onResize = () => {
      cache.clearAll();
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [cache]);

  useEffect(() => {
    if (role === 'tabpanel' && ariaLabelledby && !isLoading) {
      const list = document.getElementById(id);
      if (list) {
        list.setAttribute('aria-labelledby', ariaLabelledby);
      }
    }
  }, [ariaLabelledby, id, isLoading, role]);

  const rowRenderer: FC<ListRowProps> = useCallback(
    ({ index: i, parent, style }) => {
      const course = coursesWithLock[i];

      if ('lock' in course) {
        return (
          <CellMeasurer
            key="lock"
            cache={cache}
            columnIndex={0}
            id="course-lock"
            parent={parent}
            rowIndex={i}
          >
            <div style={style}>
              <LockedContentBanner />
            </div>
          </CellMeasurer>
        );
      }

      const isForbidden = course.permission === CoursePermission.FORBIDDEN;
      const courseLink = linkCourses
        ? `${currentUrl}/${course.hash}`
        : undefined;
      const showMenu = !course.ephemeral && !hideOptionsMenu;

      let imageUrl = '';
      if (course.packOverwriteCourseImage) {
        imageUrl = course.packImageThumbnailsThumbX1 || course.packImageUrl;
      } else if (course.isIntroCourse) {
        imageUrl =
          course.headImageThumbnailsThumbX1 ||
          course.headImageThumbnailsThumbX2 ||
          course.headImageUrl ||
          course.imageThumbnailsThumbX1 ||
          course.imageThumbnailsThumbX2 ||
          course.imageUrl;
      } else {
        imageUrl =
          course.imageThumbnailsThumbX1 ||
          course.imageThumbnailsThumbX2 ||
          course.imageUrl;
      }

      return (
        <CellMeasurer
          key={course.id}
          cache={cache}
          columnIndex={0}
          id={`course-${course.id.toLowerCase()}`}
          parent={parent}
          rowIndex={i}
        >
          {({ measure }) => (
            <Item
              courseId={course.id}
              courseStatus={course.status}
              description={course.description}
              highlight={course.isNewText}
              imageUrl={displayCourseImages ? imageUrl : undefined}
              isBookmark={course.isBookmark}
              isForbidden={isForbidden}
              isHighlighted={!!course.isHighlighted}
              onOpenOptionsMenu={showMenu ? onOpenOptionsMenu : undefined}
              onPlay={setTrack}
              onTextExpand={measure}
              packId={packId || course.packId}
              pretitle={showPretitle ? course.packTitle : undefined}
              pretitleIcon={PACK_ICONS[course.packType]}
              progress={course.progress}
              style={style}
              subtitle={course.subtitle}
              title={course.title}
              to={isForbidden ? '/account/subscription' : courseLink}
              total={course.audioLength}
              type={showProgress ? 'course' : undefined}
            />
          )}
        </CellMeasurer>
      );
    },
    [
      cache,
      coursesWithLock,
      currentUrl,
      displayCourseImages,
      hideOptionsMenu,
      linkCourses,
      onOpenOptionsMenu,
      packId,
      setTrack,
      showPretitle,
      showProgress,
    ],
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <WindowScroller>
      {({ onChildScroll, ...scrollInfo }) => (
        <List
          $hideTopBorder={hideTopBorder}
          autoHeight
          autoWidth
          deferredMeasurementCache={cache}
          id={id}
          onScroll={onChildScroll}
          role={role}
          rowCount={coursesWithLock.length}
          rowHeight={cache.rowHeight}
          rowRenderer={rowRenderer}
          {...scrollInfo}
        />
      )}
    </WindowScroller>
  );
};

export default CourseList;
