/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { ZIndex } from 'styles/metrics';

export const Container = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 50%;
  box-sizing: content-box;
  color: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  left: ${({ theme }) => theme.measures.xs};
  line-height: 1;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: ${ZIndex.PLAYER_CLOSE_BUTTON};
`;
