import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { up } from 'lib/paths';
import useMe from 'graphql/hooks/useMe';
import useStripePaymentMethod from 'graphql/hooks/useStripePaymentMethod';

const useConnect = () => {
  const {
    profile: { email, firstName, lastName, subscriptionSource },
    loading: isLoadingMe,
  } = useMe();
  const { stripePaymentMethod, loading: isLoadingStripePaymentMethod } =
    useStripePaymentMethod({ skip: subscriptionSource !== 'stripe' });
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isLoading = isLoadingMe || isLoadingStripePaymentMethod;
  const upUrl = useMemo(() => up(pathname), [pathname]);

  useEffect(() => {
    if (!isLoading && !stripePaymentMethod) {
      navigate(upUrl, { replace: true });
    }
  }, [isLoading, navigate, stripePaymentMethod, upUrl]);

  return {
    email,
    firstName,
    isLoading,
    lastName,
    stripePaymentMethod,
    upUrl,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
