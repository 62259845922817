import { type FC, useCallback, useState } from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import { CONTENT_ALGOLIA_INDEX } from 'services/algolia';
import useAlgolia from 'graphql/hooks/useAlgolia';
import Icon from 'components/Icon';
import Loader from './Loader';
import Results from './Results';
import SearchInput from './SearchInput';
import { BackLink, Content, Header, Title } from './styles';

const Search: FC = () => {
  const { searchClient } = useAlgolia();

  const [searchValue, setSearchValue] = useState({ query: '' });

  const handleSearchChange = useCallback(
    (query: any) => setSearchValue(query),
    [],
  );

  if (!searchClient) {
    return <Loader />;
  }

  return (
    <>
      <Header>
        <BackLink aria-label="Go back to home" to="/">
          <Icon aria-hidden="true" name="arrowLeftLight" />
        </BackLink>
        <Title as="h1">Search</Title>
      </Header>
      <Content aria-live="polite">
        <InstantSearch
          indexName={CONTENT_ALGOLIA_INDEX}
          onSearchStateChange={handleSearchChange}
          searchClient={searchClient}
          searchState={searchValue}
        >
          <SearchInput />
          <Results onClick={handleSearchChange} />
        </InstantSearch>
      </Content>
    </>
  );
};

export default Search;
