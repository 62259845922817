import { memo } from 'react';
import type { FC } from 'react';
import { Container, Icon } from './styles';
import type { Props } from './types';

const Stars: FC<Props> = ({ className, level, size = 24 }) => (
  <Container className={className}>
    {[...Array(level)]
      .map((v, i) => i)
      .map((v) => (
        <Icon key={v} aria-hidden="true" name="starActive" size={size} />
      ))}
  </Container>
);

export default memo(Stars);
