import { useState, useEffect, useCallback } from 'react';
import { useFormikContext } from 'formik';
import useAuthActions from 'graphql/hooks/useAuthActions';
import type { FormFields } from '../types';

const SHOW_RESEND_TIMEOUT = 30;

const useConnect = () => {
  const {
    setErrors,
    values: { email },
  } = useFormikContext<FormFields>();
  const { resend } = useAuthActions();

  const [status, setStatus] = useState<
    'dont-show' | 'show-resend' | 'show-resent'
  >('dont-show');

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setStatus('show-resend');
    }, SHOW_RESEND_TIMEOUT * 1000);

    return () => {
      clearTimeout(timeoutID);
    };
  }, []);

  const handleResend = useCallback(async () => {
    try {
      await resend({ email });
      setStatus('show-resent');
      setErrors({ submitError: '' });
    } catch (error) {
      setErrors({ submitError: error.message });
    }
  }, [email, resend, setErrors]);

  return { handleResend, status };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
