import { FC } from 'react';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { Formik } from 'formik';
import {
  Button,
  CardElement,
  Container,
  Form,
  FormErrors,
  FormSection,
  Loader,
  OtherPaymentButton,
  Progress,
  PaymentMethods,
  TextField,
  Title,
} from './styles';
import { Props } from './types';
import { validationSchema } from './constants';
import useConnect from './connect';

const CheckoutForm: FC<Props> = ({
  experiment,
  isPublic,
  isStripeCheckoutSession,
  planAmount,
  planId,
  planInterval,
  priceId,
  promoCode,
  screen,
  sharedBy,
  title,
  userEmail,
  variant,
}) => {
  const {
    elements,
    formikRef,
    handleSubmit,
    hasApplePay,
    initialValues,
    isCreatingSubscription,
    isLoading,
    paymentRequest,
    stripe,
  } = useConnect({
    experiment,
    isPublic,
    isStripeCheckoutSession,
    planAmount,
    planId,
    planInterval,
    priceId,
    promoCode,
    screen,
    sharedBy,
    title,
    userEmail,
    variant,
  });

  if (isLoading) {
    return (
      <Loader>
        <Progress />
      </Loader>
    );
  }

  const errorLabelId = 'stripe-modal-errors';

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ errors, isSubmitting, values }) => (
          <Form noValidate>
            <FormSection>
              {!userEmail && (
                <Title>1. Please enter email for this subscription</Title>
              )}
              <TextField
                autoComplete="email"
                errorId="purchase-subscription-email-error"
                id="purchase-subscription-email-field"
                label="Email"
                maxLength={150}
                name="email"
                readOnly={!!userEmail}
                type="email"
              />
            </FormSection>
            {!isStripeCheckoutSession && (
              <FormSection $disabled={!values.email || !!errors.email}>
                <Title>{!userEmail ? '2. ' : ''} Select Payment Method</Title>
                {paymentRequest && (
                  <PaymentMethods>
                    {hasApplePay ? (
                      <PaymentRequestButtonElement
                        options={{
                          paymentRequest,
                          style: {
                            paymentRequestButton: {
                              height: '50px',
                              theme: 'dark',
                              type: 'subscribe',
                            },
                          },
                        }}
                      />
                    ) : (
                      <OtherPaymentButton
                        onClick={paymentRequest.show}
                        type="button"
                      >
                        Select Credit Card
                      </OtherPaymentButton>
                    )}
                  </PaymentMethods>
                )}
                <CardElement />
                {errors.submitError && (
                  <FormErrors error center id={errorLabelId}>
                    Error: {errors.submitError}
                  </FormErrors>
                )}

                <Button
                  disabled={!stripe || !elements}
                  isLoading={isSubmitting || isCreatingSubscription}
                  type="submit"
                  id="stripe-payment-submit-button"
                >
                  Subscribe
                </Button>
              </FormSection>
            )}
            {isStripeCheckoutSession && (
              <FormSection $disabled={!values.email || !!errors.email}>
                {errors.submitError && (
                  <FormErrors error center id={errorLabelId}>
                    Error: {errors.submitError}
                  </FormErrors>
                )}

                <Button
                  isLoading={isSubmitting}
                  type="submit"
                  id="stripe-checkout-session-submit-button"
                >
                  Checkout
                </Button>
              </FormSection>
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default CheckoutForm;
