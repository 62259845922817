import { useCallback } from 'react';
import { capitalize } from 'lodash';
import { trackEvent } from 'services/analytics';
import formatCents from 'lib/formatCents';
import { SubscriptionPlanType } from 'graphql/generated';
import { ModalType } from 'graphql/reactive';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';

const useConnect = () => {
  const { isOpen, payload } = useModal(ModalType.STRIPE_PAYMENT);
  const { closeModal } = useModalActions();

  const experiment = payload?.experiment;
  const variant = payload?.variant;
  const isPublic = payload?.isPublic ?? false;
  const isStripeCheckoutSession = payload?.isStripeCheckoutSession ?? false;
  const planAmount = payload?.planAmount || 0;
  const planId = payload?.planId || '';
  const planInterval = payload?.planInterval || '';
  const planType = payload?.planType || SubscriptionPlanType.standard;
  const priceId = payload?.priceId;
  const promoCode = payload?.promoCode;
  const sharedBy = payload?.sharedBy;
  const userEmail = payload?.email;
  const screen = payload?.screen;
  const description =
    payload?.description ||
    (planInterval === 'lifetime'
      ? `Purchase for US${formatCents(planAmount)}`
      : `Subscribe for US${formatCents(planAmount)}/${planInterval}`);

  let title = '';
  if (payload?.title) {
    title = payload.title;
  } else if (planType === SubscriptionPlanType.family) {
    title = 'Family Plan';
  } else if (planType === SubscriptionPlanType.lifetime) {
    title = 'Lifetime Subscription';
  } else {
    title = `${capitalize(planInterval)}ly Subscription`;
  }

  const handleClose = useCallback(() => {
    trackEvent('Purchase Aborted', { experiment, variant });
    closeModal();
  }, [closeModal, experiment, variant]);

  return {
    description,
    experiment,
    handleClose,
    isOpen,
    isPublic,
    isStripeCheckoutSession,
    planAmount,
    planId,
    planInterval,
    priceId,
    promoCode,
    screen,
    sharedBy,
    title,
    userEmail,
    variant,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
