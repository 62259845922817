/* eslint-disable import/prefer-default-export */
export const DEEPLINKS_ROUTES = [
  '/accept-family-invitation/:token',
  '/checkout',
  '/course/:id',
  '/daily/:sharedHash',
  '/dailyMeditationDuration',
  '/freeMonthTrial/:courseHash',
  '/freeMonthTrialSocialShare/:courseHash',
  '/offer/:code',
  '/onboarding',
  '/open-self-timer',
  '/openview/aboutCourse',
  '/openview/aboutSam',
  '/openview/dailyMeditationDuration',
  '/openview/faq',
  '/openview/favorites',
  '/openview/onboarding',
  '/openview/playDaily',
  '/openview/playNextMeditation',
  '/openview/practice',
  '/openview/privacy',
  '/openview/profile',
  '/openview/settings',
  '/openview/share',
  '/openview/subscriptions',
  '/openview/terms',
  '/openview/theory',
  '/openview/timer',
  '/pack/:id',
  '/person/:id',
  '/playDaily',
  '/playNextMeditation',
  '/preview/:courseHash',
  '/redeemFreeMonth/:code',
  '/retreat/:id',
  '/share-the-app',
  '/share',
  '/share/:courseHash',
  '/share/redeem/:token',
  '/shareOpenAccess/:code',
  '/timer',
  '/upgrade-to-annual',
];
