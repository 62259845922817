import { memo } from 'react';
import type { FC } from 'react';
import useConnect from './connect';
import { Button, Cancel, Modal, Overline, Subtitle, Title } from './styles';

const LeaveRetreat: FC = () => {
  const {
    handleClose,
    handleLeave,
    isOpen,
    isUpdatingUserRetreat,
    overline,
    subtitle,
    title,
  } = useConnect();

  return (
    <Modal
      descriptionId="leave-retreat-description"
      fullscreenOnMobile
      hideClose
      onClose={handleClose}
      open={isOpen}
      titleId="leave-retreat-title"
    >
      <Overline>{overline}</Overline>
      <Title id="leave-retreat-title">{title}</Title>
      <Subtitle id="leave-retreat-description">{subtitle}</Subtitle>
      <Button isLoading={isUpdatingUserRetreat} onClick={handleLeave}>
        Leave Event
      </Button>
      <Cancel onClick={handleClose}>Nevermind</Cancel>
    </Modal>
  );
};

export default memo(LeaveRetreat);
