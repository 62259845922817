import { useEffect } from 'react';
import type { FC } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { trackEvent } from 'services/analytics';
import useAuthActions from 'graphql/hooks/useAuthActions';
import { Container, Isotype, Message, Progress } from './styles';

const MagicLink: FC = () => {
  const { loginWithMagicLink } = useAuthActions();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { token } = useParams<{ token?: string }>();

  useEffect(() => {
    trackEvent('Deep Link Magic Login');
  }, []);

  useEffect(() => {
    const login = async () => {
      try {
        if (token) {
          await loginWithMagicLink(token);
        } else {
          navigate('/', { replace: true });
        }
      } catch (error) {
        navigate(`/magic-link-login-expired${search}`, { replace: true });
      }
    };

    login();
  }, [loginWithMagicLink, navigate, search, token]);

  return (
    <Container>
      <Isotype />
      <Message>Processing login…</Message>
      <Progress />
    </Container>
  );
};

export default MagicLink;
