import { gql } from '@apollo/client';
import { USER_DATA_FRAGMENT, USER_PROGRESS_DATA_FRAGMENT } from '../fragments';

export const ME = gql`
  query Me {
    me {
      ...UserData
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const GET_SETTINGS = gql`
  query GetSettings {
    me {
      id
      settings
    }
  }
`;

export const GET_USER_ACTIVITY = gql`
  query GetUserActivity {
    me {
      id
      activity {
        minutes
        streak_days
        longest_streak
      }
    }
  }
`;

export const GET_ACTIVE_SESSIONS_COUNT = gql`
  query GetActiveSessionsCount {
    activeSessionsCount {
      total
    }
  }
`;

export const GET_USER_PROGRESS = gql`
  query GetUserProgress($userId: ID!, $offset: Int, $limit: Int) {
    progress(userId: $userId, offset: $offset, limit: $limit) {
      data {
        ...UserProgressData
      }
      total
    }
  }
  ${USER_PROGRESS_DATA_FRAGMENT}
`;

export const TRIAL_USER = gql`
  query TrialUser($uuid: String!) {
    trialUser(uuid: $uuid) {
      email
      trial_duration
    }
  }
`;
