import { useCallback, useMemo } from 'react';
import keyBy from 'lodash/keyBy';
import { trackWidgetEvent } from 'services/analytics';
import createPersonsLine from 'lib/createPersonsLine';
import useCarouselControls from 'lib/useCarouselControls';
import { Course, CoursePermission, CourseStatus } from 'models/Course';
import { PACK_ICONS, PACK_TYPE_NAME } from 'models/Pack';
import { PlayerAutoplayType, PlayerSource } from 'models/Player';
import { PackCategory } from 'graphql/generated';
import type { CardSecondaryItem } from 'components/CardSecondary/types';
import { getCourse } from 'graphql/requests';
import useSettings from 'graphql/hooks/useSettings';
import usePacks from 'graphql/hooks/usePacks';
import usePackCourses from 'graphql/hooks/usePackCourses';
import useCourseActions from 'graphql/hooks/useCourseActions';
import type { ConnectProps } from './types';

const useConnect = ({ id }: ConnectProps) => {
  const {
    loading: loadingSettings,
    settings: { recentlyPlayed },
  } = useSettings();
  const { packs, loading: loadingPacks } = usePacks();
  const { packCoursesWithPrimaryPackObj, loading: loadingPackCourses } =
    usePackCourses();
  const { play } = useCourseActions();
  const { carouselRef, handleControlClick } = useCarouselControls();

  const loading = loadingSettings || loadingPacks || loadingPackCourses;

  const continueListening = useMemo<CardSecondaryItem[]>(() => {
    if (loading) {
      return [];
    }

    const packsObj = keyBy(packs, ({ hash }) => hash);
    const recentlyPlayedObj = keyBy(
      recentlyPlayed,
      ({ course_hash }) => course_hash,
    );

    const coursesObj = recentlyPlayed.reduce<
      Record<string, { course: Course; position: number }>
    >((t, { course_hash, last_known_location }, i) => {
      const course = packCoursesWithPrimaryPackObj[course_hash];

      if (!course || course.ephemeral) {
        return t;
      }

      const { hash, isConversation, packCategory, packHash } = course;

      if (
        packCategory !== PackCategory.standard &&
        packCategory !== PackCategory.conversations &&
        packCategory !== PackCategory.qa
      ) {
        return t;
      }

      if (isConversation) {
        if (last_known_location > 0) {
          t[`C${hash}`] = { course, position: i };
        }
        return t;
      }

      if (t[`P${packHash}`]) {
        return t;
      }

      if (last_known_location > 0) {
        t[`P${packHash}`] = { course, position: i };
        return t;
      }

      const coursePackCourses = packsObj[packHash]?.courses;
      if (!coursePackCourses) return t;

      const courseIndex = coursePackCourses.findIndex(
        ({ hash: cHash }) => cHash === hash,
      );
      if (courseIndex === -1) return t;

      const sortedCourses = [
        ...coursePackCourses.slice(courseIndex + 1),
        ...coursePackCourses.slice(0, courseIndex),
      ];

      const nextCourse = sortedCourses.find(
        ({ hash: scHash, status: scStatus }) => {
          if (scStatus !== CourseStatus.FINISHED) {
            return true;
          }
          const rp = recentlyPlayedObj[scHash];
          return !!rp && rp.last_known_location > 0;
        },
      );

      if (!nextCourse) {
        t[`P${course.packHash}`] = { course, position: -1 };
        return t;
      }

      t[`P${nextCourse.packHash}`] = { course: nextCourse, position: i };

      return t;
    }, {});

    return Object.values(coursesObj)
      .filter(({ position }) => position >= 0)
      .sort((a, b) => a.position - b.position)
      .slice(0, 8)
      .map(
        (
          {
            course: {
              audioLength,
              displaySpeakers,
              hash,
              imageThumbnailsThumbX1,
              isConversation,
              packType,
              permission,
              title,
            },
          },
          index,
        ) => {
          let ariaLabel = `Play ${title} session`;
          let href;

          if (permission === CoursePermission.FORBIDDEN) {
            ariaLabel = 'Go to subscription page';
            href = '/account/subscription';
          } else if (isConversation) {
            ariaLabel = `Play ${title} conversation`;
          }

          return {
            ariaLabel,
            duration: audioLength,
            durationIcon:
              permission === CoursePermission.FORBIDDEN
                ? 'lockSimpleFilled'
                : 'playRoundedLight',
            href,
            imageUrl: imageThumbnailsThumbX1,
            itemId: hash,
            itemType: isConversation ? 'conversation' : 'session',
            overline: PACK_TYPE_NAME[packType],
            overlineIcon: PACK_ICONS[packType],
            position: index,
            subtitle: createPersonsLine(displaySpeakers),
            title,
          };
        },
      );
  }, [loading, packCoursesWithPrimaryPackObj, packs, recentlyPlayed]);

  const handleItemClick = useCallback(
    async (item: CardSecondaryItem) => {
      const course = await getCourse(item.itemId);

      if (!course || course.permission === CoursePermission.FORBIDDEN) {
        return;
      }

      play({
        autoplayType: PlayerAutoplayType.PACK,
        courseId: item.itemId,
        packId: course.packId,
        source: PlayerSource.CONTINUE_LISTENING,
      });

      trackWidgetEvent(id, {
        category: item.overline?.toLowerCase(),
        content_caption: item.caption,
        content_position: item.position,
        content_type: item.itemType,
        duration: item.duration,
        hash: course.hash,
        name: item.title,
        pack_hash: course.packHash,
        pack_name: course.packTitle,
      });
    },
    [id, play],
  );

  return {
    carouselRef,
    continueListening,
    handleControlClick,
    handleItemClick,
    loading,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
