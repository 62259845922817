import { memo, useCallback } from 'react';
import type { FC } from 'react';
import { trackEvent } from 'services/analytics';
import type { Panel } from 'components/Panels/types';
import { Container, Header, Overline, Panels, Subtitle, Title } from './styles';
import type { Props } from './types';
import Pack from './Pack';

const RetreatPack: FC<Props> = (retreat) => {
  const { about, faq, hash, pack, subtitle, title } = retreat;

  const panels = [] as Panel[];
  if (pack) panels.push({ info: <Pack {...retreat} />, title: 'Agenda' });
  if (about) panels.push({ info: about, title: 'About the Retreat' });
  if (faq) panels.push({ info: faq, title: 'FAQ' });

  const handleTabChange = useCallback(
    (index: number) => {
      if (index === 1 || index === 2)
        trackEvent('Event Information', {
          event_hash: hash,
          event_type: 'retreat',
          name: title,
          tab: index === 2 ? 'faq' : 'about_retreat',
          screen: 'pause_agenda',
        });
    },
    [hash, title],
  );

  return (
    <Container>
      <Header>
        <Overline>Happening Now</Overline>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Header>
      <Panels data={panels} onTabChange={handleTabChange} />
    </Container>
  );
};

export default memo(RetreatPack);
