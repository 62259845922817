import { memo, useCallback } from 'react';
import type { FC } from 'react';
import { trackEvent } from 'services/analytics';
import { CourseStatus } from 'models/Course';
import { PlayerSource } from 'models/Player';
import { getCourse, getSettings } from 'graphql/requests';
import usePlayerActions from 'graphql/hooks/usePlayerActions';
import { Button, Icon } from '../styles';
import type { Props } from './types';

const LearnMore: FC<Props> = ({ hash, slug, subtitle, title, trailer }) => {
  const { openRetreatPlayer } = usePlayerActions();

  const trailerHash = trailer?.hash;

  const handleClick = useCallback(async () => {
    trackEvent('Home CTA Click', {
      button_text: 'Learn More',
      deep_link: `/retreat/${hash}`,
      event_hash: hash,
      message: subtitle,
      title,
    });

    if (trailerHash) {
      const course = await getCourse(trailerHash);

      if (course && course.status !== CourseStatus.FINISHED) {
        const { recentlyPlayed } = await getSettings();

        if (!recentlyPlayed.some((rp) => rp.course_hash === course.hash)) {
          openRetreatPlayer({
            hash: trailerHash,
            source: PlayerSource.RETREAT,
          });
        }
      }
    }
  }, [hash, openRetreatPlayer, subtitle, title, trailerHash]);

  return (
    <Button
      onClick={handleClick}
      state={{ screen: 'home_cta' }}
      to={`/practice/retreats/${slug}`}
    >
      <Icon name="playLight" />
      Learn More
    </Button>
  );
};

export default memo(LearnMore);
