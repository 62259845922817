import styled from 'styled-components';
import { Form as FormikForm } from 'formik';
import { motion } from 'framer-motion';
import DefaultInputWithButton from 'components/Inputs/InputWithButton';

export const Form = styled(FormikForm)`
  flex: 1 1 auto;
  margin-bottom: 1.5rem;
  max-width: 25rem;
`;

export const InputWithButton = styled(DefaultInputWithButton)``;

export const Error = styled(motion.div).attrs(() => ({
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  transition: { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] },
}))`
  color: ${({ theme }) => theme.colors.coreRed500};
  font-size: 0.75rem;
  overflow: hidden;
  padding-top: 0.5rem;
`;
