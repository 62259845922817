import styled from 'styled-components';
import { FixedSizeList as DefaultList } from 'react-window';
import DefaultCardSecondary from 'components/CardSecondary';
import { media } from 'styles/mixins';

export const Container = styled.div`
  margin-left: -0.2rem;

  ${media.tabletPortrait} {
    margin-left: -1.4rem;
    margin-right: -1.4rem;
  }

  ${media.phonePortrait} {
    margin-left: -1rem;
    margin-right: -1rem;
  }
`;

export const List = styled(DefaultList)`
  margin: 0 !important;
  overflow-y: hidden !important;

  &::-webkit-scrollbar {
    height: 0;
  }
`;

export const Item = styled(DefaultCardSecondary)`
  &:first-child {
    margin-left: 0.22rem;
  }

  ${media.tabletPortrait} {
    &:first-child {
      margin-left: 1.4rem;
    }
  }

  ${media.phonePortrait} {
    &:first-child {
      margin-left: 1rem;
    }
  }
`;
