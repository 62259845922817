import { memo } from 'react';
import type { FC } from 'react';
import { Container, Isotype, Link } from './styles';
import type { Props } from './types';

const Header: FC<Props> = ({ className, hideLogin = false }) => (
  <Container className={className}>
    <Isotype />
    {!hideLogin && <Link href="/">Log in</Link>}
  </Container>
);

export default memo(Header);
