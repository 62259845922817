import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { trackMediaEvent } from 'services/analytics';
import { DailyPermission } from 'models/Daily';
import { DailyMeditationDuration } from 'models/DailyMeditationDuration';
import { PlayerAutoplayType, PlayerSource } from 'models/Player';
import {
  AddProgressDocument,
  BookmarkType,
  CreateSharedDailyDocument,
  DailyStatus,
} from '../generated';
import { cacheUpdateDailyFragment } from '../cache';
import { getBookmark, getDaily, getSharedDaily } from '../requests';
import useModalActions from './useModalActions';
import usePlayerActions from './usePlayerActions';

const useDailyActions = () => {
  const { closeModal, openDialogModal } = useModalActions();
  const { openDailyPlayer } = usePlayerActions();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [addProgressMutation] = useMutation(AddProgressDocument);

  const [createSharedDailyMutation, { loading: loadingCreateShared }] =
    useMutation(CreateSharedDailyDocument);

  const playDaily = useCallback(
    async ({
      source,
      hash,
      autoplayType,
    }: {
      source?: PlayerSource;
      hash?: string;
      autoplayType?: PlayerAutoplayType;
    }) => {
      let daily;
      if (hash) {
        daily = await getBookmark(hash, BookmarkType.daily);
      } else {
        daily = await getDaily();
      }

      if (!daily) return;

      if (daily.permission === DailyPermission.UNLOCKED) {
        await openDailyPlayer({ autoplayType, source, hash });
      } else if (daily.permission === DailyPermission.FORBIDDEN) {
        navigate('/account/subscription', {
          replace: true,
          state: {
            source: 'practice',
            trackID: daily.hash,
            type: 'content',
          },
        });
      } else if (daily.permission === DailyPermission.LOCKED) {
        openDialogModal({
          cancelButtonText: 'Ok',
          description:
            'Daily Meditation will be available when you complete the Introductory Course.',
          onCancel: closeModal,
        });
        trackMediaEvent('Media Special Content Challenge', daily.hash);
      }
    },
    [closeModal, navigate, openDailyPlayer, openDialogModal],
  );

  const markAsListened = useCallback(
    async (duration: DailyMeditationDuration, sharedHash?: string) => {
      try {
        const daily = sharedHash
          ? await getSharedDaily({ variables: { shared_hash: sharedHash } })
          : await getDaily();
        if (!daily) return;

        const durationSeconds =
          duration === DailyMeditationDuration.MIN_20
            ? daily.dailyAudio2Length
            : daily.dailyAudio1Length;

        await addProgressMutation({
          variables: {
            data: {
              duration_seconds: durationSeconds,
              finished_at: new Date().toISOString(),
              progress_hash: daily.hash,
              progress_type: 'daily',
            },
          },
        });

        cacheUpdateDailyFragment(daily.hash, { status: DailyStatus.finished });
      } catch (error) {
        enqueueSnackbar(error?.message || 'Unable to mark track as listened', {
          variant: 'error',
        });
      }
    },
    [addProgressMutation, enqueueSnackbar],
  );

  const createSharedDaily = useCallback(
    async ({ dailyId }: { dailyId: string }) => {
      try {
        const { data } = await createSharedDailyMutation({
          variables: { daily_id: dailyId },
        });

        return data?.createSharedDaily;
      } catch (error) {
        enqueueSnackbar(
          error?.message || 'Unable to create a daily share link',
          { variant: 'error' },
        );
      }
    },
    [enqueueSnackbar, createSharedDailyMutation],
  );

  return {
    createSharedDaily,
    isCreatingShared: loadingCreateShared,
    markAsListened,
    playDaily,
  };
};

export default useDailyActions;
export type UseDailyActions = ReturnType<typeof useDailyActions>;
