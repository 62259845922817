import type { FC } from 'react';
import Page from '../Page';
import type { Props } from './types';

const Info: FC<Props> = ({ title, html }) => (
  <Page title={title}>
    <div
      id="wu-pages-info-container"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  </Page>
);

export default Info;
