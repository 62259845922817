import styled from 'styled-components';
import DefaultSectionHeader from 'components/DeprecatedSectionHeader';
import DefaultForm from 'components/Form';
import DefaultTextField from 'components/Inputs/TextField';
import { media } from 'styles/mixins';

export const Container = styled.div`
  flex: 1;
`;

export const Header = styled(DefaultSectionHeader)`
  ${media.tabletPortrait} {
    display: none;
  }
`;

export const Title = styled.h1`
  color: inherit;
  font-size: 2.25rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: 1.75rem;
  margin-top: 3.75rem;
  text-align: center;

  ${media.tabletPortrait} {
    margin-top: 1.25rem;
  }

  ${media.phoneLandscape} {
    margin-top: 0;
  }
`;

export const Form = styled(DefaultForm)`
  color: ${({ theme }) => theme.colors.fontBase};
`;

export const TextField = styled(DefaultTextField)`
  margin-bottom: 1.25rem;
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.coreRed500};
  font-weight: ${({ theme }) => theme.font.weight.book};
  margin-top: 1.25rem;
  text-align: center;
`;

export const Contact = styled.p`
  font-weight: ${({ theme }) => theme.font.weight.book};
  margin-top: 0.5rem;
  text-align: center;

  a {
    color: inherit;
  }
`;
