import { memo, useCallback } from 'react';
import type { FC, MouseEventHandler } from 'react';
import {
  Container,
  Duration,
  Info,
  PlayWithProgress,
  Pretitle,
  Title,
} from './styles';
import type { Props } from './types';

const Track: FC<Props> = ({
  duration,
  hash,
  onClick,
  pretitle,
  progress,
  title,
}) => {
  const isClickable = !!hash && !!onClick;

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (hash && onClick) {
        onClick(hash);
      }
    },
    [hash, onClick],
  );

  return (
    <Container
      $isButton={isClickable}
      as={isClickable ? 'button' : 'li'}
      onClick={isClickable ? handleClick : undefined}
    >
      <Info>
        <Pretitle>{pretitle}</Pretitle>
        <Title>{title}</Title>
      </Info>
      <Duration>{duration}</Duration>
      {progress !== undefined && <PlayWithProgress progress={progress} />}
    </Container>
  );
};

export default memo(Track);
