import { useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse } from 'query-string';
import { media } from 'styles/mixins';
import { GiftCardResultParam, GiftCardTypeParam } from 'models/GiftCard';
import { GiftCardType } from 'graphql/generated';
import useGiftCardPrices from 'graphql/hooks/useGiftCardPrices';
import useMediaQuery from 'lib/useMediaQuery';
import { GIFT_CARD_IMAGES } from './constants';
import type { RouteState } from './types';

const useConnect = () => {
  const { giftCardPrices, loading } = useGiftCardPrices();
  const navigate = useNavigate();
  const isTabletPortrait = useMediaQuery(media.tabletPortrait);
  const location = useLocation();
  const state = location.state as RouteState;
  const { search, pathname } = location;

  const { t, r } = parse(search);

  const prevImageCaption = state?.prevImageCaption || '';
  const prevImageSrc = state?.prevImageSrc || '';
  const message = state?.message || '';
  const name = state?.name || '';
  const recipientEmail = state?.recipientEmail || '';
  const shouldBeSentAt = state?.shouldBeSentAt || '';

  const resultParam = useMemo(
    () =>
      typeof r === 'string' &&
      Object.values(GiftCardResultParam).includes(r as GiftCardResultParam)
        ? (r as GiftCardResultParam)
        : undefined,
    [r],
  );

  const typeParam = useMemo(
    () =>
      typeof t === 'string' &&
      Object.values(GiftCardTypeParam).includes(t as GiftCardTypeParam)
        ? (t as GiftCardTypeParam)
        : undefined,
    [t],
  );

  const { imageCaption, imageSrc } = GIFT_CARD_IMAGES[typeParam || 'all'];

  const giftCard = giftCardPrices.find((v) => v.param === typeParam);
  const giftCardAmount = giftCard?.amount || 0;
  const giftCardDuration = giftCard?.duration || '';
  const giftCardType = giftCard?.type || ('' as GiftCardType);

  const handleBack = useCallback(() => {
    if (t && typeof t === 'string') {
      navigate(`${pathname}${search.replace(RegExp(`t=${t}`), '')}`, {
        replace: true,
      });
    }
  }, [navigate, pathname, search, t]);

  return {
    currentUrl: `${pathname}${search ? `${search}&` : '?'}`,
    giftCardAmount,
    giftCardDuration,
    giftCardPrices,
    giftCardType,
    handleBack,
    imageCaption: prevImageCaption || imageCaption,
    imageSrc: prevImageSrc || imageSrc,
    isLoading: loading,
    isTabletPortrait,
    message,
    name,
    recipientEmail,
    resultParam,
    shouldBeSentAt,
    typeParam,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
