import getPackCourses from './getPackCourses';

const getPackCourse = async (courseKey?: string, packKey?: string) => {
  const { packCourses } = await getPackCourses();

  if (!courseKey) return undefined;

  const lwCourseKey = courseKey.toLowerCase();
  const filteredPackCourses = packCourses.filter(
    ({ bdId, hash, id }) =>
      bdId.toLowerCase() === lwCourseKey ||
      hash.toLowerCase() === lwCourseKey ||
      id.toLowerCase() === lwCourseKey,
  );

  if (filteredPackCourses.length === 0) {
    return undefined;
  }

  if (filteredPackCourses.length === 1) {
    return filteredPackCourses[0];
  }

  if (packKey) {
    const lwPackKey = packKey.toLowerCase();
    const packCourse = filteredPackCourses.find(
      ({ packHash, packId }) =>
        packHash.toLowerCase() === lwPackKey ||
        packId.toLowerCase() === lwPackKey,
    );
    if (packCourse) return packCourse;
  }

  return (
    filteredPackCourses.find(({ packIsPrimaryPack }) => packIsPrimaryPack) ||
    filteredPackCourses.find(({ isIntroCourse }) => !isIntroCourse) ||
    filteredPackCourses[0]
  );
};

export default getPackCourse;
