/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { BOOKMARKS_DATA_FRAGMENT } from '../fragments/bookmarks';

export const GET_BOOKMARKS = gql`
  query GetBookmarks {
    bookmarks {
      ...BookmarksData
    }
  }
  ${BOOKMARKS_DATA_FRAGMENT}
`;
