import { useEffect } from 'react';
import type { FC } from 'react';
import { Routes } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Props } from './types';
import { getKey } from './logic';

const AnimatedRoutes: FC<Props> = ({ location, children, className }) => {
  const pathname = location?.pathname || '';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!location) {
    return null;
  }

  const key = getKey(pathname);

  return (
    <AnimatePresence initial={false}>
      <motion.div
        key={key}
        exit={{ opacity: 0, y: 5 }}
        initial={{ opacity: 0, y: -5 }}
        animate={{ opacity: 1, y: 0 }}
        className={className}
        transition={{
          duration: 0.225,
        }}
      >
        <Routes location={location} key={key}>
          {children}
        </Routes>
      </motion.div>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
