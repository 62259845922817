import format from 'date-fns/format';
import {
  DAILY_MEDITATION_PACK_HASH,
  DAILY_MEDITATION_PACK_ID,
} from 'models/Pack';
import { type DailyDataFragment, DailyStatus } from 'graphql/generated';

export enum DailyPermission {
  UNLOCKED = 'unlocked',
  LOCKED = 'locked',
  FORBIDDEN = 'forbidden',
}

export const getDailyPermission = (
  isSubscribed: boolean,
  isDailyUnlocked: boolean,
) => {
  if (!isSubscribed) {
    return DailyPermission.FORBIDDEN;
  }
  if (!isDailyUnlocked) {
    return DailyPermission.LOCKED;
  }
  return DailyPermission.UNLOCKED;
};

export const normalizeDaily = (daily: DailyDataFragment) => ({
  bookmarkedAt: (daily.bookmarked_at as string) || '',
  dailyAudioId: daily.daily_audio.id || '',
  dailyAudio1Length: daily.daily_audio.audio1.length || 0,
  dailyAudio1Url: daily.daily_audio.audio1.url || '',
  dailyAudio2Length: daily.daily_audio.audio2.length || 0,
  dailyAudio2Url: daily.daily_audio.audio2.url || '',
  dailyAudioIsGuest: daily.daily_audio.is_guest ?? false,
  dailyAudioSubtitle: daily.daily_audio.subtitle || '',
  date: (daily.date as string) || '',
  hash: daily.hash || '',
  id: daily.id,
  imageBackgroundColor: daily.image?.background_color
    ? `#${daily.image.background_color}`
    : '',
  imageUrl: daily.image?.url || '',
  imageThumbnailsThumbX1: daily.image?.thumbnails?.thumb_x1 || '',
  imageThumbnailsThumbX2: daily.image?.thumbnails?.thumb_x2 || '',
  isBookmark: daily.is_bookmark ?? false,
  isSpecial: daily.is_special ?? false,
  packId: DAILY_MEDITATION_PACK_ID,
  packHash: DAILY_MEDITATION_PACK_HASH,
  packTitle: 'Daily Meditation',
  permission: DailyPermission.FORBIDDEN,
  progress: 0,
  status: daily.status || DailyStatus.unstarted,
  subtitle: format(new Date(), 'd MMMM yyyy'),
  title: 'Daily Meditation',
});

export type Daily = ReturnType<typeof normalizeDaily>;
