import getHome from './getHome';

const getWidget = async (widgetId: string) => {
  const home = await getHome();

  if (home.length === 0) {
    return undefined;
  }

  const lwWidgetId = widgetId.toLowerCase();

  return home.find(({ id }) => id.toLowerCase() === lwWidgetId);
};

export default getWidget;
