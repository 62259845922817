import { memo } from 'react';
import type { FC } from 'react';
import {
  ConfirmationContainer,
  Container,
  Description,
  Link,
  Title,
} from './styles';

const ConfirmationMsg: FC = () => (
  <Container>
    <ConfirmationContainer>
      <Title>Your subscription is on the way</Title>
      <Description>
        If you haven't received your subscription activation email within 10
        days, please check your spam folder before contacting
        <Link href="mailto:support@wakingup.com">support@wakingup.com</Link>.
      </Description>
    </ConfirmationContainer>
  </Container>
);

export default memo(ConfirmationMsg);
