import { memo, type FC } from 'react';
import {
  Container,
  CtaBig,
  CtaSmall,
  EqualizerIcon,
  Info,
  LiveFlag,
  PlayIcon,
  Subtitle,
  Title,
} from './styles';
import useConnect from './connect';

const LiveAudio: FC = () => {
  const { active, subtitle, title } = useConnect();

  if (!active) {
    return null;
  }

  return (
    <Container to="/join-live">
      <Info>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <CtaSmall>
          <PlayIcon aria-hidden="true" name="playRoundedLight" />
          Listen Now
        </CtaSmall>
      </Info>
      <CtaBig>Open In App to Listen Live</CtaBig>
      <LiveFlag>
        <EqualizerIcon aria-hidden="true" name="equalizerStatic" />
        Live
      </LiveFlag>
    </Container>
  );
};

export default memo(LiveAudio);
