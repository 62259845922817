import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultIsotype from 'components/Isotype';
import DefaultLogoBlackStacked from 'components/LogoBlackStacked';

export const Container = styled.div`
  align-items: center;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.25rem grey;
  display: flex;
  margin: 1.25rem 0;
  padding: 2rem 1.4rem;

  ${media.phoneLandscape} {
    flex-flow: column nowrap;
  }
`;

export const Isotype = styled(DefaultIsotype)`
  display: block;
  margin: 0 2.8rem 0 1.8rem;
  width: 3.6rem;

  ${media.phoneLandscape} {
    display: none;
  }
`;

export const Logo = styled(DefaultLogoBlackStacked).attrs(() => ({
  size: 128,
}))`
  display: block;
  margin: 0 1.6rem 1.6rem 0.4rem;

  ${media.phoneLandscape} {
    margin: 0 0 1.6rem 0;
  }
`;

export const Content = styled.div`
  ${media.phoneLandscape} {
    text-align: center;
  }
`;

export const Title = styled.h3`
  font-weight: bold;
`;

export const SourceMessage = styled.p``;

export const FamilySharing = styled.p`
  white-space: nowrap;
`;

export const Item = styled.div`
  opacity: 0.5;
`;
