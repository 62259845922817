import type { FC } from 'react';
import { Props } from './types';

const ShareLight: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="m16.5 14.25 4.5-4.5-4.5-4.5m1.5 15H3.75A.75.75 0 0 1 3 19.5V8.25"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.034 16.5a9.003 9.003 0 0 1 8.717-6.75H21"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ShareLight;
