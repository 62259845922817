import { memo, type FC } from 'react';
import { AnimatePresence } from 'framer-motion';
import formatCents from 'lib/formatCents';
import Option from './Option';
import {
  Buttons,
  Container,
  Description,
  Error,
  Label,
  Loader,
  MembershipInfo,
} from './styles';
import { Props } from './types';
import useLogic from './logic';

const PriceSelector: FC<Props> = ({
  className,
  description,
  fullScholarshipPrices,
  isLoading,
  label,
  name,
}) => {
  const { handleSelectPrice, error, touched, value } = useLogic({ name });

  const membershipText =
    value && value !== 0 ? (
      <>
        You will receive <strong>one year of membership</strong> for{' '}
        {formatCents(value).split('.')[0]}.
      </>
    ) : (
      <>
        You will receive <strong>six months of membership</strong> at no cost.
      </>
    );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container className={className}>
      <Label>{label}</Label>
      <Description>{description}</Description>
      <Buttons
        aria-label="choose-a-cost"
        className={className}
        role="radiogroup"
      >
        {fullScholarshipPrices.map((fsPrice) => (
          <Option
            key={fsPrice.price}
            description={fsPrice.description}
            isSelected={fsPrice.price === value}
            onClick={handleSelectPrice}
            price={fsPrice.price}
          />
        ))}
      </Buttons>
      <AnimatePresence initial={false}>
        {touched && error && <Error>{error}</Error>}
      </AnimatePresence>
      {value !== null && <MembershipInfo>{membershipText}</MembershipInfo>}
    </Container>
  );
};

export default memo(PriceSelector);
