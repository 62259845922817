import type { FC } from 'react';
import { Container, Slider } from './styles';
import { Props } from '../types';
import useLogic from '../logic';

const ProgressBarMinimized: FC<Props> = ({
  className,
  duration,
  onChangePosition = () => null,
  position = 0,
}) => {
  const { handleSliderClick, localPosition } = useLogic({
    duration,
    onChangePosition,
    position,
  });

  return (
    <Container className={className}>
      <Slider
        max={duration}
        min={0}
        onClick={handleSliderClick}
        step={1}
        value={!duration ? 0 : localPosition}
      />
    </Container>
  );
};

export default ProgressBarMinimized;
