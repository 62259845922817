import { useCallback, ChangeEvent } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { Container, Label, Search } from './styles';

const SearchInput = connectSearchBox(({ currentRefinement, refine }) => {
  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      refine(e.target.value);
    },
    [refine],
  );

  const handleClear = useCallback(() => {
    refine('');
  }, [refine]);

  return (
    <Container>
      <Label hidden htmlFor="search-box">
        Titles, topics or speakers
      </Label>
      <Search
        debounce={400}
        id="search-box"
        onChange={handleSearchChange}
        onClear={handleClear}
        placeholder="Titles, topics, or speakers"
        value={currentRefinement}
      />
    </Container>
  );
});

export default SearchInput;
