import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { parse } from 'query-string';
import { trackEvent } from 'services/analytics';
import {
  LoginStatus,
  loginStatusVar,
  setLoginStatusVar,
} from 'graphql/reactive';
import { Step } from './EmailLogin/types';

const useConnect = () => {
  const location = useLocation();
  const state = location.state as { title?: string };
  const { search } = location;
  const loginStatus = useReactiveVar(loginStatusVar);

  const { auto, email: searchEmail } = parse(search);
  const initialEmail = typeof searchEmail === 'string' ? searchEmail : '';
  const initialStep = initialEmail && auto === 'true' ? Step.pin : Step.email;

  const title = state?.title || 'Sign In';

  useEffect(() => {
    trackEvent('Login Begin', {
      url: window.location.href,
      refurl: document.referrer,
    });
  }, []);

  useEffect(() => {
    if (initialStep === Step.pin) {
      setLoginStatusVar(LoginStatus.STARTED);
    }
  }, [initialStep]);

  return {
    initialEmail,
    initialStep,
    loginStatus,
    queryString: search || undefined,
    title,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
