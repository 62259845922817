import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import { ModalType } from 'graphql/reactive';

const useConnect = () => {
  const { isOpen } = useModal(ModalType.DAILY_DURATION);
  const { closeModal } = useModalActions();

  return { isOpen, closeModal };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
