import { type GetGiftCardPricesQuery, GiftCardType } from 'graphql/generated';

export enum GiftCardTypeParam {
  THREE_MONTHS = '3m',
  ONE_YEAR = '1y',
  ONE_YEAR30 = '1y30',
  LIFETIME = 'l',
}

export enum GiftCardResultParam {
  SUCCESS = 's',
  ERROR = 'e',
}

export const GIFTCARDPARAMS = {
  [GiftCardType.THREE_MONTHS]: GiftCardTypeParam.THREE_MONTHS,
  [GiftCardType.ONE_YEAR]: GiftCardTypeParam.ONE_YEAR,
  [GiftCardType.ONE_YEAR30]: GiftCardTypeParam.ONE_YEAR30,
  [GiftCardType.LIFETIME]: GiftCardTypeParam.LIFETIME,
};

export const normalizeGiftCardPrice = (
  giftCardPrice: NonNullable<GetGiftCardPricesQuery['giftCardPrices']>[number],
) => {
  const type = giftCardPrice.type || GiftCardType.ONE_YEAR;
  const param = GIFTCARDPARAMS[type];

  return {
    amount: giftCardPrice.amount || 0,
    amountDecimal: giftCardPrice.amount_decimal || '',
    duration: giftCardPrice.duration || '',
    param,
    type,
    urlSuffix: `t=${param}`,
  };
};

export type Avatar = ReturnType<typeof normalizeGiftCardPrice>;
