import styled from 'styled-components';
import DefaultButton from 'components/Button';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  max-width: 24rem;
  width: 100%;
`;

export const Button = styled(DefaultButton)`
  margin-bottom: 0.875rem;
`;

export const SecondaryButton = styled(DefaultButton).attrs(() => ({
  mode: 'deemphasized',
}))`
  background: transparent;
  margin-bottom: 0.875rem;
`;

export const ReservedSpot = styled.p`
  font-size: 0.875rem;
  line-height: 1.4286;
  letter-spacing: 0.01em;
  margin-bottom: 1.3rem;
`;
