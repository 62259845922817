import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultImage from 'components/Image';
import DefaultPanels from 'components/Panels';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;

  ${media.phoneLandscape} {
    margin-top: -1rem;
  }
`;

export const Card = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 2rem;
  max-width: 20rem;
  text-align: center;
  width: 90%;
`;

export const Image = styled(DefaultImage)`
  border-radius: 0.25rem;
  box-shadow: 17.3946px 41.7075px 51.9417px rgba(0, 0, 0, 0.115977),
    10.4143px 24.9707px 29.6583px rgba(0, 0, 0, 0.0978192),
    5.45406px 13.0773px 16.4152px rgba(0, 0, 0, 0.0821808),
    2.24447px 5.38162px 9.11953px rgba(0, 0, 0, 0.0640233),
    0.516228px 1.23777px 4.67872px rgba(0, 0, 0, 0.038309);
  display: block;
  margin-bottom: 2.25rem;
  width: 100%;
`;

export const Overline = styled.p`
  color: ${({ theme }) => theme.colors.fontEmphasized};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.2em;
  margin-bottom: 0.325rem;
  text-transform: uppercase;
`;

export const Title = styled.h1`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 1.7rem;
  line-height: 1.075;
  letter-spacing: 0.01em;
`;

export const Subtitle = styled.p`
  font-size: 1rem;
  line-height: 1.33;
  letter-spacing: 0.01em;
  margin-top: 0.45rem;
`;

export const Attending = styled.p`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.4286;
  letter-spacing: 0.01em;
`;

export const Panels = styled(DefaultPanels)`
  align-self: stretch;
  font-size: 0.875rem;
  margin-top: 2.25rem;
`;
