import { memo, type FC } from 'react';
import { Content, Icon, Container, Subtitle, Title } from './styles';

const NoRecentlyPlayed: FC = () => (
  <Container>
    <Content>
      <Icon aria-hidden="true" name="arrowUUpLeftLight" />
      <Title>You don't have any recently played content</Title>
      <Subtitle>
        Play any series or meditation and it will be added to the recently
        played list.
      </Subtitle>
    </Content>
  </Container>
);

export default memo(NoRecentlyPlayed);
