/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import DefaultModal from 'components/Modal';
import { media } from 'styles/mixins';

export const Modal = styled(DefaultModal)`
  .MuiPaper-root {
    color: ${({ theme }) =>
      theme.name === 'LIGHT'
        ? theme.colors.coreNeutralAbyss
        : theme.colors.coreNeutralAurora};
    justify-content: space-between;
    max-width: 25.5rem;
    min-height: 42rem;
    padding: 2rem 1.6rem;
    width: 100%;
  }

  .MuiPaper-root {
    ${media.phoneLandscape} {
      padding: 2rem 1rem;
      max-width: 100vw;
    }
  }

  .MuiPaper-root {
    @media (max-height: 450px) {
      border-radius: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      max-height: none;
      max-width: none;
      min-height: unset;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
`;
