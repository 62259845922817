import { memo, type FC } from 'react';
import { CreditCardBrand } from 'models/StripePaymentMethod';
import { ICONS } from './constants';
import type { Props } from './types';

const CreditCardIcon: FC<Props> = ({
  name = CreditCardBrand.GENERIC,
  ...rest
}) => {
  const SelectedIcon = ICONS[name] ?? ICONS[CreditCardBrand.GENERIC];

  return <SelectedIcon {...rest} />;
};

export default memo(CreditCardIcon);
