import { memo } from 'react';
import type { FC } from 'react';
import parseISO from 'date-fns/parseISO';
import differenceInHours from 'date-fns/differenceInHours';
import useConnect from './connect';
import { Button, Cancel, Modal, Overline, Subtitle, Title } from './styles';

const DismissRetreat: FC = () => {
  const {
    handleClose,
    handleNotInterested,
    handleRemind,
    isOpen,
    pretitle,
    startDate,
    subtitle,
    title,
  } = useConnect();

  const showRemindMeLater =
    !!startDate && differenceInHours(parseISO(startDate), new Date()) >= 24;

  return (
    <Modal
      descriptionId="dismiss-retreat-description"
      disableBackdropClick
      fullscreenOnMobile
      hideClose
      onClose={handleClose}
      open={isOpen}
      titleId="dismiss-retreat-title"
    >
      <Overline>{pretitle}</Overline>
      <Title id="dismiss-retreat-title">{title}</Title>
      <Subtitle id="dismiss-retreat-description">{subtitle}</Subtitle>
      {showRemindMeLater && (
        <Button onClick={handleRemind}>Remind me later</Button>
      )}
      <Button onClick={handleNotInterested}>I'm not interested</Button>
      <Cancel onClick={handleClose}>Cancel</Cancel>
    </Modal>
  );
};

export default memo(DismissRetreat);
