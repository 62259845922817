import type { FC } from 'react';
import type { Props } from './types';

const LinkBreakLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M9 6.75V4.5m6 15v-2.25M6.75 9H4.5m15 6h-2.25M6.66 12.037l-1.061 1.061a3.75 3.75 0 0 0 5.303 5.303l1.06-1.06m5.378-5.378 1.061-1.06a3.75 3.75 0 0 0-5.303-5.304l-1.06 1.06"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LinkBreakLight;
