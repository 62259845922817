import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import {
  AddProgressDocument,
  AddProgressStartDocument,
  UpdateNotificationsDocument,
  UpdateUserProfileDocument,
  UserNotificationType,
  type UpdateUserProfileMutationVariables,
} from '../generated';
import { ME } from '../queries';
import { formatErrorMessage } from '../helpers';

const useMeActions = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [addProgressStartMutation] = useMutation(AddProgressStartDocument);

  const [addProgressMutation] = useMutation(AddProgressDocument, {
    refetchQueries: [{ query: ME }],
  });

  const [updateUserProfileMutation] = useMutation(UpdateUserProfileDocument);

  const addProgressStart = useCallback(
    async ({
      mediaDuration,
      courseHash,
    }: {
      mediaDuration: number;
      courseHash?: string;
    }) => {
      try {
        const response = await addProgressStartMutation({
          variables: {
            data: {
              media_duration: mediaDuration,
              course_hash: courseHash || undefined,
            },
          },
        });
        return response.data?.addProgressStart?.id || null;
      } catch (error) {
        enqueueSnackbar(error?.message || 'Unable to add progress start', {
          variant: 'error',
        });
        return null;
      }
    },
    [addProgressStartMutation, enqueueSnackbar],
  );

  const [updateNotificationsMutation] = useMutation(
    UpdateNotificationsDocument,
  );

  const addUserProgress = useCallback(
    async (progressData: { duration_seconds: number; finished_at: Date }) => {
      try {
        await addProgressMutation({
          variables: {
            data: {
              ...progressData,
              progress_type: 'selftimer',
            },
          },
        });
      } catch (error) {
        throw new Error(
          formatErrorMessage(error, 'Unable to add user progress'),
        );
      }
    },
    [addProgressMutation],
  );

  const updateProfile = useCallback(
    async (values: UpdateUserProfileMutationVariables) => {
      updateUserProfileMutation({
        variables: values,
      });
    },
    [updateUserProfileMutation],
  );

  const updateNotifications = useCallback(
    async (values: { id: UserNotificationType }[]) => {
      const { data } = await updateNotificationsMutation({
        variables: { data: values },
      });

      return data?.updateNotifications;
    },
    [updateNotificationsMutation],
  );

  return {
    addProgressStart,
    addUserProgress,
    updateNotifications,
    updateProfile,
  };
};

export default useMeActions;
export type UseMeActions = ReturnType<typeof useMeActions>;
