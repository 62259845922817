import { memo } from 'react';
import type { FC } from 'react';
import DurationSelector from './DurationSelector';
import { Actions } from '../styles';
import { Container, FinishTime, IntervalButton, StartButton } from './styles';
import type { Props } from './types';
import { useFinishTime } from './logic';
import useConnect from './connect';

const MainSection: FC<Props> = ({
  duration,
  intervalTime,
  isTimeSectionOpen,
  setDuration,
  setIsTimeSectionOpen,
}) => {
  const {
    begin,
    decrementDuration,
    incrementDuration,
    toogleTimeSection,
    validIntervalTimeText,
  } = useConnect({ duration, intervalTime, setDuration, setIsTimeSectionOpen });
  const finishTime = useFinishTime({ duration });

  return (
    <Container $isTimeSectionOpen={isTimeSectionOpen}>
      <DurationSelector
        decrementDuration={decrementDuration}
        duration={duration}
        incrementDuration={incrementDuration}
        setDuration={setDuration}
      />
      <Actions>
        <StartButton
          data-cypress-id="self-timer-begin-button"
          id="timer-begin-button"
          onClick={begin}
        >
          Begin
        </StartButton>
        <IntervalButton
          active={isTimeSectionOpen}
          aria-controls="intervals-section"
          aria-expanded={isTimeSectionOpen}
          data-cypress-id="self-timer-interval-time-section-link"
          onClick={toogleTimeSection}
        >
          {validIntervalTimeText}
        </IntervalButton>
      </Actions>
      <FinishTime data-cypress-id="self-timer-finishes-at">
        Timer finishes at {finishTime}
      </FinishTime>
    </Container>
  );
};

export default memo(MainSection);
