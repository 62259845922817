import { useCallback } from 'react';
import type { FC } from 'react';
import { Container, Icon } from './styles';
import { Props } from './types';

const Option: FC<Props> = ({ active, iconName, nextValue, onClick, text }) => {
  const handleClick = useCallback(() => {
    onClick(nextValue);
  }, [nextValue, onClick]);

  return (
    <Container onClick={handleClick} type="button">
      {text}
      <Icon $active={active} name={iconName} />
    </Container>
  );
};

export default Option;
