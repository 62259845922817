import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { trackMediaEvent } from 'services/analytics';
import {
  AddBookmarkDocument,
  BookmarkType,
  RemoveBookmarkDocument,
} from '../generated';
import { cacheUpdateCourseFragment } from '../cache';
import { GET_BOOKMARKS } from '../queries';
import { getBookmark } from '../requests';

const useBookmarksActions = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [addBookmarkMutation, { loading: isAdding }] = useMutation(
    AddBookmarkDocument,
    { refetchQueries: [{ query: GET_BOOKMARKS }] },
  );

  const [removeBookmarkMutation, { loading: isRemoving }] = useMutation(
    RemoveBookmarkDocument,
    { refetchQueries: [{ query: GET_BOOKMARKS }] },
  );

  const toggleBookmark = useCallback(
    async (hash: string, type: BookmarkType) => {
      try {
        const bookmark = await getBookmark(hash, type);
        const isBookmark = !!bookmark;

        if (isBookmark) {
          await removeBookmarkMutation({ variables: { hash, type } });

          if (type === BookmarkType.course) {
            cacheUpdateCourseFragment(hash, { is_bookmark: false });
          }

          trackMediaEvent('Media UnFavorited', hash);
        } else {
          await addBookmarkMutation({ variables: { hash, type } });

          if (type === BookmarkType.course) {
            cacheUpdateCourseFragment(hash, { is_bookmark: true });
          }

          trackMediaEvent('Media Favorited', hash);
        }
      } catch (error) {
        enqueueSnackbar(error?.message || 'Unable to toggle bookmark', {
          variant: 'error',
        });
      }
    },
    [addBookmarkMutation, enqueueSnackbar, removeBookmarkMutation],
  );

  return {
    isAdding,
    isRemoving,
    loading: isAdding || isRemoving,
    toggleBookmark,
  };
};

export default useBookmarksActions;
export type UseBookmarksActions = ReturnType<typeof useBookmarksActions>;
