/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  max-width: calc(100% - 2 * 1rem);
  width: 100%;
`;
