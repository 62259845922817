import { memo, useCallback } from 'react';
import type { ChangeEvent, FC } from 'react';
import { useField } from 'formik';
import { AnimatePresence } from 'framer-motion';
import {
  Button,
  Container,
  Error,
  Input,
  InputContainer,
  Label,
  Loader,
} from './styles';
import type { Props } from './types';

const InputWithButton: FC<Props> = ({
  className,
  disabled,
  isLoading,
  label,
  labelId,
  name,
  placeholder,
}) => {
  const [{ value }, { error }, { setValue }] = useField<string>(name);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setValue(e.target.value);
    },
    [setValue],
  );

  return (
    <Container className={className}>
      <InputContainer>
        {label && <Label id={labelId}>{label}</Label>}
        <Input
          aria-labelledby={labelId}
          disabled={isLoading || disabled}
          name={name}
          onChange={handleChange}
          placeholder={placeholder}
          value={value}
        />
        <Button $isLoading={isLoading} disabled={disabled} type="submit">
          {isLoading ? <Loader /> : 'Next'}
        </Button>
      </InputContainer>
      <AnimatePresence initial={false}>
        {error && <Error>{error}</Error>}
      </AnimatePresence>
    </Container>
  );
};

export default memo(InputWithButton);
