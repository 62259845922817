import { useState, useCallback } from 'react';

const useConnect = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen((v) => !v);
  }, []);

  return {
    isSidebarOpen,
    toggleSidebar,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
