import styled from 'styled-components';
import DefaultIcon from 'components/Icon';

export const Icon = styled(DefaultIcon).attrs(() => ({ size: 16 }))`
  margin-right: 0.5rem;
`;

export const TagButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  font-family: ${({ theme }) => theme.font.family.inter};
  font-size: 0.9rem;
  justify-content: flex-start;
  letter-spacing: 0.01em;
  line-height: 1.125rem;
  padding: 0.6rem 0.75rem;
  width: fit-content;
`;
