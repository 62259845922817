import {
  SubscriptionPlanType,
  type LifetimePriceDataFragment,
  type SubscriptionPlanDataFragment,
} from 'graphql/generated';

export enum SubscriptionPeriod {
  FAMILY = 'f',
  LIFETIME = 'l',
  MONTH = 'm',
  YEAR = 'y',
  MENU_LIFETIME = 'ml',
  MENU_MONTH = 'mm',
  MENU_YEAR = 'my',
}

export const PLAN_ORDER_BY_TYPE: Record<SubscriptionPlanType, number> = {
  [SubscriptionPlanType.standard]: 0,
  [SubscriptionPlanType.family]: 1,
  [SubscriptionPlanType.lifetime]: 2,
  [SubscriptionPlanType.scholarship]: 99,
};

export const normalizeSubscriptionPlan = (
  subscriptionPlan: SubscriptionPlanDataFragment,
) => ({
  amount: subscriptionPlan.amount,
  id: subscriptionPlan.id,
  interval: subscriptionPlan.interval,
  nickname: subscriptionPlan.nickname,
  originalAmount: subscriptionPlan.originalAmount,
  sku: subscriptionPlan.sku || '',
  type: subscriptionPlan.type,
});

export type SubscriptionPlan = ReturnType<typeof normalizeSubscriptionPlan>;

export const normalizeLifetimePlan = (
  lifetimePlan: LifetimePriceDataFragment,
) => ({
  amount: lifetimePlan.amount,
  id: lifetimePlan.id,
  interval: 'lifetime',
  originalAmount: lifetimePlan.originalAmount,
  nickname: 'Lifetime',
  sku: 'lifetime',
  type: SubscriptionPlanType.lifetime,
});

export type LifetimePlan = ReturnType<typeof normalizeLifetimePlan>;
