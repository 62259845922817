import styled from 'styled-components';
import DefaultText from 'components/Text';

export const Container = styled.div``;

export const Text = styled(DefaultText)`
  color: inherit;
  text-align: center;
`;

export const Error = styled(DefaultText)`
  color: ${({ theme }) => theme.colors.coreRed500};
  text-align: center;
`;
