import { memo } from 'react';
import type { FC } from 'react';
import { BorderBottom, Container, FadeOut, Scrollable } from './styles';
import type { Props } from './types';

const NavTabList: FC<Props> = ({ className, children }) => (
  <Container className={className}>
    <BorderBottom />
    <Scrollable role="tablist">{children}</Scrollable>
    <FadeOut />
  </Container>
);

export default memo(NavTabList);
