import styled from 'styled-components';
import { media, prettyScrollbar } from 'styles/mixins';
import colorWithAlpha from 'lib/color';
import DefaultModal from 'components/Modal';
import DefaultButton from 'components/Button';
import { Body } from 'components/Typography';
import { UseConnect } from './connect';

export const Modal = styled(DefaultModal)`
  .MuiPaper-root {
    color: ${({ theme }) => theme.colors.fontBase};
    max-width: 30rem;
    min-width: 26rem;
    padding: 0;
  }

  ${media.phonePortrait} {
    .MuiPaper-root {
      max-width: none;
      min-width: 92%;
    }
  }
`;

export const Title = styled(Body).attrs(() => ({
  $size: 'large',
  $weight: 'bold',
}))`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderBase};
  flex: 0 0 auto;
  line-height: 1.4;
  padding: 1.7rem 2rem;

  ${media.phoneLandscape} {
    padding: 1.3rem 1.5rem;
  }
`;

export const Content = styled.div`
  ${prettyScrollbar({ vertical: true })};
  background-color: ${({ theme }) =>
    colorWithAlpha({
      color: theme.colors.coreNeutralBlack,
      alpha: 0.01,
    })};
  flex: 1 1 auto;
  font-size: 0.9rem;
  overflow-y: auto;
  padding: 1.8rem 2rem;

  ${media.phoneLandscape} {
    padding: 1.3rem 1.5rem;
  }
`;

export const Buttons = styled.div<{
  $position?: UseConnect['buttonsPosition'];
}>`
  border-top: 1px solid ${({ theme }) => theme.colors.borderBase};
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${({ $position }) => {
    if ($position === 'center') return 'center';
    if ($position === 'left') return 'flex-start';
    return 'flex-end';
  }};
  padding: 0.8rem 1.6rem;

  ${media.phoneLandscape} {
    flex-flow: row wrap;
    padding: 0.8rem 1.1rem;
  }
`;

export const Button = styled(DefaultButton)`
  display: block;
  flex: 0 1 auto;
  margin: 0.4rem;
  min-width: 7rem;
  width: auto;
  white-space: nowrap;

  ${media.phoneLandscape} {
    flex: 1 1 auto;
  }
`;
