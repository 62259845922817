import { getApp, initializeApp, type FirebaseOptions } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { ApiConfig } from 'config';

const firebaseConfig: FirebaseOptions = {
  apiKey: ApiConfig.FIREBASE.API_KEY,
  authDomain: ApiConfig.FIREBASE.AUTH_DOMAIN,
  databaseURL: ApiConfig.FIREBASE.DATABASE_URL,
  projectId: ApiConfig.FIREBASE.PROJECT_ID,
  storageBucket: ApiConfig.FIREBASE.STORAGE_BUCKET,
  messagingSenderId: ApiConfig.FIREBASE.MESSAGING_SENDER_ID,
  appId: ApiConfig.FIREBASE.APP_ID,
  measurementId: ApiConfig.FIREBASE.MEASUREMENT_ID,
};

function createFirebaseApp(config: FirebaseOptions) {
  try {
    return getApp();
  } catch {
    return initializeApp(config);
  }
}

const app = createFirebaseApp(firebaseConfig);
const database = getDatabase(app);

export default database;
