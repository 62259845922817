import { apolloClient } from 'services/api';
import {
  GetStripePaymentMethodDocument,
  type StripePaymentMethodDataFragment,
} from '../generated';

function cacheSetStripePaymentMethod(data: StripePaymentMethodDataFragment) {
  apolloClient.writeQuery({
    query: GetStripePaymentMethodDocument,
    data: { __typename: 'Query', getStripePaymentMethod: data },
  });
}

export default cacheSetStripePaymentMethod;
