import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { useMediaQuery } from '@mui/material';
import { trackEvent } from 'services/analytics';
import { media } from 'styles/mixins';

const useConnect = () => {
  const { search } = useLocation();
  const isPhoneLandscape = useMediaQuery(media.phoneLandscape);

  const { email: searchEmail } = parse(search);
  const initialEmail = typeof searchEmail === 'string' ? searchEmail : '';

  useEffect(() => {
    trackEvent('Visit Sign Up Page', {
      url: window.location.href,
      refurl: document.referrer,
    });
  }, []);

  return {
    initialEmail,
    isPhoneLandscape,
    queryString: search || undefined,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
