import styled, { css } from 'styled-components';
import { Form as DefaultForm } from 'formik';
import { screenSizes } from 'styles/metrics';
import { media } from 'styles/mixins';
import colorWithAlpha from 'lib/color';
import DefaultSwitch from 'components/Inputs/Switch';

export const Form = styled(DefaultForm)`
  border-top: ${({ theme }) =>
    `1px solid ${colorWithAlpha({
      color:
        theme.name === 'LIGHT'
          ? theme.colors.coreNeutralBlack
          : theme.colors.coreNeutralWhite,
      alpha: 0.1,
    })}`};
  margin-left: auto;
  margin-right: auto;
  max-width: 35rem;

  ${media.phoneLandscape} {
    max-width: 100vw;
    width: 100vw;
    margin-right: 0;
    margin-left: -1rem;
  }
`;

const fullWidthStyles = css`
  @media (min-width: ${screenSizes.phoneLandscape}px) {
    background-color: transparent;
  }

  ${media.phoneLandscape} {
    width: 100vw;
  }
`;

export const Switch = styled(DefaultSwitch)`
  ${fullWidthStyles}
`;
