import { apolloClient } from 'services/api';
import { normalizeSettings, DEFAULT_SETTINGS } from 'models/Settings';
import { GetSettingsDocument } from '../generated';

const getSettings = async () => {
  const { data: settingsData } = await apolloClient.query({
    query: GetSettingsDocument,
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
  });

  const rawSettings = settingsData?.me?.settings;
  const settings = rawSettings
    ? normalizeSettings(rawSettings)
    : DEFAULT_SETTINGS;

  return settings;
};

export default getSettings;
