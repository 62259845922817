import { memo, useCallback } from 'react';
import type { FC, MouseEvent } from 'react';
import { setOptionsMenuVar } from 'graphql/reactive';
import useSettings from 'graphql/hooks/useSettings';
import { ButtonIcon } from './styles';

const Autoplay: FC = () => {
  const {
    settings: { autoplay },
  } = useSettings();

  const handleOpen = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setOptionsMenuVar({
      anchorEl: event.currentTarget,
      offset: '0, -100%p',
      placement: 'top-start',
      type: 'autoplay',
    });
  }, []);

  return (
    <ButtonIcon
      active={autoplay}
      aria-label="Select autoplay"
      iconName="infinityLight"
      onClick={handleOpen}
    />
  );
};

export default memo(Autoplay);
