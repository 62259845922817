import { useMemo } from 'react';
import { useQuery, type QueryHookOptions } from '@apollo/client';
import { normalizeGiftCardPrice } from 'models/GiftCard';
import {
  GetGiftCardPricesDocument,
  type GetGiftCardPricesQuery,
} from '../generated';

const useGiftCardPrices = (
  options: QueryHookOptions<GetGiftCardPricesQuery> = {},
) => {
  const { data, loading } = useQuery(GetGiftCardPricesDocument, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    ...options,
  });

  const giftCardPrices = useMemo(
    () =>
      data?.giftCardPrices
        ? data.giftCardPrices
            .map(normalizeGiftCardPrice)
            .sort((a, b) => a.amount - b.amount)
        : [],
    [data],
  );

  return { giftCardPrices, loading };
};

export default useGiftCardPrices;
export type UseGiftCardPrices = ReturnType<typeof useGiftCardPrices>;
