import styled from 'styled-components';
import { LinearProgress as MuiLinearProgress } from '@mui/material';
import colorWithAlpha from 'lib/color';
import DefaultIcon from 'components/Icon';

export const Container = styled.div<{ $highlight: boolean }>`
  align-items: center;
  color: ${({ $highlight, theme }) =>
    $highlight ? theme.colors.fontEmphasized : theme.colors.fontDeemphasized};
  display: flex;
  flex: 0 1 auto;
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  letter-spacing: 0.07em;
  line-height: 1.33;
`;

export const LinearProgress = styled(MuiLinearProgress)`
  &.MuiLinearProgress-root {
    border-radius: 1rem;
    display: block;
    flex: 0 0 auto;
    margin-right: 0.5rem;
    width: 3.75rem;

    .MuiLinearProgress-bar {
      border-radius: inherit;
    }

    &.MuiLinearProgress-colorPrimary {
      background-color: ${({ theme }) =>
        colorWithAlpha({ color: theme.colors.fontEmphasized, alpha: 0.3 })};

      .MuiLinearProgress-barColorPrimary {
        background-color: ${({ theme }) => theme.colors.fontEmphasized};
      }
    }
  }
`;

export const Icon = styled(DefaultIcon).attrs(() => ({ size: 14 }))`
  color: ${({ theme }) => theme.colors.coreNeutral400};
  margin-right: 0.25rem;
`;

export const Dot = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: 0 0.5rem;
`;
