import { memo, useCallback, type FC } from 'react';
import { updateUpgradeToAnnual } from 'graphql/reactive';
import differenceInDays from 'date-fns/differenceInDays';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns-tz/format';
import { Icon } from '../styles';
import {
  CloseButton,
  Container,
  Content,
  Link,
  Overline,
  Title,
} from './styles';
import type { Props } from './types';

const UpgradeToAnnual: FC<Props> = ({ expirationDate, userId }) => {
  const handleClose = useCallback(() => {
    updateUpgradeToAnnual(userId);
  }, [userId]);

  const now = new Date();
  const dtExpirationDate = parseISO(expirationDate);
  const nDays = differenceInDays(dtExpirationDate, now) + 1;

  return (
    <Container>
      <CloseButton aria-label="Close notification" onClick={handleClose}>
        <Icon name="xCircleLight" />
      </CloseButton>
      <Content>
        <Overline>
          <strong>
            {nDays} day{nDays > 1 ? 's' : ''} left.
          </strong>{' '}
          Offer Expires on {format(dtExpirationDate, 'MM/dd/yyyy')}.
        </Overline>
        <Title>Upgrade to annual today—and save.</Title>
      </Content>
      <Link to="/account/subscription">Upgrade Today</Link>
    </Container>
  );
};

export default memo(UpgradeToAnnual);
