import { PlayerAutoplayType } from 'models/Player';
import { Daily, DailyPermission } from 'models/Daily';
import { dailyPlayerVar } from '../reactive';
import getSettings from './getSettings';
import getBookmarks from './getBookmarks';

const getDailyAutoplayTrackList = async () => {
  const { autoplay } = await getSettings();
  const { autoplayType } = dailyPlayerVar();

  if (!autoplay || !autoplayType) {
    return undefined;
  }

  let autoplayTrackList: Daily[] | null = null;
  if (autoplayType === PlayerAutoplayType.BOOKMARKS_DAILY) {
    const { dailies } = await getBookmarks();
    autoplayTrackList = dailies;
  }

  const unlockedAutoplayTrackList = autoplayTrackList?.filter(
    (daily) => daily.permission === DailyPermission.UNLOCKED,
  );

  return unlockedAutoplayTrackList && unlockedAutoplayTrackList.length > 0
    ? unlockedAutoplayTrackList.map((daily) => daily.id)
    : undefined;
};

export default getDailyAutoplayTrackList;
