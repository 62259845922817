import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultSectionHeader from 'components/SectionHeader';
import DefaultIsotype from 'components/Isotype';

export const SectionHeader = styled(DefaultSectionHeader)`
  ${media.tabletPortrait} {
    display: none;
  }
`;

export const Container = styled.div<{ $isPublic: boolean }>`
  align-items: center;
  color: #303d4e;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: ${({ $isPublic }) => ($isPublic ? '5rem 3rem' : '1rem 3rem')};
  text-align: center;

  ${media.phoneLandscape} {
    padding: ${({ $isPublic }) => ($isPublic ? '2.4rem 2rem' : '1rem 1rem')};
  }
`;

export const Isotype = styled(DefaultIsotype)`
  margin-bottom: 3rem;
  width: 2.4rem;

  ${media.phoneLandscape} {
    margin-bottom: 1rem;
    width: 2rem;
  }
`;

export const Title = styled.h1`
  color: inherit;
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: -0.03em;
  line-height: 1.2188;
  margin-bottom: 0.75rem;

  ${media.phoneLandscape} {
    font-size: 1.55rem;
  }
`;

export const Subtitle = styled.p`
  color: inherit;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 1.1875;
  margin-bottom: 4.875rem;

  ${media.phoneLandscape} {
    margin-bottom: 2.4rem;
  }
`;
