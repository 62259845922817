import {
  PersonType,
  type PersonDataFragment,
  type PersonInfoDataFragment,
} from 'graphql/generated';

export const normalizePerson = (person: PersonDataFragment) => ({
  active: person.active ?? false,
  bio: person.bio || '',
  id: person.id,
  imageUrl: person.image?.url || '',
  imageThumbnailsThumbX1: person.image?.thumbnails?.thumb_x1 || '',
  imageThumbnailsThumbX2: person.image?.thumbnails?.thumb_x2 || '',
  name: person.name,
  type: PersonType.teacher,
});

export type Person = ReturnType<typeof normalizePerson>;

export const normalizePersonInfo = (person: PersonInfoDataFragment) => ({
  id: person.id,
  personId: person.person_id,
  type: person.type,
});

export type PersonInfo = ReturnType<typeof normalizePersonInfo>;

export const separatePersonsByType = (persons: Person[]) => {
  const teachers: Person[] = [];
  const speakers: Person[] = [];
  const artists: Person[] = [];
  const teachersAndSpeakers: Record<string, Person> = {};

  persons.forEach((p) => {
    if (p.type === PersonType.teacher) {
      teachers.push(p);
      if (!teachersAndSpeakers[p.id]) {
        teachersAndSpeakers[p.id] = p;
      }
    } else if (p.type === PersonType.speaker) {
      speakers.push(p);
      teachersAndSpeakers[p.id] = p;
    } else if (p.type === PersonType.artist) {
      artists.push(p);
    }
  });

  return {
    artists,
    speakers,
    teachers,
    teachersAndSpeakers: Object.values(teachersAndSpeakers),
  };
};
