/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { Props } from './types';

const ErrorStyle = css`
  color: ${({ theme }) => theme.colors.coreRed500};
  a {
    color: #8294a5;
  }
`;

export const Text = styled.p<Props>`
  backface-visibility: hidden;
  color: ${({ theme }) => theme.colors.coreNeutralWhite};
  ${({ error }) => error && ErrorStyle};
  display: block;
  font-size: ${({ large }) => (large ? '1.1rem' : '1rem')};
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 1.55;
  margin-bottom: 0.8rem;
  text-align: ${({ center }) => (center ? 'center' : 'left')};

  b,
  strong {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`;
