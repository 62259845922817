import { memo } from 'react';
import type { FC } from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isAfter from 'date-fns/isAfter';
import subMinutes from 'date-fns/subMinutes';
import Countdown from './Countdown';
import {
  Button,
  ButtonsContainer,
  Content,
  Description,
  Highlight,
  Link,
  Modal,
  Overline,
  RetreatTitle,
  StartDate,
  Title,
} from './styles';
import useConnect from './connect';

const RetreatLiveMeeting: FC = () => {
  const { handleClose, isOpen, liveMeetingInfo, retreatTitle } = useConnect();

  if (!liveMeetingInfo) {
    return null;
  }

  const { endDate, link, startDate, title } = liveMeetingInfo;
  const hasEnded = isAfter(new Date(), parseISO(endDate));
  const formattedStartDate = startDate
    ? format(parseISO(startDate), 'h:mm a (O)')
    : undefined;
  const formattedLinkAvailableDate = startDate
    ? format(subMinutes(parseISO(startDate), 15), 'h:mm a (O)')
    : undefined;

  const showHasEnded = hasEnded;
  const showWaiting = !hasEnded && !link;
  const showOpen = !hasEnded && link;

  return (
    <Modal
      descriptionId="live-meeting-description"
      fullscreenOnMobile
      onClose={handleClose}
      open={isOpen}
      titleId="live-meeting-title"
    >
      <Content>
        {showWaiting && (
          <>
            <Overline>Please Finish Retreat</Overline>
            <RetreatTitle>{retreatTitle}</RetreatTitle>
            <Title as="h1" id="live-meeting-title">
              {title}
            </Title>
            {formattedStartDate && (
              <StartDate>Begins {formattedStartDate}</StartDate>
            )}
            <Description id="live-meeting-description">
              A zoom link will appear here{' '}
              {formattedLinkAvailableDate
                ? `at ${formattedLinkAvailableDate}`
                : 'soon'}{' '}
              or when you finish the retreat audio.
            </Description>
            <ButtonsContainer>
              <Button onClick={handleClose}>Back to Retreat</Button>
            </ButtonsContainer>
          </>
        )}
        {showOpen && (
          <>
            <Countdown startDate={startDate} />
            <RetreatTitle>{retreatTitle}</RetreatTitle>
            <Title as="h1" id="live-meeting-title">
              {title}
            </Title>
            {formattedStartDate && (
              <StartDate>Begins {formattedStartDate}</StartDate>
            )}
            <Description id="live-meeting-description">
              The retreat isn't over! In the next 30 mins, try to maintain
              silence. That'll put you in the best mindset for the live Q&A with
              Sam.
            </Description>
            <ButtonsContainer>
              <Link to={link}>Open Zoom Now</Link>
            </ButtonsContainer>
          </>
        )}
        {showHasEnded && (
          <>
            <Highlight as="h1" id="live-meeting-title">
              Sorry, this Q&A was a live event and is now over.
            </Highlight>
            <Description id="live-meeting-description">
              We will be posting the entire retreat—including the recording of
              the Q&A—in the app soon.
            </Description>
            <ButtonsContainer>
              <Button onClick={handleClose}>Go Back</Button>
            </ButtonsContainer>
          </>
        )}
      </Content>
    </Modal>
  );
};

export default memo(RetreatLiveMeeting);
