import { memo, type FC } from 'react';
import { Select } from './styles';
import { timezoneOptions } from './constants';
import type { Props } from './types';

const SelectTimezone: FC<Props> = (props) => (
  <Select {...props} options={timezoneOptions} />
);

export default memo(SelectTimezone);
