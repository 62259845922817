import { memo } from 'react';
import type { FC } from 'react';
import { Container, Feature, Features, Subtitle, Title } from './styles';

const Description: FC = () => (
  <Container>
    <Title>Not just another meditation app.</Title>
    <Subtitle>
      Waking Up opens the door to a deeper understanding of yourself—and to a
      new way of being in the world.
    </Subtitle>
    <Features>
      <Feature>
        <strong>Release negative</strong> thoughts with meditations for
        beginners.
      </Feature>
      <Feature>
        <strong>Explore deeper levels of consciousness</strong> with advanced
        meditations.
      </Feature>
      <Feature>
        <strong>Learn ancient wisdom,</strong> pressure-tested by modern
        science.
      </Feature>
    </Features>
  </Container>
);

export default memo(Description);
