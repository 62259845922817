/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultLink from 'components/Link';

export const Container = styled.div`
  font-size: 0.5rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 1.375;

  ${media.phoneLandscape} {
    font-size: 0.5625rem;
  }
`;

export const P = styled.p<{ $highlight?: boolean }>`
  font-size: inherit;
  font-weight: ${({ $highlight, theme }) =>
    $highlight ? theme.font.weight.semibold : theme.font.weight.book};
  margin: 0.3rem 0;
  width: ${({ $highlight }) => ($highlight ? '14rem' : 'auto')};

  ${media.phoneLandscape} {
    width: ${({ $highlight }) => ($highlight ? '15rem' : '18rem')};
  }
`;

export const Link = styled(DefaultLink)`
  color: inherit;
  font-size: inherit;
`;
