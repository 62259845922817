import { makeVar } from '@apollo/client';

export type PersonDetails = { personId: string };

export const personDetailsVar = makeVar<PersonDetails | null>(null);

export const setPersonDetails = (data: PersonDetails) => {
  personDetailsVar(data);
};

export const resetPersonDetails = () => {
  personDetailsVar(null);
};
