/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const IMAGE_DATA_FRAGMENT = gql`
  fragment ImageData on Image {
    __typename
    id
    thumbnails {
      thumb_x1
      thumb_x2
    }
    url
  }
`;
