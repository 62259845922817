import { gql } from '@apollo/client';

export const WIDGET_CAROUSEL_ITEM_DATA_FRAGMENT = gql`
  fragment WidgetCarouselItemData on WidgetCarouselItem {
    __typename
    caption
    hash
  }
`;

export const WIDGET_UP_NEXT_DATA_FRAGMENT = gql`
  fragment WidgetUpNextData on WidgetUpNext {
    __typename
    id
    title
  }
`;

export const WIDGET_CTA_DATA_FRAGMENT = gql`
  fragment WidgetCTAData on WidgetCTA {
    __typename
    id
    title
  }
`;

export const WIDGET_SPOTLIGHT_DATA_FRAGMENT = gql`
  fragment WidgetSpotlightData on WidgetSpotlight {
    __typename
    id
    title
  }
`;

export const WIDGET_CONTINUE_LISTENING_DATA_FRAGMENT = gql`
  fragment WidgetContinueListeningData on WidgetContinueListening {
    __typename
    id
    title
  }
`;

export const WIDGET_CAROUSEL_DATA_FRAGMENT = gql`
  fragment WidgetCarouselData on WidgetCarousel {
    __typename
    id
    image_size
    items {
      ...WidgetCarouselItemData
    }
    layout
    subtitle
    title
  }
  ${WIDGET_CAROUSEL_ITEM_DATA_FRAGMENT}
`;

export const WIDGET_IMAGE_DATA_FRAGMENT = gql`
  fragment WidgetImageData on WidgetImage {
    __typename
    deeplink
    id
    title
    url_desktop
    url_mobile
  }
`;
