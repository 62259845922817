/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { COURSE_DATA_FRAGMENT } from '../fragments';

export const GET_COURSE = gql`
  query GetCourse($code: ID!) {
    course(code: $code) {
      ...CourseData
    }
  }
  ${COURSE_DATA_FRAGMENT}
`;
