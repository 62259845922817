import { memo, useCallback } from 'react';
import type { FC } from 'react';
import { CoursePermission } from 'models/Course';
import CourseLinearProgress from './CourseLinearProgress';
import { Container, Description, Icon, Progress, Title } from './styles';
import type { Props } from './types';

const Course: FC<Props> = ({
  audioLength,
  description,
  id,
  onClick,
  packId,
  permission,
  progress,
  status,
  title,
}) => {
  const handleClick = useCallback(() => {
    onClick(id, packId);
  }, [id, onClick, packId]);

  const isForbidden = permission === CoursePermission.FORBIDDEN;

  return (
    <Container
      as={!isForbidden ? 'button' : undefined}
      onClick={!isForbidden ? handleClick : undefined}
    >
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
      <Progress>
        <CourseLinearProgress
          courseStatus={status}
          progress={progress}
          total={audioLength}
        />
        {isForbidden && <Icon name="lockSimpleFilled" />}
      </Progress>
    </Container>
  );
};

export default memo(Course);
