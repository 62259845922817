import styled from 'styled-components';
import { media } from 'styles/mixins';
import { HEADER_HEIGHT } from 'styles/metrics';
import DefaultNavTabList from 'components/NavTabList';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ul {
    list-style-type: none;
  }

  li {
    &:not(:last-child) {
      border-bottom: 1px solid #e1e1e5;
    }
  }
`;

export const NavTabList = styled(DefaultNavTabList)`
  background-color: ${({ theme }) => theme.colors.backgroundBase};
  margin-bottom: ${({ theme }) => theme.measures.s};

  ${media.tabletPortrait} {
    padding-top: 3.4rem;
    position: sticky;
    top: calc(${HEADER_HEIGHT} + 6.3rem);
    z-index: 7;
  }
`;

export const Packs = styled.div`
  ${media.tabletPortrait} {
    margin-left: -1rem;
    margin-right: -1rem;
  }
`;
