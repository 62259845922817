import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const linkStyle = css`
  font-size: 0.75rem;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  outline-offset: 0.125rem;
  position: relative;

  cursor: pointer;

  transition: all 0.2s ease;

  &::before {
    content: '';
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: scaleX(0);
    transform-origin: 100% 0%;
    transition: transform 300ms ease-in-out;
    width: 100%;
  }

  &:hover {
    &::before {
      transform: scaleX(1);
      transform-origin: 0% 0%;
    }
  }

  &:active {
    transform: scaleX(0.97);
  }
`;

export const BaseLinkStyle = styled(Link)`
  ${linkStyle};

  color: ${({ color }) => color};

  &::before {
    background-color: ${({ color }) => color};
  }
`;
