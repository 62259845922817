import { useCallback } from 'react';
import type { ChangeEvent } from 'react';
import { useField } from 'formik';
import type { LogicProps } from './types';

const useLogic = ({ name }: LogicProps) => {
  const [{ value }, { error, touched }, { setValue }] = useField(name);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, optionValue: string) => {
      e.preventDefault();
      setValue(optionValue);
    },
    [setValue],
  );

  return { error, handleChange, touched, value };
};

export default useLogic;

export type UseLogic = ReturnType<typeof useLogic>;
