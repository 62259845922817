import styled from 'styled-components';
import { Form as FormikForm } from 'formik';
import { motion } from 'framer-motion';
import DefaultButton from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import type { CircularProgressProps } from '@mui/material';
import DefaultInputWithButton from 'components/Inputs/InputWithButton';
import { Error as InputWithButtonError } from 'components/Inputs/InputWithButton/styles';
import DefaultLoginCodeField from 'components/LoginCodeField';

export const Form = styled(FormikForm)``;

export const StepContent = styled(motion.div).attrs(() => ({
  animate: { x: 0, opacity: 1 },
  exit: { x: -15, opacity: 0 },
  initial: { x: 15, opacity: 0 },
  transition: { duration: 0.25, ease: 'easeInOut' },
}))`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.coreNeutral700};
  font-size: 1.1rem;
  line-height: 1.25;
  margin-bottom: 2.625rem;
  margin-top: 1.3rem;
  opacity: 0.8;
  text-align: center;

  strong {
    color: inherit;
    font-size: inherit;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`;

export const InputWithButton = styled(DefaultInputWithButton)`
  max-width: 25rem;
  width: 100%;

  ${InputWithButtonError} {
    text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const LoginCodeField = styled(DefaultLoginCodeField)`
  max-width: 18rem;
`;

export const Button = styled(DefaultButton)<{ $isLoading?: boolean }>`
  &&& {
    background-color: ${({ theme }) => theme.colors.coreBlue500};
    border-radius: 0.25rem;
    box-shadow: none;
    color: ${({ theme }) => theme.colors.coreNeutralAurora};
    cursor: pointer;
    font-size: 1.0625rem;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    line-height: 1.235;
    margin-left: 1rem;
    min-height: auto;
    min-width: 6.25rem;
    opacity: inherit;
    outline: none;
    padding: 0.75rem 1.9063rem;
    position: relative;
    text-decoration: none;
    text-transform: capitalize;
    transition: opacity 0.2s ease;

    &:disabled,
    .Mui-disabled {
      cursor: default;
      opacity: ${({ $isLoading }) => ($isLoading ? '1' : '0.6')};
      pointer-events: none;
    }
  }
`;

export const Loader = styled(CircularProgress).attrs<CircularProgressProps>(
  () => ({ size: 20, color: 'inherit' }),
)`
  color: inherit;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
`;

export const Error = styled(motion.div).attrs(() => ({
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  transition: { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] },
}))`
  color: ${({ theme }) => theme.colors.coreRed500};
  font-size: 0.75rem;
  overflow: hidden;
  padding-top: 0.5rem;
  text-align: center;
`;
