import styled, { css } from 'styled-components';
import {
  WidgetCarouselImageSize,
  WidgetCarouselLayout,
} from 'graphql/generated';
import DefaultImage from 'components/Image';
import { Body, Heading } from 'components/Typography';
import DefaultIcon from 'components/Icon';
import type { Props } from './types';

export const Container = styled.div<{
  $imageSize: Props['imageSize'];
  $layout: Props['layout'];
}>`
  background: none;
  border: none;
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  cursor: pointer;
  display: block;
  text-align: unset;
  text-decoration: none;
  width: ${({ $imageSize }) =>
    $imageSize === WidgetCarouselImageSize.large ? '20.125rem' : '9.4375rem'};

  ${({ $layout }) =>
    $layout === WidgetCarouselLayout.horizontal &&
    css`
      align-items: center;
      display: flex;
      width: 100%;
    `}
`;

export const ImageContainer = styled.div<{
  $layout: Props['layout'];
}>`
  position: relative;
  width: 100%;

  ${({ $layout }) =>
    $layout === WidgetCarouselLayout.horizontal &&
    css`
      flex: 0 0 auto;
      width: 9.4375rem;
    `}
`;

export const Image = styled(DefaultImage)`
  border-radius: 0.125rem;
`;

export const Duration = styled.div`
  align-items: center;
  backdrop-filter: blur(8.73134px);
  background-color: rgba(130, 130, 130, 0.6);
  border-radius: 0.1rem;
  bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.coreNeutralWhite};
  display: inline-flex;
  font-size: 0.65rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  left: -0.25rem;
  letter-spacing: 0.06em;
  line-height: 1.1;
  min-height: 1.5rem;
  padding: 0.2rem 0.45rem 0.2rem 0.4rem;
  position: absolute;
  transform: translateZ(0);

  span {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin-top: 0.03rem;
  }
`;

export const DurationIcon = styled(DefaultIcon)`
  display: block;
  flex: 0 0 auto;
  height: 0.9rem;
  margin-right: 0.2rem;
  width: auto;
`;

export const Content = styled.div<{ $layout: Props['layout'] }>`
  padding-top: 0.75rem;

  ${({ $layout }) =>
    $layout === WidgetCarouselLayout.horizontal &&
    css`
      padding: 0.75rem 2rem 0.75rem 0.75rem;
    `}
`;

export const Overline = styled.p<{ $imageSize: WidgetCarouselImageSize }>`
  align-items: center;
  color: ${({ theme }) => theme.colors.fontDeemphasized};
  display: flex;
  font-size: ${({ $imageSize }) =>
    $imageSize === WidgetCarouselImageSize.large ? '0.875rem' : '0.75rem'};
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: 0.04em;
  line-height: 1.3;
  margin-bottom: 0.175rem;
  width: 100%;

  span {
    font-size: inherit;
    line-height: inherit;
  }
`;

export const Icon = styled(DefaultIcon)<{
  $imageSize: WidgetCarouselImageSize;
}>`
  display: block;
  flex: 0 0 auto;
  height: 0.875rem;
  margin-bottom: 0.1rem;
  margin-right: 0.3125rem;
  width: 0.875rem;

  ${({ $imageSize }) =>
    $imageSize === WidgetCarouselImageSize.large &&
    css`
      height: 1rem;
      margin-bottom: 0;
      width: 1rem;
    `}
`;

export const Separator = styled.span`
  flex: 0 0 auto;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-left: 0.2rem;
  margin-right: 0.2rem;
`;

export const Caption = styled.span`
  color: ${({ theme }) => theme.colors.fontEmphasized};
  flex: 1 1 0;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Title = styled(Heading).attrs(() => ({
  $weight: 'bold',
}))<{ $imageSize: WidgetCarouselImageSize }>`
  box-orient: vertical;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: ${({ $imageSize }) =>
    $imageSize === WidgetCarouselImageSize.large ? '1rem' : '0.875rem'};
  line-clamp: 2;
  -webkit-line-clamp: 2;
  line-height: 1.33;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Subtitle = styled(Body).attrs(() => ({
  $size: 'small',
}))<{ $imageSize: WidgetCarouselImageSize }>`
  font-size: ${({ $imageSize }) =>
    $imageSize === WidgetCarouselImageSize.large ? '0.875rem' : '0.75rem'};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  margin-top: 0.1125rem;
  opacity: 0.6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
