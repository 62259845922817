import { memo } from 'react';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import Navigate from 'components/Navigate';

const RedirectCorporate: FC = () => {
  const { company } = useParams();
  return <Navigate replace to={`/account/corporate/${company}`} />;
};

export default memo(RedirectCorporate);
