import type { FC } from 'react';
import type { Props } from './types';

const LotusLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      clipRule="evenodd"
      d="M12.37 4.274a.651.651 0 0 0-.73-.005 9.22 9.22 0 0 0-3.055 3.418c-1.688-.671-3.494-.814-5.255-.501a.65.65 0 0 0-.525.515 9.355 9.355 0 0 0 .636 5.544 9.152 9.152 0 0 0-2.328 2.366.65.65 0 0 0 0 .733 9.405 9.405 0 0 0 10.792 3.618c.064.01.13.01.193 0a9.405 9.405 0 0 0 10.791-3.618.65.65 0 0 0 .002-.733 9.147 9.147 0 0 0-2.36-2.388c.76-1.754.945-3.689.629-5.508a.65.65 0 0 0-.527-.53c-1.751-.31-3.548-.17-5.228.492a9.476 9.476 0 0 0-3.036-3.403Zm3.56 4.595c.31.86.497 1.78.542 2.737a9.278 9.278 0 0 1 2.932.936 8.384 8.384 0 0 0 .54-4.144 8.232 8.232 0 0 0-4.014.471Zm.515 4.05a9.382 9.382 0 0 1-2.986 6.068 8.096 8.096 0 0 0 8.09-3.011 7.705 7.705 0 0 0-2.157-1.938 7.813 7.813 0 0 0-2.947-1.119Zm-5.912 6.07a9.37 9.37 0 0 1-2.975-6.07 7.814 7.814 0 0 0-2.964 1.13 7.705 7.705 0 0 0-2.14 1.927 8.096 8.096 0 0 0 8.079 3.013ZM7.53 11.606c.045-.95.23-1.865.534-2.724a8.23 8.23 0 0 0-4.056-.483 8.08 8.08 0 0 0 .555 4.162 9.268 9.268 0 0 1 2.967-.955Zm1.291.459c0-2.635 1.24-4.994 3.176-6.453a8.096 8.096 0 0 1 3.183 6.453 8.096 8.096 0 0 1-3.183 6.452c-1.935-1.459-3.176-3.818-3.176-6.452Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default LotusLight;
