import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  height: 100%;
  width: 100%;
`;

export const Progress = styled(CircularProgress).attrs(() => ({
  size: 50,
}))``;
