import { makeVar } from '@apollo/client';
import { PlayerSource } from 'models/Player';

export type RetreatPlayer = {
  hash: string;
  isMinimized: boolean;
  packId: string;
  queryString?: string;
  source: PlayerSource;
};

export const emptyRetreatPlayer: RetreatPlayer = {
  hash: '',
  isMinimized: false,
  packId: '',
  source: PlayerSource.RETREAT,
};

export const retreatPlayerVar = makeVar<RetreatPlayer>(emptyRetreatPlayer);

export const resetRetreatPlayerVar = () => {
  retreatPlayerVar(emptyRetreatPlayer);
};

export const setRetreatPlayerVar = (data: Partial<RetreatPlayer>) => {
  retreatPlayerVar({ ...emptyRetreatPlayer, ...data });
};

export const updateRetreatPlayerVar = (data: Partial<RetreatPlayer>) => {
  retreatPlayerVar({ ...retreatPlayerVar(), ...data });
};
