import type { FC } from 'react';
import Subscription from 'containers/Subscription';
import useConnect from './connect';

const SubscriptionPage: FC = () => {
  const { code, expiresAt, period, priceId, userId } = useConnect();

  return (
    <Subscription
      code={code}
      expiresAt={expiresAt}
      isPublic
      period={period}
      priceId={priceId}
      userId={userId}
    />
  );
};

export default SubscriptionPage;
