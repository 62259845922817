import { memo } from 'react';
import type { FC } from 'react';
import CloseButtonMaximized from './CloseButtonMaximized';
import CloseButtonMinimized from './CloseButtonMinimized';
import type { Props } from './types';

const CloseButton: FC<Props> = ({ isMinimized, ...rest }) =>
  isMinimized ? (
    <CloseButtonMinimized {...rest} />
  ) : (
    <CloseButtonMaximized {...rest} />
  );

export default memo(CloseButton);
