import { useCallback, useEffect } from 'react';
import {
  useMatch,
  useNavigate,
  useLocation,
  matchRoutes,
} from 'react-router-dom';
import kebabCase from 'lodash/kebabCase';
import { parse } from 'query-string';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import subMinutes from 'date-fns/subMinutes';
import { AppConfig, isDevelopment } from 'config';
import { AnalyticsEvent, trackEvent } from 'services/analytics';
import { getPackUrl } from 'models/Pack';
import { CoursePermission } from 'models/Course';
import { PlayerSource } from 'models/Player';
import { PackCategory, PackType } from 'graphql/generated';
import { setPersonDetails } from 'graphql/reactive';
import {
  getCourse,
  getNextMeditation,
  getPack,
  getPerson,
  getProfile,
  getRetreat,
  getSharedDaily,
  getSubscriptionPlans,
} from 'graphql/requests';
import useProcessOfferActions from 'graphql/hooks/useProcessOfferActions';
import usePlayerActions from 'graphql/hooks/usePlayerActions';
import useFamilyMembersActions from 'graphql/hooks/useFamilyMembersActions';
import useFreeMonthActions from 'graphql/hooks/useFreeMonthActions';
import useModalActions from 'graphql/hooks/useModalActions';
import useRedeemFreeMonthTrialActions from 'graphql/hooks/useRedeemFreeMonthTrialActions';

const OPENVIEW_TYPES: Record<string, { description: string; link: string }> = {
  aboutCourse: { description: 'About Course', link: '/account/course' },
  aboutSam: { description: 'About Sam', link: '/account/sam' },
  dailyMeditationDuration: {
    description: 'Daily Meditation Duration',
    link: '/dailyMeditationDuration',
  },
  faq: { description: 'Faq', link: '/account/help' },
  favorites: { description: 'Favorites', link: '/favorites' },
  myLibrary: { description: 'My Library', link: '/my-library' },
  onboarding: { description: 'Onboarding', link: '/onboarding' },
  playDaily: { description: 'Play Daily', link: '/playDaily' },
  playNextMeditation: { description: 'Play Next', link: '/playNextMeditation' },
  practice: { description: 'Practice', link: '/practice' },
  privacy: { description: 'Privacy', link: '/account/privacy' },
  profile: { description: 'Profile', link: '/account/edit' },
  settings: { description: 'Settings', link: '/account' },
  share: { description: 'Share', link: '/share' },
  subscriptions: { description: 'Subscriptions', link: '/checkout' },
  terms: { description: 'Terms', link: '/account/terms' },
  theory: { description: 'Theory', link: '/theory' },
  timer: { description: 'Timer', link: '/timer' },
};

const useConnect = () => {
  const { processOffer } = useProcessOfferActions();
  const { openCoursePlayer, openDailyPlayer, openPreviewPlayer } =
    usePlayerActions();
  const {
    closeModal,
    openDailyDurationModal,
    openDialogModal,
    openGiveFreeTrialModal,
    openOnboardingSlidesModal,
    openSelfTimerConfigurationModal,
    openShareAppModal,
    openShareLinkModal,
    openUnlockFreeMonthSuccessModal,
    openUpgradeToAnnualModal,
  } = useModalActions();
  const { acceptFamilyInvitation } = useFamilyMembersActions();
  const { validateSharedToken } = useFreeMonthActions();
  const { redeemFreeMonthTrial } = useRedeemFreeMonthTrialActions();
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const state = location.state as { playerSource?: PlayerSource };
  const playerSource = state?.playerSource;

  const matchesSelfTimer = !!matchRoutes(
    [{ path: '/open-self-timer' }, { path: '/timer' }],
    location,
  );
  const matchesOpenPack = useMatch('/pack/:id');
  const packIdParam = matchesOpenPack?.params.id;
  const matchesSetTrack = useMatch('/course/:id');
  const courseId = matchesSetTrack?.params.id;
  const matchesOpenSharedDaily = useMatch('/daily/:sharedHash');
  const sharedDailyHash = matchesOpenSharedDaily?.params.sharedHash;
  const matchesOpenRetreat = useMatch('/retreat/:id');
  const retreatIdParam = matchesOpenRetreat?.params.id;
  const matchesCheckout = !!useMatch({
    path: '/checkout',
    end: true,
  });
  const matchesCheckoutWithCode = useMatch({
    path: '/checkout/:code',
    end: true,
  });
  const checkoutCode = matchesCheckoutWithCode?.params.code;
  const matchesFreeTrial = !!useMatch({
    path: '/free-trial',
    end: true,
  });
  const matchesOfferWithCode = useMatch({
    path: '/offer/:code',
    end: true,
  });
  const offerCode = matchesOfferWithCode?.params.code;
  const matchesOnboarding = !!useMatch('/onboarding');
  const matchesDailyMeditationDuration = !!useMatch('/dailyMeditationDuration');
  const matchesShare = !!useMatch({
    path: '/share',
    end: true,
    caseSensitive: true,
  });
  const matchesShareTheApp = !!useMatch({
    path: '/share-the-app',
    end: true,
    caseSensitive: true,
  });
  const matchesShareRedeemWithCode = useMatch({
    path: '/share/redeem/:token',
    end: true,
    caseSensitive: true,
  });
  const shareRedeemToken = matchesShareRedeemWithCode?.params?.token;
  const matchesShareCourse = useMatch({
    path: '/share/:courseHash',
    end: true,
    caseSensitive: true,
  });
  const shareCourseHash = matchesShareCourse?.params.courseHash;
  const matchesPlayNextMeditation = !!useMatch('/playNextMeditation');
  const matchesPlayDaily = !!useMatch('/playDaily');
  const matchesOpenview = useMatch('/openview/:type');
  const openviewType = matchesOpenview?.params.type;
  const matchesFreeMonth = matchRoutes(
    [{ path: '/redeemFreeMonth/:code' }, { path: '/shareOpenAccess/:code' }],
    location,
  );
  const freeMonthCode = matchesFreeMonth ? matchesFreeMonth[0].params.code : '';
  const matchesPreview = useMatch('/preview/:courseHash');
  const previewCourseHash = matchesPreview?.params.courseHash;
  const matchesFreeMonthTrial = useMatch('/freeMonthTrial/:courseHash');
  const freeMonthTrialCourseHash = matchesFreeMonthTrial?.params.courseHash;
  const matchesFreeMonthTrialSocialShare = useMatch(
    '/freeMonthTrialSocialShare/:courseHash',
  );
  const freeMonthTrialSocialShareCourseHash =
    matchesFreeMonthTrialSocialShare?.params.courseHash;

  const matchesAcceptFamilyInvitation = useMatch(
    '/accept-family-invitation/:token',
  );
  const familyInvitationToken = matchesAcceptFamilyInvitation?.params.token;
  const matchesUpgradeToAnnual = !!useMatch({
    path: '/upgrade-to-annual',
    end: true,
    caseSensitive: true,
  });
  const matchesOpenPerson = useMatch('/person/:id');
  const personIdParam = matchesOpenPerson?.params.id;

  const handleRedeemFreeMonth = useCallback(
    async (token: string) => {
      const { isSubscribed, subscriptionSubtype, subscriptionStartTime } =
        await getProfile();

      if (isSubscribed) {
        const fifteenMinutesAgo = subMinutes(new Date(), 15);
        const isShare = subscriptionSubtype === 'SHARE';

        if (
          isShare &&
          isAfter(parseISO(subscriptionStartTime), fifteenMinutesAgo)
        ) {
          navigate('/', { replace: true });
          return null;
        }
      }

      validateSharedToken({ token });
      navigate('/', { replace: true });
    },
    [navigate, validateSharedToken],
  );

  const handleOpenSelfTimer = useCallback(() => {
    openSelfTimerConfigurationModal();
    navigate('/', { replace: true });
  }, [navigate, openSelfTimerConfigurationModal]);

  const handleSetTrack = useCallback(
    async (
      id: string,
      packId?: string,
      initialAudioStatus?: string,
      initialPosition?: number,
      endPosition?: number,
      endPositionBehavior?: string,
      queryString?: string,
    ) => {
      navigate('/', { replace: true });

      const course = await getCourse(id, packId);
      const courseDuration = course?.audioLength || 0;

      if (!course) {
        return;
      }

      if (course.permission === CoursePermission.FORBIDDEN) {
        navigate('/account/subscription', { replace: true });
      } else {
        const payload = {
          autoplay: initialAudioStatus !== 'paused',
          endPosition:
            endPosition && endPosition < courseDuration
              ? endPosition
              : undefined,
          endPositionPaused:
            endPosition && endPositionBehavior
              ? endPositionBehavior === 'pausePlayer'
              : undefined,
          hash: course.id,
          initialPosition:
            initialPosition && initialPosition < courseDuration
              ? initialPosition
              : undefined,
          packId,
          queryString,
          source: playerSource || PlayerSource.DEEPLINK,
        };

        openCoursePlayer(payload);

        trackEvent('Deep Link Course Link', {
          description: 'Course Link',
          parameters: id,
          url: window.location.href,
        });
      }
    },
    [navigate, openCoursePlayer, playerSource],
  );

  const handlePreview = useCallback(
    async (courseHash: string, packId?: string, queryString?: string) => {
      navigate('/', { replace: true });

      const course = await getCourse(courseHash, packId);

      if (!course || !course.previews || course.previews.length === 0) {
        return;
      }

      openPreviewPlayer({
        hash: courseHash,
        packId,
        queryString,
        source: PlayerSource.DEEPLINK,
      });
    },
    [navigate, openPreviewPlayer],
  );

  const handleOpenSharedDaily = useCallback(
    async (sharedHash: string, queryString?: string) => {
      navigate('/', { replace: true });

      const daily = await getSharedDaily({
        variables: { shared_hash: sharedHash },
      });

      if (!daily) {
        const { isDailyUnlocked, isSubscribed } = await getProfile();
        const showOpenDaily = isSubscribed && isDailyUnlocked;

        const handleConfirm = () => {
          closeModal();
          setTimeout(() => {
            openDailyPlayer({ queryString, source: PlayerSource.DEEPLINK });
          }, 500);
        };

        openDialogModal({
          cancelButtonText: 'Dismiss',
          confirmButtonText: showOpenDaily ? `Go to today's Daily` : undefined,
          description: 'Access to this Daily meditation has expired.',
          onCancel: closeModal,
          onConfirm: showOpenDaily ? handleConfirm : undefined,
        });

        return;
      }

      trackEvent('Deep Link Shared Daily', {
        description: 'Shared_daily',
        has_expired: false,
        hash: daily.hash,
        network_status: 'online',
        shared_hash: sharedHash,
        url: `${AppConfig.dynamicBaseUrl}/daily/${sharedHash}`,
      });

      openDailyPlayer({
        queryString,
        sharedHash,
        source: PlayerSource.DEEPLINK,
      });
    },
    [closeModal, navigate, openDailyPlayer, openDialogModal],
  );

  const handleOpenRetreat = useCallback(
    async (retreatId: string, queryString?: string) => {
      navigate('/', { replace: true });

      const retreat = await getRetreat();
      const { hash, slug } = retreat || {};

      trackEvent('Deep Link Retreat', {
        description: 'Retreat',
        hash,
        network_status: 'online',
        query_string: queryString,
        url: `${AppConfig.dynamicBaseUrl}/retreat/${retreatId}`,
      });

      navigate(`/practice/retreats/${slug}`, {
        replace: true,
        state: { screen: 'deeplink' },
      });
    },
    [navigate],
  );

  const handleFreeMonthTrial = useCallback(
    async (courseHash: string, queryString: string) => {
      const { isSubscribed, showOpenAccessExpiredBanner, subscriptionSubtype } =
        await getProfile();

      if (!isSubscribed && showOpenAccessExpiredBanner) {
        return navigate('/account/subscription', { replace: true });
      }

      navigate('/', { replace: true });

      const course = await getCourse(courseHash);

      if (!course) {
        return;
      }

      if (!isSubscribed || subscriptionSubtype === 'open_access') {
        await redeemFreeMonthTrial({
          hash: courseHash,
          source: 'preview share',
        });
        openUnlockFreeMonthSuccessModal({ courseHash });
      } else {
        openCoursePlayer({
          hash: courseHash,
          packId: course.packId,
          queryString,
          source: PlayerSource.DEEPLINK,
        });
      }
    },
    [
      navigate,
      openCoursePlayer,
      openUnlockFreeMonthSuccessModal,
      redeemFreeMonthTrial,
    ],
  );

  const handleFreeMonthTrialSocialShare = useCallback(
    async (courseHash: string) => {
      const { isSubscribed, showOpenAccessExpiredBanner, subscriptionSubtype } =
        await getProfile();

      if (!isSubscribed && showOpenAccessExpiredBanner) {
        return navigate('/account/subscription', { replace: true });
      }

      const course = await getCourse(courseHash);
      if (!course) return navigate('/', { replace: true });

      const {
        packCategory,
        packParentPackId: parentPackId,
        packTitle: title,
        packType: type,
      } = course;
      const packIsIntro = packCategory === PackCategory.intro;
      const packType = type === PackType.lesson ? 'theory' : 'practice';

      if (title) {
        if (parentPackId) {
          const parentPack = await getPack(parentPackId);
          const parentTitle = parentPack?.title;

          if (parentTitle && !packIsIntro) {
            navigate(
              `/${packType}/${kebabCase(parentTitle)}/${kebabCase(title)}`,
              { replace: true },
            );
          } else if (parentTitle && packIsIntro) {
            navigate(`/${packType}/${kebabCase(parentTitle)}`, {
              replace: true,
            });
          }
        } else {
          navigate(`/${packType}/${kebabCase(title)}`, { replace: true });
        }
      } else {
        navigate('/', { replace: true });
      }

      if (!isSubscribed || subscriptionSubtype === 'open_access') {
        await redeemFreeMonthTrial({
          hash: courseHash,
          source: 'social share',
        });
        openUnlockFreeMonthSuccessModal({ courseHash: '' });
      }
    },
    [navigate, openUnlockFreeMonthSuccessModal, redeemFreeMonthTrial],
  );

  const handleOpenPack = useCallback(
    async (id: string) => {
      const pack = await getPack(id);

      if (!pack) {
        return navigate('/', { replace: true });
      }

      trackEvent('Deep Link Pack', {
        description: 'Pack',
        parameters: id,
        url: window.location.href,
      });

      if (pack.title) {
        const url = await getPackUrl(pack);
        navigate(url, { replace: true });
      }
    },
    [navigate],
  );

  const handleCheckout = useCallback(() => {
    navigate({ pathname: '/account/subscription', search }, { replace: true });
  }, [navigate, search]);

  const handleCheckoutWithCode = useCallback(
    (code: string) => {
      navigate(
        { pathname: `/account/subscription/${code}`, search },
        { replace: true },
      );
    },
    [navigate, search],
  );

  const handleFamilyInvitation = useCallback(
    async (token: string) => {
      await acceptFamilyInvitation(token);
      await getProfile({ fetchPolicy: 'network-only' });
      navigate('/account/subscription', { replace: true });
    },
    [acceptFamilyInvitation, navigate],
  );

  const handleFreeTrial = useCallback(() => {
    navigate({ pathname: '/account/free-trial', search }, { replace: true });
  }, [navigate, search]);

  const handleOfferWithCode = useCallback(
    async (code: string) => {
      navigate('/account', { replace: true });
      await processOffer(code);
    },
    [navigate, processOffer],
  );

  const handleOpenOnboardingSlides = useCallback(() => {
    openOnboardingSlidesModal();
    navigate('/', { replace: true });
  }, [navigate, openOnboardingSlidesModal]);

  const handleOpenDailyMeditationDuration = useCallback(() => {
    openDailyDurationModal();
    navigate('/account', { replace: true });
  }, [navigate, openDailyDurationModal]);

  const handleShare = useCallback(
    async (src?: string) => {
      navigate('/', { replace: true });

      const { subscriptionIsElegibleForSharing } = await getProfile();

      const source = src === 'home_cta' ? src : 'deep_link';

      if (subscriptionIsElegibleForSharing) {
        if (isDevelopment) {
          openShareAppModal({ source });
        } else {
          openGiveFreeTrialModal({ source });
        }
      }
    },
    [navigate, openGiveFreeTrialModal, openShareAppModal],
  );

  const handleShareRedeemWithCode = useCallback(
    async (token: string) => {
      validateSharedToken({ token });
      navigate('/', { replace: true });
    },
    [navigate, validateSharedToken],
  );

  const handleShareCourse = useCallback(
    async (courseHash: string, packId?: string) => {
      try {
        navigate('/', { replace: true });

        const user = await getProfile();
        const course = await getCourse(courseHash);

        if (course?.shareable && (course.isFree || user.isSubscribed)) {
          openShareLinkModal({
            id: courseHash,
            title: `Share "${course.title}"`,
            description:
              'Share this link with anyone you feel would benefit from this Waking Up course',
            link: `${AppConfig.dynamicBaseUrl}/course/${courseHash}${
              packId ? `?packId=${packId}` : ''
            }`,
          });
        }
      } catch {
        return undefined;
      }
    },
    [navigate, openShareLinkModal],
  );

  const handlePlayNextMeditation = useCallback(
    async (queryString?: string) => {
      navigate('/', { replace: true });

      const nextPractice = await getNextMeditation();
      if (nextPractice?.type === 'daily') {
        await openDailyPlayer({ queryString, source: PlayerSource.DEEPLINK });
      } else if (
        nextPractice?.type === 'course' &&
        nextPractice.nextMeditation
      ) {
        openCoursePlayer({
          hash: nextPractice.nextMeditation.id,
          packId: nextPractice.nextMeditation.packId,
          queryString,
          source: PlayerSource.DEEPLINK,
        });
      } else {
        navigate('/checkout', { replace: true });
      }
    },
    [navigate, openCoursePlayer, openDailyPlayer],
  );

  const handlePlayDaily = useCallback(
    async (queryString?: string) => {
      navigate('/', { replace: true });
      await openDailyPlayer({ queryString, source: PlayerSource.DEEPLINK });
    },
    [navigate, openDailyPlayer],
  );

  const handleOpenview = useCallback(
    (type: string) => {
      if (OPENVIEW_TYPES[type]) {
        const { description, link } = OPENVIEW_TYPES[type];
        const eventType = `Deep Link ${description}` as AnalyticsEvent;

        if (description && link) {
          trackEvent(eventType, { description, url: window.location.href });
          navigate(link, { replace: true });
        }
      }
    },
    [navigate],
  );

  const handleUpgradeToAnnual = useCallback(async () => {
    navigate('/account/subscription', { replace: true });

    const {
      subscriptionSource,
      subscriptionSubtype,
      subscriptionIsEligibleForYearlyUpgradeWithDiscount:
        isEligibleForYearlyUpgradeWithDiscount,
    } = await getProfile();
    const hasMonthlySubscription =
      subscriptionSource === 'stripe' && subscriptionSubtype === 'month';

    if (hasMonthlySubscription && isEligibleForYearlyUpgradeWithDiscount) {
      const plans = await getSubscriptionPlans();
      const plan = plans.find(
        ({ interval, type }) => interval === 'year' && type === 'standard',
      );

      if (plan) {
        openUpgradeToAnnualModal({ planId: plan.id });
      }
    }
  }, [navigate, openUpgradeToAnnualModal]);

  const handleOpenPerson = useCallback(
    async (id: string) => {
      navigate('/', { replace: true });

      const person = await getPerson(id);

      if (!person) {
        return;
      }

      trackEvent('Deep Link Person', {
        description: 'Person',
        parameters: id,
        url: window.location.href,
      });

      setPersonDetails({ personId: id });
    },
    [navigate],
  );

  useEffect(() => {
    if (matchesSelfTimer) {
      handleOpenSelfTimer();
    }
  }, [matchesSelfTimer, handleOpenSelfTimer]);

  useEffect(() => {
    if (courseId) {
      const { behavior, endTime, packId, playback, time } = parse(search);

      const initialAudioStatus =
        playback && !Array.isArray(playback) ? playback : undefined;
      const initialPosition =
        time && !Array.isArray(time) ? Number(time) : undefined;
      const endPosition =
        endTime && !Array.isArray(endTime) ? Number(endTime) : undefined;
      const endPositionBehavior =
        behavior && !Array.isArray(behavior) ? behavior : undefined;
      const packKey = packId && !Array.isArray(packId) ? packId : undefined;

      handleSetTrack(
        courseId,
        packKey,
        initialAudioStatus,
        initialPosition,
        endPosition,
        endPositionBehavior,
        search,
      );
    }
  }, [courseId, handleSetTrack, search]);

  useEffect(() => {
    if (previewCourseHash) {
      const { packId } = parse(search);

      const packKey = packId && !Array.isArray(packId) ? packId : undefined;

      handlePreview(previewCourseHash, packKey, search);
    }
  }, [handlePreview, previewCourseHash, search]);

  useEffect(() => {
    if (sharedDailyHash) {
      handleOpenSharedDaily(sharedDailyHash, search);
    }
  }, [sharedDailyHash, handleOpenSharedDaily, search]);

  useEffect(() => {
    if (retreatIdParam) {
      handleOpenRetreat(retreatIdParam, search);
    }
  }, [retreatIdParam, handleOpenRetreat, search]);

  useEffect(() => {
    if (freeMonthTrialCourseHash) {
      handleFreeMonthTrial(freeMonthTrialCourseHash, search);
    }
  }, [freeMonthTrialCourseHash, handleFreeMonthTrial, search]);

  useEffect(() => {
    if (freeMonthTrialSocialShareCourseHash) {
      handleFreeMonthTrialSocialShare(freeMonthTrialSocialShareCourseHash);
    }
  }, [freeMonthTrialSocialShareCourseHash, handleFreeMonthTrialSocialShare]);

  useEffect(() => {
    if (packIdParam) {
      handleOpenPack(packIdParam);
    }
  }, [packIdParam, handleOpenPack]);

  useEffect(() => {
    if (matchesCheckout) {
      handleCheckout();
    }
  }, [matchesCheckout, handleCheckout]);

  useEffect(() => {
    if (checkoutCode) {
      handleCheckoutWithCode(checkoutCode);
    }
  }, [checkoutCode, handleCheckoutWithCode]);

  useEffect(() => {
    if (matchesFreeTrial) {
      handleFreeTrial();
    }
  }, [matchesFreeTrial, handleFreeTrial]);

  useEffect(() => {
    if (offerCode) {
      handleOfferWithCode(offerCode);
    }
  }, [handleOfferWithCode, offerCode]);

  useEffect(() => {
    if (matchesOnboarding) {
      handleOpenOnboardingSlides();
    }
  }, [matchesOnboarding, handleOpenOnboardingSlides]);

  useEffect(() => {
    if (matchesDailyMeditationDuration) {
      handleOpenDailyMeditationDuration();
    }
  }, [matchesDailyMeditationDuration, handleOpenDailyMeditationDuration]);

  useEffect(() => {
    if (matchesShare) {
      const { shareSource } = parse(search);

      const source =
        shareSource && !Array.isArray(shareSource) ? shareSource : undefined;

      handleShare(source);
    }
  }, [handleShare, matchesShare, search]);

  useEffect(() => {
    if (matchesShareTheApp) {
      const { shareSource } = parse(search);

      const source =
        shareSource && !Array.isArray(shareSource) ? shareSource : undefined;

      handleShare(source);
    }
  }, [handleShare, matchesShareTheApp, search]);

  useEffect(() => {
    if (shareRedeemToken) {
      handleShareRedeemWithCode(shareRedeemToken);
    }
  }, [handleShareRedeemWithCode, shareRedeemToken]);

  useEffect(() => {
    if (shareCourseHash) {
      const { packId } = parse(search);

      const packKey = packId && !Array.isArray(packId) ? packId : undefined;

      handleShareCourse(shareCourseHash, packKey);
    }
  }, [handleShareCourse, search, shareCourseHash]);

  useEffect(() => {
    if (matchesPlayNextMeditation) {
      handlePlayNextMeditation(search);
    }
  }, [handlePlayNextMeditation, matchesPlayNextMeditation, search]);

  useEffect(() => {
    if (matchesPlayDaily) {
      handlePlayDaily(search);
    }
  }, [handlePlayDaily, matchesPlayDaily, search]);

  useEffect(() => {
    if (openviewType) {
      handleOpenview(openviewType);
    }
  }, [openviewType, handleOpenview]);

  useEffect(() => {
    if (freeMonthCode) {
      handleRedeemFreeMonth(freeMonthCode);
    }
  }, [freeMonthCode, handleRedeemFreeMonth]);

  useEffect(() => {
    if (familyInvitationToken) {
      handleFamilyInvitation(familyInvitationToken);
    }
  }, [familyInvitationToken, handleFamilyInvitation]);

  useEffect(() => {
    if (matchesUpgradeToAnnual) {
      handleUpgradeToAnnual();
    }
  }, [handleUpgradeToAnnual, matchesUpgradeToAnnual]);

  useEffect(() => {
    if (personIdParam) {
      handleOpenPerson(personIdParam);
    }
  }, [personIdParam, handleOpenPerson]);

  return null;
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
