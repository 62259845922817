import styled from 'styled-components';
import { media } from 'styles/mixins';
import { Container as Child } from './Element/styles';

export const Container = styled.div`
  padding: 1.5rem 0 1.5rem 1.5rem;
  width: 100%;

  ${media.tabletPortrait} {
    padding-left: 0;
  }
`;

export const BackButton = styled.button`
  background: transparent;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: block;
  line-height: 1;
  position: absolute;
  top: 1rem;

  ${media.tabletPortrait} {
    left: 1rem;
  }
`;

export const Content = styled.fieldset`
  border: none;
`;

export const Title = styled.legend`
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: 1.25rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`;

export const ElementList = styled.div`
  display: flex;
  flex-direction: column;

  ${Child}:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.measures.m};
  }
`;
