import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import {
  AcceptFamilyMemberDocument,
  AddFamilyMemberDocument,
  RemoveFamilyMemberDocument,
} from '../generated';
import { cacheUpdateFamilyMembers } from '../cache';
import useModalActions from './useModalActions';

const useFamilyMembersActions = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [acceptFamilyMemberMutation, { loading: isAccepting }] = useMutation(
    AcceptFamilyMemberDocument,
  );

  const [addFamilyMemberMutation, { loading: isAdding }] = useMutation(
    AddFamilyMemberDocument,
  );

  const [removeFamilyMemberMutation, { loading: isRemoving }] = useMutation(
    RemoveFamilyMemberDocument,
  );

  const { closeModal, openDialogModal } = useModalActions();

  const acceptFamilyInvitation = useCallback(
    async (token: string) => {
      try {
        const { data } = await acceptFamilyMemberMutation({
          variables: {
            token,
          },
        });

        if (data?.acceptFamilyMember) {
          cacheUpdateFamilyMembers(data.acceptFamilyMember);
        }

        enqueueSnackbar('You have been added to the family.', {
          variant: 'success',
        });
        return data?.acceptFamilyMember;
      } catch (error) {
        enqueueSnackbar(
          error.message ||
            'Unable to accept invitation. Please try again later.',
          { variant: 'error' },
        );
      }
    },
    [acceptFamilyMemberMutation, enqueueSnackbar],
  );

  const addMember = useCallback(
    async (email: string) => {
      try {
        const { data } = await addFamilyMemberMutation({
          variables: {
            email,
          },
        });

        if (data?.addFamilyMember) {
          cacheUpdateFamilyMembers(data.addFamilyMember);
        }

        enqueueSnackbar(`An invitation has been sent to ${email}.`, {
          variant: 'success',
        });
        return data?.addFamilyMember;
      } catch (error) {
        enqueueSnackbar(
          error.message || 'Unable to add member. Please try again later.',
          { variant: 'error' },
        );
      }
    },
    [addFamilyMemberMutation, enqueueSnackbar],
  );

  const removeMember = useCallback(
    (email: string) => {
      const handleRemoveMember = async () => {
        try {
          const { data } = await removeFamilyMemberMutation({
            variables: {
              email,
            },
          });

          if (data?.removeFamilyMember) {
            cacheUpdateFamilyMembers(data.removeFamilyMember);
          }

          enqueueSnackbar('Member removed', { variant: 'success' });

          closeModal();
        } catch (error) {
          enqueueSnackbar(
            error.message || 'Unable to remove member. Please try again later.',
            { variant: 'error' },
          );
        }
      };

      openDialogModal({
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        description: 'Are you sure you want to continue?',
        onCancel: closeModal,
        onConfirm: handleRemoveMember,
        title: 'Remove Member',
      });
    },
    [closeModal, enqueueSnackbar, openDialogModal, removeFamilyMemberMutation],
  );

  return {
    acceptFamilyInvitation,
    addMember,
    isAccepting,
    isAdding,
    isRemoving,
    loading: isAdding || isRemoving || isAccepting,
    removeMember,
  };
};

export default useFamilyMembersActions;
export type UseFamilyMembersActions = ReturnType<
  typeof useFamilyMembersActions
>;
