import { useMemo } from 'react';
import { useQuery, type QueryHookOptions } from '@apollo/client';
import { DEFAULT_SETTINGS, normalizeSettings } from 'models/Settings';
import {
  GetSettingsDocument,
  type GetSettingsQuery,
  type GetSettingsQueryVariables,
} from '../generated';

const useSettings = (
  options: QueryHookOptions<GetSettingsQuery, GetSettingsQueryVariables> = {},
) => {
  const { data, loading } = useQuery(GetSettingsDocument, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  const settings = useMemo(
    () =>
      data?.me?.settings
        ? normalizeSettings(data.me.settings)
        : DEFAULT_SETTINGS,
    [data],
  );

  return {
    loading: !data && loading,
    settings,
  };
};

export default useSettings;
export type UseSettings = ReturnType<typeof useSettings>;
