import { memo, type FC } from 'react';
import { Carousel, Container, FadeOut, HomeSectionHeader } from './styles';
import type { Props } from './types';
import useConnect from './connect';

const WidgetContinueListening: FC<Props> = ({ id }) => {
  const {
    carouselRef,
    continueListening,
    handleControlClick,
    handleItemClick,
    loading,
  } = useConnect({ id });

  if (!loading && (!continueListening || continueListening.length === 0)) {
    return null;
  }

  return (
    <Container>
      <HomeSectionHeader
        onControlClick={handleControlClick}
        title="Continue Listening"
      />
      <Carousel
        carouselRef={carouselRef}
        data={continueListening}
        isLoading={loading}
        onItemClick={handleItemClick}
      />
      <FadeOut />
    </Container>
  );
};

export default memo(WidgetContinueListening);
