import styled from 'styled-components';
import { LinearProgress as MuiLinearProgress } from '@mui/material';
import { media } from 'styles/mixins';
import { Body } from 'components/Typography';
import DefaultIcont from 'components/Icon';

export const Container = styled.div`
  text-transform: uppercase;
  display: contents;
`;

export const LinearProgress = styled(MuiLinearProgress)`
  border-radius: 1rem;
  max-width: 3.75rem;
  width: 100%;

  &.MuiLinearProgress-root {
    margin-right: ${({ theme }) => theme.measures.s};

    .MuiLinearProgress-bar {
      border-radius: 1rem;
    }

    &.MuiLinearProgress-colorPrimary {
      background-color: ${({ theme }) =>
        theme.name === 'LIGHT' ? '#9ec2c5' : theme.colors.coreNeutral600};
      .MuiLinearProgress-barColorPrimary {
        background-color: ${({ theme }) =>
          theme.name === 'LIGHT'
            ? theme.colors.coreTeal700
            : theme.colors.coreTeal500};
      }
    }
  }
`;

export const Total = styled(Body).attrs(() => ({
  $size: 'small',
  $weight: 'bold',
}))`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreTeal700
      : theme.colors.coreTeal500};
`;

export const Icon = styled(DefaultIcont).attrs(({ theme }) => ({
  color: theme.colors.coreNeutral400,
  size: 16,
}))`
  ${media.phonePortrait} {
    font-size: 0.875rem;
  }
`;

export const Played = styled(Body).attrs(() => ({
  $size: 'small',
  $weight: 'bold',
}))`
  color: ${({ theme }) => theme.colors.fontDeemphasized};
  margin-left: 0.44rem;
`;
