import { createGlobalStyle } from 'styled-components';
import { prettyScrollbar } from './mixins';

/*
 * This file should be used to add global declarations that depend on the
 * styled-components theme. If a declaration does not depend on the theme, it
 * should be moved to `src/index.css`.
 */
export default createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.backgroundBase};
    font-family: ${({ theme }) => theme.font.family.inter};
    font-weight: ${({ theme }) => theme.font.weight.book};
    font-size: 1rem;
    line-height: 1.7;
    color: ${({ theme }) => theme.colors.fontBase};
  }
  html {
    ${prettyScrollbar({ size: '0.5rem' })}
  }
  button {
    color: ${({ theme }) => theme.colors.fontBase};  
  }

  ::selection {
    background: ${({ theme }) => theme.colors.fontEmphasized};
    color: ${({ theme }) => theme.colors.coreNeutralWhite};
  }
`;
