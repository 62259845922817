import { memo } from 'react';
import type { FC } from 'react';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { Formik } from 'formik';
import {
  Button,
  CardElement,
  Container,
  CurrentEmail,
  Form,
  FormErrors,
  FormSection,
  Link,
  OtherPaymentButton,
  PaymentMethods,
  Title,
} from './styles';
import { validationSchema } from './constants';
import useConnect from './connect';
import type { Props } from './types';

const CheckoutForm: FC<Props> = ({
  isPublic,
  planAmount,
  planId,
  planInterval,
  priceId,
  promoCode,
  screen,
  sharedBy,
  userEmail,
  trialDuration,
}) => {
  const {
    elements,
    formikRef,
    handleSubmit,
    hasApplePay,
    initialValues,
    paymentRequest,
    stripe,
  } = useConnect({
    isPublic,
    planAmount,
    planId,
    planInterval,
    priceId,
    promoCode,
    screen,
    sharedBy,
    userEmail,
    trialDuration,
  });

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ errors, isSubmitting, values: { email } }) => (
          <Form noValidate>
            <FormSection>
              {email && (
                <CurrentEmail>
                  {userEmail} <Link href="/checkout">- not you?</Link>
                </CurrentEmail>
              )}
            </FormSection>
            <FormSection $disabled={!email || !!errors.email}>
              <Title>{!userEmail ? '2. ' : ''} Select Payment Method</Title>
              {paymentRequest && (
                <PaymentMethods>
                  {hasApplePay ? (
                    <PaymentRequestButtonElement
                      options={{
                        paymentRequest,
                        style: {
                          paymentRequestButton: {
                            height: '50px',
                            theme: 'dark',
                            type: 'subscribe',
                          },
                        },
                      }}
                    />
                  ) : (
                    <OtherPaymentButton
                      onClick={paymentRequest.show}
                      type="button"
                    >
                      Select Credit Card
                    </OtherPaymentButton>
                  )}
                </PaymentMethods>
              )}
              <CardElement />
              {errors.submitError && (
                <FormErrors error center>
                  Error: {errors.submitError}
                </FormErrors>
              )}
              <Button
                disabled={!stripe || !elements}
                isLoading={isSubmitting}
                type="submit"
                id="stripe-payment-submit-button"
              >
                Subscribe
              </Button>
            </FormSection>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default memo(CheckoutForm);
