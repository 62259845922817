import type { FC } from 'react';
import { Button, Buttons, Content, Modal, Title } from './styles';
import useConnect from './connect';

const Dialog: FC = () => {
  const {
    buttonsPosition,
    cancelButtonId,
    cancelButtonText,
    confirmButtonId,
    confirmButtonText,
    description,
    handleClose,
    handleCancel,
    handleConfirm,
    open,
    title,
  } = useConnect();

  return (
    <Modal hideClose onClose={handleClose} open={open}>
      {title && <Title as="h2">{title}</Title>}
      <Content>{description}</Content>
      <Buttons $position={buttonsPosition}>
        <Button id={cancelButtonId} mode="deemphasized" onClick={handleCancel}>
          {cancelButtonText}
        </Button>
        {handleConfirm && (
          <Button id={confirmButtonId} onClick={handleConfirm}>
            {confirmButtonText}
          </Button>
        )}
      </Buttons>
    </Modal>
  );
};

export default Dialog;
