import { memo } from 'react';
import type { FC } from 'react';
import { Props } from './types';

const Isotype: FC<Props> = ({ ...rest }) => (
  <svg
    aria-label="Waking Up head isotype"
    fill="none"
    role="img"
    viewBox="0 0 68 94"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M61.54 43.96h6.22l-7.49-21.13v.03c-3.78-12.72-15.55-22-29.49-22C13.78.85 0 14.63 0 31.62 0 40 3.35 47.59 8.79 53.14h-.01l.18.18.14.14 40.15 39.81V62.39h12.29V43.96z"
      fill="currentColor"
    />
  </svg>
);

export default memo(Isotype);
