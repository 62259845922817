import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import formatCents from 'lib/formatCents';
import { trackEvent } from 'services/analytics';
import { trackGAEvent } from 'services/analytics/ga';
import { trackFacebookEvent } from 'services/analytics/facebook';
import {
  ActiveCampaigns,
  CancelSubscriptionDocument,
  CreateStripeCheckoutSessionDocument,
  CreateStripeCheckoutSessionPublicDocument,
  PurchaseLifetimeDocument,
  ReactivateSubscriptionDocument,
  SubscribeStripeCheckoutDocument,
  SubscribeStripeDocument,
  UpdateSubscriptionDocument,
  type CheckoutSessionType,
  type TrialDuration,
} from '../generated';
import { formatErrorMessage } from '../helpers';
import {
  GET_CONTENT,
  GET_DAILY,
  GET_FEATURE_CONTENT,
  GET_LIFETIME_PRICE,
  GET_REMOTE_CONFIG,
  GET_SUBSCRIPTION_PLANS,
} from '../queries';
import { getSubscriptionPlan } from '../requests';
import useAuth from './useAuth';
import useModalActions from './useModalActions';

const useSubscriptionActions = () => {
  const { isAuthenticated } = useAuth();
  const { closeModal, openDialogModal } = useModalActions();
  const { enqueueSnackbar } = useSnackbar();

  const [isCreatingSubscription, setIsCreatingSubscription] = useState(false);

  const [subscribeStripe, { loading: isLoadingSubscribeStripe }] = useMutation(
    SubscribeStripeDocument,
    {
      refetchQueries: isAuthenticated
        ? [
            { query: GET_CONTENT },
            { query: GET_DAILY },
            { query: GET_FEATURE_CONTENT },
            { query: GET_REMOTE_CONFIG },
            { query: GET_SUBSCRIPTION_PLANS },
            { query: GET_LIFETIME_PRICE },
          ]
        : undefined,
    },
  );

  const [
    subscribeStripeCheckout,
    { loading: isLoadingSubscribeStripeCheckout },
  ] = useMutation(SubscribeStripeCheckoutDocument);

  const [
    createStripeCheckoutSession,
    { loading: isLoadingCreateStripeCheckoutSession },
  ] = useMutation(CreateStripeCheckoutSessionDocument);

  const [
    createStripeCheckoutSessionPublic,
    { loading: isLoadingCreateStripeCheckoutSessionPublic },
  ] = useMutation(CreateStripeCheckoutSessionPublicDocument);

  const [purchaseLifetimeMutation, { loading: isLoadingPurchaseLifetime }] =
    useMutation(PurchaseLifetimeDocument, {
      refetchQueries: isAuthenticated
        ? [
            { query: GET_CONTENT },
            { query: GET_DAILY },
            { query: GET_FEATURE_CONTENT },
            { query: GET_REMOTE_CONFIG },
            { query: GET_SUBSCRIPTION_PLANS },
            { query: GET_LIFETIME_PRICE },
          ]
        : undefined,
    });

  const [updateSubscription, { loading: isLoadingUpdate }] = useMutation(
    UpdateSubscriptionDocument,
    {
      refetchQueries: isAuthenticated
        ? [
            { query: GET_CONTENT },
            { query: GET_DAILY },
            { query: GET_FEATURE_CONTENT },
            { query: GET_REMOTE_CONFIG },
            { query: GET_SUBSCRIPTION_PLANS },
            { query: GET_LIFETIME_PRICE },
          ]
        : undefined,
    },
  );

  const [cancelSubscription, { loading: isLoadingCancel }] = useMutation(
    CancelSubscriptionDocument,
  );

  const [reactivateSubscription, { loading: isLoadingReactivate }] =
    useMutation(ReactivateSubscriptionDocument, {
      refetchQueries: isAuthenticated
        ? [
            { query: GET_CONTENT },
            { query: GET_DAILY },
            { query: GET_FEATURE_CONTENT },
            { query: GET_REMOTE_CONFIG },
          ]
        : undefined,
    });

  const createCheckoutSession = useCallback(
    async ({
      cancelUrl = window.location.href,
      email,
      isPublic,
      priceId,
      queryParams,
      type,
    }: {
      cancelUrl?: string;
      email: string;
      isPublic: boolean;
      priceId: string;
      queryParams?: Record<string, string>;
      type: CheckoutSessionType;
    }) => {
      try {
        let stripeCheckoutSessionURL = '';

        if (isPublic) {
          const { data } = await createStripeCheckoutSessionPublic({
            variables: {
              data: {
                cancel_url: cancelUrl,
                email,
                price_id: priceId,
                query_params: queryParams,
                type,
              },
            },
          });

          stripeCheckoutSessionURL =
            data?.createStripeCheckoutSessionPublic || '';
        } else {
          const { data } = await createStripeCheckoutSession({
            variables: {
              data: {
                cancel_url: cancelUrl,
                price_id: priceId,
                type,
              },
            },
          });

          stripeCheckoutSessionURL = data?.createStripeCheckoutSession || '';
        }

        return stripeCheckoutSessionURL;
      } catch (error) {
        throw new Error(
          formatErrorMessage(
            error,
            'It was not possible to redirect to the checkout',
          ),
        );
      }
    },
    [createStripeCheckoutSession, createStripeCheckoutSessionPublic],
  );

  const create = useCallback(
    async ({
      email,
      experiment,
      isPublic,
      paymentMethodId,
      planAmount,
      planId,
      promoCode,
      screen,
      sharedBy,
      trialDuration,
      variant,
    }: {
      email: string;
      experiment?: string;
      isPublic: boolean;
      paymentMethodId: string;
      planAmount: number;
      planId: string;
      promoCode?: string;
      screen?: string;
      sharedBy?: string;
      trialDuration?: TrialDuration;
      variant?: string;
    }) => {
      try {
        setIsCreatingSubscription(true);

        let userId = '';
        let subscriptionExternalId = '';
        let subscriptionChargeDate = '';

        if (isPublic) {
          const { data } = await subscribeStripeCheckout({
            variables: {
              data: {
                email,
                payment_method: paymentMethodId,
                plan_id: planId,
                promocode: promoCode,
                shared_by: sharedBy,
                trial_duration: trialDuration,
              },
            },
          });

          userId = data?.subscribeStripeCheckout?.id || '';
          subscriptionExternalId =
            data?.subscribeStripeCheckout?.external_id || '';
          subscriptionChargeDate =
            data?.subscribeStripeCheckout?.stripe_trial_charge_date || '';
        } else {
          const { data } = await subscribeStripe({
            variables: {
              data: {
                payment_method: paymentMethodId,
                plan_id: planId,
                promocode: promoCode,
                shared_by: sharedBy,
                trial_duration: trialDuration,
              },
            },
          });

          userId = data?.subscribeStripe?.id || '';
          subscriptionExternalId =
            data?.subscribeStripe?.user_subscription?.external_id || '';
          subscriptionChargeDate =
            data?.subscribeStripe?.user_subscription
              ?.stripe_trial_charge_date || '';
        }

        const experimentData = experiment ? { experiment, variant } : {};

        const plan = await getSubscriptionPlan(planId, promoCode);

        trackFacebookEvent('track', 'Purchase', {
          value: (planAmount || 0) / 100,
          currency: 'USD',
        });

        trackEvent('Purchase Completed', {
          ...experimentData,
          code: promoCode,
          cost: formatCents(planAmount).slice(1),
          email,
          screen,
          subscription_option: plan?.sku || planId,
          subscription_sku: plan?.sku || planId,
        });

        trackGAEvent('event', `subscription_new`, {
          event_category: 'subscription',
          logged_in: isPublic ? 'no' : 'yes',
          subscription_option: planId,
        });

        if (!isPublic) {
          closeModal();
          setTimeout(() => {
            openDialogModal({
              cancelButtonText: 'Close',
              description:
                'Thank you for subscribing to Waking Up! You have now unlocked all exclusive content!',
              onCancel: closeModal,
              title: 'You are subscribed!',
            });
          }, 300);
        }

        return { userId, subscriptionChargeDate, subscriptionExternalId };
      } catch (error) {
        const plan = await getSubscriptionPlan(planId, promoCode);

        trackEvent('Purchase Uncompleted', {
          experiment,
          subscription_option: plan?.sku || planId,
          variant,
        });

        throw new Error(
          formatErrorMessage(
            error,
            'It was not possible to create the subscription',
          ),
        );
      } finally {
        setIsCreatingSubscription(false);
      }
    },
    [closeModal, openDialogModal, subscribeStripe, subscribeStripeCheckout],
  );

  const update = useCallback(
    async ({
      campaign,
      planId,
    }: {
      campaign?: ActiveCampaigns;
      planId: string;
    }) => {
      try {
        await updateSubscription({ variables: { campaign, planId } });
        enqueueSnackbar('Your subscription has been updated', {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(
          error?.message || 'Unable to update the current subscription',
          { variant: 'error' },
        );
      }
    },
    [updateSubscription, enqueueSnackbar],
  );

  const cancel = useCallback(async () => {
    try {
      await cancelSubscription();
      enqueueSnackbar('Your subscription has been canceled', {
        variant: 'success',
      });
      trackEvent('Purchase Cancel Subscription');
    } catch (error) {
      enqueueSnackbar(
        error?.message || 'Unable to cancel the current subscription',
        { variant: 'error' },
      );
    }
  }, [cancelSubscription, enqueueSnackbar]);

  const reactivate = useCallback(async () => {
    try {
      await reactivateSubscription();
      enqueueSnackbar('Your subscription has been reactivated', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(
        error?.message || 'Unable to reactivate the subscription',
        { variant: 'error' },
      );
    }
  }, [reactivateSubscription, enqueueSnackbar]);

  const purchaseLifetime = useCallback(
    async ({
      amount,
      email,
      isPublic,
      paymentMethodId,
      priceId,
    }: {
      amount: number;
      email?: string;
      isPublic: boolean;
      paymentMethodId: string;
      priceId?: string;
    }) => {
      try {
        const { data } = await purchaseLifetimeMutation({
          variables: {
            data: { payment_method: paymentMethodId, email, price_id: priceId },
          },
        });

        const info = {
          userId: data?.purchaseLifetime?.id || '',
          subscriptionChargeDate:
            data?.purchaseLifetime?.user_subscription?.expires_at || '',
          subscriptionExternalId:
            data?.purchaseLifetime?.user_subscription?.external_id || '',
        };

        trackEvent('Purchase Completed', {
          cost: formatCents(amount).slice(1),
          email,
          subscription_option: 'lifetime',
          subscription_sku: 'lifetime',
        });

        if (!isPublic) {
          closeModal();
          setTimeout(() => {
            openDialogModal({
              cancelButtonText: 'Close',
              description:
                'Thank you for subscribing to Waking Up! You have now unlocked all exclusive content!',
              onCancel: closeModal,
              title: 'You are subscribed!',
            });
          }, 300);
        }

        return info;
      } catch (error) {
        trackEvent('Purchase Uncompleted', { subscription_option: 'lifetime' });

        throw new Error(
          formatErrorMessage(
            error,
            'It was not possible to create the lifetime subscription',
          ),
        );
      }
    },
    [closeModal, openDialogModal, purchaseLifetimeMutation],
  );

  return {
    cancel,
    create,
    createCheckoutSession,
    isCancelling: isLoadingCancel,
    isCreatingSubscription,
    isPurchasingLifetime: isLoadingPurchaseLifetime,
    isReactivating: isLoadingReactivate,
    isUpdating: isLoadingUpdate,
    loading:
      isLoadingCancel ||
      isLoadingReactivate ||
      isLoadingSubscribeStripe ||
      isLoadingSubscribeStripeCheckout ||
      isLoadingCreateStripeCheckoutSession ||
      isLoadingCreateStripeCheckoutSessionPublic ||
      isLoadingUpdate ||
      isLoadingPurchaseLifetime ||
      isCreatingSubscription,
    reactivate,
    update,
    purchaseLifetime,
  };
};

export default useSubscriptionActions;
export type UseSubscriptionActions = ReturnType<typeof useSubscriptionActions>;
