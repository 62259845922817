import { memo, type FC } from 'react';
import NotificationBanner from 'components/NotificationBanner';
import Placeholder from '../Placeholder';
import type { Props } from './types';
import useConnect from './connect';

const Banner: FC<Props> = ({ widgetId }) => {
  const {
    buttonText,
    deepLink,
    description,
    handleButtonClick,
    handleClose,
    isApiCta,
    isLoading,
    title,
  } = useConnect({ widgetId });

  if (isLoading) {
    return <Placeholder />;
  }

  return (
    <NotificationBanner
      body={description}
      buttonLabel={buttonText}
      buttonTo={deepLink}
      onClickButton={handleButtonClick}
      onClose={isApiCta ? handleClose : undefined}
      title={title}
    />
  );
};

export default memo(Banner);
