import styled from 'styled-components';
import { media } from 'styles/mixins';
import { Heading } from 'components/Typography';

export const Container = styled.li`
  list-style-type: none;
`;

export const NavItem = styled.div`
  align-items: center;
  background: transparent;
  border: none;
  box-sizing: content-box;
  color: ${({ theme }) => theme.colors.fontDeemphasized};
  cursor: pointer;
  display: flex;
  line-height: 1;
  margin-bottom: 1.75rem;
  outline: none;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:not(.active) {
    &:hover,
    &:focus {
      color: ${({ theme }) =>
        theme.name === 'LIGHT'
          ? theme.colors.coreNeutral700
          : theme.colors.coreNeutral200};
      transform: translateX(3px);
    }
  }

  &.active {
    color: ${({ theme }) => theme.colors.fontEmphasized};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  ${media.tabletLandscape} {
    margin-bottom: 1.17rem;
  }
`;

export const Title = styled(Heading)`
  font-size: 0.875rem;
  font-weight: inherit;
  margin-left: ${({ theme }) => theme.measures.m};
`;
