import styled from 'styled-components';

export const StyledForm = styled.form.attrs(() => ({ noValidate: true }))`
  color: ${({ theme }) => theme.colors.coreNeutralWhite};
  margin-left: auto;
  margin-right: auto;
  max-width: 24rem;
  padding-bottom: 1.75rem;
  width: 100%;

  h1,
  h2,
  h3 {
    text-align: center;
    white-space: pre-wrap;
  }
`;

export const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  margin-bottom: ${({ theme }) => theme.measures.s};
  text-align: center;
`;

export const FormHeader = styled.header`
  padding-bottom: ${({ theme }) => theme.measures.xs};
`;
