import styled, { css } from 'styled-components';
import { media } from 'styles/mixins';
import getHours from 'date-fns/getHours';
import colorWithAlpha from 'lib/color';
import Panel from 'components/Player/Panel';
import DefaultControlButtons from 'components/Player/ControlButtons';
import DefaultProgressCircle from 'components/Player/ProgressCircle';
import DefaultProgressBar from 'components/Player/ProgressBar';
import DefaultVolumeControls from 'components/Player/VolumeControls';
import { Heading } from 'components/Typography';

import bkgDayDesktop from 'assets/images/selftimer/bkg-selftimer-day-desktop.jpg';
import bkgDuskDesktop from 'assets/images/selftimer/bkg-selftimer-dusk-desktop.jpg';
import bkgNightDesktop from 'assets/images/selftimer/bkg-selftimer-night-desktop.jpg';
import bkgDayMobile from 'assets/images/selftimer/bkg-selftimer-day-mobile.jpg';
import bkgDuskMobile from 'assets/images/selftimer/bkg-selftimer-dusk-mobile.jpg';
import bkgNightMobile from 'assets/images/selftimer/bkg-selftimer-night-mobile.jpg';

import {
  CIRCLE_DEFAULT_WIDTH,
  CIRCLE_SCALE_FACTOR_1,
  CIRCLE_SCALE_FACTOR_2,
  CIRCLE_SCALE_FACTOR_3,
} from './constants';

export const Container = styled(Panel)<{
  isMinimized: boolean;
}>`
  ${({ isMinimized }) =>
    !isMinimized &&
    css`
      background-image: ${() => {
        const hour = getHours(new Date());
        if (hour >= 6 && hour < 15) return `url('${bkgDayDesktop}')`;
        if (hour >= 15 && hour < 21) return `url('${bkgDuskDesktop}')`;
        return `url('${bkgNightDesktop}')`;
      }};
      background-position: 50% 100%;
      background-repeat: no-repeat;
      background-size: cover;

      ${media.phonePortrait} {
        background-image: ${() => {
          const hour = getHours(new Date());
          if (hour >= 6 && hour < 15) return `url('${bkgDayMobile}')`;
          if (hour >= 15 && hour < 21) return `url('${bkgDuskMobile}')`;
          return `url('${bkgNightMobile}')`;
        }};
      }
    `};
`;

export const Title = styled(Heading).attrs(() => ({ $weight: 'bold' }))<{
  $isMinimized: boolean;
}>`
  display: ${({ $isMinimized }) => ($isMinimized ? 'inline-block' : 'none')};
  font-size: 1rem;
  left: 2.75rem;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  user-select: none;

  ${media.tabletPortrait} {
    transform: translate(0, -50%);
  }

  @media (max-height: 500px) {
    top: 50%;
  }
`;

export const ProgressCircle = styled(DefaultProgressCircle)`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: scale(${CIRCLE_SCALE_FACTOR_1})
    translate(${-50 / CIRCLE_SCALE_FACTOR_1}%, ${-50 / CIRCLE_SCALE_FACTOR_1}%)
    translateY(-2rem);

  @media (max-height: 875px) {
    transform: translate(-50%, -50%) translateY(-2.5rem);
  }

  @media (max-height: 750px) {
    transform: scale(${CIRCLE_SCALE_FACTOR_2})
      translate(
        ${-50 / CIRCLE_SCALE_FACTOR_2}%,
        ${-50 / CIRCLE_SCALE_FACTOR_2}%
      )
      translateY(-2.85rem);
  }

  @media (max-height: 620px) {
    transform: scale(${CIRCLE_SCALE_FACTOR_3})
      translate(${-50 / CIRCLE_SCALE_FACTOR_3}%, 0);
    top: 0;
  }

  ${media.tabletPortrait} {
    top: 20%;
    transform: translate(-50%, 0);

    @media (max-height: 720px) {
      top: 10%;
    }
  }
`;

export const ProgressBar = styled(DefaultProgressBar)<{
  isMinimized: boolean;
}>`
  ${({ isMinimized }) =>
    !isMinimized &&
    css`
      bottom: 55%;
      position: absolute;
      width: 80%;
    `}
`;

export const ControlButtons = styled(DefaultControlButtons)<{
  isMinimized: boolean;
}>`
  bottom: 8.4rem;
  left: 50%;
  position: absolute;
  top: auto;
  transform: translate(-50%, 0);

  @media (max-height: 450px) {
    bottom: 6rem;
  }

  ${({ isMinimized }) =>
    isMinimized &&
    css`
      bottom: auto;
      left: auto;
      right: 1rem;
      top: 50%;
      transform: translate(0, -50%);

      ${media.tabletPortrait} {
        right: 0.5rem;
      }
    `}

  ${media.tabletPortrait} {
    ${({ isMinimized }) =>
      !isMinimized &&
      css`
        top: calc(20% + ${CIRCLE_DEFAULT_WIDTH});
        padding-top: ${({ theme }) => theme.measures.x};
        display: block;

        @media (max-height: 720px) {
          top: calc(10% + ${CIRCLE_DEFAULT_WIDTH});
          padding-top: ${({ theme }) => theme.measures.l};
        }

        @media (max-height: 450px) {
          padding-top: 0;
          top: 50%;
        }
      `}
  }
`;

export const VolumeControls = styled(DefaultVolumeControls)`
  bottom: 5.75rem;
  position: absolute;
  right: 50%;
  transform: translate(40%, 0);

  @media (max-height: 450px) {
    right: 20%;
    bottom: 50%;
    transform: translateY(-0.5rem);
  }
`;

export const TimerFinishesAt = styled.div<{ $isMinimized?: boolean }>`
  margin: auto;
  background: ${({ theme }) =>
    colorWithAlpha({
      color: theme.colors.coreNeutralBlack,
      alpha: 0.1,
    })};
  bottom: ${({ theme }) => theme.measures.x};
  color: ${({ theme }) => theme.colors.coreNeutralAurora};
  font-size: 1rem;
  line-height: 1.25;
  padding: 0.625rem 4.65rem;
  position: absolute;
  right: 50%;
  text-align: center;
  transform: translate(50%, 0);

  ${media.phoneLandscape} {
    bottom: ${({ theme }) => theme.measures.xl};
    left: ${({ theme }) => theme.measures.m};
    padding: 0.625rem;
    right: ${({ theme }) => theme.measures.m};
    transform: none;
  }

  @media (max-height: 450px) {
    bottom: 2rem;
  }

  ${({ $isMinimized }) =>
    $isMinimized &&
    css`
      display: none;
    `}
`;
