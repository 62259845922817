import type { FC } from 'react';
import { Props } from './types';

const CloudCheckLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M7.5 12a7.5 7.5 0 1 1 7.5 7.5H6.75A5.25 5.25 0 1 1 8.055 9.163"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m18 10.875-4.5 4.5-2.25-2.25"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CloudCheckLight;
