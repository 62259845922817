import { useEffect, useState } from 'react';

const useCountdown = (date: string, interval: number = 1000) => {
  const dateTime = new Date(date).getTime();
  const [now, setNow] = useState(Date.now());

  const countdown = dateTime - now;
  const hasFinished = countdown <= 0;

  useEffect(() => {
    if (!hasFinished) {
      const timer = setInterval(() => {
        setNow(Date.now());
      }, interval);

      return () => clearInterval(timer);
    }
  }, [hasFinished, interval]);

  return { countdown: countdown <= 0 ? 0 : countdown, now };
};

export default useCountdown;
