import { isDevelopment } from 'config';
import { DailyMeditationDuration } from 'models/DailyMeditationDuration';
import { ContentStatus, type UserDataFragment } from 'graphql/generated';

export type ProfileFormFields = {
  avatarAlt?: string;
  avatarId?: string;
  avatarUrl?: string;
  city?: string;
  email?: string;
  firstName?: string;
  id?: string;
  lastName?: string;
};

export const CANNOT_SHARE_FREE_TIME = ['share', 'groups'];

const getDailyDurationType = (dailyDuration: number | null | undefined) => {
  switch (dailyDuration) {
    case 1:
      return DailyMeditationDuration.MIN_10;
    case 2:
    default:
      return DailyMeditationDuration.MIN_20;
  }
};

export const normalizeUser = (user: UserDataFragment | null | undefined) => ({
  activityMindfulDays: user?.activity?.mindful_days || 0,
  activityMindfulMinutes: user?.activity?.minutes || 0,
  activityTotalSessions: user?.activity?.total_sessions || 0,
  alreadyRedeemedTrial: user?.already_redeemed_trial ?? false,
  avatarAlt: `Avatar ${user?.avatar?.id}`,
  avatarId: user?.avatar?.id || '',
  avatarUrl:
    user?.avatar?.thumbnails?.thumb_x1 ||
    user?.avatar?.thumbnails?.thumb_x2 ||
    user?.avatar?.url ||
    '',
  city: user?.city || '',
  dailyDurationType: getDailyDurationType(user?.daily_duration_type),
  email: user?.email || '',
  firstName: user?.first_name || '',
  fullName: user?.full_name || '',
  id: user?.id || '',
  introPackId: (user?.intro_pack_id || '').toString(),
  isAllContentUnlocked: user?.content_status === ContentStatus.unlocked,
  isDailyUnlocked: user?.content_status === ContentStatus.unlocked,
  isEligibleForOpenAccess: user?.is_eligible_for_open_access ?? false,
  isSubscribed: user?.is_subscribed ?? false,
  isNew: user?.is_new ?? false,
  joinedAt: (user?.joined_at as string) || '',
  notifications:
    user?.notifications?.map(({ id }) => ({
      id,
    })) || [],
  lastName: user?.last_name || '',
  shareCode: user?.share_code || '',
  showOpenAccessExpiredBanner: user?.show_open_access_expired_banner ?? false,
  showPurchaseOptions: user?.show_purchase_options ?? false,
  subscriptionExpiresAt: (user?.user_subscription?.expires_at as string) || '',
  subscriptionExternalId: user?.user_subscription?.external_id || '',
  subscriptionExternalPlanId: user?.user_subscription?.external_plan_id || '',
  subscriptionInterval:
    user?.user_subscription?.sub_type?.replace(/_(trial|family)/g, '') || '',
  subscriptionIsElegibleForSharing:
    user?.user_subscription?.is_eligible_for_sharing ?? false,
  subscriptionIsEligibleForYearlyUpgradeWithDiscount:
    isDevelopment &&
    !!user?.user_subscription?.is_eligible_for_yearly_upgrade_with_discount,
  subscriptionPriceUsd: user?.user_subscription?.price_usd || 0,
  subscriptionRenewIntent: user?.user_subscription?.renew_intent || '',
  subscriptionSource: user?.user_subscription?.source || '',
  subscriptionStartTime: (user?.user_subscription?.start_time as string) || '',
  subscriptionStripeTrialChargeDate:
    user?.user_subscription?.stripe_trial_charge_date || '',
  subscriptionSubtype: user?.user_subscription?.sub_type || '',
  subscriptionFamilyMember: user?.user_subscription?.family_member || null,
  typename: user?.__typename || 'User',
  contentStatus: user?.content_status || ContentStatus.locked,
  uuid: user?.uuid || '',
});

export type User = ReturnType<typeof normalizeUser>;
