import { apolloClient } from 'services/api';
import type { CourseDataFragment } from '../generated';
import { COURSE_DATA_FRAGMENT } from '../fragments';

function cacheUpdateCourseFragment(
  hash: string,
  data: Partial<CourseDataFragment>,
) {
  const id = `Course:{"hash":"${hash}"}`;

  const cacheData = apolloClient.readFragment<CourseDataFragment>({
    fragment: COURSE_DATA_FRAGMENT,
    fragmentName: 'CourseData',
    id,
  });

  if (cacheData) {
    apolloClient.writeFragment<CourseDataFragment>({
      fragment: COURSE_DATA_FRAGMENT,
      fragmentName: 'CourseData',
      id,
      data: {
        ...cacheData,
        ...data,
      },
    });
  }
}

export default cacheUpdateCourseFragment;
