import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultCheckmarkIcon from 'components/Icons/Checkmark';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 2rem 0 1rem 0;
`;

export const Title = styled.h1`
  color: inherit;
  font-size: 2.25rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.25;
  margin-bottom: 1.4rem;
  text-align: center;

  ${media.tabletPortrait} {
    margin-top: 1.25rem;
  }

  ${media.phoneLandscape} {
    margin-bottom: 2rem;
    margin-top: 0;
  }
`;

export const Features = styled.ul`
  line-height: 1.6;
  margin-bottom: 2.4rem;
  padding: 0 1.8rem 0 2rem;
  width: 24rem;
`;

export const Item = styled.li`
  align-items: flex-start;
  display: flex;
  flex-flow: row nowrap;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  justify-content: flex-start;
  list-style-type: none;
  margin-bottom: 0.8rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CheckmarkIcon = styled(DefaultCheckmarkIcon).attrs(() => ({
  size: 18,
}))`
  color: ${({ theme }) => theme.colors.coreTeal500};
  display: block;
  margin-top: 0.4rem;
`;

export const Text = styled.div`
  flex: 1 1 0;
  margin-left: 1rem;
`;
