import styled, { css } from 'styled-components';
import MuiSlider from '@mui/material/Slider';

export const SliderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 1.25rem 0.375rem 1.25rem;
`;

export const Slider = styled(MuiSlider)`
  max-width: 9.75rem;
  margin-left: 0.25rem;

  .MuiSlider-rail {
    border-radius: 2px;
    height: 2px;

    ${({ theme }) =>
      css`
        color: ${theme.name === 'LIGHT'
          ? theme.colors.coreNeutral100
          : theme.colors.coreNeutralAurora};
      `}
  }

  .MuiSlider-thumb {
    height: 1rem;
    width: 1rem;

    ${({ theme }) =>
      css`
        color: ${theme.name === 'LIGHT'
          ? theme.colors.coreBlue500
          : theme.colors.coreTeal500};
      `}

    &.Mui-disabled {
      height: 1rem;
      width: 1rem;
    }
  }

  .MuiSlider-valueLabel {
    left: calc(50% - 16px);
    top: 2.5rem;

    & * {
      background: transparent;
      color: ${({ theme }) => theme.colors.coreNeutralBlack};
      font-weight: ${({ theme }) => theme.font.weight.bold};
      line-height: 1.1875rem;
    }
  }

  &.MuiSlider-root {
    color: transparent;

    @media (pointer: coarse) {
      padding: 0.8125rem 0;
    }

    &.Mui-disabled {
      color: transparent;
      opacity: 0.3;
    }
  }
`;

export const Volume = styled.p<{ $disabled: boolean }>`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: 0.01em;
  line-height: 1.4286;
  margin-left: 0.375rem;

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.3;
    `}
`;
