import { makeVar } from '@apollo/client';
import { getUpgradeToAnnual, setUpgradeToAnnual } from 'services/storage';

export type UpgradeToAnnual = {
  lastCheckedDates: Record<string, string>;
};

export const upgradeToAnnualVar = makeVar<UpgradeToAnnual>({
  lastCheckedDates: getUpgradeToAnnual() || {},
});

export const updateUpgradeToAnnual = (userId: string) => {
  const now = new Date().toISOString();

  const currentValue = upgradeToAnnualVar()?.lastCheckedDates || {};
  const newValue = { ...currentValue, [userId]: now };

  upgradeToAnnualVar({ ...upgradeToAnnualVar(), lastCheckedDates: newValue });
  setUpgradeToAnnual(newValue);
};
