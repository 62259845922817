import { useCallback } from 'react';
import type { FC } from 'react';
import { Dot, Item, Text } from './styles';
import type { Props } from './types';

const SpeedOptionItem: FC<Props> = ({ active, onClick, value }) => {
  const handleClick = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  return (
    <Item $active={active} onClick={handleClick}>
      <Dot>{active && <>&bull;</>}</Dot>
      <Text variant="inherit" noWrap>
        {value.toFixed(1)}x
      </Text>
    </Item>
  );
};

export default SpeedOptionItem;
