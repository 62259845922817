import { memo } from 'react';
import type { FC } from 'react';
import kebabCase from 'lodash/kebabCase';
import CardListItem from 'components/CardListItem';
import Loader from './Loader';
import { Container } from './styles';
import { Props } from './types';

const PacksList: FC<Props> = ({
  ariaLabelledby,
  className,
  currentUrl,
  hideImages = false,
  hideTopBorder = false,
  id = 'pack-list',
  isLoading,
  packs = [],
  role,
  showDescriptions = false,
}) => {
  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container
      $hideTopBorder={hideTopBorder}
      aria-labelledby={ariaLabelledby}
      className={className}
      id={id}
      role={role}
    >
      {packs.map((pack) => {
        let subtitle = '';
        if (pack.isDaily) {
          subtitle = 'Refreshes dailies';
        } else if (pack.displayBylines) {
          subtitle = pack.subtitle;
        }

        return (
          <CardListItem
            key={pack.id}
            description={showDescriptions ? pack.description : undefined}
            displayContentCount={!pack.isDaily}
            highlight={pack.isNewText}
            imageUrl={
              hideImages
                ? undefined
                : pack?.imageThumbnailsThumbX1 ||
                  pack?.imageThumbnailsThumbX2 ||
                  pack?.imageUrl
            }
            nSubpacks={pack.nSubpacks}
            progress={pack.nSubpacks > 0 ? 0 : pack.progress}
            rightIcon={pack.isDaily ? 'lockSimpleLight' : undefined}
            subtitle={subtitle}
            title={pack.title}
            to={`${currentUrl}/${kebabCase(pack.title)}`}
            total={pack.courses.length}
            type="pack"
          />
        );
      })}
    </Container>
  );
};

export default memo(PacksList);
