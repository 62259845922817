import { memo } from 'react';
import type { FC } from 'react';
import { Container, Overlay } from './styles';
import { Props } from './types';

const Background: FC<Props> = ({ image }) => (
  <Container $image={image}>
    <Overlay />
  </Container>
);

export default memo(Background);
