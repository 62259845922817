import styled from 'styled-components';
import { MAX_CONTENT_WIDTH } from 'styles/metrics';
import { media } from 'styles/mixins';
import DefaultHeader from 'components/PublicPageHeader';
import DefaultFooter from 'components/PublicPageFooter';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.coreTeal950};
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;
  max-width: ${MAX_CONTENT_WIDTH};
  min-height: 100vh;
  padding: 0 2rem;

  ${media.phoneLandscape} {
    padding: 0 1.25rem;
  }
`;

export const Header = styled(DefaultHeader)`
  margin-bottom: 4rem;
  margin-top: 2.5rem;

  ${media.tabletLandscape} {
    margin-bottom: 2.5rem;
  }

  ${media.phoneLandscape} {
    margin-bottom: 2.1875rem;
    margin-top: 2.1875rem;
  }
`;

export const Footer = styled(DefaultFooter)`
  ${media.phonePortrait} {
    margin-top: 2rem;
  }
`;
