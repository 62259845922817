import type { FC } from 'react';
import OptionsMenuItem from '../OptionsMenuItem';
import useConnect from './connect';

const RetreatOptionsMenu: FC = () => {
  const {
    addToCalendarUrl,
    handleAddToCalendar,
    handleShare,
    handleLeave,
    handleToggleNotifications,
    hasReserved,
    notificationsEnabled,
  } = useConnect();

  return (
    <>
      <OptionsMenuItem
        href={addToCalendarUrl}
        iconName="arrowCircleDownLight"
        onClick={handleAddToCalendar}
        text="Add to Calendar"
      />
      {hasReserved && (
        <>
          <OptionsMenuItem
            iconName="checkCircleLightInactive"
            onClick={handleLeave}
            text="Leave event"
          />
          <OptionsMenuItem
            active={notificationsEnabled}
            iconName="checkCircleLightInactive"
            onClick={handleToggleNotifications}
            text={`${
              notificationsEnabled ? 'Disable' : 'Enable'
            } notifications`}
          />
        </>
      )}
      <OptionsMenuItem
        iconName="shareLight"
        onClick={handleShare}
        text="Share with a friend"
      />
    </>
  );
};

export default RetreatOptionsMenu;
