import { gql } from '@apollo/client';
import { USER_SUBSCRIPTION_DATA_FRAGMENT } from './subscription';

export const USER_DATA_FRAGMENT = gql`
  fragment UserData on User {
    activity {
      mindful_days
      minutes
      total_sessions
    }
    already_redeemed_trial
    avatar {
      id
      url
      thumbnails {
        thumb_x1
        thumb_x2
      }
    }
    city
    content_status
    created_at
    daily_duration_type
    email
    first_name
    full_name
    id
    intro_pack_id
    is_eligible_for_open_access
    is_subscribed
    is_new
    joined_at
    last_name
    notifications {
      id
    }
    share_code
    show_open_access_expired_banner
    show_purchase_options
    user_subscription {
      ...UserSubscriptionData
    }
    token
    uuid
  }
  ${USER_SUBSCRIPTION_DATA_FRAGMENT}
`;

export const USER_PROGRESS_DATA_FRAGMENT = gql`
  fragment UserProgressData on UserProgress {
    course_type
    created_at
    duration_seconds
    finished_at
    id
    progress_hash
    progress_id
    progress_type
    title
    user_id
  }
`;
