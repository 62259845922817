import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import {
  openAccessVar,
  showTrialBannerVar,
  upgradeToAnnualVar,
} from 'graphql/reactive';
import useMe from 'graphql/hooks/useMe';
import getDeeplinkValue from 'lib/getDeeplinkValue';

const useConnect = () => {
  const {
    id,
    isElegibleForSharing,
    isEligibleForYearlyUpgradeWithDiscount,
    profile: {
      subscriptionIsEligibleForYearlyUpgradeWithDiscount,
      subscriptionExpiresAt,
      subscriptionStartTime,
      subscriptionSubtype,
    },
    showOpenAccessExpiredBanner,
  } = useMe();
  const { lastCheckedDates: openAccessLastCheckedDates } =
    useReactiveVar(openAccessVar);
  const { lastCheckedDates: upgradeToAnnualLastCheckedDates } =
    useReactiveVar(upgradeToAnnualVar);
  const showTrialBanner = useReactiveVar(showTrialBannerVar);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    'open-gift': openGift,
    return_to: returnTo,
    deep_link_value: deepLinkValue,
  } = parse(location.search);

  const deepLink =
    deepLinkValue && typeof deepLinkValue === 'string'
      ? getDeeplinkValue(deepLinkValue)
      : undefined;

  const openAccessLastCheckedDate = openAccessLastCheckedDates[id];
  const upgradeToAnnualLastCheckedDate = upgradeToAnnualLastCheckedDates[id];

  // Support old link with 'open-gift' param (used with lightrail).
  useEffect(() => {
    if (openGift) {
      navigate('/account/purchase-gift-card', { replace: true });
    }
  }, [navigate, openGift]);

  return {
    isElegibleForSharing,
    isEligibleForYearlyUpgradeWithDiscount,
    location,
    openAccessLastCheckedDate,
    returnTo: returnTo || deepLink,
    showOpenAccessExpiredBanner,
    showTrialBanner,
    subscriptionExpirationDate: subscriptionExpiresAt,
    subscriptionIsEligibleForYearlyUpgradeWithDiscount,
    subscriptionStartDate: subscriptionStartTime,
    subscriptionSubtype,
    upgradeToAnnualLastCheckedDate,
    userId: id,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
