import { capitalize } from 'lodash';

const convertPathnameToTitle = (pathname: string) => {
  const defaultTitle = pathname
    .split('/')
    .filter((v) => !!v)
    .map((v) => v.split('-').map(capitalize).join(' '))
    .reverse()
    .join(' | ');

  // if (defaultTitle.includes('Series | Theory')) {
  //   return defaultTitle.replace('Series | Theory', 'Theory Series');
  // }

  // if (defaultTitle.includes('Conversations | Theory')) {
  //   return defaultTitle.replace(
  //     'Conversations | Theory',
  //     'Theory Conversations',
  //   );
  // }

  // if (defaultTitle.includes('Questions Answers | Theory')) {
  //   return defaultTitle.replace(
  //     'Questions Answers | Theory',
  //     'Theory Questions & Answers',
  //   );
  // }

  // if (defaultTitle.includes('Retreats | Practice')) {
  //   return defaultTitle.replace('Retreats | Practice', 'Practice Retreats');
  // }

  return defaultTitle;
};

export default convertPathnameToTitle;
