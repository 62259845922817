import type { LiveAudioDataFragment } from 'graphql/generated';

export const normalizeLiveAudio = (liveAudio: LiveAudioDataFragment) => {
  const liveStartDate = (liveAudio.live_start_date || '') as string;
  const liveEndDate = (liveAudio.live_end_date || '') as string;
  const startDate = (liveAudio.start_date || '') as string;

  return {
    active: liveAudio.active ?? false,
    details: liveAudio.details || '',
    id: liveAudio.id,
    live: liveAudio.live ?? false,
    liveEndDate,
    liveStartDate,
    startDate,
    subtitle: liveAudio.subtitle,
    title: liveAudio.title,
    url: liveAudio.url,
  };
};

export type LiveAudio = ReturnType<typeof normalizeLiveAudio>;
