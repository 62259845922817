import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 0.375rem;
`;

export const StandardPrice = styled.p`
  color: ${({ theme }) => theme.colors.coreNeutralAbyss};
  font-size: 0.9774rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: -0.03em;
  line-height: 1.215;
  margin-bottom: 0.2188rem;
  margin-top: ${({ theme }) => theme.measures.x};
`;

export const StrikeThrough = styled.span`
  font-size: inherit;
  padding-right: 0.2rem;
  position: relative;

  &:before {
    border-top: 0.0506rem solid #ff0000;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 40%;
    transform: rotate(10deg);
  }
`;

export const CurrentPrice = styled.p`
  font-size: 1.2024rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: -0.03em;
  line-height: 1.2;
`;
