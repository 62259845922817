import styled from 'styled-components';
import { media } from 'styles/mixins';
import Panel from 'components/Player/Panel';

export const Container = styled(Panel)``;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: flex-start;
  margin-bottom: 8rem;
  margin-top: 2rem;
  width: 60%;

  ${media.tabletPortrait} {
    width: 75%;
  }

  ${media.phoneLandscape} {
    margin-bottom: 6rem;
    width: 90%;
  }

  ${media.phonePortrait} {
    width: 80%;
  }
`;

export const Info = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
`;

export const Progress = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  width: 100%;
`;
