import { FetchPolicy } from '@apollo/client';
import { apolloClient } from 'services/api';
import { normalizeUser } from 'models/User';
import { MeDocument } from '../generated';

const getProfile = async (options?: { fetchPolicy: FetchPolicy }) => {
  const { data: meData } = await apolloClient.query({
    query: MeDocument,
    errorPolicy: 'all',
    fetchPolicy: options?.fetchPolicy ?? 'cache-first',
  });

  return normalizeUser(meData?.me);
};

export default getProfile;
