import styled, { css } from 'styled-components';
import Dialog from '@mui/material/Dialog';
import { media } from 'styles/mixins';
import { Heading } from 'components/Typography';
import DefaultIcon from 'components/Icon';

export const Container = styled(Dialog)<{ $responsiveFullScreen?: boolean }>`
  .MuiPaper-root {
    align-items: stretch;
    background-color: ${({ theme }) => theme.colors.backgroundModal};
    border-radius: ${({ theme }) => theme.radius.md};
    color: ${({ theme }) => theme.colors.fontBase};
    display: flex;
    flex-direction: column;
    min-width: 21.25rem;
    padding: 1rem;
    z-index: 1000;
  }

  ${({ $responsiveFullScreen }) =>
    $responsiveFullScreen &&
    css`
      ${media.phoneLandscape} {
        .MuiPaper-root {
          border-radius: 0;
          bottom: 0;
          left: 0;
          margin: 0;
          max-height: none;
          max-width: none;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    `};
`;

export const Title = styled(Heading).attrs(() => ({
  $size: 'large',
  $weight: 'bold',
}))`
  font-size: 1.5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-align: center;
`;

export const ClearButton = styled.button<{ $position?: 'left' | 'right' }>`
  background: transparent;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: block;
  line-height: 1;
  position: absolute;
  top: 1.2rem;

  ${({ $position }) =>
    $position === 'right'
      ? css`
          right: 1.2rem;
        `
      : css`
          left: 1.2rem;
        `}
`;

export const Icon = styled(DefaultIcon)`
  display: block;
`;
