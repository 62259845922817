import styled from 'styled-components';
import { motion } from 'framer-motion';
import { media } from 'styles/mixins';
import MuiCircularProgress from '@mui/material/CircularProgress';
import MuiLinearProgress from '@mui/material/LinearProgress';
import DefaultPlayIcon from 'components/Icons/Play';
import DefaultReplayIcon from 'components/Icons/Replay';
import { DEFAULT_VIDEO_HEIGHT, DEFAULT_VIDEO_WIDTH } from './constants';

export const Container = styled.div<{ $isFinished: boolean }>`
  border-radius: 1.25rem;
  cursor: ${({ $isFinished }) => ($isFinished ? 'default' : 'pointer')};
  display: inline-block;
  height: ${DEFAULT_VIDEO_HEIGHT};
  overflow: hidden;
  position: relative;
  width: ${DEFAULT_VIDEO_WIDTH};
  z-index: 1;

  ${media.phoneLandscape} {
    border-radius: 0.85rem;
  }
`;

export const LoaderContainer = styled.div`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Loader = styled(MuiCircularProgress)``;

const Button = styled.button`
  align-items: center;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors.coreNeutralWhite};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.coreNeutralWhite};
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
  outline: none;
  text-decoration: none;
  transition: background-color 200ms ease;
  width: 60px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
    opacity: 0.9;
  }
`;

export const PlayButton = styled(Button)`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const PlayIcon = styled(DefaultPlayIcon).attrs(() => ({ size: 18 }))`
  margin-left: 0.31rem;
`;

export const Video = styled.video`
  background: ${({ theme }) => theme.colors.coreNeutralBlack};
  border-radius: inherit;
  display: block;
  height: 100%;
  object-fit: contain;
  width: 100%;

  &::-webkit-media-controls {
    display: none !important;
  }
`;

export const ProgressBar = styled(motion(MuiLinearProgress)).attrs(() => ({
  initial: { opacity: 1 },
  animate: { opacity: 1 },
  transition: { duration: 0.3 },
  variant: 'determinate',
}))`
  &.MuiLinearProgress-root {
    bottom: 0;
    height: 1rem;
    left: 0;
    position: absolute;
    right: 0;
  }

  &.MuiLinearProgress-colorPrimary {
    background-color: #d8d8d8;
    .MuiLinearProgress-barColorPrimary {
      background-color: ${({ theme }) => theme.colors.coreBlue700};
    }
  }

  ${media.phoneLandscape} {
    &.MuiLinearProgress-root {
      height: 0.6rem;
    }
  }
`;

export const EndScreen = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
}))`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const ReplayButton = styled(Button)``;

export const ReplayIcon = styled(DefaultReplayIcon).attrs(() => ({
  size: 24,
}))`
  display: block;
  transform: scaleX(-1);
`;

export const EndMessage = styled.div`
  font-size: 2.4rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 1;
  margin-top: 0.75rem;
  white-space: nowrap;
`;
