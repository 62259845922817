import { type TagDataFragment, TagType } from 'graphql/generated';

export const normalizeTag = (tag: TagDataFragment) => ({
  color: tag.color || '',
  id: tag.id || '',
  type: tag.type || TagType.standard,
  value: tag.value || '',
});

export type Tag = ReturnType<typeof normalizeTag>;
