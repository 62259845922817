import DefaultCheck from 'components/Icons/Check';
import colorWithAlpha from 'lib/color';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.fontBase};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.measures.m} 0.8rem`};
  background-color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralWhite
      : colorWithAlpha({ color: theme.colors.coreNeutralBlack, alpha: 0.3 })};
  border-radius: ${({ theme }) => theme.radius.sm};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: 0.945rem;
  transition: padding 0.2s;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.measures.m};
  }

  &:active {
    padding-left: ${({ theme }) => theme.measures.m};
    padding-right: ${({ theme }) => theme.measures.m};
  }
`;

export const Check = styled(DefaultCheck)`
  color: ${({ theme }) => theme.colors.fontEmphasized};
  width: 1rem;
`;
