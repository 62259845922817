import type { FC } from 'react';
import { Props } from './types';

const ShareNetworkLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M6 15a3 3 0 100-6 3 3 0 000 6zm10.5 6.75a3 3 0 100-6 3 3 0 000 6zm0-13.5a3 3 0 100-6 3 3 0 000 6zm-2.524-1.378l-5.454 3.506m0 3.245l5.454 3.505"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ShareNetworkLight;
