import { memo, useCallback, type FC } from 'react';
import { AppStore, Container, GooglePlay } from './styles';
import type { Props } from './types';

const StoreLink: FC<Props> = ({
  href,
  onClick,
  queryString,
  store,
  text,
  ...rest
}) => {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick({ link: href, queryString, store });
    }
  }, [href, onClick, queryString, store]);

  return (
    <Container href={href} onClick={handleClick} target="_blank" {...rest}>
      {text || (store === 'apple' ? <AppStore /> : <GooglePlay />)}
    </Container>
  );
};

export default memo(StoreLink);
