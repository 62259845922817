import type { FC } from 'react';
import { formatDurationShort } from 'lib/formatTime';
import PageTitle from 'components/PageTitle';
import SectionHeader from 'components/SectionHeader';
import Loader from './Loader';
import {
  Button,
  Container,
  DetailsTabs,
  DurationAndFav,
  FavIcon,
  Icon,
  Image,
  InfoCard,
  OptionsMenuButton,
  PlayIcon,
  Subtitle,
  Title,
} from './styles';
import useConnect from './connect';

const Conversation: FC = () => {
  const {
    artists,
    audioLength,
    handleLoadTrack,
    handleOpenOptionsMenu,
    imageUrl,
    isBookmark,
    isLoading,
    packSummary,
    packTypeName,
    subtitle,
    summary,
    teachersAndSpeakers,
    title,
  } = useConnect();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      {title && (
        <PageTitle title={`${title} | Conversations | ${packTypeName}`} />
      )}
      <SectionHeader showBackLink>
        <OptionsMenuButton
          aria-label="open conversation options"
          onClick={handleOpenOptionsMenu}
        >
          <Icon aria-hidden="true" name="threeDotsVerticalLight" size={18} />
        </OptionsMenuButton>
      </SectionHeader>
      <InfoCard>
        {imageUrl && <Image alt="" src={imageUrl} />}
        <Title as="h1">{title}</Title>
        <Subtitle as="h2">{subtitle}</Subtitle>
        <DurationAndFav>
          {audioLength ? formatDurationShort(audioLength) : ''}
          {isBookmark && <FavIcon name="bookmarkSingleActive" />}
        </DurationAndFav>
      </InfoCard>
      <Button onClick={handleLoadTrack}>
        <PlayIcon aria-hidden="true" name="playCircleLight" />
        Listen Now
      </Button>
      <DetailsTabs
        artists={artists}
        hideTeacherLinks
        summary={summary || packSummary}
        teachers={teachersAndSpeakers}
      />
    </Container>
  );
};

export default Conversation;
