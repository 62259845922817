import amplitude from 'amplitude-js';
import { captureMessage } from '@sentry/react';
import { ApiConfig, AppConfig, DevConfig } from 'config';
import { AnalyticsEvent } from '../types';

export const setUpAmplitude = () => {
  try {
    const client = amplitude.getInstance();
    client.init(ApiConfig.AMPLITUDE_KEY);
    client.setVersionName(AppConfig.version);
  } catch (error) {
    captureMessage(
      `Unable to setup Amplitude (ERROR: ${error?.message || 'unknown'})`,
    );
  }
};

export const trackAmplitudeEvent = (
  event: AnalyticsEvent,
  data?: Record<string, unknown>,
) => {
  try {
    amplitude
      .getInstance()
      .logEvent(event, data, (responseCode, responseBody) => {
        // ToDo: If 'data' is undefined, we should probably show a modal telling the user
        // ToDo: he has to turn off ad-blockers & other anti-tracking tools
        if (DevConfig.amplitudeLogging) {
          // eslint-disable-next-line no-console
          console.log(event, data, responseCode, responseBody);
        }
      });
  } catch (error) {
    captureMessage(
      `Unable to track Amplitude event ${event} (ERROR: ${
        error?.message || 'unknown'
      })`,
    );
  }
};

export const setAmplitudeUserProperties = (
  userProps: Record<string, string>,
) => {
  try {
    const client = amplitude.getInstance();
    client.setUserProperties(userProps);
  } catch (error) {
    captureMessage(
      `Unable to setup Amplitude user properties (ERROR: ${
        error?.message || 'unknown'
      })`,
    );
  }
};

export const registerAmplitudeUser = (userId: string) => {
  try {
    const client = amplitude.getInstance();
    client.setUserId(userId);
  } catch (error) {
    captureMessage(
      `Unable to register Amplitude user ID: ${userId} (ERROR: ${
        error?.message || 'unknown'
      })`,
    );
  }
};

export const unregisterAmplitudeUser = () => {
  try {
    const client = amplitude.getInstance();
    client.setUserId(null);
  } catch (error) {
    captureMessage(
      `Unable to unregister Amplitude user (ERROR: ${
        error?.message || 'unknown'
      })`,
    );
  }
};
