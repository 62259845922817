import type { FC } from 'react';
import OptionsMenuItem from '../OptionsMenuItem';
import { Props } from './types';
import useConnect from './connect';

const PackOptionsMenu: FC<Props> = ({ packId }) => {
  const {
    handleMarkAsUnplayed,
    handleShare,
    handleShowDetails,
    hasDetails,
    hasSubpacks,
  } = useConnect({ packId });

  return (
    <>
      {!hasSubpacks && (
        <OptionsMenuItem
          iconName="checkSquareLight"
          onClick={handleMarkAsUnplayed}
          text="Mark as Unplayed"
        />
      )}
      {hasDetails && (
        <OptionsMenuItem
          iconName="infoLight"
          onClick={handleShowDetails}
          text="Details"
        />
      )}
      <OptionsMenuItem
        iconName="shareNetworkLight"
        onClick={handleShare}
        text="Share"
      />
    </>
  );
};

export default PackOptionsMenu;
