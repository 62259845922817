import { isProduction } from 'config';
import { apolloClient } from 'services/api';
import { normalizeRetreat } from 'models/Retreat';
import type { QueryOptions } from 'types/utils';
import {
  GetRetreatDocument,
  GetRetreatQuery,
  GetRetreatQueryVariables,
} from '../generated';

const getRetreat = async (
  options: QueryOptions<GetRetreatQueryVariables, GetRetreatQuery> = {},
) => {
  if (isProduction) {
    return undefined;
  }

  const { data } = await apolloClient.query({
    query: GetRetreatDocument,
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    ...options,
  });

  return data?.retreat ? normalizeRetreat(data.retreat) : undefined;
};

export default getRetreat;
