import { memo, useCallback } from 'react';
import type { FC } from 'react';
import { Container } from './styles';
import { Props } from './types';

const MenuItem: FC<Props> = ({ active, id, onClick, text }) => {
  const handleClick = useCallback(() => {
    if (onClick) onClick(id);
  }, [onClick, id]);

  return <Container active={active} onClick={handleClick} text={text} />;
};

export default memo(MenuItem);
