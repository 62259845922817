import { useMemo } from 'react';
import type { QueryHookOptions } from '@apollo/client';
import type { Daily } from 'models/Daily';
import type { Course } from 'models/Course';
import { BookmarkType, type GetBookmarksQuery } from '../generated';
import useBookmarks from './useBookmarks';

function useBookmark(
  hash: string,
  type: BookmarkType.course,
  options: QueryHookOptions<GetBookmarksQuery>,
): {
  bookmark: Course;
  loading: boolean;
};

function useBookmark(
  hash: string,
  type: BookmarkType.daily,
  options: QueryHookOptions<GetBookmarksQuery>,
): {
  bookmark: Daily;
  loading: boolean;
};

function useBookmark(
  hash: string,
  type: BookmarkType,
  options: QueryHookOptions<GetBookmarksQuery> = {},
) {
  const { bookmarks, loading } = useBookmarks(options);

  const bookmark = useMemo(() => {
    if (type === BookmarkType.course) {
      return bookmarks.courses.find((course) => course.hash === hash);
    }

    if (type === BookmarkType.daily) {
      return bookmarks.dailies.find((daily) => daily.hash === hash);
    }
    return null;
  }, [bookmarks, hash, type]);

  return { bookmark, loading };
}

export default useBookmark;
export type UseBookmarks = ReturnType<typeof useBookmark>;
