import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { normalizeUser } from 'models/User';
import { RedeemCodeDocument } from '../generated';
import {
  GET_CONTENT,
  GET_DAILY,
  GET_FEATURE_CONTENT,
  GET_REMOTE_CONFIG,
} from '../queries';
import useAuth from './useAuth';

const useRedeemActions = () => {
  const { isAuthenticated } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [redeemCodeMutation, { loading }] = useMutation(RedeemCodeDocument, {
    refetchQueries: isAuthenticated
      ? [
          { query: GET_CONTENT },
          { query: GET_DAILY },
          { query: GET_FEATURE_CONTENT },
          { query: GET_REMOTE_CONFIG },
        ]
      : undefined,
  });

  const redeemCode = useCallback(
    async (code: string, email?: string, isPublic = false) => {
      const { data } = await redeemCodeMutation({ variables: { code, email } });

      const profile = data?.redeemCode
        ? normalizeUser(data.redeemCode)
        : undefined;

      if (profile && !email && !isPublic) {
        enqueueSnackbar('Your subscription has been updated', {
          variant: 'success',
        });
      }

      return profile;
    },
    [enqueueSnackbar, redeemCodeMutation],
  );

  return {
    loading,
    redeemCode,
  };
};

export default useRedeemActions;
export type UseRedeemActions = ReturnType<typeof useRedeemActions>;
