import { StripeFamilyMemberStatus } from 'graphql/generated';
import { type FC, memo, useCallback } from 'react';
import { Container, Email, Info, Button, Status } from './styles';
import type { Props } from './types';

const Member: FC<Props> = ({
  className,
  email,
  status,
  onDelete,
  onResend,
}) => {
  const handleClick = useCallback(() => {
    if (status === StripeFamilyMemberStatus.active) {
      onDelete(email);
    } else {
      onResend(email);
    }
  }, [email, onDelete, onResend, status]);

  return (
    <Container className={className}>
      <Info>
        <Email>{email}</Email>
        {status !== StripeFamilyMemberStatus.active && (
          <Status>{status}</Status>
        )}
      </Info>
      <Button onClick={handleClick}>
        <span>
          {status === StripeFamilyMemberStatus.active ? 'Remove' : 'Resend'}
        </span>
      </Button>
    </Container>
  );
};

export default memo(Member);
