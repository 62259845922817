import type { AvatarsQuery } from 'graphql/generated';

export const normalizeAvatar = (
  avatar: NonNullable<AvatarsQuery['avatars']>[number],
) => {
  return {
    alt: `Avatar ${avatar.id}`,
    id: avatar.id,
    url:
      avatar.thumbnails?.thumb_x1 ||
      avatar.thumbnails?.thumb_x2 ||
      avatar.url ||
      '',
  };
};

export type Avatar = ReturnType<typeof normalizeAvatar>;
