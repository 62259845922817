import { memo } from 'react';
import type { FC } from 'react';
import { Container, Content, Progress, Logo } from './styles';

const FullscreenLoader: FC = () => (
  <Container>
    <Content>
      <Logo />
      <Progress />
    </Content>
  </Container>
);

export default memo(FullscreenLoader);
