import concatenateWords from 'lib/concatenateWords';
import type { Person } from 'models/Person';

function createPersonsLine(persons: Person[], defaultValue: string = '') {
  if (!persons || persons.length === 0) {
    return defaultValue;
  }

  return `by ${concatenateWords(persons.map(({ name }) => name))}`;
}

export default createPersonsLine;
