import styled from 'styled-components';
import DefaultModal from 'components/Modal';
import DefaultButton from 'components/Button';
import { ZIndex } from 'styles/metrics';
import { media } from 'styles/mixins';

export const Modal = styled(DefaultModal)`
  z-index: ${ZIndex.ONBOARDING} !important;

  .MuiPaper-root {
    height: 95vh;
    max-height: 42rem;
    padding: 0;
    padding-top: 2.6rem;
    width: 32rem;
  }

  .MuiPaper-root {
    ${media.phoneLandscape} {
      height: 100%;
      max-height: none;
      padding: 0;
      padding-top: 6vh;
      width: 100%;
    }
  }

  .MuiPaper-root {
    @media (max-height: 450px) {
      border-radius: 0;
      bottom: 0;
      height: 100%;
      left: 0;
      margin: 0;
      max-height: none;
      max-width: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
  }
`;

export const Slider = styled.div`
  flex: 1 1 auto;

  > .swiper {
    height: 100%;
  }
`;

export const Button = styled(DefaultButton)`
  margin: ${({ theme }) => theme.measures.l};
  width: auto;
`;
