import type { FC } from 'react';
import OptionsMenuItem from '../OptionsMenuItem';
import { Title } from '../styles';
import type { Props } from './types';
import useConnect from './connect';

const CourseOptionsMenu: FC<Props> = ({ courseId, packId, type }) => {
  const {
    conversationUrl,
    handleMarkAsUnplayed,
    handleShare,
    handleShowDetails,
    handleToggleBookmark,
    handleViewInSeries,
    hasDetails,
    isBookmark,
    isConversation,
    isEphemeral,
    isIntroCourse,
    isMeditation,
    isShareable,
    isTheoryIntroCourse,
    isUnstarted,
    packUrl,
    pathname,
  } = useConnect({ courseId, packId, type });

  const nonPackRoute = /my-library|recently-played|search/.test(pathname);

  const showMyLibrary = !isEphemeral;
  const showMarkAsUnplayed = !isUnstarted;
  const showDetails =
    (!isConversation || type === 'coursePlayer' || nonPackRoute) &&
    (!isMeditation || !isIntroCourse) &&
    hasDetails;
  const showViewInSeries =
    !isEphemeral &&
    ((type === 'coursePlayer' && !!packId) ||
      isTheoryIntroCourse ||
      nonPackRoute);
  const showShare = !isEphemeral && type === 'course' && isShareable;

  return (
    <>
      {type === 'coursePlayer' && <Title as="h2">options</Title>}
      {showMyLibrary && (
        <OptionsMenuItem
          active={isBookmark}
          iconName={
            isBookmark ? 'bookmarkSingleActive' : 'bookmarkSingleInactive'
          }
          onClick={handleToggleBookmark}
          text={isBookmark ? 'Remove from My Library' : 'Add to My Library'}
        />
      )}
      {showMarkAsUnplayed && (
        <OptionsMenuItem
          iconName="checkSquareLight"
          onClick={handleMarkAsUnplayed}
          text="Mark as Unplayed"
        />
      )}
      {showDetails && (
        <OptionsMenuItem
          iconName="infoLight"
          onClick={handleShowDetails}
          text="Details"
          to={isConversation ? conversationUrl : undefined}
        />
      )}
      {showViewInSeries && (
        <OptionsMenuItem
          iconName="stackLight"
          onClick={handleViewInSeries}
          text="View in Series"
          to={packUrl}
        />
      )}
      {showShare && (
        <OptionsMenuItem
          iconName="shareLight"
          onClick={handleShare}
          text="Share Content"
        />
      )}
    </>
  );
};

export default CourseOptionsMenu;
