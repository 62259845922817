import { useCallback, useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { AppConfig } from 'config';
import { trackEvent, trackMediaEvent } from 'services/analytics';
import { CourseType } from 'models/Course';
import createPersonsLine from 'lib/createPersonsLine';
import { SpeedControlLevel } from 'graphql/generated';
import {
  PlayerType,
  coursePlayerVar,
  playerTypeVar,
  resetPlayerTypeVar,
  updateCoursePlayerVar,
  resetOptionsMenuVar,
} from 'graphql/reactive';
import {
  getCourseAutoplayTrackList,
  updateFeaturedContent,
  updatePlayerSettings,
  updateSettings,
} from 'graphql/requests';
import useCourse from 'graphql/hooks/useCourse';
import useSettings from 'graphql/hooks/useSettings';
import useModalActions from 'graphql/hooks/useModalActions';
import useMeActions from 'graphql/hooks/useMeActions';
import useCourseActions from 'graphql/hooks/useCourseActions';

const useConnect = () => {
  const {
    autoplay,
    autoplayType,
    endPosition,
    endPositionPaused,
    hash,
    initialPosition,
    isMinimized,
    packId,
    queryString,
    source,
  } = useReactiveVar(coursePlayerVar);
  const { course, loading: isLoadingCourse } = useCourse(hash, packId);
  const {
    loading: isLoadingSettings,
    settings: { firstMediaStarted, recentlyPlayed },
  } = useSettings();
  const { openIntroCourseSectionCompletedModal, openShareLinkModal } =
    useModalActions();
  const { addProgressStart } = useMeActions();
  const { markAsListened } = useCourseActions();

  const completedRef = useRef<boolean>(false);

  const {
    audioUrl = '',
    backgroundAudioAvailable = true,
    courseType,
    displaySpeakers = [],
    id: courseId,
    imageThumbnailsThumbX1 = '',
    imageUrl = '',
    isBookmark = false,
    isIntroCourse = false,
    isLastCourse = false,
    packAutoPlay = false,
    packEndMessage,
    packId: coursePackId = '',
    packImageThumbnailsThumbX1 = '',
    packImageUrl = '',
    packTitle = '',
    packOverwriteCourseImage = false,
    shareable: isShareable = false,
    speedControlLevel = SpeedControlLevel.disabled,
    subtitle: courseSubtitle = '',
    title = '',
  } = course || {};
  const isMeditation = courseType === CourseType.MEDITATION;
  const triggerIntroEndSectionModal =
    isIntroCourse && isLastCourse && !!coursePackId && !!packEndMessage;
  const packAllowAutoPlay = !!packId && !isIntroCourse && packAutoPlay;
  const subtitle = createPersonsLine(displaySpeakers, courseSubtitle);

  const displayImageUrl = packOverwriteCourseImage ? packImageUrl : imageUrl;
  const displayImageThumbnailsThumbX1 = packOverwriteCourseImage
    ? packImageThumbnailsThumbX1
    : imageThumbnailsThumbX1;

  const startTime =
    initialPosition ||
    recentlyPlayed.find((rp) => rp.course_hash === hash)?.last_known_location ||
    0;
  const endTime =
    endPosition && (!initialPosition || endPosition > initialPosition)
      ? endPosition
      : undefined;

  useEffect(() => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title,
        artist: packTitle ? 'Series' : undefined,
        album: packTitle,
        artwork: [
          {
            src: displayImageThumbnailsThumbX1,
            sizes: '512x512',
            type: 'image/jpg',
          },
        ],
      });
    }
  }, [displayImageThumbnailsThumbX1, packTitle, title]);

  useEffect(() => {
    if (!firstMediaStarted && title !== 'START HERE') {
      trackEvent('First Media Start');
      updateSettings({ firstMediaStarted: true }, true);
    }
  }, [firstMediaStarted, title]);

  const handleClosePlayer = useCallback(() => {
    resetOptionsMenuVar();
    resetPlayerTypeVar();
  }, []);

  const handleShare = useCallback(() => {
    if (isShareable && courseId) {
      openShareLinkModal({
        id: courseId,
        title: 'Share this course',
        description:
          'Share this link with anyone you feel would benefit from this Waking Up course',
        link: `${AppConfig.dynamicBaseUrl}/course/${courseId}`,
      });
    }
  }, [courseId, isShareable, openShareLinkModal]);

  const handleToggleDisplayStatus = useCallback(() => {
    resetOptionsMenuVar();
    updateCoursePlayerVar({ isMinimized: !coursePlayerVar().isMinimized });
  }, []);

  const handleInit = useCallback(
    async ({ currentTime, duration }: HTMLAudioElement) => {
      const code = uuidv4();

      await updateSettings(
        {
          player: {
            code,
            courseId: hash,
            packId: coursePackId,
            queryString,
            source,
          },
        },
        true,
      );

      if (isMeditation) {
        await addProgressStart({
          courseHash: hash,
          mediaDuration: Math.ceil(duration),
        });
      }

      trackMediaEvent('Media Start', hash, {
        duration,
        position: currentTime,
      });
    },
    [addProgressStart, coursePackId, hash, isMeditation, queryString, source],
  );

  const handleEndTime = useCallback(
    ({ currentTime, duration }: HTMLAudioElement) => {
      if (endPositionPaused) {
        trackMediaEvent('Media Stop', hash, {
          duration,
          position: currentTime,
        });
      } else {
        resetPlayerTypeVar();
      }
    },
    [endPositionPaused, hash],
  );

  const handleComplete = useCallback(
    ({ currentTime, duration }: HTMLAudioElement) => {
      completedRef.current = true;

      const percentComplete = Math.floor(100 * (currentTime / duration));

      trackMediaEvent('Media Complete', hash, {
        duration,
        percentComplete,
        position: currentTime,
        quartileComplete: 4,
      });

      markAsListened(hash, coursePackId);
    },
    [coursePackId, hash, markAsListened],
  );

  const handleEnd = useCallback(async () => {
    updateFeaturedContent(hash);

    const autoplayTrackList = await getCourseAutoplayTrackList();

    if (autoplayTrackList) {
      const index = autoplayTrackList.indexOf(hash);

      if (index >= 0 && index < autoplayTrackList.length - 1) {
        if (!document.hidden) {
          playerTypeVar(null);
        }

        setTimeout(() => {
          updateCoursePlayerVar({
            endPosition: null,
            endPositionPaused: false,
            hash: autoplayTrackList[index + 1],
            initialPosition: 0,
            isMinimized: false,
          });
          playerTypeVar(PlayerType.COURSE);
        }, 750);
      } else {
        resetPlayerTypeVar();
      }
    } else {
      resetPlayerTypeVar();

      if (triggerIntroEndSectionModal) {
        setTimeout(
          () => openIntroCourseSectionCompletedModal({ packId: coursePackId }),
          800,
        );
      }
    }
  }, [
    coursePackId,
    hash,
    openIntroCourseSectionCompletedModal,
    triggerIntroEndSectionModal,
  ]);

  const handleClose = useCallback(
    async ({ currentTime, duration }: HTMLAudioElement) => {
      const percentComplete = Math.floor(100 * (currentTime / duration));
      const quartileComplete = Math.floor(percentComplete / 25) + 1;

      if (!completedRef.current) {
        trackMediaEvent('Media Close', hash, {
          duration,
          percentComplete,
          position: currentTime,
          quartileComplete,
        });
      }

      updatePlayerSettings(hash, currentTime);
    },
    [hash],
  );

  return {
    audioUrl,
    autoplay,
    autoplayType,
    backgroundAudioAvailable,
    endPositionPaused,
    endTime,
    handleClose,
    handleClosePlayer,
    handleComplete,
    handleEnd,
    handleEndTime,
    handleInit,
    handleShare,
    handleToggleDisplayStatus,
    hash,
    hasPack: !!coursePackId,
    hasSpeedControl:
      !isMeditation &&
      (speedControlLevel === SpeedControlLevel.enabled ||
        speedControlLevel === SpeedControlLevel.enabled_with_warning),
    imageThumbnailsThumbX1: displayImageThumbnailsThumbX1,
    imageUrl: displayImageUrl,
    isBookmark,
    isIntro: isIntroCourse,
    isLoadingData: isLoadingCourse || isLoadingSettings,
    isMinimized,
    isShareable,
    packAllowAutoPlay,
    packId: coursePackId,
    startTime,
    subtitle,
    title,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
