import type { FC } from 'react';
import { Props } from './types';

const NumberPlaybackSpeed18xLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M5.303 8h-1.59l-1.98 1.254v1.5l1.832-1.149h.047V16h1.691V8zm2.91 8.102a.95.95 0 00.938-.938.949.949 0 00-.937-.93.934.934 0 100 1.868zm5.39.007c1.806 0 3.103-.965 3.106-2.261-.003-1-.773-1.817-1.734-1.977v-.055a1.796 1.796 0 001.438-1.773c-.004-1.238-1.192-2.152-2.81-2.152-1.62 0-2.808.914-2.804 2.152a1.776 1.776 0 001.438 1.773v.055c-.977.16-1.739.977-1.735 1.977-.004 1.296 1.297 2.261 3.102 2.261zm0-1.242c-.804 0-1.343-.476-1.343-1.152 0-.692.567-1.192 1.344-1.192.773 0 1.344.5 1.344 1.192 0 .68-.543 1.152-1.344 1.152zm0-3.574c-.675 0-1.155-.441-1.155-1.074 0-.625.472-1.055 1.156-1.055.68 0 1.156.434 1.156 1.055 0 .633-.484 1.074-1.156 1.074zM19.739 10H18.02l1.718 3-1.781 3h1.719l1.164-2.074L22.023 16h1.7l-1.785-3 1.738-3h-1.707l-1.13 2.098L19.74 10z"
      fill="currentColor"
    />
  </svg>
);

export default NumberPlaybackSpeed18xLight;
