import { useRef, useEffect, useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import { isMacOs, isWindows } from 'react-device-detect';
import {
  audioPositionVar,
  setAudioDuration,
  setAudioIsLoading,
  setAudioIsPlaying,
  setAudioPosition,
  setVolume,
  volumeVar,
  type Volume,
} from 'graphql/reactive';
import { COMPLETE_MARGIN_SECONDS } from './constants';
import type { ConnectProps } from './types';
import { useAudio } from './hooks';

const useConnect = ({
  audioUrl,
  autoPlay,
  endTime,
  hasSpeedControl,
  onClose,
  onComplete,
  onEnd,
  onEndTime,
  onInit,
  onPause,
  onPlay,
  onVolumeChange,
  startTime,
}: ConnectProps) => {
  const {
    audioDuration,
    audioIsLoading,
    audioIsPlaying,
    audioPosition,
    audioSpeed,
  } = useAudio();
  const volume = useReactiveVar(volumeVar);

  const audioRef = useRef<HTMLVideoElement>(null);
  const audioUrlRef = useRef<string>(audioUrl);
  const markedAsCompletedRef = useRef<boolean>(false);
  const endTimeRef = useRef<boolean>(false);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio && audioUrl !== audioUrlRef.current) {
      audioUrlRef.current = audioUrl;
      const prevPosition = audioPositionVar();
      setAudioIsLoading(true);
      audio.pause();
      audio.src = audioUrl;
      audio.play().finally(() => {
        audio.currentTime = prevPosition;
        setAudioDuration(audio.duration);
        setAudioIsLoading(false);
      });
    }
  }, [audioUrl]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const { isMuted, level } = volumeVar();
      audio.volume = level / 100;
      audio.muted = isMuted;
    }
  }, []);

  useEffect(() => {
    if (hasSpeedControl) {
      const audio = audioRef.current;
      if (audio) {
        setTimeout(() => {
          audio.playbackRate = audioSpeed;
        }, 1);
      }
    }
  }, [audioSpeed, hasSpeedControl]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      return () => {
        if (onClose) onClose(audio);
      };
    }
  }, [onClose]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const handleCanPlay = () => {
        const { duration } = audio;
        if (startTime && startTime < duration) {
          audio.currentTime = startTime;
          markedAsCompletedRef.current =
            startTime > duration - COMPLETE_MARGIN_SECONDS;
        }
        setAudioDuration(duration);
        setAudioIsLoading(false);

        if (autoPlay) {
          audio
            .play()
            .then(() => setAudioIsPlaying(true))
            .catch(() => setAudioIsPlaying(false));
        }

        if (onInit) onInit(audio);
      };

      const handleTimeUpdate = () => {
        const { currentTime, duration } = audio;

        setAudioPosition(currentTime);

        if (endTime && !endTimeRef.current && currentTime >= endTime) {
          endTimeRef.current = true;

          audio.pause();
          setAudioIsPlaying(false);

          if (onEndTime) onEndTime(audio);
          return;
        }

        if (
          !markedAsCompletedRef.current &&
          currentTime >= duration - COMPLETE_MARGIN_SECONDS
        ) {
          markedAsCompletedRef.current = true;
          if (onComplete) onComplete(audio);
        }
      };

      const handleEnded = () => {
        if (onEnd) onEnd(audio);

        setAudioIsPlaying(false);
        setAudioPosition(0);
        audio.currentTime = 0;
        markedAsCompletedRef.current = false;
        endTimeRef.current = false;
      };

      audio.addEventListener('canplay', handleCanPlay, { once: true });
      audio.addEventListener('timeupdate', handleTimeUpdate);
      audio.addEventListener('ended', handleEnded);
      audio.load();
      return () => {
        audio.removeEventListener('canplay', handleCanPlay);
        audio.removeEventListener('timeupdate', handleTimeUpdate);
        audio.removeEventListener('ended', handleEnded);
      };
    }
  }, [autoPlay, endTime, onComplete, onEnd, onEndTime, onInit, startTime]);

  const handleTogglePlay = useCallback(async () => {
    const audio = audioRef.current;
    if (audio && !audio.ended) {
      if (audio.paused) {
        await audio.play();
        setAudioIsPlaying(true);

        if (onPlay) onPlay(audio);
      } else {
        audio.pause();
        setAudioIsPlaying(false);

        if (onPause) onPause(audio);
      }
    }
  }, [onPause, onPlay]);

  useEffect(() => {
    if (navigator?.mediaSession && (isMacOs || isWindows)) {
      navigator.mediaSession.setActionHandler('play', handleTogglePlay);
      navigator.mediaSession.setActionHandler('pause', handleTogglePlay);
      return () => {
        navigator.mediaSession.setActionHandler('play', null);
        navigator.mediaSession.setActionHandler('pause', null);
        navigator.mediaSession.playbackState = 'none';
        navigator.mediaSession.setPositionState();
      };
    }
  }, [handleTogglePlay]);

  const handleSetPosition = useCallback((newPosition: number) => {
    const audio = audioRef.current;
    if (audio && !audio.ended) {
      audio.currentTime = newPosition;
    }
  }, []);

  const handleIncreasePosition = useCallback((increase: number) => {
    const audio = audioRef.current;
    if (audio && audio.duration && !audio.ended) {
      const currentPosition = audio.currentTime || 0;
      const newPosition = currentPosition + increase;

      if (newPosition >= audio.duration) {
        audio.currentTime = audio.duration;
      } else if (newPosition <= 0) {
        audio.currentTime = 0;
      } else {
        audio.currentTime = newPosition;
      }
    }
  }, []);

  const handleReplay = useCallback(async () => {
    const audio = audioRef.current;
    if (audio && audio.ended) {
      setAudioPosition(0);
      audio.currentTime = 0;
      markedAsCompletedRef.current = false;
      endTimeRef.current = false;

      await audio.play();
      setAudioIsPlaying(true);
    }
  }, []);

  const handleSetVolume = useCallback(
    (newVolume: Volume) => {
      const audio = audioRef.current;
      if (audio) {
        audio.volume = newVolume.level / 100;
        audio.muted = newVolume.isMuted;

        setVolume(newVolume);

        if (onVolumeChange) onVolumeChange(audio);
      }
    },
    [onVolumeChange],
  );

  return {
    audioRef,
    duration: audioDuration,
    handleIncreasePosition,
    handleReplay,
    handleSetPosition,
    handleSetVolume,
    handleTogglePlay,
    isLoading: audioIsLoading,
    isPlaying: audioIsPlaying,
    position: audioPosition,
    volume,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
