import { Heading } from 'components/Typography';
import styled from 'styled-components';

export const Container = styled.div<{ $inline?: boolean }>`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAbyss
      : theme.colors.coreNeutralAurora};
  display: flex;
  flex-flow: ${({ $inline }) => ($inline ? 'row' : 'column')} nowrap;
  align-items: center;
  justify-content: flex-start;
  text-align: ${({ $inline }) => ($inline ? 'left' : 'center')};
`;

export const AvatarContainer = styled.div`
  height: 8rem;
  width: 8rem;
`;

export const Avatar = styled.img`
  height: 100%;
  width: 100%;
`;

export const Info = styled.div<{ $inline?: boolean }>`
  margin-left: ${({ $inline }) => ($inline ? '1.25rem' : '0')};
  margin-top: ${({ $inline, theme }) => ($inline ? '0' : theme.measures.s)};
`;

export const FullName = styled(Heading).attrs(() => ({
  $weight: 'bold',
}))`
  margin-bottom: ${({ theme }) => theme.measures.xs};
`;

export const MemberSince = styled.div`
  font-size: 0.875rem;
  line-height: 1;
`;

export const City = styled.div`
  font-size: 0.75rem;
  line-height: 1;
  margin-top: ${({ theme }) => theme.measures.xxs};
`;
