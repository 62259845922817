import { useCallback } from 'react';
import { ModalType } from 'graphql/reactive';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import useResetActions from 'graphql/hooks/useResetActions';

const useConnect = () => {
  const { isOpen } = useModal(ModalType.RESET_CONTENT_MENU);
  const { closeModal, openDialogModal } = useModalActions();
  const { resetAllContent, resetIntroCourses } = useResetActions();

  const handleResetIntroCourse = useCallback(() => {
    openDialogModal({
      confirmButtonId: 'settings-reset-intro-course-confirm',
      description:
        'This will restart the Introductory Course. Daily Meditations will be locked until you complete the restarted Introductory Course. Are you sure you want to continue?',
      onCancel: closeModal,
      onConfirm: () => {
        closeModal();
        resetIntroCourses();
      },
      title: 'Reset Introductory Course',
    });
  }, [closeModal, openDialogModal, resetIntroCourses]);

  const handleResetAllContent = useCallback(() => {
    openDialogModal({
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      description: 'Are you sure you want to continue?',
      onCancel: closeModal,
      onConfirm: () => {
        closeModal();
        resetAllContent();
      },
      title: 'Mark All Content as unplayed',
    });
  }, [closeModal, openDialogModal, resetAllContent]);

  return {
    closeModal,
    handleResetAllContent,
    handleResetIntroCourse,
    isOpen,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
