/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_AVATARS = gql`
  query Avatars {
    avatars {
      id
      url
      thumbnails {
        thumb_x1
        thumb_x2
      }
    }
  }
`;
