import { makeVar } from '@apollo/client';
import { DailyMeditationDuration } from 'models/DailyMeditationDuration';
import { PlayerAutoplayType, PlayerSource } from 'models/Player';

export type DailyPlayer = {
  autoplayType: PlayerAutoplayType | null;
  hash?: string;
  duration: DailyMeditationDuration;
  isMinimized: boolean;
  queryString?: string;
  sharedHash?: string;
  source: PlayerSource;
};

export const emptyDailyPlayer: DailyPlayer = {
  autoplayType: null,
  duration: DailyMeditationDuration.MIN_10,
  isMinimized: false,
  source: PlayerSource.PACK,
};

export const dailyPlayerVar = makeVar<DailyPlayer>(emptyDailyPlayer);

export const resetDailyPlayerVar = () => {
  dailyPlayerVar(emptyDailyPlayer);
};

export const setDailyPlayerVar = (data: Partial<DailyPlayer>) => {
  dailyPlayerVar({ ...emptyDailyPlayer, ...data });
};

export const updateDailyPlayerVar = (data: Partial<DailyPlayer>) => {
  dailyPlayerVar({ ...dailyPlayerVar(), ...data });
};
