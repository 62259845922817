import { useCallback, useMemo, useRef } from 'react';
import queryStringLib, { parse } from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import type { FormikHelpers, FormikProps } from 'formik';
import { ApiConfig } from 'config';
import { loginStatusVar } from 'graphql/reactive';
import { trackEvent } from 'services/analytics';
import { createAppsFlyerLink } from 'services/analytics/appsflyer';
import { trackGAEvent } from 'services/analytics/ga';
import type { ConnectProps, FormFields } from './types';

const useConnect = ({ initialEmail, queryString = '' }: ConnectProps) => {
  const loginStatus = useReactiveVar(loginStatusVar);
  const navigate = useNavigate();
  const { search } = useLocation();

  const { af_adset, af_c_id, c, pid } = parse(search);

  const formikRef = useRef<FormikProps<FormFields>>(null);

  const { android, ios } = createAppsFlyerLink({
    adSet: af_adset as string,
    cId: af_c_id as string,
    campaign: c as string,
    mediaSource: pid as string,
  });

  const initialValues: FormFields = useMemo(
    () => ({ email: initialEmail || '' }),
    [initialEmail],
  );

  const handleSubmit = useCallback(
    async (
      { email }: FormFields,
      { setFieldError }: FormikHelpers<FormFields>,
    ) => {
      const data = {
        email,
        query_string: queryString,
        source: 'Sign Up',
      };
      const queryParams = parse(queryString);

      trackEvent('Landing Page Email Submit', {
        ...data,
        ...queryParams,
      });

      const res = await fetch(`${ApiConfig.API}/marketing/send-welcome-email`, {
        body: JSON.stringify({
          ...data,
          androidUrl: android,
          attribution: queryParams,
          iosUrl: ios,
        }),
        headers: {
          Accept: 'application/json',
          'app-client': 'web',
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });

      const { error, message } = await res.json();

      if (!res.ok && message) {
        setFieldError('submitError', message);
        return;
      }

      if (error) {
        setFieldError('submitError', error);
        return;
      }

      trackGAEvent('event', 'lp_email_submit', { category: 'signup' });

      trackEvent('Landing Page Email Sent', {
        ...data,
        ...queryParams,
      });

      const parsedQueryString = {
        ...queryStringLib.parse(queryString),
        email: encodeURIComponent(email),
      };

      navigate(
        `/magic-link-welcome?${queryStringLib.stringify(parsedQueryString)}`,
      );
    },
    [android, ios, navigate, queryString],
  );

  return {
    formikRef,
    handleSubmit,
    initialValues,
    loginStatus,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
