import { memo, useCallback } from 'react';
import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { trackEvent } from 'services/analytics';
import formatRetreatDate from 'lib/formatRetreatDate';
import type { Panel } from 'components/Panels/types';
import Buttons from './Buttons';
import {
  Attending,
  Card,
  Container,
  Image,
  Overline,
  Panels,
  Subtitle,
  Title,
} from './styles';
import type { Props, RouteState } from './types';

const Retreat: FC<Props> = (retreat) => {
  const location = useLocation();
  const state = location.state as RouteState;
  const screen = state?.screen || 'unknown';

  const {
    about,
    endDate,
    faq,
    hash,
    imageAlt,
    imageUrl,
    startDate,
    subtitle,
    title,
    usersAttending,
  } = retreat;

  const formattedDate = formatRetreatDate(startDate, endDate);

  const panels = [] as Panel[];
  if (about) panels.push({ info: about, title: 'About the Retreat' });
  if (faq) panels.push({ info: faq, title: 'FAQ' });

  const handleTabChange = useCallback(
    (index: number) => {
      trackEvent('Event Information', {
        event_hash: hash,
        event_type: 'retreat',
        name: title,
        tab: index === 1 ? 'faq' : 'about_retreat',
        screen: 'pause_landing_screen',
      });
    },
    [hash, title],
  );

  return (
    <Container>
      <Card>
        <Image alt={imageAlt} src={imageUrl} />
        <Overline>{formattedDate}</Overline>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Card>
      <Buttons {...retreat} screen={screen} />
      {usersAttending >= 500 && (
        <Attending>
          {new Intl.NumberFormat('en-US').format(usersAttending)} are attending
        </Attending>
      )}
      <Panels data={panels} onTabChange={handleTabChange} />
    </Container>
  );
};

export default memo(Retreat);
