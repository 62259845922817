import { memo, type FC } from 'react';
import kebabCase from 'lodash/kebabCase';
import CourseList from 'components/CourseList';
import Pack from './Pack';
import Tab from './Tab';
import { Html } from '../styles';
import { Container, Image, Name, NavTabList, Packs } from './styles';
import useConnect from './connect';
import { type Props, TabType } from './types';

const PersonDetails: FC<Props> = ({ id }) => {
  const {
    bio,
    filteredConversations,
    filteredCourses,
    filteredPacks,
    handleClick,
    handleNavigateToPack,
    handleOpenOptionsMenu,
    handlePlayCourse,
    imageUrl,
    isLoading,
    name,
    selectedTab,
    types,
  } = useConnect({ id });

  return (
    <Container>
      {imageUrl && <Image alt="" src={imageUrl} />}
      <Name>{name}</Name>
      <Html dangerouslySetInnerHTML={{ __html: bio }} />
      {selectedTab && (
        <NavTabList>
          {types.map(([type, v]) => (
            <Tab
              key={type}
              onClick={handleClick}
              selectedTab={selectedTab}
              tabName={`${type}(${v})`}
              tabValue={type}
            />
          ))}
        </NavTabList>
      )}
      {filteredPacks.length > 0 && selectedTab === TabType.SERIES && (
        <Packs
          aria-labelledby={`${kebabCase(selectedTab)}-tab`}
          id={`${kebabCase(selectedTab)}-panel`}
          role="tabpanel"
        >
          {filteredPacks.map((pack) => (
            <Pack
              key={pack.id}
              hash={pack.hash}
              imageUrl={
                pack?.imageThumbnailsThumbX1 ||
                pack?.imageThumbnailsThumbX2 ||
                pack?.imageUrl
              }
              subtitle={
                pack.displayContentCount && pack.nSubpacks
                  ? `${pack.nSubpacks} Series`
                  : pack.subtitle
              }
              title={pack.title}
              onClick={handleNavigateToPack}
            />
          ))}
        </Packs>
      )}
      {filteredConversations.length > 0 &&
        selectedTab === TabType.CONVERSATIONS && (
          <CourseList
            ariaLabelledby={`${kebabCase(selectedTab)}-tab`}
            courses={filteredConversations}
            displayCourseImages
            hideTopBorder
            id={`${kebabCase(selectedTab)}-panel`}
            isLoading={isLoading}
            onOpenOptionsMenu={handleOpenOptionsMenu}
            role="tabpanel"
            setTrack={handlePlayCourse}
          />
        )}
      {filteredCourses.length > 0 && selectedTab === TabType.SESSIONS && (
        <CourseList
          ariaLabelledby={`${kebabCase(selectedTab)}-tab`}
          courses={filteredCourses}
          displayCourseImages
          hideTopBorder
          id={`${kebabCase(selectedTab)}-panel`}
          isLoading={isLoading}
          onOpenOptionsMenu={handleOpenOptionsMenu}
          role="tabpanel"
          setTrack={handlePlayCourse}
        />
      )}
    </Container>
  );
};

export default memo(PersonDetails);
