import { memo } from 'react';
import type { FC } from 'react';
import { Container, Placeholder } from './styles';
import type { Props } from './types';

const TextPlaceholder: FC<Props> = ({ className, typography }) => (
  <Container className={className} $typography={typography}>
    <Placeholder $typography={typography} />
  </Container>
);

export default memo(TextPlaceholder);
