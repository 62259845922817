import { useCallback } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import { parse } from 'query-string';
import { isStripeCheckoutSessionEnabled } from 'services/storage';
import { trackEvent } from 'services/analytics';
import { SubscriptionPeriod } from 'models/SubscriptionPlan';
import { CheckoutSessionType, SubscriptionPlanType } from 'graphql/generated';
import formatCents from 'lib/formatCents';
import useCheckoutSessionSuccess from 'lib/useCheckoutSessionSuccess';
import useMe from 'graphql/hooks/useMe';
import useModalActions from 'graphql/hooks/useModalActions';
import useSubscriptionActions from 'graphql/hooks/useSubscriptionActions';
import useSubscriptionPlans from 'graphql/hooks/useSubscriptionPlans';

const SCREEN = 'web_checkout_logged_in';

const useConnect = () => {
  const { profile } = useMe();
  const { email } = profile;
  const { company: code } = useParams<{ company?: string }>();
  const { search, state } = useCheckoutSessionSuccess({ screen: SCREEN });
  const { ea, p, p_id, shared_by } = parse(search);
  const expiresAt = ea && typeof ea === 'string' ? Number(ea) : undefined;
  const period = p && typeof p === 'string' ? p : undefined;
  const priceId = p_id && typeof p_id === 'string' ? p_id : undefined;
  const sharedBy =
    shared_by && typeof shared_by === 'string' ? shared_by : undefined;
  const isLifetime =
    period === SubscriptionPeriod.LIFETIME ||
    period === SubscriptionPeriod.MENU_LIFETIME;
  const isExpiredLifetimePromotion =
    isLifetime && !!priceId && (!expiresAt || new Date().getTime() > expiresAt);
  const { loading: isLoadingPlans, plans } = useSubscriptionPlans(
    { variables: { code } },
    {
      variables: { price_id: isExpiredLifetimePromotion ? undefined : priceId },
    },
  );
  const {
    cancel,
    createCheckoutSession,
    isCancelling,
    isReactivating,
    isUpdating,
    reactivate,
    update,
  } = useSubscriptionActions();
  const {
    closeModal,
    openDialogModal,
    openStripePaymentModal,
    openUpgradeToAnnualModal,
  } = useModalActions();

  const isMobileWebview = !!useMatch({
    path: '/mobile/subscription',
  });

  const hasMonthlySubscription =
    profile.subscriptionSource === 'stripe' &&
    profile.subscriptionSubtype === 'month';
  const hasYearlySubscription =
    profile.subscriptionSource === 'stripe' &&
    profile.subscriptionSubtype === 'year';
  const hasInvalidCode =
    !!code &&
    plans.length > 0 &&
    plans.every((plan) => plan.amount === plan.originalAmount);
  const isEligibleForYearlyUpgradeWithDiscount =
    profile.subscriptionIsEligibleForYearlyUpgradeWithDiscount;

  const handleOpenStripePayment = useCallback(
    async (
      planAmount: number,
      planId: string,
      planInterval: string,
      planSku: string,
      planType: SubscriptionPlanType,
    ) => {
      const isMonthlyToYearlyUpgrade =
        hasMonthlySubscription &&
        planInterval === 'year' &&
        planType === 'standard';
      const isFamilyUpgrade =
        (hasYearlySubscription || hasMonthlySubscription) &&
        planType === 'family';

      if (isMonthlyToYearlyUpgrade || isFamilyUpgrade) {
        if (
          isMonthlyToYearlyUpgrade &&
          isEligibleForYearlyUpgradeWithDiscount
        ) {
          openUpgradeToAnnualModal({ planId });
        } else {
          const formattedPlanAmount = formatCents(planAmount);
          const title =
            planType === 'family'
              ? `Family Plan for US${formattedPlanAmount}/${planInterval}`
              : `Switch to Yearly Plan (US${formattedPlanAmount}/year)`;

          openDialogModal({
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            description: 'Are you sure you want to continue?',
            onCancel: closeModal,
            onConfirm: async () => {
              closeModal();
              await update({ planId });
            },
            title,
          });
        }

        return;
      }

      trackEvent('Purchase Initiate Checkout', {
        ...state,
        screen: SCREEN,
        subscription_option: planSku,
      });

      // Flag for Stripe Checkout testing.
      if (isStripeCheckoutSessionEnabled()) {
        const stripeCheckoutURL = await createCheckoutSession({
          email,
          isPublic: false,
          priceId: planId,
          type:
            planInterval === 'lifetime'
              ? CheckoutSessionType.lifetime
              : CheckoutSessionType.subscription,
        });

        window.open(stripeCheckoutURL, '_self');
        return;
      }

      openStripePaymentModal({
        email,
        isPublic: false,
        planAmount,
        planId,
        planInterval,
        planType,
        promoCode: code,
        priceId: planInterval === 'lifetime' ? priceId : undefined,
        screen: 'my_account',
        sharedBy,
      });
    },
    [
      closeModal,
      code,
      createCheckoutSession,
      email,
      hasMonthlySubscription,
      hasYearlySubscription,
      isEligibleForYearlyUpgradeWithDiscount,
      openDialogModal,
      openStripePaymentModal,
      openUpgradeToAnnualModal,
      priceId,
      sharedBy,
      state,
      update,
    ],
  );

  return {
    cancel,
    handleOpenStripePayment,
    hasInvalidCode,
    hasMonthlySubscription,
    hasYearlySubscription,
    isExpiredLifetimePromotion,
    isLoadingPlans,
    isManagingSubscription: isCancelling || isReactivating,
    isUpdatingSubscription: isUpdating,
    isMobileWebview,
    plans,
    reactivate,
    user: profile,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
