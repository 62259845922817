import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { getCoursePermission, normalizeCourse } from 'models/Course';
import { GetCourseDocument } from '../generated';
import useMe from './useMe';
import useRetreatCourse from './useRetreatCourse';
import usePackCourse from './usePackCourse';

const useCourse = (courseKey?: string, packKey?: string) => {
  const { isSubscribed } = useMe();
  const { course: retreatCourse, loading: isLoadingRetreatCourse } =
    useRetreatCourse(courseKey, packKey);
  const { course: packCourse, loading: isLoadingPackCourse } = usePackCourse(
    courseKey,
    packKey,
  );
  const { data: courseData, loading: isLoadingCourseData } = useQuery(
    GetCourseDocument,
    {
      fetchPolicy: 'cache-first',
      skip:
        !courseKey ||
        isLoadingRetreatCourse ||
        isLoadingPackCourse ||
        !!retreatCourse ||
        !!packCourse,
      variables: courseKey ? { code: courseKey } : undefined,
    },
  );

  const course = useMemo(() => {
    if (!courseKey) {
      return undefined;
    }

    if (retreatCourse) {
      return retreatCourse;
    }

    if (packCourse) {
      return packCourse;
    }

    if (!courseData?.course) {
      return undefined;
    }

    const normalizedCourse = normalizeCourse(courseData.course);
    normalizedCourse.permission = getCoursePermission(
      isSubscribed,
      normalizedCourse.isFree,
    );

    return normalizedCourse;
  }, [courseData, courseKey, isSubscribed, packCourse, retreatCourse]);

  return {
    course,
    loading: isLoadingPackCourse || isLoadingCourseData,
  };
};

export default useCourse;
export type UseCourse = ReturnType<typeof useCourse>;
