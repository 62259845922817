import type { FC } from 'react';
import { Container, Icon, Image, Content, Target } from './styles';
import type { Props } from './types';

const HeadPickerLink: FC<Props> = ({
  avatarId,
  avatarAlt,
  avatarUrl,
  city,
  className,
  email,
  firstName,
  isLoading,
  lastName,
}) => (
  <Container
    $isLoading={isLoading}
    aria-label="Go to header picker"
    className={className}
    state={{
      avatarAlt,
      avatarId,
      avatarUrl,
      city,
      email,
      firstName,
      lastName,
    }}
    to="/account/edit/pick-a-head"
  >
    <Content>{avatarUrl && <Image alt={avatarAlt} src={avatarUrl} />}</Content>
    <Target $hidden={isLoading}>
      <Icon aria-label="Edit" name="pencilLight" size={20} />
    </Target>
  </Container>
);

export default HeadPickerLink;
