import type { FC } from 'react';
import { ButtonPlay, Container } from './styles';
import { Props } from '../types';
import useLogic from '../logic';

const ControlButtonsMinimized: FC<Props> = ({
  className,
  isLoading = false,
  isPlaying = false,
  onIncreasePosition,
  onPlayChange,
}) => {
  const { handlePlayChange } = useLogic({ onIncreasePosition, onPlayChange });

  return (
    <Container className={className}>
      <ButtonPlay
        $isLoading={isLoading}
        aria-label={isPlaying ? 'Pause' : 'Play'}
        isPlaying={isPlaying}
        id="wu-player-play-button"
        onClick={handlePlayChange}
        reverse
      />
    </Container>
  );
};

export default ControlButtonsMinimized;
