import {
  initialize,
  openSession,
  automaticallyShowInAppMessages,
  requestPushPermission,
  getUser,
  changeUser,
  logCustomEvent,
} from '@braze/web-sdk';
import { captureMessage } from '@sentry/react';
import { ApiConfig, DevConfig } from 'config';
import { AnalyticsEvent } from '../types';

export const setUpBraze = () => {
  try {
    initialize(ApiConfig.BRAZE_KEY, {
      allowUserSuppliedJavascript: true,
      baseUrl: 'https://sdk.iad-06.braze.com',
      enableLogging: DevConfig.brazeLogging,
    });
    automaticallyShowInAppMessages();
    openSession();

    if (DevConfig.brazeEnablePushNotifications) {
      requestPushPermission();
    }
  } catch (error) {
    captureMessage(
      `Unable to setup Braze (ERROR: ${error?.message || 'unknown'})`,
    );
  }
};

export const setBrazeUserProperties = (userProps: Record<string, string>) => {
  try {
    const brazeUser = getUser();
    if (brazeUser) {
      Object.keys(userProps).forEach((k) => {
        brazeUser.setCustomUserAttribute(k, userProps[k]);
      });
    }
  } catch (error) {
    captureMessage(
      `Unable to set Braze user properties (ERROR: ${
        error?.message || 'unknown'
      })`,
    );
  }
};

export const registerBrazeUser = (userId: string) => {
  try {
    changeUser(userId);
  } catch (error) {
    captureMessage(
      `Unable to register Braze user ID: ${userId} (ERROR: ${
        error?.message || 'unknown'
      })`,
    );
  }
};

export const trackBrazeEvent = (
  event: AnalyticsEvent,
  data?: Record<string, unknown>,
) => {
  try {
    logCustomEvent(event, data);

    if (
      ['Media Start', 'Media Close', 'Media Complete'].includes(event) &&
      data
    ) {
      const action = event.split(' ')[1];
      logCustomEvent(`Media Content ${action} ${data.name}`);
      logCustomEvent(`Media Content ${action} ${data.hash}`);
    }
  } catch (error) {
    captureMessage(
      `Unable to track Braze event ${event} (ERROR: ${
        error?.message || 'unknown'
      })`,
    );
  }
};
