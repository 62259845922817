import styled, { css } from 'styled-components';
import { media } from 'styles/mixins';
import DefaultCardPrimary from 'components/CardPrimary';
import DefaultIcon from 'components/Icon';
import DefaultSectionHeader from 'components/SectionHeader';
import DefaultNavTabList from 'components/NavTabList';

export const Container = styled.div``;

export const SectionHeader = styled(DefaultSectionHeader)<{
  $showTabs: boolean;
  showBackLink: boolean;
}>`
  margin-bottom: ${({ $showTabs }) => ($showTabs ? 'unset' : '0')};
  margin-top: -1rem;
  padding-bottom: 0.35rem;

  ${({ showBackLink }) =>
    !showBackLink &&
    css`
      background-color: ${({ theme }) => theme.colors.backgroundBase};
      position: sticky;
      top: 0;
      z-index: 1;

      ${media.tabletPortrait} {
        margin-left: -1.4rem;
        margin-right: -1.4rem;
        padding-left: 1.4rem;
        padding-right: 1.4rem;
        top: 4rem;
      }

      ${media.phoneLandscape} {
        margin-left: -1rem;
        margin-right: -1rem;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    `}
`;

export const SectionDescription = styled.h3`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutral500
      : theme.colors.coreNeutral300};
  font-size: 0.875rem;
  letter-spacing: 0.01em;
  line-height: 1.429;
  margin-bottom: 0.95rem;
`;

export const Icon = styled(DefaultIcon).attrs(({ theme }) => ({
  color:
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralBlack
      : theme.colors.coreNeutralWhite,
}))``;

export const NavTabList = styled(DefaultNavTabList)`
  background-color: ${({ theme }) => theme.colors.backgroundBase};
  margin-bottom: ${({ theme }) => theme.measures.s};
  position: sticky;
  top: 3rem;
  z-index: 1;

  ${media.tabletPortrait} {
    margin-left: -1.4rem;
    margin-right: -1.4rem;
    min-height: 2.8rem;
    top: 7.2rem;

    & nav {
      left: 1.4rem;
      right: 0;
    }
  }

  ${media.phoneLandscape} {
    margin-left: -1rem;
    margin-right: 0;

    & nav {
      left: 1rem;
      right: 0;
    }
  }
`;

export const CardPrimary = styled(DefaultCardPrimary)`
  margin-top: ${({ theme }) => theme.measures.s};
  margin-bottom: ${({ theme }) => theme.measures.x};
  margin-right: auto;
  margin-left: auto;
`;

export const Filters = styled.div`
  margin-bottom: ${({ theme }) => theme.measures.x};
  justify-content: center;
  display: flex;
`;

export const OptionsMenuButton = styled.button`
  align-items: center;
  background: transparent;
  border-radius: 1.5rem;
  border: none;
  box-sizing: content-box;
  color: ${({ theme }) => theme.colors.coreNeutralBlack};
  cursor: pointer;
  display: flex;
  height: 1.125rem;
  justify-content: center;
  line-height: 1;
  outline-offset: 0.2rem;
  padding: 0 0.5rem;
  position: relative;
  user-select: none;
`;
