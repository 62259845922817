import { useMemo } from 'react';
import { useQuery, type QueryHookOptions } from '@apollo/client';
import { normalizeWidget, type ValidWidgetFragments } from 'models/Widget';
import {
  GetHomeDocument,
  type GetHomeQuery,
  type GetHomeQueryVariables,
} from '../generated';

const useHome = (
  options: QueryHookOptions<GetHomeQuery, GetHomeQueryVariables> = {},
) => {
  const { data, loading } = useQuery(GetHomeDocument, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    ...options,
  });

  const home = useMemo(
    () =>
      data?.home
        ? data.home
            .filter((widget): widget is ValidWidgetFragments =>
              [
                'WidgetUpNext',
                'WidgetCTA',
                'WidgetSpotlight',
                'WidgetContinueListening',
                'WidgetCarousel',
                'WidgetImage',
              ].includes(widget.__typename),
            )
            .map((w, i) => ({ ...normalizeWidget(w), position: i }))
        : [],
    [data],
  );

  return {
    home,
    loading: !data && loading,
  };
};

export default useHome;
export type UseHome = ReturnType<typeof useHome>;
