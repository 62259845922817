import { useMemo } from 'react';
import { useQuery, type QueryHookOptions } from '@apollo/client';
import { normalizeDaily } from 'models/Daily';
import {
  GetSharedDailyDocument,
  type GetSharedDailyQuery,
  type GetSharedDailyQueryVariables,
} from '../generated';

const useSharedDaily = (
  options: QueryHookOptions<
    GetSharedDailyQuery,
    GetSharedDailyQueryVariables
  > = {},
) => {
  const { data, loading } = useQuery(GetSharedDailyDocument, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    ...options,
  });

  const sharedDaily = useMemo(
    () => (data?.sharedDaily ? normalizeDaily(data.sharedDaily) : undefined),
    [data],
  );

  return {
    sharedDaily,
    loading: !data && loading,
  };
};

export default useSharedDaily;
export type UseSharedDaily = ReturnType<typeof useSharedDaily>;
