import { memo } from 'react';
import type { FC } from 'react';
import { Container, Label, Slider, Volume, VolumeIcon } from './styles';
import { Props } from './types';
import useLogic from './logic';

const VolumeControls: FC<Props> = ({ className, onSetVolume, volume }) => {
  const {
    displayedVolume,
    handleChangeVolume,
    handleSliderClick,
    handleToggleMute,
    isTabletLandscape,
  } = useLogic({ onSetVolume, volume });

  if (isTabletLandscape) {
    return null;
  }

  return (
    <Container className={className}>
      <Label id="player-volume">Audio volume:</Label>
      <Volume aria-label="unmute/mute audio" onClick={handleToggleMute}>
        <VolumeIcon aria-hidden="true" volume={displayedVolume} />
      </Volume>
      <Slider
        aria-labelledby="player-volume"
        max={100}
        min={0}
        onChange={handleChangeVolume}
        onClick={handleSliderClick}
        step={1}
        value={displayedVolume}
      />
    </Container>
  );
};

export default memo(VolumeControls);
