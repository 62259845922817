import { ModalType } from 'graphql/reactive';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import usePacks from 'graphql/hooks/usePacks';

const useConnect = () => {
  const { isOpen, payload } = useModal(
    ModalType.INTRO_COURSE_SECTION_COMPLETED,
  );
  const { closeModal } = useModalActions();
  const { packs } = usePacks();

  const packId = payload?.packId || '';
  const pack = packId ? packs.find(({ id }) => id === packId) : undefined;
  const endMessage = pack?.endMessage || '';

  return {
    closeModal,
    endMessage,
    isOpen: isOpen && !!endMessage,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
