import type { FC, ReactNode } from 'react';
import { Container, Content, Logo } from './styles';

const Layout: FC<{ children?: ReactNode }> = ({ children }) => (
  <Container>
    <Content>
      <Logo />
      {children}
    </Content>
  </Container>
);

export default Layout;
