import { memo } from 'react';
import type { FC } from 'react';
import formatRetreatDate from 'lib/formatRetreatDate';
import CardListItem from 'components/CardListItem';
import { Container } from './styles';
import type { Props } from './types';

const RetreatsList: FC<Props> = ({ ariaLabelledby, id, retreats, role }) => (
  <Container aria-labelledby={ariaLabelledby} id={id} role={role}>
    {retreats.map(
      ({
        endDate,
        id: retreatId,
        imageThumbnailsThumbX1,
        slug,
        startDate,
        subtitle,
        title,
      }) => {
        const formattedDate = formatRetreatDate(startDate, endDate);

        return (
          <CardListItem
            key={retreatId}
            highlight={formattedDate}
            imageUrl={imageThumbnailsThumbX1}
            state={{ screen: 'retreats_tab' }}
            subtitle={subtitle}
            title={title}
            to={`/practice/retreats/${slug}`}
            type="retreat"
          />
        );
      },
    )}
  </Container>
);

export default memo(RetreatsList);
