/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import addMilliseconds from 'date-fns/addMilliseconds';
import addMinutes from 'date-fns/addMinutes';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import endOfMinute from 'date-fns/endOfMinute';
import format from 'date-fns/format';
import { UseConnect } from './connect';

export const useFinishTime = ({
  duration,
}: {
  duration: UseConnect['duration'];
}): string => {
  const [currentDate, setCurrentDate] = useState(new Date());

  // Update the current date each minute.
  useEffect(() => {
    const timeout = window.setTimeout(() => {
      setCurrentDate(new Date());
    }, differenceInMilliseconds(addMilliseconds(endOfMinute(currentDate), 1), Date.now()));

    return () => window.clearTimeout(timeout);
  }, [currentDate]);

  const finishTime = addMinutes(currentDate, duration);

  return format(finishTime, 'hh:mm a');
};
