import { memo } from 'react';
import type { FC } from 'react';
import { Container, Icon, Subtitle, Title } from './styles';
import type { Props } from './types';

const NoBookmarks: FC<Props> = ({ ariaLabelledby, id, role, textType }) => (
  <Container aria-labelledby={ariaLabelledby} id={id} role={role}>
    <Icon aria-hidden="true" name="bookmarkLight" />
    <Title>
      You haven't added{' '}
      {textType === 'Daily Meditation' ? (
        <>
          any <span>Daily Meditations</span>
        </>
      ) : (
        `anything from ${textType}`
      )}{' '}
      yet.
    </Title>
    <Subtitle>
      Tap the bookmark icon on any{' '}
      {textType === 'Daily Meditation' ? textType : `${textType} track`} to add
      it to your Library.
    </Subtitle>
  </Container>
);

export default memo(NoBookmarks);
