import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultRetreatCard from '../RetreatCard';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  color: inherit;
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.01em;
  line-height: 1.5;
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;

  ${media.phoneLandscape} {
    margin-top: 2.25rem;
  }

  ${media.phonePortrait} {
    margin-top: 4.25rem;
  }
`;

export const Subtitle = styled.p`
  color: inherit;
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: 0.01em;
  line-height: 1.4286;
  margin-bottom: 1.75rem;
  max-width: 19.375rem;
  text-align: center;

  ${media.phoneLandscape} {
    margin-bottom: 1.75rem;
  }
`;

export const RetreatCard = styled(DefaultRetreatCard)`
  margin-bottom: 3.5rem;
  max-width: 21.375rem;
  padding: 37px 1.4063rem 2.8388rem;
`;
