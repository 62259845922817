import type { FC } from 'react';
import {
  Container,
  Expired,
  ExpiredText,
  ExpiredTitle,
  LinkButton,
  StepContent,
} from './styles';
import type { Props } from './types';

const ExpiredPromotion: FC<Props> = ({
  className,
  description,
  isPublic = false,
  linkText,
  linkTo,
  title = 'Sorry, this offer is no longer available.',
}) => {
  return (
    <Container $isPublic={isPublic} className={className}>
      <StepContent>
        <Expired>
          <ExpiredTitle>{title}</ExpiredTitle>
          <ExpiredText>{description}</ExpiredText>
          <LinkButton to={linkTo}>{linkText}</LinkButton>
        </Expired>
      </StepContent>
    </Container>
  );
};

export default ExpiredPromotion;
