import { apolloClient } from 'services/api';
import type { QueryOptions } from 'types/utils';
import { normalizeWidget, type ValidWidgetFragments } from 'models/Widget';
import {
  GetHomeDocument,
  type GetHomeQuery,
  type GetHomeQueryVariables,
} from '../generated';

const getHome = async (
  options: QueryOptions<GetHomeQueryVariables, GetHomeQuery> = {},
) => {
  const { data } = await apolloClient.query({
    query: GetHomeDocument,
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    ...options,
  });

  return data?.home
    ? data.home
        .filter((widget): widget is ValidWidgetFragments =>
          [
            'WidgetUpNext',
            'WidgetCTA',
            'WidgetSpotlight',
            'WidgetContinueListening',
            'WidgetCarousel',
            'WidgetImage',
          ].includes(widget.__typename),
        )
        .map((w, i) => ({ ...normalizeWidget(w), position: i }))
    : [];
};

export default getHome;
