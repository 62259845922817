import { setUser as registerSentryUser } from '@sentry/react';
import { parse } from 'query-string';
import { PlayerSource } from 'models/Player';
import { START_HERE_COURSE_HASH } from 'models/Course';
import { DailyMeditationDuration } from 'models/DailyMeditationDuration';
import { PackType } from 'graphql/generated';
import { dailyPlayerVar } from 'graphql/reactive';
import {
  getCourse,
  getDaily,
  getSettings,
  getSharedDaily,
  getWidget,
} from 'graphql/requests';
import {
  setAmplitudeUserProperties,
  setUpAmplitude,
  registerAmplitudeUser,
  unregisterAmplitudeUser,
  trackAmplitudeEvent,
} from './amplitude';
import {
  setBrazeUserProperties,
  setUpBraze,
  registerBrazeUser,
  trackBrazeEvent,
} from './braze';
import { registerAppsflyerUser, trackAppsflyerEvent } from './appsflyer';
import { registerGAUser } from './ga';
import { AnalyticsEvent } from './types';

export const setUpAnalytics = () => {
  setUpAmplitude();
  setUpBraze();
};

export const setAnalyticsUserProperties = (
  userProps: Record<string, string>,
) => {
  setBrazeUserProperties(userProps);
  setAmplitudeUserProperties(userProps);
};

export const registerAnalyticsUser = ({
  id,
  uuid,
}: {
  id: string;
  uuid: string;
}) => {
  registerAmplitudeUser(uuid);
  registerBrazeUser(uuid);
  registerAppsflyerUser(uuid);
  registerGAUser({ id, uuid });
  registerSentryUser({ id: uuid });
};

export const unregisterAnalyticsUser = () => {
  unregisterAmplitudeUser();
  registerSentryUser(null);
};

export const trackEvent = (
  event: AnalyticsEvent,
  data?: Record<string, unknown>,
) => {
  trackAmplitudeEvent(event, data);

  if (event === 'Onboard Home') {
    trackBrazeEvent(event, data);
  }
};

export const trackMediaEvent = async (
  event: AnalyticsEvent,
  courseId: string,
  data: Record<string, unknown> = {},
) => {
  const { player, recentlyPlayed } = await getSettings();
  const playerCourseId = player?.courseId;
  const playerCode = player?.code;
  const playerPackId = player?.packId;
  const playerQueryString = player?.queryString;
  const playerRetreatHash = player?.retreatHash;
  const playerSharedDailyHash = player?.sharedDailyHash;
  const playerSource = player?.source;

  const code =
    playerCode && playerCourseId === courseId
      ? playerCode
      : `${event.replace(/\s/g, '')}-${courseId}-${new Date().getTime()}`;

  let payload: Record<string, unknown> = {};

  const daily = playerSharedDailyHash
    ? await getSharedDaily({
        variables: { shared_hash: playerSharedDailyHash },
      })
    : await getDaily();
  if (daily && daily.hash === courseId) {
    const { duration } = dailyPlayerVar();

    payload = {
      category: 'daily meditation',
      code,
      dailyAudioId: daily.dailyAudioId,
      duration:
        duration === DailyMeditationDuration.MIN_20
          ? daily.dailyAudio2Length
          : daily.dailyAudio1Length,
      hash: daily.hash,
      name: daily.title,
      packID: -1,
      packName: 'Daily Meditation',
      platform: 'web',
      recentlyPlayed: playerSource === PlayerSource.RECENTLY_PLAYED,
      source: playerSource || PlayerSource.PACK,
      tags: '',
      trackID: daily.hash,
      type: 'daily',
      ...data,
    };
  } else {
    const course = await getCourse(courseId, playerPackId);
    if (course) {
      let category = 'practice';
      if (course.packType === PackType.life) {
        category = 'life';
      } else if (course.packType === PackType.lesson) {
        category = 'theory';
      }

      let type = 'course';
      if (course.isConversation) {
        type = 'conversation';
      } else if (course.courseType === 'lesson') {
        type = 'lesson';
      }

      payload = {
        category,
        code,
        duration: course.audioLength,
        hash: course.hash,
        name: course.title,
        packID: course.packId || 'noPackID',
        packName: course.packTitle || 'noPackID',
        platform: 'web',
        recentlyPlayed: playerSource === PlayerSource.RECENTLY_PLAYED,
        source: playerSource || PlayerSource.PACK,
        tags: course.tags.map((v) => `[${v.value}]`).join(''),
        trackID: course.bdId,
        type,
        ...data,
      };

      if (playerRetreatHash) {
        payload.retreatHash = playerRetreatHash;
      }
    }
  }

  if (playerQueryString) {
    const queryStringfields = parse(playerQueryString);
    Object.keys(queryStringfields).forEach((k) => {
      payload[`link_${k}`] = queryStringfields[k];
    });
  }

  trackAmplitudeEvent(event, payload);

  if (event !== 'Media Notes View') {
    trackBrazeEvent(event, payload);
  }

  // Check if it's the first time the user completes a content
  // that is not the 'START HERE' course.
  if (
    event === 'Media Complete' &&
    courseId !== START_HERE_COURSE_HASH &&
    (recentlyPlayed.length === 0 ||
      (recentlyPlayed.length === 1 &&
        recentlyPlayed[0].course_hash === START_HERE_COURSE_HASH))
  ) {
    trackAppsflyerEvent('pba', 'event', {
      eventType: 'EVENT',
      eventName: 'af_content_view',
    });
  }
};

export const trackWidgetEvent = async (
  widgetId: string,
  data: Record<string, unknown> = {},
) => {
  const widget = await getWidget(widgetId);
  if (!widget) return;

  const payload: Record<string, unknown> = {
    widget_id: widget.id,
    widget_position: widget.position,
    widget_title: widget.title,
    widget_type: widget.category,
  };

  if (
    widget.typename === 'WidgetCarousel' ||
    widget.typename === 'WidgetContinueListening'
  ) {
    payload.image_size = widget.imageSize;
    payload.layout = widget.layout;
  }

  if (widget.typename === 'WidgetImage') {
    payload.deep_link = widget.deepLink;
  }

  trackAmplitudeEvent('Home Widget Click', { ...payload, ...data });
};
