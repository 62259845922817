import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { normalizePerson } from 'models/Person';
import { GetContentDocument } from 'graphql/generated';

const usePerson = (personId?: string) => {
  const { data, loading } = useQuery(GetContentDocument, {
    fetchPolicy: 'cache-first',
  });

  const person = useMemo(() => {
    if (!personId || !data?.content?.persons) {
      return undefined;
    }

    const p = data.content.persons.find((v) => v.id === personId);

    return p ? normalizePerson(p) : undefined;
  }, [data, personId]);

  return { loading, person };
};

export default usePerson;
export type UsePerson = ReturnType<typeof usePerson>;
