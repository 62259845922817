import { useCallback, useMemo, useState } from 'react';
import type { KeyboardEvent, MouseEvent } from 'react';
import getCourseProgressPercent from 'lib/getCourseProgressPercent';
import { setOptionsMenuVar } from 'graphql/reactive';
import usePacks from 'graphql/hooks/usePacks';
import usePackCourses from 'graphql/hooks/usePackCourses';
import useCourseActions from 'graphql/hooks/useCourseActions';
import useSettings from 'graphql/hooks/useSettings';
import { Pack } from 'models/Pack';
import { Course } from 'models/Course';
import { ConnectProps, TabType } from './types';

const useConnect = ({ hits }: ConnectProps) => {
  const {
    settings: { recentlyPlayed },
  } = useSettings();
  const { packs, loading: isLoadingPacks } = usePacks();
  const { packCourses, loading: isLoadingCourses } = usePackCourses();
  const { play } = useCourseActions();

  const [selectedTab, setSelectedTab] = useState<string>();

  const { filteredConversations, filteredCourses, filteredPacks, types } =
    useMemo(() => {
      const coursesWithProgress = packCourses.map((c) => ({
        ...c,
        progress: getCourseProgressPercent({
          hash: c.hash,
          audioLength: c.audioLength,
          recentlyPlayed,
          status: c.status,
        }),
      }));

      const initialTypes = {
        [TabType.SERIES]: 0,
        [TabType.CONVERSATIONS]: 0,
        [TabType.SESSIONS]: 0,
      };

      const categorizedHits = hits.reduce<{
        filteredConversations: Course[];
        filteredCourses: Course[];
        filteredPacks: Pack[];
      }>(
        (t, { hash, type }) => {
          if (type === 'Pack') {
            const pack = packs.find((p) => hash === p.hash);
            if (pack) {
              initialTypes[TabType.SERIES] += 1;
              t.filteredPacks.push(pack);
              return t;
            }
          }
          if (type === 'Course' || type === 'Conversation') {
            const course = coursesWithProgress.find(
              (c) => c.hash === hash && c.packIsPrimaryPack,
            );
            if (course) {
              if (type === 'Conversation') {
                initialTypes[TabType.CONVERSATIONS] += 1;
                t.filteredConversations.push(course);
                return t;
              }
              initialTypes[TabType.SESSIONS] += 1;
              t.filteredCourses.push(course);
              return t;
            }
          }
          return t;
        },
        {
          filteredConversations: [],
          filteredCourses: [],
          filteredPacks: [],
        },
      );

      const currentTypes = Object.fromEntries(
        Object.entries(initialTypes).filter(([, v]) => v !== 0),
      );

      if (!selectedTab || !currentTypes[selectedTab as TabType]) {
        setSelectedTab(Object.keys(currentTypes)[0]);
      }

      return { ...categorizedHits, types: currentTypes };
    }, [hits, packCourses, packs, recentlyPlayed, selectedTab]);

  const handlePlayCourse = useCallback(
    async (courseId: string, packId: string) => {
      await play({ courseId, packId });
    },
    [play],
  );

  const handleClick = useCallback((tab: string) => {
    setSelectedTab(tab);
  }, []);

  const handleOpenOptionsMenu = useCallback(
    (
      event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLButtonElement>,
      courseId: string,
      packId: string,
    ) => {
      event.stopPropagation();
      setOptionsMenuVar({
        anchorEl: event.currentTarget,
        id: courseId,
        packId,
        type: 'course',
      });
    },
    [],
  );

  return {
    filteredConversations,
    filteredCourses,
    filteredPacks,
    handleClick,
    handleOpenOptionsMenu,
    handlePlayCourse,
    isLoading: isLoadingPacks || isLoadingCourses,
    selectedTab,
    types: Object.entries(types),
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
