import { memo } from 'react';
import type { FC } from 'react';
import { Container, Label, MaterialSwitch } from './styles';
import { Props } from './types';

const Switch: FC<Props> = ({
  className,
  label,
  labelPosition = 'left',
  ...rest
}) => (
  <Container $labelPosition={labelPosition} className={className}>
    {!!label && <Label $labelPosition={labelPosition}>{label}</Label>}
    <MaterialSwitch inputProps={{ 'aria-label': label }} {...rest} />
  </Container>
);

export default memo(Switch);
