import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultIcon from 'components/Icon';
import DefaultButton from 'components/Button';

export const Container = styled.div`
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;
  max-width: 1184px;
  min-height: 100vh;
  padding: 0 2rem 0 2rem;

  ${media.phoneLandscape} {
    padding: 0 1.25rem 0 1.25rem;
  }
`;

export const Main = styled.main`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 2rem 0;
  text-align: center;

  ${media.phoneLandscape} {
    padding-bottom: 4rem;
  }
`;

export const Image = styled.img`
  align-self: center;
  display: block;
  height: 3.8984rem;
  width: 2.875rem;
  margin-top: 2.5625rem;

  ${media.phoneLandscape} {
    height: 2.9814rem;
    width: 2.2rem;
    margin-top: 2rem;
  }
`;

export const Icon = styled(DefaultIcon).attrs(() => ({ size: 40 }))`
  color: ${({ theme }) => theme.colors.coreBlue500};
  display: block;
  margin-bottom: 1.125rem;
`;

export const Title = styled.h1`
  font-size: 1.5625rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.21;
`;

export const Text = styled.p`
  font-size: 0.875rem;
  letter-spacing: 0.01em;
  line-height: 1.4;
  margin-top: 0.375rem;
  max-width: 28rem;

  strong {
    font-size: inherit;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`;

export const TextSmall = styled(Text)`
  font-size: 0.75rem;
  margin-top: 1.6rem;
`;

export const Button = styled(DefaultButton)`
  background-color: ${({ theme }) => theme.colors.coreTeal500};
  border: 1px solid ${({ theme }) => theme.colors.coreTeal500};
  display: block;
  margin-top: 2rem;
  max-width: 100%;
  width: 21.5rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.coreTeal700};
    border: 1px solid ${({ theme }) => theme.colors.coreTeal700};
    color: ${({ theme }) => theme.colors.coreNeutralWhite};
  }
`;
