import { memo, useCallback } from 'react';
import type { FC } from 'react';
import { Container } from './styles';
import { Props } from './types';

const MenuItem: FC<Props> = ({
  active,
  id,
  onClick = () => null,
  role,
  text,
}) => {
  const handleClick = useCallback(() => {
    onClick(id);
  }, [onClick, id]);

  return (
    <Container
      active={active}
      id={id.toString()}
      onClick={handleClick}
      role={role}
      text={text}
    />
  );
};

export default memo(MenuItem);
