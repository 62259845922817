import styled from 'styled-components';
import { dangerButtonStyles, media } from 'styles/mixins';
import DefaultSectionHeader from 'components/DeprecatedSectionHeader';
import colorWithAlpha from 'lib/color';
import DefaultForm from 'components/Form';
import DefaultTextField from 'components/Inputs/TextField';
import DefaultMember from './Member';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;

  ${media.tabletPortrait} {
    padding: 1.4rem;
  }

  ${media.phoneLandscape} {
    padding: ${({ theme }) => theme.measures.m};
  }
`;

export const SectionHeader = styled(DefaultSectionHeader)``;

export const Content = styled.div`
  color: inherit;
  width: 100%;
`;

export const Title = styled.h2`
  flex: 2;
  color: inherit;
  font-size: 1.125rem;
  margin: 1.25rem;
  text-align: center;
  text-transform: uppercase;
`;

export const Member = styled(DefaultMember)`
  padding: 0.6rem 1.6rem;
  transition: background-color 200ms ease;

  &:first-child {
    border-top: ${({ theme }) =>
      `1px solid ${colorWithAlpha({
        color:
          theme.name === 'LIGHT'
            ? theme.colors.coreNeutralBlack
            : theme.colors.coreNeutralWhite,
        alpha: 0.1,
      })}}`};
  }

  &:not(:last-child) {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.borderBase}`};
  }

  &:last-child {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.borderBase}`};
  }

  &:hover {
    background: ${({ theme }) =>
      colorWithAlpha({
        color: theme.colors.coreNeutralWhite,
        alpha: theme.name === 'LIGHT' ? 0.5 : 0.05,
      })};
  }

  ${media.tabletPortrait} {
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }

  ${media.phoneLandscape} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const Form = styled(DefaultForm)``;

export const TextField = styled(DefaultTextField)`
  margin-bottom: 1.25rem;
`;

export const Button = styled.button.attrs({ type: 'button' })`
  ${dangerButtonStyles};

  color: ${({ theme }) => theme.colors.coreBlue700};

  &::before {
    background-color: ${({ theme }) => theme.colors.coreBlue700};
  }
  display: flex;
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Members = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Spacer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
