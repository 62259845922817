import { useEffect, useCallback, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  setTimerIsPlaying,
  setTimerPosition,
  setVolume,
  timerIsPlayingVar,
  timerPositionVar,
  volumeVar,
  type Volume,
} from 'graphql/reactive';
import { COMPLETE_MARGIN_SECONDS } from './constants';
import type { ConnectProps } from './types';
import { useTimer } from './hooks';

const useConnect = ({
  duration,
  interval,
  onComplete,
  onEnd,
  onInit,
  onInterval,
  onPause,
  onPlay,
  onVolumeChange,
  startTime,
}: ConnectProps) => {
  const { timerIsPlaying, timerPosition } = useTimer();
  const volume = useReactiveVar(volumeVar);

  const audioAmbientRef = useRef<HTMLVideoElement>(null);
  const audioInitRef = useRef<HTMLVideoElement>(null);
  const audioIntervalRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    setTimerPosition(0);
  }, [startTime]);

  useEffect(() => {
    const { isMuted, level } = volumeVar();
    const normalizedVolume = level / 100;

    if (audioAmbientRef.current) {
      audioAmbientRef.current.volume = normalizedVolume;
      audioAmbientRef.current.muted = isMuted;
    }
    if (audioInitRef.current) {
      audioInitRef.current.volume = normalizedVolume;
      audioInitRef.current.muted = isMuted;
    }
    if (audioIntervalRef.current) {
      audioIntervalRef.current.volume = normalizedVolume;
      audioIntervalRef.current.muted = isMuted;
    }
  }, []);

  useEffect(() => {
    if (duration && timerIsPlaying) {
      if (timerPosition === 1) {
        audioInitRef.current?.play().catch(() => null);
        if (onInit) onInit();
      } else if (timerPosition === duration - COMPLETE_MARGIN_SECONDS) {
        audioInitRef.current?.play().catch(() => null);
        if (onComplete) onComplete();
      } else if (timerPosition === duration) {
        setTimerIsPlaying(false);
        if (onEnd) onEnd();
      } else if (
        interval &&
        timerPosition > 0 &&
        timerPosition % interval === 0
      ) {
        audioIntervalRef.current?.play().catch(() => null);
        if (onInterval) onInterval();
      }
    }
  }, [
    duration,
    interval,
    onComplete,
    onEnd,
    onInit,
    onInterval,
    timerIsPlaying,
    timerPosition,
  ]);

  useEffect(() => {
    if (timerIsPlaying) {
      const audioAmbient = audioAmbientRef.current;
      audioAmbient?.play().catch(() => null);

      const intervalId = setInterval(() => {
        setTimerPosition(timerPositionVar() + 1);
      }, 1000);
      return () => {
        audioAmbient?.pause();
        clearInterval(intervalId);
      };
    }
  }, [timerIsPlaying]);

  const handleTogglePlay = useCallback(async () => {
    const prevTimerIsPlaying = timerIsPlayingVar();

    if (onPause && prevTimerIsPlaying) {
      onPause();
    } else if (onPlay && !prevTimerIsPlaying) {
      onPlay();
    }

    setTimerIsPlaying(!prevTimerIsPlaying);
  }, [onPause, onPlay]);

  const handleSetPosition = useCallback(async (newPosition: number) => {
    setTimerPosition(newPosition);
  }, []);

  const handleSetVolume = useCallback(
    (newVolume: Volume) => {
      const normalizedVolume = newVolume.level / 100;

      if (audioAmbientRef.current) {
        audioAmbientRef.current.volume = normalizedVolume;
        audioAmbientRef.current.muted = newVolume.isMuted;
      }
      if (audioInitRef.current) {
        audioInitRef.current.volume = normalizedVolume;
        audioInitRef.current.muted = newVolume.isMuted;
      }
      if (audioIntervalRef.current) {
        audioIntervalRef.current.volume = normalizedVolume;
        audioIntervalRef.current.muted = newVolume.isMuted;
      }

      setVolume(newVolume);

      if (onVolumeChange) onVolumeChange();
    },
    [onVolumeChange],
  );

  return {
    audioAmbientRef,
    audioInitRef,
    audioIntervalRef,
    handleSetPosition,
    handleSetVolume,
    handleTogglePlay,
    isPlaying: timerIsPlaying,
    position: timerPosition,
    volume,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
