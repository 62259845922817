import type { FC } from 'react';
import { memo } from 'react';
import { AnimatePresence } from 'framer-motion';
import useConnect from './connect';
import {
  Container,
  ClearButton,
  Error,
  Icon,
  InputContainer,
  MuiTextField,
  IconContainer,
} from './styles';
import type { Props } from './types';

const TextField: FC<Props> = ({
  autoComplete,
  autoFocus,
  className,
  errorId,
  icon,
  id,
  isClearable = false,
  label,
  maxLength,
  multiline = false,
  name,
  normalize,
  placeholder,
  readOnly,
  ref,
  type,
  value,
}) => {
  const { error, field, handleChange, handleClear, inputRef, touched } =
    useConnect({
      name,
      normalize,
      ref,
    });

  const showIcon = icon && (!isClearable || (isClearable && !field.value));

  return (
    <Container className={className}>
      <InputContainer>
        <MuiTextField
          $hasIcon={isClearable || !!showIcon}
          aria-describedby={errorId}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          disabled={readOnly}
          error={touched && !!error}
          fullWidth
          helperText={touched && error}
          hiddenLabel={!label}
          id={id}
          InputProps={{
            readOnly,
            inputProps: { maxLength },
          }}
          inputRef={inputRef}
          label={label}
          multiline={multiline}
          placeholder={placeholder}
          type={type}
          variant="filled"
          {...field}
          onChange={handleChange}
          value={value || field.value}
        />
        {isClearable && !!field.value && (
          <ClearButton
            aria-label="Clear field"
            type="button"
            onClick={handleClear}
          >
            <Icon name="xCircleLight" />
          </ClearButton>
        )}
        {showIcon && (
          <IconContainer
            $disabled={!!readOnly}
            $hasValue={!!field.value && !!label}
          >
            <Icon name={icon} />
          </IconContainer>
        )}
      </InputContainer>
      <AnimatePresence initial={false}>
        {touched && error && <Error id={errorId}>{error}</Error>}
      </AnimatePresence>
    </Container>
  );
};

export default memo(TextField);
