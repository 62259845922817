import type { FC } from 'react';
import type { BaseIconProps as Props } from '../../Icons';

const BookmarkSingleActive: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 16 16"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="m12.4 14-4.2-3L4 14V2.6a.6.6 0 0 1 .6-.6h7.2a.6.6 0 0 1 .6.6V14Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BookmarkSingleActive;
