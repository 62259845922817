/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const SUBSCRIPTION_PLAN_DATA_FRAGMENT = gql`
  fragment SubscriptionPlanData on SubscriptionPlan {
    __typename
    amount
    id
    interval
    nickname
    originalAmount
    sku
    type
  }
`;

export const LIFETIME_PRICE_DATA_FRAGMENT = gql`
  fragment LifetimePriceData on LifetimePrice {
    __typename
    amount
    email
    id
    originalAmount
  }
`;

export const USER_SUBSCRIPTION_DATA_FRAGMENT = gql`
  fragment UserSubscriptionData on UserSubscription {
    __typename
    expires_at
    external_id
    external_plan_id
    family_member
    id
    is_eligible_for_sharing
    is_eligible_for_yearly_upgrade_with_discount
    price_usd
    renew_intent
    source
    start_time
    stripe_trial_charge_date
    sub_type
  }
`;

export const STRIPE_FAMILY_MEMBER_DATA_FRAGMENT = gql`
  fragment StripeFamilyMemberData on StripeFamilyMember {
    __typename
    email
    status
  }
`;
