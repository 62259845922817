import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ZIndex } from 'styles/metrics';
import { media } from 'styles/mixins';
import DefaultIcon from 'components/Icon';

export const Container = styled(motion.div).attrs(() => ({
  animate: { y: '0' },
  initial: { y: '100%' },
  exit: { y: '100%' },
  transition: { duration: 0.25, ease: 'easeInOut' },
}))`
  align-items: center;
  background-color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralAurora
      : theme.colors.coreNeutralAbyss};
  bottom: 0;
  color: ${({ theme }) => theme.colors.fontBase};
  display: flex;
  flex-flow: column nowrap;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${ZIndex.PLAYER_DETAILS};
`;

const ClearButton = styled.button`
  background: transparent;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: block;
  line-height: 1;
  outline: none;
  width: fit-content;

  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

export const Button = styled(ClearButton)`
  position: absolute;
  left: 2rem;
  top: 2rem;
  z-index: 10;

  ${media.phoneLandscape} {
    left: 1rem;
    top: 1rem;
  }
`;

export const Icon = styled(DefaultIcon)`
  display: block;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: flex-start;
  max-width: 35rem;
  position: relative;
  width: 100%;
`;

export const Animation = styled(motion.div).attrs(() => ({
  transition: { duration: 0.25, ease: 'easeInOut' },
}))`
  align-items: stretch;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  left: 0;
  overflow-x: visible;
  overflow-y: auto;
  padding-bottom: 4rem;
  position: absolute;
  right: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  top: 2.6rem;

  &::-webkit-scrollbar {
    display: none;
  }

  ${media.phoneLandscape} {
    padding: 0 1rem 4rem 1rem;
    top: 4rem;
  }
`;

export const Html = styled.div`
  font-size: 0.875rem;
  line-height: 1.4;
  letter-spacing: 0.01em;

  span,
  a,
  p {
    font-size: inherit;
  }

  a,
  a:hover,
  a:active,
  a:visited {
    color: inherit;
  }

  p {
    display: block;
    margin-block-end: 1rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  strong,
  b {
    font-size: inherit;
    font-weight: 600;
  }

  em,
  i {
    font-size: inherit;
    font-style: italic;
  }

  ul {
    font-size: inherit;
    list-style-position: inside;
    margin: 0.5rem 0;
  }
`;
