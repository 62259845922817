/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { DAILY_DATA_FRAGMENT } from './daily';

export const BOOKMARKS_DATA_FRAGMENT = gql`
  fragment BookmarksData on Bookmarks {
    courses {
      id
      hash
    }
    dailies {
      ...DailyData
    }
  }
  ${DAILY_DATA_FRAGMENT}
`;
