import { memo, type FC } from 'react';
import { Container, Progress } from './styles';

const Loader: FC = () => (
  <Container>
    <Progress />
  </Container>
);

export default memo(Loader);
