import type { FC } from 'react';
import { Container, Description, Title } from './styles';
import type { Props } from './types';

const Slide: FC<Props> = ({ description, title }) => (
  <Container>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Container>
);

export default Slide;
