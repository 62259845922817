import { memo, useEffect, type FC } from 'react';
import { useLocation } from 'react-router-dom';
import convertPathnameToTitle from 'lib/convertPathnameToTitle';
import { BASE_TITLE, POST_TITLE, ROUTES_TITLES } from './constants';
import { Props } from './types';

const SeoTitle: FC<Props> = ({ isAuthenticated }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/') {
      document.title = `${
        isAuthenticated ? 'Home' : 'Sign In'
      } | ${BASE_TITLE} ${POST_TITLE}`;
    } else {
      document.title =
        ROUTES_TITLES[pathname] ??
        `${convertPathnameToTitle(pathname)} | ${BASE_TITLE}`;
    }
  }, [isAuthenticated, pathname]);

  return null;
};

export default memo(SeoTitle);
