import { memo } from 'react';
import type { FC } from 'react';
import Track from './Track';
import { Container } from './styles';
import type { Props } from './types';

const TrackList: FC<Props> = ({ className, onPlayTrack, tracks }) => (
  <Container className={className}>
    {tracks.map((track) => (
      <Track
        key={`${track.pretitle}${track.title}`}
        onClick={onPlayTrack}
        {...track}
      />
    ))}
  </Container>
);

export default memo(TrackList);
