import { memo, type FC } from 'react';
import useCourse from 'graphql/hooks/useCourse';
import DetailsTabs from 'components/DetailsTabs';
import Card from '../Card';
import { Container } from './styles';
import type { Props } from './types';

const CourseDetails: FC<Props> = ({ courseId, packId }) => {
  const { loading, course } = useCourse(courseId, packId);

  if (loading || !course) {
    return null;
  }

  const {
    displayArtists,
    displayTeachersAndSpeakers,
    imageThumbnailsThumbX2,
    packSummary,
    packType,
    subtitle,
    summary,
    title,
  } = course;

  return (
    <Container>
      <Card
        imageUrl={imageThumbnailsThumbX2}
        packType={packType}
        subtitle={subtitle}
        title={title}
      />
      <DetailsTabs
        artists={displayArtists}
        imageUrl={imageThumbnailsThumbX2}
        summary={summary || packSummary}
        teachers={displayTeachersAndSpeakers}
      />
    </Container>
  );
};

export default memo(CourseDetails);
