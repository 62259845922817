import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { AppConfig } from 'config';
import { resetOptionsMenuVar } from 'graphql/reactive';
import { BookmarkType } from 'graphql/generated';
import useSharedDaily from 'graphql/hooks/useSharedDaily';
import useDailyActions from 'graphql/hooks/useDailyActions';
import useModalActions from 'graphql/hooks/useModalActions';
import useBookmarksActions from 'graphql/hooks/useBookmarksActions';
import useBookmarks from 'graphql/hooks/useBookmarks';
import type { ConnectProps } from './types';

const useConnect = ({ dailyId, sharedHash }: ConnectProps) => {
  const { sharedDaily } = useSharedDaily({
    skip: !sharedHash,
    variables: { shared_hash: sharedHash || '' },
  });
  const { bookmarks } = useBookmarks();
  const { toggleBookmark } = useBookmarksActions();
  const { createSharedDaily } = useDailyActions();
  const { openShareLinkModal } = useModalActions();
  const { pathname } = useLocation();

  const id = sharedDaily ? sharedDaily.id : dailyId;
  const daily =
    sharedDaily || bookmarks.dailies.find((bookmark) => bookmark.id === id);
  const { isBookmark = false, isSpecial = false } = daily || {};

  const handleShare = useCallback(async () => {
    if (!id) return;

    const newSharedHash = await createSharedDaily({ dailyId: id });
    if (!newSharedHash) return;

    openShareLinkModal({
      id,
      title: 'Daily',
      description: 'Check out this guided meditation from the Waking Up app:',
      link: `${AppConfig.dynamicBaseUrl}/daily/${newSharedHash}`,
    });
  }, [createSharedDaily, id, openShareLinkModal]);

  const handleToggleBookmark = useCallback(() => {
    if (id) {
      toggleBookmark(id, BookmarkType.daily);
      if (pathname.includes('my-library')) {
        resetOptionsMenuVar();
      }
    }
  }, [id, pathname, toggleBookmark]);

  return {
    handleShare,
    handleToggleBookmark,
    isBookmark,
    isSpecial,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
