import styled, { css } from 'styled-components';
import { CardElement as StripeCardElement } from '@stripe/react-stripe-js';
import CircularProgress from '@mui/material/CircularProgress';
import { Form as FormikForm } from 'formik';
import { media } from 'styles/mixins';
import DefaultButton from 'components/Button';
import { Label as ButtonLabel } from 'components/Button/styles';
import DefaultRadioGroup from 'components/Inputs/RadioGroup';
import { Option } from 'components/Inputs/RadioGroup/styles';
import DefaultTextField from 'components/Inputs/TextField';
import { MuiTextField } from 'components/Inputs/TextField/styles';
import DefaultTimePicker from 'components/Inputs/TimePicker';
import DefaultDateTimePicker from 'components/Inputs/DateTimePicker';
import DefaultDatePicker from 'components/Inputs/DatePicker';
import DefaultText from 'components/Text';
import {
  CARD_ELEMENT_OPTIONS_DARK,
  CARD_ELEMENT_OPTIONS_LIGHT,
} from './constants';
import { Title as DefaultHeader } from '../styles';

export const Form = styled(FormikForm)<{
  $isPublic: boolean;
}>`
  align-items: flex-start;
  display: flex;

  ${({ $isPublic }) =>
    $isPublic
      ? css`
          padding-top: 5rem;
          padding-bottom: 2rem;
        `
      : css`
          align-items: center;
          flex-direction: column;
          margin: 0 auto;
          width: 29.75rem;
        `}

  ${media.tabletLandscape} {
    padding-top: ${({ $isPublic }) => $isPublic && '1.25rem'};
  }

  ${media.phoneLandscape} {
    flex-direction: column;
    padding: 0 0 ${({ $isPublic }) => $isPublic && '3.85rem'};
    width: 100%;
  }
`;

export const Container = styled.div`
  width: 100%;
`;

export const Loader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 2rem 0;
`;

export const Progress = styled(CircularProgress).attrs(() => ({
  size: 40,
}))``;

export const Header = styled(DefaultHeader)`
  margin-bottom: 1.8125rem;
`;

export const Title = styled.h2`
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.2em;
  line-height: 1.33;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`;

export const Row = styled.div`
  align-items: flex-start;
  display: flex;

  margin-bottom: 1.6875rem;
  width: 100%;

  > :not(:first-child) {
    margin-left: 1.25rem;
  }

  ${media.phonePortrait} {
    flex-direction: column;

    > :not(:first-child) {
      margin-top: 0.5rem;
      margin-left: 0;
    }
  }
`;

export const TextField = styled(DefaultTextField)`
  width: 100%;

  ${MuiTextField} {
    .MuiFormLabel-root {
      font-size: 0.875rem;
      line-height: 1.21;
    }
  }
`;

export const RadioGroup = styled(DefaultRadioGroup)`
  > div {
    flex-direction: row;
  }

  ${Option} {
    .MuiTypography-root {
      font-size: 0.875rem;
      line-height: 1.21;
    }
  }
`;

export const Label = styled.h2`
  font-size: 0.875rem;
  line-height: 1.21;
`;

export const DateTimePickerLabel = styled(Label)`
  margin-bottom: -0.6875rem;
`;

export const DatePicker = styled(DefaultDatePicker)`
  margin-bottom: 0.9063rem;
`;

export const DateTimePicker = styled(DefaultDateTimePicker)`
  margin-bottom: 1rem;
`;

export const Block = styled.div`
  width: 100%;

  ${media.phonePortrait} {
    &:not(:first-child) {
      margin-top: 0.9063rem;
    }
  }
`;

export const TimePicker = styled(DefaultTimePicker)`
  width: 100%;

  .MuiInputBase-root {
    padding-top: 0.875rem !important;
  }
`;

export const NoteLabel = styled(Label)`
  margin-bottom: -12px;
  margin-top: 2.6563rem;
`;

export const PaymentLabel = styled(Label)`
  margin-top: 1.8rem;
`;

export const PaymentMethods = styled.div`
  &::after {
    content: "Or enter card details and click 'Subscribe'";
    display: block;
    opacity: 0.5;
    padding: 0.5rem;
    text-align: center;
  }

  > button {
    margin-top: 0.75rem;
  }
`;

export const OtherPaymentButton = styled(DefaultButton)`
  &&& {
    background-color: ${({ theme }) => theme.colors.coreNeutralBlack};
    border-radius: ${({ theme }) => theme.radius.sm};
    color: ${({ theme }) => theme.colors.coreTeal50};
    min-height: 2.5rem;
    width: 100%;
  }
`;

export const CardElement = styled(StripeCardElement).attrs(({ theme }) => ({
  options:
    theme.name === 'LIGHT'
      ? CARD_ELEMENT_OPTIONS_LIGHT
      : CARD_ELEMENT_OPTIONS_DARK,
}))`
  margin-bottom: 0.75rem;

  &.StripeElement {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderBase};
    box-sizing: border-box;
    padding: 0.7rem 0rem;
  }

  &.StripeElement--focus {
    border-color: ${({ theme }) => theme.colors.fontEmphasized};
  }

  &.StripeElement--webkit-autofill {
    background-color: transparent !important;
  }
`;

export const FormErrors = styled(DefaultText)`
  margin-bottom: 0.6rem;
  margin-top: 1.6rem;
`;

export const PurchaseContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 1.125rem;
`;

export const Price = styled.h2`
  font-size: 1.0625rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.24;
  text-align: center;
`;

export const Button = styled(DefaultButton)<{ isLoading: boolean }>`
  background-color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreBlue500
      : theme.colors.coreTeal500};
  border: none;
  box-sizing: border-box;
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutralWhite
      : theme.colors.coreNeutralAbyss};
  display: inline-block;
  padding: 0.75rem 1.5625rem;
  width: auto;

  &:hover,
  &:focus {
    background-color: ${({ isLoading, theme }) =>
      !isLoading &&
      (theme.name === 'LIGHT'
        ? theme.colors.coreBlue700
        : theme.colors.coreTeal700)};
    border: none;
  }

  ${ButtonLabel} {
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.font.weight.semibold};
    line-height: 1.3125rem;
  }
`;
