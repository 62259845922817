import { useCallback } from 'react';
import { AppConfig } from 'config';
import { resetOptionsMenuVar, setDetails } from 'graphql/reactive';
import usePack from 'graphql/hooks/usePack';
import useResetActions from 'graphql/hooks/useResetActions';
import useModalActions from 'graphql/hooks/useModalActions';
import type { LogicProps } from './types';

const useConnect = ({ packId }: LogicProps) => {
  const { pack } = usePack(packId);
  const { resetPackCourses } = useResetActions();
  const { openShareLinkModal } = useModalActions();

  const { hash = '', info, persons, summary } = pack || {};

  const hasSubpacks = pack && pack.nSubpacks > 0;
  const hasDetails = !!summary || (persons && persons.length > 0);

  const handleMarkAsUnplayed = useCallback(async () => {
    if (!hasSubpacks && hash) {
      await resetPackCourses(hash);
      resetOptionsMenuVar();
    }
  }, [hash, hasSubpacks, resetPackCourses]);

  const handleShare = useCallback(() => {
    if (packId) {
      openShareLinkModal({
        id: packId,
        title: 'Share this pack',
        description:
          'Share this link with anyone you feel would benefit from this Waking Up pack',
        link: `${AppConfig.dynamicBaseUrl}/pack/${hash}`,
      });

      setTimeout(() => resetOptionsMenuVar(), 300);
    }
  }, [hash, openShareLinkModal, packId]);

  const handleShowDetails = useCallback(() => {
    if (hasDetails) {
      setDetails({ packId, type: 'pack' });
      resetOptionsMenuVar();
    }
  }, [hasDetails, packId]);

  return {
    handleMarkAsUnplayed,
    handleShare,
    handleShowDetails,
    hasDetails,
    hasSubpacks,
    info,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
