import { memo } from 'react';
import type { FC } from 'react';
import TrackInfo from 'components/Player/TrackInfo';
import ProgressBar from 'components/Player/ProgressBar';
import VolumeControls from 'components/Player/VolumeControls';
import ControlButtons from 'components/Player/ControlButtons';
import Audio from '../Audio';
import { Container, Content, Info, Progress } from './styles';
import useConnect from './connect';

const RetreatPlayer: FC = () => {
  const {
    audioUrl,
    handleClose,
    handleClosePlayer,
    handleComplete,
    handleEnd,
    handleInit,
    handleToggleDisplayStatus,
    hash,
    imageThumbnailsThumbX1,
    imageUrl,
    isLoadingData,
    isMinimized,
    startTime,
    subtitle,
    title,
  } = useConnect();

  return (
    <Container
      backgroundImage={imageThumbnailsThumbX1}
      isMinimized={isMinimized}
      onClose={handleClosePlayer}
      onToggleDisplayStatus={handleToggleDisplayStatus}
    >
      <Content>
        <Info>
          <TrackInfo
            imgUrl={imageUrl}
            isMinimized={isMinimized}
            subtitle={subtitle}
            title={title}
          />
        </Info>
        <Audio
          key={hash}
          audioUrl={audioUrl}
          autoPlay
          onClose={handleClose}
          onComplete={handleComplete}
          onEnd={handleEnd}
          onInit={handleInit}
          startTime={startTime}
        >
          {({
            duration,
            handleIncreasePosition,
            handleSetPosition,
            handleSetVolume,
            handleTogglePlay,
            isLoading,
            isPlaying,
            position,
            volume,
          }) => {
            return (
              <>
                <Progress>
                  <ProgressBar
                    duration={duration}
                    isMinimized={isMinimized}
                    onChangePosition={handleSetPosition}
                    position={position}
                  />
                  {!isMinimized && (
                    <VolumeControls
                      onSetVolume={handleSetVolume}
                      volume={volume}
                    />
                  )}
                </Progress>
                <ControlButtons
                  isLoading={isLoadingData || isLoading}
                  isMinimized={isMinimized}
                  isPlaying={isPlaying}
                  onIncreasePosition={handleIncreasePosition}
                  onPlayChange={handleTogglePlay}
                />
              </>
            );
          }}
        </Audio>
      </Content>
    </Container>
  );
};

export default memo(RetreatPlayer);
