import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { CoursePermission } from 'models/Course';
import { PlayerAutoplayType, PlayerSource } from 'models/Player';
import { AddProgressDocument } from '../generated';
import { cacheUnlockAllContent, cacheUpdateCourseFragment } from '../cache';
import { getCourse, getProfile } from '../requests';
import usePlayerActions from './usePlayerActions';

const useCourseActions = () => {
  const { openCoursePlayer } = usePlayerActions();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [addProgressMutation] = useMutation(AddProgressDocument);

  const play = useCallback(
    async ({
      autoplayType,
      courseId,
      packId,
      source,
    }: {
      autoplayType?: PlayerAutoplayType;
      courseId: string;
      packId?: string;
      source?: PlayerSource;
    }) => {
      const course = await getCourse(courseId, packId);
      if (!course) return;

      if (course.permission === CoursePermission.UNLOCKED) {
        openCoursePlayer({
          autoplayType:
            autoplayType === PlayerAutoplayType.PACK && !course.packAutoPlay
              ? null
              : autoplayType,
          hash: courseId,
          packId,
          source,
        });
      } else if (course.permission === CoursePermission.FORBIDDEN) {
        navigate('/account/subscription', {
          replace: true,
          state: {
            source: course.section,
            trackID: course.bdId,
            type: 'content',
          },
        });
      }
    },
    [navigate, openCoursePlayer],
  );

  const markAsListened = useCallback(
    async (courseId: string, packId: string) => {
      try {
        const course = await getCourse(courseId, packId);
        if (!course) return;

        await addProgressMutation({
          variables: {
            data: {
              duration_seconds: course.audioLength,
              finished_at: new Date().toISOString(),
              pack_hash: course.packHash || undefined,
              progress_hash: course.hash,
              progress_type: 'course',
            },
          },
        });

        cacheUpdateCourseFragment(course.hash, { status: 'finished' });

        // Unlock all content after listening last intro course for the first time.
        if (course.isLastIntro) {
          const { isAllContentUnlocked } = await getProfile();

          if (!isAllContentUnlocked) {
            cacheUnlockAllContent();
            enqueueSnackbar('All content has been unlocked', {
              variant: 'success',
            });
          }
        }
      } catch (error) {
        enqueueSnackbar(error?.message || 'Unable to mark track as listened', {
          variant: 'error',
        });
      }
    },
    [addProgressMutation, enqueueSnackbar],
  );

  return {
    markAsListened,
    play,
  };
};

export default useCourseActions;
export type UseCourseActions = ReturnType<typeof useCourseActions>;
