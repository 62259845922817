import DefaultButton from 'components/Button';
import DefaultModal from 'components/Modal';
import styled from 'styled-components';
import { media } from 'styles/mixins';
import { ZIndex } from 'styles/metrics';
import { Body as DefaultBody } from 'components/Typography';

export const MODAL_WIDTH_DESKTOP = '28rem';

export const Modal = styled(DefaultModal)`
  z-index: ${ZIndex.ONBOARDING} !important;

  .MuiPaper-root {
    padding: 0;
    width: ${MODAL_WIDTH_DESKTOP};
  }

  ${media.phoneLandscape} {
    .MuiPaper-root {
      border-radius: 0;
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  margin-left: auto;
  margin-right: auto;

  ${media.phoneLandscape} {
    max-width: ${MODAL_WIDTH_DESKTOP};
  }

  @media (max-height: 450px) {
    max-width: ${MODAL_WIDTH_DESKTOP};
  }

  ${media.phonePortrait} {
    max-width: calc(100vw - 2rem);
  }
`;

export const Description = styled(DefaultBody).attrs(() => ({
  $size: 'large',
  $weight: 'regular',
}))`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderBase};
  letter-spacing: 0.0625rem;
  line-height: 1.1;
  padding: ${({ theme }) => `${theme.measures.x} ${theme.measures.m}`};
  text-align: center;
  ${media.phoneLandscape} {
    margin-top: 1rem;
  }

  @media (max-height: 500px) {
    padding: ${({ theme }) =>
      `${theme.measures.x} ${theme.measures.s} ${theme.measures.m}`};
  }
`;

export const Options = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.measures.x} ${theme.measures.x} `};

  @media (max-height: 500px) {
    padding: ${({ theme }) => `${theme.measures.m} ${theme.measures.m} `};
  }
`;

export const Button = styled(DefaultButton)`
  &&& {
    margin-bottom: ${({ theme }) => theme.measures.x};
    padding: 1rem;

    .MuiButton-label {
      letter-spacing: 0.0625rem;
      font-weight: ${({ theme }) => theme.font.weight.book};
    }

    @media (max-height: 500px) {
      font-size: 0.9rem;
      margin-bottom: ${({ theme }) => theme.measures.m};
      padding: ${({ theme }) => `${theme.measures.s} ${theme.measures.s}`};
    }

    @media (max-height: 380px) {
      font-size: 0.8rem;
      margin-bottom: ${({ theme }) => theme.measures.s};
      padding: ${({ theme }) => `${theme.measures.xs} ${theme.measures.xs}`};
    }
  }
`;
