import { apolloClient } from 'services/api';
import notEmpty from 'lib/notEmpty';
import { normalizeUserProgress } from 'models/UserProgress';
import { GetUserProgressDocument } from '../generated';

const getUserProgress = async (userId: string) => {
  const { data } = await apolloClient.query({
    query: GetUserProgressDocument,
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    variables: { userId },
  });

  if (!data.progress?.data) {
    return [];
  }

  return data.progress.data.filter(notEmpty).map(normalizeUserProgress);
};

export default getUserProgress;
