import useLiveAudio from 'gfirebase/hooks/useLiveAudio';

const useConnect = () => {
  const { liveAudio } = useLiveAudio();

  const { active = false, subtitle = '', title = '' } = liveAudio || {};

  return {
    active,
    subtitle,
    title,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
