import { useCallback } from 'react';
import type { FC } from 'react';
import { useField } from 'formik';
import Option from './Option';
import { Container } from './styles';
import { Props } from './types';

const Selector: FC<Props> = ({ name }) => {
  const [{ value }, , { setValue }] = useField<boolean>(name);

  const handleClick = useCallback(
    (newValue: boolean) => {
      setValue(newValue);
    },
    [setValue],
  );

  return (
    <Container>
      <Option
        active={value}
        iconName={value ? 'checkCircleLightInactive' : 'circleLight'}
        nextValue
        onClick={handleClick}
        text="Send me reminders"
      />
      <Option
        active={!value}
        iconName={!value ? 'checkCircleLightInactive' : 'circleLight'}
        nextValue={false}
        onClick={handleClick}
        text="Don't send me reminders"
      />
    </Container>
  );
};

export default Selector;
