import type { FC } from 'react';
import { Props } from './types';

const TimerLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M12 20.25a8.25 8.25 0 100-16.5 8.25 8.25 0 000 16.5zM12 12l3.712-3.713M9.75.75h4.5"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TimerLight;
