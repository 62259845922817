import type { FC } from 'react';
import {
  Container,
  Content,
  SectionHeader,
  Title,
  Member,
  Members,
  Button,
  Row,
  Spacer,
} from './styles';
import useConnect from './connect';

const FamilyMembers: FC = () => {
  const {
    addMember,
    familyMembers,
    openAddFamilyMemberModal,
    removeMember,
    upUrl,
  } = useConnect();

  return (
    <Container>
      <SectionHeader
        testId="info-header"
        title="subcription"
        backHref={upUrl}
      />

      <Content>
        <Row>
          <Spacer />
          <Title>Manage Family Members</Title>
          <Spacer>
            <Button onClick={openAddFamilyMemberModal}>
              <span>Add Member</span>
            </Button>
          </Spacer>
        </Row>
        <Members>
          {familyMembers.map(({ email, status }) => (
            <Member
              key={email}
              email={email}
              onDelete={removeMember}
              onResend={addMember}
              status={status}
            />
          ))}
        </Members>
      </Content>
    </Container>
  );
};

export default FamilyMembers;
