import styled from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Display as DefaultDisplay } from 'components/Typography';
import { media } from 'styles/mixins';

export const Container = styled.div``;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  width: 100%;
`;
export const Loader = styled(CircularProgress).attrs(() => ({
  color: 'inherit',
  size: 50,
}))`
  color: ${({ theme }) => theme.colors.fontEmphasized};
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.measures.l};
  min-height: 3rem;
  padding: ${({ theme }) => theme.measures.m} 0;
  position: relative;

  ${media.tabletPortrait} {
    min-height: unset;
    padding: 0 0 ${({ theme }) => theme.measures.m} 0;
  }

  @media (max-height: 500px) {
    padding: 0;
  }
`;

export const Title = styled(DefaultDisplay).attrs(() => ({ $size: 'small' }))``;

export const BackLinkContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
`;

export const BackLink = styled(DefaultLink)`
  color: inherit;
  line-height: 1;
`;

export const Heads = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  margin-left: auto;
  margin-right: auto;
  max-width: 30rem;

  @media (max-height: 500px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const Head = styled(DefaultLink)<{ $isCurrentHead?: boolean }>`
  background: none;
  border-radius: ${({ theme }) => theme.radius.md};
  border: 1px solid
    ${({ theme, $isCurrentHead }) =>
      $isCurrentHead ? theme.colors.fontBase : theme.colors.coreNeutral300};
  cursor: pointer;
  height: 0;
  margin: auto;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  width: 100%;

  @media (max-height: 500px) {
    max-width: 5.125rem;
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.fontEmphasized};
    outline: none;
  }
`;

export const HeadImage = styled.img`
  height: auto;
  padding: 1rem;
  width: 100%;

  @media (max-height: 500px) {
    padding: 0.5rem;
  }
`;
