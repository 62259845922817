import type { FC } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import FullscreenLoader from 'components/FullscreenLoader';
import CookiesNotification from 'components/CookiesNotification';
import SeoTitle from 'components/SeoTitle';
import Guest from 'containers/Guest';
import Main from 'containers/Main';
import useConnect from './connect';

const Routes: FC = () => {
  const { init, isAuthenticated } = useConnect();

  const isLoading = !init;
  const isAuthenticatedUser = init && isAuthenticated;
  const isGuestUser = init && !isAuthenticated;

  return (
    <>
      <AnimatePresence initial={false}>
        <motion.div
          key={init ? 'loaded' : 'loading'}
          initial={false}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {init && <SeoTitle isAuthenticated={isAuthenticated} />}
          {isLoading && <FullscreenLoader />}
          {isAuthenticatedUser && <Main />}
          {isGuestUser && <Guest />}
        </motion.div>
      </AnimatePresence>
      {init && <CookiesNotification />}
    </>
  );
};

export default Routes;
