import { useEffect, useCallback, useRef } from 'react';
import type { MouseEventHandler, KeyboardEventHandler } from 'react';
import useWindowSize from 'lib/useWindowSize';
import type { LogicProps } from './types';

const useLogic = ({
  isMinimized,
  onClose,
  onToggleDisplayStatus,
}: LogicProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null!);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (!isMinimized) {
      document.body.style.overflowY = 'hidden';
      return () => {
        document.body.style.overflowY = 'auto';
      };
    }
  }, [isMinimized]);

  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, []);

  const handleToggleDisplayStatus: MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (onToggleDisplayStatus) onToggleDisplayStatus();
      },
      [onToggleDisplayStatus],
    );

  const handleClose: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (onClose) onClose();
    },
    [onClose],
  );

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (e.key !== 'Tab') return;

      if (modalRef.current) {
        const focusableModalElements = modalRef.current.querySelectorAll(
          'button:not([disabled]), span',
        );

        const lastElement =
          focusableModalElements[focusableModalElements.length - 1];

        if (!e.shiftKey && document.activeElement === lastElement) {
          closeButtonRef.current?.focus();
          return e.preventDefault();
        }

        if (e.shiftKey && document.activeElement === closeButtonRef.current) {
          (lastElement as HTMLElement).focus();
          e.preventDefault();
        }
      }
    },
    [closeButtonRef],
  );

  return {
    closeButtonRef,
    handleClose,
    handleKeyDown,
    handleToggleDisplayStatus,
    modalRef,
    windowHeight: windowSize.height,
  };
};

export default useLogic;
export type UseLogic = ReturnType<typeof useLogic>;
