import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { trackEvent } from 'services/analytics';
import { DAILY_DURATIONS } from 'models/DailyMeditationDuration';
import { Props } from './types';

const useLogic = ({
  id,
  onClose,
  onProfileUpdate,
  selected,
}: {
  id: Props['id'];
  onClose: Props['onClose'];
  onProfileUpdate: Props['onProfileUpdate'];
  selected: Props['selected'];
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const selectOption = useCallback(async () => {
    if (!selected) {
      const mins = DAILY_DURATIONS[id] / 60;
      try {
        await onProfileUpdate({ daily_duration_type: id });
        enqueueSnackbar(`Daily duration has been changed to ${mins} mins`, {
          variant: 'success',
        });
        trackEvent('Setting Daily Duration', { value: mins });
      } catch (error) {
        enqueueSnackbar(
          error?.message || 'Unable to change daily meditation duration',
          { variant: 'error' },
        );
      }
    }
    onClose();
  }, [enqueueSnackbar, id, onClose, onProfileUpdate, selected]);

  return { selectOption };
};

export default useLogic;

export type UseLogic = ReturnType<typeof useLogic>;
