import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 0.9375rem;
  margin: 0 -2.8125rem;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.fontBase};
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.01em;
  line-height: 0.96;
  margin-bottom: 1.625rem;
  text-align: center;
`;

export const ImageContainer = styled.div`
  height: auto;
  margin-bottom: 0.8125rem;
  overflow: hidden;
  width: 100%;
`;

export const Image = styled.img`
  margin: -2.5rem 0 0 0;
  width: 100%;
`;

export const Caption = styled.p`
  color: #cccccc;
  font-family: 'Helvetica';
  font-size: 0.5rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 2px;
  line-height: 1.75;
  text-align: center;
  text-transform: uppercase;
`;
