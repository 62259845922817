import { memo, type FC } from 'react';
import CircularSlider from '@fseehawer/react-circular-slider';
import { basePalette } from 'styles/themes';
import colorWithAlpha from 'lib/color';
import { formatTime } from 'lib/formatTime';
import { Container, TimeElapsed, TimePending } from './styles';
import type { Props } from './types';

const ProgressCircle: FC<Props> = ({
  className,
  duration,
  position,
  width = 312,
}) => {
  const knobPosition =
    duration !== undefined && position !== undefined
      ? (position * 360) / duration
      : undefined;

  const isDurationOverOneHour = !!duration && duration >= 3600;
  const timePending = !duration
    ? `${isDurationOverOneHour ? '–– : ' : ''}–– : ––`
    : formatTime(duration - (position ?? 0), isDurationOverOneHour);
  const timeElapsed =
    position === undefined
      ? `${isDurationOverOneHour ? '–– : ' : ''}–– : ––`
      : formatTime(position, isDurationOverOneHour);

  return (
    <Container
      aria-live="polite"
      aria-atomic="true"
      className={className}
      role="timer"
    >
      <CircularSlider
        dataIndex={knobPosition}
        hideKnob={duration === undefined || position === undefined}
        hideLabelValue
        knobColor={basePalette.coreNeutralAurora}
        knobPosition="top"
        knobSize={24}
        progressColorFrom={basePalette.coreNeutralAurora}
        progressColorTo={basePalette.coreNeutralAurora}
        progressLineCap="flat"
        progressSize={8}
        trackColor={colorWithAlpha({
          color: basePalette.coreNeutralAurora,
          alpha: 0.5,
        })}
        trackSize={8}
        width={width}
      />
      <TimePending aria-label={`${timePending} remaining`}>
        {timePending}
      </TimePending>
      <TimeElapsed aria-label={`${timeElapsed} accomplished`}>
        - {timeElapsed}
      </TimeElapsed>
    </Container>
  );
};

export default memo(ProgressCircle);
