import { apolloClient } from 'services/api';
import { getDailyPermission, normalizeDaily } from 'models/Daily';
import { GetDailyDocument } from '../generated';
import getProfile from './getProfile';
import getSettings from './getSettings';

const getDaily = async () => {
  const { isDailyUnlocked, isSubscribed } = await getProfile();
  const { recentlyPlayed } = await getSettings();

  const { data } = await apolloClient.query({
    query: GetDailyDocument,
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
  });

  if (!data?.daily) {
    return undefined;
  }

  const daily = normalizeDaily(data.daily);
  daily.permission = getDailyPermission(isSubscribed, isDailyUnlocked);

  const progress =
    recentlyPlayed.find((rp) => rp.course_hash === daily.hash)
      ?.last_known_location || 0;
  daily.progress = progress;

  return daily;
};

export default getDaily;
