import { useCallback, useState } from 'react';
import { PlayerSource } from 'models/Player';
import { ModalType } from 'graphql/reactive';
import { cacheUpdateMe } from 'graphql/cache';
import useMe from 'graphql/hooks/useMe';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import useRedeemFreeMonthTrialActions from 'graphql/hooks/useRedeemFreeMonthTrialActions';
import usePlayerActions from 'graphql/hooks/usePlayerActions';

const useConnect = () => {
  const { isOpen, payload } = useModal(ModalType.UNLOCK_FREE_MONTH);
  const { closeModal } = useModalActions();
  const {
    profile: { alreadyRedeemedTrial },
  } = useMe();
  const { isRedeeming, redeemFreeMonthTrial } =
    useRedeemFreeMonthTrialActions();
  const { openCoursePlayer } = usePlayerActions();

  const [errorMessage, setErrorMessage] = useState<string>();

  const courseHash = payload?.courseHash;

  const handleUnlockFreeMonth = useCallback(async () => {
    if (courseHash) {
      try {
        await redeemFreeMonthTrial({
          hash: courseHash,
          source: 'preview share',
        });
        cacheUpdateMe({ already_redeemed_trial: true });
      } catch (error) {
        setErrorMessage(
          error?.message ||
            'Unable to unlock free month trial. Try again in a few minutes.',
        );
      }
    }
  }, [courseHash, redeemFreeMonthTrial]);

  const handlePlayFullCourse = useCallback(() => {
    if (courseHash) {
      closeModal();
      setTimeout(() => {
        openCoursePlayer({
          hash: courseHash,
          source: PlayerSource.MEDIA_PREVIEW,
        });
      }, 500);
    }
  }, [closeModal, courseHash, openCoursePlayer]);

  return {
    alreadyRedeemedTrial,
    closeModal,
    errorMessage,
    handlePlayFullCourse,
    handleUnlockFreeMonth,
    isOpen,
    isRedeeming,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
