import type { FC } from 'react';
import { memo } from 'react';
import { Link, Icon } from './styles';
import type { Props } from './types';

const HomeSectionLink: FC<Props> = ({
  className,
  children,
  showChevron,
  ...props
}) => (
  <Link className={className} {...props}>
    {children}
    {showChevron && <Icon aria-hidden="true" name="caretRightLight" />}
  </Link>
);

export default memo(HomeSectionLink);
