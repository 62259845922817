import styled from 'styled-components';
import DefaultIcon from 'components/Icon';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 1.6rem;
`;

export const Image = styled.img`
  border-radius: 0.2rem;
  display: block;
  height: 4rem;
  margin-right: 0.625rem;
  width: 4rem;
`;

export const Content = styled.div``;

export const Overline = styled.p`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 0.25rem;
`;

export const Icon = styled(DefaultIcon).attrs(() => ({ size: 14 }))`
  margin-right: 0.3rem;
`;

export const Type = styled.span`
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.07em;
  opacity: 0.75;
`;

export const Title = styled.h1`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 1rem;
  line-height: 1.25;
  letter-spacing: 0.01em;
`;

export const Subtitle = styled.p`
  font-size: 0.75rem;
  line-height: 1.4;
  letter-spacing: 0.01em;
  margin-top: 0.25rem;
`;
