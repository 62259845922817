import { memo } from 'react';
import type { FC } from 'react';
import { Button, Container, Text } from './styles';
import useConnect from './connect';

const Resend: FC = () => {
  const { handleResend, status } = useConnect();

  if (status === 'dont-show') return null;

  return (
    <Container>
      {status === 'show-resend' && (
        <Text>
          Didn't receive one?{' '}
          <Button onClick={handleResend}>Resend code</Button>
        </Text>
      )}
      {status === 'show-resent' && <Text>New code sent</Text>}
    </Container>
  );
};

export default memo(Resend);
