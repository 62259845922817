import type { FC } from 'react';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';
import { Item, Text } from './styles';
import type { Props } from './types';

const OptionsMenuItem: FC<Props> = ({
  active,
  href,
  iconName,
  onClick,
  text,
  to,
}) => {
  let component;
  if (href) component = 'a';
  else if (to) component = Link;

  return (
    <Item
      $active={active}
      component={component}
      href={href}
      onClick={onClick}
      to={!href ? to : undefined}
    >
      {iconName && <Icon aria-hidden="true" name={iconName} size={16} />}
      <Text variant="inherit" noWrap>
        {text}
      </Text>
    </Item>
  );
};

export default OptionsMenuItem;
