import { useCallback, type MouseEvent } from 'react';
import { useParams } from 'react-router-dom';
import { PACK_TYPE_NAME } from 'models/Pack';
import { PlayerAutoplayType } from 'models/Player';
import { setOptionsMenuVar } from 'graphql/reactive';
import useCourse from 'graphql/hooks/useCourse';
import useCourseActions from 'graphql/hooks/useCourseActions';

const useConnect = () => {
  const { courseHash } = useParams();
  const { course, loading: isLoading } = useCourse(courseHash);
  const { play } = useCourseActions();

  const {
    displayArtists = [],
    audioLength = 0,
    id = '',
    imageUrl = '',
    isBookmark = false,
    packId = '',
    packSummary = '',
    packType,
    subtitle = '',
    summary = '',
    teachersAndSpeakers = [],
    title = '',
  } = course || {};

  const packTypeName = packType ? PACK_TYPE_NAME[packType] : undefined;

  const handleOpenOptionsMenu = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (id) {
        event.stopPropagation();
        setOptionsMenuVar({
          anchorEl: event.currentTarget,
          id,
          packId,
          type: 'course',
        });
      }
    },
    [id, packId],
  );

  const handleLoadTrack = useCallback(async () => {
    if (id) {
      await play({ courseId: id, autoplayType: PlayerAutoplayType.PACK });
    }
  }, [id, play]);

  return {
    artists: displayArtists,
    audioLength,
    handleLoadTrack,
    handleOpenOptionsMenu,
    imageUrl,
    isBookmark,
    isLoading,
    packSummary,
    packTypeName,
    subtitle,
    summary,
    teachersAndSpeakers,
    title,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
