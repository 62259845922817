import { forwardRef } from 'react';
import type { Ref } from 'react';
import Slide from '@mui/material/Slide';
import type { SlideProps } from '@mui/material/Slide';
import Zoom from '@mui/material/Zoom';
import Fade from '@mui/material/Fade';

const SlideUp = forwardRef((props: SlideProps, ref: Ref<unknown>) => (
  <Slide direction="up" ref={ref} {...props} />
));

const animations = {
  slideUp: SlideUp,
  zoom: Zoom,
  fade: Fade,
};

export default animations;
