import type { FC } from 'react';
import { Props } from './types';

const HouseLineLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.073 2.995a1.375 1.375 0 011.85 0l7.5 6.819a1.374 1.374 0 01.45 1.017v8.794H22.5a.625.625 0 110 1.25h-21a.625.625 0 110-1.25h1.625v-8.794a1.375 1.375 0 01.45-1.017l7.5-6.82zm8.55 7.836v8.794h-4.751v-4.626a1.375 1.375 0 00-1.375-1.375h-3a1.375 1.375 0 00-1.375 1.375v4.626H4.374v-8.794a.125.125 0 01.04-.092l7.5-6.82-.42-.462.42.463a.125.125 0 01.168 0l7.501 6.819a.124.124 0 01.04.092zm-9.251 8.794v-4.626a.125.125 0 01.125-.125h3a.125.125 0 01.125.125v4.626h-3.25z"
      fill="currentColor"
    />
  </svg>
);

export default HouseLineLight;
