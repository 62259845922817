import type { StripeFamilyMemberDataFragment } from 'graphql/generated';

export const normalizeStripeFamilyMember = (
  person: StripeFamilyMemberDataFragment,
) => ({
  email: person.email,
  status: person.status,
});

export type StripeFamilyMember = ReturnType<typeof normalizeStripeFamilyMember>;
