import { makeVar } from '@apollo/client';
import { SelfTimerInterval } from 'models/SelfTimerInterval';

export type SelfTimerPlayer = {
  duration: number;
  interval: SelfTimerInterval;
  isMinimized: boolean;
  startTime: number;
};

export const emptySelfTimerPlayer: SelfTimerPlayer = {
  duration: 0,
  interval: SelfTimerInterval.NONE,
  isMinimized: false,
  startTime: 0,
};

export const selfTimerPlayerVar =
  makeVar<SelfTimerPlayer>(emptySelfTimerPlayer);

export const resetSelfTimerPlayerVar = () => {
  selfTimerPlayerVar(emptySelfTimerPlayer);
};

export const setSelfTimerPlayerVar = (data: Partial<SelfTimerPlayer>) => {
  selfTimerPlayerVar({ ...emptySelfTimerPlayer, ...data });
};

export const updateSelfTimerPlayerVar = (data: Partial<SelfTimerPlayer>) => {
  selfTimerPlayerVar({ ...selfTimerPlayerVar(), ...data });
};
