import styled, { css } from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

export const Container = styled.div<{ $isGoogleLoaded: boolean }>`
  margin-top: 0;
  visibility: ${({ $isGoogleLoaded }) =>
    $isGoogleLoaded ? 'visible' : 'hidden'};
`;

export const ButtonContainer = styled.div`
  position: relative;
`;

export const Button = styled.div<{ $disabled: boolean }>`
  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.7;
      pointer-events: none;
    `}
`;

export const Loader = styled.div`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const LoaderProgress = styled(CircularProgress).attrs(() => ({
  size: 18,
}))`
  &.MuiCircularProgress-root {
    display: block;
  }
`;
