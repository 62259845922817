import { memo } from 'react';
import type { FC } from 'react';
import { LoginStatus } from 'graphql/reactive';
import {
  Button,
  ButtonContainer,
  Container,
  Loader,
  LoaderProgress,
} from './styles';
import type { Props } from './types';
import useConnect from './connect';

const GoogleLogin: FC<Props> = ({ mode }) => {
  const { buttonRef, isGoogleLoaded, loginStatus } = useConnect({ mode });

  const isLoading = loginStatus === LoginStatus.STARTING_GOOGLE_MODE;
  const disabled = loginStatus === LoginStatus.STARTING_PIN_MODE;

  return (
    <Container $isGoogleLoaded={isGoogleLoaded}>
      <ButtonContainer>
        <Button
          $disabled={disabled || isLoading}
          id="googleSignIn"
          ref={buttonRef}
        />
        {isLoading && (
          <Loader>
            <LoaderProgress />
          </Loader>
        )}
      </ButtonContainer>
    </Container>
  );
};

export default memo(GoogleLogin);
