import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import {
  CreatePaymentMethodUpdateCheckoutSessionDocument,
  UpdateStripePaymentMethodDocument,
} from '../generated';
import { cacheSetStripePaymentMethod } from '../cache';

const useStripePaymentMethodActions = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateStripePaymentMethod, { loading: isLoadingUpdate }] = useMutation(
    UpdateStripePaymentMethodDocument,
  );

  const [
    createPaymentMethodUpdateCheckoutSession,
    { loading: isLoadingUpdateCheckoutSession },
  ] = useMutation(CreatePaymentMethodUpdateCheckoutSessionDocument);

  const updateCheckout = useCallback(async () => {
    const stripeCheckoutResponse =
      await createPaymentMethodUpdateCheckoutSession();
    const checkoutSessionURL =
      stripeCheckoutResponse.data?.createPaymentMethodUpdateCheckoutSession;
    window.open(checkoutSessionURL, '_self');
  }, [createPaymentMethodUpdateCheckoutSession]);

  const update = useCallback(
    async (paymentMethodId: string) => {
      try {
        const { data } = await updateStripePaymentMethod({
          variables: { paymentMethodId },
        });

        if (data?.updateStripePaymentMethod) {
          cacheSetStripePaymentMethod(data.updateStripePaymentMethod);

          enqueueSnackbar('Your payment method has been updated', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar('Unable to update your payment method right now', {
            variant: 'error',
          });
        }
      } catch (error) {
        enqueueSnackbar(
          error?.message || 'Unable to update your payment method right now',
          { variant: 'error' },
        );
      }
    },
    [enqueueSnackbar, updateStripePaymentMethod],
  );

  return {
    isUpdating: isLoadingUpdate || isLoadingUpdateCheckoutSession,
    update,
    updateCheckout,
  };
};

export default useStripePaymentMethodActions;
export type UseStripePaymentMethodActions = ReturnType<
  typeof useStripePaymentMethodActions
>;
