import { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const ChevronLeft: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="currentColor"
    height={size}
    viewBox="0 0 12 12"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M7.41 10.58L2.83 6l4.58-4.59L6 0 0 6l6 6z" />
  </svg>
);

export default memo(ChevronLeft);
