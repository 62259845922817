import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { parse } from 'query-string';
import { useApolloClient, useMutation } from '@apollo/client';
import { isProduction } from 'config';
import {
  registerAnalyticsUser,
  trackEvent,
  unregisterAnalyticsUser,
} from 'services/analytics';
import { trackAppsflyerEvent } from 'services/analytics/appsflyer';
import { trackBrazeEvent } from 'services/analytics/braze';
import { trackFacebookEvent } from 'services/analytics/facebook';
import { trackGAEvent } from 'services/analytics/ga';
import {
  deleteToken,
  getToken,
  removeEmail,
  setEmail,
  setToken,
} from 'services/storage';
import {
  GetPinDocument,
  LoginDocument,
  LoginWithGoogleDocument,
  LoginWithMagicLinkDocument,
  LogoutDocument,
  UpdateUserProfileDocument,
  type UserDataFragment,
} from '../generated';
import { cacheSetMe } from '../cache';
import {
  checkMediaInterrupted,
  getAvatars,
  getInitialData,
  getSettings,
  redeemOpenAccess,
} from '../requests';
import {
  initVar,
  resetLoginStatusVar,
  resetPlayerTypeVar,
  setBackgroundSoundsVar,
  setAudioSpeed,
  setShowTrialBannerVar,
  setThemeVar,
} from '../reactive';
import { formatErrorMessage } from '../helpers';
import useModalActions from './useModalActions';

const useAuthActions = () => {
  const { openOnboardingSlidesModal } = useModalActions();
  const apolloClient = useApolloClient();
  const navigate = useNavigate();

  const [updateUserProfileMutation] = useMutation(UpdateUserProfileDocument);

  const [getPinMutation, { loading: isLoadingGetPin }] =
    useMutation(GetPinDocument);

  const [loginMutation, { loading: isLoadingLogin }] =
    useMutation(LoginDocument);

  const [loginWithMagicLinkMutation, { loading: isLoadingLoginWithMagicLink }] =
    useMutation(LoginWithMagicLinkDocument);

  const [loginWithGoogleMutation, { loading: isLoadingLoginWithGoogle }] =
    useMutation(LoginWithGoogleDocument);

  const [logoutMutation, { loading: isLoadingLogout }] = useMutation(
    LogoutDocument,
    {
      update(cache) {
        cache.modify({
          fields: {
            me() {
              deleteToken();
              return null;
            },
          },
        });
      },
    },
  );

  const { return_to } = parse(location.search);
  const returnTo =
    return_to && typeof return_to === 'string' ? return_to : undefined;

  const isNoOnboardingDeepLink = useMemo(
    () =>
      !!returnTo &&
      /^\/(preview|freeMonthTrial|freeMonthTrialSocialShare|course|pack)\//.test(
        returnTo,
      ),
    [returnTo],
  );

  const setInit = useCallback((newInit: boolean) => {
    setTimeout(() => initVar(newInit), newInit ? 500 : 0);
  }, []);

  const getPin = useCallback(
    async ({
      email,
      method = 'email',
      queryString,
    }: {
      email: string;
      method?: 'email' | 'magic_link';
      queryString?: string;
    }) => {
      try {
        await getPinMutation({
          variables: { email, query_string: queryString },
        });
        setEmail(email);
        trackEvent('Login Submit', {
          method,
          credential_type: 'email',
          credential: email,
          private: false,
        });
      } catch (error) {
        throw new Error(formatErrorMessage(error, 'Failed to send code'));
      }
    },
    [getPinMutation],
  );

  const processLogin = useCallback(
    async (
      userData: UserDataFragment,
      method: 'email' | 'gpl' | 'magic_link',
    ) => {
      const token = userData?.token;
      if (!token) {
        throw new Error('No token found');
      }
      setToken(token);

      let user = userData;

      await getInitialData();

      registerAnalyticsUser({ id: user.id, uuid: user.uuid });

      trackEvent('Login Complete', {
        method,
        user_type: user.is_new ? 'new' : 'existing',
      });

      trackGAEvent('event', `login_complete`, {
        event_category: 'signup',
        method,
        user_type: user.is_new ? 'new' : 'existing',
      });

      const {
        backgroundAudioEnabled,
        backgroundAudioProgress,
        backgroundAudioTrackIndex,
        backgroundAudioVolumeWeb,
        hasPresentedHome,
        playbackSpeed,
        theme,
      } = await getSettings();
      setShowTrialBannerVar(hasPresentedHome);
      setBackgroundSoundsVar({
        backgroundAudioEnabled,
        backgroundAudioProgress,
        backgroundAudioTrackIndex,
        backgroundAudioVolume:
          backgroundAudioVolumeWeb > 1
            ? backgroundAudioVolumeWeb / 100
            : backgroundAudioVolumeWeb,
      });
      setAudioSpeed(Number(playbackSpeed));

      if (theme) {
        setThemeVar(theme);
      }

      if (
        !isProduction &&
        user.is_eligible_for_open_access &&
        !user.is_subscribed
      ) {
        const response = await redeemOpenAccess();
        if (response) {
          user = response;
        }
      }

      if (user.is_new) {
        trackFacebookEvent('track', 'CompleteRegistration');
        trackBrazeEvent('Registration Success');
        trackAppsflyerEvent('pba', 'event', {
          eventType: 'EVENT',
          eventName: 'af_complete_registration',
        });

        if (!isNoOnboardingDeepLink && !hasPresentedHome) {
          trackEvent('Onboard Home');

          openOnboardingSlidesModal();
        }
      }

      cacheSetMe(user);

      await checkMediaInterrupted();

      if (!user.avatar?.id) {
        const avatars = await getAvatars();
        const imageId = avatars[0]?.id;
        if (imageId) {
          await updateUserProfileMutation({
            variables: { avatar_id: imageId },
          });
        }
      }
    },
    [
      isNoOnboardingDeepLink,
      openOnboardingSlidesModal,
      updateUserProfileMutation,
    ],
  );

  const loginWithPin = useCallback(
    async (email: string, pin: string) => {
      try {
        if (!email) {
          throw new Error('No email available');
        }

        const { data } = await loginMutation({
          variables: { email, pin },
        });
        const userData = data?.login;

        if (userData) {
          await processLogin(userData, 'email');
          removeEmail();
        } else {
          throw new Error('No user data found');
        }
      } catch (error) {
        throw new Error(formatErrorMessage(error, 'Failed to validate code'));
      }
    },
    [loginMutation, processLogin],
  );

  const loginWithGoogle = useCallback(
    async (tokenId: string) => {
      try {
        const { data } = await loginWithGoogleMutation({
          variables: { id_token: tokenId },
        });
        const userData = data?.googleLogin?.user;

        if (userData) {
          await processLogin(userData, 'gpl');
        } else {
          throw new Error('No user data found');
        }
      } catch (error) {
        throw new Error(formatErrorMessage(error));
      }
    },
    [loginWithGoogleMutation, processLogin],
  );

  const loginWithMagicLink = useCallback(
    async (magicLink: string) => {
      try {
        if (!magicLink) {
          throw new Error('No magic link available');
        }

        const { data } = await loginWithMagicLinkMutation({
          variables: { magicLink },
        });
        const userData = data?.login;

        if (userData) {
          trackEvent('Login Magic Link Accepted');

          await processLogin(userData, 'magic_link');
          removeEmail();
        } else {
          throw new Error('No user data found');
        }
      } catch (error) {
        trackEvent('Login Magic Link Declined');

        throw new Error(
          formatErrorMessage(error, 'Failed to validate magic link'),
        );
      }
    },
    [loginWithMagicLinkMutation, processLogin],
  );

  const resend = useCallback(
    async ({ email, queryString }: { email: string; queryString?: string }) => {
      try {
        await getPinMutation({
          variables: { email, query_string: queryString },
        });
      } catch (error) {
        throw new Error(formatErrorMessage(error, 'Failed to resend code'));
      }
    },
    [getPinMutation],
  );

  const logout = useCallback(
    async (type?: string) => {
      const storedToken = getToken();
      if (storedToken) {
        try {
          setInit(false);
          await logoutMutation({ variables: { device_token: storedToken! } });
          await apolloClient.cache.reset();
          if (type === 'checkout') {
            navigate('/checkout', { replace: true });
          } else {
            navigate('/', { replace: true });
          }
          setThemeVar('light');
          unregisterAnalyticsUser();
          resetLoginStatusVar();
          resetPlayerTypeVar();
          setInit(true);

          trackEvent('Logout');
        } catch (error) {
          setInit(true);
          throw new Error(formatErrorMessage(error, 'Failed to logout user'));
        }
      }
    },
    [logoutMutation, navigate, setInit, apolloClient],
  );

  return {
    getPin,
    isLoadingGetPin,
    isLoadingLogin,
    isLoadingLoginWithGoogle,
    isLoadingLoginWithMagicLink,
    isLoadingLogout,
    loginWithGoogle,
    loginWithMagicLink,
    loginWithPin,
    logout,
    processLogin,
    resend,
    setInit,
  };
};

export default useAuthActions;
export type UseAuthActions = ReturnType<typeof useAuthActions>;
