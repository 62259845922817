import styled from 'styled-components';
import { MenuItem, Typography } from '@mui/material';

export const Item = styled(MenuItem)<{ $active?: boolean }>`
  &.MuiMenuItem-root {
    min-height: 2rem;
    padding: 0.5rem 1.25rem;

    ${({ $active, theme }) =>
      $active &&
      `color: ${
        theme.name === 'LIGHT'
          ? theme.colors.fontEmphasized
          : theme.colors.coreTeal500
      }`}
  }
`;

export const Dot = styled.p`
  align-items: center;
  display: flex;
  height: 1.25rem;
  width: 0.5625rem;
`;

export const Text = styled(Typography)`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: 0.01em;
  line-height: 1.4286;

  &.MuiTypography-root {
    margin: 0 0.5rem;
  }
`;
