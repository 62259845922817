import { memo, useCallback, type FC } from 'react';
import { PersonType } from 'graphql/generated';
import { setPersonDetails } from 'graphql/reactive';
import { Bio, Container, Icon, Name, PersonPage, Teacher } from './styles';
import type { Props } from './types';

const Teachers: FC<Props> = ({ hideLinks, teachers }) => {
  const sortedTeachers = [...teachers].sort((a, b) => {
    if (a.type === PersonType.speaker && b.type === PersonType.teacher) {
      return -1;
    }
    if (a.type === PersonType.teacher && b.type === PersonType.speaker) {
      return 1;
    }
    return 0;
  });

  const handleOpenPersonInfo = useCallback((personId: string) => {
    setPersonDetails({ personId });
  }, []);

  return (
    <Container>
      {sortedTeachers.map(({ bio, id, name, type }) => (
        <Teacher key={id}>
          <Name>{name}</Name>
          <Bio dangerouslySetInnerHTML={{ __html: bio }} />
          {!hideLinks && type === PersonType.speaker && (
            <PersonPage onClick={() => handleOpenPersonInfo(id)}>
              See all {name} Content <Icon name="arrowRightLight" />
            </PersonPage>
          )}
        </Teacher>
      ))}
    </Container>
  );
};

export default memo(Teachers);
