import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import isSameDay from 'date-fns/isSameDay';

const formatRetreatDate = (startDateString: string, endDateString: string) => {
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);
  if (!isValid(startDate) || !isValid(endDate)) {
    return '';
  }

  const startDateFormatted = format(startDate, 'MMM d · ha');
  if (isSameDay(endDate, startDate)) {
    const endHourFormatted = format(endDate, 'ha (O)');
    return `${startDateFormatted}-${endHourFormatted}`;
  }
  const endDateFormatted = format(endDate, 'MMM d · ha (O)');
  return `${startDateFormatted} - ${endDateFormatted}`;
};

export default formatRetreatDate;
