import { memo, type FC } from 'react';
import { Button } from './styles';
import type { Props } from './types';
import useLogic from './logic';

const StripePlan: FC<Props> = ({
  amount,
  hasMonthlySubscription,
  hasYearlySubscription,
  id,
  interval,
  isEligibleForYearlyUpgradeWithDiscount,
  isLoading = false,
  onCreate,
  sku,
  type,
}) => {
  const { buttonText, handleClick, highlight } = useLogic({
    amount,
    hasMonthlySubscription,
    hasYearlySubscription,
    id,
    interval,
    isEligibleForYearlyUpgradeWithDiscount,
    onCreate,
    sku,
    type,
  });

  return (
    <Button
      $highlight={highlight}
      disabled={isLoading}
      id={interval ? `subscription-purchase-button-${interval}` : undefined}
      isLoading={isLoading}
      onClick={handleClick}
    >
      {buttonText}
    </Button>
  );
};

export default memo(StripePlan);
