import { useMemo } from 'react';
import { useQuery, type QueryHookOptions } from '@apollo/client';
import { normalizeUser } from 'models/User';
import { MeDocument, type MeQuery, type MeQueryVariables } from '../generated';

const useMe = (options: QueryHookOptions<MeQuery, MeQueryVariables> = {}) => {
  const { data, loading } = useQuery(MeDocument, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  const {
    id,
    introPackId,
    isAllContentUnlocked,
    isDailyUnlocked,
    isElegibleForSharing,
    isEligibleForYearlyUpgradeWithDiscount,
    isSubscribed,
    notifications,
    profile,
    showOpenAccessExpiredBanner,
  } = useMemo(() => {
    const user = normalizeUser(data?.me);

    return {
      id: user.id,
      introPackId: user.introPackId,
      isAllContentUnlocked: user.isAllContentUnlocked,
      isDailyUnlocked: user.isDailyUnlocked,
      isElegibleForSharing: user.subscriptionIsElegibleForSharing,
      isEligibleForYearlyUpgradeWithDiscount:
        user.subscriptionIsEligibleForYearlyUpgradeWithDiscount,
      isSubscribed: user.isSubscribed,
      notifications: user.notifications,
      profile: user,
      showOpenAccessExpiredBanner: user.showOpenAccessExpiredBanner,
    };
  }, [data]);

  return {
    id,
    introPackId,
    isAllContentUnlocked,
    isDailyUnlocked,
    isElegibleForSharing,
    isEligibleForYearlyUpgradeWithDiscount,
    isSubscribed,
    loading,
    notifications,
    profile,
    showOpenAccessExpiredBanner,
  };
};

export default useMe;
export type UseMe = ReturnType<typeof useMe>;
