import styled from 'styled-components';
import { media } from 'styles/mixins';
import SectionHeader from 'components/DeprecatedSectionHeader';

export const Header = styled(SectionHeader)`
  ${media.tabletPortrait} {
    display: none;
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 1.6rem 0;
`;
