import type { FC } from 'react';
import { connectHits } from 'react-instantsearch-dom';
import { kebabCase } from 'lodash';
import CourseList from 'components/CourseList';
import CardListItem from 'components/CardListItem';
import Tab from './Tab';
import TopSearches from '../../TopSearches';
import { Container, NavTabList, Packs } from './styles';
import useConnect from './connect';
import { Props, Result, TabType } from './types';

const Hits: FC<Props> = ({ hits, onClick }) => {
  const {
    filteredConversations,
    filteredCourses,
    filteredPacks,
    handleClick,
    handleOpenOptionsMenu,
    handlePlayCourse,
    isLoading,
    selectedTab,
    types,
  } = useConnect({
    hits,
  });

  if (isLoading) {
    return <TopSearches onClick={onClick} />;
  }

  return (
    <Container role="region">
      {selectedTab && (
        <NavTabList>
          {types.map(([type, v]) => (
            <Tab
              key={type}
              onClick={handleClick}
              selectedTab={selectedTab}
              tabName={`${type}(${v})`}
              tabValue={type}
            />
          ))}
        </NavTabList>
      )}
      {selectedTab === TabType.SESSIONS && filteredCourses.length > 0 && (
        <CourseList
          ariaLabelledby={`${kebabCase(selectedTab)}-tab`}
          courses={filteredCourses}
          displayCourseImages
          hideTopBorder
          id={`${kebabCase(selectedTab)}-panel`}
          isLoading={isLoading}
          onOpenOptionsMenu={handleOpenOptionsMenu}
          role="tabpanel"
          setTrack={handlePlayCourse}
        />
      )}
      {selectedTab === TabType.CONVERSATIONS &&
        filteredConversations.length > 0 && (
          <CourseList
            ariaLabelledby={`${kebabCase(selectedTab)}-tab`}
            courses={filteredConversations}
            displayCourseImages
            hideTopBorder
            id={`${kebabCase(selectedTab)}-panel`}
            isLoading={isLoading}
            onOpenOptionsMenu={handleOpenOptionsMenu}
            role="tabpanel"
            setTrack={handlePlayCourse}
          />
        )}
      {selectedTab === TabType.SERIES && filteredPacks.length > 0 && (
        <Packs
          aria-labelledby={`${kebabCase(selectedTab)}-tab`}
          id={`${kebabCase(selectedTab)}-panel`}
          role="tabpanel"
        >
          {filteredPacks.map((pack) => {
            let subtitle = '';
            if (pack.displayContentCount && pack.nSubpacks) {
              subtitle = `${pack.nSubpacks} Series`;
            } else if (pack.displayBylines) {
              subtitle = pack.subtitle;
            }

            return (
              <CardListItem
                key={pack.id}
                imageUrl={
                  pack?.imageThumbnailsThumbX1 ||
                  pack?.imageThumbnailsThumbX2 ||
                  pack?.imageUrl
                }
                subtitle={subtitle}
                title={pack.title}
                to={`/pack/${pack.hash}`}
              />
            );
          })}
        </Packs>
      )}
    </Container>
  );
};

export default connectHits<Props, Result>(Hits);
