import type { FC } from 'react';
import kebabCase from 'lodash/kebabCase';
import { PackCategory } from 'graphql/generated';
import NavTab from 'components/NavTab';
import PacksList from 'components/PacksList';
import CourseList from 'components/CourseList';
import Loader from './Loader';
import {
  CardPrimary,
  Icon,
  NavTabList,
  OptionsMenuButton,
  SectionDescription,
  SectionHeader,
} from './styles';
import useConnect from './connect';

const TheoryPage: FC = () => {
  const {
    currentUrl,
    handleOpenCourseOptionsMenu,
    handleOpenPackOptionsMenu,
    handlePlayCourse,
    isLoading,
    isSubpack,
    packs,
    pathname,
    selectedPackCategory,
    selectedPackCourses,
    selectedPackDescription,
    selectedPackDisplayCourseImages,
    selectedPackId,
    selectedPackImageUrl,
    selectedPackParentPackId,
    selectedPackSubpacks,
    selectedPackSubtitle,
    selectedPackTitle,
    titleDescription,
  } = useConnect();

  const isMasterPack = !selectedPackParentPackId;
  const isConversations = selectedPackCategory === PackCategory.conversations;
  const isQA = selectedPackCategory === PackCategory.qa;
  const isQAMasterPack = isQA && !selectedPackParentPackId;

  const showSubpacks = selectedPackSubpacks && selectedPackSubpacks.length > 0;
  const showCoursesList = selectedPackCourses && selectedPackCourses.length > 0;
  const showCardPrimary = !isLoading && (isSubpack || isQA);
  const showLoader = isLoading && !showSubpacks && !showCoursesList;
  const showOptions =
    showCoursesList &&
    !isMasterPack &&
    selectedPackId &&
    !isQAMasterPack &&
    !isConversations;

  const ariaLabelledby = selectedPackTitle
    ? `${kebabCase(selectedPackTitle)}-tab`
    : '';
  const listId = selectedPackTitle
    ? `${kebabCase(selectedPackTitle)}-panel`
    : '';

  return (
    <>
      <SectionHeader
        showBackLink={isSubpack}
        title="Theory"
        titleIconName="bookOpenLight"
      >
        {showOptions && (
          <OptionsMenuButton
            aria-label="open series options"
            onClick={handleOpenPackOptionsMenu}
          >
            <Icon aria-hidden="true" name="threeDotsVerticalLight" size={18} />
          </OptionsMenuButton>
        )}
      </SectionHeader>
      {!isSubpack && (
        <SectionDescription>{titleDescription}</SectionDescription>
      )}
      {!isSubpack && !showLoader && (
        <NavTabList>
          {packs.map(({ id, title }) => (
            <NavTab
              key={id}
              aria-controls={`${kebabCase(title)}-panel`}
              id={`${kebabCase(title)}-tab`}
              isActive={pathname.includes(kebabCase(title))}
              to={`/theory/${kebabCase(title)}`}
            >
              {title}
            </NavTab>
          ))}
        </NavTabList>
      )}
      {showLoader && <Loader />}
      {showCardPrimary && (
        <CardPrimary
          $hasTabs={isQAMasterPack}
          boldSubtitle
          description={selectedPackDescription}
          imageUrl={selectedPackImageUrl}
          key={selectedPackId}
          subtitle={selectedPackSubtitle}
          title={selectedPackTitle}
        />
      )}
      {showCoursesList && (
        <CourseList
          ariaLabelledby={ariaLabelledby}
          courses={selectedPackCourses}
          currentUrl={currentUrl}
          displayCourseImages={selectedPackDisplayCourseImages}
          hideOptionsMenu={isConversations}
          hideTopBorder={isConversations}
          id={listId}
          isLoading={isLoading}
          linkCourses={isConversations}
          onOpenOptionsMenu={handleOpenCourseOptionsMenu}
          packId={selectedPackId}
          role={!isSubpack && !showLoader ? 'tabpanel' : undefined}
          setTrack={isConversations ? undefined : handlePlayCourse}
          showProgress
        />
      )}
      {showSubpacks && (
        <PacksList
          ariaLabelledby={isMasterPack || isQA ? ariaLabelledby : undefined}
          currentUrl={currentUrl}
          hideImages={isQA}
          hideTopBorder={(isMasterPack && !isQA) || showCoursesList}
          id={listId}
          isLoading={isLoading}
          packs={selectedPackSubpacks}
          role={isMasterPack || isQA ? 'tabpanel' : undefined}
          showDescriptions={isQA}
        />
      )}
    </>
  );
};

export default TheoryPage;
