/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const LIVE_AUDIO_DATA_FRAGMENT = gql`
  fragment LiveAudioData on LiveAudio {
    active
    details
    id
    live
    live_end_date
    live_start_date
    start_date
    subtitle
    title
    url
  }
`;
