import styled from 'styled-components';
import { media } from 'styles/mixins';
import { motion } from 'framer-motion';
import DefaultLink from 'components/Link';

export const Container = styled.div<{ $isPublic: boolean }>`
  align-items: flex-start;
  display: ${({ $isPublic }) => ($isPublic ? 'flex' : 'block')};
  flex: 1 1 auto;
  margin-top: 1rem;

  ${media.tabletLandscape} {
    margin-top: 0;
  }

  ${media.tabletPortrait} {
    display: block;
  }
`;

export const Expired = styled.div`
  line-height: 1.4;
  margin-top: 1.25rem;
  width: 80%;
`;

export const ExpiredTitle = styled.div`
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: 1rem;
`;

export const ExpiredText = styled.div`
  font-size: 1rem;
  margin-top: 0.8rem;
`;

export const StepContent = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.25 },
}))``;

export const LinkButton = styled(DefaultLink)`
  &&& {
    background-color: #2565f5;
    border: none;
    border-radius: 0.25rem;
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.coreNeutralAurora};
    cursor: pointer;
    display: inline-block;
    font-size: 1.0625rem;
    margin: 1.125rem 0;
    outline: none;
    padding: 0.75rem 2.25rem;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    width: auto;

    ${media.phonePortrait} {
      width: 100%;
    }
  }
`;
