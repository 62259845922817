import { useMemo } from 'react';
import { useQuery, type QueryHookOptions } from '@apollo/client';
import { normalizeStripePaymentMethod } from 'models/StripePaymentMethod';
import {
  GetStripePaymentMethodDocument,
  type GetStripePaymentMethodQuery,
  type GetStripePaymentMethodQueryVariables,
} from '../generated';

const useStripePaymentMethod = (
  options: QueryHookOptions<
    GetStripePaymentMethodQuery,
    GetStripePaymentMethodQueryVariables
  > = {},
) => {
  const { data, loading } = useQuery(GetStripePaymentMethodDocument, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  const stripePaymentMethod = useMemo(
    () =>
      data?.getStripePaymentMethod
        ? normalizeStripePaymentMethod(data.getStripePaymentMethod)
        : undefined,
    [data],
  );

  return {
    loading,
    stripePaymentMethod,
  };
};

export default useStripePaymentMethod;
export type UseStripePaymentMethod = ReturnType<typeof useStripePaymentMethod>;
