import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { ZIndex } from 'styles/metrics';
import LogoBase from 'components/LogoBlackStacked';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: ${ZIndex.FULLSCREEN_LOADER};
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Progress = styled(CircularProgress).attrs(() => ({
  size: 40,
}))``;

export const Logo = styled(LogoBase).attrs(() => ({ size: 250 }))`
  margin-bottom: 2rem;
`;
