import { memo } from 'react';
import type { FC } from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { DailyPermission } from 'models/Daily';
import CardListItem from 'components/CardListItem';
import useLogic from './logic';
import type { Props } from './types';

const Daily: FC<Props> = ({
  date,
  duration,
  hash,
  onClick,
  onOpenOptionsMenu,
  permission,
  progress,
}) => {
  const { handleClick, handleOpenOptionsMenu } = useLogic({
    hash,
    onClick,
    onOpenOptionsMenu,
  });

  const isForbidden = permission === DailyPermission.FORBIDDEN;
  const isLocked = permission === DailyPermission.LOCKED;

  return (
    <CardListItem
      isForbidden={isForbidden || isLocked}
      onClick={isForbidden ? undefined : handleClick}
      progress={progress}
      title="Daily"
      subtitle={format(parseISO(date), 'MMMM d, yyyy')}
      to={isForbidden ? '/account/Subscription' : undefined}
      total={duration}
      type="course"
      isBookmark
      onOpenOptions={handleOpenOptionsMenu}
    />
  );
};

export default memo(Daily);
