const generateHex = (n?: number) => {
  let count = n || 16;
  let result = '';

  while (count) {
    result += Math.floor(Math.random() * 16)
      .toString(16)
      .toUpperCase();
    count -= 1;
  }

  return result;
};

export default generateHex;
