import { memo, type FC } from 'react';
import Attribution from './Attribution';
import {
  Buttons,
  Container,
  Icon,
  QRCode,
  QRCodeContainer,
  QRCodeText,
  StoreLink,
} from './styles';
import type { Props } from './types';

const AppsDownload: FC<Props> = ({
  buttonsMode = 'all',
  buttonTextAndroid,
  buttonTextIos,
  className,
  mode = 'buttons',
  onClick,
  qrCodeId,
  ...appsflyerProps
}) => {
  return (
    <Attribution qrCodeId={qrCodeId} {...appsflyerProps}>
      {({ android, ios, isAndroid, isDesktop, isIOS, queryString }) => {
        const showQRCode =
          ['qr', 'all'].includes(mode) && !!qrCodeId && isDesktop;
        const showIos =
          ['buttons', 'all'].includes(mode) &&
          (isDesktop ||
            buttonsMode === 'all' ||
            (buttonsMode === 'platform' && isIOS));
        const showAndroid =
          ['buttons', 'all'].includes(mode) &&
          (isDesktop ||
            buttonsMode === 'all' ||
            (buttonsMode === 'platform' && isAndroid));

        return (
          <Container className={className}>
            {showQRCode && (
              <QRCodeContainer>
                <QRCode aria-label="QR code to open on store" id={qrCodeId} />
                <QRCodeText>
                  <Icon aria-hidden="true" name="cameraLight" />
                  Scan me
                </QRCodeText>
              </QRCodeContainer>
            )}
            {(showIos || showAndroid) && (
              <Buttons>
                {showIos && (
                  <StoreLink
                    aria-label="Download on App Store (opens in a new tab)"
                    href={ios}
                    onClick={onClick}
                    queryString={queryString}
                    store="apple"
                    text={buttonTextIos}
                  />
                )}
                {showAndroid && (
                  <StoreLink
                    aria-label="Download on Google Play (opens in a new tab)"
                    href={android}
                    onClick={onClick}
                    queryString={queryString}
                    store="google"
                    text={buttonTextAndroid}
                  />
                )}
              </Buttons>
            )}
          </Container>
        );
      }}
    </Attribution>
  );
};

export default memo(AppsDownload);
