import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Container = styled.ul`
  display: flex;
  justify-content: center;
`;

export const Dot = styled.li`
  display: block;
  list-style-type: none;
  margin-left: 1rem;

  &:first-child {
    margin-left: 0;
  }

  ${media.phoneLandscape} {
    margin-left: 0.5rem;
  }
`;
