import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Container = styled.div`
  flex: 0 0 auto;
  margin-right: 5rem;
  position: relative;

  ${media.tabletLandscape} {
    margin-right: 4rem;
  }

  ${media.tabletPortrait} {
    margin-right: 0;
  }
`;

export const Image = styled.img<{ $isLoading: boolean }>`
  border-radius: 0.5625rem;
  box-shadow: ${({ theme }) => theme.elevation.web3};
  height: 32.56rem;
  object-fit: cover;
  visibility: ${({ $isLoading }) => ($isLoading ? 'hidden' : 'visible')};
  width: 22.0794rem;

  ${media.tabletPortrait} {
    height: auto;
    width: 100%;
  }
`;

export const Caption = styled.p`
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.2em;
  line-height: 1.33;
  margin-top: 1rem;
  opacity: 0.2;
  text-transform: uppercase;

  ${media.phonePortrait} {
    font-size: 0.65rem;
    margin-top: 0.6rem;
  }
`;
