import { Course, CoursePermission, CourseType } from 'models/Course';
import { PlayerAutoplayType } from 'models/Player';
import { coursePlayerVar } from '../reactive';
import getSettings from './getSettings';
import getCoursePack from './getCoursePack';
import getRecentlyPlayed from './getRecentlyPlayed';
import getBookmarks from './getBookmarks';

const getCourseAutoplayTrackList = async () => {
  const { autoplay } = await getSettings();
  const { autoplayType, hash, packId } = coursePlayerVar();

  if (!autoplay || !autoplayType || !hash) {
    return undefined;
  }

  let autoplayTrackList: Course[] | null = null;
  if (autoplayType === PlayerAutoplayType.PACK) {
    const pack = await getCoursePack(hash, packId);
    if (pack && pack.autoPlay) {
      autoplayTrackList = pack.courses;
    }
  } else if (autoplayType === PlayerAutoplayType.BOOKMARKS_THEORY) {
    const { courses } = await getBookmarks();
    autoplayTrackList = courses.filter(
      ({ courseType }) => courseType === CourseType.LESSON,
    );
  } else if (autoplayType === PlayerAutoplayType.BOOKMARKS_PRACTICE) {
    const { courses } = await getBookmarks();
    autoplayTrackList = courses.filter(
      ({ courseType }) => courseType === CourseType.MEDITATION,
    );
  } else if (autoplayType === PlayerAutoplayType.RECENTLY_PLAYED_PRACTICE) {
    autoplayTrackList = await getRecentlyPlayed(CourseType.MEDITATION);
  } else if (autoplayType === PlayerAutoplayType.RECENTLY_PLAYED_THEORY) {
    autoplayTrackList = await getRecentlyPlayed(CourseType.LESSON);
  }

  const unlockedAutoplayTrackList = autoplayTrackList?.filter(
    (course) => course.permission === CoursePermission.UNLOCKED,
  );

  return unlockedAutoplayTrackList && unlockedAutoplayTrackList.length > 0
    ? unlockedAutoplayTrackList.map((course) => course.id)
    : undefined;
};

export default getCourseAutoplayTrackList;
