import { memo } from 'react';
import type { FC } from 'react';
import { formatDurationShort } from 'lib/formatTime';
import useCountdown from 'lib/useCountdown';
import { Overline } from '../styles';
import type { Props } from './types';

const Countdown: FC<Props> = ({ startDate }) => {
  const { countdown } = useCountdown(startDate);

  return (
    <Overline>
      {countdown <= 0
        ? 'Happening Now'
        : `Begins in ${formatDurationShort(countdown / 1000)}`}{' '}
    </Overline>
  );
};

export default memo(Countdown);
