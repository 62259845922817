import { memo, useCallback } from 'react';
import type { FC } from 'react';
import { useField } from 'formik';
import addYears from 'date-fns/addYears';
import type { TextFieldProps } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { Container, Content, Error, Icon, TextField } from './styles';
import type { Props } from './types';

const OpenPickerIcon = () => (
  <Icon aria-hidden="true" name="calendarBlankLight" />
);

const DatePicker: FC<Props> = ({
  className,
  id,
  label,
  maxDate = addYears(new Date(), 2),
  minDate = new Date(),
  name,
  readOnly = false,
}) => {
  const [{ value }, { touched, error }, { setValue }] = useField(name);

  const handleChange = useCallback(
    (date: Date | null) => {
      setValue(date);
    },
    [setValue],
  );

  return (
    <Container className={className}>
      <Content $readOnly={readOnly}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiDatePicker
            components={{ OpenPickerIcon }}
            disableOpenPicker={false}
            inputFormat="MM/dd/yyyy"
            label={label}
            maxDate={maxDate}
            minDate={minDate}
            onChange={handleChange}
            readOnly={readOnly}
            renderInput={(params: TextFieldProps) => (
              <TextField {...params} variant="filled" id={id} />
            )}
            value={value}
          />
        </LocalizationProvider>
      </Content>
      {touched && error && <Error>{error}</Error>}
    </Container>
  );
};

export default memo(DatePicker);
