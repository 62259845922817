import { useCallback } from 'react';
import type { FC } from 'react';
import { Icon, TagButton } from './styles';
import type { Props } from './types';

const Tag: FC<Props> = ({ onClick, value }) => {
  const handleClick = useCallback(
    () => onClick({ query: value }),
    [onClick, value],
  );

  return (
    <TagButton onClick={handleClick}>
      <Icon name="search" />
      {value}
    </TagButton>
  );
};

export default Tag;
