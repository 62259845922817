import styled from 'styled-components';
import { media } from 'styles/mixins';
import { Body as DefaultBody } from 'components/Typography';
import DefaultCarousel from 'components/Carousel';
import DefaultHomeSectionHeader from 'components/HomeSectionHeader';

export const Container = styled.div`
  margin-bottom: 1rem;
  position: relative;

  ${media.tabletPortrait} {
    margin-bottom: 0.8rem;
  }
`;

export const HomeSectionHeader = styled(DefaultHomeSectionHeader)`
  padding-right: 1.6rem;

  ${media.tabletPortrait} {
    padding-right: 0;
  }
`;

export const Carousel = styled(DefaultCarousel)`
  margin-top: 1rem;
`;

export const EmptyState = styled(DefaultBody).attrs(() => ({
  $size: 'medium',
  $weight: 'regular',
}))`
  color: ${({ theme }) =>
    theme.name === 'LIGHT'
      ? theme.colors.coreNeutral500
      : theme.colors.coreNeutral100};
  padding-bottom: ${({ theme }) => theme.measures.x};
  padding-top: ${({ theme }) => theme.measures.xxs};
`;

export const FadeOut = styled.div`
  background: linear-gradient(
    270deg,
    ${({ theme }) => theme.colors.backgroundBase} 0%,
    rgba(244, 244, 244, 0) 100%
  );
  height: 100%;
  right: 0;
  position: absolute;
  top: 0;
  width: ${({ theme }) => theme.measures.l};

  ${media.tabletPortrait} {
    display: none;
  }
`;
