import { memo } from 'react';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import CourseLinearProgress from './CourseLinearProgress';
import PackLinearProgress from './PackLinearProgress';
import {
  Container,
  Content,
  Description,
  Dot,
  ExpandButton,
  ExpandContainer,
  ExpandIcon,
  ExpandLabel,
  Highlight,
  Icon,
  IconsContainer,
  Image,
  Info,
  OptionsButton,
  Pretitle,
  PreTitleContent,
  PreTitleIcon,
  ProgressContainer,
  RightIcon,
  Subtitle,
  ThreeDotsIcon,
  Title,
  TitleContent,
  TitleIcon,
} from './styles';
import type { Props } from './types';
import useLogic from './logic';

const CardListItem: FC<Props> = ({
  className,
  courseStatus,
  description,
  displayContentCount = true,
  highlight,
  id,
  imageAlt = '',
  imageUrl,
  isBookmark,
  isForbidden,
  isHighlighted,
  nSubpacks,
  onClick,
  onOpenOptions,
  onTextExpand,
  pretitle,
  pretitleIcon,
  progress,
  replace,
  rightIcon,
  state,
  style,
  subtitle,
  title,
  titleIcon,
  to,
  total,
  type,
}) => {
  const {
    descriptionValue,
    handleKeyPress,
    handleKeyPressOptions,
    handleToggleDescription,
    isCollapsable,
    isCollapsed,
  } = useLogic({ description, onClick, onOpenOptions, onTextExpand });

  return (
    <Container
      $isHighlighted={isHighlighted}
      aria-label={to || onClick ? `Go to ${title}` : undefined}
      as={to ? Link : 'div'}
      className={className}
      id={id}
      onClick={onClick}
      onKeyPress={!to && onClick ? handleKeyPress : undefined}
      replace={replace}
      role={!to && onClick ? 'button' : undefined}
      state={state}
      style={style}
      tabIndex={0}
      to={to}
    >
      {rightIcon && <RightIcon name={rightIcon} />}
      {imageUrl && (
        <Image
          $reduceOpacity={isForbidden}
          alt={imageAlt}
          hideLoader
          src={imageUrl}
        />
      )}
      <Content>
        <Info
          $hasMenu={onOpenOptions !== undefined}
          $reduceOpacity={isForbidden}
        >
          {highlight && <Highlight>{highlight}</Highlight>}
          {pretitle && (
            <PreTitleContent>
              {pretitleIcon && <PreTitleIcon name={pretitleIcon} />}
              <Pretitle>{pretitle}</Pretitle>
            </PreTitleContent>
          )}
          <TitleContent>
            {titleIcon && <TitleIcon name={titleIcon} />}
            <Title>{title}</Title>
          </TitleContent>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          {descriptionValue && <Description>{descriptionValue}</Description>}
        </Info>
        {onOpenOptions !== undefined && (
          <OptionsButton
            aria-label="Open options"
            onClick={onOpenOptions}
            onKeyPress={handleKeyPressOptions}
          >
            <ThreeDotsIcon aria-hidden="true" />
          </OptionsButton>
        )}
        {displayContentCount && type && type !== 'retreat' && (
          <ProgressContainer $reduceOpacity={isForbidden}>
            {type === 'course' ? (
              <CourseLinearProgress
                courseStatus={courseStatus}
                progress={progress}
                total={total}
              />
            ) : (
              <PackLinearProgress
                nSubpacks={nSubpacks}
                progress={progress}
                total={total}
              />
            )}
            {(isBookmark || isForbidden) && (
              <IconsContainer>
                <Dot>·</Dot>
                {isBookmark && (
                  <Icon aria-hidden="true" name="bookmarkSingleActive" />
                )}
                {isForbidden && (
                  <Icon aria-hidden="true" name="lockSimpleFilled" />
                )}
              </IconsContainer>
            )}
          </ProgressContainer>
        )}
        {isCollapsable && (
          <ExpandContainer>
            <ExpandButton
              $neutralColor={isForbidden}
              onClick={handleToggleDescription}
            >
              <ExpandLabel>
                {isCollapsed ? 'Read more ' : 'Read less'}
              </ExpandLabel>
              <ExpandIcon $rotate={!isCollapsed} />
            </ExpandButton>
          </ExpandContainer>
        )}
      </Content>
    </Container>
  );
};

export default memo(CardListItem);
