import { useRef, useCallback } from 'react';

const useCopyInputContent = () => {
  const ref = useRef<HTMLTextAreaElement>(null);

  const copy = useCallback(() => {
    if (ref.current) {
      ref.current.focus();
      ref.current.select();
      document.execCommand('copy');
    }
  }, []);

  return { ref, copy };
};

export default useCopyInputContent;
