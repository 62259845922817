import { useCallback, useEffect, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { trackEvent } from 'services/analytics';
import formatCents from 'lib/formatCents';
import { formatErrorMessage } from 'graphql/helpers';
import { ModalType } from 'graphql/reactive';
import useModal from 'graphql/hooks/useModal';
import useModalActions from 'graphql/hooks/useModalActions';
import useScholarshipPrices from 'graphql/hooks/useScholarshipPrices';
import useScholarshipActions from 'graphql/hooks/useScholarshipActions';
import { FormFields } from './types';
import { FULL_SCHOLARSHIP_PRICES } from './constants';

const SCREEN = 'pay_what_you_want';

const useConnect = () => {
  const { isOpen } = useModal(ModalType.FULL_SCHOLARSHIP);
  const { closeModal, openStripePaymentModal } = useModalActions();
  const { scholarshipPrices, loading } = useScholarshipPrices({
    skip: !isOpen,
  });
  const { subscribeSelfServe } = useScholarshipActions();

  const initialValues = useMemo<FormFields>(
    () => ({
      email: '',
      firstName: '',
      lastName: '',
      hear: '',
      hearOtherInfo: '',
      finish: false,
      price: null,
    }),
    [],
  );

  const filteredPrices = useMemo(
    () =>
      FULL_SCHOLARSHIP_PRICES.filter(
        ({ price }) =>
          price === 0 ||
          scholarshipPrices.some(({ amount }) => amount === price),
      ),
    [scholarshipPrices],
  );

  useEffect(() => {
    if (isOpen) {
      trackEvent('Scholarship Pay What you Want Display Options', {
        screen: SCREEN,
      });
    }
  }, [isOpen]);

  const handleClose = useCallback(() => {
    trackEvent('Scholarship Application Aborted', { screen: SCREEN });
    closeModal();
  }, [closeModal]);

  const handleSubmit = useCallback(
    async (
      { email, firstName, lastName, hear, hearOtherInfo, price }: FormFields,
      { setFieldError, setFieldValue }: FormikHelpers<FormFields>,
    ) => {
      try {
        if (price && price > 0) {
          const subscriptionPlan = scholarshipPrices.find(
            ({ amount }) => amount === price,
          );

          if (subscriptionPlan) {
            closeModal();

            trackEvent('Scholarship Pay What you Want Cost Submitted', {
              cost: formatCents(subscriptionPlan.amount).slice(1),
              screen: SCREEN,
            });

            trackEvent('Purchase Initiate Checkout', {
              screen: SCREEN,
              source: 'scholarship pay what you want',
              subscription_option: subscriptionPlan.sku,
            });

            setTimeout(() => {
              openStripePaymentModal({
                description: `You've been approved for one year of Waking Up for ${formatCents(
                  subscriptionPlan.amount,
                )}`,
                email,
                isPublic: true,
                planAmount: subscriptionPlan.amount,
                planId: subscriptionPlan.id,
                planInterval: subscriptionPlan.interval,
                planType: subscriptionPlan.type,
                screen: SCREEN,
                title: 'Scholarship Checkout',
              });
            }, 750);
          }
        } else {
          const data = {
            email,
            firstName,
            lastName,
            source: ['Other', 'Other Podcast'].includes(hear)
              ? `${hear}: ${hearOtherInfo}`
              : hear,
          };

          await subscribeSelfServe(data);

          trackEvent('Scholarship - Form Submit', {
            email: data.email,
            first_name: data.firstName,
            last_name: data.lastName,
            source: data.source,
            screen: SCREEN,
          });

          setFieldValue('finish', true);
        }
      } catch (e) {
        const field = ['Other', 'Other Podcast'].includes(hear)
          ? 'hearOtherInfo'
          : 'hear';

        setFieldError(field, formatErrorMessage(e));
      }
    },
    [closeModal, openStripePaymentModal, scholarshipPrices, subscribeSelfServe],
  );

  return {
    fullScholarshipPrices: filteredPrices,
    handleClose,
    handleSubmit,
    initialValues,
    isLoading: loading,
    isOpen,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
