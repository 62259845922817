import type { FC } from 'react';
import { AnimatePresence } from 'framer-motion';
import Input from './Input';
import { Container, Error, InputsContainer, Legend } from './styles';
import { Props } from './types';
import { N_PIN_INPUTS_ARRAY } from './constants';
import useLogic from './logic';

const LoginCodeField: FC<Props> = ({ className, name }) => {
  const {
    code,
    digitRefs,
    error,
    handleDigitChange,
    handleKeyDown,
    handleKeyPress,
    handlePaste,
    touched,
  } = useLogic({ name });

  return (
    <Container className={className}>
      <InputsContainer>
        <Legend>Login code</Legend>
        {N_PIN_INPUTS_ARRAY.map((v, i) => (
          <Input
            key={v}
            autoComplete="off"
            autoCorrect="off"
            autoFocus={i === 0}
            data-cypress-id={`code-digit-${i}`}
            id={`code-digit-${i}`}
            index={i}
            inputRef={digitRefs[i]}
            label={`code input ${i + 1}`}
            name={`Code digit ${i}/4`}
            onChange={handleDigitChange}
            onKeyDown={handleKeyDown}
            onKeyPress={handleKeyPress}
            onPaste={handlePaste}
            pattern="[0-9]"
            spellCheck={false}
            type="number"
            value={code[i]}
          />
        ))}
      </InputsContainer>
      <AnimatePresence initial={false}>
        {touched && error && <Error>{error}</Error>}
      </AnimatePresence>
    </Container>
  );
};

export default LoginCodeField;
