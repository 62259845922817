import stringToEnum from 'lib/stringToEnum';
import type { StripePaymentMethodDataFragment } from 'graphql/generated';

export enum CreditCardBrand {
  AMEX = 'amex',
  DINERS = 'diners',
  DISCOVER = 'discover',
  JCB = 'jcb',
  MASTER_CARD = 'mastercard',
  UNION_PAY = 'unionpay',
  VISA = 'visa',
  GENERIC = 'credit card',
}

export const normalizeStripePaymentMethod = (
  stripePaymentMethod: StripePaymentMethodDataFragment,
) => ({
  brand:
    stringToEnum(CreditCardBrand, stripePaymentMethod.brand) ||
    CreditCardBrand.GENERIC,
  expMonth: stripePaymentMethod.exp_month || 0,
  expYear: stripePaymentMethod.exp_year || 0,
  id: stripePaymentMethod.id || '',
  last4: stripePaymentMethod.last4 || '',
});

export type StripePaymentMethod = ReturnType<
  typeof normalizeStripePaymentMethod
>;
