import {
  useRef,
  useCallback,
  ChangeEvent as ReactChangeEvent,
  MouseEvent as ReactMouseEvent,
} from 'react';
import { useField } from 'formik';
import { LogicProps } from './types';

const useConnect = ({ name, normalize, ref }: LogicProps) => {
  const [field, { touched, error }, { setValue, setError, setTouched }] =
    useField(name);

  const localRef = useRef<HTMLInputElement>(null);
  const inputRef = ref || localRef;

  const handleChange = useCallback(
    (e: ReactChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const value = normalize ? normalize(e.target.value) : e.target.value;
      setValue(value);
    },
    [setValue, normalize],
  );

  const handleClear = useCallback(
    (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setValue('');
      setError(undefined);
      setTouched(false);
      inputRef.current?.focus();
    },
    [inputRef, setError, setValue, setTouched],
  );

  return {
    error,
    field,
    handleChange,
    handleClear,
    inputRef,
    touched,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
