import { useCallback, useState } from 'react';
import type { FC, MouseEvent as ReactMouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import useModalActions from 'graphql/hooks/useModalActions';
import useStripePaymentMethodActions from 'graphql/hooks/useStripePaymentMethodActions';
import { Button, CardElement, Container, FormErrors, Title } from './styles';
import type { Props } from './types';

const ChangePaymentCardForm: FC<Props> = ({
  email,
  firstName,
  isLoading,
  lastName,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { isUpdating, update } = useStripePaymentMethodActions();
  const { closeModal, openDialogModal } = useModalActions();
  const navigate = useNavigate();

  const [errors, setErrors] = useState<string | undefined>(undefined);

  const handleSubmit = useCallback(
    async (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      if (stripe && elements) {
        try {
          const card = elements.getElement('card');

          if (!card) {
            throw new Error(
              'Unable to get card information. Try again in a few seconds',
            );
          }

          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card,
            billing_details: {
              email,
              name:
                firstName && lastName ? `${firstName} ${lastName}` : undefined,
            },
          });

          if (error || !paymentMethod) {
            throw new Error(error ? error.message : undefined);
          }

          openDialogModal({
            cancelButtonText: 'No',
            confirmButtonText: 'Confirm',
            description: 'Are you sure you want to update your payment card?',
            onCancel: closeModal,
            onConfirm: async () => {
              closeModal();
              await update(paymentMethod.id);
              navigate('/account/subscription');
            },
            title: 'Update payment card',
          });
        } catch (error) {
          setErrors(
            error.message ||
              'Unable to complete transaction. Try again in a few seconds',
          );
        }
      }
    },
    [
      closeModal,
      elements,
      email,
      firstName,
      lastName,
      navigate,
      openDialogModal,
      stripe,
      update,
    ],
  );

  return (
    <Container>
      <Title>Fill in the details below to update your payment card.</Title>
      <CardElement />
      {errors && <FormErrors>{errors}</FormErrors>}
      <Button
        id="stripe-update-payment-source-button"
        isLoading={isLoading || isUpdating}
        onClick={handleSubmit}
        type="button"
      >
        Update Payment Source
      </Button>
    </Container>
  );
};

export default ChangePaymentCardForm;
