import type { FC } from 'react';
import { AppConfig } from 'config';
import {
  Caption,
  Container,
  Image,
  ImageContainer,
  Reviews,
  Stars,
} from './styles';
import type { Props } from './types';

const AppScreenshot: FC<Props> = ({ className }) => (
  <Container className={className}>
    <ImageContainer>
      <Image alt="" src={`${AppConfig.imagesURL}/signup-app-screenshot.png`} />
    </ImageContainer>
    <Reviews>
      <Caption>Over 50,000 5-star reviews</Caption>
      <Stars level={5} size={21} />
    </Reviews>
  </Container>
);

export default AppScreenshot;
