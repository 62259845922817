import styled from 'styled-components';

export const Container = styled.span`
  align-items: center;
  background-color: rgba(196, 196, 196, 0.08);
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.measures.m};
  min-height: 2.875rem;
  padding: ${({ theme }) => `${theme.measures.s} ${theme.measures.m}`};
`;

export const Text = styled.p`
  font-family: ${({ theme }) => theme.font.family.inter};
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.01em;
  line-height: 1.5rem;
`;

export const More = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.coreTeal700};
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.family.inter};
  font-size: 0.75rem;
  letter-spacing: 0.01em;
  line-height: 1.125rem;
  outline-offset: 0.25rem;
  padding: 0;
  text-decoration-line: underline;
`;
