import { matchRoutes, useLocation } from 'react-router-dom';
import { createGetBackLevels } from 'lib/createGetBackLevels';
import { up } from 'lib/paths';

/*
 * We can use getBackLevels to define custom "back levels" that will be passed
 * to "Paths.up" based on the current path.
 *
 * For example, we want the back button to achieve the following:
 *
 * /theory                  --> /
 * /practice/intro-course --> /
 *
 * This is because if '/practice/intro-course' redirected to '/practice',
 * '/practice' would redirect back to '/intro-course', therefore we must be
 * able to explicitly specify that we want to get back two levels instead of
 * one.
 *
 * If the path doesn't match any rule, then it will go back one level by
 * default.
 */
const getBackLevels = createGetBackLevels([
  {
    levels: 2,
    route: '/practice/:pack',
  },
  {
    levels: 2,
    route: '/theory/:pack',
  },
  {
    levels: 2,
    route: '/account/redeem/gift',
  },
  {
    levels: 2,
    route: '/account/redeem/promo',
  },
]);

const NO_BACK_ROUTES = [{ path: '/theory/*' }, { path: '/practice/*' }];
const NO_BACK_ROUTES_EXACT = [{ path: '/account' }, { path: '/' }];

const useConnect = () => {
  const location = useLocation();
  const { pathname } = location;
  const match = matchRoutes(NO_BACK_ROUTES, location);
  const matchExact = matchRoutes(NO_BACK_ROUTES_EXACT, location);

  return {
    backToUrl: up(pathname, getBackLevels(pathname)),
    showBackButton: !match && !matchExact,
  };
};

export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
