import { makeVar } from '@apollo/client';
import { getPlayerVolume, setPlayerVolume } from 'services/storage';

export type Volume = {
  isMuted: boolean;
  level: number;
};

export const volumeVar = makeVar<Volume>({
  isMuted: false,
  level: getPlayerVolume(),
});

export const setVolume = (data: Volume) => {
  volumeVar(data);
  setPlayerVolume(data.level);
};
