/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { media } from 'styles/mixins';
import DefaultButton from 'components/Button';

export const Button = styled(DefaultButton)<{ $highlight: boolean }>`
  margin-bottom: 1.25rem;
  min-width: 0;
  padding: 0.8rem;

  ${media.phoneLandscape} {
    span {
      font-size: 0.95rem;
    }
  }

  ${({ $highlight }) =>
    $highlight &&
    css`
      background-color: ${({ theme }) =>
        theme.name === 'LIGHT'
          ? theme.colors.coreBlue500
          : theme.colors.coreTeal500};
      border: 2px solid
        ${({ theme }) =>
          theme.name === 'LIGHT'
            ? theme.colors.coreBlue500
            : theme.colors.coreTeal500};
      color: ${({ theme }) =>
        theme.name === 'LIGHT'
          ? theme.colors.coreNeutralWhite
          : theme.colors.coreNeutralAbyss};

      &:hover {
        background-color: ${({ theme }) =>
          theme.name === 'LIGHT'
            ? theme.colors.coreBlue700
            : theme.colors.coreTeal700};
        border: 2px solid
          ${({ theme }) =>
            theme.name === 'LIGHT'
              ? theme.colors.coreBlue700
              : theme.colors.coreTeal700};
      }
    `}
`;
