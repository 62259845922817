import { getFeatureContent } from '.';

const updateFeaturedContent = async (hash: string) => {
  const featured = await getFeatureContent();
  const { deepLink } = featured || {};

  if (deepLink?.toLowerCase().includes(`/course/${hash.toLowerCase()}`)) {
    await getFeatureContent('network-only');
  }
};

export default updateFeaturedContent;
