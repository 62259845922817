import type { FC } from 'react';
import { Container, Link, Small, Text } from './styles';

const Agreement: FC = () => (
  <Container>
    <Text>No credit card required.</Text>
    <Small>
      By continuing, you agree to Waking Up&apos;s{' '}
      <Link
        aria-label="Terms of Service page (opens in a new tab)"
        href="https://wakingup.com/terms-of-service"
        rel="noopener"
        target="_blank"
      >
        Terms & Conditions
      </Link>{' '}
      and{' '}
      <Link
        aria-label="Privacy Policy page (opens in a new tab)"
        href="https://wakingup.com/privacy-policy"
        rel="noopener"
        target="_blank"
      >
        Privacy Policy
      </Link>
    </Small>
  </Container>
);

export default Agreement;
