import type { ReactNode } from 'react';
import { makeVar } from '@apollo/client';
import { SubscriptionPlanType } from 'graphql/generated';

export enum ModalType {
  ADD_FAMILY_MEMBER,
  DAILY_DURATION,
  DIALOG,
  DISMISS_RETREAT,
  END_RETREAT,
  FULL_SCHOLARSHIP,
  GIVE_FREE_TRIAL,
  INTRO_COURSE_SECTION_COMPLETED,
  JOIN_RETREAT,
  LEAVE_RETREAT,
  ONBOARDING_EXPERIENCE,
  ONBOARDING_OPEN_ACCESS,
  ONBOARDING_SLIDES,
  RESERVE_RETREAT,
  RESET_CONTENT_MENU,
  RETREAT_LIVE_MEETING,
  SCHOLARSHIP,
  SELF_TIMER_CONFIGURATION,
  SEND_EMAIL,
  SHARE_APP,
  SHARE_LINK,
  STRIPE_PAYMENT,
  SUPPORT,
  UNLOCK_FREE_MONTH_SUCCESS,
  UNLOCK_FREE_MONTH,
  UPGRADE_TO_ANNUAL,
}

export type ModalAddFamilyMember = {
  type: ModalType.ADD_FAMILY_MEMBER;
};

export type ModalDailyDuration = {
  type: ModalType.DAILY_DURATION;
};

export type ModalDialog = {
  type: ModalType.DIALOG;
  payload: {
    buttonsPosition?: 'left' | 'center' | 'right';
    cancelButtonId?: string;
    cancelButtonText?: string;
    confirmButtonId?: string;
    confirmButtonText?: string;
    description: ReactNode;
    onConfirm?: () => void;
    onCancel: () => void;
    title?: string;
  };
};

export type ModalDismissRetreat = {
  type: ModalType.DISMISS_RETREAT;
};

export type ModalEndRetreat = {
  type: ModalType.END_RETREAT;
};

export type ModalFullScholarship = {
  type: ModalType.FULL_SCHOLARSHIP;
};

export type ModalGiveFreeTrial = {
  type: ModalType.GIVE_FREE_TRIAL;
  payload: {
    source: 'home_cta' | 'deep_link' | 'menu';
  };
};

export type ModalIntroCourseSectionCompleted = {
  type: ModalType.INTRO_COURSE_SECTION_COMPLETED;
  payload: {
    packId: string;
  };
};

export type ModalJoinRetreat = {
  type: ModalType.JOIN_RETREAT;
};

export type ModalLeaveRetreat = {
  type: ModalType.LEAVE_RETREAT;
};

export type ModalOnboardingExperience = {
  type: ModalType.ONBOARDING_EXPERIENCE;
};

export type ModalOnboardingOpenAccess = {
  type: ModalType.ONBOARDING_OPEN_ACCESS;
};

export type ModalOnboardingSlides = {
  type: ModalType.ONBOARDING_SLIDES;
};

export type ModalReserveRetreat = {
  type: ModalType.RESERVE_RETREAT;
};

export type ModalResetContentMenu = {
  type: ModalType.RESET_CONTENT_MENU;
};

export type ModalRetreatLiveMeeting = {
  type: ModalType.RETREAT_LIVE_MEETING;
};

export type ModalScholarship = {
  type: ModalType.SCHOLARSHIP;
};

export type ModalSelfTimerConfiguration = {
  type: ModalType.SELF_TIMER_CONFIGURATION;
};

export type ModalSendEmail = {
  type: ModalType.SEND_EMAIL;
};

export type ModalShareApp = {
  type: ModalType.SHARE_APP;
  payload: {
    source: 'menu' | 'home_cta' | 'homescreen_footer' | 'deep_link';
  };
};

export type ModalShareLink = {
  type: ModalType.SHARE_LINK;
  payload: {
    description: string;
    id: string;
    link: string;
    title: string;
  };
};

export type ModalStripePayment = {
  type: ModalType.STRIPE_PAYMENT;
  payload: {
    description?: string;
    experiment?: string;
    email?: string;
    isPublic: boolean;
    isStripeCheckoutSession?: boolean;
    planAmount: number;
    planId: string;
    planInterval: string;
    planType: SubscriptionPlanType;
    priceId?: string;
    promoCode?: string;
    screen?: string;
    sharedBy?: string;
    title?: string;
    variant?: string;
  };
};

export type ModalSupport = {
  type: ModalType.SUPPORT;
};

export type ModalUnlockFreeMonth = {
  type: ModalType.UNLOCK_FREE_MONTH;
  payload: {
    courseHash: string;
  };
};

export type ModalUnlockFreeMonthSuccess = {
  type: ModalType.UNLOCK_FREE_MONTH_SUCCESS;
  payload: {
    courseHash: string;
  };
};

export type ModalUpgradeToAnnual = {
  type: ModalType.UPGRADE_TO_ANNUAL;
  payload: {
    planId: string;
  };
};

export type Modal =
  | ModalAddFamilyMember
  | ModalDailyDuration
  | ModalDialog
  | ModalDismissRetreat
  | ModalEndRetreat
  | ModalFullScholarship
  | ModalGiveFreeTrial
  | ModalIntroCourseSectionCompleted
  | ModalJoinRetreat
  | ModalLeaveRetreat
  | ModalOnboardingExperience
  | ModalOnboardingOpenAccess
  | ModalOnboardingSlides
  | ModalReserveRetreat
  | ModalResetContentMenu
  | ModalRetreatLiveMeeting
  | ModalScholarship
  | ModalSelfTimerConfiguration
  | ModalSendEmail
  | ModalShareApp
  | ModalShareLink
  | ModalStripePayment
  | ModalSupport
  | ModalUnlockFreeMonth
  | ModalUnlockFreeMonthSuccess
  | ModalUpgradeToAnnual;

export const modalVar = makeVar<Modal | null>(null);

export const resetModalVar = () => {
  modalVar(null);
};

export const setModalVar = (data: Modal | null) => {
  modalVar(data);
};
