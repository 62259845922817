import { memo, type FC } from 'react';
import Form from './Form';
import { ClearButton, CloseIcon, Modal } from './styles';
import useConnect from './connect';

const SendEmailModal: FC = () => {
  const { closeModal, handleSubmit, isOpen } = useConnect();

  return (
    <Modal hideClose open={isOpen} onClose={closeModal}>
      <ClearButton onClick={closeModal}>
        <CloseIcon name="xCircleLight" />
      </ClearButton>
      <Form onSubmit={handleSubmit} />
    </Modal>
  );
};

export default memo(SendEmailModal);
