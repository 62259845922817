/* eslint-disable import/prefer-default-export */
import { CreditCardBrand } from 'models/StripePaymentMethod';
import Generic from './Generic';
import Amex from './Amex';
import Diners from './Diners';
import Discover from './Discover';
import Jcb from './Jcb';
import Mastercard from './Mastercard';
import Unionpay from './Unionpay';
import Visa from './Visa';

export const ICONS = {
  [CreditCardBrand.AMEX]: Amex,
  [CreditCardBrand.DINERS]: Diners,
  [CreditCardBrand.DISCOVER]: Discover,
  [CreditCardBrand.GENERIC]: Generic,
  [CreditCardBrand.JCB]: Jcb,
  [CreditCardBrand.MASTER_CARD]: Mastercard,
  [CreditCardBrand.UNION_PAY]: Unionpay,
  [CreditCardBrand.VISA]: Visa,
};
