import type { FC } from 'react';
import Icon from 'components/Icon';
import CloseButton from '../CloseButton';
import Background from './Background';
import { Button, Container, Content } from './styles';
import { Props } from './types';
import useLogic from './logic';

const Panel: FC<Props> = ({
  backgroundImage,
  children,
  className,
  id,
  isMinimized = false,
  onClose,
  onToggleDisplayStatus,
}) => {
  const {
    closeButtonRef,
    handleClose,
    handleKeyDown,
    handleToggleDisplayStatus,
    modalRef,
    windowHeight,
  } = useLogic({ isMinimized, onClose, onToggleDisplayStatus });

  return (
    <Container
      $isMinimized={isMinimized}
      $wHeight={windowHeight}
      className={className}
      id={id}
      onClick={
        isMinimized && onToggleDisplayStatus !== undefined
          ? onToggleDisplayStatus
          : undefined
      }
      onKeyDown={handleKeyDown}
      ref={modalRef}
    >
      {!isMinimized && backgroundImage && (
        <Background image={backgroundImage} />
      )}
      {onClose !== undefined && (
        <CloseButton
          isMinimized={isMinimized}
          onClick={handleClose}
          innerRef={closeButtonRef}
        />
      )}
      {!isMinimized && onToggleDisplayStatus !== undefined && (
        <Button
          aria-label="Minimize player"
          onClick={handleToggleDisplayStatus}
        >
          <Icon name="caretDownLight" />
        </Button>
      )}
      <Content $isMinimized={isMinimized}>{children}</Content>
    </Container>
  );
};

export default Panel;
