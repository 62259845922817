export const MAX_DURATION_MINS = 120;

export enum SelfTimerInterval {
  NONE = 'none',
  HALFWAY = 'halfway',
  ONE_MINUTE = 'oneMinute',
  TWO_MINUTES = 'twoMinutes',
  FIVE_MINUTES = 'fiveMinutes',
  TEN_MINUTES = 'tenMinutes',
}

export type SelfTimerIntervalData = {
  minutes: number | null;
  text: string;
  trackerValue: string;
  type: SelfTimerInterval;
};

export const SELF_TIMER_INTERVALS: Record<
  SelfTimerInterval,
  SelfTimerIntervalData
> = {
  [SelfTimerInterval.NONE]: {
    minutes: null,
    text: 'None',
    trackerValue: '0',
    type: SelfTimerInterval.NONE,
  },
  [SelfTimerInterval.ONE_MINUTE]: {
    minutes: 1,
    text: 'Every minute',
    trackerValue: '1',
    type: SelfTimerInterval.ONE_MINUTE,
  },
  [SelfTimerInterval.TWO_MINUTES]: {
    minutes: 2,
    text: 'Every 2 minutes',
    trackerValue: '2',
    type: SelfTimerInterval.TWO_MINUTES,
  },
  [SelfTimerInterval.FIVE_MINUTES]: {
    minutes: 5,
    text: 'Every 5 minutes',
    trackerValue: '5',
    type: SelfTimerInterval.FIVE_MINUTES,
  },
  [SelfTimerInterval.TEN_MINUTES]: {
    minutes: 10,
    text: 'Every 10 minutes',
    trackerValue: '10',
    type: SelfTimerInterval.TEN_MINUTES,
  },
  [SelfTimerInterval.HALFWAY]: {
    minutes: null,
    text: 'Halfway',
    trackerValue: '.5',
    type: SelfTimerInterval.HALFWAY,
  },
};

export const getValidIntervalTimes = (
  duration: number,
  intervalTime: SelfTimerInterval,
): SelfTimerInterval => {
  const intervalTimeMinutes = SELF_TIMER_INTERVALS[intervalTime].minutes;
  // If there is an intervalTime of EVERY_X_MINUTES and the frequency is
  // greater than the duration, pretend that the interval is not selected.
  if (intervalTimeMinutes && duration <= intervalTimeMinutes) {
    return SelfTimerInterval.NONE;
  }

  return intervalTime;
};

export const getIntervalSeconds = ({
  duration,
  interval,
}: {
  duration: number;
  interval: SelfTimerInterval;
}): number | undefined => {
  if (interval === SelfTimerInterval.NONE) {
    return undefined;
  }

  if (interval === SelfTimerInterval.HALFWAY) {
    return Math.ceil(duration / 2);
  }

  const intervalMinutes = SELF_TIMER_INTERVALS[interval].minutes;
  return intervalMinutes ? intervalMinutes * 60 : undefined;
};
