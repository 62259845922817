import styled from 'styled-components';
import { blink } from 'styles/mixins';
import colorWithAlpha from 'lib/color';
import type { Props } from './types';

export const Container = styled.div<{ $typography: Props['typography'] }>`
  align-items: center;
  display: flex;
  height: ${({ $typography }) => {
    switch ($typography) {
      case 'title1':
        return '3.25rem';
      case 'title2':
        return '2.625rem';
      case 'displayLarge':
        return '3rem';
      case 'displayMedium':
        return '2.25rem';
      case 'displaySmall':
        return '1.875rem';
      case 'heading':
        return '1.25rem';
      case 'bodyLarge':
        return '1.5rem';
      case 'bodyMedium':
        return '1.25rem';
      case 'bodySmall':
        return '1rem';
      case 'overline':
        return '0.75rem';
      default:
        return '0';
    }
  }};
`;

export const Placeholder = styled.div<{ $typography: Props['typography'] }>`
  animation: ${blink} 3s linear infinite;
  background-color: ${({ theme }) =>
    colorWithAlpha({
      color: theme.colors.fontDeemphasized,
      alpha: 0.8,
    })};
  height: ${({ $typography }) => {
    switch ($typography) {
      case 'title1':
        return '3.25rem';
      case 'title2':
        return '2.625rem';
      case 'displayLarge':
        return '2rem';
      case 'displayMedium':
        return '1.5rem';
      case 'displaySmall':
        return '1.25rem';
      case 'heading':
        return '1rem';
      case 'bodyLarge':
        return '1rem';
      case 'bodyMedium':
        return '0.875rem';
      case 'bodySmall':
        return '0.75rem';
      case 'overline':
        return '0.5625rem';
      default:
        return '0';
    }
  }};
  width: 100%;
`;
