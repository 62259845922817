import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { ZIndex } from 'styles/metrics';
import { media } from 'styles/mixins';
import { ContainerProps } from './types';

export const Container = styled(motion.div).attrs(
  ({ $isMinimized, $wHeight }: ContainerProps) => ({
    initial: { y: '100%', height: `calc(${$wHeight}px - 1.5%)` },
    animate: { y: 0, height: !$isMinimized ? `${$wHeight}px` : 64 },
    exit: { y: '100%', opacity: 0 },
    transition: { duration: 0.25, ease: 'easeInOut' },
  }),
)<ContainerProps>`
  background-color: ${({ theme }) => theme.colors.coreNeutralAbyss};
  bottom: 0;
  color: ${({ theme }) => theme.colors.coreNeutralAurora};
  left: 0;
  position: fixed;
  right: 0;
  z-index: ${ZIndex.PLAYER};

  ${({ $isMinimized }) =>
    $isMinimized &&
    css`
      background-color: ${({ theme }) => theme.colors.backgroundBase};
      color: ${({ theme }) => theme.colors.fontBase};
    `}
`;

export const Button = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 50%;
  box-sizing: content-box;
  color: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  line-height: 1;
  opacity: 1;
  position: absolute;
  right: ${({ theme }) => theme.measures.x};
  top: ${({ theme }) => theme.measures.x};
  z-index: ${ZIndex.PLAYER_CLOSE_BUTTON};

  ${media.tabletPortrait} {
    right: ${({ theme }) => theme.measures.m};
    top: ${({ theme }) => theme.measures.l};
  }
`;

export const Content = styled.div<{ $isMinimized: boolean }>`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: flex-start;
  overflow: hidden;
  -ms-overflow-style: none;
  position: relative;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ $isMinimized }) =>
    $isMinimized &&
    css`
      overflow: visible;
    `}
`;
