import type { FC } from 'react';
import type { Props } from './types';

const NoMusicNoteLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#a)" fill="currentColor">
      <path d="M5.44 16.17a3.117 3.117 0 0 1 2.32-2.32l1.28-1.28c-.19-.03-.37-.06-.57-.06-2.41 0-4.38 1.96-4.38 4.38 0 .2.03.38.06.57l1.28-1.28.01-.01Zm7.41-7.45h.03l1-.99-1.03-.31v-3.2l3.49 1.05 1-1-4.94-1.49a.605.605 0 0 0-.55.1c-.16.12-.25.3-.25.5v6.63l1.25-1.25v-.04Zm-1.25 8.16c0 1.72-1.4 3.12-3.12 3.12-.88 0-1.67-.37-2.24-.95l-.88.88c.79.81 1.9 1.32 3.12 1.32 2.41 0 4.38-1.96 4.38-4.38v-4.45l-2.2 2.2c.59.57.95 1.36.95 2.24l-.01.02Zm7.5-10.69V9.3l-2.39-.72-1 1 3.84 1.15c.19.06.39.02.55-.1.16-.12.25-.3.25-.5v-4.5a.62.62 0 0 0-.22-.47L19.1 6.19Zm2.16-4.01a.628.628 0 0 0-.88 0l-17.2 17.2c-.24.24-.24.64 0 .88.12.12.28.18.44.18.16 0 .32-.06.44-.18l17.2-17.19c.24-.24.24-.64 0-.88v-.01Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(3 2)" d="M0 0h18.45v19.26H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default NoMusicNoteLight;
