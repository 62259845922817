import type { FC } from 'react';
import { Props } from './types';

const PlayRoundedLight: FC<Props> = ({ size, ...rest }) => (
  <svg
    fill="none"
    height={size}
    // viewBox="0 0 11 13"
    viewBox="-0.55 -0.65 12.1 14.3"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M10.144 6.164 1.633.962a.473.473 0 0 0-.72.404v10.403a.473.473 0 0 0 .72.404l8.511-5.202a.472.472 0 0 0 0-.807Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PlayRoundedLight;
